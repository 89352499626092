import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Affair } from '@libs/models/src';
import { SwalService, ToggleService } from '@libs/services/src';
import { AffairMyLinkService } from '@libs/services/src/lib/affair-mylink.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-affair-create-mylink-results',
  templateUrl: './affair-create-mylink-results.component.html',
  styleUrls: ['./affair-create-mylink-results.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AffairCreateMylinkResultsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() affairsResults = [];
  public isOpen = false;
  public selector = 'list-actions';

  constructor(
    private toggleService: ToggleService,
    private affairService: AffairMyLinkService,
    private swalService: SwalService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.triggerActions();
  }

  ngOnChanges() {
  }

  ngOnDestroy() {
    this.affairsResults = [];
  }

  toggle() {
    if (this.isOpen) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }
  }

  importFolder(result: Affair) {
    this.affairService.import(result.mlRef);
  }

  private initSubscriptions() {
    this.toggleService.quickViewToggle.subscribe(data => {
      this.toggle();
    });
  }

  private triggerActions() {
    const that = this;
    $(`#${this.selector}`).on('click', 'button.btn-import', function () {
      const mlRef = $(this).data('ref');
      that.affairService.import(mlRef);
    });
    $(`#${this.selector}`).on('click', 'button.btn-edit', function () {
      const id = $(this).data('id');
      that.affairService.edit(id);
    });
    $(`#${this.selector}`).on('click', 'button.btn-archive', function () {
      const swalOptions = that.swalService.getSwalDeleteOptions({
        text: 'L\'affaire sera archivée et ne sera plus visible par les autres sociétés du groupe.'
      });
      Swal.fire(swalOptions).then((result) => {
        if (result.value) {
          const mlRef = $(this).data('ref');
          that.affairService.archive(mlRef);
        }
      });
    });
  }


}
