import { Component, Input, OnInit } from '@angular/core';
import { AffairGoal, SubmitButton } from '@libs/models/src';
import { NotifService } from '@libs/services/src';
import { AffairGoalService } from '@libs/services/src/lib/affair-goal.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-affairs-goals-modal',
  templateUrl: './affairs-goals-modal.component.html',
  styleUrls: ['./affairs-goals-modal.component.scss']
})
export class AffairsGoalsModalComponent implements OnInit {
  @Input() form: any;
  @Input() affairGoal: AffairGoal;
  @Input() marketsTypes: any;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private affairGoalService: AffairGoalService,
    private notifService: NotifService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.affairGoalService.setSubmitButton(this.submitButton);
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.affairGoal) {
        this.affairGoalService.update(this.affairGoal.id, this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      } else {
        this.affairGoalService.store(this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.affairGoalService.determinateSubmitButton(this.affairGoal, 'l\'objectif affaire');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
