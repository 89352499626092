import { Component, Input, OnInit } from '@angular/core';
import { Affair, AffairYnergie } from '@libs/models/src';
import { SwalService } from '@libs/services/src';
import { AffairYnergieService } from '@libs/services/src/lib/affairs/affair-ynergie.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, throwError } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { AffairShowYnergieScenarioModalComponent } from './affair-show-ynergie-scenario-modal/affair-show-ynergie-scenario-modal.component';

@Component({
  selector: 'app-affair-show-ynergie',
  templateUrl: './affair-show-ynergie.component.html',
  styleUrls: ['./affair-show-ynergie.component.scss']
})
export class AffairShowYnergieComponent implements OnInit {
  @Input() affair: Affair;
  public affairYnergie: AffairYnergie = null;
  public isDestroyed$ = new Subject<void>();

  constructor(
    private affairYnergieService: AffairYnergieService,
    private modalService: NzModalService,
    private spinnerService: NgxSpinnerService,
    private swalService: SwalService
  ) { }

  ngOnInit(): void {
    this.spinnerService.show('load-affair-ynergie')
    if (this.affair.affairYnergieId) {
      this.affairYnergieService.get(this.affair.affairYnergieId)
        .pipe(
          takeUntil(this.isDestroyed$),
          catchError(error => {
            this.swalService.showSwalError(error);
            return throwError(error);
          }),
          finalize(() => {
            this.spinnerService.hide('load-affair-ynergie');
          })
        )
        .subscribe(
          (response) => {
            this.affairYnergie = response;
          }
        )
    }
  }

  onAddEditScenarioClicked(): void {
    this.modalService.create({
      nzContent: AffairShowYnergieScenarioModalComponent,
      nzComponentParams: {
        affairYnergie: this.affairYnergie
      }
    })
  }

}
