import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AffairDocumentDeliveredService } from '@libs/services/src/lib/affair-document-delivered.service';
import { Subscription } from 'rxjs';
import { AffairShowDocumentFiltersComponent } from '../affair-show-modules/affair-show-document-filters/affair-show-document-filters.component';

@Component({
  selector: 'app-affair-show-documents-delivered',
  templateUrl: './affair-show-documents-delivered.component.html',
  styleUrls: ['./affair-show-documents-delivered.component.scss']
})
export class AffairShowDocumentsDeliveredComponent implements OnInit, OnDestroy {

  @Input() affair: any;
  @Input() appKey: string;
  private documents$: Subscription;
  private filters$: Subscription;
  public documents = [];
  public companies = [];
  public filters = [];

  constructor(
    public affairDocumentService: AffairDocumentDeliveredService
  ) { }

  ngOnInit() {
    this.documents$ = this.affairDocumentService.getAll(this.affair.id, null).subscribe(
      (documents) => {
        if (documents) {
          this.documents = documents;
          this.companies = Object.keys(documents);
        }
      }
    );
    this.filters$ = this.affairDocumentService.getFilters(this.affair.id, false).subscribe(
      (filters) => {
        if (filters) {
          this.filters = filters;
        }
      }
    );
  }

  ngOnDestroy() {
    this.documents$.unsubscribe();
    this.filters$.unsubscribe();
    this.affairDocumentService.resetDocuments();
  }

}
