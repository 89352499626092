import { BuildingService, ContactSelectParameters, UserService, InterventionNameParameters, NotifService } from '@eros-front/services';
import { ContactService, AffairService, InterventionService } from '@eros-front/services';
import { Affair, Building, SelectModel, SubmitButton } from '@eros-front/models';
import { Subscription } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AffairMarketType } from '@libs/enum/src';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { tabSuffix } from '_config/tab-suffix';
import { Title } from '@angular/platform-browser';

export interface InterventionCardAffairProduct {
  id: number;
  checked: boolean;
  productId: number;
  productName: string;
  quantity: number;
  price: number;
}

@Component({
  selector: 'app-intervention-create',
  templateUrl: './intervention-create.component.html',
  styleUrls: ['./intervention-create.component.scss']
})
export class InterventionCreateComponent implements OnInit, OnDestroy, AfterViewChecked {

  private affair$: Subscription;
  private selectWorksSupervisors$: Subscription;
  private contactsSelect$: Subscription;
  private generatedDescription$: Subscription;
  private generatedName$: Subscription;
  private interventionsCreateSelects$: Subscription;
  public form: FormGroup = null;
  public affair: Affair;
  public interventionProducts: any[] = [];
  public worksSupervisors;
  public worksSupervisorsSelect;
  public contacts: any[];
  public selectedContacts: any[];
  public technicians: any[];
  public selectedTechnicians: any[] = [];
  public showTime = {
    nzMinuteStep: 15,
    nzFormat: 'HH:mm'
  }
  public isConfirmed = false;
  public selectContactsParams: ContactSelectParameters;
  public buildings: Building[];
  public housings: any;
  public housingsFiltered: any;
  public marketTypeEnum = AffairMarketType;
  public affairProducts: any = [];
  public submitButton: SubmitButton;
  public isOpenOnce = false;
  public defaultDate = null;
  public housingsSelected = [];
  public worksSupervisorSelected = null;
  public indicatorsInterventionsTypes: any[];
  public affairsContacts: SelectModel[];
  public isDeliveredHouse: boolean;
  public buildingsSelected = [];
  public isTimeSlot: boolean;
  public readonly DPE_ID = 13;

  constructor(
    private titleService: Title,
    private interventionService: InterventionService,
    private affairService: AffairService,
    private route: ActivatedRoute,
    private contactService: ContactService,
    private userService: UserService,
    private buildingService: BuildingService,
    private router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private notifService: NotifService,
  ) {
    this.titleService.setTitle(`Création d'une intervention${tabSuffix}`);
    this.initSubscriptions();
  }

  ngOnInit() {
    this.userService.getTechniciansSelect();
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = Number(params.get('id'));
      this.affairService.getForAdd(id);
      this.buildingService.getHousingsByAffair(id);
    });
    this.determinateSubmitButton();
  }

  ngOnDestroy() {
    this.affairService.resetForAdd();
    this.affair = null;
    this.form = null;
    this.interventionProducts = [];
    this.affair$.unsubscribe();
    this.selectWorksSupervisors$.unsubscribe();
    this.contactsSelect$.unsubscribe();
    this.generatedDescription$.unsubscribe();
    this.generatedName$?.unsubscribe();
    if (this.interventionsCreateSelects$) {
      this.interventionsCreateSelects$.unsubscribe();
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  private initSubscriptions() {
    this.affair$ = this.affairService.affairForAdd$.subscribe(
      (data) => {
        if (data) {
          this.affair = data;
          this.interventionService.getSelectsForCreate(this.affair.id);
        }
      })

    this.interventionsCreateSelects$ = this.interventionService.interventionsCreateSelects$.subscribe(
      (data) => {
        if (data) {
          this.technicians = data.technicians;
          this.affairProducts = data.affairProductsToProgram;
          this.indicatorsInterventionsTypes = data.indicatorsInterventionsTypes;
          this.housings = data.housings;
          this.buildings = data.buildings;
          this.affairsContacts = data.affairsContacts
          if (this.affair) {
            //Contact select 
            this.selectContactsParams = {
              'selectedAgencies': JSON.stringify([this.affair.agency.ref])
            }
            this.contactService.getForSelectWorksSupervisor(this.selectContactsParams);
            this.contactService.getForSelectWithParams(this.selectContactsParams);
            //Init form
            this.form = this.interventionService.getAddForm(this.affair, this.technicians);
            this.interventionService.initAffairProductsCheckboxes(this.form, this.affairProducts);
            this.interventionService.initHousingsCheckboxes(this.form, this.housings);
            //Description & form changes
            this.interventionService.getGeneratedDescription(this.form);
            this.onChanges();
          }
        }
      }
    )
    this.selectWorksSupervisors$ = this.contactService.selectWorksSupervisors$.subscribe(
      (data) => {
        if (data) {
          this.worksSupervisors = data;
          this.worksSupervisorsSelect = this.contactService.formatToSelectArray(this.worksSupervisors);
        }
      });
    this.contactsSelect$ = this.contactService.contactsSelect$.subscribe(
      (data) => {
        if (data) {
          this.contacts = data.data;
        }
      }
    )
    this.generatedName$ = this.interventionService.generatedName$.subscribe(
      (data) => {
        if (data) {
          if (this.form) {
            this.form.patchValue({ 'name': data });
          }
        }
      }
    )
    this.generatedDescription$ = this.interventionService.generatedDescription$.subscribe(
      (data) => {
        if (data) {
          if (this.form) {
            this.form.patchValue({ 'generatedDescription': data });
          }
        }
      }
    )
  }

  public get affairsProductsArray() {
    return this.form.get('affairProducts') as FormArray;
  }

  isTimeSlotChecked() {
    if (this.form) {
      return this.form.value.isTimeSlot === '1';
    }
    return false;
  }


  isValidForm(): boolean {
    return this.form.valid;
  }

  onSubmit() {
    if (this.form.valid) {
      this.interventionService.storeForModal(this.form).subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.router.navigateByUrl(`affairs/${this.affair.id}/show`);
        }
      );
    }
  }

  onDatepickerOpening() {
    if (!this.isOpenOnce) {
      this.defaultDate = new Date().setMinutes(0);
      this.isOpenOnce = true;
    }
  }

  onDateRangePickerOpening() {
    if (!this.isOpenOnce) {
      this.defaultDate = [new Date().setMinutes(0), null];
      this.isOpenOnce = true;
    }
  }

  onIsTimeSlotChange() {
    this.interventionService.getGeneratedName(this.form);
  }

  onTimeSlotChange() {
    this.interventionService.getGeneratedDescription(this.form);
  }

  onChanges() {
    this.form.get('techniciansIds').valueChanges.subscribe(val => {
      this.selectedTechnicians = val;
      if (!this.isConfirmed) {
        this.isConfirmed = true;
      }
    });
    this.form.get('affairProducts').valueChanges.subscribe(val => {
      const affairProductsChecked = val.filter(v => v.checked == true);
      this.interventionProducts = [];
      let techniciansIds = [];
      let isDpeChecked = false;
      this.affair.affairProducts.forEach((affairProduct) => {
        const checked = affairProductsChecked.map(x => x.affairProductId).includes(affairProduct.id);
        if (checked) {
          const affairProductChecked = affairProductsChecked.find(x => x.affairProductId == affairProduct.id);
          affairProduct.quantity = affairProductChecked.quantity != null ? affairProductChecked.quantity : affairProduct.quantity;
          this.interventionProducts.push(affairProduct);
          affairProduct.technicians.map(x => x.id).forEach((technicienId) => {
            techniciansIds.push(technicienId);
          });
          if (affairProductChecked.productId === this.DPE_ID) {
            isDpeChecked = true;
          }
        }
        if (isDpeChecked) {
          this.form.patchValue({ 'isOffice': true });
        } else {
          this.form.patchValue({ 'isOffice': false });
        }
      });
      this.interventionService.getGeneratedName(this.form);
      this.interventionService.updateDuration(this.form, this.affair, this.interventionProducts);
      this.interventionService.updateTechnicians(this.form, this.technicians, techniciansIds);
    });
    this.form.get('buildingsIds').valueChanges.subscribe(value => {
      this.filterHousings(value, true);
      this.buildingsSelected = value;
    });
    this.form.get('isTimeSlot').valueChanges.subscribe(value => {
      this.isTimeSlot = value === '1' ? true : false;
    });
  }

  goToAffairClicked() {
    this.router.navigateByUrl(`affairs/${this.affair.id}/show`);
  }

  private filterHousings(buildings: any, valueChange?: boolean) {
    const buildingsSelected = buildings.map(v => valueChange ? v.value : v.id);
    this.housingsFiltered = this.housings.length > 0 ? this.housings.filter(x => buildingsSelected.includes(x.buildingId)) : [];
    this.interventionService.initHousingsCheckboxes(this.form, this.housingsFiltered);
  }

  private determinateSubmitButton() {
    let text = '';
    let icon = null;
    icon = faSave;
    text = "Valider les changements"
    this.submitButton = {
      text: text,
      originalText: text,
      icon: icon,
      originalIcon: icon,
      enabled: true
    };
    this.interventionService.setSubmitButton(this.submitButton)
  }
}
