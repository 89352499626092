import { AfterViewInit, Component, Input, OnInit, Output, ViewChild, EventEmitter, OnDestroy, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Intervention, SubmitButton } from '@libs/models/src';
import { ContactSelectParameters, ContactService, InterventionService, NotifService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-intervention-modal-edit',
  templateUrl: './intervention-modal-edit.component.html',
  styleUrls: ['./intervention-modal-edit.component.scss']
})
export class InterventionModalEditComponent implements OnInit, OnDestroy {
  @Input() intervention: Intervention;
  @Input() users: any;
  @Input() conformities: any;
  @Input() indicatorsInterventionsTypes: any[];
  @Input() form: FormGroup;
  @Input() affairsProducts: any[];
  @Input() documentsCustomersAttached: any;
  @Input() contacts: any[];
  @Input() worksSupervisorsSelect: any;
  public submitButton: SubmitButton;
  private generatedName$: Subscription;
  private generatedDescription$: Subscription;

  constructor(
    private modal: NzModalRef,
    private interventionService: InterventionService,
    private notifService: NotifService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.determinateSubmitButton();
  }

  ngOnDestroy(): void {
    this.generatedDescription$?.unsubscribe();
    this.generatedName$?.unsubscribe();

  }

  isValidForm(): boolean {
    return this.form.valid;
  }

  submitForm(): void {
    if (this.isValidForm()) {
      this.interventionService.updateForModal(this.intervention.id, this.form).subscribe(
        (success) => {
          if (success) {
            this.notifService.showSuccessNotif(success);
            this.modal.close();
          }
        }
      );
    }
  }

  goToAffairClicked(): void {
    window.open(`affairs/${this.intervention.affairId}/show`);
  }

  private determinateSubmitButton() {
    let text = '';
    let icon = null;
    text = 'Modifier l\'intervention';
    icon = faSave;
    this.submitButton = {
      text: text,
      originalText: text,
      icon: icon,
      originalIcon: icon,
      enabled: true
    };
    this.interventionService.setSubmitButton(this.submitButton)
  }

  private initSubscriptions() {
    this.generatedName$ = this.interventionService.generatedName$.subscribe(
      (data) => {
        if (data) {
          if (this.form) {
            this.form.patchValue({ 'name': data });
          }
        }
      }
    )
    this.generatedDescription$ = this.interventionService.generatedDescription$.subscribe(
      (data) => {
        if (data) {
          if (this.form) {
            this.form.patchValue({ 'generatedDescription': data });
          }
        }
      }
    )
  }
}
