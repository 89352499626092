import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { InterventionService } from '@libs/services/src';

@Component({
  selector: 'app-intervention-housings',
  templateUrl: './intervention-housings.component.html',
  styleUrls: ['./intervention-housings.component.scss']
})
export class InterventionHousingsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() buildings: any;
  @Input() housings: any;
  @Input() isEditMode = true;
  public isCheckedAll = false;
  public housingsControls: any;

  constructor(
    private interventionService: InterventionService
  ) { }

  ngOnInit() {
  }

  onCheck(event) {
    const housingsChecked = this.form.value.housings.filter(v => v.checked == true);
    if (housingsChecked.length == this.housings.length) {
      this.isCheckedAll = true;
    } else {
      this.isCheckedAll = false;
    }
    this.interventionService.getGeneratedDescription(this.form);
  }

  onCheckAll(event) {
    const housingsFormArray = this.form.get('housings') as FormArray;
    for (const ap of this.housings) {
      const checked = event.target.checked;
      housingsFormArray.controls.map(value => value.patchValue({ checked: checked }))
    }
    this.interventionService.getGeneratedDescription(this.form);
  }

  getHousingsControls() {
    return (this.form.get('housings') as FormArray).controls;
  }

  onBuildingsChange() {
    this.interventionService.getGeneratedName(this.form);
  }

}
