import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { EventService, EventTypeService, UserService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-events-index',
  templateUrl: './events-index.component.html',
  styleUrls: ['./events-index.component.scss']
})
export class EventsIndexComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  private readonly storageKey = 'interventions-filters-form';
  public event: any[];
  private eventsTypes$: Subscription;
  public eventsTypes: any;
  private usersSelect$: Subscription;
  public usersSelect: any[];
  public eventsData: any[] = [];
  public isLoading = false;
  public pageSize: number = 10;
  public pageIndex: number = 1;
  public total: number = 1;
  private isDestroyed$ = new Subject<void>();
  public inputSearchValue = '';

  constructor(
    private titleService: Title,
    private eventService: EventService,
    private eventTypeService: EventTypeService,
    private userService: UserService,
    private storageService: FormLocalStorageService
  ) {
    this.titleService.setTitle(`Liste des évènements${tabSuffix}`);
    this.initSubscriptions();
  }

  ngOnInit() {
    const sessionFormValue = this.storageService.retrieve(this.storageKey);
    this.form = this.eventService.getFiltersForm(sessionFormValue);
    this.initList();
    this.getValues();

    this.form.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$)
      )
      .subscribe(form => {
        this.storageService.store(this.storageKey, this.form)
        this.initList();
      });
  }

  ngOnDestroy() {
    this.eventsTypes$.unsubscribe();
    this.usersSelect$.unsubscribe();
    this.isDestroyed$?.unsubscribe();
  }

  resetForm() {
    const resetForm = this.eventService.getFiltersForm();
    this.form.reset(resetForm.value);
  }

  private getValues() {
    this.eventTypeService.getForSelect();
    this.userService.getUsersSelect();
    if (this.form) {
      if (this.form.value.search) {
        this.inputSearchValue = this.form.value.search;
      }
    }
  }

  private initList() {
    this.eventService.initList(this.form)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (response) => {
          this.eventsData = response.data;
          this.isLoading = false;
          this.total = response.meta.total;
        }
      )
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.pageIndex = params.pageIndex;
    this.pageSize = params.pageSize;
    const currentSort = params.sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.form.patchValue({
      page: this.pageIndex,
      size: this.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
    })
  }


  onInputSearchChanged(newSearch: string): void {
    this.inputSearchValue = newSearch;
    this.form.patchValue({
      search: newSearch,
    })
  }

  onRedirectAffair(affairId: number): void {
    window.open('affairs/' + affairId + '/show');
  }

  private initSubscriptions() {
    this.eventsTypes$ = this.eventTypeService.eventsTypesSelect$.subscribe(
      (data) => {
        if (data) {
          this.eventsTypes = data;
        }
      }
    )
    this.usersSelect$ = this.userService.usersSelect$.subscribe(
      (data) => {
        if (data) {
          this.usersSelect = data;
        }
      }
    )

  }

}
