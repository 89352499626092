import { Injectable } from '@angular/core';

@Injectable()
export class RegexService {

  constructor() { }

  public getPhoneMask() {
    return '00 00 00 00 00';
  }

  public getEmailMask() {
    return 'A*@B*.CC*';
  }

  public getEmailPattern() {
    return {
      'A': { pattern: new RegExp('[0-9a-z-_.]') },
      'B': { pattern: new RegExp('[0-9a-z-_]') },
      'C': { pattern: new RegExp('[a-z]') }
    };
  }

  public getTimeSlotMask() {
    return 'RZh-RZh';
  }

  public getTimeSlotPattern() {
    return {
      'R': { pattern: new RegExp('[0-2]') },
      'Z': { pattern: new RegExp('[0-9]') },
    };
  }

}


