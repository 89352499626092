import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AffairService, DataTableColumn } from '@libs/services/src';
import { AffairArchiveService } from '@libs/services/src/lib/affairs/affair-archive.service';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-affairs-archives-index',
  templateUrl: './affairs-archives-index.component.html',
  styleUrls: ['./affairs-archives-index.component.scss']
})
export class AffairsArchivesIndexComponent implements OnInit, AfterViewInit {
  public columns: DataTableColumn[] = [
    {
      attr: 'mlRef',
      name: 'Réf.',
      individualFilterInput: true
    },
    {
      attr: 'oldRef',
      name: 'Réf. suivi',
      individualFilterInput: true,
      visible: false
    },
    {
      attr: 'finalCustomers',
      name: 'Nom(s) et prénom(s)',
      individualFilterInput: true
    },
    {
      attr: 'customer',
      name: 'Client',
      orderable: false,
      searchable: false
    },
    {
      attr: 'workSiteAddress',
      name: 'Adresse',
      individualFilterInput: true
    },
    {
      attr: 'workSitePostalCode',
      name: 'CP',
      individualFilterInput: true
    },
    {
      attr: 'workSiteLocality',
      name: 'Ville',
      individualFilterInput: true
    },
    {
      attr: 'folderNumber',
      name: 'N° dossier',
      individualFilterInput: true,
      visible: false
    },
    {
      attr: 'customerRef',
      name: 'Réf. client',
      individualFilterInput: true,
      visible: false
    },
    {
      attr: 'assistant',
      name: 'Assistante',
      searchable: false
    },
    {
      attr: 'technician',
      name: 'Technicien',
      searchable: false
    },
    {
      attr: 'productStatuses',
      name: 'Produit(s)',
      orderable: false,
      searchable: false,
      width: '15%'
    },
  ];
  public datatableSelector = 'affairs-archives-datatable';
  private readonly storageKey = 'affairs-archives-filters-form';
  public form: FormGroup;

  constructor(
    private titleService: Title,
    private affairService: AffairArchiveService
  ) {
    this.titleService.setTitle(`Archives : Liste des affaires${tabSuffix}`);
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.affairService.initDataTable(this.datatableSelector, this.columns);
  }

}
