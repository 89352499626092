import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { SelectModel, SubmitButton } from '@libs/models/src';
import { AgencyService, PayerService, SubmitButtonService, ThingService } from '@libs/services/src';
import { InvoiceService } from '@libs/services/src/lib/invoice.service';
import { PaymentService } from '@libs/services/src/lib/payment.service';
import { Subscription } from 'rxjs';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-payment-create',
  templateUrl: './payment-create.component.html',
  styleUrls: ['./payment-create.component.scss']
})
export class PaymentCreateComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public agencies: SelectModel[];
  public payers: SelectModel[];
  public invoicesData: any[];
  public checked = false;
  public loading = false;
  public indeterminate = false;
  public listOfData: readonly any[] = [];
  public listOfCurrentPageData: readonly any[] = [];
  public setOfCheckedId = new Set<number>();
  public setOfCheckedInvoices = new Set<any>();
  private agenciesSelect$: Subscription;
  private payersSelect$: Subscription;
  public paymentsModes$: Subscription;
  public paymentsModes: any[];
  public submitButton: SubmitButton;

  constructor(
    private titleService: Title,
    private invoiceService: InvoiceService,
    private paymentService: PaymentService,
    private agencyService: AgencyService,
    private payerService: PayerService,
    private thingService: ThingService,
    private submitButtonService: SubmitButtonService,
    private router: Router
  ) {
    this.titleService.setTitle(`Ajout d'un paiement${tabSuffix}`);
    this.initSubscriptions();
  }

  ngOnInit() {
    this.agencyService.getForSelect();
    this.payerService.getForSelect();
    this.thingService.getPaymentsModesSelect();
    this.form = this.paymentService.getForm();
    this.determineSubmitButton();
  }

  ngOnDestroy() {
    this.agenciesSelect$.unsubscribe();
    this.payersSelect$.unsubscribe();
  }

  onCurrentPageDataChange(listOfCurrentPageData: readonly any[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    let invoice = this.invoicesData.find(x => x.id == id);
    if (checked) {
      this.setOfCheckedId.add(id);
      this.setOfCheckedInvoices.add(invoice);
    } else {
      this.setOfCheckedId.delete(id);
      this.setOfCheckedInvoices.delete(invoice);
    }
    this.form.patchValue({
      'invoices': Array.from(this.setOfCheckedId)
    })
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData.filter(({ disabled }) => !disabled);
    this.checked = listOfEnabledData.every(({ id }) => this.setOfCheckedId.has(id));
    this.indeterminate = listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
  }


  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.listOfCurrentPageData
      .filter(({ disabled }) => !disabled)
      .forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  onSelectedAgencyChange(item: any) {
    this.loading = true;
    this.invoiceService.getByAgency(item.value).subscribe(
      (data) => {
        if (data) {
          this.invoicesData = data;
          this.loading = false;
        }
      }
    )
  }

  onSelectedPayerChange(item: any) {
    this.loading = true;
    this.invoiceService.getByPayer(item.value).subscribe(
      (data) => {
        if (data) {
          this.invoicesData = data;
          this.loading = false;
        }
      }
    )
  }

  submitForm() {
    if (this.form.valid) {
      this.paymentService.store(this.form);
    }
  }

  public calcSumSelected() {
    let sum: number = 0.0;
    this.setOfCheckedInvoices.forEach((elem) => {
      sum += parseFloat(elem.balance);
    })
    this.form.patchValue({
      'amount': sum.toFixed(2)
    })
    return sum.toFixed(2);
  }

  public isValidForm() {
    return this.form.valid;
  }

  private initSubscriptions() {
    this.agenciesSelect$ = this.agencyService.agenciesSelect$.subscribe(
      (data) => {
        if (data) {
          this.agencies = data;
        }
      }
    )
    this.payersSelect$ = this.payerService.selectPayers$.subscribe(
      (data) => {
        if (data) {
          this.payers = data;
        }
      }
    )
    this.paymentsModes$ = this.thingService.paymentsModes$.subscribe(
      (data) => {
        if (data) {
          this.paymentsModes = data;
        }
      }
    )
  }

  private determineSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'un paiement',
      text: 'Ajouter',
      icon: faCreditCard
    });
    this.paymentService.setSubmitButton(this.submitButton);
  }

}
