import { MlAgencyService, UserService, RoleService} from '@eros-front/services';
import { Subscription } from 'rxjs';
import { User, SubmitButton } from '@eros-front/models';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'eros-front-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {
  public form: FormGroup;
  public submitButton: SubmitButton;
  public roles$: Subscription;
  public roles: any;
  public mlAgenciesSelect$: Subscription;
  public mlAgencies: any;
  public selectedMlAgencies: any[];

  constructor(
    private titleService: Title,
    private userService: UserService,
    private roleService: RoleService,
    private mlAgencyService: MlAgencyService
  ) {
    this.titleService.setTitle(`Création d'un utilisateur${tabSuffix}`);
  }

  ngOnInit() {
    this.determinateSubmitButton();
    this.initValues();
    this.initSubscriptions();
    this.form = this.userService.getCreateForm();
  }

  private initValues() {
    this.roleService.getAll();
    this.mlAgencyService.getForSelect();
  }

  private initSubscriptions(){
    this.roles$ = this.roleService.roles$.subscribe(
      (data) => {
        if (data) {
          this.roles = data;
          this.addCheckboxes();
        }
    });
    this.mlAgenciesSelect$ = this.mlAgencyService.mlAgenciesSelect$.subscribe(
      (data) => {
        if (data) {
          this.mlAgencies = data;
        }
    });
  }

  get rolesFormArray() {
    return this.form.controls.roles as FormArray;
  }

  private addCheckboxes() {
    this.roles.forEach(() => this.rolesFormArray.push(new FormControl(false)));
  }

  isInvalidField(fieldName: string) {
    const field = this.form.get(fieldName);
    return (field.dirty || field.touched) && field.invalid && field.errors.required;
  }

  generatePassword() {
    let result           = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( var i = 0; i < 6; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    this.form.controls['password'].setValue(result);
  }

  submitForm() {
    if (this.isValidForm()) {
      this.form.value.roles = this.form.value.roles.map((v, i) => v ? this.roles[i].id : null).filter(v => v !== null);
      this.form.value.selectedMlAgencies = this.form.value.selectedMlAgencies.map(x => x.value);
      this.userService.store(this.form);
    }
  }

  isValidForm(): boolean {
    return this.form.valid;
  }

  private determinateSubmitButton() {
    this.submitButton = this.userService.determinateSubmitButton(null, 'l\'utilisateur');
  }

  onColorPickerSelect(color) {
    this.form.controls['color'].setValue(color);
  }
}
