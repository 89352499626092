export class ColorsTool {

  public static getShadeColor(color: string, percent: number): string {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    // eslint-disable-next-line no-mixed-operators
    R = Math.floor(R * (100 + percent) / 100);
    // eslint-disable-next-line no-mixed-operators
    G = Math.floor(G * (100 + percent) / 100);
    // eslint-disable-next-line no-mixed-operators
    B = Math.floor(B * (100 + percent) / 100);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    const RR = ((R.toString(16).length === 1) ? `0${R.toString(16)}` : R.toString(16));
    const GG = ((G.toString(16).length === 1) ? `0${G.toString(16)}` : G.toString(16));
    const BB = ((B.toString(16).length === 1) ? `0${B.toString(16)}` : B.toString(16));

    return `#${RR}${GG}${BB}`;
  }

  public static getRandomHexColor(): string {
    const n = (Math.random() * 0xfffff * 1000000).toString(16);
    return `#${n.slice(0, 6)}`;
  }

  public static getRandomHexColorInPalette(): string {
    const colors = [
      '#e47cd2',
      '#fac813',
      '#8fce00',
      '#ec7a38',
      '#2986cc',
      '#6a329f',
      '#c90076',
    ];
    return colors[Math.floor(Math.random() * (colors.length))];
  }

}
