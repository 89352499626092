import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AffairMarketType } from '@libs/enum/src';
import { Intervention } from '@libs/models/src';

@Component({
  selector: 'app-intervention-edit-headband',
  templateUrl: './intervention-edit-headband.component.html',
  styleUrls: ['./intervention-edit-headband.component.scss']
})
export class InterventionEditHeadbandComponent implements OnInit, OnChanges {
  @Input() intervention: Intervention;
  public affairMarketTypeEnum = AffairMarketType;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  isRenovationMarket() {
    return this.intervention.affair.marketTypeId == this.affairMarketTypeEnum.RENOVATION;
  }

}
