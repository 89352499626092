import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { Router } from '@angular/router';
import { AffairGoal } from '@libs/models/src';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { SelectService } from './utilities/select.service';
import { FormFormatterService } from './utilities/form-formatter.service';

@Injectable()
export class AffairGoalService extends ModelWithDatatableAndCrudObservable {

    public route = '/affairs-goals';
    public selectAffairsMonitoringTypes$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private commonService: CommonService,
        private selectService: SelectService,
        private formFormatterService: FormFormatterService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route);
    }

    public getAll(): Observable<any> {
        return super.getAll(this.route);
    }

    public store(form: any): Observable<any> {
        return super.store(this.formatForm(form), this.route);
    }

    public update(id: number, form: any): Observable<any> {
        return super.update(id, this.formatForm(form), this.route);
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route);
    }

    public getForm(affairGoal?: AffairGoal) {
        const marketsTypes = affairGoal ?
            affairGoal.marketsTypes.map(x => ({ value: x.id, label: x.name })) : [];
        const name = affairGoal ?
            this.commonService.valueToFormString(affairGoal.name) : this.commonService.getDefaultFormStringValue();
        return this.formBuilder.group({
            marketsTypes: [marketsTypes, Validators.required],
            name: [name, Validators.required],
        });
    }

    public getForSelect(): void {
        this.selectService.getForSelect(this.selectAffairsMonitoringTypes$, this.route);
    }

    public getSelectByMarketType(affairsGoals: any, marketTypeId: number) {
        let affairsGoalsSelect = [];
        affairsGoals.forEach((affairGoal: AffairGoal) => {
            if (affairGoal.marketsTypes.find(x => x.id == marketTypeId)) {
                affairsGoalsSelect.push({
                    label: affairGoal.name,
                    value: affairGoal.id
                })
            }
        })
        return affairsGoalsSelect;
    }

    public formatToSelectMultiple(affairsGoals: any) {
        let affairsGoalsSelect = [];
        affairsGoals.forEach((affairGoal: AffairGoal) => {
            affairsGoalsSelect.push({
                label: affairGoal.name,
                value: affairGoal.id
            })
        })
        return affairsGoalsSelect;
    }

    private formatForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.marketsTypes = this.formFormatterService.formatSelectMultipleToIntArray(values.marketsTypes);
        return values;
    }


}
