// Angular Core
import { NgModule } from '@angular/core';

//Angular core
import { CommonModule } from '@angular/common';
// Configs
import { API_URL } from './core.shared';

// Libs
import { UsersModule } from '@eros-front/users';
import { CustomersModule } from '@eros-front/customers';
import { SettingsModule } from '@eros-front/settings';
import { AffairsModule } from '@eros-front/affairs';
import { InvoicesModule } from '@eros-front/invoices';
import { BuildingModule } from '@eros-front/building';
import { PayersModule } from '@eros-front/payers';
import { StatisticsModule } from '@eros-front/statistics';
import { QuotationsModule } from '@eros-front/quotations';
import { PaymentsModule } from '@eros-front/payments';
import { ExpensesAccountsModule } from '@eros-front/expenses-accounts';
import { CustomerRelationshipModule } from '@eros-front/customer-relationship';


@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    UsersModule,
    CustomersModule,
    SettingsModule,
    AffairsModule,
    InvoicesModule,
    BuildingModule,
    PayersModule,
    StatisticsModule,
    InvoicesModule,
    QuotationsModule,
    PaymentsModule,
    ExpensesAccountsModule,
    CustomerRelationshipModule,
  ]
})
export class CoreModule {

  static forRoot(host: string) {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: API_URL,
          useValue: host
        }
      ]
    }
  }
}
