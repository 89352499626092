import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-eros-nz-table-checkboxes',
  templateUrl: './eros-nz-table-checkboxes.component.html',
  styleUrls: ['./eros-nz-table-checkboxes.component.scss']
})
export class ErosNzTableCheckboxesComponent implements OnInit {
  public total: number = 0;
  public pageSize: number = 10;
  public pageIndex: number = 1;
  public isLoading = false;
  public expandSet = new Set<number>();
  public setOfCheckedId = new Set<number>();
  public listOfCurrentPageData: readonly any[] = [];
  public checked = false;
  @Output() checkedIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  constructor() { }

  ngOnInit() {
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
    this.onCheckedIdsChange();
  }

  onCurrentPageDataChange(listOfCurrentPageData: readonly any[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData;
    this.checked = listOfEnabledData.every(({ id }) => this.setOfCheckedId.has(id));
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.listOfCurrentPageData
      .forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  onCheckedIdsChange(): void {
    this.checkedIdsChange.emit(Array.from(this.setOfCheckedId));
  }

}
