// Angular Core
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// Configs
import { environment } from '../environments/environment';

// Routing
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

// Libs
import { CoreModule } from '@eros-front/core';
import { SharedModule } from '@libs/shared/src';
import { AuthGuardService, AuthModule } from '@eros-front/auth';
import { ApiModule } from '@eros-front/api';

// Services
import { BarTypeService } from './inspections/services/bar-type.service';

//Components
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppWithSidebarComponent } from './_layouts/app-with-sidebar/app-with-sidebar.component';
import { AffairShowInterventionsComponent } from './affairs/affair-show/affair-show-interventions/affair-show-interventions.component';
import { InterventionEditInformationsComponent } from './interventions/intervention-edit/intervention-edit-informations/intervention-edit-informations.component';
import { InterventionEditHistoricalComponent } from './interventions/intervention-edit/intervention-edit-historical/intervention-edit-historical.component';
import { InterventionDocumentCustomerImportComponent } from './interventions/intervention-document-customer-import/intervention-document-customer-import.component';
import { InterventionEditDocumentCustomerComponent } from './interventions/intervention-edit/intervention-edit-document-customer/intervention-edit-document-customer.component';
import { InterventionEditShiftModalComponent } from './interventions/intervention-edit/intervention-edit-informations/intervention-edit-shift-modal/intervention-edit-shift-modal.component';
import { InterventionEditDeprogramModalComponent } from './interventions/intervention-edit/intervention-edit-informations/intervention-edit-deprogram-modal/intervention-edit-deprogram-modal.component';
import { InterventionEditProgramModalComponent } from './interventions/intervention-edit/intervention-edit-informations/intervention-edit-program-modal/intervention-edit-program-modal.component';
import { InterventionsTabsComponent } from './interventions/interventions-tabs/interventions-tabs.component';
import { InterventionsDatatableFiltersComponent } from './interventions/interventions-datatable-filters/interventions-datatable-filters.component';
import { InterventionCreateComponent } from './interventions/intervention-create/intervention-create.component';
import { InterventionEditComponent } from './interventions/intervention-edit/intervention-edit.component';
import { AffairShowCostsEstimatesComponent } from './affairs/affair-show/affair-show-costs-estimates/affair-show-costs-estimates.component';
import { AffairShowShuttleSheetsComponent } from './affairs/affair-show/affair-show-shuttle-sheets/affair-show-shuttle-sheets.component';
import { AffairShowMessagesComponent } from './affairs/affair-show/affair-show-messages/affair-show-messages.component';
import { AffairShowInformationsComponent } from './affairs/affair-show/affair-show-informations/affair-show-informations.component';
import { AffairShowComponent } from './affairs/affair-show/affair-show.component';
import { CalendarComponent } from './calendar/calendar.component';
import { EventModalAddEditComponent } from './events/event-modal-add-edit/event-modal-add-edit.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { InterventionModalEditComponent } from './interventions/intervention-modal-edit/intervention-modal-edit.component';
import { InterventionEditProductsComponent } from './interventions/intervention-edit/intervention-edit-informations/intervention-edit-products/intervention-edit-products.component';
import { ContextMenuModule, ContextMenuService } from 'ngx-contextmenu';
import { InterventionModalCreateComponent } from './interventions/intervention-modal-create/intervention-modal-create.component';
import { InterventionProductsComponent } from './interventions/intervention-products/intervention-products.component';
import { InterventionIndicatorsTypesComponent } from './interventions/intervention-indicators-types/intervention-indicators-types.component';
import { InterventionInformationsComponent } from './interventions/intervention-informations/intervention-informations.component';
import { StatisticsVolumesComponent } from './statistics/statistics-volumes/statistics-volumes.component';
import { StatisticFiltersComponent } from './statistics/statistic-filters/statistic-filters.component';
import { StatisticsVolumesRegionsComponent } from './statistics/statistics-volumes/statistics-volumes-regions/statistics-volumes-regions.component';
import { StatisticSettingsComponent } from './statistics/statistic-settings/statistic-settings.component';
import { StatisticsVolumesTechniciansComponent } from './statistics/statistics-volumes/statistics-volumes-technicians/statistics-volumes-technicians.component';
import { StatisticsVolumesProductsComponent } from './statistics/statistics-volumes/statistics-volumes-products/statistics-volumes-products.component';
import { StatisticsRevenuesRegionsComponent } from './statistics/statistics-revenues/statistics-revenues-regions/statistics-revenues-regions.component';
import { StatisticsRevenuesProductsComponent } from './statistics/statistics-revenues/statistics-revenues-products/statistics-revenues-products.component';
import { StatisticsRevenuesTechniciansComponent } from './statistics/statistics-revenues/statistics-revenues-technicians/statistics-revenues-technicians.component';
import { StatisticsRevenuesComponent } from './statistics/statistics-revenues/statistics-revenues.component';
import { StatisticsVolumesMapComponent } from './statistics/statistics-volumes/statistics-volumes-map/statistics-volumes-map.component';
import { StatisticsRevenuesMapComponent } from './statistics/statistics-revenues/statistics-revenues-map/statistics-revenues-map.component';
import { InterventionHousingsComponent } from './interventions/intervention-housings/intervention-housings.component';
import { DashboardSettingsComponent } from './dashboard/dashboard-settings/dashboard-settings.component';
import { InterventionsUnconfirmedListComponent } from './interventions/interventions-dashboard/interventions-unconfirmed-list/interventions-unconfirmed-list.component';
import { InterventionsUncompletedListComponent } from './interventions/interventions-dashboard/interventions-uncompleted-list/interventions-uncompleted-list.component';
import { InterventionsCompletedListComponent } from './interventions/interventions-dashboard/interventions-completed-list/interventions-completed-list.component';
import { InterventionEditHeadbandComponent } from './interventions/intervention-edit/intervention-edit-headband/intervention-edit-headband.component';
import { AffairShowProgramComponent } from './affairs/affair-show/affair-show-program/affair-show-program.component';
import { EventsIndexComponent } from './events/events-index/events-index.component';
import { EventsDatatableFiltersComponent } from './events/events-datatable-filters/events-datatable-filters.component';
import { AffairShowEventsComponent } from './affairs/affair-show/affair-show-events/affair-show-events.component';
import { AngularLaravelEchoModule } from 'angular-laravel-echo';
import { AffairProductsToProgramListComponent } from './interventions/interventions-dashboard/affair-products-to-program-list/affair-products-to-program-list.component';
import { DashboardAffairsVolumeComponent } from './dashboard/dashboard-affairs-volume/dashboard-affairs-volume.component';
import { InterventionsPreparationComponent } from './interventions/interventions-preparation/interventions-preparation.component';
import { InterventionsPreparationFiltersComponent } from './interventions/interventions-preparation/interventions-preparation-filters/interventions-preparation-filters.component';
import { LayoutsModule } from '@libs/layouts/src';
import { LoginLayoutComponent } from './_layouts/login-layout/login-layout.component';
import { EventAffairModalAddEditComponent } from './events/event-affair-modal-add-edit/event-affair-modal-add-edit.component';
import { InterventionEditCancelModalComponent } from './interventions/intervention-edit/intervention-edit-informations/intervention-edit-cancel-modal/intervention-edit-cancel-modal.component';
import { InterventionsIndexComponent } from './interventions/interventions-index/interventions-index.component';
import { NzUploadModule } from "ng-zorro-antd/upload";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzAutocompleteModule } from "ng-zorro-antd/auto-complete";
import { InspectionsCustomerComponent } from './inspections/customer/inspections-customer.component';
import { NzCardModule } from "ng-zorro-antd/card";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { InspectionsGlossaryComponent } from './inspections/glossary/inspections-glossary.component';
import { InspectionsPeriodComponent } from './inspections/period/inspections-period.component';
import { NzResultModule } from "ng-zorro-antd/result";
import { NzGridModule } from "ng-zorro-antd/grid";
import { InspectionsBaseLotComponent } from "./inspections/base-lot/inspections-base-lot/inspections-base-lot.component";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { InspectionsHistoryComponent } from './inspections/history/inspections-history.component';
import { InspectionsPeriodRulesComponent } from './inspections/period/inspections-period-rules/inspections-period-rules.component';
import { InspectionsBetaComponent } from "./inspections/inspections-beta/inspections-beta.component";
import { InspectionsPeriodEditModalComponent } from './inspections/period/inspections-period-edit-modal/inspections-period-edit-modal.component';
import { InspectionsModalCallComponent } from './inspections/customer/inspections-modal-call/inspections-modal-call.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NzProgressModule } from "ng-zorro-antd/progress";
import { IncomingCallSheetComponent } from './incoming-call-sheet/incoming-call-sheet.component';
import { IncomingCallSheetContactInformationsComponent } from './incoming-call-sheet/incoming-call-sheet-contact-informations/incoming-call-sheet-contact-informations.component';
import { IncomingCallSheetEmailsComponent } from './incoming-call-sheet/incoming-call-sheet-emails/incoming-call-sheet-emails.component';
import { IncomingCallSheetAffairsComponent } from './incoming-call-sheet/incoming-call-sheet-affairs/incoming-call-sheet-affairs.component';
import { InterventionTagComponent } from './incoming-call-sheet/intervention-tag/intervention-tag.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { InterventionsListComponent } from './interventions/interventions-list/interventions-list.component';
import { DateService } from './inspections/services/date.service';
import { InspectionsSetCreateComponent } from './inspections/set/create/inspections-set-create.component';
import { InspectionsSetManagerComponent } from './inspections/set/manager/inspections-set-manager.component';
import { ModelesComponent } from './inspections/modeles/modeles.component';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    DashboardSettingsComponent,
    AppWithSidebarComponent,
    AffairShowComponent,
    AffairShowInformationsComponent,
    AffairShowMessagesComponent,
    AffairShowShuttleSheetsComponent,
    AffairShowCostsEstimatesComponent,
    AffairShowInterventionsComponent,
    InterventionEditComponent,
    InterventionCreateComponent,
    InterventionEditInformationsComponent,
    InterventionEditHistoricalComponent,
    InterventionDocumentCustomerImportComponent,
    InterventionEditDocumentCustomerComponent,
    InterventionEditShiftModalComponent,
    InterventionEditDeprogramModalComponent,
    InterventionEditProgramModalComponent,
    InterventionsTabsComponent,
    InterventionsDatatableFiltersComponent,
    CalendarComponent,
    EventModalAddEditComponent,
    EventsIndexComponent,
    InterventionModalEditComponent,
    InterventionEditProductsComponent,
    InterventionModalCreateComponent,
    InterventionProductsComponent,
    InterventionIndicatorsTypesComponent,
    InterventionInformationsComponent,
    InterventionHousingsComponent,
    StatisticsVolumesComponent,
    StatisticFiltersComponent,
    StatisticsVolumesRegionsComponent,
    StatisticsVolumesTechniciansComponent,
    StatisticsVolumesProductsComponent,
    StatisticsVolumesMapComponent,
    StatisticSettingsComponent,
    StatisticsRevenuesComponent,
    StatisticsRevenuesRegionsComponent,
    StatisticsRevenuesProductsComponent,
    StatisticsRevenuesTechniciansComponent,
    StatisticsRevenuesMapComponent,
    InterventionsUnconfirmedListComponent,
    InterventionsUncompletedListComponent,
    InterventionsCompletedListComponent,
    InterventionEditHeadbandComponent,
    AffairShowProgramComponent,
    AffairShowEventsComponent,
    EventsDatatableFiltersComponent,
    AffairProductsToProgramListComponent,
    DashboardAffairsVolumeComponent,
    InterventionsPreparationComponent,
    InterventionsPreparationFiltersComponent,
    LoginLayoutComponent,
    EventAffairModalAddEditComponent,
    InterventionEditCancelModalComponent,
    InterventionsIndexComponent,
    InspectionsCustomerComponent,
    InspectionsGlossaryComponent,
    InspectionsPeriodComponent,
    InspectionsPeriodEditModalComponent,
    InspectionsBaseLotComponent,
    InspectionsHistoryComponent,
    InspectionsPeriodRulesComponent,
    InspectionsBetaComponent,
    InspectionsModalCallComponent,
    IncomingCallSheetComponent,
    IncomingCallSheetContactInformationsComponent,
    IncomingCallSheetEmailsComponent,
    IncomingCallSheetAffairsComponent,
    InterventionTagComponent,
    InterventionsListComponent,
    InspectionsSetCreateComponent,
    InspectionsSetManagerComponent,
    ModelesComponent
  ],
  imports: [
    CoreModule,
    SharedModule,
    LayoutsModule,
    RouterModule.forRoot(AppRoutes),
    AuthModule.forRoot(environment.apiUrl),
    ApiModule.forRoot(environment.apiUrl),
    FullCalendarModule,
    ContextMenuModule,
    AngularLaravelEchoModule,
    NzUploadModule,
    NzSelectModule,
    NzCollapseModule,
    NzDividerModule,
    NzSpinModule,
    NzAutocompleteModule,
    NzCardModule,
    NzAvatarModule,
    NzResultModule,
    NzGridModule,
    NzPaginationModule,
    NzInputNumberModule,
    NgxSpinnerModule,
    NzProgressModule,
    NzInputModule,
  ],
  providers: [
    AuthGuardService,
    ContextMenuService,
    BarTypeService,
    DateService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
