// Angular Core
import { NgModule } from '@angular/core';
import { CDK_DRAG_CONFIG, DragDropModule } from '@angular/cdk/drag-drop';

// Configs
const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000
};

// Libs
import { SharedModule } from '@eros-front/shared';

// Components
import { ShuttleSheetShowComponent } from './shuttle-sheet-show/shuttle-sheet-show.component';
import { ShuttleSheetTemplateShowComponent } from './shuttle-sheet-template-show/shuttle-sheet-template-show.component';
import { ShuttleSheetModalSelectTemplateComponent } from './shuttle-sheet-modal-select-template/shuttle-sheet-modal-select-template.component';
import { ShuttleSheetBuildingTemplateComponent } from './shuttle-sheet-building-template/shuttle-sheet-building-template.component';
import { ShuttleSheetEditSelectValuesButtonComponent } from './shuttle-sheet-edit-select-values-button/shuttle-sheet-edit-select-values-button.component';
import { ShuttleSheetEquipmentTemplateComponent } from './shuttle-sheet-equipment-template/shuttle-sheet-equipment-template.component';
import { ShuttleSheetModalTitleComponent } from './shuttle-sheet-modal-title/shuttle-sheet-modal-title.component';
import { ShuttleSheetModalItemComponent } from './shuttle-sheet-modal-item/shuttle-sheet-modal-item.component';
import { ShuttleSheetModalTemplateComponent } from './shuttle-sheet-modal-template/shuttle-sheet-modal-template.component';
import { ShuttleSheetModalOptionComponent } from './shuttle-sheet-modal-option/shuttle-sheet-modal-option.component';

@NgModule({
  imports: [
    SharedModule,
    DragDropModule
  ],
  declarations: [
    ShuttleSheetShowComponent,
    ShuttleSheetTemplateShowComponent,
    ShuttleSheetModalSelectTemplateComponent,
    ShuttleSheetBuildingTemplateComponent,
    ShuttleSheetEditSelectValuesButtonComponent,
    ShuttleSheetEquipmentTemplateComponent,
    ShuttleSheetModalTitleComponent,
    ShuttleSheetModalItemComponent,
    ShuttleSheetModalTemplateComponent,
    ShuttleSheetModalOptionComponent
  ],
  exports: [
    ShuttleSheetShowComponent,
    ShuttleSheetTemplateShowComponent
  ],
  providers: [
    {
      provide: CDK_DRAG_CONFIG,
      useValue: DragConfig
    },
  ],
})
export class ShuttleSheetsModule { }
