import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { CustomerService, CustomersModalSync } from '@eros-front/services';
import { NgSelectMultipleComponent } from '@eros-front/components';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-customers-consult-graph-modal-sync-prices',
  templateUrl: './customers-consult-graph-modal-sync-prices.component.html',
  styleUrls: ['./customers-consult-graph-modal-sync-prices.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomersConsultGraphModalSyncPricesComponent extends CustomersModalSync implements OnInit, AfterViewInit, OnDestroy {

  @Input() group: any;
  @Input() sources: any;
  @Input() products: any;
  @Input() marketsTypes: any;
  @Input() entities: any;
  @ViewChild('customersConsultGraphSyncPrices', { static: false }) childModal: ModalDirective;
  @ViewChild('consultGraphModalSyncPricesSelectEntities', { static: false }) childSelectEntities: NgSelectMultipleComponent;
  @ViewChild('consultGraphModalSyncPricesSelectMarketsTypes', { static: false }) childSelectMarketsTypes: NgSelectMultipleComponent;
  @ViewChild('consultGraphModalSyncPricesSelectProducts', { static: false }) childSelectProducts: NgSelectMultipleComponent;
  @Output() onModalInitComplete = new EventEmitter<any>();
  @Output() onModalClosed = new EventEmitter<any>();
  private custService: CustomerService;
  public selectedSource: string;
  public selectedEntities: any[];
  public selectedMarketsTypes: any[];
  public selectedProducts: any[];

  constructor(
    customerService: CustomerService
  ) {
    super(customerService, 4);
    this.custService = customerService;
  }

  ngOnInit() {
    this.initValues();
    this.setSelectEntitiesFromSource(this.selectedSource);
    this.initSubscriptions();
  }

  ngAfterViewInit() {
    this.onModalInitComplete.emit(this.childModal);
  }

  ngOnDestroy() {
    this.previewResult$.unsubscribe();
  }

  onModalHidden() {
    this.initValues();
    this.onModalClosed.emit();
  }

  onSelectedSourceChanged() {
    this.selectedEntities = [];
    this.childSelectEntities.clearSelectedItems();
    this.setSelectEntitiesFromSource(this.selectedSource);
    this.getPreviewResult();
  }

  isValidForm() {
    return (this.selectedSource != null
      && this.selectedEntities.length > 0
      && this.selectedMarketsTypes.length > 0
      && this.selectedProducts.length > 0);
  }

  getPreviewResult() {
    if (this.isValidForm()) {
      this.custService.getPreviewOfSyncPrices({
        sourceRef: this.selectedSource,
        entitiesRefs: this.selectedEntities.map(x => x.value),
        marketsTypesIds: this.selectedMarketsTypes.map(x => x.value),
        productsIds: this.selectedProducts.map(x => x.value)
      });
    } else {
      this.previewResult = [];
    }
  }

  onApplyClicked() {

  }

  private initValues() {
    this.selectedSource = this.group.ref;
    this.selectedEntities = [];
    this.selectedMarketsTypes = [];
    this.selectedProducts = [];
    this.selectedStep = 0;
    this.previewResult = [];
    if (this.childSelectEntities) {
      this.childSelectEntities.clearSelectedItems();
    }
    if (this.childSelectMarketsTypes) {
      this.childSelectMarketsTypes.clearSelectedItems();
    }
    if (this.childSelectProducts) {
      this.childSelectProducts.clearSelectedItems();
    }
  }

  private initSubscriptions() {
    this.previewResult$ = this.custService.previewOfSyncPrices$.subscribe(
      (data) => {
        if (data) {
          this.previewResult = data;
        }
      }
    );
  }
}
