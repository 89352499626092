import { ModelWithDatatableAndModalCrud } from './classes/model-datatable-modal-crud';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { Router } from '@angular/router';
import { CommonService } from './utilities/common.service';
import { BuildingType } from '@libs/models/src';
import { ApiService } from '@libs/api/src';

@Injectable()
export class BuildingTypeService extends ModelWithDatatableAndModalCrud {

    public route = '/buildings-types';
    public buildingType$ = new BehaviorSubject<BuildingType>(undefined);
    public buildingsTypes$ = new BehaviorSubject<BuildingType[]>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        apiService: ApiService,
        router: Router,
        private formBuilder: FormBuilder,
        private commonService: CommonService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): void {
        super.get(id, this.buildingType$, this.route);
    }

    public getAll(): void {
        super.getAll(this.buildingsTypes$, this.route);
    }

    public store(form: any): void {
        super.store(form.value, this.route);
    }

    public update(id: number, form: any): void {
        super.update(id, form.value, this.route);
    }

    public delete(id: number): void {
        super.delete(id, this.route);
    }

    public getForm(type: BuildingType) {
        const name = type ?
            this.commonService.valueToFormString(type.name) : this.commonService.getDefaultFormStringValue();
        const prefix = type ?
            this.commonService.valueToFormString(type.prefix) : this.commonService.getDefaultFormStringValue();
        const color = type ?
            this.commonService.valueToFormColor(type.color) : this.commonService.getDefaultFormColorValue();
        return this.formBuilder.group({
            name: [name, Validators.required],
            prefix: [prefix, Validators.required],
            color: [color, Validators.required]
        });
    }
}
