import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class InterventionDocumentCustomerService {

    constructor(
        private apiService: ApiService
    ) {
    }

    import(interventionId: number, documentCustomerId: string, file: File): Observable<any> {
        const formData = new FormData();
        formData.append("documentCustomerId", documentCustomerId);
        formData.append("file", file);
        return this.apiService.importFile('/interventions/' + interventionId + '/documents-customers/import', formData)
    }
}
