import { Component, Input, OnInit } from '@angular/core';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';

@Component({
  selector: 'app-table-datatable-toggle-vis',
  templateUrl: './table-datatable-toggle-vis.component.html',
  styleUrls: ['./table-datatable-toggle-vis.component.scss']
})
export class TableDatatableToggleVisComponent implements OnInit {

  @Input() title = 'Colonnes visibles :';
  @Input() columns: DataTableColumn[];

  constructor() { }

  ngOnInit() {
  }

}
