import { ApiService } from '@eros-front/api';
import { ModelWithDatatableAndModalCrud } from './classes/model-datatable-modal-crud';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotifService } from './utilities/notif.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormFormatterService } from './utilities/form-formatter.service';
import { DataTableColumn } from './classes/model-datatable';
import { Router } from '@angular/router';
import { CommonService } from './utilities/common.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { InterventionStatus } from '@libs/models/src';
import { SelectService } from './utilities/select.service';

@Injectable()
export class InterventionStatusService extends ModelWithDatatableAndCrudObservable {

    public route = '/interventions-statuses'
    public interventionStatus$ = new BehaviorSubject<any>(undefined);
    public interventionsStatuses$ = new BehaviorSubject<any>(undefined);
    public interventionsStatusesSelect$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        apiService: ApiService,
        router: Router,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService,
        private selectService: SelectService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route);
    }

    public getAll(): Observable<any> {
        return super.getAll(this.route);
    }

    public store(form: any): Observable<any> {
        return super.store(this.formatForm(form), this.route);
    }

    public update(id: number, form: any): Observable<any> {
        return super.update(id, this.formatForm(form), this.route);
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route);
    }

    public getForm(status: InterventionStatus) {
        const name = status ?
            this.commonService.valueToFormString(status.name) : this.commonService.getDefaultFormStringValue();
        const labelClass = status ?
            this.commonService.valueToFormSelect(status.labelClass) : this.commonService.getDefaultFormNullValue();
        const googleCalendarColorId = status ?
            this.commonService.valueToFormSelect(status.googleCalendarColorId) : this.commonService.getDefaultFormNullValue();
        const outlookCategory = status ?
            this.commonService.valueToFormSelect(status.outlookCategory) : this.commonService.getDefaultFormNullValue();
        const color = status ?
            this.commonService.valueToFormColor(status.color) : this.commonService.getDefaultFormNullValue();
        const tagColor = status ?
            this.commonService.valueToFormColor(status.tagColor) : this.commonService.getDefaultFormColorValue();
        return this.formBuilder.group({
            name: [name, Validators.required],
            labelClass: [labelClass, Validators.required],
            googleCalendarColorId: [googleCalendarColorId],
            outlookCategory: [outlookCategory],
            color: [color],
            tagColor: [tagColor]
        });
    }

    private formatForm(form: FormGroup) {
        const values = this.formFormatterService.createFormCopy(form);
        values.labelClass = this.formFormatterService.formatSelectSingle(values.labelClass);
        return values;
    }

    public getForSelect() {
        this.selectService.getForSelect(this.interventionsStatusesSelect$, this.route);
    }

}
