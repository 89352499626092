import { ApiService } from '@eros-front/api';
import { DatatableService } from '../utilities/datatable.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { NotifService } from '../utilities/notif.service';
import { SubmitButtonService } from '../utilities/submit-button.service';
import { SubmitButton } from '@eros-front/models';
import { SwalService } from '@eros-front/services';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Injectable()
export class ModelCrud {

    // Variables
    submitButton: SubmitButton;
    dtInstance: DataTables.Api;
    // Modules & Services
    http: HttpClient;
    notifService: NotifService;
    submitButtonService: SubmitButtonService;
    swalService: SwalService;
    datatableService: DatatableService;
    apiService: ApiService;
    router: Router;

    get(id: number, observable$: BehaviorSubject<any>, route: string): void {
        this.apiService.get(route + '/' + id)
            .subscribe(
                (object) => {
                    observable$.next(object);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    getAll(observable$: BehaviorSubject<any>, route: string): void {
        this.apiService.get(route)
            .subscribe(
                (objects) => {
                    observable$.next(objects);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    store(object: any, route: string, redirectUrl?: string): void {
        this.submitButtonService.setDisabled(this.submitButton);
        this.apiService.post(route, object)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
            .subscribe(
                (success) => {
                    if (redirectUrl) {
                        this.router.navigateByUrl(redirectUrl);
                    }
                    this.notifService.showSuccessNotif(success);
                }
            );
    }

    update(id: number, object: any, route: string, redirectUrl?: string): void {
        this.submitButtonService.setDisabled(this.submitButton);
        this.apiService.put(route + '/' + id, object)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
            .subscribe(
                (success) => {
                    if (redirectUrl) {
                        this.router.navigateByUrl(redirectUrl);
                    }
                    this.notifService.showSuccessNotif(success);
                }
            );
    }

    delete(id: number, route: string): void {
        this.apiService.delete(route + '/' + id)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            )
            .subscribe(
                (success) => {
                    this.swalService.showSwalSuccess(success);
                    if (this.dtInstance) {
                        this.dtInstance.draw();
                    }
                }
            );
    }

    setSubmitButton(button: SubmitButton): void {
        this.submitButton = button;
    }

    determinateSubmitButton(object: any, objectName: string): SubmitButton {
        return this.submitButtonService.getSubmitButtonInstance({
            isAdd: !object,
            objectName: objectName,
        });
    }
}
