import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { Agency, Payer, SubmitButton } from '@libs/models/src';
import { AffairProductService, AgencyService, CommonService, FormFormatterService, NotifService, PayerService, SubmitButtonService } from '@libs/services/src';
import { InvoiceService } from '@libs/services/src/lib/invoice.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-affairs-products-to-invoicing-modal',
  templateUrl: './affairs-products-to-invoicing-modal.component.html',
  styleUrls: ['./affairs-products-to-invoicing-modal.component.scss']
})
export class AffairsProductsToInvoicingModalComponent implements OnInit, OnDestroy {

  @Input() productKey: string;
  @Input() affairProducts: any[];
  @Input() mlAgencies: any[];
  @Input() agencies: any[];
  @Input() payers: any[];
  @Input() agencyItem: any;
  @Input() mlAgencyItem: any;
  @Input() payersPreferences: any[];
  public informationsForm: FormGroup;
  private agency$: Subscription;
  private payer$: Subscription;
  private payerForProduct$: Subscription;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private payerService: PayerService,
    private agencyService: AgencyService,
    private formFormatterService: FormFormatterService,
    private submitButtonService: SubmitButtonService,
    private invoiceService: InvoiceService,
    private notifService: NotifService
  ) {
  }

  ngOnInit() {
    this.initSubscriptions();
    this.informationsForm = this.initForm();
    this.setSubmitButton();
    if (this.productKey == 'bih') {
      this.invoiceService.getPayerForProduct(this.productKey);
    } else if (this.agencyItem) {
      this.agencyService.get(this.agencyItem.value);
    }
  }

  ngOnDestroy() {
    this.informationsForm = null;
    this.agency$.unsubscribe();
    this.payer$.unsubscribe();
    this.payerForProduct$.unsubscribe();
  }

  initForm(): FormGroup {
    const defaultString = this.commonService.getDefaultFormStringValue();
    const defaultSelect = this.commonService.getDefaultFormNullValue();
    return this.formBuilder.group({
      mlAgencyId: [this.mlAgencyItem],
      agencyId: [this.agencyItem],
      payerId: defaultSelect,
      payerName: [defaultString, Validators.required],
      payerPreference: [defaultSelect, Validators.required],
      accountingCode: [defaultString, Validators.required],
      payerAddress: [defaultString, Validators.required],
      payerAdditionalAddress: defaultString,
      payerPostalCode: [defaultString, Validators.required],
      payerLocality: [defaultString, Validators.required],
      affairProducts: [this.affairProducts, Validators.required],
      isTemporary: [false, Validators.required]
    });
  }


  onSelectedAgencyChange(value: any) {
    if (this.productKey != 'bih') {
      if (value && value.value) {
        this.agencyService.get(value.value);
        this.informationsForm.patchValue({
          payerId: this.commonService.getDefaultFormNullValue()
        });
      } else {
        this.agencyService.agency$.next(null);
        this.completePayerInformation(null);
      }
    }
  }

  onSelectedPayerChange(value: any) {
    if (this.productKey != 'bih') {
      if (value && value.value) {
        this.payerService.get(value.value);
        this.informationsForm.patchValue({
          agencyId: this.commonService.getDefaultFormNullValue()
        });
      } else {
        this.payerService.payer$.next(null);
        this.completePayerInformation(null);
      }
    }
  }


  private completePayerInformation(entity?: Agency | Payer): void {
    const defaultString = this.commonService.getDefaultFormStringValue();
    if (this.informationsForm) {
      this.informationsForm.patchValue({
        payerName: entity ? entity.name : defaultString,
        payerPreference: entity ? entity.payerPreference : 0,
        accountingCode: entity ? entity.accountingCode : defaultString,
        payerAddress: entity ? entity.address : defaultString,
        payerAdditionalAddress: entity ? entity.additionalAddress : defaultString,
        payerPostalCode: entity ? entity.postalCode : defaultString,
        payerLocality: entity ? entity.locality : defaultString
      });
    }
  }

  onPayerAddressChange(address: Address) {
    this.informationsForm = this.formFormatterService.completeGoogleAddress(
      this.informationsForm, address,
      {
        route: 'payerAddress',
        postal_code: 'payerPostalCode',
        locality: 'payerLocality',
        concatStreetWithRoute: true
      }
    );
  }

  private initSubscriptions() {
    this.agency$ = this.agencyService.agency$.subscribe(
      (data) => {
        if (data) {
          this.completePayerInformation(data);
        }
      }
    );
    this.payer$ = this.payerService.payer$.subscribe(
      (data) => {
        if (data) {
          this.completePayerInformation(data);
        }
      }
    );
    this.payerForProduct$ = this.invoiceService.payerForProduct$.subscribe(
      (data) => {
        if (data) {
          this.completePayerInformation(data);
          if (this.informationsForm) {
            this.informationsForm.patchValue({
              payerId: data.id
            });
          }
        }
      }
    )
  }

  isValidForm() {
    return this.informationsForm.valid;
  }

  submitForm(draftMode?: boolean) {
    if (this.isValidForm()) {
      if (draftMode) {
        this.informationsForm.patchValue({ 'isTemporary': true })
      }
      this.invoiceService.storeForAffairProducts(this.informationsForm).subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        }
      )
    }
  }

  private setSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'une facture',
      text: 'Générer',
      icon: faCreditCard
    });
    this.invoiceService.setSubmitButton(this.submitButton);
  }

  public isReadonlyProperty() {
    return this.productKey == 'bih';

  }
}
