import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-invoices-datatable-filters',
  templateUrl: './invoices-datatable-filters.component.html',
  styleUrls: ['./invoices-datatable-filters.component.scss']
})
export class InvoicesDatatableFiltersComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() showCheckboxes = false;
  @Input() showDateInput = false;
  @Input() agencies: any[];
  @Input() status: any[];
  @Input() products: any[];
  @Input() mlAgencies: any[];

  constructor() { }

  ngOnInit() {
  }

}
