import { Component, Input, OnInit } from '@angular/core';
import { StudyOffice, SubmitButton } from '@libs/models/src';
import { NotifService } from '@libs/services/src';
import { StudyOfficeService } from '@libs/services/src/lib/study-office.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-studies-offices-modal',
  templateUrl: './studies-offices-modal.component.html',
  styleUrls: ['./studies-offices-modal.component.scss']
})
export class StudiesOfficesModalComponent implements OnInit {
  @Input() form: any;
  @Input() studyOffice: StudyOffice;
  @Input() marketsTypes: any;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private studyOfficeService: StudyOfficeService,
    private notifService: NotifService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.studyOfficeService.setSubmitButton(this.submitButton);
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.studyOffice) {
        this.studyOfficeService.update(this.studyOffice.id, this.form).subscribe((success) => {
          this.modal.close();
          this.notifService.showSuccessNotif(success);
        });
      } else {
        this.studyOfficeService.store(this.form).subscribe((success) => {
          this.modal.close();
          this.notifService.showSuccessNotif(success);
        });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.studyOfficeService.determinateSubmitButton(this.studyOffice, 'le bureau d\'étude');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
