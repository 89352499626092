import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AsyncSubject, BehaviorSubject } from 'rxjs';
import { NotifService } from './utilities/notif.service';
import { DataTableColumn } from './classes/model-datatable';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { FormFormatterService } from './utilities/form-formatter.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { ModelWithDatatableAndModalCrud } from './classes/model-datatable-modal-crud';
import { SelectService } from './utilities/select.service';
import { MlAgency } from '@eros-front/models';
import { CommonService } from './utilities/common.service';

@Injectable()
export class MlAgencyService extends ModelWithDatatableAndModalCrud {

    private route = '/ml-agencies';
    public mlAgency$ = new BehaviorSubject<any>(undefined);
    public mlAgencies$ = new BehaviorSubject<any>(undefined);
    public mlAgenciesSelect$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        apiService: ApiService,
        router: Router,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private selectService: SelectService,
        private commonService: CommonService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): void {
        super.get(id, this.mlAgency$, this.route);
    }

    public getAll(): void {
        super.getAll(this.mlAgencies$, this.route);
    }

    public getForSelect(): void {
        this.selectService.getForSelect(this.mlAgenciesSelect$, this.route);
    }

    public store(form: any): void {
        super.store(this.formatForm(form), this.route);
    }

    public update(id: number, form: any): void {
        super.update(id, this.formatForm(form), this.route);
    }

    public delete(id: number): void {
        super.delete(id, this.route);
    }

    public getForm(mlAgency?: MlAgency): FormGroup {
        const regionId = mlAgency ?
            this.commonService.valueToFormSelect(mlAgency.regionId) : this.commonService.getDefaultFormNullValue();
        const ref = mlAgency ?
            this.commonService.valueToFormString(mlAgency.ref) : this.commonService.getDefaultFormNullValue();
        const name = mlAgency ?
            this.commonService.valueToFormString(mlAgency.name) : this.commonService.getDefaultFormNullValue();
        const personalNumber = mlAgency ?
            this.commonService.valueToFormString(mlAgency.personalNumber) : this.commonService.getDefaultFormNullValue();
        const address = mlAgency ?
            this.commonService.valueToFormString(mlAgency.address) : this.commonService.getDefaultFormNullValue();
        const postalCode = mlAgency ?
            this.commonService.valueToFormString(mlAgency.postalCode) : this.commonService.getDefaultFormNullValue();
        const locality = mlAgency ?
            this.commonService.valueToFormString(mlAgency.locality) : this.commonService.getDefaultFormNullValue();
        const phone = mlAgency ?
            this.commonService.valueToFormString(mlAgency.phone) : this.commonService.getDefaultFormNullValue();
        const email = mlAgency ?
            this.commonService.valueToFormString(mlAgency.email) : this.commonService.getDefaultFormNullValue();
        const rcs = mlAgency ?
            this.commonService.valueToFormString(mlAgency.rcs) : this.commonService.getDefaultFormNullValue();
        const siret = mlAgency ?
            this.commonService.valueToFormString(mlAgency.siret) : this.commonService.getDefaultFormNullValue();
        const paymentAddress = mlAgency ?
            this.commonService.valueToFormString(mlAgency.paymentAddress) : this.commonService.getDefaultFormNullValue();
        const paymentPostalCode = mlAgency ?
            this.commonService.valueToFormString(mlAgency.paymentPostalCode) : this.commonService.getDefaultFormNullValue();
        const paymentLocality = mlAgency ?
            this.commonService.valueToFormString(mlAgency.paymentLocality) : this.commonService.getDefaultFormNullValue();
        const isCollective = mlAgency ?
            this.commonService.valueToRadioFormString(mlAgency.isCollective) : this.commonService.getDefaultFormRadioValue();
        const color = mlAgency ?
            this.commonService.valueToFormColor(mlAgency.color) : this.commonService.getDefaultFormColorValue();
        return this.formBuilder.group({
            regionId: [regionId, Validators.required],
            ref: [ref, Validators.required],
            name: [name, Validators.required],
            personalNumber: [personalNumber],
            address: [address, Validators.required],
            postalCode: [postalCode, Validators.required],
            locality: [locality, Validators.required],
            phone: [phone, Validators.required],
            email: [email, Validators.required],
            rcs: [rcs, Validators.required],
            siret: [siret, Validators.required],
            paymentAddress: [paymentAddress, Validators.required],
            paymentPostalCode: [paymentPostalCode, Validators.required],
            paymentLocality: [paymentLocality, Validators.required],
            isCollective: [isCollective, Validators.required],
            color: [color, Validators.required]
        });
    }

    private formatForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.regionId = this.formFormatterService.formatSelectSingle(values.regionId);
        return values;
    }
}
