import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AffairYnergie } from '@libs/models/src';
import { AffairYnergieService } from '@libs/services/src/lib/affairs/affair-ynergie.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AffairYnergieCreateModalComponent } from '../affair-ynergie-create-modal/affair-ynergie-create-modal.component';

@Component({
  selector: 'app-affairs-ynergie-list',
  templateUrl: './affairs-ynergie-list.component.html',
  styleUrls: ['./affairs-ynergie-list.component.scss']
})
export class AffairsYnergieListComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public affairsData: any[];
  public total = 0;
  public pageSize = 10;
  public pageIndex = 1;
  public isLoading = false;
  public isDestroyed$ = new Subject<void>();

  constructor(
    private affairYnergieService: AffairYnergieService,
    private modalService: NzModalService,
  ) { }

  ngOnInit() {
    this.form = this.affairYnergieService.initFiltersForm();
    this.initList();
  }

  ngOnDestroy(): void {
    this.isDestroyed$?.next();
    this.isDestroyed$?.complete();

  }

  onQueryParamsChange(params: NzTableQueryParams) {
    this.pageIndex = params.pageIndex;
    this.pageSize = params.pageSize;
    this.form.patchValue({
      page: this.pageIndex,
      size: this.pageSize,
    })
  }

  initList() {
    this.affairYnergieService.list(this.form)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(response => {
        this.affairsData = response.data;
        this.total = response.info.count;
      })
  }

  onCreateModalClicked(affairYnergie: AffairYnergie) {
    this.modalService.create({
      nzContent: AffairYnergieCreateModalComponent,
      nzComponentParams: {
        affairYnergie: affairYnergie
      },
      nzStyle: { top: '2vh' },
    })
  }

}
