import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { AgencyService, BrandService, CustomerService, GroupService, NotifService, SelectService } from '@libs/services/src';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, zip } from 'rxjs';
import { tabSuffix } from '_config/tab-suffix';

type TAB_TYPE = 'INFO_GEN' | 'INVOICING' | 'PRICING' | 'CONTACTS' | 'ORGANIZATION_CHART' | 'OFFICE_FILE' | 'SHUTTLE_SHEET' |'SYNC';

@Component({
  selector: 'app-customers-show',
  templateUrl: './customers-show.component.html',
  styleUrls: ['./customers-show.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomersShowComponent implements OnInit, OnDestroy {
  public appKey: string;
  public customer: any;
  private customer$: Subscription;
  public entities: any[] = [];
  private entities$: Subscription;
  public products = [];
  public companies = [];
  public mlAgencies = [];
  public assistants = [];
  public technicians = [];
  public marketsTypes = [];
  public customersTypes = [];
  public indicatorsCustomers = [];
  public paymentDeadlines = [];
  public paymentDeadlineTypes = [];
  public billingPreferences = [];
  public payerPreferences = [];
  public librariesBuilder = [];
  public professions = [];
  public mlSocieties = [];
  public contacts: any;
  public payers: any[] = [];
  private payers$: Subscription;
  public informationsForm: FormGroup;
  public invoicingForm: FormGroup;
  public pricesForm: FormGroup;
  private group$: Subscription;
  public group: any;
  public faHome = faHome;
  public activeTab: TAB_TYPE = 'INFO_GEN';
  public activeTabIndex: number = 0;
  private zip$: Subscription;
  public contactSearch: string = '';

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private selectService: SelectService,
    private customerService: CustomerService,
    private groupService: GroupService,
    private brandService: BrandService,
    private agencyService: AgencyService,
    private spinner: NgxSpinnerService,
    private notifService: NotifService,
    //public cdRef: ChangeDetectorRef
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.spinner.show('load-customer');
      this.customerService.getEntityByCustomerRef(params.get('id'));
      this.customerService.getSelectsForConsultCustomers();
      this.customerService.getGroupByCustomerRef(params.get('id'));

      const contactSearch = params.get('contactSearch');
      if (contactSearch) {
        this.activeTab = 'CONTACTS';
        this.activeTabIndex = 3;
        this.contactSearch = contactSearch;
      }
    });
    this.route.data.subscribe(data => {
      this.appKey = data.appKey;
    })
  }

  ngOnDestroy() {
    this.customer$.unsubscribe();
    this.entities$.unsubscribe();
    this.payers$.unsubscribe();
    this.group$.unsubscribe();
    this.zip$.unsubscribe();
    this.customerService.reset();
  }

  /*ngAfterViewInit() {
    this.cdRef.detectChanges();
  }*/

  isGroup() {
    if (this.customer != null && this.customer.hasOwnProperty('ref')) {
      return this.customer.ref.includes('-00-00');
    }
    return false;
  }

  isBrand() {
    if (this.customer != null && this.customer.hasOwnProperty('ref')) {
      return !this.isGroup() && this.customer.ref.includes('-00');
    }
    return false;
  }

  isAgency() {
    if (this.customer != null && this.customer.hasOwnProperty('ref')) {
      return !this.isGroup() && !this.isBrand();
    }
    return false;
  }

  onTabChanged(event) {
    this.activeTab = event;
  }

  isInformationsFormValid() {
    return this.informationsForm.valid;
  }

  isInvoicingFormValid() {
    return this.invoicingForm.valid;
  }

  isPricesFormValid() {
    return this.pricesForm.valid;
  }

  onSaveClicked() {
    if (this.isInformationsFormValid()) {
      if (this.isGroup()) {
        this.groupService.update(this.customer.id, this.informationsForm).subscribe(
          (success) => {
            this.refreshAfterUpdate(success);
          }
        );
      } else if (this.isBrand()) {
        this.brandService.update(this.customer.id, this.informationsForm).subscribe(
          (success) => {
            this.refreshAfterUpdate(success);
          }
        );
      } else if (this.isAgency()) {
        this.agencyService.update(this.customer.id, this.informationsForm).subscribe(
          (success) => {
            this.refreshAfterUpdate(success);
          }
        );
      }
    }
  }

  onSaveInvoicingInformations() {
    if (this.isInvoicingFormValid()) {
      this.agencyService.updateInvoicingInformations(this.customer.id, this.invoicingForm);
    }
  }

  onSavePrices() {
    if (this.isPricesFormValid()) {
      if (this.isGroup()) {
        this.groupService.updatePrices(this.customer.id, this.pricesForm).subscribe(
          (success) => {
            this.refreshAfterUpdate(success);
          }
        );
      } else if (this.isBrand()) {
        this.brandService.updatePrices(this.customer.id, this.pricesForm).subscribe(
          (success) => {
            this.refreshAfterUpdate(success);
          }
        );
      } else if (this.isAgency()) {
        this.agencyService.updatePrices(this.customer.id, this.pricesForm).subscribe(
          (success) => {
            this.refreshAfterUpdate(success);
          }
        );
      }
    }
  }

  refreshAfterUpdate(success: any) {
    this.notifService.showSuccessNotif(success);
    this.customerService.refreshEntityByCustomerRef(this.customer.ref);
  }

  private initSubscriptions() {
    this.customer$ = this.customerService.refreshEntityByCustomerRef$.subscribe(
      (entity: any) => {
        if (entity) {
          this.customer = entity;
          this.contacts = this.customer.contacts;
          this.initInformationsForm();
          this.initInvoicingForm();
          this.initPricesForm();
          this.customerService.initMlSocietiesCheckboxes(this.informationsForm, this.mlSocieties, this.customer);
        }
      }
    );
    this.payers$ = this.selectService.selectPayersByAgency$.subscribe(
      (payers) => {
        if (payers) {
          this.payers = payers;
        }
      }
    );

    this.zip$ = zip(
      this.customerService.entityByCustomerRef$,
      this.customerService.selects$
    ).subscribe(
      ([entity, selects]) => {
        if (entity && selects) {
          this.customer = entity;
          this.customerService.getEntitiesFromSourceRef(entity.ref);
          this.contacts = this.customer.contacts;
          if (this.isAgency()) {
            this.selectService.getPayersByAgencySelect(entity.id);
          }
          this.customerService.getGroupByCustomerRef(this.customer.ref);
          this.spinner.hide('load-customer');

          this.products = selects.products;
          this.companies = selects.mlCompanies;
          this.mlAgencies = selects.mlAgencies;
          this.assistants = selects.assistants;
          this.technicians = selects.technicians;
          this.marketsTypes = this.marketsAndCustomersTypesDataToArray(selects.marketsTypes);
          this.customersTypes = this.marketsAndCustomersTypesDataToArray(selects.customersTypes);
          this.indicatorsCustomers = this.indicatorsCustomersDataToArray(selects.indicatorsCustomers);
          this.paymentDeadlines = selects.paymentDeadlines;
          this.paymentDeadlineTypes = selects.paymentDeadlineTypes;
          this.billingPreferences = selects.billingPreferences;
          this.payerPreferences = selects.payerPreferences;
          this.librariesBuilder = selects.librariesBuilder;
          this.professions = selects.professions;
          this.mlSocieties = selects.mlSocieties;
          this.initInformationsForm();
          this.initInvoicingForm();
          this.initPricesForm();
          this.titleService.setTitle(`Gestion client : ${entity.name}${tabSuffix}`);
        }
      }
    );
    this.entities$ = this.customerService.entitiesBySourceRef$.subscribe(
      (data) => {
        if (data) {
          this.entities = this.entitiesDataToArray(data);
        }
      }
    );
    this.group$ = this.customerService.groupByCustomerRef$.subscribe(
      (data) => {
        if (data) {
          this.group = data;
        }
      }
    );
  }

  private initInformationsForm(): void {
    if (this.isGroup()) {
      this.informationsForm = this.groupService.getInformationsForm(this.customer);
    } else if (this.isBrand()) {
      this.informationsForm = this.brandService.getInformationsForm(this.customer);
    } else if (this.isAgency()) {
      this.informationsForm = this.agencyService.getInformationsForm(this.customer);
    }
    this.customerService.initMlSocietiesCheckboxes(this.informationsForm, this.mlSocieties, this.customer);
  }

  private initPricesForm(): void {
    this.pricesForm = this.customerService.getPricesForm();
  }

  private initInvoicingForm(): void {
    if (this.isAgency()) {
      this.invoicingForm = this.agencyService.getInvoicingForm(this.customer);
      this.agencyService.initPayersFormArray(this.invoicingForm, this.customer);
    } else {
      this.invoicingForm = null;
    }
  }

  private marketsAndCustomersTypesDataToArray(data: any[]): any[] {
    const result = [];
    for (const item of data) {
      result.push({
        value: item.id,
        label: item.name,
        icon: 'tll fa-' + item.tllIcon
      });
    }
    return result;
  }

  private indicatorsCustomersDataToArray(data: any[]): any[] {
    const result = [];
    for (const item of data) {
      result.push({
        value: item.id,
        label: item.name,
        icon: item.svgIcon
      });
    }
    return result;
  }

  private entitiesDataToArray(data: any): any[] {
    const result = [];
    if (data.hasOwnProperty('brands')) {
      for (const item of data.brands) {
        result.push(
          this.getSelectItemWithIcon(item, 'far fa-copyright')
        );
      }
    }
    if (data.hasOwnProperty('agencies')) {
      for (const item of data.agencies) {
        result.push(
          this.getSelectItemWithIcon(item, 'fas fa-home', ', ' + item.postalCode + ' ' + item.locality)
        );
      }
    }
    return result;
  }

  private getSelectItemWithIcon(item: any, icon: string, additionalName?: string): any {
    let label = item.name;
    if (additionalName != null) {
      label += additionalName;
    }
    return {
      value: item.ref,
      label: label,
      icon: icon
    };
  }

}
