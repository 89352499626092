import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { SubmitButtonComponent } from '@libs/components/src';
import { Affair, SubmitButton } from '@libs/models/src';
import { NotifService, SubmitButtonService } from '@libs/services/src';
import { AffairDocumentCustomerService } from '@libs/services/src/lib/affair-document-customer.service';
import { AffairDocumentDeliveredService } from '@libs/services/src/lib/affair-document-delivered.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-affair-show-modal-identify-document',
  templateUrl: './affair-show-modal-identify-document.component.html',
  styleUrls: ['./affair-show-modal-identify-document.component.scss']
})
export class AffairShowModalIdentifyDocumentComponent implements OnInit {
  @Input() documents: any[];
  @Input() affair: Affair;
  @Input() form: FormGroup;
  public affairProductsSelect: any[] = [];
  public documentsSelect: any[] = [];
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private submitButtonService: SubmitButtonService,
    private affairDocumentCustomerService: AffairDocumentCustomerService,
    private notifService: NotifService
  ) { }

  ngOnInit() {
    this.affair.affairProducts.forEach((affairProduct) => {
      this.affairProductsSelect.push({
        label: affairProduct.product.name,
        value: affairProduct.id
      })

      affairProduct.product.documentsCustomers.forEach((document) => {
        this.documentsSelect.push({
          label: document.name,
          value: document.id
        })
      });
    });
    this.setSubmitButton();
  }

  public isValidForm() {
    return this.form.valid;
  }

  public submitForm() {
    if (this.isValidForm()) {
      this.affairDocumentCustomerService.identify(this.affair.id, this.form).subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        })
    }
  }

  private setSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'les documents',
      text: 'Importer',
      icon: faLink
    });
    this.affairDocumentCustomerService.setIdentifySubmitButton(this.submitButton);
  }



}
