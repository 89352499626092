import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AffairMarketType } from '@libs/enum/src';
import { Affair, Intervention } from '@libs/models/src';
import { InterventionService } from '@libs/services/src';

@Component({
  selector: 'app-intervention-informations',
  templateUrl: './intervention-informations.component.html',
  styleUrls: ['./intervention-informations.component.scss']
})
export class InterventionInformationsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() intervention: Intervention
  @Input() affair: Affair
  @Input() users: any;
  @Input() worksSupervisors: any;
  @Input() contacts: any;
  @Input() conformities: any;
  @Input() affairsContacts: any;
  @Input() affairsStatuses: any;
  @Input() editMode: boolean = false;
  public marketTypeEnum = AffairMarketType;

  constructor(
    private interventionService: InterventionService
  ) { }

  ngOnInit() {
  }

  public onSelectedContactsChanged() {
    this.interventionService.getGeneratedDescription(this.form);
  }

  public onSelectedAffairsContactsChanged() {
    this.interventionService.getGeneratedDescription(this.form);
  }

  public onSelectWorksSupervisorChanged() {
    this.interventionService.getGeneratedDescription(this.form);
  }

  public onIsWaitingCheckChanged() {
    this.interventionService.getGeneratedDescription(this.form);
  }

  public onIsDeliveredHouseChanged() {
    this.interventionService.getGeneratedName(this.form);
  }

  public isRenovationMarket() {
    return this.intervention.affair.marketTypeId == this.marketTypeEnum.RENOVATION
  }

  public onTimeSlotChange() {
    this.interventionService.getGeneratedDescription(this.form);
  }

  public isTimeSlot() {
    if (this.intervention) {
      return this.intervention.isTimeSlot;
    }
    return false;
  }

  public onDoNotShiftChanged(): void {
    this.interventionService.getGeneratedName(this.form);
    if (!this.isDoNotShiftChecked()) {
      this.form.patchValue({
        doNotShiftReason: ''
      });
    }
  }

  public onDoNotShiftReasonChange(): void {
    this.interventionService.getGeneratedDescription(this.form);
  }

  public isDoNotShiftChecked(): boolean {
    return this.form.value.doNotShift;
  }

  public onRecoverCheckChecked(): void {
    this.interventionService.getGeneratedDescription(this.form);
  }

  public onIsPreparedChecked(): void {
    this.interventionService.getGeneratedName(this.form);
  }
}
