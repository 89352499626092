import Swal from 'sweetalert2';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { CommonService, SwalService } from '@libs/services/src';
import { InvoiceDetailService } from '@libs/services/src/lib/invoice-detail.service';
import { Subscription } from 'rxjs';
import { Invoice, InvoiceDetail } from '@libs/models/src';

@Component({
  selector: 'app-invoice-show-products',
  templateUrl: './invoice-show-products.component.html',
  styleUrls: ['./invoice-show-products.component.scss']
})
export class InvoiceShowProductsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() invoice: Invoice;
  @Input() products = [];
  @Input() vatRates = [];
  public invoiceDetails: InvoiceDetail[] = [];
  public invoiceDetailsFiltered: InvoiceDetail[] = [];
  public detail = null;
  private detail$: Subscription;
  public inputSearchValue = '';
  public total = 0;
  public searchableFields: string[];

  constructor(
    private invoiceDetailService: InvoiceDetailService,
    private commonService: CommonService,
    private swalService: SwalService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.setInvoiceDetails();
    this.searchableFields = ['finalCustomerLastname', 'finalCustomerLastnameSecondary', 'finalCustomerFirstname', 'finalCustomerFirstnameSecondary',
      'mlRef', 'ref', 'designation'];
  }

  ngOnChanges(changes: any) {
    if (changes['invoice'] && changes['invoice'].previousValue != changes['invoice'].currentValue) {
      this.setInvoiceDetails();
    }
  }

  ngOnDestroy() {
    this.detail$.unsubscribe();
  }

  onAddProductClicked() {
    this.detail = null;
    this.invoiceDetailService.showModalAddEdit();
  }

  onEditProductClicked(id: number) {
    this.invoiceDetailService.get(id);
  }

  onDeleteProductClicked(id: number) {
    const swalOptions = this.swalService.getSwalDeleteOptions({
      text: 'Le produit sera supprimé de manière irréversible.'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.invoiceDetailService.deleteAndRefresh(this.invoice.id, id);
      }
    });
  }

  formatNumberToLocale(number: number) {
    return this.commonService.formatNumberToLocale(number);
  }

  onFilteredData(data): void {
    this.invoiceDetailsFiltered = data;
  }

  private initSubscriptions() {
    this.detail$ = this.invoiceDetailService.detail$.subscribe(
      (data) => {
        if (data) {
          this.detail = data;
          this.invoiceDetailService.showModalAddEdit();
        }
      }
    );
  }

  private setInvoiceDetails() {
    if (this.invoice.invoiceDetails) {
      this.invoiceDetails = this.invoice.invoiceDetails;
      this.invoiceDetailsFiltered = this.invoiceDetails;
    }
  }

}
