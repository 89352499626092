import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SubmitButton } from '@libs/models/src';
import { FormFormatterService, NotifService, PayerService, SelectService } from '@libs/services/src';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-payers-modal-add',
  templateUrl: './payers-modal-add.component.html',
  styleUrls: ['./payers-modal-add.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PayersModalAddComponent implements OnInit {

  @Input() agencyId: number;
  @ViewChild('PayersModalAdd', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public buttonAddPayer: SubmitButton = {
    text: 'Ajouter le payeur',
    originalText: 'Ajouter le payeur',
    icon: faPlus,
    originalIcon: faPlus,
    enabled: true
  };
  @Output() modalClosed = new EventEmitter<any>();

  constructor(
    private payerService: PayerService,
    private formFormatterService: FormFormatterService,
    private selectService: SelectService,
    private notifService: NotifService,
    private modal: NzModalRef
  ) { }

  ngOnInit() {
    this.form = null;
    this.initFormValues();
  }

  onAddressChanged(address: Address) {
    this.form = this.formFormatterService.completeGoogleAddress(
      this.form, address,
      {
        street_number: 'streetNumber',
        route: 'address',
        postal_code: 'postalCode',
        locality: 'locality'
      }
    );
  }

  submitPayerForm() {
    if (this.isValidForm()) {
      this.payerService.store(this.form, this.buttonAddPayer).subscribe(
        (success) => {
          this.selectService.getPayersByAgencySelect(this.form.value.agencyId);
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        }
      );
    }
  }

  private initFormValues() {
    this.form = this.payerService.getForm();
    this.form.addControl('agencyId', new FormControl(this.agencyId, Validators.required));
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
