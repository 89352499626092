import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ToggleService {

 // Quickview Toggle
  private quickViewToggle$ = new Subject();
  quickViewToggle = this.quickViewToggle$.asObservable();

  private contactMode = new Subject<boolean>();
  contactMode$ = this.contactMode.asObservable();


  toggleQuickView() {
    this.quickViewToggle$.next();
  }

  getContactMode() {
    this.contactMode.next();
  }

  setContactMode(value) {
    this.contactMode.next(value);
  }
}
