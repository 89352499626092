import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { DragDropService } from '@libs/services/src';

@Component({
  selector: 'app-settings-products-products-modal-fields',
  templateUrl: './settings-products-products-modal-fields.component.html',
  styleUrls: ['./settings-products-products-modal-fields.component.scss']
})
export class SettingsProductsProductsModalFieldsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() fieldsItems: any;
  public fieldsItemsArray = [];

  constructor(
    private dragDropService: DragDropService
  ) { }

  ngOnInit() {
    //Create deep copy
    this.fieldsItemsArray = JSON.parse(JSON.stringify(this.fieldsItems));
  }

  get fieldsControls(): FormArray {
    return this.form.get("fields") as FormArray;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      this.dragDropService.moveItemInFormArray(
        this.fieldsControls,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      const formGroupItem = this.getFormGroupItem(event.previousContainer.data, event.previousIndex);
      this.dragDropService.copyItemInFormArray(event.previousContainer.data,
        this.fieldsControls,
        event.previousIndex,
        event.currentIndex,
        formGroupItem,
        this.fieldsItemsArray)
    }
  }

  private getFormGroupItem(
    data: any,
    fromIndex: number): FormGroup {
    const item = data[fromIndex];
    return new FormGroup({
      id: new FormControl(item.id),
      name: new FormControl(item.name),
      isRequired: new FormControl(false)
    });
  }

  onDeleteSelectElementClicked(index: number) {
    const item = this.fieldsControls.at(index);
    this.fieldsControls.removeAt(index);
    this.fieldsItemsArray.push(item.value);
  }
}
