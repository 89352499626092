// Angular Core
import { NgModule } from '@angular/core';

// Libs
import { SharedModule } from '@eros-front/shared';


import { CommonModule } from '@angular/common';

//Components
import { QuotationsIndexComponent } from './quotations-index/quotations-index.component';
import { QuotationsDatatableFiltersComponent } from './quotations-datatable-filters/quotations-datatable-filters.component';
import { QuotationManualCreateComponent } from './quotation-manual-create/quotation-manual-create.component';
import { QuotationFormAffairComponent } from './quotation-form/quotation-form-affair/quotation-form-affair.component';
import { QuotationShowComponent } from './quotation-show/quotation-show.component';
import { QuotationShowInformationsComponent } from './quotation-show/quotation-show-informations/quotation-show-informations.component';
import { QuotationShowProductsComponent } from './quotation-show/quotation-show-products/quotation-show-products.component';
import { QuotationShowProductsModalComponent } from './quotation-show/quotation-show-products/quotation-show-products-modal/quotation-show-products-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    QuotationsIndexComponent,
    QuotationsDatatableFiltersComponent,
    QuotationManualCreateComponent,
    QuotationFormAffairComponent,
    QuotationShowComponent,
    QuotationShowInformationsComponent,
    QuotationShowProductsComponent,
    QuotationShowProductsModalComponent
  ]
})
export class QuotationsModule { }
