// Angular Core
import { NgModule } from '@angular/core';

// Libs
import { SharedModule } from '@eros-front/shared';
import { PayersModule } from '@eros-front/payers';

// Components
import { AffairShowInvoicesComponent } from './affair-show/affair-show-invoices/affair-show-invoices.component';
import { AffairShowInvoicesInvoicingPreferencesComponent } from './affair-show/affair-show-invoices/affair-show-invoices-invoicing-preferences/affair-show-invoices-invoicing-preferences.component';
import { AffairShowInvoicesGenerateComponent } from './affair-show/affair-show-invoices/affair-show-invoices-generate/affair-show-invoices-generate.component';
import { AffairsIndividualHouseIndexComponent } from './affairs-individual-house-index/affairs-individual-house-index.component';
import { AffairsTabsComponent } from './affairs-tabs/affairs-tabs.component';
import { AffairsDatatableFiltersComponent } from './affairs-datatable-filters/affairs-datatable-filters.component';
import { AffairsCollectiveIndexComponent } from './affairs-collective-index/affairs-collective-index.component';
import { AffairsRenovationIndexComponent } from './affairs-renovation-index/affairs-renovation-index.component';
import { AffairCreateComponent } from './affair-create/affair-create.component';
import { AffairsMylinkIndexComponent } from './affairs-mylink-index/affairs-mylink-index.component';
import { AffairShowDocumentsCustomersComponent } from './affair-show/affair-show-documents-customers/affair-show-documents-customers.component';
import { AffairShowDocumentFiltersComponent } from './affair-show/affair-show-modules/affair-show-document-filters/affair-show-document-filters.component';
import { AffairShowModalRenameDocumentComponent } from './affair-show/affair-show-modules/affair-show-modal-rename-document/affair-show-modal-rename-document.component';
import { AffairShowDocumentsComponent } from './affair-show/affair-show-modules/affair-show-documents/affair-show-documents.component';
import { AffairDocumentCustomerImportComponent } from './affair-document-customer-import/affair-document-customer-import.component';
import { AffairDocumentDeliveredImportComponent } from './affair-document-delivered-import/affair-document-delivered-import.component';
import { AffairDocumentImportComponent } from './affair-document-import/affair-document-import.component';
import { AffairDocumentDropzonesComponent } from './affair-document-dropzones/affair-document-dropzones.component';
import { AffairShowContactsComponent } from './affair-show/affair-show-contacts/affair-show-contacts.component';
import { AffairShowContactsModalComponent } from './affair-show/affair-show-contacts/affair-show-contacts-modal/affair-show-contacts-modal.component';
import { AffairFormWorkSiteInformationsComponent } from './affair-form/affair-form-work-site-informations/affair-form-work-site-informations.component';
import { AffairFormFinalCustomerInformationsComponent } from './affair-form/affair-form-final-customer-informations/affair-form-final-customer-informations.component';
import { AffairFormAffairInformationsComponent } from './affair-form/affair-form-affair-informations/affair-form-affair-informations.component';
import { AffairShowDocumentsDeliveredComponent } from './affair-show/affair-show-documents-delivered/affair-show-documents-delivered.component';
import { AffairShowOfficeFileComponent } from './affair-show/affair-show-office-file/affair-show-office-file.component';
import { AffairShowOfficeFileModalComponent } from './affair-show/affair-show-office-file/affair-show-office-file-modal/affair-show-office-file-modal.component';
import { AffairCreateMylinkResultsComponent } from './affair-create/affair-create-mylink-results/affair-create-mylink-results.component';
import { SanitizeHtmlPipe } from '@libs/services/src';
import { AffairShowHeadbandComponent } from './affair-show/affair-show-headband/affair-show-headband.component';
import { AffairShowInvoicesThumbnailsComponent } from './affair-show/affair-show-invoices/affair-show-invoices-thumbnails/affair-show-invoices-thumbnails.component';
import { InvoicesModule } from '@eros-front/invoices';
import { AffairFormNotesComponent } from './affair-form/affair-form-notes/affair-form-notes.component';
import { AffairFormAffairProductsComponent } from './affair-form/affair-form-affair-products/affair-form-affair-products.component';
import { AffairProductModalAddComponent } from './affair-product-modal/affair-product-modal-add/affair-product-modal-add.component';
import { AffairProductModalEditComponent } from './affair-product-modal/affair-product-modal-edit/affair-product-modal-edit.component';
import { AffairProductArrayComponent } from './affair-product-modal/affair-product-array/affair-product-array.component';
import { AffairProductsToInvoicingTabsComponent } from './affair-products/affair-products-to-invoicing/affair-products-to-invoicing-tabs/affair-products-to-invoicing-tabs.component';
import { AffairProductsToInvoicingIndexComponent } from './affair-products/affair-products-to-invoicing/affair-products-to-invoicing-index/affair-products-to-invoicing-index.component';
import { AffairProductsToInvoicingFiltersComponent } from './affair-products/affair-products-to-invoicing/affair-products-to-invoicing-filters/affair-products-to-invoicing-filters.component';
import { AffairsProductsToInvoicingModalComponent } from './affair-products/affair-products-to-invoicing/affairs-products-to-invoicing-modal/affairs-products-to-invoicing-modal.component';
import { AffairsProductsToInvoicingSelectTempoModalComponent } from './affair-products/affair-products-to-invoicing/affairs-products-to-invoicing-select-tempo-modal/affairs-products-to-invoicing-select-tempo-modal.component';
import { AffairsProductsUncompletedListComponent } from './affair-products/affairs-products-uncompleted-list/affairs-products-uncompleted-list.component';
import { AffairsProductsCompletedListComponent } from './affair-products/affairs-products-completed-list/affairs-products-completed-list.component';
import { AffairShowMailsComponent } from './affair-show/affair-show-mails/affair-show-mails.component';
import { AffairsInspectionIndexComponent } from './affairs-inspection-index/affairs-inspection-index.component';
import { AffairsProductsFinishedListComponent } from './affair-products/affairs-products-finished-list/affairs-products-finished-list.component';
import { AffairsExportComponent } from './affairs-export/affairs-export.component';
import { AffairShowQuotationsComponent } from './affair-show/affair-show-quotations/affair-show-quotations.component';
import { AffairShowQuotationsGenerateComponent } from './affair-show/affair-show-quotations/affair-show-quotations-generate/affair-show-quotations-generate.component';
import { AffairShowPreviewAmountComponent } from './affair-show/affair-show-modules/affair-show-preview-amount/affair-show-preview-amount.component';
import { AffairShowQuotationsThumbnailsComponent } from './affair-show/affair-show-quotations/affair-show-quotations-thumbnails/affair-show-quotations-thumbnails.component';
import { AffairProductModifyModalComponent } from './affair-product-modal/affair-product-modify-modal/affair-product-modify-modal.component';
import { AffairDocumentCustomerAttachedComponent } from './affair-document-customer-attached/affair-document-customer-attached.component';
import { AffairFormWorkSiteAddressComponent } from './affair-form/affair-form-work-site-address/affair-form-work-site-address.component';
import { AffairFormFinalCustomerAddressComponent } from './affair-form/affair-form-final-customer-address/affair-form-final-customer-address.component';
import { AffairFormFieldsComponent } from './affair-form/affair-form-fields/affair-form-fields.component';
import { AffairShowModalIdentifyDocumentComponent } from './affair-show/affair-show-modules/affair-show-modal-identify-document/affair-show-modal-identify-document.component';
import { AffairShowMonitoringComponent } from './affair-show/affair-show-monitoring/affair-show-monitoring.component';
import { AffairShowMonitoringFormComponent } from './affair-show/affair-show-monitoring/affair-show-monitoring-form/affair-show-monitoring-form.component';
import { AffairsArchivesIndexComponent } from './affairs-archives-index/affairs-archives-index.component';
import { AffairShowHistoryComponent } from './affair-show/affair-show-history/affair-show-history.component';
import { AffairsExportsComponent } from './affairs-exports/affairs-exports.component';
import { AffairsExportsDocumentsDeliveredComponent } from './affairs-exports/affairs-exports-documents-delivered/affairs-exports-documents-delivered.component';
import { AffairsExportsFiltersComponent } from './affairs-exports/affairs-exports-filters/affairs-exports-filters.component';
import { AffairsYnergieListComponent } from './affairs-ynergie/affairs-ynergie-list/affairs-ynergie-list.component';
import { AffairYnergieCreateModalComponent } from './affairs-ynergie/affair-ynergie-create-modal/affair-ynergie-create-modal.component';
import { AffairShowYnergieComponent } from './affairs-ynergie/affair-show-ynergie/affair-show-ynergie.component';
import { AffairShowYnergieScenarioModalComponent } from './affairs-ynergie/affair-show-ynergie/affair-show-ynergie-scenario-modal/affair-show-ynergie-scenario-modal.component';

@NgModule({
  imports: [
    SharedModule,
    InvoicesModule,
    PayersModule
  ],
  declarations: [
    AffairShowInvoicesComponent,
    AffairShowInvoicesInvoicingPreferencesComponent,
    AffairShowInvoicesGenerateComponent,
    AffairsIndividualHouseIndexComponent,
    AffairsCollectiveIndexComponent,
    AffairsRenovationIndexComponent,
    AffairsTabsComponent,
    AffairsDatatableFiltersComponent,
    AffairCreateComponent,
    AffairsMylinkIndexComponent,
    AffairShowDocumentsCustomersComponent,
    AffairShowDocumentsDeliveredComponent,
    AffairShowDocumentFiltersComponent,
    AffairShowModalRenameDocumentComponent,
    AffairShowDocumentsComponent,
    AffairShowContactsComponent,
    AffairShowContactsModalComponent,
    AffairDocumentCustomerImportComponent,
    AffairDocumentDeliveredImportComponent,
    AffairDocumentImportComponent,
    AffairDocumentDropzonesComponent,
    AffairFormWorkSiteInformationsComponent,
    AffairFormFinalCustomerInformationsComponent,
    AffairFormAffairInformationsComponent,
    AffairShowOfficeFileComponent,
    AffairShowOfficeFileModalComponent,
    AffairCreateMylinkResultsComponent,
    AffairShowHeadbandComponent,
    AffairShowInvoicesThumbnailsComponent,
    AffairFormNotesComponent,
    AffairFormAffairProductsComponent,
    AffairProductModalAddComponent,
    AffairProductModalEditComponent,
    AffairProductArrayComponent,
    AffairProductsToInvoicingTabsComponent,
    AffairProductsToInvoicingIndexComponent,
    AffairProductsToInvoicingFiltersComponent,
    AffairsProductsToInvoicingModalComponent,
    AffairsProductsToInvoicingSelectTempoModalComponent,
    AffairsProductsUncompletedListComponent,
    AffairsProductsCompletedListComponent,
    AffairShowMailsComponent,
    AffairsInspectionIndexComponent,
    AffairsProductsFinishedListComponent,
    AffairsExportComponent,
    AffairShowQuotationsComponent,
    AffairShowQuotationsGenerateComponent,
    AffairShowPreviewAmountComponent,
    AffairShowQuotationsThumbnailsComponent,
    AffairProductModifyModalComponent,
    AffairDocumentCustomerAttachedComponent,
    AffairFormWorkSiteAddressComponent,
    AffairFormFinalCustomerAddressComponent,
    AffairFormFieldsComponent,
    AffairShowModalIdentifyDocumentComponent,
    AffairShowMonitoringComponent,
    AffairShowMonitoringFormComponent,
    AffairsArchivesIndexComponent,
    AffairShowHistoryComponent,
    AffairsExportsComponent,
    AffairsExportsDocumentsDeliveredComponent,
    AffairsExportsFiltersComponent,
    AffairsYnergieListComponent,
    AffairYnergieCreateModalComponent,
    AffairShowYnergieComponent,
    AffairShowYnergieScenarioModalComponent,
  ],
  exports: [
    AffairShowInvoicesInvoicingPreferencesComponent,
    AffairShowInvoicesGenerateComponent,
    AffairShowDocumentsCustomersComponent,
    AffairShowDocumentsDeliveredComponent,
    AffairDocumentCustomerImportComponent,
    AffairDocumentDeliveredImportComponent,
    AffairDocumentImportComponent,
    AffairDocumentDropzonesComponent,
    AffairsMylinkIndexComponent,
    AffairShowContactsComponent,
    AffairShowContactsModalComponent,
    AffairShowInvoicesComponent,
    AffairFormWorkSiteInformationsComponent,
    AffairFormFinalCustomerInformationsComponent,
    AffairFormAffairInformationsComponent,
    AffairShowOfficeFileComponent,
    AffairShowOfficeFileModalComponent,
    AffairCreateMylinkResultsComponent,
    AffairShowHeadbandComponent,
    AffairFormNotesComponent,
    AffairFormAffairProductsComponent,
    AffairProductModalAddComponent,
    AffairProductModalEditComponent,
    AffairProductsToInvoicingTabsComponent,
    AffairsProductsToInvoicingModalComponent,
    AffairsProductsUncompletedListComponent,
    AffairsProductsCompletedListComponent,
    AffairsProductsFinishedListComponent,
    AffairShowMailsComponent,
    AffairShowQuotationsComponent,
    AffairDocumentCustomerAttachedComponent,
    AffairFormWorkSiteAddressComponent,
    AffairFormFinalCustomerAddressComponent,
    AffairFormFieldsComponent,
    AffairShowMonitoringComponent,
    AffairShowMonitoringFormComponent,
    AffairShowHistoryComponent,
    AffairsExportsComponent,
    AffairsExportsDocumentsDeliveredComponent,
    AffairsYnergieListComponent,
    AffairShowYnergieComponent
  ]
})
export class AffairsModule { }
