import { Component, Input, OnInit } from '@angular/core';
import { Quotation } from '@libs/models/src';
import { CommonService, ProductService, SwalService, ThingService } from '@libs/services/src';
import { QuotationDetailService } from '@libs/services/src/lib/quotation-detail.service';
import { QuotationService } from '@libs/services/src/lib/quotation.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { QuotationShowProductsModalComponent } from './quotation-show-products-modal/quotation-show-products-modal.component';

@Component({
  selector: 'app-quotation-show-products',
  templateUrl: './quotation-show-products.component.html',
  styleUrls: ['./quotation-show-products.component.scss']
})
export class QuotationShowProductsComponent implements OnInit {
  @Input() quotation: Quotation;
  public products$: Subscription;
  public vatRates$: Subscription;
  public detail;
  public products: any;
  public vatRates: any;

  constructor(
    private quotationDetailService: QuotationDetailService,
    private swalService: SwalService,
    private commonService: CommonService,
    private modalService: NzModalService,
    private productService: ProductService,
    private thingService: ThingService,
    private quotationService: QuotationService,
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.productService.getAll();
    this.thingService.getVatRatesSelect();
  }

  onAddProductClicked() {
    const modalInstance = this.modalService.create({
      nzContent: QuotationShowProductsModalComponent,
      nzComponentParams: {
        quotation: this.quotation,
        products: this.products,
        vatRates: this.vatRates
      },
      nzStyle: { top: '2vh' }
    });
    modalInstance.afterClose.subscribe(() => {
      this.quotationService.get(this.quotation.id);
    });
  }

  onEditProductClicked(detail: any) {
    const modalInstance = this.modalService.create({
      nzContent: QuotationShowProductsModalComponent,
      nzComponentParams: {
        quotation: this.quotation,
        detail: detail,
        products: this.products,
        vatRates: this.vatRates
      },
      nzStyle: { top: '2vh' }
    });
    modalInstance.afterClose.subscribe(() => {
      this.quotationService.get(this.quotation.id);
    });
  }

  onDeleteProductClicked(id: number) {
    const swalOptions = this.swalService.getSwalDeleteOptions({
      text: 'Le produit sera supprimé de manière irréversible.'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.quotationDetailService.deleteAndRefresh(this.quotation.id, id);
      }
    });
  }

  formatNumberToLocale(number: number) {
    return this.commonService.formatNumberToLocale(number);
  }

  private initSubscriptions() {
    this.products$ = this.productService.products$.subscribe(
      (data) => {
        if (data) {
          this.products = data;
        }
      }
    )
    this.vatRates$ = this.thingService.vatRates$.subscribe(
      (data) => {
        if (data) {
          this.vatRates = data;
        }
      }
    )
  }


}
