import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToggleService } from '@libs/services/src';
import { StatisticInterventionService } from '@libs/services/src/lib/statistic-intervention.service';

@Component({
  selector: 'app-statistic-settings',
  templateUrl: './statistic-settings.component.html',
  styleUrls: ['./statistic-settings.component.scss']
})
export class StatisticSettingsComponent implements OnInit {
  @Input() form: FormGroup;
  public isOpen = false;

  constructor(
    private toggleService: ToggleService,
    private statisticService: StatisticInterventionService
  ) { }

  ngOnInit() {
    this.toggleService.quickViewToggle.subscribe(data => {
      this.toggle();
    });
  }

  toggle() {
    if (this.isOpen) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }
  }

  clearForm() {
    //this.form.reset();
    const resetForm = this.statisticService.initForm();
    this.form.reset(resetForm.value)
  }


}
