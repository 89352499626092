import { SwalService } from './../../../../services/src/lib/utilities/swal.service';
import Swal from 'sweetalert2';
import { UserService } from './../../../../services/src/lib/user.service';
import { DataTableColumn } from './../../../../services/src/lib/utilities/datatable.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthentificationService } from '@libs/auth/src';
import { Title } from '@angular/platform-browser';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'eros-front-users-index',
  templateUrl: './users-index.component.html',
  styleUrls: ['./users-index.component.scss']
})
export class UsersIndexComponent implements OnInit, AfterViewInit {

  public datatableSelector = 'admin-users-datatable';
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'color',
      name: 'Couleur'
    },
    {
      attr: 'initials',
      name: 'Initiales'
    },
    {
      attr: 'lastname',
      name: 'Nom'
    },
    {
      attr: 'firstname',
      name: 'Prénom'
    },
    {
      attr: 'email',
      name: 'Email'
    },
    {
      attr: 'sendAsEmail',
      name: 'Email d\'envoi'
    },
    {
      attr: 'roles',
      searchColumn: 'roles.name',
      name: 'Rôles'
    },
    {
      attr: 'mlAgencies',
      searchColumn: 'mlAgencies.name',
      name: 'Agence(s) de rattachement'
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];

  constructor(
    private titleService: Title,
    private userService: UserService,
    private swalService: SwalService,
    private authentificationService: AuthentificationService,
    private router: Router
  ) {
    this.titleService.setTitle(`Liste des utilisateurs${tabSuffix}`);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.userService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        //that.userService.get(id);
        that.router.navigateByUrl('admin/users/' + id)
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'L\'utilisateur sera supprimée de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.userService.delete(id);
          }
        });
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-deactivate', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.userService.deactivate(id);
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-log-as', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.userService.logAs(id).subscribe(data => {
          if (data) {
            that.authentificationService.logAsUser(data);
          }
        });
      }
    });
  }

  public createUser() {
    this.router.navigateByUrl('admin/user/create');
  }

}
