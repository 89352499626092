import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DocumentModalStandardResponseComponent } from '@libs/components/src/lib/document-modal-standard-response/document-modal-standard-response.component';
import { Quotation, SelectModel, SubmitButton } from '@libs/models/src';
import { MarketType } from '@libs/models/src/lib/market-type.model';
import { CommonService, FileService, FileUtilsService, MarketTypeService, MlAgencyService, SubmitButtonService } from '@libs/services/src';
import { QuotationStatusService } from '@libs/services/src/lib/quotation-status.service';
import { QuotationService } from '@libs/services/src/lib/quotation.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-quotation-show-informations',
  templateUrl: './quotation-show-informations.component.html',
  styleUrls: ['./quotation-show-informations.component.scss']
})
export class QuotationShowInformationsComponent implements OnInit, OnDestroy {
  @Input() quotation: Quotation;
  private mlAgencies$: Subscription;
  private marketsTypes$: Subscription;
  private quotationsStatusesSelect$: Subscription;
  public form: FormGroup;
  public submitButton: SubmitButton;
  public mlAgencies: any;
  public marketsTypes: MarketType[];
  public quotationsStatuses: SelectModel[];

  constructor(
    private quotationService: QuotationService,
    private quotationStatusService: QuotationStatusService,
    private submitButtonService: SubmitButtonService,
    private mlAgencyService: MlAgencyService,
    private marketTypeService: MarketTypeService,
    private commonService: CommonService,
    private fileUtilsService: FileUtilsService,
    private modalService: NzModalService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.form = this.quotationService.getUpdateForm(this.quotation);
    this.getValues();
    this.setSubmitButton();
  }

  ngOnDestroy() {
    this.mlAgencies$.unsubscribe();
    this.marketsTypes$.unsubscribe();
    this.quotationsStatusesSelect$.unsubscribe();
  }

  private setSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'les changements',
      text: 'Enregistrer'
    });
    this.quotationService.setSubmitButton(this.submitButton);
  }

  public onSaveClicked() {
    if (this.isValidForm()) {
      this.quotationService.update(this.quotation.id, this.form);
      this.quotationService.get(this.quotation.id);
    }
  }

  public isValidForm() {
    return this.form.valid;
  }

  formatNumberToLocale(property: string): string {
    if (this.quotation[property] && !isNaN(this.quotation[property])) {
      return this.commonService.formatNumberToLocale(this.quotation[property]);
    }
    return '0';
  }

  public onShowInTabClicked() {
    this.quotationService.generateReport(this.quotation.id).subscribe(
      (quotation) => {
        this.fileUtilsService.openPreviewDocument(quotation.path, 'application/pdf');
      }
    )
  }

  onDownloadClicked() {
    this.quotationService.download(this.quotation.id).subscribe(
      (file: HttpResponse<Blob>) => {
        this.fileUtilsService.responseToDownload(file, 'pdf')
      }
    )
  }

  onModalDocumentStandardResponseShow() {
    const modalInstance = this.modalService.create({
      nzContent: DocumentModalStandardResponseComponent,
      nzComponentParams: {
        document: this.quotation,
      },
      nzStyle: { top: '2vh' }
    });
  }

  private getValues() {
    this.mlAgencyService.getForSelect();
    this.mlAgencyService.getAll();
    this.marketTypeService.getAll();
    this.quotationStatusService.getForSelect();
  }

  private initSubscriptions() {
    this.mlAgencies$ = this.mlAgencyService.mlAgenciesSelect$.subscribe(
      (data) => {
        if (data) {
          this.mlAgencies = data;
        }
      }
    )
    this.marketsTypes$ = this.marketTypeService.getAll().subscribe(
      (data) => {
        if (data) {
          this.marketsTypes = data;
        }
      }
    )
    this.quotationsStatusesSelect$ = this.quotationStatusService.quotationsStatusesSelect$.subscribe(
      (data) => {
        if (data) {
          this.quotationsStatuses = data;
        }
      }
    )
  }

}
