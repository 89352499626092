import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { SelectService } from './utilities/select.service';
import { DataTableColumn } from './classes/model-datatable';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { ModelWithDatatableAndModalCrud } from './classes/model-datatable-modal-crud';
import { Region } from '@libs/models/src';
import { CommonService } from './utilities/common.service';

@Injectable()
export class RegionService extends ModelWithDatatableAndModalCrud {

    private route = '/regions';
    public region$ = new BehaviorSubject<any>(undefined);
    public regions$ = new BehaviorSubject<any>(undefined);
    public regionsSelect$ = new BehaviorSubject<any>(undefined);
    public regionsSelectTechnicians$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private selectService: SelectService,
        private commonService: CommonService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): void {
        super.get(id, this.region$, this.route);
    }

    public getAll(): void {
        super.getAll(this.regions$, this.route);
    }

    public store(form: any): void {
        super.store(form.value, this.route);
    }

    public update(id: number, form: any): void {
        super.update(id, form.value, this.route);
    }

    public delete(id: number): void {
        super.delete(id, this.route);
    }

    public getForm(region: Region) {
        const name = region ?
            this.commonService.valueToFormString(region.name) : this.commonService.getDefaultFormStringValue();
        const color = region ?
            this.commonService.valueToFormColor(region.color) : this.commonService.getDefaultFormColorValue();
        return this.formBuilder.group({
            name: [name, Validators.required],
            color: [color, Validators.required]
        });
    }

    public getForSelect(): void {
        this.selectService.getForSelect(this.regionsSelect$, this.route);
    }

    public getForSelectTechnicians(regionsIds: any): void {
        this.apiService.post(this.route + '/select/technicians', {
            'regionsIds': JSON.stringify(regionsIds)
        })
            .subscribe(
                (objects) => {
                    this.regionsSelectTechnicians$.next(objects);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    getFormattedRegions(regions) {
        let formattedRegions = [];
        regions.forEach((region) => {
            formattedRegions.push({
                'value': region.id,
                'label': region.name,
            });
        });
        return formattedRegions;
    }

}
