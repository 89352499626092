import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AffairGoal, SubmitButton } from '@libs/models/src';
import { MarketTypeService, SwalService } from '@libs/services/src';
import { AffairGoalService } from '@libs/services/src/lib/affair-goal.service';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AffairsGoalsModalComponent } from '../affairs-goals-modal/affairs-goals-modal.component';

@Component({
  selector: 'app-affairs-goals-index',
  templateUrl: './affairs-goals-index.component.html',
  styleUrls: ['./affairs-goals-index.component.scss']
})
export class AffairsGoalsIndexComponent implements OnInit {
  @Input() appKey: string;
  public datatableSelector = 'affairs-goals-datatable';
  private marketsTypes$: Subscription;
  public form: FormGroup;
  public affairGoal: any = null;
  public affairGoal$: Subscription;
  public marketsTypes: any;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'marketsTypes',
      name: 'Marché(s)',
      orderable: false,
      searchable: false
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];

  constructor(
    private affairGoalService: AffairGoalService,
    private marketTypeService: MarketTypeService,
    private swalService: SwalService,
    private modalService: NzModalService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.marketTypeService.getForSelect();
  }

  ngAfterViewInit() {
    this.affairGoalService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    if (this.affairGoal$) {
      this.affairGoal$.unsubscribe();
    }
    this.marketsTypes$.unsubscribe();
  }

  onShowModalAddEditModal() {
    this.form = this.affairGoalService.getForm(this.affairGoal);
    const modalInstance = this.modalService.create({
      nzContent: AffairsGoalsModalComponent,
      nzComponentParams: {
        affairGoal: this.affairGoal,
        marketsTypes: this.marketsTypes,
        form: this.form
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.affairGoal = null;
    })
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.affairGoal$ = that.affairGoalService.get(id).subscribe(
          (affairGoal) => {
            if (affairGoal) {
              that.affairGoal = affairGoal;
              that.onShowModalAddEditModal();
            }
          }
        );
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'L\'objectif sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.affairGoalService.delete(id).subscribe(
              (success) => {
                this.swalService.showSwalSuccess(success);
              }
            );
          }
        });
      }
    });
  }

  private initSubscriptions() {
    this.marketsTypes$ = this.marketTypeService.selectMarketType$.subscribe(
      (data) => {
        this.marketsTypes = data;
      }
    )
  }

}
