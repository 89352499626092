import { AfterContentInit, AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { SubmitButton } from '@libs/models/src';
import { SubmitButtonService } from '@libs/services/src';
import { AccountingExportService } from '@libs/services/src/lib/accounting-export.service';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { NzModalService } from 'ng-zorro-antd/modal';
import { InvoicesExportGenerateComponent } from '../invoices-export-generate/invoices-export-generate.component';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-accounting-exports-index',
  templateUrl: './accounting-exports-index.component.html',
  styleUrls: ['./accounting-exports-index.component.scss']
})
export class AccountingExportsIndexComponent implements OnInit, AfterViewInit {
  public datatableSelector = 'accounting-exports-datatable';
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'id',
      orderable: false,
      searchable: false,
      displayInToggleVis: false
    },
    {
      attr: 'type',
      name: 'Type',
      individualFilterInput: true
    },
    {
      attr: 'dateFrom',
      name: 'Date de début',
      individualFilterInput: true
    },
    {
      attr: 'dateTo',
      name: 'Date de fin',
      individualFilterInput: true
    }
  ];
  public submitButton: SubmitButton;
  public form: FormGroup;

  constructor(
    private titleService: Title,
    private accountingExportService: AccountingExportService,
    private modalService: NzModalService,
    private submitButtonService: SubmitButtonService
  ) {
    this.titleService.setTitle(`Liste des exports comptables${tabSuffix}`);
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.accountingExportService.initDataTable(this.datatableSelector, this.columns);
  }

  onOpenModalExportInvoices() {
    const modalInstance = this.modalService.create({
      nzContent: InvoicesExportGenerateComponent
    })
    modalInstance.afterClose.subscribe()
  }

}
