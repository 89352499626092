import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectModel } from '@libs/models/src';

@Component({
  selector: 'app-payments-datatable-filters',
  templateUrl: './payments-datatable-filters.component.html',
  styleUrls: ['./payments-datatable-filters.component.scss']
})
export class PaymentsDatatableFiltersComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() agencies: SelectModel[];
  @Input() invoices: SelectModel[];
  @Input() payers: SelectModel[];

  constructor() { }

  ngOnInit() {
  }

}
