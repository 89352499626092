import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { Router } from '@angular/router';
import { AffairMeeting } from '@libs/models/src';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { SelectService } from './utilities/select.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { catchError, finalize } from 'rxjs/operators';
import { AffairContactService } from './affair-contact.service';
import { UserService } from './user.service';

@Injectable()
export class AffairMeetingService extends ModelWithDatatableAndCrudObservable {

    public route = '/affairs-meetings';
    public affairsMeetingsByAffair$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private commonService: CommonService,
        private selectService: SelectService,
        private formFormatterService: FormFormatterService,
        private userService: UserService,
        private affairContactService: AffairContactService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route);
    }

    public getAll(): Observable<any> {
        return super.getAll(this.route);
    }

    public store(form: any): Observable<any> {
        return this.apiService.post(this.route, this.formatForm(form))
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                    if (this.dtInstance) {
                        this.dtInstance.draw();
                    }
                })
            );
    }

    public update(id: number, form: any): Observable<any> {
        return super.update(id, this.formatForm(form), this.route);
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route);
    }

    public getByAffair(affairId: number) {
        return this.apiService.get(this.route + '/affair/' + affairId).subscribe(
            (meetings) => {
                this.affairsMeetingsByAffair$.next(meetings);
            },
            (error) => {
                this.notifService.showNotif({
                    type: 'danger',
                    text: 'Une erreur est survenue lors de la récupération'
                });
            }
        );
    }

    public getForm(affairId: number, affairMeeting: AffairMeeting): FormGroup {
        const phase = affairMeeting ?
            affairMeeting.phase : this.commonService.getDefaultFormStringValue();
        const startDate = affairMeeting ?
            this.commonService.valueToFormDate(affairMeeting.startDate) : this.commonService.getDefaultFormNullValue();
        const duration = affairMeeting ?
            this.getDurationDate(affairMeeting) : this.commonService.getDefaultFormNullValue();
        const address = affairMeeting ?
            this.commonService.valueToFormString(affairMeeting.address) : this.commonService.getDefaultFormStringValue();
        const postalCode = affairMeeting ?
            this.commonService.valueToFormString(affairMeeting.postalCode) : this.commonService.getDefaultFormStringValue();
        const locality = affairMeeting ?
            this.commonService.valueToFormString(affairMeeting.locality) : this.commonService.getDefaultFormStringValue();
        const comment = affairMeeting ?
            this.commonService.valueToFormString(affairMeeting.comment) : this.commonService.getDefaultFormStringValue();
        const isBillable = affairMeeting ?
            this.commonService.valueToRadioFormString(affairMeeting.isBillable) : this.commonService.getDefaultFormRadioValue();
        const users = affairMeeting ?
            this.userService.valueToFormSelectMultiple(affairMeeting.users) : this.commonService.getDefaultFormNullValue();
        const affairContacts = affairMeeting ?
            affairMeeting.affairContacts : this.commonService.getDefaultFormNullValue();
        return this.formBuilder.group({
            affairId: [affairId, Validators.required],
            phase: [phase, Validators.required],
            startDate: [startDate, Validators.required],
            duration: [duration, Validators.required],
            address: [address],
            postalCode: [postalCode],
            locality: [locality],
            comment: [comment],
            users: [users],
            affairContacts: [affairContacts],
            isBillable: [isBillable]
        });
    }

    private getDurationDate(affairMeeting: AffairMeeting): Date {
        let dateTimeString = new Date().toDateString() + " " + affairMeeting.duration;
        return new Date(dateTimeString);
    }


    private formatForm(form) {
        const values = this.formFormatterService.createFormCopy(form);
        values.phase = this.formFormatterService.formatSelectSingle(values.phase);
        values.startDate = new Date(values.startDate).toJSON();
        values.duration = new Date(values.duration).toTimeString();
        values.users = this.formFormatterService.formatSelectMultipleToIntArray(values.users);
        return values;
    }

}
