import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@libs/api/src';
import { AuthentificationService } from '@libs/auth/src';
import { FileService, FileUtilsService } from '@libs/services/src';
import { NgxSpinnerService } from 'ngx-spinner';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss']
})
export class DocumentViewerComponent implements OnInit {

  public path: string;
  public type: string;
  public pdfSrc: any;
  public headers: string;
  public filename: string;

  constructor(
    private route: ActivatedRoute,
    private fileService: FileService,
    private authService: AuthentificationService,
    private spinner: NgxSpinnerService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.spinner.show('document-loading');
    this.path = this.route.snapshot.queryParamMap.get('path');
    this.type = this.route.snapshot.queryParamMap.get('type');
    this.pdfSrc = this.fileService.getPreviewUrl(this.path);
    this.headers = 'Bearer ' + this.authService.getToken();
    this.filename = this.path.split("/").pop();
    this.titleService.setTitle(this.filename + tabSuffix);
  }

  onPdfLoaded() {
    this.spinner.hide('document-loading');
  }

  onPdfLoadingFailed() {
    this.spinner.hide('document-loading');
  }

}
