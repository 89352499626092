import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { StatisticModeEnum } from '@libs/enum/src';
import { NzTableComponent, NzTableFilterFn, NzTableFilterList, NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';


interface StatisticData {
  key: number;
  categorie: string;
  value: number;
  valueYMinusOne?: number;
  difference?: number;
  expand: boolean;
}

interface StatistiChildrencData {
  key: number;
  categorie: string;
  value: number;
  valueYMinusOne?: number;
  difference?: number;
}

@Component({
  selector: 'app-statistics-table',
  templateUrl: './statistics-table.component.html',
  styleUrls: ['./statistics-table.component.scss']
})

export class StatisticsTableComponent implements OnChanges {
  @Input() statisticsData;
  @Input() displayMode;
  public statisticsDataTable: StatisticData[] = [];
  public statisticsChildrenDataTable: StatistiChildrencData[] = [];
  public totalStats = 0;
  public totalManualAmount = 0;
  public totalTemporaryAmount = 0;
  public totalStatsYMinusOne = 0;
  public difference = 0;
  public statisticModeEnum = StatisticModeEnum;
  listOfColumn = [
    {
      title: 'Catégorie',
      compare: (a: StatisticData, b: StatisticData) => a.categorie.localeCompare(b.categorie)
    },
    {
      title: 'Valeur',
      compare: (a: StatisticData, b: StatisticData) => a.value - b.value
    },
  ];


  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['statisticsData'] && changes['statisticsData'].previousValue != changes['statisticsData'].currentValue) {
      this.initTableData(this.statisticsData);
    }
    if (changes['displayMode'] && changes['displayMode'].previousValue != changes['displayMode'].currentValue) {
      this.updateListOfColum();
    }

  }

  updateListOfColum() {
    this.listOfColumn = [
      {
        title: 'Catégorie',
        compare: (a: StatisticData, b: StatisticData) => a.categorie.localeCompare(b.categorie)
      },
      {
        title: 'Valeur',
        compare: (a: StatisticData, b: StatisticData) => a.value - b.value
      },
    ];
    if (this.displayMode == this.statisticModeEnum.YMINUSONE) {
      this.listOfColumn.push({
        title: 'Valeur N-1',
        compare: (a: StatisticData, b: StatisticData) => a.valueYMinusOne - b.valueYMinusOne
      })
      this.listOfColumn.push({
        title: 'Diff',
        compare: (a: StatisticData, b: StatisticData) => a.difference - b.difference
      })
    }

  }

  initTableData(data: any) {
    if (data != undefined) {
      this.statisticsDataTable = [];
      this.statisticsChildrenDataTable = [];
      this.totalStats = 0;
      this.totalStatsYMinusOne = 0;
      this.difference = 0;
      this.totalManualAmount = data.totalManualAmount;
      this.totalTemporaryAmount = data.totalTemporaryAmount ? data.totalTemporaryAmount : 0;
      if (this.displayMode === StatisticModeEnum.YMINUSONE) {
        const dimensions = data.dataset.dimensions;
        const source = data.dataset.source;

        for (let i = 1; i < dimensions.length; i++) {
          let value = 0.0;
          let valueYMinusOne = 0;
          for (let j = 0; j < source.length; j++) {
            const yMinusOneDimension = dimensions[i];
            const currentDimension = dimensions[i + 1];
            let current = source[j][currentDimension] != null ? source[j][currentDimension] : 0;
            let currentValueYMinusOne = source[j][yMinusOneDimension] != null ? source[j][yMinusOneDimension] : 0;
            let elem = {
              'key': i,
              'categorie': source[j].name,
              'value': current,
              'valueYMinusOne': currentValueYMinusOne,
              'difference': current - currentValueYMinusOne
            };
            this.statisticsChildrenDataTable.push(elem);
            if (source[j][currentDimension] != null) {
              let valueFormatted = + parseFloat(source[j][currentDimension]).toFixed(2);
              value += valueFormatted;
            }
            if (source[j][yMinusOneDimension] != null) {
              let valueFormattedYMinusOne = + parseFloat(source[j][yMinusOneDimension]).toFixed(2);
              valueYMinusOne += valueFormattedYMinusOne;
            }
          }
          if (value > 0) {
            let period_split = String(dimensions[i + 1]).split(' ');
            let elementData: StatisticData = {
              'key': i,
              'categorie': period_split[0],
              'value': value,
              'valueYMinusOne': valueYMinusOne,
              'difference': value - valueYMinusOne,
              'expand': false
            };
            this.totalStats += value;
            this.totalStatsYMinusOne += valueYMinusOne;
            this.statisticsDataTable.push(elementData);
            i++;
          }
        }
        this.difference = this.totalStats - this.totalStatsYMinusOne;
      } else {
        for (let i = 0; i < data.categories.length; i++) {
          let value = 0.0;
          for (let j = 0; j < data.series.length; j++) {
            if (data.series[j].name !== 'Objectif CA') {
              let elem = {
                'key': i,
                'categorie': data.series[j].name,
                'value': data.series[j].data[i] != null ? this.formatNumber(data.series[j].data[i]) : 0
              };
              this.statisticsChildrenDataTable.push(elem);
              if (data.series[j].data[i] != null) {
                let valueFormatted = + parseFloat(data.series[j].data[i]).toFixed(2);
                value += valueFormatted;
              }
            }
          }
          const elementData: StatisticData = { 'key': i, 'categorie': data.categories[i], 'value': value, 'expand': false };
          this.statisticsDataTable.push(elementData);
          this.totalStats += value;
        }
      }
    }

  }

  getStatisticsTableChildren(key) {
    return this.statisticsChildrenDataTable.filter(stat => stat.key == key);
  }

  formatNumber(number) {
    if (number !== undefined) {
      return number.toLocaleString('fr-FR', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
    }
  }

  formatDiff(number) {
    if (number !== undefined) {
      if (number > 0) {
        return '<span class="span-value-up"><i class="fa fa-arrow-up mr-1"></i>' + this.formatNumber(number) + '</span>';
      } else if (number < 0) {
        return '<span class="span-value-down"><i class="fa fa-arrow-down mr-1"></i>' + this.formatNumber(number) + '</span>';
      } else {
        return '<span class="span-value-dash"><i class="fa fa-minus mr-1"></i>' + this.formatNumber(number) + '</span>';
      }
    }
  }


}
