import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'input-clear-icon',
  templateUrl: './input-clear-icon.component.html',
  styleUrls: ['./input-clear-icon.component.scss']
})
export class InputClearIconComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() formControlNameInput: string;
  @Input() placeholder = '';
  @Output() inputChange = new EventEmitter();
  public faTimesCircle = faTimesCircle;
  public _value: string = '';
  @Input()
  get inputModel() {
    return this._value;
  }
  set inputModel(val) {}

  constructor() {}

  ngOnInit() {
    if (this.form) {
      const control = this.form.controls[this.formControlNameInput];
      if (control && control.value) {
        this._value = control.value;
      }
    }
  }

  change() {
    this.setFormValue();
    this.inputChange.emit(this._value);
  }

  click() {
    this._value = '';
    this.setFormValue();
    this.inputChange.emit(this._value);
  }

  isClearIconVisible() {
    return this._value.length > 0;
  }

  private setFormValue() {
    if (this.form) {
      this.form.controls[this.formControlNameInput].setValue(this._value);
    }
  }
}
