import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Affair } from '@libs/models/src';
import { AffairService } from '@libs/services/src';

@Component({
  selector: 'app-affair-show-history',
  templateUrl: './affair-show-history.component.html',
  styleUrls: ['./affair-show-history.component.scss']
})
export class AffairShowHistoryComponent implements OnInit, OnChanges {
  @Input() affair: Affair;
  public audits = [];

  constructor(
    private affairService: AffairService
  ) { }

  ngOnInit() {
    this.initAudits();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['affair'] && changes['affair'].previousValue != changes['affair'].currentValue) {
      this.initAudits();
    }
  }

  initAudits() {
    this.affairService.getAudits(this.affair.id).subscribe(
      (audits) => {
        this.audits = audits;
      }
    )
  }
}
