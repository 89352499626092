import { Component, OnInit, Input } from '@angular/core';
import { DataTableColumn } from '@eros-front/services';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'table-datatable',
  templateUrl: './table-datatable.component.html',
  styleUrls: ['./table-datatable.component.scss']
})
export class TableDatatableComponent implements OnInit {

  @Input() selector: string;
  @Input() columns: DataTableColumn[];
  constructor() { }

  ngOnInit() {
  }

}
