import { RootLayout } from '@pages/layouts/root/root.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'eros-front-root',
  template: `
  <router-outlet></router-outlet>
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends RootLayout implements OnInit {
  title = 'eros-nrj';

  ngOnInit() {

  }
}
