import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AffairShuttleSheet } from '@libs/models/src';
import { ApiService } from '@libs/api/src';
import { SwalService } from '../utilities/swal.service';

export interface InvoiceQueryParams {
  search: string;
  size: number;
  page: number;
}

@Injectable()
export class AffairShuttleSheetService {

  private route = '/affairs-shuttles-sheets';
  public affairsShuttlesSheets$ = new BehaviorSubject<AffairShuttleSheet[]>(undefined);

  constructor(
    private swalService: SwalService,
    private apiService: ApiService,
  ) {}

  public get(id: number): Observable<any> {
    return this.apiService.get(`${this.route}/${id}`);
  }

  public getForAffair(id: number): Observable<any> {
    return this.apiService.get(`${this.route}/affair/${id}`);
  }
}
