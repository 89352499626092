import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'internal-form-notes',
  templateUrl: './internal-form-notes.component.html',
  styleUrls: ['./internal-form-notes.component.scss']
})
export class InternalFormNotesComponent implements OnInit {
  @Input() form: FormGroup;

  constructor() { }

  ngOnInit() {
  }

}
