import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Affair, Agency, Intervention } from '@libs/models/src';

@Component({
  selector: 'app-affair-show-headband',
  templateUrl: './affair-show-headband.component.html',
  styleUrls: ['./affair-show-headband.component.scss']
})
export class AffairShowHeadbandComponent implements OnInit {
  @Input() affair: Affair;
  @Input() appKey: string;
  @Input() isRenovationMarket: boolean;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  redirectShowAgency(agency: Agency) {
    window.open(`customers/${agency.ref}/show`);
  }

  redirectShowIntervention(intervention: Intervention) {
    this.router.navigateByUrl(`interventions/${intervention.id}/edit`);
  }

}
