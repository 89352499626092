import { ApiService } from '@eros-front/api';
import { ModelWithDatatableAndModalCrud } from './classes/model-datatable-modal-crud';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { Router } from '@angular/router';
import { SendSetting, StandardResponse } from '@libs/models/src';
import { FormFormatterService } from './utilities/form-formatter.service';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { SelectService } from './utilities/select.service';

@Injectable()
export class SendSettingService extends ModelWithDatatableAndCrudObservable {

    public route = '/send-settings';
    public sendSettingsSelect$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService,
        private selectService: SelectService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route);
    }

    public getAll(): Observable<any> {
        return super.getAll(this.route);
    }

    public store(form: any): Observable<any> {
        return super.store(this.formatForm(form), this.route);
    }

    public update(id: number, form: any): Observable<any> {
        return super.update(id, this.formatForm(form), this.route);
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route);
    }

    public getForm(sendSetting?: SendSetting) {
        const key = sendSetting ?
            this.commonService.valueToFormString(sendSetting.key) : this.commonService.getDefaultFormStringValue();
        const name = sendSetting ?
            this.commonService.valueToFormString(sendSetting.name) : this.commonService.getDefaultFormStringValue();
        const description = sendSetting ?
            this.commonService.valueToFormString(sendSetting.description) : this.commonService.getDefaultFormStringValue();
        return this.formBuilder.group({
            key: [key, Validators.required],
            name: [name, Validators.required],
            description: [description, Validators.required]
        });
    }

    public getForSelect() {
        this.selectService.getForSelect(this.sendSettingsSelect$, this.route);
    }

    public getStandardsResponses(sendSetting: SendSetting, marketTypeId?: number) {
        if (marketTypeId) {
            return sendSetting.standardsResponses.filter(s => s.marketsTypes.map(x => x.id).includes(marketTypeId));
        }
        return sendSetting.standardsResponses;
    }

    private formatForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.standardsResponses = this.formFormatterService.formatSelectMultipleToIntArray(values.standardsResponses);
        return values;
    }
}
