import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import { Building, Intervention } from '@libs/models/src';
import { BuildingService, NotifService, SwalService } from '@libs/services/src';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-affair-show-buildings-building-template',
  templateUrl: './affair-show-buildings-building-template.component.html',
  styleUrls: ['./affair-show-buildings-building-template.component.scss']
})
export class AffairShowBuildingsBuildingTemplateComponent implements OnInit {

  @Input() building: Building;
  @Input() currentBuildingIdHover: number;
  @Output() editBuildingClicked = new EventEmitter();
  @Output() associateBuildingClicked = new EventEmitter();
  @Output() deleteBuildingClicked = new EventEmitter();
  @Output() mouseOverBuilding = new EventEmitter();
  public faCheckCircle = faCheckCircle;
  public faCircle = faCircle;

  constructor(
  ) { }

  ngOnInit() {
  }

  onMouseOverBuilding(id: number) {
    this.mouseOverBuilding.emit(id);
  }

  onEditBuildingClicked(building: Building) {
    this.editBuildingClicked.emit(building);
  }

  onAssociateBuildingClicked(building: Building) {
    this.associateBuildingClicked.emit(building);
  }

  onDeleteBuildingClicked(building: Building) {
    this.deleteBuildingClicked.emit(building);
  }

  getPComment() {
    return this.building.comment.replace(/\r?\n|\r/g, '<br>');
  }

  onInterventionClicked(intervention: Intervention) {
    window.open(`/interventions/${intervention.id}/edit`);
  }

  hexToRGB(hex: string) {
    let c: any;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ', .3)';
    }
    else {
      return 'rgba(238, 238, 238, 1)';
    }
  }

  getBackgroundColor(building: Building) {
    if (building.backgroundColor) {
      return this.hexToRGB(building.backgroundColor)
    }
    return this.hexToRGB(building.buildingType.color)
  }
}
