import { ActivatedRoute, ParamMap } from '@angular/router';
import { PermissionService, RoleService } from '@eros-front/services';
import { Subscription, zip } from 'rxjs';
import { SubmitButton } from '@eros-front/models';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'eros-front-role-create-edit',
  templateUrl: './role-create-edit.component.html',
  styleUrls: ['./role-create-edit.component.scss']
})
export class RoleCreateEditComponent implements OnInit {
  public form: FormGroup;
  public role = null;
  public submitButton: SubmitButton;
  public roleId = null;
  public role$: Subscription;
  public permissions: any;

  constructor(
    private titleService: Title,
    private roleService: RoleService,
    private permissionService: PermissionService,
    private route: ActivatedRoute
  ) {
    this.titleService.setTitle(`Création d'un rôle${tabSuffix}`);
  }

  ngOnInit() {
    this.permissionService.getAll();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.roleId = params.get('id');
      if (this.roleId) {
        this.initSubscriptions();
        this.roleService.get(this.roleId);
      } else {
        this.form = this.roleService.getForm(this.role);
        this.determinateSubmitButton();
        this.permissionService.permissions$.subscribe(
          (permissionData) => {
            if (permissionData) {
            this.permissions = permissionData;
            this.addCheckboxes();
            }
          }
        )
      }
    });
  }

  private initSubscriptions() {
    zip(
      this.roleService.role$,
      this.permissionService.permissions$,
    ).subscribe(([roleData, permissionData]) => {
      if (roleData) {
        this.role = roleData;
        this.form = this.roleService.getForm(this.role);
        this.determinateSubmitButton();
        this.titleService.setTitle(`Édition d'un rôle${tabSuffix}`);
      }
      if (permissionData) {
        this.permissions = permissionData;
        this.addCheckboxes();
      }
    })
  }

  isValidForm(): boolean {
    return this.form.valid;
  }

  private determinateSubmitButton() {
    this.submitButton = this.roleService.determinateSubmitButton(this.role, 'le rôle');
  }

  isInvalidField(fieldName: string) {
    const field = this.form.get(fieldName);
    return (field.dirty || field.touched) && field.invalid && field.errors.required;
  }

  submitForm() {
    if (this.isValidForm()) {
      this.form.value.permissions = this.form.value.permissions.map((v, i) => v ? this.permissions[i].id : null).filter(v => v !== null);
      if (this.role){
        this.roleService.update(this.roleId, this.form);
      } else {
        this.roleService.store(this.form);
      }
    }
  }

  get permissionsFormArray() {
    return this.form.controls.permissions as FormArray;
  }

  private addCheckboxes() {
    this.permissions.forEach((permission) => {
      let checked = false;
      if (this.role){
        checked = this.role.permissions.map(x => x.id).includes(permission.id);
      }
      this.permissionsFormArray.push(new FormControl(checked))
    });
  }

}
