import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubmitButton, TextTag } from '@libs/models/src';
import { TextTagService } from '@libs/services/src/lib/text-tag.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-text-tags-modal',
  templateUrl: './text-tags-modal.component.html',
  styleUrls: ['./text-tags-modal.component.scss']
})
export class TextTagsModalComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() textTag: TextTag;
  public submitButton: SubmitButton;

  constructor(
    private textTagService: TextTagService,
    private modal: NzModalRef
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.textTagService.setSubmitButton(this.submitButton);
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.textTag) {
        this.textTagService.update(this.textTag.id, this.form).subscribe(() => {
          this.modal.close();
        });
      } else {
        this.textTagService.store(this.form).subscribe(() => {
          this.modal.close();
        });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.textTagService.determinateSubmitButton(this.textTag, 'la balise texte');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }
}
