import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AffairService, PayerService, SubmitButtonService } from '@eros-front/services';
import { AffairPayerPreference, AffairPayerInvoicing, AffairPayerCustomer, MlSocietyEnum } from '@eros-front/enum';
import { AffairInvoicingPreferenceService } from '@libs/services/src/lib/affair-invoicing-preference.service';
import { SubmitButton } from '@libs/models/src';
import { faSave } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-affair-show-invoices',
  templateUrl: './affair-show-invoices.component.html',
  styleUrls: ['./affair-show-invoices.component.scss']
})
export class AffairShowInvoicesComponent implements OnInit {

  @Input() affair: any;
  @Input() mlAgencies: any[];
  @Input() payers: any[];
  @Input() appKey: string;

  constructor(
  ) { }

  ngOnInit() {
  }
}
