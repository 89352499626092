import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { NotifService } from './utilities/notif.service';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { FormFormatterService } from './utilities/form-formatter.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { Expense, ExpenseAccount, ExpenseAccountStatus, ExpenseAmount, InvoiceStatus } from '@eros-front/models';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { SelectService } from './utilities/select.service';
import { DateUtilsService } from './utilities/date-utils.service';
import { catchError } from 'rxjs/operators';

export interface ExpenseAccountListForm {
    selectedUsers: string;
    month: string;
    size: number;
    page: number;
}

@Injectable()
export class ExpenseService extends ModelWithDatatableAndCrudObservable {

    private route = '/expenses';
    public expensesAccounts$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService,
        private selectService: SelectService,
        private dateUtilsService: DateUtilsService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route);
    }

    public getAll(): Observable<any> {
        return super.getAll(this.route);
    }

    public store(form: any): Observable<any> {
        return super.store(this.formatForm(form), this.route);
    }

    public update(id: number, form: any): Observable<any> {
        return super.update(id, this.formatForm(form), this.route);
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route);
    }

    public getForSelect(): void {
        this.selectService.getForSelect(this.expensesAccounts$, this.route);
    }

    public getForComptabilityList(form: any): Observable<any> {
        return this.apiService.post(`${this.route}/comptability/list`, this.formatFiltersForm(form))
    }

    public getForm(expense: Expense): FormGroup {
        const date = expense?.date || new Date();

        return this.formBuilder.group({
            date: [date, Validators.required],
            expenseWordingId: [expense?.expenseWording.id || null, Validators.required],
            expensesAmounts: new FormArray(this.getExpensesAmounts()),
        });
    }

    public getExpensesAmounts(expense?: Expense): any[] {
        const expensesAmounts: any[] = [];
        if (expense) {
            expense.expensesAmounts.forEach((expAm: ExpenseAmount) => {
                expensesAmounts.push(this.getExpensesAmountsForm(expAm));
            });
        } else {
            expensesAmounts.push(this.getExpensesAmountsForm(null));
        }
        return expensesAmounts;
    }

    private getExpensesAmountsForm(expAm: ExpenseAmount | null): FormGroup {
        return this.formBuilder.group({
            expenseTypeId: [expAm?.expenseType.id || null, Validators.required],
            amountVatFive: expAm?.amountVatFive || 0,
            amountVatTen: expAm?.amountVatTen || 0,
            amountVatTwenty: expAm?.amountVatTwenty || 0,
            amountTotalAllTaxes: [expAm?.amountTotalAllTaxes || 0, Validators.required],
        });
    }

    public initFiltersForm(form?: ExpenseAccountListForm): FormGroup {
        const selectedUsers = form ?
            form.selectedUsers : this.commonService.getDefaultFormNullValue();
        const month = form ?
            form.month : this.commonService.getDefaultFormDateValue();
        const page = form ?
            form.page : 1;
        const size = form ?
            form.size : 10;
        return this.formBuilder.group({
            selectedUsers: [selectedUsers],
            month: [month],
            page: [page],
            size: [size],
        });
    }

    private formatFiltersForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.selectedUsers = this.formFormatterService.formatSelectMultiple(values.selectedUsers);
        return values;
    }

    private formatForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.users = this.formFormatterService.formatSelectMultipleToIntArray(values.users);
        values.contacts = this.formFormatterService.formatSelectMultipleToIntArray(values.contacts);
        return values;
    }

    public import(expenseId: number, file: File): Observable<any> {
        const formData = new FormData();
        formData.append('expenseId', expenseId.toString());
        formData.append('file', file);

        return this.apiService.importFile(`${this.route}/import-file`, formData);
    }

}
