import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from './utilities/common.service';
import { Affair, Building, SelectModel, SubmitButton } from '@libs/models/src';
import { catchError, finalize } from 'rxjs/operators';
import { ModelWithModal } from './classes/model-modal';
import { BuildingPartService } from './building-part.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

export interface BuildingFormParam {
    affair: Affair;
    partId?: number;
    building?: Building;
}

@Injectable()
export class BuildingService extends ModelWithDatatableAndCrudObservable {

    private route = '/buildings';
    private associateSubmitButton: SubmitButton;
    private associateModal: ModalDirective;
    public building$ = new BehaviorSubject<Building>(undefined);
    public buildingsByAffair$ = new BehaviorSubject<Building[]>(undefined);
    public housingsByAffair$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private partService: BuildingPartService,
        private formBuilder: FormBuilder,
        private commonService: CommonService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }

    public get(id: number): Observable<any> {
        return this.apiService.get(`${this.route}/${id}`);
    }

    public store(form: FormGroup): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return super.store(form.value, this.route)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            );
    }

    public update(id: number, form: FormGroup): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return super.update(id, form.value, this.route)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            );
    }

    public associateToPart(form: FormGroup): Observable<any> {
        this.submitButtonService.setDisabled(this.associateSubmitButton);
        return this.apiService.post(`${this.route}/associate`, form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.associateSubmitButton);
                })
            );
    }

    public getForm(param: BuildingFormParam) {
        const buildingTypeId = param.building && param.building.buildingType ?
            this.commonService.valueToFormSelect(param.building.buildingType.id) : this.commonService.getDefaultFormNullValue();
        const buildingPartId = param.building && param.building.buildingPart ?
            this.commonService.valueToFormSelect(param.building.buildingPart.id) : param.partId;
        const name = param.building ?
            this.commonService.valueToFormString(param.building.name) : this.commonService.getDefaultFormStringValue();
        let deliveryDate = this.commonService.getDefaultFormNullValue();
        if (param.building) {
            deliveryDate = this.commonService.valueToFormDate(param.building.deliveryDate)
        } else if (param.affair) {
            deliveryDate = this.commonService.valueToFormDate(param.affair.deliveryDate)
        }
        const housingNumber = param.building ?
            this.commonService.valueToFormNumber(param.building.housingNumber) : this.commonService.getDefaultFormNumberValue();
        const goal = param.building ?
            this.commonService.valueToFormNumber(param.building.goal) : this.commonService.getDefaultFormNumberValue();
        const result = param.building ?
            this.commonService.valueToFormNumber(param.building.result) : this.commonService.getDefaultFormNumberValue();
        const comment = param.building ?
            this.commonService.valueToFormString(param.building.comment) : this.commonService.getDefaultFormStringValue();
        let housings = [];
        if (param.building) {
            for (let house of param.building.housings) {
                housings.push(this.getHousingForm(house));
            }
        }
        return this.formBuilder.group({
            affairId: [param.affair.id, Validators.required],
            buildingTypeId: [buildingTypeId, Validators.required],
            buildingPartId: [buildingPartId],
            name: [name, Validators.required],
            deliveryDate: deliveryDate,
            housingNumber: housingNumber,
            goal: goal,
            result: result,
            comment: comment,
            housings: new FormArray(housings),
            indicatorsInterventionsTypes: new FormArray([])
        });
    }

    public getHousingForm(house?: any) {
        const id = house ? house.id : '_' + Math.random().toString(36).substr(2, 9);
        const name = house ?
            this.commonService.valueToFormString(house.name) : this.commonService.getDefaultFormStringValue();
        return this.formBuilder.group({
            id: [id, Validators.required],
            name: [name, Validators.required]
        })
    }

    public getByAffair(affairId) {
        this.apiService.get(`${this.route}/get-by-affair/${affairId}`)
            .subscribe(
                (buildings: Building[]) => {
                    this.buildingsByAffair$.next(buildings);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );

    }


    public getHousingsByAffair(affairId) {
        this.apiService.get(`${this.route}/housings/get-by-affair/${affairId}`)
            .subscribe(
                (housings) => {
                    this.housingsByAffair$.next(housings);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );

    }

    public getAssociateForm(buildingId: number) {
        return this.formBuilder.group({
            buildingId: [buildingId, Validators.required],
            buildingPartId: this.commonService.getDefaultFormNullValue()
        });
    }

    public setAssociateSubmitButton(submitButton: SubmitButton) {
        this.associateSubmitButton = submitButton;
    }


    public initInterventionsTypesSwitches(form: FormGroup, indicatorsInterventionsTypes: any, building?: Building, affair?: Affair) {
        indicatorsInterventionsTypes.forEach((indicator) => {
            let checked = false;
            if (building) {
                let found = building.indicatorsInterventionsTypes.find(x => x.id == indicator.id)
                if (found) {
                    checked = found.pivot.value;
                }
            } else if (affair) {
                let found = affair.documentsCustomersIds.some(x => indicator.documentsCustomersIds.includes(x));
                if (found) {
                    checked = true;
                }
            }
            const indicatorsInterventionsTypesFormArray = form.get('indicatorsInterventionsTypes') as FormArray;
            indicatorsInterventionsTypesFormArray.push(new FormGroup({
                checked: new FormControl(checked),
                indicatorInterventionTypeId: new FormControl(indicator.id),
            }))
        });
    }


    public formatSelect(buildings: Building[]): SelectModel[] {
        const buildingsSelect: SelectModel[] = [];
        buildings.forEach(building => {
            buildingsSelect.push({
                label: building.name,
                value: building.id
            })
        });
        return buildingsSelect;
    }
}
