import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-shuttle-sheet-equipment-template',
  templateUrl: './shuttle-sheet-equipment-template.component.html',
  styleUrls: ['./shuttle-sheet-equipment-template.component.scss']
})
export class ShuttleSheetEquipmentTemplateComponent implements OnInit {

  @Input() equipment: any;
  @Input() titlesEditable = false;
  @Input() selectsEditable = false;
  @Input() optionsEditable = false;
  @Input() selectsValues: any[];
  @Input() optionsValues: any[];
  @Output() radioValueChanged = new EventEmitter();
  @Output() editSelectValues = new EventEmitter();
  @Output() editTitleText = new EventEmitter();
  @Output() editOptionsValues = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onRadioValueChanged(equipmentKey: string, itemKey: string) {
    this.radioValueChanged.emit({
      equipmentKey: equipmentKey,
      itemKey: itemKey
    });
  }

  onEditSelectValues(key: string, showNoneOther: boolean) {
    this.editSelectValues.emit({
      key: key,
      showNoneOther: showNoneOther
    });
  }

  onEditTitleTextClicked(key: string) {
    this.editTitleText.emit(key);
  }

  onEditOptionsValues(key: string) {
    this.editOptionsValues.emit(key);
  }

}
