import Swal from 'sweetalert2';
import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AffairService, FileService, NotifService, SwalService } from '@libs/services/src';
import { AffairDocumentCustomerService } from '@libs/services/src/lib/affair-document-customer.service';
import { AffairDocumentDeliveredService } from '@libs/services/src/lib/affair-document-delivered.service';
import { AffairShowDocumentFiltersComponent, FiltersValues } from '../affair-show-document-filters/affair-show-document-filters.component';
import { HttpResponse } from '@angular/common/http';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AffairShowModalIdentifyDocumentComponent } from '../affair-show-modal-identify-document/affair-show-modal-identify-document.component';
import { Affair } from '@libs/models/src';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { typeaheadAnimation } from 'ngx-bootstrap/typeahead/typeahead-animations';
import { AffairMarketType } from '@libs/enum/src';
import { Subject, Subscriber, Subscription, zip } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap, switchMap } from 'rxjs/operators';
import { map } from 'jquery';
import { AffairShowModalRenameDocumentComponent } from '../affair-show-modal-rename-document/affair-show-modal-rename-document.component';

@Component({
  selector: 'app-affair-show-documents',
  templateUrl: './affair-show-documents.component.html',
  styleUrls: ['./affair-show-documents.component.scss']
})
export class AffairShowDocumentsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() affair: Affair;
  @Input() documents: any[];
  @Input() companies: any[];
  @Input() filters: any[];
  @Input() isDelivered = false;
  @Input() appKey: string = '';
  @Input() affairDocumentService: AffairDocumentCustomerService | AffairDocumentDeliveredService;
  @ViewChild('appDocumentFilters', { static: false }) filtersComponent: AffairShowDocumentFiltersComponent;
  public filtersForm: FormGroup;
  public filtersValues: FiltersValues = {
    research: '',
    selectedDocumentsTypes: [],
    selectedProducts: [],
    selectedVersions: [],
    selectedBuildings: [],
    showOthersCompanies: false
  };
  public currentTabIndex = 0;
  public selectedDocuments = [];
  public isAllSelected = false;
  public showOthersCompanies = false;
  public currentHoverIndex = -1;
  public documentToRename: any;
  public identifyForm: FormGroup;
  public unidentifiedDocuments: any[] = [];
  public filtersChanged = new Subject<string>();
  private zip$: Subscription;

  constructor(
    private fileService: FileService,
    private swalService: SwalService,
    private router: Router,
    private affairService: AffairService,
    private modalService: NzModalService,
    private formBuilder: FormBuilder,
    private affairDocumentCustomerService: AffairDocumentCustomerService,
    private notifService: NotifService,
  ) { }

  ngOnInit() {
    if (this.affair) {
      this.filtersForm = this.initFiltersForm();
      this.onChanges();

      if (this.appKey == 'nrj' || this.affair.marketTypeId == AffairMarketType.RENOVATION) {
        this.showOthersCompanies = true;
        this.filtersForm.patchValue({showOthersCompanies: true});
      }
    }
  }

  ngOnChanges(changes: any) {
    if (changes['documents'] && changes['documents'].previousValue != changes['documents'].currentValue) {
      this.selectedDocuments = [];
      if (this.isDelivered) {
        this.unidentifiedDocuments = this.getUnidentifiedDocuments();
      }
    }
  }

  ngOnDestroy(): void {
    this.zip$?.unsubscribe();
  }

  initFiltersForm(): FormGroup {
    return this.formBuilder.group({
      research: [''],
      selectedDocumentsTypes: [null],
      selectedProducts: [null],
      selectedVersions: [null],
      selectedBuildings: [null],
      showOthersCompanies: false,
    });
  }

  onSelectedTabIndexChange(index: number) {
    this.currentTabIndex = index;
    this.isAllSelected = false;
    this.selectedDocuments = [];
  }

  onImportClicked() {
    let type = 'customer';
    if (this.isDelivered) {
      type = 'delivered';
    }
    this.router.navigateByUrl(`affairs/${this.affair.id}/document-${type}/import`);
  }

  onDownloadClicked() {
    if (this.selectedDocuments.length === 1) {
      const document = this.getDocumentByPath(this.selectedDocuments[0]);
      if (document) {
        this.fileService.getBlob(document.path).subscribe(
          (file) => {
            const blob = new Blob([file], { type: document.mimeType });
            this.downloadDocument(blob, document);

          }
        );
      }
    }
    else if (this.selectedDocuments.length > 1) {
      this.fileService.downloadMultiple(this.selectedDocuments).subscribe(
        (response: HttpResponse<Blob>) => {
          const blob = new Blob([response.body], { type: 'application/zip' });
          this.downloadDocuments(blob);
        }
      );
    }
    this.selectedDocuments = [];
  }

  onDeleteClicked() {
    const swalOptions = this.swalService.getSwalDeleteOptions({
      text: 'Le(s) document(s) sélectionné(s) sera/seront supprimé(s) de manière irréversible.'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.affairDocumentService.delete(this.affair.id, this.selectedDocuments).subscribe(
          (success) => {
            this.notifService.showSuccessNotif(success);
            this.getDocuments();
            this.affairService.get(this.affair.id);
          }
        )
      }
    });
  }

  onRemaneClicked(doc: any) {
    this.documentToRename = doc;
    const modalInstance = this.modalService.create({
      nzContent: AffairShowModalRenameDocumentComponent,
      nzComponentParams: {
        affair: this.affair,
        document: this.documentToRename,
        affairDocumentService: this.affairDocumentService
      }
    });
    modalInstance.afterClose.subscribe(() => {
      this.getDocuments();
    });
  }

  onIdentifyDocumentClicked() {
    this.identifyForm = this.initIdentifyForm();
    this.initIdentifyDocumentsForm(this.identifyForm, this.unidentifiedDocuments);
    const modalInstance = this.modalService.create({
      nzContent: AffairShowModalIdentifyDocumentComponent,
      nzWidth: '70%',
      nzComponentParams: {
        documents: this.unidentifiedDocuments,
        affair: this.affair,
        form: this.identifyForm
      }
    })
    modalInstance.afterClose.subscribe(() => {
      //this.affairDocumentCustomerService.getFilters(this.affair.id, this.showOthersCompanies);
      //this.affairDocumentCustomerService.getAll(this.affair.id, this.filtersValues);
    });
  }

  onSelectAll() {
    this.selectedDocuments = [];
    if (this.isAllSelected) {
      for (const companie of this.companies) {
        for (const doc of this.documents[companie]) {
          this.selectedDocuments.push(doc.path);
        }
      }
    }
  }

  onThumbnailSelected(document: any) {
    if (this.selectedDocuments.includes(document.path)) {
      this.selectedDocuments = this.selectedDocuments.filter(e => e !== document.path);
    } else {
      this.selectedDocuments.push(document.path);
    }
  }

  isSelected(path): boolean {
    return this.selectedDocuments.includes(path);
  }

  onRefreshDocuments() {
    this.getDocuments();
  }

  getDocuments() {
    this.zip$ = zip(
        this.affairDocumentService.getAll(this.affair.id, this.filtersForm),
        this.affairDocumentService.getFilters(this.affair.id, this.showOthersCompanies)
    ).subscribe(([documents, filters]) => {
      this.documents = documents;
      this.filters = filters;
      this.companies = Object.keys(documents);
      this.unidentifiedDocuments = this.getUnidentifiedDocuments();
    });
  }

  onChanges() {
    this.filtersForm.valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged()
    )
    .subscribe(form => {
      this.getDocuments();
    });
  }


  public onShowOthersCompaniesChanged() {
    this.filtersForm.patchValue({showOthersCompanies: this.showOthersCompanies});
  }

  private getDocumentByPath(path: string) {
    for (let companie of this.companies) {
      for (let document of this.documents[companie]) {
        if (document.path === path) {
          return document;
        }
      }
    }
    return null;
  }

  private downloadDocument(blob: Blob, doc: any) {
    const downloadUrl = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    if (typeof a.download === 'undefined') {
      window.location.href = downloadUrl;
    } else {
      a.href = downloadUrl;
      a.download = doc.basename;
      document.body.appendChild(a);
      a.click();
    }
  }

  private downloadDocuments(blob: Blob) {
    const downloadUrl = window.URL.createObjectURL(blob);
    window.open(downloadUrl);
  }

  private initIdentifyForm(): FormGroup {
    return this.formBuilder.group({
      documents: new FormArray([])
    });
  }

  private initIdentifyDocumentsForm(form: FormGroup, unidentifiedDocuments: any[]) {
    const documentsArray = form.get('documents') as FormArray;
    const lastAffairProductId = this.affair.affairProducts.length > 0 ?
    this.affair.affairProducts[this.affair.affairProducts.length - 1].id : null;
    unidentifiedDocuments.forEach((doc) => {
      documentsArray.push(new FormGroup({
        path: new FormControl(doc.path),
        affairProductId: new FormControl(lastAffairProductId),
        documentId: new FormControl(null),
      }))
    })
  }

  private getUnidentifiedDocuments() {
    if (this.documents[this.companies[1]] !== undefined) {
      let documents = this.documents[this.companies[1]];
      return documents;
    }
  }


}
