import { ApiService } from '@eros-front/api';
import { ModelWithDatatableAndModalCrud } from './classes/model-datatable-modal-crud';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { Router } from '@angular/router';
import { SendSetting, SendSettingType, StandardResponse } from '@libs/models/src';
import { FormFormatterService } from './utilities/form-formatter.service';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';

@Injectable()
export class SendSettingTypeService extends ModelWithDatatableAndCrudObservable {

    public route = '/send-settings-types';

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route);
    }

    public getAll(): Observable<any> {
        return super.getAll(this.route);
    }

    public store(form: any): Observable<any> {
        return super.store(form.value, this.route);
    }

    public update(id: number, form: any): Observable<any> {
        return super.update(id, form.value, this.route);
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route);
    }

    public getForm(sendSettingType?: SendSettingType) {
        const key = sendSettingType ?
            this.commonService.valueToFormString(sendSettingType.key) : this.commonService.getDefaultFormStringValue();
        const name = sendSettingType ?
            this.commonService.valueToFormString(sendSettingType.name) : this.commonService.getDefaultFormStringValue();
        const icon = sendSettingType ?
            this.commonService.valueToFormString(sendSettingType.icon) : this.commonService.getDefaultFormStringValue()
        return this.formBuilder.group({
            key: [key, Validators.required],
            name: [name, Validators.required],
            icon: [icon, Validators.required]
        });
    }
}
