import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AffairProductService } from '@libs/services/src';

@Component({
  selector: 'app-affairs-products-completed-list',
  templateUrl: './affairs-products-completed-list.component.html',
  styleUrls: ['./affairs-products-completed-list.component.scss']
})
export class AffairsProductsCompletedListComponent implements OnInit {
  public affairProducts: any = [];
  @Input() form: FormGroup;
  @Output() isInit = new EventEmitter<any>();

  constructor(
    private affairProductService: AffairProductService,
    private router: Router
  ) { }

  ngOnInit() {
    this.initList();
    this.form.valueChanges.subscribe(form => {
      this.initList();
    });
  }

  private initList() {
    this.affairProductService.initCompletedList(this.form).subscribe(
      (data) => {
        if (data) {
          this.affairProducts = data;
          this.isInit.emit();
        }
      }
    )
  }

  onRowClicked(data): void {
    this.router.navigateByUrl('/affairs/' + data.affairId + '/show');
  }

}
