import { ContactDatatableParameters } from '@eros-front/services';
import { AgencyDatatableParameters } from '@eros-front/services';
import { data } from 'jquery';
import { RegionService } from '@eros-front/services';
import { BrandService, BrandSelectParam, BrandDatatableParameters } from '@eros-front/services';
import { GroupService, GroupDatatableParameters } from '@eros-front/services';
import { CustomerService } from '@eros-front/services';
import { Subscription, Subject, zip } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AgencyService, AgencySelectParameters } from '@eros-front/services';
import { flatMap, switchMap, mergeMap, map } from 'rxjs/operators';
import { ContactService } from '@eros-front/services';

@Component({
  selector: 'app-customers-consult',
  templateUrl: './customers-consult.component.html',
  styleUrls: ['./customers-consult.component.scss']
})
export class CustomersConsultComponent implements OnInit, OnDestroy {
  // Group select
  public groups = [];
  public selectGroup$: Subscription;
  public selectedGroups: any[] = [];
  public selectedGroupsItems = [];
  public maxSelectedGroups = 3;
  public groupDatatableParameters: GroupDatatableParameters;
  // Brand select
  public brands = [];
  public selectBrand$: Subscription;
  public selectedBrands: any[] = [];
  public maxSelectedBrands = 3;
  public selectBrandsParams = {} as BrandSelectParam;
  public brandDatatableParameters: BrandDatatableParameters;
  // Agency select
  public agencies = [];
  public selectAgency$: Subscription;
  public selectedAgencies: any[] = [];
  public maxSelectedAgencies = 3;
  public selectAgenciesParameters = {} as AgencySelectParameters;
  public agencyDatatableParameters: AgencyDatatableParameters;
  // Agency select
  public contacts = [];
  public selectContact$: Subscription;
  public selectedContacts: any[] = [];
  public maxSelectedContacts = 3;
  public contactDatatableParameters: ContactDatatableParameters;

  public GROUP_LEVEL = 1;
  public BRAND_LEVEL = 2;
  public AGENCY_LEVEL = 3;
  public CONTACT_LEVEL = 4;
  public activeLevel = 1;

  public ajaxParameters: any;

  constructor(
    private customerService: CustomerService,
    private groupService: GroupService,
    private brandService: BrandService,
    private agencyService: AgencyService,
    private contactService: ContactService
  ) { }

  ngOnInit() {
    this.initValues();
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.selectContact$.unsubscribe();
  }

  initValues() {
    //this.groupService.getForSelectWithParams();
  }

  initSubscriptions() {
    this.selectContact$ = this.contactService.contactsSelect$.subscribe(
      (data) => {
        if (data) {
          this.contacts = data.data;
          this.selectedContacts = data.selected;
          this.redrawDatatableLevel();
        }
      });

    zip(
      this.groupService.groupsSelect$,
      this.brandService.brandsSelect$,
      this.agencyService.agenciesSelect$,
      function (s1, s2, s3) {
        const tab = [];
        tab[0] = s1;
        tab[1] = s2;
        tab[2] = s3;
        return tab;
      }
    ).subscribe(([res1, res2, res3]) => {
      if (res1) {
        this.groups = res1.data;
        this.selectedGroups = res1.selected;
      }
      if (res2) {
        this.brands = res2.data;
        this.selectedBrands = res2.selected;
      }
      if (res3) {
        this.agencies = res3.data;
        this.selectedAgencies = res3.selected;
      }
      if (res1 != null && res2 != null && res3 != null) {
        this.contactService.applyFilters(res1.selected, res2.selected, res3.selected);
      }
    });
  }

  onSelectGroupsChange($event) {
    this.selectedGroups = $event;
    this.selectedGroupsItems = $event.map(x => x.value);
    this.groupService.setSelectedGroupsItems(this.selectedGroupsItems);
    this.updateSelectBrands(this.selectedGroupsItems);
  }

  onSelectBrandsChange($event) {
    this.selectedBrands = $event;
    const selectedBrandsItems = $event.map(x => x.value);
    this.updateSelectAgencies(selectedBrandsItems);
  }


  updateSelectBrands(selectedGroupsItems) {
    this.selectBrandsParams.selectedGroups = selectedGroupsItems;
    //this.brandService.getForSelectWithParams(this.selectBrandsParams);
  }

  updateSelectAgencies(selectedBrandsItems) {
    this.customerService.getAgencies(selectedBrandsItems);
  }

  changeLevel(level) {
    this.activeLevel = level;
    this.redrawDatatableLevel();
  }

  redrawDatatableLevel() {
    if (this.activeLevel === this.GROUP_LEVEL) {
      this.groupDatatableParameters = {
        selectedGroups: this.selectedGroups.map(x => x.value),
      };
      this.groupService.redrawDataTable(this.groupDatatableParameters);
    }
    if (this.activeLevel === this.BRAND_LEVEL) {
      this.brandDatatableParameters = {
        selectedBrands: this.selectedBrands.map(x => x.value),
      };
      this.brandService.redrawDataTable(this.brandDatatableParameters);
    }
    if (this.activeLevel === this.AGENCY_LEVEL) {
      this.agencyDatatableParameters = {
        selectedAgencies: this.selectedAgencies.map(x => x.value),
      };
      this.agencyService.redrawDataTable(this.agencyDatatableParameters);
    }
    if (this.activeLevel === this.CONTACT_LEVEL) {
      this.contactDatatableParameters = {
        selectedContacts: this.selectedContacts.map(x => x.value),
      };
      this.contactService.redrawDataTable(this.contactDatatableParameters);
    }
  }

}
