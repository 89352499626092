import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OfficeFileIndicator, SubmitButton } from '@libs/models/src';
import { OfficeFileIndicatorService } from '@libs/services/src/lib/office-file-indicator.service';
import { OfficeFileService } from '@libs/services/src/lib/office-file.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-offices-files-indicators-modal',
  templateUrl: './offices-files-indicators-modal.component.html',
  styleUrls: ['./offices-files-indicators-modal.component.scss']
})
export class OfficesFilesIndicatorsModalComponent implements OnInit {
  @Input() officeFileIndicator: OfficeFileIndicator;
  @Input() documentsCustomers: any;
  @Input() marketsTypes: any;
  @Input() form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private officeFileIndicatorService: OfficeFileIndicatorService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.officeFileIndicatorService.setSubmitButton(this.submitButton);
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.officeFileIndicator) {
        this.officeFileIndicatorService.update(this.officeFileIndicator.id, this.form).subscribe(() => {
          this.modal.close();
        });
      } else {
        this.officeFileIndicatorService.store(this.form).subscribe(() => {
          this.modal.close();
        });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.officeFileIndicatorService.determinateSubmitButton(this.officeFileIndicator, 'l\'indicateur fiche bureau');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }
}
