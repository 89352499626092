import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';
import { SubmitButton, MlAgency, Region } from '@eros-front/models';
import { MlAgencyService } from '@eros-front/services';

@Component({
  selector: 'app-settings-invoicing-ml-agencies-modal',
  templateUrl: './settings-invoicing-ml-agencies-modal.component.html',
  styleUrls: ['./settings-invoicing-ml-agencies-modal.component.scss']
})
export class SettingsInvoicingMlAgenciesModalComponent implements OnInit, AfterViewInit {

  @Input() mlAgency: MlAgency;
  @Input() regions: Region[];
  @ViewChild('settingsAddEditMlAgency', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private mlAgencyService: MlAgencyService
  ) { }

  ngOnInit() {
    this.initValues();
  }

  ngAfterViewInit() {
    this.mlAgencyService.setModalAddEdit(this.childModal);
  }

  onModalShown() {
    this.determinateSubmitButton();
    this.mlAgencyService.setSubmitButton(this.submitButton);
    this.form = this.mlAgencyService.getForm(this.mlAgency);
  }

  onModalHidden() {
    this.initValues();
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.mlAgency) {
        this.mlAgencyService.update(this.mlAgency.id, this.form);
      } else {
        this.mlAgencyService.store(this.form);
      }
    }
  }

  private initValues() {
    this.mlAgency = null;
    this.form = null;
  }

  private determinateSubmitButton() {
    this.submitButton = this.mlAgencyService.determinateSubmitButton(this.mlAgency, 'l\'agence ML');
  }

  private isValidForm(): boolean {
    return this.form.valid;
  }

}
