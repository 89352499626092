import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { InterventionService, SwalService } from '@libs/services/src';
import { DashboardService } from '@libs/services/src/lib/dashboard.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-affair-products-to-program-list',
  templateUrl: './affair-products-to-program-list.component.html',
  styleUrls: ['./affair-products-to-program-list.component.scss']
})
export class AffairProductsToProgramListComponent implements OnInit {
  @Input() statistics: any = [];
  public filters: [];
  @Input() form: FormGroup;
  @Input() index: number;
  @Input() marketTypeId: number;
  @Output() isInit = new EventEmitter<any>();

  constructor(
    private interventionService: InterventionService,
    private dashboardService: DashboardService,
    private router: Router,
    private swalService: SwalService
  ) {
  }

  ngOnInit() {
  }

  private initList() {
    this.interventionService.initAffairProductsList(this.form)
      .pipe(
        catchError(error => {
          this.swalService.showSwalError(error);
          this.isInit.emit();
          return throwError(error);
        }),
      ).subscribe(
        (data) => {
          if (data) {
            this.statistics = data;
            this.isInit.emit();
          }
        }
      )
  }

  onCardStatsClicked(index): void {
    this.filters = this.statistics[index].filters;
    this.dashboardService.setDashboardFilters(this.filters);
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`affairs/index`, {
        'activeTab': this.marketTypeId
      }])
    );
    window.open(url, '_blank');
  }
}
