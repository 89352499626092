import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Contact, Invoice, Quotation, StandardResponse, SubmitButton } from '@libs/models/src';
import { AffairStandardResponseService, MailService, NotifService, StandardResponseService, SubmitButtonService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'eros-front-document-modal-standard-response',
  templateUrl: './document-modal-standard-response.component.html',
  styleUrls: ['./document-modal-standard-response.component.scss']
})
export class DocumentModalStandardResponseComponent implements OnInit, OnDestroy {

  @Input() document: Invoice | Quotation;
  public form: FormGroup;
  public standardsResponses: StandardResponse[] = [];
  public contacts: Contact[];
  public selectedDocuments = [];
  public submitButton: SubmitButton;
  public isDestroyed$ = new Subject<void>();
  public isLoading = false;

  constructor(
    private modal: NzModalRef,
    private mailService: MailService,
    private standardResponseService: StandardResponseService,
    private submitButtonService: SubmitButtonService,
    private notifService: NotifService,
    private affairStandardResponseService: AffairStandardResponseService,
  ) {
    this.initSubscriptions();
  }

  ngOnInit(): void {
    this.form = this.mailService.initForm();
    this.isLoading = true;
    this.standardResponseService.getAll()
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        (data) => {
          this.standardsResponses = data;
        }
      )
    this.contacts = this.document.agency?.contacts;
    this.setSubmitButton();

    if (this.form) {
      this.selectedDocuments.push(this.document.path);
      this.form.patchValue({
        attachments: this.selectedDocuments
      });
    }
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$?.complete();
  }

  onStandardResponseChange(standardResponse: StandardResponse): void {
    this.form.patchValue({
      'subject': standardResponse.subject,
      'content': standardResponse.body,
    });
    if (this.document.affairs && this.document.affairs.length == 1) {
      const affairId = this.document.affairs[0].id;
      this.affairStandardResponseService.getRecipients(affairId, this.form);
      this.affairStandardResponseService.replaceTextTags(affairId, this.form);
    }
  }

  isValidForm(): boolean {
    return this.form.valid;
  }

  submitForm(): void {
    if (this.isValidForm()) {
      this.mailService.send(this.form).subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        }
      );
    }
  }

  private initSubscriptions() {
    this.affairStandardResponseService.textReplaced$.subscribe(
      (data) => {
        if (data) {
          if (this.form) {
            this.form.patchValue({
              subject: data.subject,
              content: data.content
            });
          }
        }
      }
    );
    this.affairStandardResponseService.recipients$.subscribe(
      (data) => {
        if (data) {
          if (this.form) {
            this.form.patchValue({
              recipients: data.to,
              recipientsCopy: data.copy
            });
          }
        }
      }
    )
  }

  private setSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'un mail',
      text: 'Envoyer',
      icon: faPaperPlane
    });
    this.mailService.setSubmitButton(this.submitButton);
  }

}
