import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BrandService } from '@eros-front/services';
import { SubmitButton } from '@eros-front/models';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-customers-consult-graph-modal-brand',
  templateUrl: './customers-consult-graph-modal-brand.component.html',
  styleUrls: ['./customers-consult-graph-modal-brand.component.scss']
})
export class CustomersConsultGraphModalBrandComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() group: any;
  @Input() brand: any;
  @Input() companies: any[];
  @Input() marketsTypes: any[];
  @Input() customersTypes: any[];
  @Input() indicatorsCustomers: any[];
  @Input() products: any[];
  @Input() managers: any[];
  @Input() rivals: any[];
  @ViewChild('customersConsultGraphAddEditBrand', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public submitButton: SubmitButton;
  public displayedProducts = [];
  public selectedPriceMarketTypeText = '';

  constructor(
    private brandService: BrandService
  ) { }

  ngOnInit() {
    this.initValues();
  }

  ngAfterViewInit() {
  }

  ngOnChanges() {
    if (this.marketsTypes.length > 0 && this.selectedPriceMarketTypeText.length === 0) {
      this.selectedPriceMarketTypeText = this.marketsTypes[0].label;
    }
    if (this.products.length > 0 && this.displayedProducts.length === 0) {
      for (const p of this.products) {
        this.displayedProducts.push({
          id: p.id,
          name: p.name,
          visible: true
        });
      }
    }
  }

  onModalShown() {
    this.determinateSubmitButton();
    this.brandService.setSubmitButton(this.submitButton);
    this.form = this.brandService.getForm({
      companies: this.companies,
      marketsTypes: this.marketsTypes,
      products: this.products
    }, this.brand);
  }

  onModalHidden() {
    this.initValues();
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    // if (this.isValidForm()) {
    //   if (this.brand) {
    //     this.brandService.update(this.brand.id, this.form);
    //   } else {
    //     this.brandService.store(this.form);
    //   }
    // }
    console.log(this.form.value);
  }

  isValidForm() {
    return this.form.valid;
  }

  private determinateSubmitButton() {
    this.submitButton = this.brandService.determinateSubmitButton(this.brand, 'la marque');
  }

  private initValues() {
    this.form = null;
  }

}
