import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { ModelWithDatatableAndModalCrud } from './classes/model-datatable-modal-crud';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SubmitButton } from '@eros-front/models';
import { SwalService } from './utilities/swal.service';
import { catchError, finalize } from 'rxjs/operators';
import { CommonService } from './utilities/common.service';

@Injectable()
export class AffairInvoicingPreferenceService extends ModelWithDatatableAndModalCrud {

    private route = '/affair-invoicing-preferences';
    public submitButton: SubmitButton

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public store(form: any): void {
        this.submitButtonService.setDisabled(this.submitButton);
        this.apiService.post(this.route, form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
            .subscribe(
                (success) => {
                    this.swalService.showSwalSuccess(success);
                }
            );
    }

    public update(id: number, form: any): void {
        this.submitButtonService.setDisabled(this.submitButton);
        this.apiService.put(this.route + '/' + id, form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
            .subscribe(
                (success) => {
                    this.swalService.showSwalSuccess(success);
                }
            );
    }


    public getForm(affair: any): FormGroup {
        let mlAgencyId = null;
        let payerPreference = '';
        let payerName = '';
        let payerAccountingCode = '';
        let payerAddress = '';
        let payerPostalCode = '';
        let payerLocality = '';
        let affairInvoicing = this.commonService.getDefaultFormRadioValue();
        let invoiceInAdvance = this.commonService.getDefaultFormRadioValue();
        let billingPreference = '';
        if (affair) {
            const pref = affair.affairInvoicingPreferences;
            mlAgencyId = affair.mlAgencyId;
            if (pref != null) {
                payerPreference = pref.payerPreference.toString();
                payerName = pref.payerName;
                payerAccountingCode = pref.payerAccountingCode;
                payerAddress = pref.payerAddress;
                payerPostalCode = pref.payerPostalCode;
                payerLocality = pref.payerLocality;
                affairInvoicing = this.commonService.valueToRadioFormString(pref.affairInvoicing);
                billingPreference = pref.billingPreference;
                invoiceInAdvance =  this.commonService.valueToRadioFormString(pref.invoiceInAdvance);
            }
        }
        return this.formBuilder.group({
            affairId: [affair.id, Validators.required],
            mlAgencyId: [mlAgencyId, Validators.required],
            payerPreference: [payerPreference, Validators.required],
            payerName: [payerName, Validators.required],
            payerAccountingCode: [payerAccountingCode, Validators.required],
            payerAddress: [payerAddress, Validators.required],
            payerPostalCode: [payerPostalCode, Validators.required],
            payerLocality: [payerLocality, Validators.required],
            affairInvoicing: affairInvoicing,
            invoiceInAdvance: invoiceInAdvance,
        });
    }

    public setSubmitButton(button: SubmitButton) {
        this.submitButton = button;
    }
}
