import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AffairMarketType } from '@libs/enum/src';
import { AffairGoal, AffairStatus, SelectModel } from '@libs/models/src';
import { InterventionService } from '@libs/services/src';
import { CustomLocalStorageService } from '@libs/services/src/lib/utilities/custom-local-storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-interventions-tabs',
  templateUrl: './interventions-tabs.component.html',
  styleUrls: ['./interventions-tabs.component.scss']
})
export class InterventionsTabsComponent implements OnInit {

  public assistants: any[];
  public technicians: any[];
  public agencies: any[];
  public conformities: any[];
  public interventionsStatuses: any[];
  public indicatorsSearchValues: any[] = [];
  public products: any[];
  public productsStatuses: any[];
  public documentsCustomers: SelectModel[];
  public interventionsSelects$: Subscription;
  public interventionsSelect: any[];
  public affairsGoals: AffairGoal[];
  public affairsStatuses: AffairStatus[];
  public marketsTypes: any[];
  public affairsMonitoringTypes: SelectModel[];
  public selectedMarketsTypesIds: any;
  public marketTypeEnum = AffairMarketType;
  public activeTab: number;
  public tabs = [
    {
      ngbNavItem: 'ALL',
      marketTypeId: null,
      title: 'Toutes',
      icon: 'fas fa-list',
      isDisplay: true,
    }, 
    {
      ngbNavItem: this.marketTypeEnum.STANDALONE_HOUSE,
      marketTypeId: this.marketTypeEnum.STANDALONE_HOUSE,
      title: 'Maison Individuelle',
      icon: 'tll fa-mi-market',
      isDisplay: this.isDisplayTab(this.marketTypeEnum.STANDALONE_HOUSE),
    },
    {
      ngbNavItem: this.marketTypeEnum.COLLECTIVE,
      marketTypeId: this.marketTypeEnum.COLLECTIVE,
      title: 'Collectif - Tertiaire',
      icon: 'tll fa-collective-market',
      isDisplay: this.isDisplayTab(this.marketTypeEnum.COLLECTIVE),
    },
    {
      ngbNavItem: this.marketTypeEnum.RENOVATION,
      marketTypeId: this.marketTypeEnum.RENOVATION,
      title: 'Rénovation',
      icon: 'tll fa-home-renovation',
      isDisplay: this.isDisplayTab(this.marketTypeEnum.RENOVATION),
    },
    {
      ngbNavItem: this.marketTypeEnum.INSPECTION,
      marketTypeId: this.marketTypeEnum.INSPECTION,
      title: 'Inspection',
      icon: 'fas fa-search',
      isDisplay: this.isDisplayTab(this.marketTypeEnum.INSPECTION),
    }
  ]

  constructor(
    private interventionService: InterventionService,
    private localStorage: CustomLocalStorageService,
    private route: ActivatedRoute
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.initInterventionsSelects();
    this.selectedMarketsTypesIds = this.localStorage.getSelectedMarketsTypesIds();
  }

  private initInterventionsSelects() {
    this.interventionService.getSelects();
  }

  private initSubscriptions() {
    this.interventionsSelects$ = this.interventionService.interventionsSelects$.subscribe(
      (data) => {
        if (data) {
          this.interventionsSelect = data;
          this.assistants = data.assistants;
          this.technicians = data.technicians;
          this.agencies = data.agencies;
          this.conformities = data.conformities;
          this.interventionsStatuses = data.interventionsStatuses;
          this.products = data.products;
          this.productsStatuses = data.productsStatuses;
          this.documentsCustomers = data.documentsCustomers;
          this.affairsGoals = data.affairsGoals;
          this.affairsStatuses = data.affairsStatuses;
          this.marketsTypes = data.marketsTypes;
          this.affairsMonitoringTypes = data.affairsMonitoringTypes;
        }
      }
    );
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = null
      if (params.has('activeTab')) {
        id = Number(params.get('activeTab'))
      }
      if (!isNaN(id) && id != null) {
        this.activeTab = id;
      }
    });
  }

  public isDisplayTab(marketTypeId) {
    if (this.selectedMarketsTypesIds) {
      return this.selectedMarketsTypesIds.includes(marketTypeId);
    } 
    return false;
  }

}
