// Angular Core
import { Injectable, NgModule, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';

// Configs
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { fr } from 'date-fns/locale';
import { NZ_I18N, fr_FR, NZ_DATE_LOCALE, NzI18nService } from 'ng-zorro-antd/i18n';
import { MESSAGES_PIPE_FACTORY_TOKEN, MESSAGES_PROVIDER, NgxValidationErrorsModule } from '@xtream/ngx-validation-errors';
import { TranslateLoader, TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
export function httpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
export function translatePipeFactoryCreator(translateService: TranslateService) {
  return (detector: ChangeDetectorRef) => new TranslatePipe(translateService, detector);
}
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import localeFr from '@angular/common/locales/fr';

// Pages Components
import { RootLayout } from '@pages/layouts/root/root.component';
import { SidebarComponent } from '@pages/components/sidebar/sidebar.component';
import { QuickviewComponent } from '@pages/components/quickview/quickview.component';
import { SearchOverlayComponent } from '@pages/components/search-overlay/search-overlay.component';
import { HeaderComponent } from '@pages/components/header/header.component';
import { HorizontalMenuComponent } from '@pages/components/horizontal-menu/horizontal-menu.component';

// Basic Bootstrap Modules
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// Pages Modules
import { pgCardModule } from '@pages/components/card/card.module';
import { pgTabsModule } from '@pages/components/tabs/tabs.module';
import { pgListViewModule } from '@pages/components/list-view/list-view.module';
import { pgCardSocialModule } from '@pages/components/card-social/card-social.module';
import { pgSwitchModule } from '@pages/components/switch/switch.module';
import { SharedModule as pgSharedModule } from '@pages/components/shared.module';

// Zorro Modules
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';

// Modules
import { NgSelectModule } from '@ng-select/ng-select';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { QuillModule } from 'ngx-quill';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MglTimelineModule } from 'angular-mgl-timeline';
import { NgxMaskModule } from 'ngx-mask';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { NgxEchartsModule } from 'ngx-echarts';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { WebdatarocksPivotModule } from 'ng-webdatarocks';
import { ImageCropperModule } from 'ngx-image-cropper';

// Libs
import { LayoutsModule } from '@eros-front/layouts';
import { ServicesModule } from '@eros-front/services';
import { ComponentsModule } from '@eros-front/components';
import { TokenInterceptor } from '@libs/api/src/lib/token-interceptor.component';

// Services
import { pagesToggleService } from '@pages/services/toggler.service';
import { QuickviewService } from '@pages/components/quickview/quickview.service';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { NzMenuModule } from 'ng-zorro-antd/menu';



const ngZorroConfig: NzConfig = {
  modal: { nzMaskClosable: false },
};

// Hammer Config Overide
// https://github.com/angular/angular/issues/10541
@Injectable()
export class AppHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'pinch': { enable: false },
    'rotate': { enable: false }
  };
}
@NgModule({
  imports: [
    CommonModule,
    TooltipModule.forRoot(),
    TabsModule.forRoot(),
    TypeaheadModule.forRoot(),
    ModalModule.forRoot(),
    NgxValidationErrorsModule.forRoot(),
    BsDropdownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true
    }),
    NgxMaskModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    QuillModule.forRoot(),
    MatCheckboxModule,
    WebdatarocksPivotModule
  ],
  declarations: [
    RootLayout,
    SidebarComponent,
    QuickviewComponent,
    SearchOverlayComponent,
    HeaderComponent,
    HorizontalMenuComponent
  ],
  exports: [
    //Pages Components
    RootLayout,
    SidebarComponent,
    QuickviewComponent,
    SearchOverlayComponent,
    HeaderComponent,
    HorizontalMenuComponent,
    //Angular Core
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    //Basic Bootstrap Modules
    NgbModule,
    TooltipModule,
    TabsModule,
    TypeaheadModule,
    ModalModule,
    BsDropdownModule,
    //Pages Modules
    pgCardModule,
    pgTabsModule,
    pgListViewModule,
    pgCardSocialModule,
    pgSwitchModule,
    pgSharedModule,
    //Zorro Modules
    NzDatePickerModule,
    NzTimePickerModule,
    NzInputModule,
    NzPopconfirmModule,
    NzSkeletonModule,
    NzTabsModule,
    NzIconModule,
    NzTableModule,
    NzAffixModule,
    NzModalModule,
    NzDropDownModule,
    NzTagModule,
    NzCheckboxModule,
    NzButtonModule,
    NzStepsModule,
    NzBadgeModule,
    NzListModule,
    NzToolTipModule,
    NzCollapseModule,
    NzDividerModule,
    NzResultModule,
    NzRadioModule,
    NzDescriptionsModule,
    //Others Modules
    NgSelectModule,
    NgxValidationErrorsModule,
    TranslateModule,
    GooglePlaceModule,
    PerfectScrollbarModule,
    QuillModule,
    FontAwesomeModule,
    NgxDropzoneModule,
    MglTimelineModule,
    NgxMaskModule,
    CalendarModule,
    DragAndDropModule,
    NgxEchartsModule,
    MatSidenavModule,
    MatCheckboxModule,
    WebdatarocksPivotModule,
    ImageCropperModule,
    //Libs
    LayoutsModule,
    ServicesModule,
    ComponentsModule
  ],
  providers: [
    pagesToggleService,
    QuickviewService,
    NzI18nService,
    {
      provide: MESSAGES_PIPE_FACTORY_TOKEN,
      useFactory: translatePipeFactoryCreator,
      deps: [TranslateService]
    },
    {
      provide: MESSAGES_PROVIDER,
      useExisting: TranslateService
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: AppHammerConfig
    },
    {
      provide: NZ_I18N,
      useValue: fr_FR
    },
    {
      provide: NZ_DATE_LOCALE,
      useValue: fr_FR
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: NZ_CONFIG,
      useValue: ngZorroConfig
    }
  ]
})
export class SharedModule {

  constructor(
    private i18n: NzI18nService,
    private translateService: TranslateService
  ) {
    this.setDatepickerLanguage();
    this.translateService.setDefaultLang('fr');
    registerLocaleData(localeFr);

  }

  setDatepickerLanguage() {
    this.i18n.setDateLocale(fr);
  }
}
