import { Permission } from '@eros-front/models';
import { ModelWithDatatableAndCrud } from './classes/model-datatable-crud';
import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { CommonService } from './utilities/common.service';

@Injectable()
export class PermissionService extends ModelWithDatatableAndCrud {

    private route = '/permissions';
    private redirectUrl = '/admin/permissions/index';
    public permission$ = new BehaviorSubject<any>(undefined);
    public permissions$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private commonService: CommonService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): void {
        super.get(id, this.permission$, this.route);
    }

    public getAll(): void {
        super.getAll(this.permissions$, this.route);
    }

    public store(form: any): void {
        super.store(form.value, this.route, this.redirectUrl);
    }

    public update(id: number, form: any): void {
        super.update(id, form.value, this.route, this.redirectUrl);
    }

    public delete(id: number): void {
        super.delete(id, this.route);
    }

    public getForm(permission?: Permission): FormGroup {
        const name = permission ?
            this.commonService.valueToFormString(permission.name) : this.commonService.getDefaultFormStringValue();
        const displayName = permission ?
            this.commonService.valueToFormString(permission.displayName) : this.commonService.getDefaultFormStringValue();
        const description = permission ?
            this.commonService.valueToFormString(permission.description) : this.commonService.getDefaultFormStringValue();
        return this.formBuilder.group({
            name: [name, Validators.required],
            displayName: [displayName, Validators.required],
            description: [description, Validators.required]
        });
    }
}
