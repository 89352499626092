import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { Intervention, SubmitButton } from '@libs/models/src';
import { InterventionService, NotifService, SubmitButtonService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-intervention-edit-cancel-modal',
  templateUrl: './intervention-edit-cancel-modal.component.html',
  styleUrls: ['./intervention-edit-cancel-modal.component.scss']
})
export class InterventionEditCancelModalComponent implements OnInit {
  @Input() intervention: Intervention;
  @Input() shiftOrigins;
  @Input() form: FormGroup;
  public submitButton: SubmitButton;
  public faBan = faBan;

  constructor(
    private modal: NzModalRef,
    private interventionService: InterventionService,
    private submitButtonService: SubmitButtonService,
    private notifService: NotifService
  ) { }

  ngOnInit() {
    this.setSubmitButton();
  }


  private setSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'l\'intervention',
      text: 'Annuler',
      icon: faBan
    });
    this.interventionService.setSubmitButtonCancel(this.submitButton);
  }

  submitCancelIntervention(): void {
    if (this.isValidForm()) {
      this.interventionService.cancel(this.intervention.id, this.form).subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        }
      )
    }
  }

  public isValidForm(): boolean {
    if (this.form) {
      return this.form.valid;
    }
    return false;
  }
}
