import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CustomerService } from '@libs/services/src';
import { Subscription } from 'rxjs';
import cytoscape from 'cytoscape';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customers-show-graph',
  templateUrl: './customers-show-graph.component.html',
  styleUrls: ['./customers-show-graph.component.scss']
})
export class CustomersShowGraphComponent implements OnInit, OnDestroy {
  @Input() customer: any;
  private graph$: Subscription;
  public nodes = [];
  public edges = [];
  public layout;
  public searchList = [];
  public graph: cytoscape.Core;
  public customerRef: string;

  constructor(
    private customerService: CustomerService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getValues();
    this.showGraph();
  }

  ngOnDestroy() {
    this.graph$.unsubscribe();
  }

  private getValues() {
    this.customerRef = this.customer.ref
    this.customerService.getGraph(this.customerRef);
    this.customerService.getGroupByCustomerRef(this.customerRef);
  }

  onGraphInitComplete(graph: cytoscape.Core): void {
    const that = this;
    this.graph = graph;
    this.graph.resize();
    this.graph.fit();
    this.setZoomAndCenterGraph(this.customerRef);

    this.graph.on('click', 'node', function (evt) {
      const clickedRef = this.id();
      that.router.navigateByUrl('/customers/' + clickedRef + '/show');
    });
  }

  private setZoomAndCenterGraph(ref: string): void {
    const elem = this.graph.getElementById(ref);
    this.graph.zoom(0.75);
    this.graph.center(elem);
  }

  private showGraph() {
    this.graph$ = this.customerService.graph$.subscribe((graph) => {
      if (graph) {
        this.nodes = graph.nodes;
        this.edges = graph.edges;

        this.layout = {
          name: 'dagre',
          rankDir: 'TB',
          fit: true,
          condense: true
        };

        for (let node of this.nodes) {
          this.searchList.push({
            id: node.data.id,
            name: node.data.name,
            color: node.data.color,
            search: node.data.id + node.data.name
          })
        }
      }
    });
  }

}
