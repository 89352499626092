import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation, Input, OnChanges } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';
import { SubmitButton } from '@eros-front/models';
import { GroupService } from '@eros-front/services';

@Component({
  selector: 'app-customers-consult-graph-modal-group',
  templateUrl: './customers-consult-graph-modal-group.component.html',
  styleUrls: ['./customers-consult-graph-modal-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomersConsultGraphModalGroupComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() group: any;
  @Input() companies: any[];
  @Input() marketsTypes: any[];
  @Input() customersTypes: any[];
  @Input() indicatorsCustomers: any[];
  @Input() products: any[];
  @Input() contactFrequencies: any[];
  @Input() managers: any[];
  @Input() rivals: any[];
  @ViewChild('customersConsultGraphAddEditGroup', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public submitButton: SubmitButton;
  public displayedProducts = [];
  public selectedPriceMarketTypeText = '';

  constructor(
    private groupService: GroupService,
  ) { }

  ngOnInit() {
    this.initValues();
    this.getValues();
    this.initSubscriptions();
  }

  ngAfterViewInit() {
  }

  ngOnChanges() {
    if (this.marketsTypes.length > 0 && this.selectedPriceMarketTypeText.length === 0) {
      this.selectedPriceMarketTypeText = this.marketsTypes[0].label;
    }
    if (this.products.length > 0 && this.displayedProducts.length === 0) {
      for (const p of this.products) {
        this.displayedProducts.push({
          id: p.id,
          name: p.name,
          visible: true
        });
      }
    }
  }

  onModalShown() {
    this.determinateSubmitButton();
    this.groupService.setSubmitButton(this.submitButton);
    this.form = this.groupService.getForm({
      companies: this.companies,
      marketsTypes: this.marketsTypes,
      products: this.products
    }, this.group);
  }

  onModalHidden() {
    this.initValues();
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    // if (this.isValidForm()) {
    //   if (this.group) {
    //     this.groupService.update(this.group.id, this.form);
    //   } else {
    //     this.groupService.store(this.form);
    //   }
    // }
    console.log(this.form.value);
  }

  isValidForm(): boolean {
    return this.form.valid;
  }

  private initValues() {
    this.form = null;
  }

  private getValues() {
  }

  private initSubscriptions() {
  }

  private determinateSubmitButton() {
    this.submitButton = this.groupService.determinateSubmitButton(this.group, 'le groupe');
  }

}
