import { Component, OnInit, Input, OnChanges, ElementRef, Output, EventEmitter } from '@angular/core';
import cytoscape from 'cytoscape';
import dagre from 'cytoscape-dagre';
import cxtmenu from 'cytoscape-cxtmenu';
import popper from 'cytoscape-popper';
cytoscape.use(dagre);
cytoscape.use(cxtmenu);
cytoscape.use(popper);

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'custom-cytoscape',
  template: '',
  styleUrls: ['./cytoscape.component.scss']
})
export class CytoscapeComponent implements OnInit, OnChanges {

  @Input() nodes: cytoscape.NodeDefinition[];
  @Input() edges: cytoscape.EdgeDefinition[];
  @Input() layout: cytoscape.LayoutOptions;
  @Output() onInitComplete = new EventEmitter<any>();
  private graph: cytoscape.Core;
  private styles: cytoscape.Stylesheet[];
  private nativeElement: HTMLElement;
  private initOnce = false;

  constructor(element: ElementRef) {
    this.nativeElement = element.nativeElement;
  }

  ngOnInit() {
    this.styles = [
      {
        selector: 'node',
        style: {
          'shape': 'roundrectangle',
          'width': 'data(width)',
          'height': 'data(height)',
          'border-width': 5,
          'background-color': 'white',
          'background-image': this.renderNode,
          'border-color': 'data(color)',
        }
      },
      {
        selector: 'edge',
        style: {
          'curve-style': 'taxi',
          'taxi-direction': 'downward',
          'taxi-turn': 20,
          'target-arrow-shape': 'triangle',
          'taxi-turn-min-distance': 5
        }
      },
    ];
  }

  ngOnChanges() {
    if (this.nodes && this.edges && this.styles && this.layout) {
      this.redraw();
    }
  }

  redraw() {
    this.graph = cytoscape({
      container: this.nativeElement,
      elements: {
        nodes: this.nodes,
        edges: this.edges
      },
      style: this.styles,
      layout: this.layout,
      autoungrabify: true,
      //wheelSensitivity: 0.25,
      minZoom: 0.1,
      maxZoom: 5
    });
    if (!this.initOnce) {
      this.onInitComplete.emit(this.graph);
      this.initOnce = true;
    }
  }

  renderNode(ele) {
    const name = ele.data('name').toUpperCase();
    name.replace('&', '&amp;');
    const data = ele.data();
    const width = data['width'];
    const height = data['height'];
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      width="${width}" height="${height}" viewBox="0 0 ${width} ${height}"><g>
      <text text-overflow="ellipsis" width="500" font-size="24px" font-weight="bold" x="250" y="30"
      text-anchor="middle" style="font-family: Arial">${name}</text>
      ${data['address']}
      ${data['city']}
      ${data['marketsTypes']}
      ${data['customersTypes']}
      ${data['indicatorsCustomers']}
      ${data['products']}
      ${data['iconsManagers']}
      ${data['textsManagers']}
      ${data['statisticsTable']}
      </g></svg>`;


    return 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg);

  }
}