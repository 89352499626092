import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'eros-front-history',
  templateUrl: './inspections-history.component.html',
  styleUrls: ['./inspections-history.component.css']
})
export class InspectionsHistoryComponent implements OnInit {

  public list: any[];

  /**
   * Constructor of InspectionsHistoryComponent
   *
   * @param _http
   */
  constructor(private _http: HttpClient) { }

  /**
   * Executed before component initialisation
   */
  ngOnInit(): void {
    this._http.get<any[]>(environment.serviceEUrl + '/set/header/column-history').subscribe({
      next: data => {
        this.list = data;
        console.log(data);
      }
    })
  }

}
