import { Component, Input, OnInit } from '@angular/core';
import { ShuttleSheetService } from '@libs/services/src';

@Component({
  selector: 'app-shuttle-sheet-show',
  templateUrl: './shuttle-sheet-show.component.html',
  styleUrls: ['./shuttle-sheet-show.component.scss']
})
export class ShuttleSheetShowComponent implements OnInit {

  @Input() baseModel: any;
  @Input() templateModel: any;
  @Input() titlesEditable = false;
  @Input() selectsEditable = false;
  public selectedKey = '';
  public selectedResults = [];
  public selectedObject: any;
  public selectsValues = {};
  public optionsValues = {};
  public showNoneOtherOptions = false;

  constructor(
    private shuttleService: ShuttleSheetService
  ) { }

  ngOnInit() {
    for (let equipment of this.baseModel.equipments) {
      this.selectsValues[equipment.key] = [];
      this.optionsValues[equipment.key] = [];
    }
  }

  getElements(key: string) {
    return this.baseModel.buildings.filter((e: any) => e.col === key);
  }

  onEditSelectValues(event: any) {
    this.selectedKey = event.key;
    this.showNoneOtherOptions = event.showNoneOther;
    this.selectedResults = this.getResultsByKey(event.key);
    this.shuttleService.showModalTemplate();
  }

  onEditTitleText(key: string) {
    let found = false;
    this.selectedObject = null;
    for (let b of this.baseModel.buildings) {
      if (b.key === key) {
        this.selectedObject = b;
        found = true;
        break;
      }
    }
    if (!found) {
      for (let e of this.baseModel.equipments) {
        if (e.key === key) {
          this.selectedObject = e;
          break;
        }
      }
    }
    this.shuttleService.showModalTitle();
  }

  onEditItemText(key: string) {
    this.selectedObject = null;
    let found = false;
    for (let b of this.baseModel.buildings) {
      for (let i of b.itemsSelect) {
        if (i.key === key) {
          this.selectedObject = i;
          found = true;
          break;
        }
      }
      if (found) {
        break;
      }
    }
    this.shuttleService.showModalItem();
  }

  onEditOptionValues(key: string) {
    this.selectedObject = null;
    let found = false;
    for (let e of this.baseModel.equipments) {
      for (let i of e.itemsRadio) {
        if (i.key === key) {
          this.selectedObject = i;
          found = true;
          break;
        }
      }
      if (found) {
        break;
      }
    }
    this.shuttleService.showModalOption();
  }

  onModalTemplateClosed(results: any[]) {
    let select = [];
    for (let r of results) {
      select.push({
        label: r,
        value: r
      })
    }
    for (let b of this.baseModel.buildings) {
      for (let i of b.itemsSelect) {
        if (i.key === this.selectedKey) {
          i.select = select;
        }
      }
    }
    for (let e of this.baseModel.equipments) {
      if (e.hasOwnProperty('itemsRadio')) {
        for (let i of e.itemsRadio) {
          if (i.key === this.selectedKey) {
            i.select = select;
          }
        }
      } else {
        if (e.key === this.selectedKey) {
          e.select = select;
        }
      }
    }
  }

  onRadioValueChanged(event: any) {
    this.selectsValues[event.equipmentKey] = [];
    for (let equipment of this.baseModel.equipments) {
      if (equipment.key === event.equipmentKey) {
        for (let item of equipment.itemsRadio) {
          if (item.key === event.itemKey) {
            this.selectsValues[equipment.key] = item.select;
          }
        }
      }
    }
    this.optionsValues[event.equipmentKey] = [];
    for (let equipment of this.baseModel.equipments) {
      if (equipment.key === event.equipmentKey) {
        for (let item of equipment.itemsRadio) {
          if (item.key === event.itemKey) {
            this.optionsValues[equipment.key] = item.options;
          }
        }
      }
    }
  }

  private getResultsByKey(key: string) {
    let arrayToReturn = [];
    for (let b of this.baseModel.buildings) {
      for (let i of b.itemsSelect) {
        if (i.key === key) {
          for (let s of i.select) {
            arrayToReturn.push(s.value);
          }
        }
      }
    }
    for (let e of this.baseModel.equipments) {
      if (e.hasOwnProperty('itemsRadio')) {
        for (let i of e.itemsRadio) {
          if (i.key === key) {
            for (let s of i.select) {
              arrayToReturn.push(s.value);
            }
          }
        }
      } else {
        if (e.key === key) {
          for (let s of e.select) {
            arrayToReturn.push(s.value);
          }
        }
      }
    }
    return arrayToReturn;
  }

}
