import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ExpenseAccountStatus } from '@libs/models/src';
import { SwalService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { ExpenseAccountStatusService } from '@libs/services/src/lib/expense-account-status.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ExpensesAccountsStatusesModalComponent } from '../expenses-accounts-statuses-modal/expenses-accounts-statuses-modal.component';

@Component({
  selector: 'app-expenses-accounts-statuses-index',
  templateUrl: './expenses-accounts-statuses-index.component.html',
  styleUrls: ['./expenses-accounts-statuses-index.component.scss']
})
export class ExpensesAccountsStatusesIndexComponent implements OnInit {
  public datatableSelector = 'expenses-account-statuses-datatable';
  private marketsTypes$: Subscription;
  public form: FormGroup;
  public expenseAccountStatus: ExpenseAccountStatus = null;
  public expenseAccountStatus$: Subscription;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'icon',
      name: 'Icône'
    },
    {
      attr: 'color',
      name: 'Couleur'
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];

  constructor(
    private expenseAccountStatusService: ExpenseAccountStatusService,
    private swalService: SwalService,
    private modalService: NzModalService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.expenseAccountStatusService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    if (this.expenseAccountStatus$) {
      this.expenseAccountStatus$.unsubscribe();
    }
  }

  onShowModalAddEditModal() {
    this.form = this.expenseAccountStatusService.getForm(this.expenseAccountStatus);
    const modalInstance = this.modalService.create({
      nzContent: ExpensesAccountsStatusesModalComponent,
      nzComponentParams: {
        expenseAccountStatus: this.expenseAccountStatus,
        form: this.form
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.expenseAccountStatus = null;
    })
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.expenseAccountStatus$ = that.expenseAccountStatusService.get(id).subscribe(
          (expenseAccountStatus) => {
            if (expenseAccountStatus) {
              that.expenseAccountStatus = expenseAccountStatus;
              that.onShowModalAddEditModal();
            }
          }
        );
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'L\'objectif sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.expenseAccountStatusService.delete(id).subscribe(
              (success) => {
                this.swalService.showSwalSuccess(success);
              }
            );
          }
        });
      }
    });
  }

}
