import { DataTableColumn, DocumentCustomerService, IndicatorInterventionTypeService, MarketTypeService, SwalService } from '@eros-front/services';
import { Subscription } from 'rxjs';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import Swal from 'sweetalert2';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FormGroup } from '@angular/forms';
import { SettingsInterventionsIndicatorsTypesModalComponent } from '../settings-interventions-indicators-types-modal/settings-interventions-indicators-types-modal.component';

@Component({
  selector: 'app-settings-interventions-indicators-types-index',
  templateUrl: './settings-interventions-indicators-types-index.component.html',
  styleUrls: ['./settings-interventions-indicators-types-index.component.scss']
})
export class SettingsInterventionsIndicatorsTypesIndexComponent implements OnInit, AfterViewInit, OnDestroy {

  public datatableSelector = 'indicators-interventions-types-datatable';
  public indicatorInterventionType: any = null;
  public indicatorInterventionType$: Subscription;
  public marketsTypes$: Subscription;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'marketsTypes',
      name: 'Type(s) de marché(s)',
      orderable: false,
      searchable: false
    },
    {
      attr: 'documentsCustomers',
      name: 'Documents clients',
      orderable: false,
      searchable: false
    },
    {
      attr: 'condition',
      name: 'Condition',
      orderable: false,
      searchable: false
    },
    {
      attr: 'isRequired',
      name: 'Requis',
      orderable: false,
      searchable: false
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];
  public form: FormGroup;
  public marketsTypes: any[];
  private documentsCustomers$: Subscription;
  public documentsCustomers: any[];


  constructor(
    private indicatorInterventionTypeService: IndicatorInterventionTypeService,
    private swalService: SwalService,
    private modalService: NzModalService,
    private marketTypeService: MarketTypeService,
    private documentCustomerService: DocumentCustomerService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.marketTypeService.getForSelect();
    this.documentCustomerService.getForSelect();
  }

  ngAfterViewInit() {
    this.indicatorInterventionTypeService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    if (this.indicatorInterventionType$) {
      this.indicatorInterventionType$.unsubscribe();
    }
    this.marketsTypes$.unsubscribe();
    this.documentsCustomers$.unsubscribe();
  }

  onShowModalAddEdit() {
    this.form = this.indicatorInterventionTypeService.getForm(this.indicatorInterventionType, this.documentsCustomers);
    const modalInstance = this.modalService.create({
      nzContent: SettingsInterventionsIndicatorsTypesModalComponent,
      nzComponentParams: {
        indicatorInterventionType: this.indicatorInterventionType,
        marketsTypes: this.marketsTypes,
        documentsCustomers: this.documentsCustomers,
        form: this.form
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.indicatorInterventionType = null;
    })

  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.indicatorInterventionTypeService.get(id).subscribe(
          (data) => {
            if (data) {
              that.indicatorInterventionType = data;
              that.onShowModalAddEdit();
            }
          }
        )
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le type d\'indicateur intervention sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.indicatorInterventionTypeService.delete(id);
          }
        });
      }
    });
  }

  private initSubscriptions() {
    this.marketsTypes$ = this.marketTypeService.selectMarketType$.subscribe(
      (data) => {
        this.marketsTypes = data;
      }
    )
    this.documentsCustomers$ = this.documentCustomerService.documentsCustomersSelect$.subscribe(
      (documentsCustomers) => {
        if (documentsCustomers) {
          this.documentsCustomers = documentsCustomers;
        }
      }
    );
  }

}
