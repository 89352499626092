import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerService, NotifService } from '@libs/services/src';
import { OnChangeType } from 'ng-zorro-antd/core/types';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CustomersShowImportLogoComponent } from '../customers-show-import-logo/customers-show-import-logo.component';

@Component({
  selector: 'app-customers-show-headband',
  templateUrl: './customers-show-headband.component.html',
  styleUrls: ['./customers-show-headband.component.scss']
})
export class CustomersShowHeadbandComponent implements OnInit, OnChanges {
  @Input() customer: any;
  @Input() isGroup: boolean;
  @Input() isBrand: boolean;
  @Input() isAgency: boolean;
  @Input() mlSocieties: any[];
  public mlSocietiesTemplate: string;
  public hoverLogo = false;
  public form: FormGroup;

  constructor(
    private modalService: NzModalService,
    private notifService: NotifService,
    private customerService: CustomerService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.initMlSocietiesTemplate();
  }

  onHoverLogo(value: boolean) {
    this.hoverLogo = value;
  }

  importLogo() {
    this.form = this.initLogoForm();
    const modalInstance = this.modalService.create({
      nzContent: CustomersShowImportLogoComponent,
      nzComponentParams: {
        customer: this.customer,
        form: this.form
      }
    })
    modalInstance.afterClose.subscribe((success) => {
      this.notifService.showSuccessNotif(success);
      this.customerService.refreshEntityByCustomerRef(this.customer.ref);
    })
  }

  initMlSocietiesTemplate() {
    this.mlSocietiesTemplate = '';
    this.mlSocieties.forEach(mlSociety => {
      const checked = this.customer.mlSocieties.map(x => x.id).includes(mlSociety.id);
      if (checked) {
        this.mlSocietiesTemplate += `<i class="${mlSociety.tllIcon} tll-48 tll-${mlSociety.key} mx-1"></i>`;
      } else {
        this.mlSocietiesTemplate += `<i class="${mlSociety.tllIcon} tll-48 tll-lightgrey mx-1"></i>`;
      }
    });
  }

  private initLogoForm(): FormGroup {
    return this.formBuilder.group({
      'logo': new FormControl(null, [Validators.required]),
      'width': new FormControl(null, [Validators.required]),
      'height': new FormControl(null, [Validators.required]),
    });
  }


}
