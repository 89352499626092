import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Affair, AffairContact, Contact, SubmitButton } from '@libs/models/src';
import { AffairContactService, NotifService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-affair-show-contacts-modal',
  templateUrl: './affair-show-contacts-modal.component.html',
  styleUrls: ['./affair-show-contacts-modal.component.scss']
})
export class AffairShowContactsModalComponent implements OnInit {

  @Input() affair: Affair;
  @Input() contact: AffairContact;
  @Input() contacts: Contact[];
  @Input() form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private affairContactService: AffairContactService,
    private notifService: NotifService,
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.affairContactService.setSubmitButton(this.submitButton);
  }

  onSelectedContactChange(contact: Contact) {
    this.form = this.affairContactService.getForm(this.affair.id, contact);
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.contact) {
        this.affairContactService.update(this.contact.id, this.form).subscribe(
          (success) => {
            this.notifService.showSuccessNotif(success);
            this.modal.close();
          }
        );
      } else {
        this.affairContactService.store(this.form).subscribe(
          (success) => {
            this.notifService.showSuccessNotif(success);
            this.modal.close();
          }
        );
      }
    }
  }

  isValidForm() {
    return this.form.valid;
  }

  private determinateSubmitButton() {
    this.submitButton = this.affairContactService.determinateSubmitButton(this.contact, 'le contact');
  }

}
