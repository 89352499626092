import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Affair, AffairSendSetting, Contact, SendSetting, StandardResponse, SubmitButton } from '@libs/models/src';
import { AffairStandardResponseService, MailService, NotifService, SendSettingService, SubmitButtonService } from '@libs/services/src';
import { AffairDocumentDeliveredService } from '@libs/services/src/lib/affair-document-delivered.service';
import { Subject, Subscription } from 'rxjs';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';

export interface StandardResponseFiles {
  documentsDelivered: any;
  invoices: any;
  quotations: any;
  attachments: any;
}

@Component({
  selector: 'affair-modal-standard-response',
  templateUrl: './affair-modal-standard-response.component.html',
  styleUrls: ['./affair-modal-standard-response.component.scss']
})
export class AffairModalStandardResponseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() affair: Affair;
  @Input() affairSendSettings: AffairSendSetting[];
  @Input() affairContacts: any[];
  private files$: Subscription;
  private textReplaced$: Subscription;
  private recipients$: Subscription;
  public sendSettings: SendSetting[] = [];
  public standResps: StandardResponse[] = [];
  public recipients: Contact[] = [];
  public recipientsCopy: Contact[] = [];
  public files: StandardResponseFiles = {
    documentsDelivered: [],
    invoices: [],
    quotations: [],
    attachments: [],
  };
  public form: FormGroup;
  public selectedDocuments = [];
  public products = [];
  public contacts = [];
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private affairResponseStandardService: AffairStandardResponseService,
    private sendSettingService: SendSettingService,
    private submitButtonService: SubmitButtonService,
    private mailService: MailService,
    private notifService: NotifService,
    private spinner: NgxSpinnerService
  ) {
    this.initSubscriptions();
  }

  ngOnInit(): void {
    this.files = this.initFilesObject();
    if (this.affair) {
      this.form = this.affairResponseStandardService.initForm(this.affair);
      if (this.affair) {
        this.initProductsList();
        this.initContactsList();
      }
      this.setSubmitButton();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['affair'] && changes['affair'].previousValue != changes['affair'].currentValue) {
      this.initProductsList();
      this.initContactsList();
    }

  }

  ngOnDestroy() {
    if (this.files$) {
      this.files$.unsubscribe();
    }
    if (this.textReplaced$) {
      this.textReplaced$.unsubscribe();
    }
    this.files = this.initFilesObject();
    this.selectedDocuments = [];
  }

  onSendSettingChange(value: AffairSendSetting) {
    this.standResps = this.sendSettingService.getStandardsResponses(value.sendSetting, this.affair.marketTypeId);
    // Recipients recovery
    const recs = value.recipients.length > 0 ?
      value.recipients.map(x => x.email) : null;
    // RecipientsCopy recovery
    const recsCC = value.recipientsCopy.length > 0 ?
      value.recipientsCopy.map(x => x.email) : null;
    // Patch value of form
    this.affairResponseStandardService.getRecipients(this.affair.id, this.form);
  }

  onStandardResponseChange(value: StandardResponse) {
    this.selectedDocuments = [];
    this.form.patchValue({
      'subject': value.subject,
      'content': value.body,
    });
    if (this.isValidForm()) {
      this.spinner.show('documents');
      this.affairResponseStandardService.getFiles(this.affair.id, this.form);
      this.affairResponseStandardService.replaceTextTags(this.affair.id, this.form);
    }
  }

  onProductChange(value) {
    if (this.isValidForm()) {
      this.spinner.show('documents');
      this.affairResponseStandardService.getFiles(this.affair.id, this.form);
      this.affairResponseStandardService.replaceTextTags(this.affair.id, this.form);
    }
  }

  onThumbnailSelected(document: any): void {
    if (this.selectedDocuments.includes(document.path)) {
      this.selectedDocuments = this.selectedDocuments.filter(e => e !== document.path);
    } else {
      this.selectedDocuments.push(document.path);
    }
    if (this.form) {
      this.form.patchValue({
        attachments: this.selectedDocuments
      });
    }
  }

  isInSelectedDocumentsTypes(value): boolean {
    return this.selectedDocuments.includes(value);
  }

  getButtonType(value: string): string {
    return this.isInSelectedDocumentsTypes(value) ? 'primary' : 'default';
  }

  private initSubscriptions() {
    this.files$ = this.affairResponseStandardService.files$.subscribe(
      (files) => {
        if (files) {
          this.files = files;
          this.files.documentsDelivered.forEach(documentDelivered => {
            if (documentDelivered.isSelected) {
              this.onThumbnailSelected(documentDelivered);
            }
          });
          this.files.invoices.forEach(invoice => {
            if (invoice.isSelected) {
              this.onThumbnailSelected(invoice);
            }
          });
          this.files.attachments.forEach(attachment => {
            this.onThumbnailSelected(attachment);
          });
          this.spinner.hide('documents');
        }
      }
    );
    this.textReplaced$ = this.affairResponseStandardService.textReplaced$.subscribe(
      (data) => {
        if (data) {
          if (this.form) {
            this.form.patchValue({
              subject: this.replaceSubjectStringTemplate(data.subject),
              content: data.content
            });
          }
        }
      }
    );
    this.recipients$ = this.affairResponseStandardService.recipients$.subscribe(
      (data) => {
        if (data) {
          if (this.form) {
            this.form.patchValue({
              recipients: data.to,
              recipientsCopy: data.copy
            });
          }
        }
      }
    )
  }

  submitForm() {
    if (this.isValidForm()) {
      this.mailService.send(this.form).subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.files = this.initFilesObject();
          this.selectedDocuments = [];
          this.modal.close();
        }
      );
    }
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }


  /**
   * 
   * PRIVATE FUNCTIONS
   */
  private initFilesObject(): StandardResponseFiles {
    return {
      documentsDelivered: [],
      invoices: [],
      quotations: [],
      attachments: []
    }
  }

  private replaceSubjectStringTemplate(s: string): string {
    s = s.replace('{mlRef}', this.affair.mlRef);
    return s;
  }

  private setSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'un mail',
      text: 'Envoyer',
      icon: faPaperPlane
    });
    this.mailService.setSubmitButton(this.submitButton);
  }

  private initProductsList(): void {
    this.products = [];
    if (this.affair.affairProducts.length > 0) {
      this.affair.affairProducts.forEach((affairProduct) => {
        this.products.push({
          'value': affairProduct.id,
          'label': affairProduct.product.name
        });
      })
    }
  }

  private initContactsList(): void {
    this.contacts = [];
    if (this.affair.agency) {
      this.affair.agency.contacts.forEach((contact) => {
        this.contacts.push({
          'email': contact.email
        });
      })
    }
  }



}
