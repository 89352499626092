import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormFormatterService } from './../utilities/form-formatter.service';
import { DataTableColumn, ModelWithDatatable } from './../classes/model-datatable';
import { CommonService } from './../utilities/common.service';
import { NgxSpinnerService } from 'ngx-spinner';

export interface AffairDatatableParameters {
    selectedMlAgencies: string;
    selectedAgencies: string;
    selectedAssistants: string;
    selectedTechnicians: string;
    selectedAffairsGoals: string;
    selectedPerformances: string;
    selectedProducts: string;
    selectedProductsStatuses: string;
    selectedAffairsMonitoringTypes: string;
    affairMonitoringTypeLevel: number;
    dateCreationMin: Date;
    dateCreationMax: Date;
    dateProductReceiptMin: Date;
    dateProductReceiptMax: Date;
    dateProductRealisationMin: Date;
    dateProductRealisationMax: Date;
}

@Injectable()
export class AffairArchiveService extends ModelWithDatatable {

    private route = '/affairs/archives';
    public affairDatatableParams: AffairDatatableParameters;

    constructor(
        http: HttpClient,
        router: Router,
        apiService: ApiService,
        spinner: NgxSpinnerService,
    ) {
        super(
            http,
            router,
            apiService,
            spinner
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]) {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns,
            openInNewTabUrl: 'affairs/$id/show'
        });
    }
}
