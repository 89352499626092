import { Injectable } from '@angular/core';
import { TabChangeEvent } from '@pages/components/tabs/tabset.component';
import { CustomerService } from './../customer.service';
import { Subscription } from 'rxjs';

@Injectable()
export abstract class CustomersModalSync {

    public selectedStep: number;
    public nbStep: number;
    private customerService: CustomerService;
    public previewResult = [];
    public previewResult$: Subscription;

    constructor(customerService: CustomerService, nbStep: number) {
        this.customerService = customerService;
        this.selectedStep = 0;
        this.nbStep = nbStep;
    }

    public onTabSelect(el: TabChangeEvent): void {
        this.selectedStep = el.index;
    }

    public onPreviousTabClicked(): void {
        if (this.selectedStep > 0) {
            this.selectedStep--;
        }
    }

    public onNextTabClicked(): void {
        if (this.selectedStep < this.nbStep) {
            this.selectedStep++;
        }
    }

    public setSelectEntitiesFromSource(ref: string): void {
        this.customerService.getEntitiesFromSourceRef(ref);
    }

    abstract isValidForm(): boolean;

    abstract getPreviewResult(): void;

    abstract onApplyClicked(): void;
}
