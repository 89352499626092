import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { Router } from '@angular/router';
import { AffairGoal, AffairMonitoringType, StudyOffice } from '@libs/models/src';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { SelectService } from './utilities/select.service';

@Injectable()
export class StudyOfficeService extends ModelWithDatatableAndCrudObservable {

    public route = '/studies-offices';
    public selectStudiesOffices$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private commonService: CommonService,
        private selectService: SelectService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route);
    }

    public getAll(): Observable<any> {
        return super.getAll(this.route);
    }

    public store(form: any): Observable<any> {
        return super.store(form.value, this.route);
    }

    public update(id: number, form: any): Observable<any> {
        return super.update(id, form.value, this.route);
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route);
    }

    public getForm(studyOffice?: StudyOffice) {
        const name = studyOffice ?
            this.commonService.valueToFormString(studyOffice.name) : this.commonService.getDefaultFormStringValue();
        const email = studyOffice ?
            this.commonService.valueToFormString(studyOffice.email) : this.commonService.getDefaultFormStringValue();
        return this.formBuilder.group({
            name: [name, Validators.required],
            email: [email, Validators.required],
        });
    }

    public getForSelect(): void {
        this.selectService.getForSelect(this.selectStudiesOffices$, this.route);
    }
}
