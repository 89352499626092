import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Brand, SubmitButton } from '@libs/models/src';
import { AgencyService, CustomerService, NotifService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-customers-create-agency-modal',
  templateUrl: './customers-create-agency-modal.component.html',
  styleUrls: ['./customers-create-agency-modal.component.scss']
})
export class CustomersCreateAgencyModalComponent implements OnInit {
  @Input() brand: Brand;
  @Input() marketsTypes: any[];
  @Input() customersTypes: any[];
  @Input() indicatorsCustomers: any[];
  @Input() mlSocieties: any[];
  @Input() mlAgencies: any[];
  @Input() assistants: any[];
  @Input() technicians: any[];
  public isAgency = true;
  public form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private agencyService: AgencyService,
    private notifService: NotifService,
    private customerService: CustomerService
  ) { }

  ngOnInit() {
    this.form = this.agencyService.getCreateForm(this.brand);
    this.customerService.initMlSocietiesCheckboxes(this.form, this.mlSocieties, this.brand);
    this.determinateSubmitButton();
  }

  isValidForm() {
    return this.form.valid;
  }

  submitForm() {
    if (this.isValidForm()) {
      this.agencyService.store(this.form).subscribe(
        (success) => {
          this.modal.close();
          this.notifService.showSuccessNotif(success);
        }
      )
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.agencyService.determinateSubmitButton(null, 'l\'agence');
    this.agencyService.setCreateSubmitButton(this.submitButton);
  }

}
