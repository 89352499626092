import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FileService, FileUtilsService, NotifService } from '@libs/services/src';
import { AffairDocumentCustomerService } from '@libs/services/src/lib/affair-document-customer.service';
import { AffairDocumentDeliveredService } from '@libs/services/src/lib/affair-document-delivered.service';

@Component({
  selector: 'document-thumbnail',
  templateUrl: './document-thumbnail.component.html',
  styleUrls: ['./document-thumbnail.component.scss']
})
export class DocumentThumbnailComponent implements OnInit, OnChanges {

  @Input() index: number;
  @Input() document: any;
  @Input() currentHoverIndex: number;
  @Input() isAllSelected: boolean = false;
  @Input() currentTabIndex: number = 0;
  @Input() isMinified: boolean = false;
  @Input() isSelected = false;
  @Input() isDelivered = false;
  @Input() affairDocumentService: AffairDocumentCustomerService | AffairDocumentDeliveredService;
  @Output() renameButtonClicked = new EventEmitter();
  @Output() mouseOverThumbnails = new EventEmitter();
  @Output() onThumbnailSelected = new EventEmitter();
  @Output() onRefreshDocuments = new EventEmitter();

  constructor(
    private fileService: FileService,
    private notifService: NotifService,
    private fileUtilsService: FileUtilsService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: any) {
    if (changes['isAllSelected'] && changes['isAllSelected'].previousValue != changes['isAllSelected'].currentValue) {
      this.isSelected = this.isAllSelected;
    }
    if (changes['currentTabIndex'] && changes['currentTabIndex'].previousValue != changes['currentTabIndex'].currentValue) {
      if (this.currentTabIndex > 0) {
        this.isSelected = false;
      }
    }
  }

  onDivSelected() {
    this.isSelected = !this.isSelected;
    this.onThumbnailSelected.emit(this.document);
  }

  onMouseOverThumbnails(index: number) {
    this.mouseOverThumbnails.emit(index);
  }

  onShow(event: any) {
    event.stopPropagation();
    if (this.document.mimeType === 'application/pdf') {
      this.fileUtilsService.openPreviewDocument(this.document.path, this.document.mimeType);
    } else {
      this.fileService.getBlob(this.document.path).subscribe(
        (file) => {
          const blob = new Blob([file], { type: this.document.mimeType });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        }
      );
    }
  }

  onRemaneClicked(event: any) {
    event.stopPropagation();
    this.renameButtonClicked.emit(this.document);
  }

  onMarkAsNotPcVisibleClicked(event: any) {
    event.stopPropagation();
    this.affairDocumentService.markAsNotPcVisible(this.document.path).subscribe(
      (success) => {
        this.notifService.showSuccessNotif(success);
        this.onRefreshDocuments.emit();
      }
    )
  }

  onMarkAsPcVisibleClicked(event: any) {
    event.stopPropagation();
    this.affairDocumentService.markAsPcVisible(this.document.path).subscribe(
      (success) => {
        this.notifService.showSuccessNotif(success);
        this.onRefreshDocuments.emit();
      }
    )
  }

}
