import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { SubmitButton } from '@libs/models/src';
import { ShuttleSheetService, SubmitButtonService } from '@libs/services/src';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-shuttle-sheet-modal-option',
  templateUrl: './shuttle-sheet-modal-option.component.html',
  styleUrls: ['./shuttle-sheet-modal-option.component.scss']
})
export class ShuttleSheetModalOptionComponent implements OnInit, AfterViewInit {

  @Input() model: any;
  @Input() option: any;
  @ViewChild('ShuttleSheetModalOption', { static: false }) childModal: ModalDirective;
  public optionCopy: any = null;
  public submitButton: SubmitButton;

  constructor(
    private shuttleService: ShuttleSheetService,
    private submitButtonService: SubmitButtonService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.shuttleService.setModalOption(this.childModal);
  }

  onModalShown() {
    this.optionCopy = JSON.parse(JSON.stringify(this.option));
    this.determinateSubmitButton();
    this.shuttleService.setOptionSubmitButton(this.submitButton);
  }

  onModalHidden() {
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    // if (this.isValidForm()) {
    //   let found = false;
    //   let modelCopy = JSON.parse(JSON.stringify(this.model));
    //   for (let i = 0; i < modelCopy.buildings.length; i++) {
    //     for (let j = 0; j < modelCopy.buildings[i].itemsSelect.length; j++) {
    //       if (modelCopy.buildings[i].itemsSelect[j].key === this.itemCopy.key) {
    //         modelCopy.buildings[i].itemsSelect[j] = { ...this.itemCopy };
    //         found = true;
    //         break;
    //       }
    //     }
    //     if (found) {
    //       break;
    //     }
    //   }
    //   this.shuttleService.updateBaseModel(modelCopy);
    // }
  }

  isValidForm() {
    return this.optionCopy != null;
  }

  onAddValueClicked(i: number) {
    if (i < this.optionCopy.options.length) {
      this.optionCopy.options[i].values.push("");
    }
  }

  onInputValueChanged(evt: any, i: number, j: number) {
    if (i < this.optionCopy.options.length) {
      if (j < this.optionCopy.options[i].values.length) {
        this.optionCopy.options[i].values[j] = evt.target.value;
      }
    }
  }

  onDeleteValueClicked(i: number, j: number) {
    if (i < this.optionCopy.options.length) {
      if (j < this.optionCopy.options[i].values.length) {
        this.optionCopy.options[i].values.splice(j, 1);
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      text: 'Mettre à jour',
      objectName: 'les options',
      icon: faSave
    });
  }

}
