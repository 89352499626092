import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotifService } from './utilities/notif.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { FormFormatterService } from './utilities/form-formatter.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { InvoiceStatus } from '@eros-front/models';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { SelectService } from './utilities/select.service';

@Injectable()
export class QuotationStatusService extends ModelWithDatatableAndCrudObservable {

    private route = '/quotations-statuses';
    public quotationsStatusesSelect$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService,
        private selectService: SelectService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route);
    }

    public getAll(): Observable<any> {
        return super.getAll(this.route);
    }

    public store(form: any): Observable<any> {
        return super.store(this.formatForm(form), this.route);
    }

    public update(id: number, form: any): Observable<any> {
        return super.update(id, this.formatForm(form), this.route);
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route);
    }

    public getForm(status: InvoiceStatus): FormGroup {
        const name = status ?
            this.commonService.valueToFormString(status.name) : this.commonService.getDefaultFormNullValue();
        const labelClass = status ?
            this.commonService.valueToFormSelect(status.labelClass) : this.commonService.getDefaultFormNullValue();
        return this.formBuilder.group({
            name: [name, Validators.required],
            labelClass: [labelClass, Validators.required],
        });
    }

    public getForSelect(): void {
        this.selectService.getForSelect(this.quotationsStatusesSelect$, this.route);
    }

    private formatForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.labelClass = this.formFormatterService.formatSelectSingle(values.labelClass);
        return values;
    }

}
