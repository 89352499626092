import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { OnChangeType } from 'ng-zorro-antd/core/types';

@Component({
  selector: 'app-affair-document-dropzones',
  templateUrl: './affair-document-dropzones.component.html',
  styleUrls: ['./affair-document-dropzones.component.scss']
})
export class AffairDocumentDropzonesComponent implements OnInit {

  @Input() dropzonesProducts: any[];
  @Input() files: File[][];
  @Output() onFileImport = new EventEmitter();
  public faCheckCircle = faCheckCircle;
  public faExclamationCircle = faExclamationCircle;
  public currentTabIndex = 0;

  constructor(
  ) {
  }

  ngOnInit() {
  }

  onSelectIndexChange(index) {
    this.currentTabIndex = index;
  }

  onSelect(event: any, documentId: number, affairProductId: number, isImported: boolean, buildingId: number): void {
    this.onFileImport.emit({
      event: event,
      documentId: documentId,
      affairProductId: affairProductId,
      isImported: isImported,
      buildingId: buildingId
    });
  }

}
