import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectModel, TextTag } from '@libs/models/src';
import { AffairMonitoringTypeService, MarketTypeService, SendSettingService, StandardResponseService, SwalService, ThingService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { TextTagService } from '@libs/services/src/lib/text-tag.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { StandardsResponseModalComponent } from '../standards-response-modal/standards-response-modal.component';

@Component({
  selector: 'app-standards-responses-index',
  templateUrl: './standards-responses-index.component.html',
  styleUrls: ['./standards-responses-index.component.scss']
})
export class StandardsResponsesIndexComponent implements OnInit {
  public datatableSelector = 'standards-response-datatable';
  public form: FormGroup;
  public standardResponse: any = null;
  public standardResponse$: Subscription;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'standardResponseType',
      name: 'Type',
      orderable: false,
      searchable: false
    },
    {
      attr: 'name',
      name: 'Nom',
    },
    {
      attr: 'marketsTypes',
      name: 'Type(s) de marché(s)',
      orderable: false,
      searchable: false
    },
    {
      attr: 'subject',
      name: 'Sujet'
    },
    {
      attr: 'body',
      name: 'Corps du message'
    },
    {
      attr: 'sendSettingName',
      name: 'Paramètre d\'envoi',
      orderable: false,
      searchable: false
    },
    {
      attr: 'nbDaysBefore',
      name: 'Déclenchement nombre de jours avant',
      orderable: false,
      searchable: false
    },
    {
      attr: 'nbDaysAfter',
      name: 'Déclenchement nombre de jours après',
      orderable: false,
      searchable: false
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];
  public selectAffairsMonitoringTypes$: Subscription;
  public affairsMonitoringTypes: any;
  public sendSettings$: Subscription;
  public sendSettings: any;
  public textTags: TextTag[];
  public days$: Subscription;
  public days: any[];
  private marketsTypes$: Subscription;
  public marketsTypes: SelectModel[];

  constructor(
    private standardResponseService: StandardResponseService,
    private swalService: SwalService,
    private modalService: NzModalService,
    private affairMonitoringTypeService: AffairMonitoringTypeService,
    private sendSettingService: SendSettingService,
    private textTagService: TextTagService,
    private thingService: ThingService,
    private marketTypeService: MarketTypeService,
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.affairMonitoringTypeService.getForSelect();
    this.sendSettingService.getForSelect();
    this.thingService.getDaysSelect();
    this.marketTypeService.getForSelect();

    this.textTagService.getAll().subscribe(
      (data) => {
        if (data) {
          this.textTags = data;
        }
      }
    )
  }

  ngAfterViewInit() {
    this.standardResponseService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    if (this.standardResponse$) {
      this.standardResponse$.unsubscribe();
    }
    this.selectAffairsMonitoringTypes$.unsubscribe();
    this.days$?.unsubscribe();
  }

  onShowModalAddEditStandardResponse() {
    this.form = this.standardResponseService.getForm(this.days, this.standardResponse);
    const modalInstance = this.modalService.create({
      nzContent: StandardsResponseModalComponent,
      nzComponentParams: {
        standardResponse: this.standardResponse,
        form: this.form,
        affairsMonitoringTypes: this.affairsMonitoringTypes,
        sendSettings: this.sendSettings,
        textTags: this.textTags,
        days: this.days,
        marketsTypes: this.marketsTypes
      },
      nzStyle: { top: '2vh' },
      nzWidth: "80%"
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.standardResponse = null;
    })
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.standardResponse$ = that.standardResponseService.get(id).subscribe(
          (standardResponse) => {
            if (standardResponse) {
              that.standardResponse = standardResponse;
              that.onShowModalAddEditStandardResponse();
            }
          }
        );
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'La réponse standardisée sera supprimée de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.standardResponseService.delete(id);
          }
        });
      }
    });
  }

  private initSubscriptions() {
    this.selectAffairsMonitoringTypes$ = this.affairMonitoringTypeService.selectAffairsMonitoringTypes$.subscribe(
      (data) => {
        if (data) {
          this.affairsMonitoringTypes = data;
        }
      }
    )
    this.sendSettings$ = this.sendSettingService.sendSettingsSelect$.subscribe(
      (data) => {
        if (data) {
          this.sendSettings = data;
        }
      }
    )
    this.days$ = this.thingService.days$.subscribe(
      (data) => {
        if (data) {
          this.days = data;
        }
      }
    )
    this.marketsTypes$ = this.marketTypeService.selectMarketType$.subscribe(
      (data) => {
        if (data) {
          this.marketsTypes = data;
        }
      }
    )

  }

}
