import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AffairRenovationService } from '@libs/services/src/lib/affair-renovation.service';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { DashboardService } from '@libs/services/src/lib/dashboard.service';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-affairs-renovation-index',
  templateUrl: './affairs-renovation-index.component.html',
  styleUrls: ['./affairs-renovation-index.component.scss']
})
export class AffairsRenovationIndexComponent implements OnInit, AfterViewInit {
  @Input() marketType: number;
  @Input() mlAgencies: any[];
  @Input() agencies: any[];
  @Input() worksCompanies: any[];
  @Input() assistants: any[];
  @Input() technicians: any[];
  @Input() products: any[];
  @Input() productsStatuses: any[];
  @Input() affairsStatuses: any[];
  @Input() affairsGoals: any[];
  @Input() affairsMonitoringTypes: any[];
  public columns: DataTableColumn[] = [
    {
      attr: 'mlRef',
      name: 'Réf.',
      individualFilterInput: true
    },
    {
      attr: 'oldRef',
      name: 'Réf. suivi',
      individualFilterInput: true,
      visible: false
    },
    {
      attr: 'finalCustomers',
      name: 'Nom(s) et prénom(s)',
      individualFilterInput: true
    },
    {
      attr: 'customer',
      name: 'Client',
      orderable: false,
      searchable: false
    },
    {
      attr: 'workCompanyName',
      name: 'Entreprise travaux',
      individualFilterInput: false,
      searchable: false,
      orderable: false
    },
    {
      attr: 'workSiteAddress',
      name: 'Adresse',
      individualFilterInput: true
    },
    {
      attr: 'workSitePostalCode',
      name: 'CP',
      individualFilterInput: true
    },
    {
      attr: 'workSiteLocality',
      name: 'Ville',
      individualFilterInput: true
    },
    {
      attr: 'folderNumber',
      name: 'N° dossier',
      individualFilterInput: true,
      visible: false
    },
    {
      attr: 'customerRef',
      name: 'Réf. client',
      individualFilterInput: true,
      visible: false
    },
    {
      attr: 'administrativeInternalNote',
      name: 'Note administrative',
      individualFilterInput: true
    },
    {
      attr: 'technicalInternalNote',
      name: 'Note technique',
      individualFilterInput: true
    },
    {
      attr: 'assistant',
      name: 'Assistante',
      searchable: false,
      orderable: false
    },
    {
      attr: 'technician',
      name: 'Technicien',
      searchable: false,
      orderable: false
    },
    {
      attr: 'affairStatus',
      name: 'Statut',
      searchable: false,
      orderable: false
    },
    {
      attr: 'productStatuses',
      name: 'Produit(s)',
      orderable: false,
      searchable: false,
      width: '15%'
    },
  ];
  public datatableSelector = 'affairs-renovation-datatable';
  private readonly storageKey = 'affairs-renovation-filters-form';
  public form: FormGroup;

  constructor(
    private titleService: Title,
    private affairService: AffairRenovationService,
    private storageService: FormLocalStorageService,
    private dashboardService: DashboardService
  ) {
    this.titleService.setTitle(`Rénovation : Liste des affaires${tabSuffix}`);
  }

  ngOnInit() {
    let storageFormValue = this.dashboardService.retrieveDashboardFilters({
      assistants: this.assistants,
      productsStatuses: this.productsStatuses
    });
    if (storageFormValue == null) {
      storageFormValue = this.storageService.retrieve(this.storageKey);
    }
    this.form = this.affairService.getFiltersForm(storageFormValue);
    this.dashboardService.resetFilters();

    this.form.valueChanges.subscribe(form => {
      this.storageService.store(this.storageKey, this.form)
      this.affairService.redrawDataTable(this.form);
    });
  }

  ngAfterViewInit() {
    this.affairService.initDataTable(this.datatableSelector, this.columns, this.form)
  }

  resetForm() {
    const resetForm = this.affairService.getFiltersForm();
    this.form.reset(resetForm.value);
  }

}
