import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-customers-consult-graph-internal-notes-module',
  templateUrl: './customers-consult-graph-internal-notes-module.component.html',
  styleUrls: ['./customers-consult-graph-internal-notes-module.component.scss']
})
export class CustomersConsultGraphInternalNotesModuleComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() administrativeNoteRows = 10;
  @Input() technicalNoteRows = 10;

  constructor() { }

  ngOnInit() {
  }

}
