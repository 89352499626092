import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

export interface GoogleAddressFieldMatch {
    street_number?: string;
    route: string;
    postal_code: string;
    locality: string;
    concatStreetWithRoute?: boolean;
}

@Injectable()
export class FormFormatterService {

    constructor() { }


    createFormCopy(form: FormGroup) {
        if (form) {
            return JSON.parse(JSON.stringify(form.value))
        }
        return null;
    }

    formatSelectSingle(value: any): any {
        if (value != null && typeof value === 'object') {
            if (value.hasOwnProperty('value')) {
                value = value.value;
            }
        }
        return value;
    }

    formatSelectMultiple(array: any[]): string {
        if (array != null && Array.isArray(array)) {
            return JSON.stringify(array.map((x: any) => x.value));
        }
        return '';
    }

    formatSelectMultipleIds(array: any[]): string {
        if (array != null && Array.isArray(array)) {
            return JSON.stringify(array.map((x: any) => x.id));
        }
        return '';
    }

    formatSelectMultipleToIntArray(array: any[], customProperty?: string): any[] {
        if (array != null && Array.isArray(array)) {
            if (customProperty) {
                return array.map((x: any) => x[customProperty]);
            }
            return array.map((x: any) => x.value);
        }
        return [];
    }


    formatDate(value: any): any {
        if (value != null) {
            console.log(value);
            return new Date(value).toJSON()
        }
        return null;
    }

    formatBoolean(value: any): number {
        if (typeof value === 'boolean') {
            return value ? 1 : 0;
        } else if (typeof value === 'string') {
            return value === '1' ? 1 : 0;
        } else if (value === 0 || value === 1) {
            return value;
        } else {
            return 0;
        }
    }

    filterCheckedValues(array: any[]): any[] {
        if (array != null && Array.isArray(array)) {
            return array.filter(v => v.checked);
        }
        return [];
    }

    completeGoogleAddress(form: FormGroup, address: Address, fieldMatch: GoogleAddressFieldMatch): FormGroup {
        if (address && address.address_components) {
            let streetNumber = '';
            for (const addressType of address.address_components) {
                if (fieldMatch.concatStreetWithRoute && addressType.types[0] === 'street_number') {
                    streetNumber = addressType.long_name;
                }
                const control = form.controls[fieldMatch[addressType.types[0]]];
                if (control) {
                    if (fieldMatch.concatStreetWithRoute && addressType.types[0] === 'route') {
                        addressType.long_name = streetNumber + ' ' + addressType.long_name;
                    }
                    control.patchValue(addressType.long_name);
                }
            }
        }
        return form;
    }


}
