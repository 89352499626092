import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { Affair, Building, Event, SelectModel, SubmitButton } from '@libs/models/src';
import { BuildingService, CommonService, EventService, NotifService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-event-affair-modal-add-edit',
  templateUrl: './event-affair-modal-add-edit.component.html',
  styleUrls: ['./event-affair-modal-add-edit.component.scss']
})
export class EventAffairModalAddEditComponent implements OnInit {
  @Input() event: Event = null;
  @Input() affair: Affair;
  @Input() buildings: Building[];
  @Input() users: any[];
  @Input() eventsTypes: SelectModel[];
  @Input() form: FormGroup;
  @Input() editFromCalendar: boolean = false;
  public buildingsSelect: SelectModel[];
  public submitButton: SubmitButton;
  public isOpenOnce;
  public isTimeOpenOnce;
  public defaultDate;
  public defaultTime;
  public isDeleteClicked = false;

  constructor(
    private modal: NzModalRef,
    private eventService: EventService,
    private buildingService: BuildingService,
    private notifService: NotifService
  ) {
  }

  ngOnInit() {
    this.buildingsSelect = this.buildingService.formatSelect(this.buildings);
    this.determinateSubmitButton();

    if (!this.event) {
      this.defaultDate = null;
      this.defaultTime = null;
      this.isOpenOnce = false;
      this.isTimeOpenOnce = false;
    }
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

  public onSelectValueChanged() {
    this.form.patchValue({
      'name': this.generateName()
    });
    this.eventService.getGeneratedDescription(this.form).subscribe(
      (description) => {
        this.form.patchValue({
          'description': description
        });
      }
    )

  }

  public submitForm() {
    if (this.isValidForm()) {
      if (this.event) {
        this.eventService.updateForAffair(this.event.id, this.form).subscribe(
          (success) => {
            this.notifService.showSuccessNotif(success);
            this.modal.close();
          });
      } else {
        this.eventService.storeForAffair(this.affair.id, this.form).subscribe(
          (success) => {
            this.notifService.showSuccessNotif(success);
            this.modal.close();
          }
        );
      }

    }
  }

  public onDeleteClicked(): void {
    this.isDeleteClicked = true;
    this.eventService.delete(this.event.id).subscribe(
      (success) => {
        this.isDeleteClicked = false;
        this.notifService.showSuccessNotif(success);
        this.modal.close();
      }
    )
  }

  onDatepickerOpening() {
    if (!this.isOpenOnce) {
      this.defaultDate = new Date().setMinutes(0);
      this.isOpenOnce = true;
    }
  }

  onTimepickerOpening() {
    if (!this.isTimeOpenOnce) {
      this.defaultTime = new Date().setMinutes(0);
      this.isTimeOpenOnce = true;
    }
  }

  public goToAffairClicked(): void {
    window.open(`affairs/${this.affair.id}/show`);
  }

  private generateName() {
    const buildingsSelectedIds = this.form.get('buildings').value ? this.form.get('buildings').value.map(x => x.value) : null;
    const buildingsSelected = buildingsSelectedIds != null ? this.buildings.filter(x => buildingsSelectedIds.includes(x.id)) : [];

    const eventTypeSelectedId = this.form.get('eventTypeId').value;
    const eventTypeSelected = eventTypeSelectedId ? this.eventsTypes.find(x => x.value == eventTypeSelectedId) : null

    let name = this.affair.mlRef + ' - ' + this.affair.finalCustomerFullName;
    if (buildingsSelected.length > 0) {
      for (let i = 0; i < buildingsSelected.length; i++) {
        name = name.concat(' - ', buildingsSelected[i].name);
      }
    }
    if (eventTypeSelected) {
      name = name.concat(' - ', eventTypeSelected.label);
    }

    return name;
  }

  private determinateSubmitButton() {
    let text = '';
    let icon = null;
    if (!this.event) {
      text = 'Ajouter l\'événement';
      icon = faPlus;
    } else {
      text = 'Modifier l\'événement';
      icon = faSave;
    }
    this.submitButton = {
      text: text,
      originalText: text,
      icon: icon,
      originalIcon: icon,
      enabled: true
    };
    this.eventService.setSubmitButton(this.submitButton);
  }



}
