import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { ModelWithDatatableAndModalCrud } from './classes/model-datatable-modal-crud';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { AffairMarketType, AffairPayerPreference } from '@eros-front/enum';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { CommonService } from './utilities/common.service';
import { catchError, finalize } from 'rxjs/operators';
import { ModelWithDatatableAndCrud } from './classes/model-datatable-crud';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Affair } from '@libs/models/src';

export interface AffairDatatableParameters {
    selectedMlAgencies: string;
    selectedAgencies: string;
}

export interface IncomingCallSheetParameters {
    contactId?: number;
    contactType: string;
    contactEmail?: string;
    size: number;
    page: number;
    search: string;
}

@Injectable()
export class AffairService extends ModelWithDatatableAndCrud {

    private route = '/affairs';
    public affair$ = new BehaviorSubject<any>(undefined);
    public affairForAdd$ = new BehaviorSubject<any>(undefined);
    public affairProductEdit$ = new BehaviorSubject<any>(undefined);
    public affairSelects$ = new BehaviorSubject<any>(undefined);
    public affairSelectsForShow$ = new BehaviorSubject<any>(undefined);
    public affairProgrammedStandardResponses$ = new BehaviorSubject<any>(undefined);
    public affairPayerPreferenceEnum = AffairPayerPreference;
    public affairDatatableParams: AffairDatatableParameters;
    public marketTypeEnum = AffairMarketType;

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]) {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns,
            redirectUrl: 'affairs/$id/show'
        });
    }

    public get(id: number): void {
        super.get(id, this.affair$, this.route);
    }

    public getForAdd(id: number): void {
        this.apiService.get(this.route + '/' + id + '/get-for-add')
            .subscribe(
                (object) => {
                    this.affairForAdd$.next(object);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public create(form: FormGroup): void {
        this.submitButtonService.setDisabled(this.submitButton);
        this.apiService.post(this.route, this.formatForm(form))
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
            .subscribe(
                (response: any) => {
                    this.router.navigateByUrl('/affairs/' + response.data.id + '/show');
                    this.notifService.showSuccessNotif(response);
                }
            );
    }

    public update(id: number, form: any): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.put(this.route + '/' + id, this.formatForm(form));
    }

    public archive(id: number): Observable<any> {
        return this.apiService.get(this.route + '/' + id + '/archive');
    }

    public unarchive(id: number): Observable<any> {
        return this.apiService.get(this.route + '/' + id + '/archive/0');
    }

    public validate(id: number): Observable<any> {
        return this.apiService.get(this.route + '/' + id + '/validate');
    }

    public getInformationsForm(affair?: any): FormGroup {
        const agencyId = affair ?
            this.commonService.valueToFormSelect(affair.agencyId) : this.commonService.getDefaultFormNullValue();
        const workCompanyId = affair ?
            this.commonService.valueToFormSelect(affair.workCompanyId) : this.commonService.getDefaultFormNullValue();
        const mlAgencyId = affair ?
            this.commonService.valueToFormSelect(affair.mlAgencyId) : this.commonService.getDefaultFormNullValue();
        const userAssistantId = affair ?
            this.commonService.valueToFormSelect(affair.userAssistantId) : this.commonService.getDefaultFormNullValue();
        const userTechnicianId = affair ?
            this.commonService.valueToFormSelect(affair.userTechnicianId) : this.commonService.getDefaultFormNullValue();
        const studyOfficeId = affair ?
            this.commonService.valueToFormSelect(affair.studyOfficeId) : this.commonService.getDefaultFormNullValue();
        const marketTypeId = affair && affair.marketTypeId ?
            this.commonService.valueToFormNumber(affair.marketType.id) : this.marketTypeEnum.STANDALONE_HOUSE;
        const affairStatusId = affair && affair.affairStatusId ?
            this.commonService.valueToFormSelect(affair.affairStatusId) : this.commonService.getDefaultFormNullValue();
        const workSiteStreetNumber = affair ?
            this.commonService.valueToFormString(affair.workSiteStreetNumber) : this.commonService.getDefaultFormStringValue();
        const workSiteAddress = affair ?
            this.commonService.valueToFormString(affair.workSiteAddress) : this.commonService.getDefaultFormStringValue();
        const workSiteAdditionalAddress = affair ?
            this.commonService.valueToFormString(affair.workSiteAdditionalAddress) : this.commonService.getDefaultFormStringValue();
        const workSitePostalCode = affair ?
            this.commonService.valueToFormString(affair.workSitePostalCode) : this.commonService.getDefaultFormStringValue();
        const workSiteLocality = affair ?
            this.commonService.valueToFormString(affair.workSiteLocality) : this.commonService.getDefaultFormStringValue();
        const finalCustomerLastname = affair ?
            this.commonService.valueToFormString(affair.finalCustomerLastname) : this.commonService.getDefaultFormStringValue();
        const finalCustomerFirstname = affair ?
            this.commonService.valueToFormString(affair.finalCustomerFirstname) : this.commonService.getDefaultFormStringValue();
        const finalCustomerLastnameSecondary = affair ?
            this.commonService.valueToFormString(affair.finalCustomerLastnameSecondary) : this.commonService.getDefaultFormStringValue();
        const finalCustomerFirstnameSecondary = affair ?
            this.commonService.valueToFormString(affair.finalCustomerFirstnameSecondary) : this.commonService.getDefaultFormStringValue();
        const finalCustomerEmail = affair ?
            this.commonService.valueToFormString(affair.finalCustomerEmail) : this.commonService.getDefaultFormStringValue();
        const finalCustomerPhone = affair ?
            this.commonService.valueToFormString(affair.finalCustomerPhone) : this.commonService.getDefaultFormStringValue();
        const folderNumber = affair ?
            this.commonService.valueToFormString(affair.folderNumber) : this.commonService.getDefaultFormStringValue();
        const customerRef = affair ?
            this.commonService.valueToFormString(affair.customerRef) : this.commonService.getDefaultFormStringValue();
        const invoiceComment = affair ?
            this.commonService.valueToFormString(affair.invoiceComment) : this.commonService.getDefaultFormStringValue();
        const finalCustomerStreetNumber = affair ?
            this.commonService.valueToFormString(affair.finalCustomerStreetNumber) : this.commonService.getDefaultFormStringValue();
        const finalCustomerAddress = affair ?
            this.commonService.valueToFormString(affair.finalCustomerAddress) : this.commonService.getDefaultFormStringValue();
        const finalCustomerAdditionalAddress = affair ?
            this.commonService.valueToFormString(affair.finalCustomerAdditionalAddress) : this.commonService.getDefaultFormStringValue();
        const finalCustomerPostalCode = affair ?
            this.commonService.valueToFormString(affair.finalCustomerPostalCode) : this.commonService.getDefaultFormStringValue();
        const finalCustomerLocality = affair ?
            this.commonService.valueToFormString(affair.finalCustomerLocality) : this.commonService.getDefaultFormStringValue();
        const deliveryDate = affair ?
            this.commonService.valueToFormDate(affair.deliveryDate) : this.commonService.getDefaultFormNullValue();
        const constructionYear = affair ?
            this.commonService.valueToFormDate(affair.constructionYear) : this.commonService.getDefaultFormNullValue();
        const costEstimateRef = affair ?
            this.commonService.valueToFormString(affair.costEstimateRef) : this.commonService.getDefaultFormStringValue();
        const administrativeInternalNote = affair ?
            this.commonService.valueToFormString(affair.administrativeInternalNote) : this.commonService.getDefaultFormStringValue();
        const technicalInternalNote = affair ?
            this.commonService.valueToFormString(affair.technicalInternalNote) : this.commonService.getDefaultFormStringValue();
        const renovationComment = affair ?
            this.commonService.valueToFormString(affair.renovationComment) : this.commonService.getDefaultFormStringValue();
        const brandPurchaseOrder = affair ?
            this.commonService.valueToFormString(affair.brandPurchaseOrder) : this.commonService.getDefaultFormStringValue();
        const fields = [];
        if (affair) {
            affair.fields.forEach((field) => {
                fields.push(this.createAffairFieldForm(field));
            });
        }
        const affairsGoalsIds = affair ?
            this.commonService.valueToFormSelectMultiple(affair.affairsGoals) : [];
        const performances = affair ?
            this.commonService.valueToFormSelectMultiple(affair.performances) : [];

        return this.formBuilder.group({
            marketTypeId: [marketTypeId, Validators.required],
            affairStatusId: [affairStatusId],
            agencyId: [agencyId, Validators.required],
            workCompanyId: [workCompanyId],
            mlAgencyId: [mlAgencyId, Validators.required],
            userAssistantId: [userAssistantId],
            userTechnicianId: [userTechnicianId],
            studyOfficeId: [studyOfficeId],
            workSiteStreetNumber: workSiteStreetNumber,
            workSiteAddress: [workSiteAddress, Validators.required],
            workSiteAdditionalAddress: workSiteAdditionalAddress,
            workSitePostalCode: [workSitePostalCode, Validators.required],
            workSiteLocality: [workSiteLocality, Validators.required],
            finalCustomerLastname: [finalCustomerLastname, {
                validators: Validators.required,
                updateOn: 'blur'
            }],
            finalCustomerFirstname: finalCustomerFirstname,
            finalCustomerLastnameSecondary: finalCustomerLastnameSecondary,
            finalCustomerFirstnameSecondary: finalCustomerFirstnameSecondary,
            finalCustomerEmail: finalCustomerEmail,
            finalCustomerPhone: finalCustomerPhone,
            invoiceComment: invoiceComment,
            finalCustomerStreetNumber: finalCustomerStreetNumber,
            finalCustomerAddress: finalCustomerAddress,
            finalCustomerAdditionalAddress: finalCustomerAdditionalAddress,
            finalCustomerPostalCode: finalCustomerPostalCode,
            finalCustomerLocality: finalCustomerLocality,
            folderNumber: folderNumber,
            customerRef: customerRef,
            deliveryDate: deliveryDate,
            constructionYear: constructionYear,
            costEstimateRef: costEstimateRef,
            administrativeInternalNote: administrativeInternalNote,
            renovationComment: renovationComment,
            technicalInternalNote: technicalInternalNote,
            brandPurchaseOrder: brandPurchaseOrder,
            affairFields: this.formBuilder.array(fields),
            affairsGoalsIds: [affairsGoalsIds],
            performances: [performances]
        });
    }

    public getInvoicesPreferencesForm(affair: any): FormGroup {
        let mlAgencyId = null;
        let payerPreference = '';
        let payerName = '';
        let payerAccountingCode = '';
        let payerAddress = '';
        let payerPostalCode = '';
        let payerLocality = '';
        let affairInvoicing = '';
        let billingPreference = '';
        if (affair) {
            const pref = affair.affairInvoicingPreferences;
            mlAgencyId = affair.mlAgencyId;
            if (pref != null) {
                payerPreference = pref.payerPreference.toString();
                payerName = pref.payerName;
                payerAccountingCode = pref.payerAccountingCode;
                payerAddress = pref.payerAddress;
                payerPostalCode = pref.payerPostalCode;
                payerLocality = pref.payerLocality;
                affairInvoicing = pref.affairInvoicing.toString();
                billingPreference = pref.billingPreference;
            }
        }
        return this.formBuilder.group({
            mlAgencyId: [mlAgencyId, Validators.required],
            payerPreference: [payerPreference, Validators.required],
            payerName: [payerName, Validators.required],
            payerAccountingCode: [payerAccountingCode, Validators.required],
            payerAddress: payerAddress,
            payerPostalCode: [payerPostalCode, Validators.required],
            payerLocality: [payerLocality, Validators.required],
            affairInvoicing: affairInvoicing
        });
    }

    public createAffairFieldForm(field: any): FormGroup {
        // In case of field of type date,
        // we need to put the value to null.
        // Otherwise, the form will consider this input has invalid during the submit
        let defaultValue = field.value;
        if (field.value == '' && field.type === 'date') {
            defaultValue = null;
        }
        return this.formBuilder.group({
            id: [field.id, Validators.required],
            label: field.name,
            type: field.type,
            value: defaultValue
        });
    }

    private formatForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.agencyId = this.formFormatterService.formatSelectSingle(values.agencyId);
        values.mlAgencyId = this.formFormatterService.formatSelectSingle(values.mlAgencyId);
        values.affairsGoalsIds = this.formFormatterService.formatSelectMultipleToIntArray(values.affairsGoalsIds);
        values.performances = this.formFormatterService.formatSelectMultipleToIntArray(values.performances);
        values.affairFields.forEach((value) => {
            if (value.type === "date") {
                value.value = value.value ? new Date(value.value).toDateString() : null;
            }
        });
        return values;
    }

    public redrawDataTable(form: any): void {
        const affairDatatableParams: AffairDatatableParameters = {
            selectedMlAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedMlAgencies),
            selectedAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedAgencies)
        };
        super.redrawDataTable(affairDatatableParams);
    }

    public reset(): void {
        this.affair$.next(null);
    }

    public resetForAdd(): void {
        this.affairForAdd$.next(null);
    }

    public checkIfDifferentId(id: number): boolean {
        let affairData = this.affair$.getValue();
        if (isNaN(id) || affairData != null && id === affairData.id) {
            return false;
        }
        return true;
    }

    public getSelects(): void {
        this.apiService.get('/affairs/list/selects')
            .subscribe(
                (selects) => {
                    this.affairSelects$.next(selects);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération des selections'
                    });
                }
            );
    }

    public getSelectsForShow(affairId: number): void {

        this.apiService.get('/affairs/show/' + affairId + '/selects')
            .subscribe(
                (selects) => {
                    this.affairSelectsForShow$.next(selects);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération des selections'
                    });
                }
            );
    }

    public checkIfAffairRoute(affairId: number, route: string): boolean {
        const affairRoutes = [
            `${this.route}/${affairId}/document-customer/import`,
            `${this.route}/${affairId}/document-delivered/import`,
        ]
        return affairRoutes.includes(route);
    }

    public getProgrammedStandardResponses(affairId) {
        this.apiService.get(`/affairs/${affairId}/programmed-standards-responses`)
            .subscribe(
                (data) => {
                    this.affairProgrammedStandardResponses$.next(data);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération des selections'
                    });
                }
            );

    }

    public deleteProgrammedStandardResponses(affair: Affair, programmedStandardResponseId): Observable<any> {
        return this.apiService.delete(`/affairs/${affair.id}/programmed-standards-responses/${programmedStandardResponseId}`)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            );

    }

    public getAudits(affairId: number): Observable<any> {
        return this.apiService.get(`/affairs/${affairId}/audits`);

    }

    public getForIncomingCallSheetList(params: IncomingCallSheetParameters): Observable<any> {
        return this.apiService.post(`/affairs/incoming-call-sheet/list`, params)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            );
    }

}
