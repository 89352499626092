import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AutocompleteOptions, FormFormatterService } from '@libs/services/src';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'general-adress-form',
  templateUrl: './general-adress-form.component.html',
  styleUrls: ['./general-adress-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GeneralAdressFormComponent implements OnInit {
  @Input() form: FormGroup;
  public autocompleteOptions: AutocompleteOptions = new AutocompleteOptions();

  constructor(
    private formFormatterService: FormFormatterService
  ) { }

  ngOnInit() {
  }


  onAddressChanged(address: Address) {
    this.form = this.formFormatterService.completeGoogleAddress(
      this.form, address,
      {
        street_number: 'streetNumber',
        route: 'address',
        postal_code: 'postalCode',
        locality: 'locality'
      }
    );
  }

}
