import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectModel } from '@libs/models/src';

@Component({
  selector: 'app-affair-products-to-invoicing-filters',
  templateUrl: './affair-products-to-invoicing-filters.component.html',
  styleUrls: ['./affair-products-to-invoicing-filters.component.scss']
})
export class AffairProductsToInvoicingFiltersComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() agencies: SelectModel[];
  @Input() mlAgencies: SelectModel[];
  @Input() productsStatuses: SelectModel[];

  constructor() { }

  ngOnInit() {
  }

}
