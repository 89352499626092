import { Component, OnInit, Input, Output, EventEmitter, forwardRef, ViewChild, Injector, ElementRef, ViewEncapsulation, OnChanges } from '@angular/core';
import { ControlContainer, FormGroup, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

// creates an ngModel accessor to be used in components providers
const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NgSelectMultipleFormComponent),
  multi: true
};

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ng-select-multiple-form',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  templateUrl: './ng-select-multiple-form.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./ng-select-multiple-form.component.scss'],
})
export class NgSelectMultipleFormComponent implements OnInit, ControlValueAccessor {

  _onChange: (_: any) => {};
  _onTouched: (_: any) => {};
  _disabled: boolean;
  _value: any = '';
  control: any;
  classesOnCustomControl: any;
  @ViewChild(NgSelectComponent, { static: false }) ref: NgSelectComponent;

  @Input() class: string;
  @Input() items: any[];
  @Input() selectedNgModel: any;
  @Input() maxSelectedItems: number;
  @Input() selectAllText: string;
  @Input() selectAllFilteredText: string;
  @Input() deselectAllText: string;
  @Input() countSelectedText: string;
  @Input() placeholder: string;
  @Input() virtualScroll = false;
  @Output() change = new EventEmitter<any>();
  public filteredItems: any[] = [];

  constructor(private injector: Injector, private element: ElementRef) { }

  ngOnInit() {
    if (!this.maxSelectedItems || this.maxSelectedItems <= 0) {
      this.maxSelectedItems = 3;
    }
    if (!this.items) {
      this.items = [];
    }
  }

  onSelectAll() {
    this.selectedNgModel = this.items;
    this.onChange(this.items);
  }

  onSelectAllFiltered() {
    console.log(this.filteredItems);
    this.selectedNgModel = this.filteredItems;
    this.onChange(this.filteredItems);
  }

  onDeselectAll() {
    this.filteredItems = [];
    this.selectedNgModel = [];
  }

  clearSelectItem(callback: Function, arg: any) {
    callback(arg);
  }

  onChange($event) {
    this.change.emit($event);
    this.propagateChange($event);
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}


  // write the value to the input
  writeValue(value: any) {
    if (value !== undefined) {
      this._value = value;
    }
  }

  onSearch($event) {
    if ($event.term == "") {
      this.filteredItems = [];
    } else {
      this.filteredItems = $event.items;
    }
  }

}
