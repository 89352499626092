import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FileService } from '../file.service';

interface CachedImage {
 url: string;
 blob: Blob;
}

@Injectable()
export class ImageService {
  private _cacheUrls: string[] = [];
  private _cachedImages: CachedImage[] = [];

  constructor(
    private fileService: FileService,
  ) {

  }

  set cacheUrls(urls: string[]) {
    this._cacheUrls = urls;
  }

  get cacheUrls(): string[] {
    return this._cacheUrls;
  }
  
  set cachedImages(image: CachedImage) {
    this._cachedImages.push(image);
  }

  getImage(path: string) {
    const index = this._cachedImages.findIndex(image => image.url === path);
    if (index > -1) {
      const image = this._cachedImages[index];
      return of(image.blob);
    }
    return this.fileService.thumbnail(path).pipe(
      tap(blob => this.checkAndCacheImage(path, blob))
    );
  }

  checkAndCacheImage(url: string, blob: Blob) {
   this.cacheUrls.push(url);
    if (this._cacheUrls.indexOf(url) > -1) {
    this._cachedImages.push({url, blob});
    }
  }
}
