import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Affair } from '@libs/models/src';
import { MailService } from '@libs/services/src';

@Component({
  selector: 'app-affair-show-mails',
  templateUrl: './affair-show-mails.component.html',
  styleUrls: ['./affair-show-mails.component.scss']
})
export class AffairShowMailsComponent implements OnInit, OnChanges {
  @Input() affair: Affair;
  public mailsData = [];

  constructor(
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.initList();
  }

  private initList() {
    this.mailsData = this.affair.mails;
  }

}
