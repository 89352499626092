import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AffairService } from '@libs/services/src';
import { AffairDocumentDeliveredService } from '@libs/services/src/lib/affair-document-delivered.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-affair-document-delivered-import',
  templateUrl: './affair-document-delivered-import.component.html',
  styleUrls: ['./affair-document-delivered-import.component.scss']
})
export class AffairDocumentDeliveredImportComponent implements OnInit, OnDestroy {

  public affair: any;
  private affair$: Subscription;
  private dropzones$: Subscription;
  public dropzones = [];

  constructor(
    public affairDocumentService: AffairDocumentDeliveredService,
    private route: ActivatedRoute,
    private affairService: AffairService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = Number(params.get('id'));
      if (this.affairService.checkIfDifferentId(id)) {
        this.affairService.get(id);
      }
    });
    this.affair$ = this.affairService.affair$.subscribe(
      (affair) => {
        if (affair) {
          this.affair = affair;
          this.initWhenAffair();
        }
      }
    );
    this.dropzones$ = this.affairDocumentService.dropzones$.subscribe(
      (dropzones) => {
        if (dropzones) {
          this.dropzones = dropzones;
        }
      }
    );
  }

  ngOnDestroy() {
    this.affair$.unsubscribe();
    this.dropzones$.unsubscribe();
    this.affairService.reset();
    this.affairDocumentService.resetDropzones();
  }

  private initWhenAffair() {
    this.titleService.setTitle('Import de documents pour l\'affaire : ' + this.affair.mlRef);
    this.affairDocumentService.getDropzones(this.affair.id);
  }

}
