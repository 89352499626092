import { Component, OnInit, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';
import { SubmitButton } from '@eros-front/models';
import { DocumentCustomerService } from '@eros-front/services';
import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-settings-products-documents-customers-modal',
  templateUrl: './settings-products-documents-customers-modal.component.html',
  styleUrls: ['./settings-products-documents-customers-modal.component.scss']
})
export class SettingsProductsDocumentsCustomersModalComponent implements OnInit, AfterViewInit {

  @Input() document: any;
  @Input() extensions: any[];
  @Input() textTags: any[];
  @ViewChild('settingsAddEditDocumentCustomer', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public submitButton: SubmitButton;
  @ViewChild('filename') filename: ElementRef;

  constructor(
    private documentService: DocumentCustomerService
  ) { }

  ngOnInit() {
    this.initValues();
  }

  ngAfterViewInit() {
    this.documentService.setModalAddEdit(this.childModal);
  }

  onModalShown() {
    this.determinateSubmitButton();
    this.form = this.documentService.getForm(this.document, this.extensions);
  }

  onModalHidden() {
    this.initValues();
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.document) {
        this.documentService.update(this.document.id, this.form);
      } else {
        this.documentService.store(this.form);
      }
    }
  }

  private initValues() {
    this.document = null;
    this.form = null;
  }

  private determinateSubmitButton() {
    let text = '';
    let icon = null;
    if (!this.document) {
      text = 'Ajouter le document';
      icon = faPlus;
    } else {
      text = 'Modifier le document';
      icon = faSave;
    }
    this.submitButton = {
      text: text,
      originalText: text,
      icon: icon,
      originalIcon: icon,
      enabled: true
    };
    this.documentService.setSubmitButton(this.submitButton);
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

  insertTextTagInFilename(text: string) {
    var startPos = this.filename.nativeElement.selectionStart;
    this.filename.nativeElement.focus();
    this.filename.nativeElement.value = this.filename.nativeElement.value.substr(0, this.filename.nativeElement.selectionStart)
      + text
      + this.filename.nativeElement.value.substr(this.filename.nativeElement.selectionStart, this.filename.nativeElement.value.length);

    this.filename.nativeElement.selectionStart = startPos;
    this.filename.nativeElement.focus();
    this.form.patchValue({
      filename: this.filename.nativeElement.value
    });
  }

}
