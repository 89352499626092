import { BehaviorSubject } from 'rxjs';
import { AuthentificationService } from '../authentification.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CustomLocalStorageService } from '@libs/services/src/lib/utilities/custom-local-storage.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthentificationService,
    private localStorageService: CustomLocalStorageService
  ) {
    this.route.queryParams
      .subscribe(params => {
        if (params.email !== "" && params.token !== "") {
          // store email and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('user',
            JSON.stringify({ email: params.email, token: params.token }));
          this.authService.setToken(params.token);
          let selectedMarketsTypes = this.authService.getMarketsTypes(params.token);
          if (selectedMarketsTypes) {
            this.localStorageService.setSelectedMarketsTypes(selectedMarketsTypes);
          }
          this.loggedIn.next(true);
          this.router.navigate(['/dashboard']);
        }
      });
  }

  ngOnInit() {
  }

}
