import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataTableColumn, PerformanceService, SwalService} from '@eros-front/services';
import Swal from 'sweetalert2';
import { Performance } from '@eros-front/models';

@Component({
  selector: 'app-settings-products-performances-index',
  templateUrl: './settings-products-performances-index.component.html',
  styleUrls: ['./settings-products-performances-index.component.scss']
})
export class SettingsProductsPerformancesIndexComponent implements OnInit, AfterViewInit, OnDestroy {

  public datatableSelector = 'performances-datatable';
  public performance: Performance = null;
  public performance$: Subscription;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];

  constructor(
    private performanceService: PerformanceService,
    private swalService: SwalService
  ) { }

  ngOnInit() {
    this.initSubscriptions();
  }

  ngAfterViewInit() {
    this.performanceService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    this.performance$.unsubscribe();
  }

  onShowModalAddEditPerformance() {
    this.performanceService.showModalAddEdit();
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function() {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.performanceService.get(id);
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function() {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'La performance sera supprimée de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.performanceService.delete(id);
          }
        });
      }
    });
  }

  private initSubscriptions() {
    this.performance$ = this.performanceService.performance$.subscribe(
      (performance) => {
        if (performance) {
          this.performance = performance;
          this.onShowModalAddEditPerformance();
        }
      }
    );
  }

}
