import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Injectable()
export class DragDropService {

  moveItemInFormArray(
    formArray: FormArray,
    fromIndex: number,
    toIndex: number
  ): void {
    const dir = toIndex > fromIndex ? 1 : -1;

    const item = formArray.at(fromIndex);
    for (let i = fromIndex; i * dir < toIndex * dir; i = i + dir) {
      const current = formArray.at(i + dir);
      formArray.setControl(i, current);
    }
    formArray.setControl(toIndex, item);
  }

  copyItemInFormArray(
    data: any,
    formArray: FormArray,
    fromIndex: number,
    toIndex: number,
    itemFormGroup: FormGroup,
    arrayToRemove: any
  ): void {
    const item = data[fromIndex];
    formArray.insert(toIndex, itemFormGroup);
    arrayToRemove.splice(fromIndex, 1)
  }
}
