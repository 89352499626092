import { Component, Input, OnInit } from '@angular/core';
import { AffairMarketType } from '@libs/enum/src';
import { Affair } from '@libs/models/src';

@Component({
  selector: 'app-affair-form-notes',
  templateUrl: './affair-form-notes.component.html',
  styleUrls: ['./affair-form-notes.component.scss']
})
export class AffairFormNotesComponent implements OnInit {
  @Input() affair: Affair;
  @Input() form;
  public modules = {
    'toolbar': [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
    ]
  }
  public marketTypeEnum = AffairMarketType;

  constructor() { }

  ngOnInit() {
  }

  isNotesCollapsed() {
    if (this.form) {
      return this.form.value.administrativeInternalNote == "";
    }
    return true;
  }

  isRenovationMarket() {
    return this.affair.marketTypeId == this.marketTypeEnum.RENOVATION;
  }

}
