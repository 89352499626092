import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { AffairContact, Intervention, SelectModel, SubmitButton, User } from '@libs/models/src';
import { AffairContactService, ContactSelectParameters, ContactService, InterventionService, NotifService, SelectService, SettingService, SwalService, UserService } from '@libs/services/src';
import { Subject, Subscription, throwError, zip } from 'rxjs';
import { AffairMarketType, InterventionStatusEnum } from '@eros-front/enum';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { NzModalService } from 'ng-zorro-antd/modal';
import { InterventionEditProgramModalComponent } from './intervention-edit-program-modal/intervention-edit-program-modal.component';
import { InterventionEditShiftModalComponent } from './intervention-edit-shift-modal/intervention-edit-shift-modal.component';
import { InterventionEditDeprogramModalComponent } from './intervention-edit-deprogram-modal/intervention-edit-deprogram-modal.component';
import { InterventionEditCancelModalComponent } from './intervention-edit-cancel-modal/intervention-edit-cancel-modal.component';
import { ContactsCallModalComponent } from '@libs/components/src/lib/contacts-call-modal/contacts-call-modal.component';
import { catchError, takeUntil } from 'rxjs/operators';

export interface InterventionCardAffairProduct {
  id: number;
  checked: boolean;
  productId: number;
  productName: string;
  quantity: number;
  price: number;
}

@Component({
  selector: 'app-intervention-edit-informations',
  templateUrl: './intervention-edit-informations.component.html',
  styleUrls: ['./intervention-edit-informations.component.scss']
})

export class InterventionEditInformationsComponent implements OnInit, OnDestroy {

  @Input() intervention: Intervention;
  private selectWorksSupervisors$: Subscription;
  private settings$: Subscription;
  private zip$: Subscription;
  private refreshIntervention$: Subscription;
  private generatedDescription$: Subscription;
  private generatedName$: Subscription;
  public form: FormGroup;
  public technicians: any;
  public conformities: any;
  public contacts: any;
  public worksSupervisors: any;
  public worksSupervisorsSelect = []
  public shiftOrigins: any;
  public buildings: SelectModel[];
  public settings: any;
  public indicatorsInterventionsTypes: any = [];
  public interventionStatusEnum = InterventionStatusEnum;
  public marketTypeEnum = AffairMarketType;
  public selectContactsParams: ContactSelectParameters;
  public housings: any;
  public isSelectsInit = false;
  public submitButton: SubmitButton;
  public housingsSelected = [];
  public housingsFiltered = [];
  public worksSupervisorSelected = null;
  public affairsProductsToProgram$: Subscription;
  public affairProductsToProgram = [];
  public affairProducts = [];
  public affairsStatuses = [];
  public documentsCustomersAttached$: Subscription;
  public documentsCustomersAttached: any;
  public affairsContacts: AffairContact[];
  public affairContactsSelect: SelectModel[] = [];
  public shiftForm: FormGroup;
  public deprogramForm: FormGroup;
  public programForm: FormGroup;
  public cancelForm: FormGroup;
  public isDestroyed$ = new Subject<void>();

  constructor(
    private contactService: ContactService,
    private interventionService: InterventionService,
    private settingService: SettingService,
    private swalService: SwalService,
    private router: Router,
    private modalService: NzModalService,
    private notifService: NotifService,
    private affairContactService: AffairContactService,
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    if (this.intervention != null) {
      this.settingService.getAll();
      this.interventionService.getSelectsForEdit(this.intervention.id);
      this.interventionService.getDocumentCustomersAttached(this.intervention.id);
      this.selectContactsParams = {
        'selectedAgencies': JSON.stringify([this.intervention.affair.agency.ref])
      }
      this.contactService.getForSelectWithParams(this.selectContactsParams);
      this.contactService.getForSelectWorksSupervisor(this.selectContactsParams);
    }
    this.determinateSubmitButton();
  }

  ngOnDestroy() {
    this.zip$.unsubscribe();
    this.selectWorksSupervisors$.unsubscribe();
    this.settings$.unsubscribe();
    if (this.refreshIntervention$) {
      this.refreshIntervention$.unsubscribe();
    }
    this.generatedDescription$.unsubscribe();
    this.generatedName$?.unsubscribe();
    this.isDestroyed$?.next();
    this.isDestroyed$?.complete();
  }


  isValidForm(): boolean {
    return this.form.valid;
  }

  onSubmit(): void {
    if (this.isValidForm()) {
      this.interventionService.update(this.intervention.id, this.form).subscribe((response) => {
        this.notifService.showSuccessNotif(response);
        this.interventionService.get(this.intervention.id);
      });
    }
  }

  showShiftModal(): void {
    this.shiftForm = this.interventionService.initShiftForm(this.intervention, this.technicians);
    const modalInstance = this.modalService.create({
      nzContent: InterventionEditShiftModalComponent,
      nzComponentParams: {
        intervention: this.intervention,
        shiftOrigins: this.shiftOrigins,
        form: this.shiftForm,
        users: this.technicians
      }
    });
    modalInstance.afterClose.subscribe(() => {
      this.interventionService.refreshIntervention();
    });
  }

  showProgramModal(): void {
    this.programForm = this.interventionService.initProgramForm();
    const modalInstance = this.modalService.create({
      nzContent: InterventionEditProgramModalComponent,
      nzComponentParams: {
        intervention: this.intervention,
        form: this.programForm
      }
    });
    modalInstance.afterClose.subscribe(() => {
      this.interventionService.refreshIntervention();
    });
  }

  showDeprogramModal(): void {
    this.deprogramForm = this.interventionService.initDeprogramForm();
    const modalInstance = this.modalService.create({
      nzContent: InterventionEditDeprogramModalComponent,
      nzComponentParams: {
        intervention: this.intervention,
        shiftOrigins: this.shiftOrigins,
        form: this.deprogramForm
      }
    });
    modalInstance.afterClose.subscribe(() => {
      this.interventionService.refreshIntervention();
    });
  }

  showCancelModal() {
    this.cancelForm = this.interventionService.initCancelForm();
    const modalInstance = this.modalService.create({
      nzContent: InterventionEditCancelModalComponent,
      nzComponentParams: {
        intervention: this.intervention,
        shiftOrigins: this.shiftOrigins,
        form: this.cancelForm
      }
    });
    modalInstance.afterClose.subscribe(() => {
      this.interventionService.refreshIntervention();
    });

  }

  onOpenCallModalClicked(): void {
    const modalInstance = this.modalService.create({
      nzWidth: '80%',
      nzContent: ContactsCallModalComponent,
      nzComponentParams: {
        intervention: this.intervention,
        affairContacts: this.affairsContacts
      },
      nzStyle: { top: '2vh' }
    });
  }

  onSendYzierClicked(): void {
    this.interventionService.pushToYsier(this.intervention.id)
      .pipe(
        takeUntil(this.isDestroyed$),
        catchError(error => {
          this.swalService.showSwalError(error);
          return throwError(error);
        })
      )
      .subscribe((response) => {
        this.notifService.showSuccessNotif(response);
      })
  }

  confirm() {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'L\'intervention passera en statut confirmé.'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.interventionService.confirm(that.intervention.id).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.interventionService.get(this.intervention.id);
        });
      }
    });
  }

  markAsFree() {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'L\'intervention passera en statut clôturée et offerte.'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.interventionService.markAsFree(that.intervention.id).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.interventionService.get(this.intervention.id);
        });
      }
    });
  }

  markAsWaitingConfirmation() {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'L\'intervention passera en attente de confirmation client.'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.interventionService.markAsWaitingConfirmation(that.intervention.id).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.interventionService.get(this.intervention.id);
        });
      }
    });
  }

  markAsBilled() {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'L\'intervention passera en statut clôturée et facturée.'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.interventionService.markAsBilled(that.intervention.id).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.interventionService.get(this.intervention.id);
        });
      }
    });
  }

  getSettingValue(key) {
    return this.settings.find(x => x.key == key).value;
  }

  goToAffairClicked(): void {
    this.router.navigateByUrl(`affairs/${this.intervention.affairId}/show`);
  }

  get affairsProductsArray() {
    return this.form.get('affairProducts') as FormArray;
  }

  private initSubscriptions() {
    this.selectWorksSupervisors$ = this.contactService.selectWorksSupervisors$.subscribe(
      (data) => {
        if (data) {
          this.worksSupervisors = data;
          this.worksSupervisorsSelect = this.contactService.formatToSelectArray(this.worksSupervisors);
        }
      }
    );
    this.settings$ = this.settingService.settings$.subscribe(
      (data) => {
        if (data) {
          this.settings = data;
        }
      }
    )
    this.zip$ = zip(this.contactService.contactsSelect$,
      this.interventionService.interventionsEditSelects$)
      .subscribe(
        ([contacts, selects]) => {
          if (this.intervention && contacts && selects) {
            this.contacts = contacts.data;
            this.conformities = selects.conformities;
            this.shiftOrigins = selects.shiftOrigins;
            this.indicatorsInterventionsTypes = selects.indicatorsInterventionsTypes;
            this.technicians = selects.technicians;
            this.housings = selects.housings;
            this.buildings = selects.buildings;
            this.affairsContacts = selects.affairsContacts;
            this.affairsStatuses = selects.affairsStatuses;
            this.form = this.interventionService.getEditForm(this.intervention, this.technicians, this.contacts);
            this.interventionService.initHousingsCheckboxes(this.form, this.housings, this.intervention.housings);
            this.interventionService.getAffairsProductsToProgram(null, this.intervention.affair.id);
            if (this.buildings.length > 0) {
              this.housingsFiltered = this.interventionService.filterHousings(this.intervention.buildings, this.housings);
              this.interventionService.initHousingsCheckboxes(this.form, this.housingsFiltered, this.intervention.housings);
            }
            this.onChanges();
            this.affairContactsSelect = this.affairContactService.valueToFormSelectMultiple(this.affairsContacts);
            this.isSelectsInit = true;
          }
        }
      );
    this.refreshIntervention$ = this.interventionService.refreshIntervention$.subscribe(
      (data) => {
        if (data) {
          if (this.form) {
            this.intervention = data;
            this.form = this.interventionService.getEditForm(this.intervention, this.technicians, this.contacts);
            if (this.isSelectsInit) {
              this.interventionService.getAffairsProductsToProgram(null, this.intervention.affair.id);
              this.interventionService.initHousingsCheckboxes(this.form, this.housings, this.intervention.housings);
            }
          }
        }
      }
    )
    this.generatedName$ = this.interventionService.generatedName$.subscribe(
      (data) => {
        if (data) {
          if (this.form) {
            this.form.patchValue({ 'name': data });
          }
        }
      }
    )
    this.generatedDescription$ = this.interventionService.generatedDescription$.subscribe(
      (data) => {
        if (data) {
          if (this.form) {
            this.form.patchValue({ 'generatedDescription': data });
          }
        }
      }
    )
    this.affairsProductsToProgram$ = this.interventionService.affairsProductsToProgram$.subscribe(
      (data) => {
        if (data) {
          this.affairProductsToProgram = data;
          if (this.intervention && this.form) {
            this.interventionService.initAffairProductsCheckboxes(this.form, this.affairProductsToProgram, this.intervention.affairProducts, this.intervention.affair.affairProducts);
            this.affairProducts = this.getAffairProducts(this.affairProductsToProgram, this.intervention.affairProducts);
          }
        }
      }
    )
    this.interventionService.documentsCustomersAttached$.subscribe(
      (data) => {
        if (data) {
          this.documentsCustomersAttached = data;

        }
      }
    )
  }

  private onChanges(): void {
    this.form.get('buildingsIds').valueChanges.subscribe(value => {
      this.housingsFiltered = this.interventionService.filterHousings(value, this.housings, true);
      this.interventionService.initHousingsCheckboxes(this.form, this.housingsFiltered, this.intervention.housings);
    });
  }

  private determinateSubmitButton(): void {
    let text = '';
    let icon = null;
    icon = faSave;
    text = "Valider les changements"
    this.submitButton = {
      text: text,
      originalText: text,
      icon: icon,
      originalIcon: icon,
      enabled: true
    };
    this.interventionService.setSubmitButton(this.submitButton)
  }

  private getAffairProducts(affairProductsToProgram, interventionAffairProducts) {
    let affairProducts = [];
    affairProducts = interventionAffairProducts;
    affairProductsToProgram.forEach(affairProduct => {
      const found = interventionAffairProducts.find(x => x.id == affairProduct.id);
      if (!found) {
        affairProducts.push(affairProduct);
      }
    });
    return affairProducts;
  }

}
