import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { Router } from '@angular/router';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { FormFormatterService } from './utilities/form-formatter.service';

@Injectable()
export class IndicatorInterventionTypeService extends ModelWithDatatableAndCrudObservable {

    public route = '/indicators-interventions-types'
    public indicatorInterventionType$ = new BehaviorSubject<any>(undefined);
    public indicatorsInterventionsTypes$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        apiService: ApiService,
        router: Router,
        private formBuilder: FormBuilder,
        private commonService: CommonService,
        private formFormatterService: FormFormatterService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route);
    }

    public getAll(): Observable<any> {
        return super.getAll(this.route);
    }

    public getAllByMarketType(marketTypeId: number): Observable<any> {
        return this.apiService.get(this.route + '/markets-types/' + marketTypeId);
    }

    public store(form: any): Observable<any> {
        return super.store(this.formatForm(form), this.route);
    }

    public update(id: number, form: any): Observable<any> {
        return super.update(id, this.formatForm(form), this.route);
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route);
    }

    public getForm(indicatorInterventionType: any, documentsCustomers: any) {
        const name = indicatorInterventionType ?
            this.commonService.valueToFormString(indicatorInterventionType.name) : this.commonService.getDefaultFormStringValue();
        const marketsTypes = indicatorInterventionType ?
            indicatorInterventionType.marketsTypes.map(x => ({ value: x.id, label: x.name })) : [];
        const isOrCondition = indicatorInterventionType ?
            this.commonService.valueToRadioFormString(indicatorInterventionType.isOrCondition) : this.commonService.getDefaultFormRadioValue();
        const documentsCustomersIds = indicatorInterventionType ?
            documentsCustomers.filter(x => indicatorInterventionType.documentsCustomersIds.map(x => x).includes(x.value)) : [];
        const isRequired = indicatorInterventionType ?
            this.commonService.valueToRadioFormString(indicatorInterventionType.isRequired) : this.commonService.getDefaultFormRadioValue();

        return this.formBuilder.group({
            name: [name, Validators.required],
            marketsTypes: [marketsTypes, Validators.required],
            isOrCondition: [isOrCondition],
            documentsCustomersIds: [documentsCustomersIds],
            isRequired: [isRequired]
        });
    }

    private formatForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.marketsTypes = this.formFormatterService.formatSelectMultipleToIntArray(values.marketsTypes);
        values.documentsCustomersIds = values.documentsCustomersIds.map(x => x.value);
        return values;

    }

}
