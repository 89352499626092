import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Affair, Building, BuildingType, Part, SubmitButton } from '@libs/models/src';
import { BuildingService, NotifService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-affair-show-buildings-modal-building',
  templateUrl: './affair-show-buildings-modal-building.component.html',
  styleUrls: ['./affair-show-buildings-modal-building.component.scss']
})
export class AffairShowBuildingsModalBuildingComponent implements OnInit {

  @Input() affair: Affair;
  @Input() buildingsTypes: BuildingType[];
  @Input() building: Building;
  @Input() part: Part;
  @Input() form: FormGroup;
  @Input() indicatorsInterventionsTypes: any[];
  public submitButton: SubmitButton;

  constructor(
    private buildingService: BuildingService,
    private modal: NzModalRef,
    private notifService: NotifService,
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.buildingService.setSubmitButton(this.submitButton);
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.building) {
        this.buildingService.update(this.building.id, this.form).subscribe(
          (response) => {
            this.notifService.showSuccessNotif(response);
            this.modal.close();
          }
        );
      } else {
        this.buildingService.store(this.form).subscribe(
          (response) => {
            this.notifService.showSuccessNotif(response);
            this.modal.close();
          }
        );
      }
    }
  }

  isValidForm() {
    return this.form.valid;
  }

  getFormControls() {
    return (this.form.get('housings') as FormArray).controls;
  }

  onAddHousingClicked() {
    (this.form.get('housings') as FormArray).push(this.buildingService.getHousingForm());
  }

  onDeleteHousingClicked(index: number) {
    (this.form.get('housings') as FormArray).removeAt(index);
  }

  private determinateSubmitButton() {
    this.submitButton = this.buildingService.determinateSubmitButton(this.building, 'le type de bâtiment');
  }

}
