import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { DragDropService } from '@libs/services/src';

@Component({
  selector: 'app-settings-products-products-modal-documents-delivered',
  templateUrl: './settings-products-products-modal-documents-delivered.component.html',
  styleUrls: ['./settings-products-products-modal-documents-delivered.component.scss']
})
export class SettingsProductsProductsModalDocumentsDeliveredComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() documentsDeliveredItems: any;
  public documentsDeliveredArray = [];

  constructor(
    private dragDropService: DragDropService
  ) { }

  ngOnInit() {
    this.documentsDeliveredArray = JSON.parse(JSON.stringify(this.documentsDeliveredItems));
  }

  get documentsDeliveredControls(): FormArray {
    return this.form.get("documentsDelivered") as FormArray;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      this.dragDropService.moveItemInFormArray(
        this.documentsDeliveredControls,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      const formGroupItem = this.getFormGroupItem(event.previousContainer.data, event.previousIndex);
      this.dragDropService.copyItemInFormArray(event.previousContainer.data,
        this.documentsDeliveredControls,
        event.previousIndex,
        event.currentIndex,
        formGroupItem,
        this.documentsDeliveredArray)
    }
  }

  private getFormGroupItem(
    data: any,
    fromIndex: number): FormGroup {
    const item = data[fromIndex];
    return new FormGroup({
      id: new FormControl(item.id),
      name: new FormControl(item.name),
      isNeeded: new FormControl(false)
    });
  }

  onDeleteSelectElementClicked(index: number) {
    const item = this.documentsDeliveredControls.at(index);
    this.documentsDeliveredControls.removeAt(index);
    this.documentsDeliveredArray.push(item.value);
  }

}
