import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@libs/api/src';
import { SubmitButton } from '@libs/models/src';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { FormFormatterService } from '../utilities/form-formatter.service';
import { NotifService } from '../utilities/notif.service';
import { SubmitButtonService } from '../utilities/submit-button.service';
import { SwalService } from '../utilities/swal.service';

export interface AffairDocumentListParam {
    research: string;
    selectedDocumentsTypes: any[];
    selectedProducts: any[];
    selectedVersions: any[];
    selectedBuildings?: any[];
}

export interface AffairDocumentImportParam {
    affairId: number;
    affairProductId: number;
    documentId: number
    incrementVersion: boolean;
    file: File;
    buildingId?: number
}

export interface AffairDocumentImportMultipleParam {
    affairId: number;
    affairProductId: number;
    documentId: number
    incrementVersion: boolean;
    files: File[];
    buildingId?: number
}


@Injectable()
export class AffairDocument {

    renameModal: ModalDirective;
    renameSubmitButton: SubmitButton;
    identifySubmitButton: SubmitButton;
    documents$ = new BehaviorSubject<any>(undefined);
    filters$ = new BehaviorSubject<any>(undefined);
    dropzones$ = new BehaviorSubject<any>(undefined);

    constructor(
        private notifService: NotifService,
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        private submitButtonService: SubmitButtonService,
        private swalService: SwalService,
        private formFormatterService: FormFormatterService
    ) { }

    getAll(affairId: number, form: FormGroup, route: string): Observable<any> {
        if (form) {
            return this.apiService.post('/affairs/' + affairId + route + '/list', this.formatFiltersForm(form));
        } else {
            return this.apiService.post('/affairs/' + affairId + route + '/list', []);
        }
    }

    getFilters(affairId: number, showOtherCompanies: boolean, route: string): Observable<any> {
        return this.apiService.get('/affairs/' + affairId + route + '/filters/others-companies/' + showOtherCompanies);
    }

    getRenameForm(document: any): FormGroup {
        return this.formBuilder.group({
            path: [document.path, Validators.required],
            filename: [document.filename, Validators.required]
        });
    }

    getDropzones(affairId: number, route: string): void {
        this.apiService.get('/affairs/' + affairId + route + '/dropzones')
            .subscribe(
                (object) => {
                    this.dropzones$.next(object);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    rename(affairId: number, form: FormGroup, route: string): Observable<any> {
        this.submitButtonService.setDisabled(this.renameSubmitButton);
        return this.apiService.post(`/affairs/${affairId}${route}/rename`, form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.renameSubmitButton);
                })
            );
    }

    public identify(affairId: number, form: FormGroup, route: string): Observable<any> {
        this.submitButtonService.setDisabled(this.identifySubmitButton);
        return this.apiService.post(`/affairs/${affairId}${route}/identify`, this.formatIdentifyForm(form))
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.identifySubmitButton);
                })
            )
    }

    public markAsNotPcVisible(path: string): Observable<any> {
        return this.apiService.get(`/affairs/documents-delivered/${path}/mark-as-not-pc-visible`);
    }

    public markAsPcVisible(path: string): Observable<any> {
        return this.apiService.get(`/affairs/documents-delivered/${path}/mark-as-pc-visible`);
    }

    import(affairDocumentImportParam: AffairDocumentImportParam, route: string): Observable<any> {
        const formData = new FormData();
        formData.append("affairProductId", affairDocumentImportParam.affairProductId.toString());
        formData.append("documentId", affairDocumentImportParam.documentId.toString());
        formData.append("incrementVersion", affairDocumentImportParam.incrementVersion.toString());
        if (affairDocumentImportParam.buildingId) {
            formData.append("buildingId", affairDocumentImportParam.buildingId.toString());
        }
        formData.append("file", affairDocumentImportParam.file);

        return this.apiService.importFile(`/affairs/${affairDocumentImportParam.affairId}${route}/import`, formData);
    }

    importMultiple(affairDocumentImportParam: AffairDocumentImportMultipleParam, route: string): Observable<any> {
        const formData = new FormData();
        formData.append("affairProductId", affairDocumentImportParam.affairProductId.toString());
        formData.append("documentId", affairDocumentImportParam.documentId.toString());
        formData.append("incrementVersion", affairDocumentImportParam.incrementVersion.toString());
        if (affairDocumentImportParam.buildingId) {
            formData.append("buildingId", affairDocumentImportParam.buildingId.toString());
        }
        for (let i = 0; i < affairDocumentImportParam.files.length; i++) {
            formData.append("files[]", affairDocumentImportParam.files[i]);
        }

        return this.apiService.importFile(`/affairs/${affairDocumentImportParam.affairId}${route}/import-multiple`, formData);
    }

    delete(affairId: number, paths: string[], route: string): Observable<any> {
        return this.apiService.deleteWithBody(`/affairs/${affairId}${route}/files/delete`, {
            'selectedPaths': paths
        })
        .pipe(
            catchError(error => {
                this.swalService.showSwalError(error);
                return throwError(error);
            })
        );
    }

    getForExportList(form: FormGroup, route:string): Observable<any> {
        return this.apiService.post(`/affairs${route}/export/list`, this.formatExportForm(form))
    }

    
    downloadMultiple(documentsIds: number[], route:string): Observable<HttpResponse<Blob>> {
        return this.apiService.postBlob(`/affairs${route}/download/multiple`, {
            'ids' : documentsIds
        })
    }

    setSubmitButton(button: SubmitButton): void {
        this.renameSubmitButton = button;
    }

    setIdentifySubmitButton(button: SubmitButton): void {
        this.identifySubmitButton = button;
    }


    setRenameModal(modal: ModalDirective): void {
        this.renameModal = modal;
    }

    showRenameModal(): void {
        this.renameModal.show();
    }

    hideRenameModal(): void {
        this.renameModal.hide();
    }

    private formatIdentifyForm(form) {
        const values = this.formFormatterService.createFormCopy(form);
        values.documents.forEach((value) => {
            value.affairProductId = this.formFormatterService.formatSelectSingle(value.affairProductId);
            value.documentId = this.formFormatterService.formatSelectSingle(value.documentId);
        });
        return values;
    }

     private formatFiltersForm(form) {
        if (form !== undefined && form !== null) {
            const values = this.formFormatterService.createFormCopy(form);
            values.selectedProducts = values && values.selectedProducts ? this.formFormatterService.formatSelectMultiple(values.selectedProducts) : null;
            values.selectedBuildings = values && values.selectedBuildings ? this.formFormatterService.formatSelectMultiple(values.selectedBuildings) : null;
            values.selectedVersions = values && values.selectedVersions ? this.formFormatterService.formatSelectMultiple(values.selectedVersions) : null;
            return values;
        }
        return null;
    }

    private formatExportForm(form) {
        const values = this.formFormatterService.createFormCopy(form);
        values.selectedAgencies = this.formFormatterService.formatSelectMultiple(values.selectedAgencies);
        values.selectedTechnicians = this.formFormatterService.formatSelectMultiple(values.selectedTechnicians);
        values.selectedDocumentsDelivered = this.formFormatterService.formatSelectMultiple(values.selectedDocumentsDelivered);
        return values;
    }


}
