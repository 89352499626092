import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-intervention-indicators-types',
  templateUrl: './intervention-indicators-types.component.html',
  styleUrls: ['./intervention-indicators-types.component.scss']
})
export class InterventionIndicatorsTypesComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() indicatorsInterventionsTypes: any[];

  constructor() { }

  ngOnInit() {
  }
}
