import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormFormatterService } from './utilities/form-formatter.service';
import { DataTableColumn, ModelWithDatatable } from './classes/model-datatable';
import { CommonService } from './utilities/common.service';
import { NgxSpinnerService } from 'ngx-spinner';

export interface AffairDatatableParameters {
    selectedMlAgencies: string;
    selectedAgencies: string;
    selectedAssistants: string;
    selectedTechnicians: string;
    selectedAffairsGoals: string;
    selectedPerformances: string;
    selectedProducts: string;
    selectedProductsStatuses: string;
    selectedAffairsMonitoringTypes: string;
    affairMonitoringTypeLevel: number;
    isAfterIntervention: boolean;
    dateCreationMin: Date;
    dateCreationMax: Date;
    dateProductReceiptMin: Date;
    dateProductReceiptMax: Date;
    dateProductRealisationMin: Date;
    dateProductRealisationMax: Date;
}

@Injectable()
export class AffairCollectiveService extends ModelWithDatatable {

    private route = '/affairs/collective';
    public affairDatatableParams: AffairDatatableParameters;

    constructor(
        http: HttpClient,
        router: Router,
        apiService: ApiService,
        spinner: NgxSpinnerService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService
    ) {
        super(
            http,
            router,
            apiService,
            spinner
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[], form?: any) {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns,
            openInNewTabUrl: 'affairs/$id/show'
        }, this.formToDatatableParameters(form));
    }

    private formToDatatableParameters(form: FormGroup): AffairDatatableParameters {
        if (form) {
            return {
                selectedMlAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedMlAgencies),
                selectedAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedAgencies),
                selectedAssistants: this.formFormatterService.formatSelectMultiple(form.value.selectedAssistants),
                selectedTechnicians: this.formFormatterService.formatSelectMultiple(form.value.selectedTechnicians),
                selectedAffairsGoals: this.formFormatterService.formatSelectMultiple(form.value.selectedAffairsGoals),
                selectedPerformances: this.formFormatterService.formatSelectMultiple(form.value.selectedPerformances),
                selectedProducts: this.formFormatterService.formatSelectMultiple(form.value.selectedProducts),
                selectedProductsStatuses: this.formFormatterService.formatSelectMultiple(form.value.selectedProductsStatuses),
                selectedAffairsMonitoringTypes: this.formFormatterService.formatSelectMultiple(form.value.selectedAffairsMonitoringTypes),
                affairMonitoringTypeLevel: form.value.affairMonitoringTypeLevel,
                isAfterIntervention: form.value.isAfterIntervention,
                dateCreationMin: this.formFormatterService.formatDate(form.value.dateCreationMin),
                dateCreationMax: this.formFormatterService.formatDate(form.value.dateCreationMax),
                dateProductReceiptMin: this.formFormatterService.formatDate(form.value.dateProductReceiptMin),
                dateProductReceiptMax: this.formFormatterService.formatDate(form.value.dateProductReceiptMax),
                dateProductRealisationMin: this.formFormatterService.formatDate(form.value.dateProductRealisationMin),
                dateProductRealisationMax: this.formFormatterService.formatDate(form.value.dateProductRealisationMax),
            };
        }
        return null;
    }

    public redrawDataTable(form: any): void {
        const affairDatatableParams: AffairDatatableParameters = this.formToDatatableParameters(form);
        super.redrawDataTable(affairDatatableParams);
    }

    public getFiltersForm(formValue?: any): FormGroup {
        const selectedAssistants = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAssistants) : this.commonService.getDefaultFormNullValue();
        const selectedTechnicians = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedTechnicians) : this.commonService.getDefaultFormNullValue();
        const selectedAgencies = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAgencies) : this.commonService.getDefaultFormNullValue();
        const selectedMlAgencies = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedMlAgencies) : this.commonService.getDefaultFormNullValue();
        const selectedAffairsGoals = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAffairsGoals) : this.commonService.getDefaultFormNullValue();
        const selectedPerformances = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedPerformances) : this.commonService.getDefaultFormNullValue();
        const selectedAffairsStatuses = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAffairsStatuses) : this.commonService.getDefaultFormNullValue();
        const selectedProductsStatuses = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedProductsStatuses) : this.commonService.getDefaultFormNullValue();
        const selectedAffairsMonitoringTypes = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAffairsMonitoringTypes) : this.commonService.getDefaultFormNullValue();
        const affairMonitoringTypeLevel = formValue ?
            this.commonService.valueToFormNumber(formValue.affairMonitoringTypeLevel) : 1;
        const isAfterIntervention = formValue ?
            formValue.isAfterIntervention : null;
        const selectedProducts = formValue ?
            this.commonService.valueToFormSelect(formValue.products) : this.commonService.getDefaultFormNullValue();
        const dateCreationMin = formValue ?
            this.commonService.valueToFormDate(formValue.dateCreationMin) : this.commonService.getDefaultFormNullValue();
        const dateCreationMax = formValue ?
            this.commonService.valueToFormDate(formValue.dateCreationMax) : this.commonService.getDefaultFormNullValue();
        const dateProductReceiptMin = formValue ?
            this.commonService.valueToFormDate(formValue.dateProductReceiptMin) : this.commonService.getDefaultFormNullValue();
        const dateProductReceiptMax = formValue ?
            this.commonService.valueToFormDate(formValue.dateProductReceiptMax) : this.commonService.getDefaultFormNullValue();
        const dateProductRealisationMin = formValue ?
            this.commonService.valueToFormDate(formValue.dateProductRealisationMin) : this.commonService.getDefaultFormNullValue();
        const dateProductRealisationMax = formValue ?
            this.commonService.valueToFormDate(formValue.dateProductRealisationMax) : this.commonService.getDefaultFormNullValue();
        return this.formBuilder.group({
            selectedAssistants: [selectedAssistants],
            selectedTechnicians: [selectedTechnicians],
            selectedAgencies: [selectedAgencies],
            selectedAffairsGoals: [selectedAffairsGoals],
            selectedAffairsStatuses: [selectedAffairsStatuses],
            selectedMlAgencies: [selectedMlAgencies],
            selectedProductsStatuses: [selectedProductsStatuses],
            selectedAffairsMonitoringTypes: [selectedAffairsMonitoringTypes],
            selectedPerformances: [selectedPerformances],
            affairMonitoringTypeLevel: [affairMonitoringTypeLevel],
            isAfterIntervention: [isAfterIntervention],
            selectedProducts: [selectedProducts],
            dateCreationMin: [dateCreationMin],
            dateCreationMax: [dateCreationMax],
            dateProductReceiptMin: [dateProductReceiptMin],
            dateProductReceiptMax: [dateProductReceiptMax],
            dateProductRealisationMin: [dateProductRealisationMin],
            dateProductRealisationMax: [dateProductRealisationMax]
        });
    }
}
