import Swal from 'sweetalert2';
import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormFormatterService } from './utilities/form-formatter.service';
import { DataTableColumn, ModelWithDatatable } from './classes/model-datatable';
import { CommonService } from './utilities/common.service';
import { NotifService } from './utilities/notif.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AffairMyLinkService extends ModelWithDatatable {

    private route = '/affairs/my-link';
    public affairsSearch$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService,
        private notifService: NotifService
    ) {
        super(
            http,
            router,
            apiService,
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public redrawDataTable(form: FormGroup): void {
        super.redrawDataTable({
            selectedAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedAgencies),
            selectedMarketsTypes: this.formFormatterService.formatSelectMultiple(form.value.selectedMarketsTypes),
        });
    }

    public getFiltersForm(formValue?: any): FormGroup {
        const selectedAgencies = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAgencies) : this.commonService.getDefaultFormNullValue();
        const selectedMarketsTypes = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedMarketsTypes) : this.commonService.getDefaultFormNullValue();
        return this.formBuilder.group({
            selectedAgencies: selectedAgencies,
            selectedMarketsTypes: [selectedMarketsTypes],
        });
    }

    public import(mlRef: string): void {
        this.apiService.get(`${this.route}/${mlRef}/import`)
            .subscribe(
                (response: any) => {
                    Swal.fire({
                        title: 'Succès !',
                        text: response.data.message,
                        icon: 'success'
                    }).then((result) => {
                        if (result.isConfirmed || result.isDismissed) {
                            this.router.navigate([`/affairs/${response.data.id}/show`]);
                        }
                    });
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public edit(id: number): void {
        this.router.navigate([`/affairs/${id}/show`]);
    }

    public archive(mlRef: string, form?: FormGroup): void {
        this.apiService.get(`${this.route}/${mlRef}/archive`)
            .subscribe(
                (success) => {
                    this.redrawDataTable(form);
                    this.notifService.showSuccessNotif(success);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public search(form: FormGroup) {
        this.apiService.post(`${this.route}/search`, {
            'finalCustomerLastname': form.value.finalCustomerLastname,
            'workSiteAddress': form.value.workSiteAddress,
            'workSitePostalCode': form.value.workSitePostalCode,
            'workSiteLocality': form.value.workSiteLocality,
        })
            .subscribe(
                (results) => {
                    this.affairsSearch$.next(results);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }
}
