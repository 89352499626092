import { catchError } from 'rxjs/operators';
import { ModelWithDatatableAndCrud } from './classes/model-datatable-crud';
import { User } from '@eros-front/models';
import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { DataTableColumn } from './classes/model-datatable';
import { CommonService } from './utilities/common.service';

@Injectable()
export class UserService extends ModelWithDatatableAndCrud {

    private route = '/users';
    private redirectUrl = '/admin/users/index';
    public user$ = new BehaviorSubject<any>(undefined);
    public users$ = new BehaviorSubject<any>(undefined);
    public selectTechnicians$ = new BehaviorSubject<any>(undefined);
    public usersSelect$ = new BehaviorSubject<any>(undefined);
    public usersTechnicians$ = new BehaviorSubject<any>(undefined);
    public usersAssistants$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): void {
        super.get(id, this.user$, this.route);
    }

    public getAll(): void {
        super.getAll(this.users$, this.route);
    }

    public store(form: any): void {
        super.store(form.value, this.route, this.redirectUrl);
    }

    public update(id: number, form: any): void {
        super.update(id, form.value, this.route, this.redirectUrl);
    }

    public delete(id: number): void {
        super.delete(id, this.route);
    }

    public deactivate(id: number): void {
        this.apiService.get(this.route + '/deactivate/' + id)
            .pipe(
                catchError(error => {
                    this.notifService.showErrorNotif(error);
                    return throwError(error);
                }),
            )
            .subscribe(
                (success) => {
                    this.notifService.showSuccessNotif(success);
                }
            );
    }

    public logAs(id: number): Observable<any> {
        return this.apiService.get(this.route + '/log-as/' + id)
            .pipe(
                catchError(error => {
                    this.notifService.showErrorNotif(error);
                    return throwError(error);
                }),
            );
    }

    public getCreateForm(): FormGroup {
        let lastname = '';
        let firstname = '';
        let email = '';
        let color = '#000000';
        let phone = '';
        let initials = '';
        let password = '';
        let selectedMlAgencies = [];
        return this.formBuilder.group({
            initials: [initials],
            lastname: [lastname, Validators.required],
            firstname: [firstname, Validators.required],
            email: [email, Validators.required],
            color: color,
            phone: phone,
            password: [password, Validators.required],
            roles: new FormArray([]),
            selectedMlAgencies: selectedMlAgencies,
        });
    }

    public getEditForm(user: User): FormGroup {
        return this.formBuilder.group({
            initials: [user.initials],
            lastname: [user.lastname, Validators.required],
            firstname: [user.firstname, Validators.required],
            email: [user.email, Validators.required],
            sendAsEmail: [user.sendAsEmail],
            personalNumber: [user.personalNumber],
            color: user.color,
            phone: user.phone,
            roles: new FormArray([]),
            selectedMlAgencies: [],
        });
    }

    public reset(): void {
        this.user$.next(null);
    }


    getAllTechnicians() {
        this.apiService.get('/users/technician')
            .pipe(
                catchError(err => {
                    return throwError(err);
                })
            )
            .subscribe(
                (response) => {
                    this.usersTechnicians$.next(response);
                }
            );
    }


    getAllAssistants() {
        this.apiService.get('/users/assistant')
            .pipe(
                catchError(err => {
                    return throwError(err);
                })
            )
            .subscribe(
                (response) => {
                    this.usersAssistants$.next(response);
                }
            );
    }

    getTechniciansSelect() {
        this.apiService.get('/users/technician/select')
            .pipe(
                catchError(err => {
                    return throwError(err);
                })
            )
            .subscribe(
                (response) => {
                    this.selectTechnicians$.next(response);
                }
            );
    }

    getUsersSelect() {
        this.apiService.get('/users/select')
            .pipe(
                catchError(err => {
                    return throwError(err);
                })
            )
            .subscribe(
                (response) => {
                    this.usersSelect$.next(response);
                }
            );
    }

    updateMarketsTypes(marketsTypesIds: any) {
        this.apiService.put(`${this.route}/update-markets-types`, {
            marketsTypesIds: marketsTypesIds
        })
            .pipe(
                catchError(err => {
                    return throwError(err);
                })
            )
            .subscribe(
                (response) => {
                    this.usersSelect$.next(response);
                }
            );
    }

    public valueToFormSelectMultiple(values: any[]): any {
        if (values != null) {
            return values.map(x => ({ label: [x.lastname, x.firstname].filter(Boolean).join(" "), value: x.id }));
        }
        return null;
    }

    public formatToSelectMultiple(users: any) {
        let usersSelect = [];
        users.forEach((user: User) => {
            usersSelect.push({
                label: user.fullName,
                value: user.id
            })

        })
        return usersSelect;
    }

}
