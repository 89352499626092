import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { ModelWithDatatableAndCrud } from './classes/model-datatable-crud';
import { DataTableColumn } from './classes/model-datatable';
import { FormFormatterService } from './utilities/form-formatter.service';
import { CommonService } from './utilities/common.service';
import { catchError, finalize } from 'rxjs/operators';
import { Quotation } from '@libs/models/src';
import { FormValidatorsService } from './utilities/form-validators.service';

export interface QuotationDatatableParameters {
    selectedAgencies: string;
    selectedMlAgencies: string;
    selectedProducts: string;
    selectedQuotationsStatuses: string;
}

@Injectable()
export class QuotationService extends ModelWithDatatableAndCrud {

    private route = '/quotations';
    public quotation$ = new BehaviorSubject<any>(undefined);
    public filters$ = new BehaviorSubject<any>(undefined);
    public quotationsThumbnails$ = new BehaviorSubject<any>(undefined);
    public payerForProduct$ = new BehaviorSubject<any>(undefined);
    public invoicesTempoByProduct$ = new BehaviorSubject<any>(undefined);
    public editUrl = 'quotations/$id/show';

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService,
        private formValidatorService: FormValidatorsService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[], form?: FormGroup): void {
        super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns,
            pageLength: 25,
            redirectUrl: this.editUrl
        }, this.formToDatatableParameters(form));
    }

    public redrawDataTable(form: FormGroup): void {
        super.redrawDataTable(this.formToDatatableParameters(form));
    }

    public getDataTableFilters(): void {
        this.apiService.get(`${this.route}/list/selects`)
            .subscribe(
                (object) => {
                    this.filters$.next(object);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public getFiltersForm(formValue?: any): FormGroup {
        const selectedAgencies = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAgencies) : this.commonService.getDefaultFormNullValue();
        const selectedMlAgencies = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedMlAgencies) : this.commonService.getDefaultFormNullValue();
        const selectedProducts = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedProducts) : this.commonService.getDefaultFormNullValue();
        const selectedQuotationsStatuses = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedQuotationsStatuses) : this.commonService.getDefaultFormNullValue();
        return this.formBuilder.group({
            selectedAgencies: [selectedAgencies],
            selectedMlAgencies: [selectedMlAgencies],
            selectedProducts: [selectedProducts],
            selectedQuotationsStatuses: [selectedQuotationsStatuses],
        });
    }

    public getUpdateForm(quotation: Quotation): FormGroup {
        const marketTypeId = quotation && quotation.marketTypeId ?
            this.commonService.valueToFormNumber(quotation.marketTypeId) : this.commonService.getDefaultFormNullValue();
        if (quotation) {
            return this.formBuilder.group({
                mlAgencyId: [this.commonService.valueToFormSelect(quotation.mlAgencyId), Validators.required],
                quotationStatusId: [this.commonService.valueToFormSelect(quotation.quotationStatusId), Validators.required],
                agencyId: this.commonService.valueToFormSelect(quotation.agencyId),
                payerId: this.commonService.valueToFormSelect(quotation.payerId),
                payerName: [this.commonService.valueToFormString(quotation.payerName), Validators.required],
                payerAddress: [this.commonService.valueToFormString(quotation.payerAddress), Validators.required],
                payerAdditionalAddress: this.commonService.valueToFormString(quotation.payerAdditionalAddress),
                payerPostalCode: [this.commonService.valueToFormString(quotation.payerPostalCode), Validators.required],
                payerLocality: [this.commonService.valueToFormString(quotation.payerLocality), Validators.required],
                comment: this.commonService.valueToFormString(quotation.comment),
                marketTypeId: [marketTypeId],
                affairLastname: [this.commonService.valueToFormString(quotation.affairLastname)],
                affairFirstname: [this.commonService.valueToFormString(quotation.affairFirstname)],
                affairAddress: [this.commonService.valueToFormString(quotation.affairAddress)],
                affairPostalCode: [this.commonService.valueToFormString(quotation.affairPostalCode)],
                affairLocality: [this.commonService.valueToFormString(quotation.affairLocality)],
                affairInternalNote: [this.commonService.valueToFormString(quotation.affairInternalNote)]
            });
        }
        return null;
    }

    public get(id: number): void {
        super.get(id, this.quotation$, this.route);
    }

    public store(form: FormGroup): void {
        this.submitButtonService.setDisabled(this.submitButton);
        this.apiService.post(this.route, this.formatForm(form, true))
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
            .subscribe(
                (response: any) => {
                    this.router.navigateByUrl('/quotations/' + response.data.id + '/show');
                    this.notifService.showSuccessNotif(response);
                }
            );
    }

    public storeForAffair(form: FormGroup, affairId: number): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.post(`${this.route}/affair/${affairId}`, form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            );
    }

    public update(id: number, form: FormGroup): void {
        super.update(id, this.formatForm(form), this.route);
    }

    public delete(id: number): void {
        this.apiService.delete(this.route + '/' + id)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            )
            .subscribe(
                (success) => {
                    this.router.navigateByUrl(this.route + '/index');
                }
            );
    }

    private formToDatatableParameters(form: FormGroup): QuotationDatatableParameters {
        if (form) {
            return {
                selectedAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedAgencies),
                selectedMlAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedMlAgencies),
                selectedProducts: this.formFormatterService.formatSelectMultiple(form.value.selectedProducts),
                selectedQuotationsStatuses: this.formFormatterService.formatSelectMultiple(form.value.selectedQuotationsStatuses),
            };
        }
        return null;
    }


    public getCreateFormAffair(affair: any): FormGroup {
        return this.formBuilder.group({
            affairProducts: new FormArray([], this.formValidatorService.minSelectedCheckboxes(1)),
            comment: null
        });
    }

    public stream(id: Number): Observable<Blob> {
        return this.apiService.getFile(`${this.route}/${id}/stream`);
    }

    public download(id: Number): Observable<HttpResponse<Blob>> {
        return this.apiService.postBlob(`${this.route}/${id}/download`, []);
    }

    public thumbnails(affairId: number) {
        this.apiService.get(`${this.route}/affair/${affairId}/thumbnails`)
            .subscribe(
                (object) => {
                    this.quotationsThumbnails$.next(object);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }


    public generateReport(id: number): Observable<any> {
        return this.apiService.get(`${this.route}/${id}/generate-report`);
    }

    private formatForm(form: FormGroup, isCreate?: boolean): void {
        const values = this.formFormatterService.createFormCopy(form);
        values.mlAgencyId = this.formFormatterService.formatSelectSingle(values.mlAgencyId);
        values.agencyId = this.formFormatterService.formatSelectSingle(values.agencyId);
        values.payerId = this.formFormatterService.formatSelectSingle(values.payerId);
        values.payerPreference = this.formFormatterService.formatSelectSingle(values.payerPreference);
        if (isCreate) {
            values.quotationDetails.forEach((value) => {
                value.vatRate = this.formFormatterService.formatSelectSingle(value.vatRate);
            });
        }
        return values;
    }

    public reset(): void {
        this.quotation$.next(null);
    }

}
