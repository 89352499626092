import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TelephonyTool } from '_tools/telephony';

@Component({
  selector: 'app-contacts-call-contact-template',
  templateUrl: './contacts-call-contact-template.component.html',
  styleUrls: ['./contacts-call-contact-template.component.scss']
})
export class ContactsCallContactTemplateComponent implements OnInit {
  @Input() lastname: string;
  @Input() firstname: string;
  @Input() email: string;
  @Input() phone: string;
  @Input() profession: any;

  @Output() onCall = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onCallClicked() {
    this.onCall.emit();
  }
}
