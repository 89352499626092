import { Component, Input, OnChanges, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { SubmitButton } from '@libs/models/src';
import { AgencyService, BrandService, CustomerService, GroupService, SubmitButtonService } from '@libs/services/src';

@Component({
  selector: 'app-customers-show-prices',
  templateUrl: './customers-show-prices.component.html',
  styleUrls: ['./customers-show-prices.component.scss']
})
export class CustomersShowPricesComponent implements OnInit, OnChanges {

  @Input() customer: any;
  @Input() isGroup: boolean;
  @Input() isBrand: boolean;
  @Input() isAgency: boolean;
  @Input() products: any[];
  @Input() marketsTypes: any[];
  @Input() pricesForm: FormGroup;
  @Output() saveClicked = new EventEmitter();
  public displayedProducts = [];
  public submitButton: SubmitButton;
  public isEnabled: boolean[] = [];
  public buttonBackgroundColor = [];
  public inputColor = [];

  constructor(
    private submitButtonService: SubmitButtonService,
    private customerService: CustomerService,
    private groupService: GroupService,
    private brandService: BrandService,
    private agencyService: AgencyService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.initDisplayedProducts();
    this.customerService.initPricesInputs(this.pricesForm, this.marketsTypes, this.products, this.customer);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['customer'] && changes['customer'].previousValue != changes['customer'].currentValue) {
      this.initDisplayedProducts();
      this.customerService.initPricesInputs(this.pricesForm, this.marketsTypes, this.products, this.customer);
    }
  }

  initDisplayedProducts() {
    if (this.products.length > 0) {
      if (this.displayedProducts.length === 0) {
        for (const p of this.products) {
          this.displayedProducts.push({
            id: p.id,
            name: p.name,
            visible: true,
            marketsTypes: p.marketsTypes
          });
        }
      }
      for (var i = 0; i < this.products.length; i++) {
        this.isEnabled[i] = true;
        this.inputColor[i] = 'black';
        this.buttonBackgroundColor[i] = 'transparent';
      }
    }
  }

  isVisibleForMarket(product: any, marketTypeId: number): boolean {
    return product.marketsTypes.map(x => x.id).includes(marketTypeId);
  }

  onSelectedFilteredProductChange(item: any) {
    this.displayedProducts.every((p) => {
      p.visible = !(item != null);
      return p;
    });
    if (item != null) {
      this.displayedProducts.find(p => p.id === item.id).visible = true;
    }
  }

  getPriceOfParent(marketTypeId: number, productId: number): number {
    let price = 0
    if (this.isBrand) {
      if (this.customer) {
        let found = this.customer.group.products.find((x) => x.marketTypeId == marketTypeId && x.productId == productId);
        if (found) {
          price = found.price;
        }
      }
    } else if (this.isAgency) {
      let found = this.customer.brand.products.find((x) => x.marketTypeId == marketTypeId && x.productId == productId);
      if (found) {
        price = found.price;
      }
    }
    return price;
  }

  getPriceOfChild(marketTypeId: number, productId: number): number {
    let price = 0;
    let found = this.customer.products.find((x) => x.marketTypeId == marketTypeId && x.productId == productId);
    if (found) {
      price = found.price;
    }
    return price;
  }

  isPriceOfParentDifferent(marketTypeId: number, productId: number) {
    return this.getPriceOfParent(marketTypeId, productId) != this.getPriceOfChild(marketTypeId, productId);
  }

  isValidForm() {
    return this.pricesForm && this.pricesForm.valid;
  }

  onSaveClicked() {
    this.saveClicked.emit();
  }


  onApplyPriceOfParentClicked(marketTypeId: number, productId: number, index: number, productIndex: number) {
    this.setPriceOfParent(marketTypeId, productId, index);
    this.setInputColor(this.isEnabled[productIndex], productIndex);
    this.setButtonBackgroundColor(productIndex);
    this.isEnabled[productIndex] = !this.isEnabled[productIndex];
  }

  onPreviewPriceOfParentMouseOver(marketTypeId: number, productId: number, index: number, productIndex: number) {
    if (this.isEnabled[productIndex]) {
      this.setInputColor(true, productIndex);
      this.setPriceOfParent(marketTypeId, productId, index);
    }
  }

  onPreviewPriceOfParentMouseLeave(marketTypeId: number, productId: number, index: number, productIndex: number) {
    if (this.isEnabled[productIndex]) {
      this.setInputColor(false, productIndex);
      this.setPriceOfChild(marketTypeId, productId, index);
    }
  }

  private setPriceOfParent(marketTypeId: number, productId: number, index: number): void {
    let price = this.getPriceOfParent(marketTypeId, productId);

    const pricesFormArray = this.pricesForm.get('prices') as FormArray;
    const products = pricesFormArray.controls[index].get('products') as FormArray;
    let values = products.value;
    values.forEach(product => {
      if (product.productId == productId) {
        product.price = price
      }
    });
    products.patchValue(values);
  }

  private setPriceOfChild(marketTypeId: number, productId: number, index: number): void {
    let price = this.getPriceOfChild(marketTypeId, productId);

    const pricesFormArray = this.pricesForm.get('prices') as FormArray;
    const products = pricesFormArray.controls[index].get('products') as FormArray;
    let values = products.value;
    values.forEach(product => {
      if (product.productId == productId) {
        product.price = price
      }
    });
    products.patchValue(values);
  }

  private setInputColor(condition: boolean, index: number) {
    this.inputColor[index] = condition ? 'red' : 'black';
  }

  private setButtonBackgroundColor(index: number) {
    this.buttonBackgroundColor[index] = this.isEnabled[index] ? 'darkgray' : 'transparent';
  }

  private determinateSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance(
      {
        objectName: '',
        text: 'Enregistrer les changements',
        icon: faSave
      }
    );
    if (this.isGroup) {
      this.groupService.setSubmitPricesButton(this.submitButton);
    } else if (this.isBrand) {
      this.brandService.setSubmitPricesButton(this.submitButton);
    } else if (this.isAgency) {
      this.agencyService.setSubmitPricesButton(this.submitButton);
    }
  }
}
