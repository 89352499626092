import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { ModelWithDatatable } from './model-datatable';
import { ModelWithModalCrud } from './model-modal-crud';
import { SubmitButtonService } from './../utilities/submit-button.service';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NotifService } from './../utilities/notif.service';
import { SwalService } from './../utilities/swal.service';

function applyMixins(derivedConstructor: any, baseConstructors: any[]) {
    baseConstructors.forEach(baseConstructor => {
      Object.getOwnPropertyNames(baseConstructor.prototype)
      .forEach(name => {
        Object.defineProperty(derivedConstructor.prototype,
           name,
           Object.
             getOwnPropertyDescriptor(
               baseConstructor.prototype,
               name
             )
           );
        });
    });
}

export interface ModelWithDatatableAndModalCrud extends ModelWithDatatable, ModelWithModalCrud {
}

@Injectable()
export class ModelWithDatatableAndModalCrud {

    http: HttpClient;
    notifService: NotifService;
    submitButtonService: SubmitButtonService;
    swalService: SwalService;
    router: Router;
    apiService: ApiService;
  

    constructor(
      http: HttpClient,
      notifService: NotifService,
      submitButtonService: SubmitButtonService,
      swalService: SwalService,
      apiService: ApiService,
      router?: Router,
    ) {
        this.http = http;
        this.notifService = notifService;
        this.submitButtonService = submitButtonService;
        this.swalService = swalService;
        this.apiService = apiService;
        this.router = router;
    }
}

applyMixins(ModelWithDatatableAndModalCrud, [ModelWithDatatable, ModelWithModalCrud]);
