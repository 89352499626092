export const CHARTS_NAMES = {
    VOLUME_MAP: 'volume-map',
    VOLUME_REGIONS: 'volume-regions',
    VOLUME_RECEIVED: 'volume-received',
    VOLUME_COMPLETED: 'volume-completed',
    VOLUME_AGENCIES: 'volume-agencies',
    VOLUME_TECHNICIANS: 'volume-technicians',
    VOLUME_PRODUCTS: 'volume-products',
    REVENUE_GENERAL: 'revenue-general',
    REVENUE_REGIONS: 'revenue-regions',
    REVENUE_TECHNICIANS: 'revenue-technicians',
    REVENUE_BALANCE_SHEET: 'revenue-balance-sheet',
    REVENUE_PRODUCTS: 'revenue-products',
    REVENUE_MAP: 'revenue-map',
};
