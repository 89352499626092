import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AffairStatus } from '@libs/models/src';
import { DocumentCustomerService, DocumentDeliveredService, MarketTypeService, SwalService } from '@libs/services/src';
import { AffairStatusService } from '@libs/services/src/lib/affair-status.service';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AffairsStatusesModalComponent } from '../affairs-statuses-modal/affairs-statuses-modal.component';
import { AffairsStatusesSettingsModalComponent } from '../affairs-statuses-settings-modal/affairs-statuses-settings-modal.component';

@Component({
  selector: 'app-affairs-statuses-index',
  templateUrl: './affairs-statuses-index.component.html',
  styleUrls: ['./affairs-statuses-index.component.scss']
})
export class AffairsStatusesIndexComponent implements OnInit, AfterViewInit {
  @Input() appKey;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'marketType',
      name: 'Marché',
      searchable: false
    },
    {
      attr: 'color',
      name: 'Couleur'
    },
    {
      attr: 'documentsCustomers',
      name: 'Données d\'entrées',
      orderable: false,
      searchable: false
    },
    {
      attr: 'documentsDelivered',
      name: 'Données de sorties',
      orderable: false,
      searchable: false
    },
    {
      attr: 'step',
      name: 'Etape'
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];
  public datatableSelector = 'affairs-statuses-datatable';
  public form: FormGroup;
  public settingForm: FormGroup;
  public affairStatus: AffairStatus;
  public affairStatus$: Subscription;
  public marketsTypes: any;
  public marketsTypes$: Subscription;
  public documentsDeliveredSelect$: Subscription;
  public documentsDelivered: any;
  public documentsCustomersSelect$: Subscription;
  public documentsCustomers: any;

  constructor(
    private affairStatusService: AffairStatusService,
    private marketTypeService: MarketTypeService,
    private documentDeliveredService: DocumentDeliveredService,
    private documentCustomerService: DocumentCustomerService,
    private modalService: NzModalService,
    private swalService: SwalService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.marketTypeService.getAll();
    if (this.appKey != 'ml') {
      this.documentDeliveredService.getForSelect();
      this.documentCustomerService.getForSelect();
    }
  }

  ngAfterViewInit() {
    this.affairStatusService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  onShowModalAddEditModal() {
    this.form = this.affairStatusService.getForm(this.affairStatus);
    const modalInstance = this.modalService.create({
      nzContent: AffairsStatusesModalComponent,
      nzComponentParams: {
        affairStatus: this.affairStatus,
        marketsTypes: this.marketsTypes,
        form: this.form
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.affairStatus = null;
    })
  }

  onShowModalSetting() {
    this.settingForm = this.affairStatusService.getSettingForm(this.affairStatus, this.documentsDelivered, this.documentsCustomers);
    const modalInstance = this.modalService.create({
      nzContent: AffairsStatusesSettingsModalComponent,
      nzComponentParams: {
        affairStatus: this.affairStatus,
        documentsDelivered: this.documentsDelivered,
        documentsCustomers: this.documentsCustomers,
        form: this.settingForm
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.affairStatus = null;
    })

  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.affairStatus$ = that.affairStatusService.get(id).subscribe(
          (affairStatus) => {
            if (affairStatus) {
              that.affairStatus = affairStatus;
              that.onShowModalAddEditModal();
            }
          }
        );
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le statut sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.affairStatusService.delete(id).subscribe(
              (success) => {
                this.swalService.showSwalSuccess(success);
              }
            );
          }
        });
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-setting', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.affairStatus$ = that.affairStatusService.get(id).subscribe(
          (affairStatus) => {
            if (affairStatus) {
              that.affairStatus = affairStatus;
              that.onShowModalSetting();
            }
          }
        );
      }
    });
  }

  private initSubscriptions() {
    this.marketsTypes$ = this.marketTypeService.getAll().subscribe(
      (data) => {
        this.marketsTypes = data;
      }
    );
    this.documentsDeliveredSelect$ = this.documentDeliveredService.documentsDeliveredSelect$.subscribe(
      (documentsDelivered) => {
        if (documentsDelivered) {
          this.documentsDelivered = documentsDelivered;
        }
      }
    );
    this.documentsCustomersSelect$ = this.documentCustomerService.documentsCustomersSelect$.subscribe(
      (documentsCustomers) => {
        if (documentsCustomers) {
          this.documentsCustomers = documentsCustomers;
        }
      }
    );
  }

}
