import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ExpenseWording, SelectModel, SubmitButton } from '@libs/models/src';
import { NotifService } from '@libs/services/src';
import { ExpenseWordingService } from '@libs/services/src/lib/expense-wording.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-expenses-wording-modal',
  templateUrl: './expenses-wording-modal.component.html',
  styleUrls: ['./expenses-wording-modal.component.scss']
})
export class ExpensesWordingModalComponent implements OnInit {
  @Input() expenseWording: ExpenseWording;
  @Input() form: FormGroup;
  @Input() expensesTypes: SelectModel[];
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private expenseWordingService: ExpenseWordingService,
    private notifService: NotifService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.expenseWordingService.setSubmitButton(this.submitButton);
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.expenseWording) {
        this.expenseWordingService.update(this.expenseWording.id, this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      } else {
        this.expenseWordingService.store(this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.expenseWordingService.determinateSubmitButton(this.expenseWording, 'le libellé de dépense');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
