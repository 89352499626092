import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectModel, SubmitButton } from '@libs/models/src';
import { BrandService, CustomerService, NotifService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-customers-create-brand-modal',
  templateUrl: './customers-create-brand-modal.component.html',
  styleUrls: ['./customers-create-brand-modal.component.scss']
})
export class CustomersCreateBrandModalComponent implements OnInit {
  @Input() group;
  @Input() marketsTypes: any[];
  @Input() customersTypes: any[];
  @Input() indicatorsCustomers: any[];
  @Input() mlSocieties: any[];
  public form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private brandService: BrandService,
    private notifService: NotifService,
    private customerService: CustomerService
  ) { }

  ngOnInit() {
    this.form = this.brandService.getCreateForm(this.group);
    this.customerService.initMlSocietiesCheckboxes(this.form, this.mlSocieties, this.group);
    this.determinateSubmitButton();
  }

  isValidForm() {
    return this.form.valid;
  }

  submitForm() {
    if (this.isValidForm()) {
      this.brandService.store(this.form).subscribe(
        (success) => {
          this.modal.close();
          this.notifService.showSuccessNotif(success);
        }
      );
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.brandService.determinateSubmitButton(null, 'la marque');
    this.brandService.setCreateSubmitButton(this.submitButton);
  }



}
