import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Invoice, InvoiceDetail, Product, SubmitButton } from '@libs/models/src';
import { CommonService } from '@libs/services/src';
import { InvoiceDetailService } from '@libs/services/src/lib/invoice-detail.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-invoice-show-products-modal',
  templateUrl: './invoice-show-products-modal.component.html',
  styleUrls: ['./invoice-show-products-modal.component.scss']
})
export class InvoiceShowProductsModalComponent implements OnInit, AfterViewInit {

  @Input() invoice: Invoice;
  @Input() detail: InvoiceDetail;
  @Input() products: any[];
  @Input() vatRates: any[];
  @ViewChild('invoiceShowAddEditProduct', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private invoiceDetailService: InvoiceDetailService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.initValues();
  }

  ngAfterViewInit() {
    this.invoiceDetailService.setModalAddEdit(this.childModal);
  }

  onModalShown() {
    this.determinateSubmitButton();
    this.invoiceDetailService.setSubmitButton(this.submitButton);
    this.form = this.invoiceDetailService.getForm(this.invoice.id, this.detail);
  }

  onModalHidden() {
    this.initValues();
  }

  onSelectProductChange(product: Product) {
    if (product) {
      this.form.patchValue({
        ref: product.name,
        accountingCode: product.accountingCode,
        designation: product.designation,
        vatRate: product.vatRate
      });
    }
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.detail) {
        this.invoiceDetailService.update(this.detail.id, this.form);
      } else {
        this.invoiceDetailService.store(this.form);
      }
    }
  }

  isValidForm() {
    return this.form.valid;
  }

  getTotalWithoutTaxes() {
    let total = 0;
    if (this.form) {
      const unitPrice = this.form.controls['unitPrice'];
      const quantity = this.form.controls['quantity'];
      if (unitPrice && !isNaN(unitPrice.value) && quantity && !isNaN(quantity.value)) {
        total = unitPrice.value * quantity.value;
      }
    }
    return this.commonService.formatNumberToLocale(total);
  }

  private initValues() {
    this.detail = null;
    this.form = null;
  }

  private determinateSubmitButton() {
    this.submitButton = this.invoiceDetailService.determinateSubmitButton(this.detail, 'le produit');
  }
}
