import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectModel, StandardResponse, SubmitButton, TextTag } from '@libs/models/src';
import { StandardResponseService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';


@Component({
  selector: 'app-standards-response-modal',
  templateUrl: './standards-response-modal.component.html',
  styleUrls: ['./standards-response-modal.component.scss']
})
export class StandardsResponseModalComponent implements OnInit {
  @Input() standardResponse: StandardResponse;
  @Input() form: FormGroup;
  @Input() affairsMonitoringTypes: any;
  @Input() sendSettings: any;
  @Input() textTags: TextTag[];
  @Input() days: any[];
  @Input() marketsTypes: SelectModel[];
  @ViewChild('subject') subject: ElementRef;
  @ViewChild('body') body: ElementRef;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private standardResponseService: StandardResponseService,
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.standardResponseService.setSubmitButton(this.submitButton);
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.standardResponse) {
        this.standardResponseService.update(this.standardResponse.id, this.form).subscribe(() => {
          this.modal.close();
        });
      } else {
        this.standardResponseService.store(this.form).subscribe(() => {
          this.modal.close();
        });
      }
    }
  }


  insertTextTagInSubject(text: string) {
    var startPos = this.subject.nativeElement.selectionStart;
    this.subject.nativeElement.focus();
    this.subject.nativeElement.value = this.subject.nativeElement.value.substr(0, this.subject.nativeElement.selectionStart)
      + text
      + this.subject.nativeElement.value.substr(this.subject.nativeElement.selectionStart, this.subject.nativeElement.value.length);

    this.subject.nativeElement.selectionStart = startPos;
    this.subject.nativeElement.focus();
    this.form.patchValue({
      subject: this.subject.nativeElement.value
    });
  }

  insertTextTagInBody(text: string) {
    var startPos = this.body.nativeElement.selectionStart;
    this.body.nativeElement.focus();
    this.body.nativeElement.value = this.body.nativeElement.value.substr(0, this.body.nativeElement.selectionStart)
      + text
      + this.body.nativeElement.value.substr(this.body.nativeElement.selectionStart, this.body.nativeElement.value.length);

    this.body.nativeElement.selectionStart = startPos;
    this.body.nativeElement.focus();
    this.form.patchValue({
      body: this.body.nativeElement.value
    });
  }

  private determinateSubmitButton() {
    this.submitButton = this.standardResponseService.determinateSubmitButton(this.standardResponse, 'la réponse standardisée');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }
}
