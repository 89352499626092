import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { DragDropService } from '@libs/services/src';

@Component({
  selector: 'app-settings-products-products-modal-documents-customers',
  templateUrl: './settings-products-products-modal-documents-customers.component.html',
  styleUrls: ['./settings-products-products-modal-documents-customers.component.scss']
})
export class SettingsProductsProductsModalDocumentsCustomersComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() documentsCustomersItems: any;
  public documentsCustomersArray = []

  constructor(
    private dragDropService: DragDropService
  ) { }

  ngOnInit() {
    this.documentsCustomersArray = JSON.parse(JSON.stringify(this.documentsCustomersItems));
  }

  get documentsCustomersControls(): FormArray {
    return this.form.get("documentsCustomers") as FormArray;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      this.dragDropService.moveItemInFormArray(
        this.documentsCustomersControls,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      const formGroupItem = this.getFormGroupItem(event.previousContainer.data, event.previousIndex);
      this.dragDropService.copyItemInFormArray(event.previousContainer.data,
        this.documentsCustomersControls,
        event.previousIndex,
        event.currentIndex,
        formGroupItem,
        this.documentsCustomersArray)
    }
  }

  private getFormGroupItem(
    data: any,
    fromIndex: number): FormGroup {
    const item = data[fromIndex];
    return new FormGroup({
      id: new FormControl(item.id),
      name: new FormControl(item.name),
      isNeeded: new FormControl(false)
    });
  }

  onDeleteSelectElementClicked(index: number) {
    const item = this.documentsCustomersControls.at(index);
    this.documentsCustomersControls.removeAt(index);
    this.documentsCustomersArray.push(item.value);
  }

}
