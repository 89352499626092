import { Component, OnInit } from '@angular/core';
import { ShuttleSheetService, ShuttleSheetTemplateService } from '@libs/services/src';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-customers-show-shuttles-sheets',
  templateUrl: './customers-show-shuttles-sheets.component.html',
  styleUrls: ['./customers-show-shuttles-sheets.component.scss']
})
export class CustomersShowShuttlesSheetsComponent implements OnInit {
  public baseModel: any = null;
  private baseModel$: Subscription;
  public templateModel: any = null;
  private templateModel$: Subscription;

  constructor(
    private shuttleService: ShuttleSheetService,
    private shuttleTemplateService: ShuttleSheetTemplateService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.shuttleService.getBaseModel();
    this.shuttleTemplateService.getModel();
  }

  ngOnDestroy() {
    this.baseModel$.unsubscribe();
    this.templateModel$.unsubscribe();
  }

  private initSubscriptions() {
    this.baseModel$ = this.shuttleService.baseModel$.subscribe(
      (model) => {
        if (model) {
          this.baseModel = model;
        }
      }
    );
    this.templateModel$ = this.shuttleTemplateService.model$.subscribe(
      (model) => {
        if (model) {
          this.templateModel = model;
        }
      }
    );
  }

}
