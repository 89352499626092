import { Component, OnInit } from '@angular/core';
import { SettingsDatatableParameters, SettingService, SwalService, ThingService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-settings-accounting-settings-index',
  templateUrl: './settings-accounting-settings-index.component.html',
  styleUrls: ['./settings-accounting-settings-index.component.scss']
})
export class SettingsAccountingSettingsIndexComponent implements OnInit {
  public datatableSelector = 'settings-accounting-datatable';
  public setting: any = null;
  public setting$: Subscription;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'key',
      name: 'Clé'
    },
    {
      attr: 'description',
      name: 'Description'
    },
    {
      attr: 'fieldType',
      name: 'Type'
    },
    {
      attr: 'value',
      name: 'Valeur',
      orderable: false,
      searchable: false
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];
  public availableEntitiesClasses = [];
  public availableEntitiesClasses$: Subscription;
  public param: SettingsDatatableParameters;
  private settingTypeKey = 'accounting';

  constructor(
    private settingService: SettingService,
    private swalService: SwalService,
    private thingService: ThingService
  ) { }

  ngOnInit() {
    this.initValues();
    this.initSubscriptions();
  }

  ngAfterViewInit() {
    this.settingService.initDataTable(this.datatableSelector, this.columns, this.settingTypeKey);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    this.setting$.unsubscribe();
  }

  onShowModalAddEdit() {
    this.settingService.showModalAddEdit();
  }

  initValues() {
    this.thingService.getAvailableEntitiesClasses('accounting');
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.settingService.get(id);
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le paramètre sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.settingService.delete(id);
          }
        });
      }
    });
  }

  private initSubscriptions() {
    this.setting$ = this.settingService.setting$.subscribe(
      (setting) => {
        if (setting) {
          this.setting = setting;
          this.onShowModalAddEdit();
        }
      }
    );

    this.availableEntitiesClasses$ = this.thingService.availableEntitiesClasses$.subscribe(
      (availableEntitiesClasses) => {
        if (availableEntitiesClasses) {
          this.availableEntitiesClasses = availableEntitiesClasses;

        }
      }
    );
  }


}
