import { Component, OnInit, ViewChild, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';
import { Field, Product, SubmitButton } from '@eros-front/models';
import { DocumentCustomerService, ProductService } from '@eros-front/services';
import { isThisSecond } from 'date-fns';

@Component({
  selector: 'app-settings-products-products-modal',
  templateUrl: './settings-products-products-modal.component.html',
  styleUrls: ['./settings-products-products-modal.component.scss']
})
export class SettingsProductsProductsModalComponent implements OnInit, AfterViewInit {

  @Input() product: Product;
  @Input() invoicesStatuses: any;
  @Input() vatRates: any;
  @Input() documentsCustomers: any;
  @Input() documentsDelivered: any;
  @Input() fields: Field[];
  @Input() conformities: any;
  @Input() marketsTypes: any;
  @Input() affairsGoals: any[];
  @ViewChild('settingsAddEditProduct', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public submitButton: SubmitButton;
  public documentsCustomersNotAssociate: any;
  public documentsDeliveredNotAssociate: any;
  public documentsDeliveredSelectItems: any = [];
  public fieldsNotAssociate: any = [];
  public affairsGoalsSelect: any = [];

  constructor(
    private productService: ProductService,
  ) {
  }

  ngOnInit() {
    this.initValues();
  }

  ngAfterViewInit() {
    this.productService.setModalAddEdit(this.childModal);
  }

  onModalShown() {
    this.determinateSubmitButton();
    this.productService.setSubmitButton(this.submitButton);
    this.form = this.productService.getForm(this.product);
    this.documentsDelivered.forEach(documentDelivered => {
      this.documentsDeliveredSelectItems.push({
        label: documentDelivered.name,
        value: documentDelivered.id,
      })
    });
    this.affairsGoals.forEach(affairGoal => {
      this.affairsGoalsSelect.push({
        label: affairGoal.name, 
        value: affairGoal.id, 
      })
    })
    if (this.product) {
      this.documentsCustomersNotAssociate = this.documentsCustomers.filter((x) => !this.product.documentsCustomers.some((y) => x.id === y.id));
      this.documentsDeliveredNotAssociate = this.documentsDelivered.filter((x) => !this.product.documentsDelivered.some((y) => x.id === y.id));
      this.fieldsNotAssociate = this.fields.filter((x) => !this.product.fields.some((y) => x.id === y.id));
      this.productService.initDocumentsCustomersList(this.form, this.product);
      this.productService.initDocumentsDeliveredList(this.form, this.product);
      this.productService.initFieldsList(this.form, this.product);
      this.productService.initConformitiesList(this.form, this.conformities, this.documentsDeliveredSelectItems, this.product);
    }
  }

  onModalHidden() {
    this.initValues();
    this.childModal.hide();
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.product) {
        this.productService.update(this.product.id, this.form);
      } else {
        this.productService.store(this.form);
      }
    }
  }

  private initValues() {
    this.product = null;
    this.form = null;
    this.documentsDeliveredSelectItems = [];
    this.documentsDeliveredNotAssociate = [];
    this.documentsCustomersNotAssociate = [];
    this.fieldsNotAssociate = [];
    this.affairsGoalsSelect = [];
  }

  private determinateSubmitButton() {
    this.submitButton = this.productService.determinateSubmitButton(this.product, 'le produit');
  }

  private isValidForm(): boolean {
    return this.form.valid;
  }

}
