import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-events-datatable-filters',
  templateUrl: './events-datatable-filters.component.html',
  styleUrls: ['./events-datatable-filters.component.scss']
})
export class EventsDatatableFiltersComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() users: any[];
  @Input() eventsTypes: any[];

  constructor() { }

  ngOnInit() {
  }

}
