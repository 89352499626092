import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SubmitButton } from '@eros-front/models';
import { SwalService } from './utilities/swal.service';
import { SelectService } from './utilities/select.service';
import { catchError, finalize } from 'rxjs/operators';
import { Payer } from '@eros-front/models';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CommonService } from './utilities/common.service';

@Injectable()
export class PayerService {

    private route = '/payers';
    private modalAdd: ModalDirective;
    public payer$ = new BehaviorSubject<any>(undefined);
    public payers$ = new BehaviorSubject<any>(undefined);
    public selectPayers$ = new BehaviorSubject<any>(undefined);

    constructor(
        private swalService: SwalService,
        private notifService: NotifService,
        private submitButtonService: SubmitButtonService,
        private selectService: SelectService,
        private formBuilder: FormBuilder,
        private apiService: ApiService,
        private commonService: CommonService
    ) { }

    public get(id: number): void {
        this.apiService.get(this.route + '/' + id)
            .subscribe(
                (payer) => {
                    this.payer$.next(payer);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public getAll(): void {
        this.apiService.get(this.route)
            .subscribe(
                (objects) => {
                    this.payers$.next(objects);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public store(form: any, button: SubmitButton): Observable<any> {
        this.submitButtonService.setDisabled(button);
        return this.apiService.post(this.route, form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(button);
                    this.swalService.showSwalSuccess('Payeur ajouté avec succès !');
                })
            );
    }

    public getForm(payer?: Payer): FormGroup {
        const name = payer ?
            this.commonService.valueToFormString(payer.name) : this.commonService.getDefaultFormNullValue();
        const accountingCode = payer ?
            this.commonService.valueToFormString(payer.accountingCode) : this.commonService.getDefaultFormNullValue();
        const address = payer ?
            this.commonService.valueToFormString(payer.address) : this.commonService.getDefaultFormNullValue();
        const additionalAddress = payer ?
            this.commonService.valueToFormString(payer.additionalAddress) : this.commonService.getDefaultFormNullValue();
        const postalCode = payer ?
            this.commonService.valueToFormString(payer.postalCode) : this.commonService.getDefaultFormNullValue();
        const locality = payer ?
            this.commonService.valueToFormString(payer.locality) : this.commonService.getDefaultFormNullValue();
        return this.formBuilder.group({
            name: [name, Validators.required],
            accountingCode: [accountingCode, Validators.required],
            address: [address, Validators.required],
            additionalAddress: additionalAddress,
            postalCode: [postalCode, Validators.required],
            locality: [locality, Validators.required]
        });
    }

    public setModalAdd(modal: ModalDirective): void {
        this.modalAdd = modal;
    }

    public showModalAdd(): void {
        this.modalAdd.show();
    }

    public hideModalAdd(): void {
        this.modalAdd.hide();
    }

    public getForSelect(): void {
        this.selectService.getForSelect(this.selectPayers$, this.route);
    }
}
