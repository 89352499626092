import { ConformityService, ContactService, IndicatorInterventionTypeService } from '@eros-front/services';
import { FormGroup, FormBuilder, Validators, FormArray, ValidatorFn, FormControl } from '@angular/forms';
import { InterventionStatusService } from './../../../../../../libs/services/src/lib/intervention-status.service';
import { Subscription, zip } from 'rxjs';
import { SubmitButton } from './../../../../../../libs/models/src/lib/submit-button.model';
import { InterventionService } from 'libs/services/src/lib/intervention.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Contact, Intervention } from '@libs/models/src';
import { zipAll } from 'rxjs/operators';
import { tabSuffix } from '_config/tab-suffix';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-intervention-edit',
  templateUrl: './intervention-edit.component.html',
  styleUrls: ['./intervention-edit.component.scss']
})
export class InterventionEditComponent implements OnInit, OnDestroy {

  public intervention: Intervention = null;
  public intervention$: Subscription;
  public interventionId: number;


  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private interventionService: InterventionService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = Number(params.get('id'));
      if (!isNaN(id)) {
        this.interventionId = id;
        this.interventionService.get(this.interventionId);
      }
    });
  }

  ngOnDestroy() {
    this.intervention$.unsubscribe();
    this.interventionService.reset();
  }

  initSubscriptions() {
    this.intervention$ = this.interventionService.intervention$.subscribe(
      (data) => {
        if (data) {
          this.intervention = data;
          this.titleService.setTitle(`Gestion intervention : ${data.name}${tabSuffix}`);
        }
      }
    )
  }
}
