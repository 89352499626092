import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AffairMonitoringType, SubmitButton } from '@libs/models/src';
import { AffairMonitoringTypeService, NotifService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-affairs-monitoring-types-modal',
  templateUrl: './affairs-monitoring-types-modal.component.html',
  styleUrls: ['./affairs-monitoring-types-modal.component.scss']
})
export class AffairsMonitoringTypesModalComponent implements OnInit {
  @Input() affairMonitoringType: AffairMonitoringType;
  @Input() form: FormGroup;
  @Input() sendSettings: any;
  public submitButton: SubmitButton;
  public colorValue: string

  constructor(
    private modal: NzModalRef,
    private affairMonitoringTypeService: AffairMonitoringTypeService,
    private notifService: NotifService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.affairMonitoringTypeService.setSubmitButton(this.submitButton);
    if (this.form) {
      this.colorValue = this.form.get('color').value
    }
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.affairMonitoringType) {
        this.affairMonitoringTypeService.update(this.affairMonitoringType.id, this.form).subscribe((success) => {
          this.modal.close();
          this.notifService.showSuccessNotif(success);
        });
      } else {
        this.affairMonitoringTypeService.store(this.form).subscribe((success) => {
          this.modal.close();
          this.notifService.showSuccessNotif(success);
        });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.affairMonitoringTypeService.determinateSubmitButton(this.affairMonitoringType, 'le paramètre de suivi d\'affaire');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
