import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-settings-products-products-modal-conformities',
  templateUrl: './settings-products-products-modal-conformities.component.html',
  styleUrls: ['./settings-products-products-modal-conformities.component.scss']
})
export class SettingsProductsProductsModalConformitiesComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() conformitiesItems: any;
  @Input() documentsDelivered: any;


  constructor() { }

  ngOnInit() {
    console.log(this.documentsDelivered);
  }

}
