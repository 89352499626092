import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AutocompleteOptions, FormFormatterService } from '@libs/services/src';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-affair-form-final-customer-address',
  templateUrl: './affair-form-final-customer-address.component.html',
  styleUrls: ['./affair-form-final-customer-address.component.scss']
})
export class AffairFormFinalCustomerAddressComponent implements OnInit {
  @Input() form: FormGroup;
  public autocompleteOptions: AutocompleteOptions = new AutocompleteOptions();

  constructor(
    private formFormatterService: FormFormatterService
  ) { }

  ngOnInit() {
  }


  onFinalCustomerAddressChange(address: Address) {
    this.form = this.formFormatterService.completeGoogleAddress(
      this.form, address,
      {
        street_number: 'finalCustomerStreetNumber',
        route: 'finalCustomerAddress',
        postal_code: 'finalCustomerPostalCode',
        locality: 'finalCustomerLocality'
      }
    );
  }

}
