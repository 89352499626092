import { CdkDragDrop, copyArrayItem, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { SubmitButton } from '@libs/models/src';
import { ShuttleSheetService, ShuttleSheetTemplateBuilding, SubmitButtonService } from '@libs/services/src';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-shuttle-sheet-modal-select-template',
  templateUrl: './shuttle-sheet-modal-select-template.component.html',
  styleUrls: ['./shuttle-sheet-modal-select-template.component.scss']
})
export class ShuttleSheetModalSelectTemplateComponent implements OnInit, AfterViewInit {

  @Input() key: string;
  @Input() templateModel: any;
  @Input() results: any[] = [];
  @Input() showNoneOtherOptions: boolean;
  @Output() modalClose = new EventEmitter();
  @ViewChild('ShuttleSheetModalSelectTemplate', { static: false }) childModal: ModalDirective;
  public submitButton: SubmitButton;
  public templates: any[] = [];

  constructor(
    private shuttleService: ShuttleSheetService,
    private submitButtonService: SubmitButtonService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.shuttleService.setModalTemplate(this.childModal);
  }

  onModalShown() {
    this.determinateSubmitButton();
    this.templates = [...this.getTemplatesByKey()];
    this.templates.push('Saisie libre de texte');
    if (this.showNoneOtherOptions) {
      this.templates.push('Aucun');
      this.templates.push('Autre');
    }
  }

  onModalHidden() {
    this.templates = [];
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm()) {
      this.modalClose.emit(this.results);
      this.closeModal();
    }
  }

  isValidForm() {
    return true;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      copyArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  onDeleteSelectElementClicked(index: number) {
    this.results.splice(index, 1);
  }

  onInputValueChanged(evt: any, index: number) {
    this.results[index] = evt.target.value;
  }

  private determinateSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      text: 'Mettre à jour',
      objectName: 'le menu déroulant',
      icon: faSyncAlt
    });
  }

  private getTemplatesByKey() {
    for (let b of this.templateModel.buildings) {
      if (b.key === this.key) {
        return b.templates;
      }
    }
    for (let e of this.templateModel.equipments) {
      if (e.key === this.key) {
        return e.templates;
      }
    }
    return [];
  }

}
