import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { CustomerService, CustomersModalSync } from '@eros-front/services';
import { NgSelectMultipleComponent } from '@eros-front/components';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-customers-consult-graph-modal-sync-indicators-types',
  templateUrl: './customers-consult-graph-modal-sync-indicators-types.component.html',
  styleUrls: ['./customers-consult-graph-modal-sync-indicators-types.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomersConsultGraphModalSyncIndicatorsTypesComponent extends CustomersModalSync implements OnInit, AfterViewInit, OnDestroy {

  @Input() group: any;
  @Input() sources: any;
  @Input() products: any;
  @Input() entities: any;
  @ViewChild('customersConsultGraphSyncIndicatorsTypes', { static: false }) childModal: ModalDirective;
  @ViewChild('consultGraphModalSyncIndicatorsTypesSelectEntities', { static: false }) childSelectEntities: NgSelectMultipleComponent;
  @Output() onModalInitComplete = new EventEmitter<any>();
  @Output() onModalClosed = new EventEmitter<any>();
  private custService: CustomerService;
  public selectedSource: string;
  public selectedEntities: any[];
  public selectedMode: number;
  public syncMarketsTypes: boolean;
  public syncCustomersTypes: boolean;
  public syncIndicatorsCustomersTypes: boolean;

  constructor(
    customerService: CustomerService
  ) {
    super(customerService, 4);
    this.custService = customerService;
  }

  ngOnInit() {
    this.initValues();
    this.setSelectEntitiesFromSource(this.selectedSource);
    this.initSubscriptions();
  }

  ngAfterViewInit() {
    this.onModalInitComplete.emit(this.childModal);
  }

  ngOnDestroy() {
    this.previewResult$.unsubscribe();
  }

  onModalHidden() {
    this.initValues();
    this.onModalClosed.emit();
  }

  onSelectedSourceChanged() {
    this.setSelectEntitiesFromSource(this.selectedSource);
    this.getPreviewResult();
  }

  isValidForm() {
    return (this.selectedSource != null
      && this.selectedEntities.length > 0
      && !isNaN(Number(this.selectedMode))
      && (this.syncMarketsTypes || this.syncCustomersTypes || this.syncIndicatorsCustomersTypes));
  }

  getPreviewResult() {
    if (this.isValidForm()) {
      this.custService.getPreviewOfSyncIndicatorsTypes({
        sourceRef: this.selectedSource,
        entitiesRefs: this.selectedEntities.map(x => x.value),
        mode: this.selectedMode,
        syncMarketsTypes: this.syncMarketsTypes,
        syncCustomersTypes: this.syncCustomersTypes,
        syncIndicatorsCustomersTypes: this.syncIndicatorsCustomersTypes,
      });
    } else {
      this.previewResult = [];
    }
  }

  onApplyClicked() {
  }

  private initValues() {
    this.selectedSource = this.group.ref;
    this.selectedEntities = [];
    this.selectedMode = 0;
    this.syncMarketsTypes = false;
    this.syncCustomersTypes = false;
    this.syncIndicatorsCustomersTypes = false;
    this.selectedStep = 0;
    this.previewResult = [];
    if (this.childSelectEntities) {
      this.childSelectEntities.clearSelectedItems();
    }
  }

  private initSubscriptions() {
    this.previewResult$ = this.custService.previewOfSyncIndicatorsTypes$.subscribe(
      (data) => {
        if (data) {
          this.previewResult = data;
        }
      }
    );
  }
}
