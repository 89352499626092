import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-affair-show-messages',
  templateUrl: './affair-show-messages.component.html',
  styleUrls: ['./affair-show-messages.component.scss']
})
export class AffairShowMessagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
