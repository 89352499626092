import { Injectable } from '@angular/core';
import { variable } from '@angular/compiler/src/output/output_ast';

interface MapData {
    data: any,
    valueMin: number,
    valueMax: number
}

@Injectable()
export class MapChartService {

    constructor() { }

    generateDataSimpleMode(geoJson: any, values: any): MapData {
        let mapData: MapData = {
            data: [],
            valueMin: values["01"] != null ? values["01"] : 0,
            valueMax: 0
        };
        geoJson.features.map((feature) => {
            if (values[feature.properties.code] < mapData.valueMin) {
                mapData.valueMin = values[feature.properties.code];
            }
            if (values[feature.properties.code] > mapData.valueMax) {
                mapData.valueMax = values[feature.properties.code];
            }
            mapData.data.push({
                code: feature.properties.code,
                name: feature.properties.name,
                value: values[feature.properties.code] != null ? values[feature.properties.code] : 0
            })
        });
        return mapData;
    }

    generateDataYMinusOneMode(geoJson: any, values: any, valuesYMinusOne: any): MapData {
        let mapData: MapData = {
            data: [],
            valueMin: 0,
            valueMax: 0
        };
        geoJson.features.map((feature) => {
            let diff = this.calcDiffValue(values[feature.properties.code], valuesYMinusOne[feature.properties.code]);
            if (diff < mapData.valueMin) {
                mapData.valueMin = diff;
            }
            if (diff > mapData.valueMax) {
                mapData.valueMax = diff;
            }
            mapData.data.push({
                code: feature.properties.code,
                name: feature.properties.name,
                value: diff,
                currentValue: values[feature.properties.code],
                valueYMinusOne: valuesYMinusOne[feature.properties.code]
            })
        });
        return mapData;
    }


    generateNameMap(geoJson: any): any {
        let nameMap = {};
        geoJson.features.forEach((feature) => {
            nameMap[feature.properties.name] = feature.properties.name;
        });

        return nameMap;
    }

    getOptions(statisticsData: any, geoJson: any) {
        return {
            tooltip: {
                trigger: 'item',
                formatter: '{b}<br/>{c}'
            },
            visualMap: {
                left: 'left',
                min: statisticsData.valueMin,
                max: statisticsData.valueMax,
                inRange: {
                    color: ['lightskyblue', 'yellow', 'orangered', 'red']
                },
                calculable: true
            },
            series: [
                {
                    name: 'France map',
                    type: 'map',
                    mapType: 'FR',
                    roam: true,
                    selectedMode: 'single',
                    scaleLimit: {
                        min: 1,
                        max: 4
                    },
                    data: statisticsData.data,
                    nameMap: this.generateNameMap(geoJson)
                }
            ]
        };
    }

    private calcDiffValue(value, valueYMinusOne) {
        if (value != null && valueYMinusOne != null) {
            return value - valueYMinusOne;
        } else if (value != null && valueYMinusOne == null) {
            return value
        } else if (value == null && valueYMinusOne != null) {
            return valueYMinusOne;
        } else {
            return 0;
        }
    }


}
