import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';
import { SubmitButton } from '@eros-front/models';
import { PerformanceService } from '@eros-front/services';
import { Performance } from '@eros-front/models';

@Component({
  selector: 'app-settings-products-performances-modal',
  templateUrl: './settings-products-performances-modal.component.html',
  styleUrls: ['./settings-products-performances-modal.component.scss']
})
export class SettingsProductsPerformancesModalComponent implements OnInit, AfterViewInit {

  @Input() performance: Performance;
  @ViewChild('settingsAddEditPerformance', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private performanceService: PerformanceService
  ) { }

  ngOnInit() {
    this.initValues();
  }

  ngAfterViewInit() {
    this.performanceService.setModalAddEdit(this.childModal);
  }

  onModalShown() {
    this.determinateSubmitButton();
    this.performanceService.setSubmitButton(this.submitButton);
    this.form = this.performanceService.getForm(this.performance);
  }

  onModalHidden() {
    this.initValues();
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.performance) {
        this.performanceService.update(this.performance.id, this.form);
      } else {
        this.performanceService.store(this.form);
      }
    }
  }

  private initValues() {
    this.performance = null;
    this.form = null;
  }

  private determinateSubmitButton() {
    this.submitButton = this.performanceService.determinateSubmitButton(this.performance, 'la performance');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
