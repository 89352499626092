import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'apps/eros-nrj/src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { BarType } from '../types/bar-type.type';

@Injectable()
export class BarTypeService {

  public barTypes$ = new BehaviorSubject<BarType[]>(undefined);

  constructor(
    private http: HttpClient,
  ) {}

  getAll(): void {
    this.http.get<BarType[]>(environment.serviceEUrl + '/bar-types').subscribe({
      next: (barTypes: BarType[]) => {
        this.barTypes$.next(barTypes);
      }
    });
  }

}
