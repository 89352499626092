export const locale = {
    // French locale
    fr: {
        // Toolbar
        undo: "Annuler",
        redo: "Rétablir",

        textColor: "Couleur de police",
        backgroundColor: "Couleur de remplissage",

        bold: "Gras",
        italic: "Italique",
        underline: "Souligné",

        halign: "Alignement horizontal",
        valign: "Alignement vertical",
        left: "Aligner à gauche",
        right: "Aligner à droite",
        center: "Centrer",
        top: "Aligner en haut",
        bottom: "Aligner en bas",
        multiline: "Habillage",
        clip: "Ajuster",
        wrap: "Renvoyer à la ligne automatiquement",

        lockCell: "Verrouiller la cellule",
        unlockCell: "Déverrouiller la cellule",

        clear: "Effacer",
        clearValue: "Effacer le contenu",
        clearStyles: "Effacer les formats",
        clearAll: "Tout effacer",

        columns: "Colonnes",
        rows: "Lignes",
        addColumn: "Ajouter une colonne",
        removeColumn: "Supprimer une column",
        addRow: "Ajouter une ligne",
        removeRow: "Supprimer une ligne",

        format: "Format",
        common: "Standard",
        number: "Nombre",
        currency: "Devise",
        percent: "Pourcentage",
        text: "Texte",
        date: "Date",
        time: "Heure",

        help: "Aide",

        // Tabbar
        deleteSheet: "Supprimer",
        renameSheet: "Renommer",

        // Menu
        file: "Fichier",
        import: "Import",
        export: "Export",
        downloadAs: "Télécharger en tant que...",
        importAs: "Importer en tant que...",

        edit: "Éditer",
        insert: "Insérer",

        data: "Données",
        validation: "Validation des données",
        sort: "Trier",
        ascSort: "Tri de A à Z",
        descSort: "Tri de Z à A",
    }
};