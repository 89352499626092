import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormFormatterService } from './utilities/form-formatter.service';
import { CommonService } from './utilities/common.service';
import { ApiService } from '@libs/api/src';
import { ModelWithModalCrud } from './classes/model-modal-crud';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { NotifService } from './utilities/notif.service';
import { catchError, finalize } from 'rxjs/operators';
import { InvoiceService } from './invoice.service';
import { InvoiceDetail } from '@libs/models/src';


@Injectable()
export class InvoiceDetailService extends ModelWithModalCrud {

    private route = '/invoice-details';
    public detail$ = new BehaviorSubject<any>(undefined);
    public details$ = new BehaviorSubject<any>(undefined);

    constructor(
        public apiService: ApiService,
        public swalService: SwalService,
        public notifService: NotifService,
        public submitButtonService: SubmitButtonService,
        private invoiceService: InvoiceService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService
    ) {
        super();
    }

    public getForm(invoiceId: number, detail?: InvoiceDetail): FormGroup {
        const productId = detail ? this.commonService.valueToFormSelect(detail.productId) : this.commonService.getDefaultFormNullValue();
        const ref = detail ? this.commonService.valueToFormString(detail.ref) : this.commonService.getDefaultFormStringValue();
        const accountingCode = detail ? this.commonService.valueToFormString(detail.accountingCode) : this.commonService.getDefaultFormStringValue();
        const designation = detail ? this.commonService.valueToFormString(detail.designation) : this.commonService.getDefaultFormStringValue();
        const vatRate = detail ? this.commonService.valueToFormSelect(detail.vatRate) : this.commonService.getDefaultFormNullValue();
        const unitPrice = detail ? this.commonService.valueToFormNumber(detail.unitPrice) : 1;
        const quantity = detail ? this.commonService.valueToFormNumber(detail.quantity) : 1;
        return this.formBuilder.group({
            invoiceId: [invoiceId, Validators.required],
            productId: productId,
            ref: [ref, Validators.required],
            accountingCode: [accountingCode, Validators.required],
            designation: designation,
            vatRate: [vatRate, Validators.required],
            unitPrice: [unitPrice, Validators.required],
            quantity: [quantity, Validators.required]
        });
    }

    public getManualCreateForm(): FormGroup {
        let form = this.getForm(0);
        form.removeControl('invoiceId');
        form = this.formBuilder.group({
            ...form.controls,
            rowId: '_' + Math.random().toString(36).substr(2, 9),
            totalWithoutTaxes: this.commonService.formatNumberToLocale(1)
        });
        return form;
    }

    public get(id: number): void {
        super.get(id, this.detail$, this.route);
    }

    public getAllByInvoice(invoiceId: number): void {
        this.apiService.get(`${this.route}/invoice/${invoiceId}`)
            .subscribe(
                (objects) => {
                    this.details$.next(objects);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public store(form: FormGroup): void {
        this.formatForm(form);
        this.submitButtonService.setDisabled(this.submitButton);
        this.apiService.post(this.route, form.value)
            .pipe(
                catchError(error => {
                    this.notifService.showErrorNotif(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
            .subscribe(
                (success) => {
                    this.refreshInvoice(form);
                    this.hideModalAddEdit();
                    this.notifService.showSuccessNotif(success);
                }
            );
    }

    public update(id: number, form: FormGroup): void {
        this.submitButtonService.setDisabled(this.submitButton);
        this.apiService.put(this.route + '/' + id, this.formatForm(form))
            .pipe(
                catchError(error => {
                    this.notifService.showErrorNotif(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
            .subscribe(
                (success) => {
                    this.refreshInvoice(form);
                    this.hideModalAddEdit();
                    this.notifService.showSuccessNotif(success);
                }
            );
    }

    public deleteAndRefresh(invoiceId: number, id: number): void {
        this.apiService.delete(this.route + '/' + id)
            .pipe(
                catchError(error => {
                    this.notifService.showErrorNotif(error);
                    return throwError(error);
                })
            )
            .subscribe(
                (success) => {
                    this.invoiceService.get(invoiceId);
                    this.notifService.showSuccessNotif(success);
                }
            );
    }

    private formatForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.productId = this.formFormatterService.formatSelectSingle(values.mlAgencyId);
        return values;
    }

    private refreshInvoice(form: FormGroup): void {
        if (form && form.controls['invoiceId'] && !isNaN(form.controls['invoiceId'].value)) {
            this.invoiceService.get(form.controls['invoiceId'].value);
        }
    }

}
