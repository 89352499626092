import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Affair, Building, Event } from '@libs/models/src';
import { BuildingService, EventService, EventTypeService, UserService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { EventAffairModalAddEditComponent } from '../../../events/event-affair-modal-add-edit/event-affair-modal-add-edit.component';

@Component({
  selector: 'app-affair-show-events',
  templateUrl: './affair-show-events.component.html',
  styleUrls: ['./affair-show-events.component.scss']
})
export class AffairShowEventsComponent implements OnInit {
  @Input() affair: Affair;
  public form: FormGroup;
  private event$: Subscription;
  public event: Event = null;
  private eventsTypes$: Subscription;
  public eventsTypes: any;
  private usersSelect$: Subscription;
  public usersSelect: any[];
  public buildings$: Subscription;
  public buildings: Building[];
  public eventForm: FormGroup;
  public eventsData: any;
  public isLoading = false;

  constructor(
    private eventService: EventService,
    private eventTypeService: EventTypeService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private modalService: NzModalService,
    private buildingService: BuildingService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.getValues();
    this.form = this.getFiltersForm();
    this.initList();

    this.form.valueChanges.subscribe(value => {
      this.initList();
    });
  }

  ngOnDestroy() {
    if (this.event$) {
      this.event$.unsubscribe();
    }
    this.eventsTypes$.unsubscribe();
    this.usersSelect$.unsubscribe();
  }

  initList() {
    this.isLoading = true;
    this.eventService.getForAffair(this.form).subscribe(
      (data) => {
        this.eventsData = data;
        this.isLoading = false;
      }
    )
  }

  onShowModalAddEdit() {
    this.eventForm = this.eventService.getFormForAffair(this.affair, this.event, this.usersSelect);
    const modalInstance = this.modalService.create({
      nzContent: EventAffairModalAddEditComponent,
      nzComponentParams: {
        affair: this.affair,
        event: this.event,
        buildings: this.buildings,
        users: this.usersSelect,
        form: this.eventForm,
        eventsTypes: this.eventsTypes
      },
      nzWidth: '70%'
    })
    modalInstance.afterClose.subscribe(() => {
      this.event = null;
      this.initList();
    })
  }

  onRowClicked(id: number) {
    this.eventService.get(id).subscribe(
      (data) => {
        this.event = data;
        this.onShowModalAddEdit();
      }
    )
  }

  public getFiltersForm(): FormGroup {
    return this.formBuilder.group({
      selectedAffair: this.affair.id,
    });
  }

  private getValues() {
    this.eventTypeService.getForSelect();
    this.userService.getUsersSelect();
    this.buildingService.getByAffair(this.affair.id);
  }

  private initSubscriptions() {
    this.event$ = this.eventService.event$.subscribe(
      (data) => {
        if (data) {
          this.event = data;
          this.onShowModalAddEdit();
        }
      }
    );
    this.eventsTypes$ = this.eventTypeService.eventsTypesSelect$.subscribe(
      (data) => {
        if (data) {
          this.eventsTypes = data;
        }
      }
    )
    this.usersSelect$ = this.userService.usersSelect$.subscribe(
      (data) => {
        if (data) {
          this.usersSelect = data;
        }
      }
    )
    this.buildings$ = this.buildingService.buildingsByAffair$.subscribe(
      (data) => {
        if (data) {
          this.buildings = data;
        }
      });

  }

}
