import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable()
export class DateUtilsService {

  constructor() { }


  calcTimeDifference(startDate: Date, endDate: Date) {
    const durationHours = Math.floor(Math.abs(startDate.getTime() - endDate.getTime()) / 3600000);
    const durationMinutes = Math.floor(Math.abs(startDate.getTime() - endDate.getTime()) / 60000 % 60);
    const duration = new Date();
    duration.setHours(durationHours);
    duration.setMinutes(durationMinutes);
    duration.setSeconds(0);
    return duration;
  }

  getDuration(duration: number | string): Date {
    const newDate = new Date();
    let dateObject = moment(newDate).add(duration, 'm').toDate();
    return this.calcTimeDifference(newDate, dateObject);
  }

  getDateFromFormatted(dateFrom, dateDisplayMode) {
    let momentDate = moment(dateFrom);
    switch (dateDisplayMode) {
      case 0: {
        return momentDate.startOf('day').format('Y-M-D');
      }

      case 1: {
        return momentDate.startOf('month').format('Y-M-D');
      }

      case 2: {
        return momentDate.startOf('quarter').format('Y-M-D');
      }

      case 3: {
        return momentDate.startOf('year').format('Y-M-D');
      }

      default: {
        return dateFrom;
      }
    }
  }

  getDateToFormatted(dateTo, dateDisplayMode) {
    let momentDate = moment(dateTo);
    switch (dateDisplayMode) {
      case 0: {
        return momentDate.endOf('day').format('Y-M-D');
      }

      case 1: {
        return momentDate.endOf('month').format('Y-M-D');
      }

      case 2: {
        return momentDate.endOf('quarter').format('Y-M-D');
      }

      case 3: {
        return momentDate.endOf('year').format('Y-M-D');
      }

      default: {
        return dateTo;
      }
    }
  }

  getNextWeekStart() {
    var newDate = moment().add(7, 'days');
    return newDate.startOf('week').startOf('day').toDate();
  }

  getNextWeekEnd() {
    var endDate = moment().add(7, 'days');
    return endDate.endOf('week').endOf('day').toDate();
  }

  getStartOfMonth() {
    var currentDate = moment();
    return currentDate.startOf('month').toDate();
  }

  getEndOfMonth() {
    var currentDate = moment();
    return currentDate.endOf('month').toDate();
  }


}
