import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AffairStatus, SubmitButton } from '@libs/models/src';
import { NotifService } from '@libs/services/src';
import { AffairStatusService } from '@libs/services/src/lib/affair-status.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-affairs-statuses-settings-modal',
  templateUrl: './affairs-statuses-settings-modal.component.html',
  styleUrls: ['./affairs-statuses-settings-modal.component.scss']
})
export class AffairsStatusesSettingsModalComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() affairStatus: AffairStatus;
  @Input() documentsDelivered: any;
  @Input() documentsCustomers: any;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private affairStatusService: AffairStatusService,
    private notifService: NotifService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.affairStatusService.setSubmitButton(this.submitButton);
  }

  submitForm() {
    if (this.isValidForm()) {
      this.affairStatusService.updateSettings(this.affairStatus.id, this.form).subscribe((success) => {
        this.notifService.showSuccessNotif(success);
        this.modal.close();
      });
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.affairStatusService.determinateSubmitButton(this.affairStatus, 'le statut affaire');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
