import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Libs
import { SharedModule } from '@eros-front/shared';
import { StatisticsTableComponent } from './statistics-table/statistics-table.component';
import { StatisticsMapTableComponent } from './statistics-map-table/statistics-map-table.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    StatisticsTableComponent,
    StatisticsMapTableComponent
  ],
  exports: [
    StatisticsTableComponent,
    StatisticsMapTableComponent
  ]
})
export class StatisticsModule {

}
