import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiService } from '@libs/api/src';
import { BehaviorSubject } from 'rxjs';
import { StatisticInterventionService } from './statistic-intervention.service';
import { NotifService } from './utilities/notif.service';

@Injectable()
export class StatisticInterventionRevenueService {

    private route = '/stats/interventions/revenue';
    public statsInterventionsRevenueRegions$ = new BehaviorSubject<any>(undefined);
    public statsInterventionsRevenueTechnicians$ = new BehaviorSubject<any>(undefined);
    public statsInterventionsRevenueProducts$ = new BehaviorSubject<any>(undefined);
    public statsInterventionsRevenueDepartments$ = new BehaviorSubject<any>(undefined);

    constructor(
        private apiService: ApiService,
        private notifService: NotifService,
        private statisticIntervention: StatisticInterventionService
    ) { }

    getStatsInterventionsRegions(form: FormGroup) {
        const params = this.statisticIntervention.formToParams(form);
        this.apiService.post(this.route + '/regions', params).subscribe(
            (result) => {
                this.statsInterventionsRevenueRegions$.next(result);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    getStatsInterventionsTechnicians(form: FormGroup) {
        const params = this.statisticIntervention.formToParams(form);
        this.apiService.post(this.route + '/technicians', params).subscribe(
            (result) => {
                this.statsInterventionsRevenueTechnicians$.next(result);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    getStatsInterventionsProducts(form: FormGroup) {
        const params = this.statisticIntervention.formToParams(form);
        this.apiService.post(this.route + '/products', params).subscribe(
            (result) => {
                this.statsInterventionsRevenueProducts$.next(result);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    getStatsInterventionsDepartments(form: FormGroup) {
        const params = this.statisticIntervention.formToParams(form);
        this.apiService.post(this.route + '/departments', params).subscribe(
            (result) => {
                this.statsInterventionsRevenueDepartments$.next(result);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }


}
