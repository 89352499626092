import { Injectable } from '@angular/core';
import { MarketType } from '@libs/models/src/lib/market-type.model';

@Injectable()
export class CustomLocalStorageService {

    constructor() { }

    isMenuPin(): boolean {
        const isMenuPin = this.retrieve('isMenuPin');
        if (typeof isMenuPin === 'boolean') {
            return !isMenuPin;
        }
        return true;
    }

    setMenuPin(value: boolean) {
        this.store('isMenuPin', value);
    }

    getActiveRouteItem() {
        const activeRouteItem = this.retrieve('activeRouteItem');
        if (activeRouteItem && activeRouteItem.length > 0) {
            return activeRouteItem;
        }
        return null;
    }

    setActiveRouteItem(value: string) {
        this.store('activeRouteItem', value);
    }

    resetActiveRouteItem() {
        this.remove('activeRouteItem');
    }


    getSelectedMarketsTypes() {
        const selectedMarketsTypes = this.retrieve('selectedMarketsTypes');
        if (Array.isArray(selectedMarketsTypes)) {
            return selectedMarketsTypes;
        }
        return [];
    }

    getSelectedMarketsTypesIds() {
        return this.getSelectedMarketsTypes().map((i) => { return i.id });
    }

    setSelectedMarketsTypes(selectedMarketsTypes: MarketType[]) {
        const arrayToStore = [];
        selectedMarketsTypes.sort((a, b) => (a.id < b.id ? -1 : 1));
        for (let selected of selectedMarketsTypes) {
            arrayToStore.push({
                id: selected.id,
                tllIcon: selected.tllIcon,
                name: selected.name
            });
        }
        this.store('selectedMarketsTypes', arrayToStore);
    }

    public store(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public retrieve(key: string): any {
        if (localStorage.getItem(key) != null) {
            return JSON.parse(localStorage.getItem(key));
        }
        return null;
    }

    public remove(key: string) {
        localStorage.removeItem(key);
    }
}