import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { Affair, Building, Part, SubmitButton } from '@libs/models/src';
import { BuildingPartService, BuildingService, NotifService, SubmitButtonService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-affair-show-buildings-modal-associate',
  templateUrl: './affair-show-buildings-modal-associate.component.html',
  styleUrls: ['./affair-show-buildings-modal-associate.component.scss']
})
export class AffairShowBuildingsModalAssociateComponent implements OnInit, OnDestroy {

  @Input() affair: Affair;
  @Input() building: Building;
  @Input() form: FormGroup;
  public parts: Part[] = [];
  private parts$: Subscription;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private partService: BuildingPartService,
    private buildingService: BuildingService,
    private submitButtonService: SubmitButtonService,
    private notifService: NotifService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.determinateSubmitButton();
    this.partService.getAllByAffair(this.affair.id);
    this.buildingService.setAssociateSubmitButton(this.submitButton);
  }

  ngOnDestroy() {
    if (this.parts$) {
      this.parts$.unsubscribe();
    }
  }

  submitForm() {
    if (this.isValidForm()) {
      this.buildingService.associateToPart(this.form).subscribe(
        (success) => {
          this.modal.close();
          this.notifService.showSuccessNotif(success);
        }
      );
    }
  }

  isValidForm() {
    return this.form.valid;
  }

  private initSubscriptions() {
    this.parts$ = this.partService.selectParts$.subscribe(
      (parts: Part[]) => {
        if (parts) {
          this.parts = parts;
        }
      }
    );
  }

  private determinateSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'la tranche',
      text: 'Affecter à',
      icon: faShare
    });
  }

}
