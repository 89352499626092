import { AfterViewInit, Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { Affair, SubmitButton, User } from '@libs/models/src';
import { EventService, FormFormatterService, NotifService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-event-modal-add-edit',
  templateUrl: './event-modal-add-edit.component.html',
  styleUrls: ['./event-modal-add-edit.component.scss']
})
export class EventModalAddEditComponent implements OnInit {
  @Input() affair: Affair;
  @Input() event: any;
  @Input() eventAdd: any;
  @Input() users: any;
  @Input() usersSelected: any;
  @Input() eventsTypes: any[];
  @Input() form: FormGroup;
  public submitButton: SubmitButton;
  public showTime = {
    nzMinuteStep: 15,
    nzFormat: 'HH:mm'
  }

  constructor(
    private modal: NzModalRef,
    private eventService: EventService,
    private formFormatter: FormFormatterService,
    private notifService: NotifService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
  }

  isValidForm(): boolean {
    return this.form.valid;
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.event) {
        this.eventService.update(this.event.id, this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      } else {
        this.eventService.store(this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      }
    }
  }

  public onSelectValueChanged() {
    this.form.patchValue({
      'name': this.generateName()
    });
  }

  private generateName() {
    const eventTypeSelectedId = this.form.get('eventTypeId').value;
    const eventTypeSelected = eventTypeSelectedId ? this.eventsTypes.find(x => x.value == eventTypeSelectedId) : null

    let name = '';
    if (eventTypeSelected) {
      name = name.concat('', eventTypeSelected.label);
    }

    return name;
  }

  private determinateSubmitButton() {
    let text = '';
    let icon = null;
    if (!this.event) {
      text = 'Ajouter l\'événement';
      icon = faPlus;
    } else {
      text = 'Modifier l\'événement';
      icon = faSave;
    }
    this.submitButton = {
      text: text,
      originalText: text,
      icon: icon,
      originalIcon: icon,
      enabled: true
    };
    this.eventService.setSubmitButton(this.submitButton);
  }
}
