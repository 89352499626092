import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CustomersShowModalSyncIndicatorsTypesComponent } from './customers-show-modal-sync-indicators-types/customers-show-modal-sync-indicators-types.component';
import { CustomersShowModalSyncInternalNoteComponent } from './customers-show-modal-sync-internal-note/customers-show-modal-sync-internal-note.component';
import { CustomersShowModalSyncPricesComponent } from './customers-show-modal-sync-prices/customers-show-modal-sync-prices.component';

@Component({
  selector: 'app-customers-show-synchronization',
  templateUrl: './customers-show-synchronization.component.html',
  styleUrls: ['./customers-show-synchronization.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomersShowSynchronizationComponent implements OnInit, OnChanges {

  @Input() customer: any;
  @Input() products: any;
  @Input() marketsTypes: any;
  @Input() entities: any;
  private modalSyncPrices = null;
  private modalSyncIndicatorsTypes = null;
  private modalSyncInternalNote = null;

  constructor(
    private modalService: NzModalService

  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: any) {
    if (changes['products'] && changes['products'].previousValue != changes['products'].currentValue) {
      if (Array.isArray(this.products) && this.products.length > 0) {
        this.products = this.productsDataToArray(this.products);
      }
    }
  }

  onSyncPricesClicked() {
    const modalInstance = this.modalService.create({
      nzContent: CustomersShowModalSyncPricesComponent,
      nzComponentParams: {
        source: this.customer,
        products: this.products,
        entities: this.entities,
        marketsTypes: this.marketsTypes
      },
      nzWidth: "70%"
    });
  }

  onSyncInternalNoteClicked() {
    this.modalService.create({
      nzContent: CustomersShowModalSyncInternalNoteComponent,
      nzComponentParams: {
        source: this.customer,
        entities: this.entities
      },
      nzWidth: "70%"
    })
  }

  onSyncIndicatorsTypesClicked() {
    this.modalService.create({
      nzContent: CustomersShowModalSyncIndicatorsTypesComponent,
      nzComponentParams: {
        source: this.customer,
        entities: this.entities,

      },
      nzWidth: "70%"
    })
  }

  onModalSyncPricesInitComplete(modal: ModalDirective) {
    this.modalSyncPrices = modal;
  }

  onModalSyncInternalNoteInitComplete(modal: ModalDirective) {
    this.modalSyncInternalNote = modal;
  }

  onModalSyncIndicatorsTypesInitComplete(modal: ModalDirective) {
    this.modalSyncIndicatorsTypes = modal;
  }

  private productsDataToArray(data: any[]): any[] {
    const result = [];
    for (const item of data) {
      result.push({
        value: item.id,
        label: item.name
      });
    }
    return result;
  }

}
