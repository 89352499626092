import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { QuotationStatus, SubmitButton } from '@libs/models/src';
import { NotifService } from '@libs/services/src';
import { QuotationStatusService } from '@libs/services/src/lib/quotation-status.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-quotations-statuses-modal',
  templateUrl: './quotations-statuses-modal.component.html',
  styleUrls: ['./quotations-statuses-modal.component.scss']
})
export class QuotationsStatusesModalComponent implements OnInit {
  @Input() quotationStatus: QuotationStatus;
  @Input() labelsClasses: any;
  @Input() form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private quotationStatusService: QuotationStatusService,
    private notifService: NotifService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.quotationStatusService.setSubmitButton(this.submitButton);
  }


  submitForm() {
    if (this.isValidForm()) {
      if (this.quotationStatus) {
        this.quotationStatusService.update(this.quotationStatus.id, this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      } else {
        this.quotationStatusService.store(this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.quotationStatusService.determinateSubmitButton(this.quotationStatus, 'le statut devis');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
