import { Component, OnDestroy, OnInit } from '@angular/core';
import { AffairProductsToInvoicingTabs } from '@libs/enum/src';
import { Payer, SelectModel } from '@libs/models/src';
import { AffairProductService, AgencyService, MlAgencyService, PayerService, ThingService } from '@libs/services/src';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-affair-products-to-invoicing-tabs',
  templateUrl: './affair-products-to-invoicing-tabs.component.html',
  styleUrls: ['./affair-products-to-invoicing-tabs.component.scss']
})
export class AffairProductsToInvoicingTabsComponent implements OnInit, OnDestroy {
  private payersPreferences$: Subscription;
  public agencies: SelectModel[];
  public mlAgencies: SelectModel[];
  public payersPreferences = [];

  public payers: SelectModel[];
  public productsStatuses: SelectModel[];
  public affairProductsToInvoicingTabs = Object.values(AffairProductsToInvoicingTabs);
  private affairProductsFilters$: Subscription;

  constructor(
    private affairProductService: AffairProductService,
    private thingService: ThingService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.thingService.getPayersPreferences();
    this.affairProductService.getFiltersForProductList();
  }

  ngOnDestroy() {
    this.affairProductsFilters$.unsubscribe();
    this.payersPreferences$.unsubscribe();
  }

  private initSubscriptions() {
    this.affairProductsFilters$ = this.affairProductService.affairProductsFilters$.subscribe(
      (data) => {
        if (data) {
          this.agencies = data.agencies;
          this.mlAgencies = data.mlAgencies;
          this.payers = data.payers;
          this.productsStatuses = data.productsStatuses
        }
      }
    )
    this.payersPreferences$ = this.thingService.payersPreferences$.subscribe(
      (data) => {
        this.payersPreferences = data;
      }
    )
  }


}
