import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectMultipleComponent } from '@libs/components/src';
import { CustomerService, CustomersModalSync, NotifService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-customers-show-modal-sync-internal-note',
  templateUrl: './customers-show-modal-sync-internal-note.component.html',
  styleUrls: ['./customers-show-modal-sync-internal-note.component.scss']
})
export class CustomersShowModalSyncInternalNoteComponent extends CustomersModalSync implements OnInit, OnDestroy {

  @Input() source: any;
  @Input() entities: any;
  @ViewChild('consultGraphModalSyncPricesSelectEntities', { static: false }) childSelectEntities: NgSelectMultipleComponent;
  private custService: CustomerService;
  public selectedEntities: any[];
  public selectedMode: number;
  public submitClicked = false;

  constructor(
    customerService: CustomerService,
    private notifService: NotifService,
    private modal: NzModalRef
  ) {
    super(customerService, 2);
    this.custService = customerService;
  }

  ngOnInit() {
    this.initValues();
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.previewResult$.unsubscribe();
  }

  isValidForm() {
    return (this.selectedEntities.length > 0
      && !isNaN(Number(this.selectedMode)));
  }

  getPreviewResult() {
    if (this.isValidForm()) {
      this.custService.getPreviewOfSyncInternalNotes({
        sourceRef: this.source.ref,
        entitiesRefs: this.selectedEntities.map(x => x.value),
        mode: this.selectedMode
      });
    } else {
      this.previewResult = [];
    }
  }

  onApplyClicked() {
    if (this.isValidForm()) {
      this.submitClicked = true;
      this.custService.applySyncInternalNotes({
        sourceRef: this.source.ref,
        entitiesRefs: this.selectedEntities.map(x => x.value),
        mode: this.selectedMode
      }).subscribe((response) => {
        this.notifService.showSuccessNotif(response);
        this.submitClicked = false;
        this.modal.close();
      });
    }
  }

  private initValues() {
    this.selectedEntities = [];
    this.selectedMode = 0;
    this.selectedStep = 0;
    this.previewResult = [];
    if (this.childSelectEntities) {
      this.childSelectEntities.clearSelectedItems();
    }
  }

  private initSubscriptions() {
    this.previewResult$ = this.custService.previewOfSyncInternalNotes$.subscribe(
      (data) => {
        if (data) {
          this.previewResult = data;
        }
      }
    );
  }

}
