import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { StatisticModeEnum } from '@libs/enum/src';

interface StatisticData {
  categorie: string;
  value: number;
  valueYMinusOne?: number;
  difference?: number;
}

@Component({
  selector: 'app-statistics-map-table',
  templateUrl: './statistics-map-table.component.html',
  styleUrls: ['./statistics-map-table.component.scss']
})

export class StatisticsMapTableComponent implements OnInit, OnChanges {
  @Input() statisticsData;
  @Input() displayMode;
  public totalStats = 0;
  public totalStatsYMinusOne = 0;
  public difference = 0;
  public statisticsDataTable: StatisticData[] = [];
  public statisticModeEnum = StatisticModeEnum;
  listOfColumn = [
    {
      title: 'Département',
      compare: (a: StatisticData, b: StatisticData) => a.categorie.localeCompare(b.categorie)
    },
    {
      title: 'Valeur',
      compare: (a: StatisticData, b: StatisticData) => a.value - b.value
    },
  ];


  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['statisticsData'] && changes['statisticsData'].previousValue != changes['statisticsData'].currentValue) {
      this.initTableData(this.statisticsData);
    }
    if (changes['displayMode'] && changes['displayMode'].previousValue != changes['displayMode'].currentValue) {
      this.updateListOfColum();
    }
  }


  updateListOfColum() {
    this.listOfColumn = [
      {
        title: 'Département',
        compare: (a: StatisticData, b: StatisticData) => a.categorie.localeCompare(b.categorie)
      },
      {
        title: 'Valeur',
        compare: (a: StatisticData, b: StatisticData) => a.value - b.value
      },
    ];
    if (this.displayMode == this.statisticModeEnum.YMINUSONE) {
      this.listOfColumn.push({
        title: 'Valeur N-1',
        compare: (a: StatisticData, b: StatisticData) => a.valueYMinusOne - b.valueYMinusOne
      })
      this.listOfColumn.push({
        title: 'Diff',
        compare: (a: StatisticData, b: StatisticData) => a.difference - b.difference
      })
    }
  }

  initTableData(data) {
    if (data !== undefined) {
      this.statisticsDataTable = [];
      this.totalStats = 0;
      this.totalStatsYMinusOne = 0;
      if (this.displayMode == this.statisticModeEnum.YMINUSONE) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].currentValue !== undefined && data[i].valueYMinusOne !== undefined) {
            const elementData: StatisticData = { 'categorie': data[i].name, 'value': data[i].currentValue, 'valueYMinusOne': data[i].valueYMinusOne, 'difference': data[i].value };
            this.statisticsDataTable.push(elementData);
            this.totalStats += data[i].currentValue;
            this.totalStatsYMinusOne += data[i].valueYMinusOne;
          }
        }
        this.difference = this.totalStats - this.totalStatsYMinusOne;
      } else {
        for (let i = 0; i < data.length; i++) {
          console.log(data[i]);
          const elementData: StatisticData = { 'categorie': data[i].name, 'value': data[i].value };
          this.statisticsDataTable.push(elementData);
          const valueFormatted = + parseFloat(data[i].value).toFixed(2);
          this.totalStats += valueFormatted;
        }
      }
    }
  }

  formatNumber(number) {
    if (number !== undefined) {
      return number.toLocaleString('fr-FR', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
    }
  }

  formatDiff(number) {
    if (number !== undefined) {
      if (number > 0) {
        return '<span class="span-value-up"><i class="fa fa-arrow-up mr-1"></i>' + this.formatNumber(number) + '</span>';
      } else if (number < 0) {
        return '<span class="span-value-down"><i class="fa fa-arrow-down mr-1"></i>' + this.formatNumber(number) + '</span>';
      } else {
        return '<span class="span-value-dash"><i class="fa fa-minus mr-1"></i>' + this.formatNumber(number) + '</span>';
      }
    }
  }

}
