import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MapChartService, StatisticInterventionRevenueService, StatisticInterventionVolume } from '@libs/services/src';
import { Subscription } from 'rxjs';
import * as echarts from 'echarts';
import { StatisticModeEnum } from '@libs/enum/src';

@Component({
  selector: 'app-statistics-revenues-map',
  templateUrl: './statistics-revenues-map.component.html',
  styleUrls: ['./statistics-revenues-map.component.scss']
})
export class StatisticsRevenuesMapComponent implements OnInit {
  @ViewChild('chart') chart: ElementRef;
  @Input() displayMode: number
  public options: any;
  public statisticsData: any;
  private statsInterventionsRevenueDepartments$: Subscription;

  constructor(
    private http: HttpClient,
    private statisticInterventionRevenueService: StatisticInterventionRevenueService,
    private mapChartService: MapChartService
  ) { }

  ngOnInit() {
    this.http.get('assets/data/france.json').subscribe(geoJson => {
      echarts.registerMap('FR', geoJson);
      this.statsInterventionsRevenueDepartments$ = this.statisticInterventionRevenueService.statsInterventionsRevenueDepartments$.subscribe(
        (data) => {
          if (data) {
            this.initMap(geoJson, data);

          }
        }
      )
    })
  }

  ngOnDestroy() {
    this.statsInterventionsRevenueDepartments$.unsubscribe();
  }

  private initMap(geoJson, data) {
    if (this.displayMode == StatisticModeEnum.YMINUSONE) {
      this.statisticsData = this.mapChartService.generateDataYMinusOneMode(geoJson, data.revenues, data.revenuesYMinusOne);
    } else {
      this.statisticsData = this.mapChartService.generateDataSimpleMode(geoJson, data);
    }
    this.options = this.mapChartService.getOptions(this.statisticsData, geoJson)
  }


}
