import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConformityService, DataTableColumn, DocumentCustomerService, DocumentDeliveredService, FieldService, MarketTypeService } from '@eros-front/services';
import Swal from 'sweetalert2';
import { InvoiceStatusService, ProductService, ThingService, SwalService } from '@eros-front/services';
import { Product } from '@eros-front/models';
import { AffairGoalService } from '@libs/services/src/lib/affair-goal.service';

@Component({
  selector: 'app-settings-products-products-index',
  templateUrl: './settings-products-products-index.component.html',
  styleUrls: ['./settings-products-products-index.component.scss']
})
export class SettingsProductsProductsIndexComponent implements OnInit, AfterViewInit, OnDestroy {

  public datatableSelector = 'products-datatable';
  public product: Product = null;
  public product$: Subscription;
  public invoicesStatuses$: Subscription;
  private documentsCustomers$: Subscription;
  private documentsDelivered$: Subscription;
  private fields$: Subscription;
  private conformities$: Subscription;
  public vatRates$: Subscription;
  public marketsTypes$: Subscription;
  private affairsGoals$: Subscription;
  public invoicesStatuses = [];
  public vatRates = [];
  public documentsCustomers = [];
  public documentsDelivered = [];
  public fields = [];
  public conformities = [];
  public marketsTypes = [];
  public affairsGoals = [];

  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'color',
      name: 'Couleur',
      orderable: false,
      searchable: false
    },
    {
      attr: 'marketsTypes',
      name: 'Type(s) de marché(s)',
      orderable: false,
      searchable: false
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'abbreviation',
      name: 'Abréviation'
    },
    {
      attr: 'active',
      name: 'Actif',
      orderable: false,
      searchable: false
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];

  constructor(
    private productService: ProductService,
    private invoiceStatusService: InvoiceStatusService,
    private thingService: ThingService,
    private swalService: SwalService,
    private documentCustomerService: DocumentCustomerService,
    private documentDeliveredService: DocumentDeliveredService,
    private fieldService: FieldService,
    private conformityService: ConformityService,
    private marketTypeService: MarketTypeService,
    private affairGoalService: AffairGoalService,
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.getValues();
  }

  ngAfterViewInit() {
    this.productService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    this.product$.unsubscribe();
    this.invoicesStatuses$.unsubscribe();
    this.vatRates$.unsubscribe();
    this.documentsCustomers$.unsubscribe();
    this.documentsDelivered$.unsubscribe();
    this.fields$.unsubscribe();
    this.conformities$.unsubscribe();
    this.affairsGoals$.unsubscribe();
  }

  onShowModalAddEditProduct() {
    this.productService.showModalAddEdit();
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.productService.get(id);
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le produit sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.productService.delete(id);
          }
        });
      }
    });
  }

  private getValues() {
    this.conformityService.getAll();
    this.invoiceStatusService.getAll();
    this.documentCustomerService.getAll();
    this.documentDeliveredService.getAll();
    this.fieldService.getAll();
    this.thingService.getVatRatesSelect();
    this.marketTypeService.getForSelect();
    this.affairGoalService.getAll();
  }

  private initSubscriptions() {
    this.product$ = this.productService.product$.subscribe(
      (product) => {
        if (product) {
          this.product = product;
          this.onShowModalAddEditProduct();
        }
      }
    );
    this.invoicesStatuses$ = this.invoiceStatusService.invoicesStatuses$.subscribe(
      (invoicesStatuses) => {
        if (invoicesStatuses) {
          this.invoicesStatuses = invoicesStatuses;
        }
      }
    );
    this.vatRates$ = this.thingService.vatRates$.subscribe(
      (vatRates) => {
        if (vatRates) {
          this.vatRates = vatRates;
        }
      }
    );
    this.documentsCustomers$ = this.documentCustomerService.documentsCustomers$.subscribe(
      (documentsCustomers) => {
        if (documentsCustomers) {
          this.documentsCustomers = documentsCustomers;
        }
      }
    );
    this.documentsDelivered$ = this.documentDeliveredService.documentsDelivered$.subscribe(
      (documentsDelivered) => {
        if (documentsDelivered) {
          this.documentsDelivered = documentsDelivered;
        }
      }
    );
    this.fields$ = this.fieldService.fields$.subscribe(
      (fields) => {
        if (fields) {
          this.fields = fields;
        }
      }
    );
    this.conformities$ = this.conformityService.conformities$.subscribe(
      (conformities) => {
        if (conformities) {
          this.conformities = conformities;
        }
      }
    )
    this.marketsTypes$ = this.marketTypeService.selectMarketType$.subscribe(
      (data) => {
        if (data) {
          this.marketsTypes = data;
        }
      }
    )
    this.affairsGoals$ = this.affairGoalService.getAll().subscribe(
      (data) => {
        this.affairsGoals = data;
      }
    )
  }

}
