import { Injectable } from '@angular/core';
import { variable } from '@angular/compiler/src/output/output_ast';

@Injectable()
export class ChartService {
    constructor() { }

    revenueTooltipFormatter(params, serieName = '') {
        let table = '<div style="position:relative"><h6 class="text-center">' + params[0].axisValue + '</h6><table class="table text-white">';
        table += '<thead class="thead-light">';
        table += `<tr><th>${serieName}</th><th>CA<th></tr></thead>`;
        table += '<tbody>';
        let total = 0.0;
        let objective = 0.0;

        params.forEach(({ marker, seriesName, value }) => {
            if (seriesName !== 'Objectif CA') {
                table += `<tr><th>${marker} ${seriesName}</th><th>${this.currencyFormatter(value)}</th></tr>`;
                total += parseFloat(value);
            } else {
                objective += parseFloat(value);
            }
        });

        table += '</tbody>' + `<tfoot><tr><th>TOTAL</th><th>${this.currencyFormatter(total)}</th></tr>`;
        if (objective > 0) {
            table += `<tr><th> Objectif</th><th>${this.currencyFormatter(objective)}</th></tr>`;
        }
        table += `</tfoot></table></div>`;
        return table;
    }

    volumeTooltipFormatter(params, serieName = '') {
        let table = '<div><h6 class="text-center">' + params[0].axisValue + '</h6><table class="table text-white">';
        table += '<thead class="thead-light">';
        table += `<tr><th>${serieName}</th><th>Volume<th></tr></thead>`;
        table += '<tbody>';
        let total = 0;


        params.forEach(({ marker, seriesName, value }) => {
            const intValue = parseInt(value, 10);
            if (intValue > 0) {
                table += `<tr><th>${marker} ${seriesName}</th><th>${value}</th></tr>`;
                total += intValue;
            }
        });
        table += '</tbody>' + `<tfoot><tr><th>TOTAL</th><th>${total}</th></tr>`;
        table += `</tfoot></table></div>`;
        return table;
    }


    currencyFormatter(data) {
        data = parseFloat(data);
        return data.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 2 });
    }

    addSeriesTotal(series: any[]) {
        if (series != null) {
            const lastElem = series[series.length - 1];
            lastElem.label = {
                emphasis: {
                    show: true,
                    position: 'top',
                    formatter: (params) => {
                        let total = 0.0;
                        series.forEach(serie => {
                            total += parseFloat(serie.data[params.dataIndex]);
                        });
                        // The return value will be shown on top of your stack
                        return 'Total : ' + total;
                    }
                }
            };
            series[series.length - 1] = lastElem;

            return series;
        }
    }

    addSeriesTotalCurrency(series: any[]) {
        if (series != null && series.length > 0) {
            for (let i = 0; i < series.length; i++) {
                const elem = series[i];
                elem.label = {
                    normal: {
                        formatter: (params) => {
                            return this.currencyFormatter(params.value);
                        },
                        show: true,
                        position: 'inside'
                    }
                };
                series[i] = elem;
            }
            return series;
        }
    }

    initYMinusOneVolumeOptions(data: any) {
        return {
            legend: {},
            tooltip: {},
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            dataset: data.dataset,
            xAxis: {
                type: 'category',
                axisLabel: {
                    rotate: 40,
                    interval: 0,
                },
            },
            yAxis: {},
            series: data.series
        };
    }

    initVolumeOptions(data: any) {
        return {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                enterable: true,
                renderMode: 'html',
                appendToBody: true,
                formatter: (params) => {
                    return this.volumeTooltipFormatter(params, 'Technicien');
                },
                extraCssText: 'z-index:1051;overflow:auto;height:400px;pointer-events:auto',
                position: function (point, params, dom, rect, size) {
                    return [point[0], '5%'];
                }
            },
            legend: {
                data: data.legend
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: data.categories,
                axisLabel: {
                    rotate: 40,
                    interval: 0,
                },
            },
            yAxis: {
                type: 'value'
            },
            series: data.series
        };
    }

    initRevenueOptions(data: any) {
        return {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                enterable: true,
                renderMode: 'html',
                appendToBody: true,
                formatter: (params) => {
                    return this.revenueTooltipFormatter(params, 'Secteur');
                },
                extraCssText: 'z-index:1051;overflow:auto;height:400px;pointer-events:auto',
                position: function (point, params, dom, rect, size) {
                    return [point[0], '5%'];
                }
            },
            legend: {
                data: data.legend
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: data.categories,
                axisLabel: {
                    rotate: 40,
                    interval: 0,
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: (value) => {
                        return this.currencyFormatter(value);
                    }
                },
            },
            series: data.series
        };
    }
}
