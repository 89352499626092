import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ExpenseType, SubmitButton } from '@libs/models/src';
import { NotifService } from '@libs/services/src';
import { ExpenseTypeService } from '@libs/services/src/lib/expense-type.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-expenses-types-modal',
  templateUrl: './expenses-types-modal.component.html',
  styleUrls: ['./expenses-types-modal.component.scss']
})
export class ExpensesTypesModalComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() expenseType: ExpenseType;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private expenseTypeService: ExpenseTypeService,
    private notifService: NotifService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.expenseTypeService.setSubmitButton(this.submitButton);
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.expenseType) {
        this.expenseTypeService.update(this.expenseType.id, this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      } else {
        this.expenseTypeService.store(this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.expenseTypeService.determinateSubmitButton(this.expenseType, 'le type de dépense');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
