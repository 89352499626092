import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SelectModel } from '@libs/models/src';
import { SwalService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { QuotationService } from '@libs/services/src/lib/quotation.service';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { Subscription } from 'rxjs';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-quotations-index',
  templateUrl: './quotations-index.component.html',
  styleUrls: ['./quotations-index.component.scss']
})
export class QuotationsIndexComponent implements OnInit {
  private filters$: Subscription;
  public form: FormGroup;
  public columns: DataTableColumn[] = [
    {
      attr: 'number',
      name: 'Numéro',
      individualFilterInput: true
    },
    {
      attr: 'dateFormatted',
      name: 'Date',
      searchColumn: 'date',
      individualFilterInput: true
    },
    {
      attr: 'dateValidityFormatted',
      name: 'Date de validité',
      individualFilterInput: true,
      orderable: false,
      searchable: false
    },
    {
      attr: 'payerName',
      name: 'Payeur',
      individualFilterInput: true
    },
    {
      attr: 'customerFormatted',
      name: 'Client',
      orderable: false,
      searchable: false
    },
    {
      attr: 'quotationDetailsFormatted',
      name: 'Details',
      orderable: false,
      searchable: false
    },
    {
      attr: 'quotationStatus',
      name: 'Statut',
      orderable: false,
      searchable: false
    },
    {
      attr: 'affairLastname',
      name: 'Nom d\'affaire',
      individualFilterInput: true
    },
    {
      attr: 'affairPostalCode',
      name: 'CP',
      individualFilterInput: true
    },
    {
      attr: 'affairLocality',
      name: 'Ville',
      individualFilterInput: true
    },
    {
      attr: 'totalAllTaxes',
      name: 'Total TTC'
    }
  ];
  public datatableSelector = 'quotations-datatable';
  private readonly storageKey = 'quotations-filters-form';
  public agencies: SelectModel[];
  public mlAgencies: SelectModel[];
  public products: SelectModel[];
  public quotationsStatuses: SelectModel[];

  constructor(
    private titleService: Title,
    private quotationService: QuotationService,
    private storageService: FormLocalStorageService,
    private swalService: SwalService,
    private router: Router,
  ) {
    this.titleService.setTitle(`Liste des devis${tabSuffix}`);
    this.initSubcriptions();
  }

  ngOnInit() {
    this.quotationService.getDataTableFilters();
    const sessionFormValue = this.storageService.retrieve(this.storageKey);
    this.form = this.quotationService.getFiltersForm(sessionFormValue);
    this.form.valueChanges.subscribe(() => {
      this.storageService.store(this.storageKey, this.form);
      this.quotationService.redrawDataTable(this.form);
    });
  }

  ngAfterViewInit() {
    this.quotationService.initDataTable(this.datatableSelector, this.columns, this.form);
  }

  onCreate() {
    this.router.navigateByUrl(`quotations/manual/create`);
  }

  resetForm() {
    const resetForm = this.quotationService.getFiltersForm();
    this.form.reset(resetForm.value);
  }

  private initSubcriptions() {
    this.filters$ = this.quotationService.filters$.subscribe(
      (data) => {
        if (data) {
          this.mlAgencies = data.mlAgencies;
          this.agencies = data.agencies;
          this.products = data.products;
          this.quotationsStatuses = data.quotationsStatuses;
        }
      }
    )

  }

}
