import { ApiService } from '@eros-front/api';
import { ModelWithDatatableAndModalCrud } from './classes/model-datatable-modal-crud';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { Router } from '@angular/router';
import { AffairGoal, AffairMonitoringType, OfficeFile } from '@libs/models/src';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { SelectService } from './utilities/select.service';
import { FormFormatterService } from './utilities/form-formatter.service';

@Injectable()
export class OfficeFileService extends ModelWithDatatableAndCrudObservable {

    public route = '/offices-files';

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private commonService: CommonService,
        private formFormatterService: FormFormatterService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route);
    }

    public getAll(): Observable<any> {
        return super.getAll(this.route);
    }

    public store(form: any): Observable<any> {
        return super.store(this.formatForm(form), this.route);
    }

    public update(id: number, form: any): Observable<any> {
        return super.update(id, this.formatForm(form), this.route);
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route);
    }

    public getForm(customerId: number, marketTypeId: number, officeFile?: OfficeFile) {
        const comment = officeFile ?
            this.commonService.valueToFormString(officeFile.comment) : this.commonService.getDefaultFormStringValue();
        const studiesOffices = officeFile ?
            officeFile.studiesOffices.map(x => ({ value: x.id, label: x.name })) : [];
        return this.formBuilder.group({
            marketTypeId: [marketTypeId],
            agencyId: [customerId],
            comment: [comment],
            officesFilesSettings: new FormArray([]),
            officesFilesIndicators: new FormArray([]),
            studiesOffices: [studiesOffices]
        });
    }

    public initOfficesFilesSettings(form: FormGroup, sendSettings: any, sendSettingsTypes: any, contacts: any, officeFile?: OfficeFile) {
        const sendSettingsFormArray = form.get('officesFilesSettings') as FormArray;
        sendSettings.forEach((sendSetting) => {
            let found = false;
            let officeFileSetting = null;
            let officesFilesRecipients = [];
            let officesFilesRecipientsCopy = [];
            let isAutomaticSendChecked = false;

            if (officeFile) {
                found = officeFile.officesFilesSettings.find(x => x.sendSettingId == sendSetting.id);
            }
            if (found) {
                officeFileSetting = found;
                officesFilesRecipients = contacts.filter(x => officeFileSetting.officesFilesRecipients.map(x => x.id).includes(x.value));
                officesFilesRecipientsCopy = contacts.filter(x => officeFileSetting.officesFilesRecipientsCopy.map(x => x.id).includes(x.value));
                isAutomaticSendChecked = officeFileSetting.isAutomaticSend;
            }
            sendSettingsFormArray.push(new FormGroup({
                sendSettingId: new FormControl(sendSetting.id),
                sendSettingsTypes: this.initSendSettingsTypes(sendSettingsTypes, officeFileSetting),
                sendSettingsTypesCopy: this.initSendSettingsTypesCopy(sendSettingsTypes, officeFileSetting),
                isAutomaticSend: new FormControl(isAutomaticSendChecked),
                officesFilesRecipients: new FormControl(officesFilesRecipients),
                officesFilesRecipientsCopy: new FormControl(officesFilesRecipientsCopy),
            }))
        });
    }

    public initSendSettingsTypes(sendSettingsTypes: any, officeFileSetting?: any): FormArray {
        const formArray = new FormArray([]);
        sendSettingsTypes.forEach(sendSettingType => {
            let checkedValue = false;
            if (officeFileSetting) {
                if (officeFileSetting.sendSettingsTypes) {
                    let found = officeFileSetting.sendSettingsTypes.map(x => x.id).includes(sendSettingType.id);
                    if (found) {
                        checkedValue = true;
                    }
                }
            }
            formArray.push(new FormGroup({
                sendSettingTypeId: new FormControl(sendSettingType.id),
                checked: new FormControl(checkedValue),
            }))
        });
        return formArray
    }

    public initSendSettingsTypesCopy(sendSettingsTypes: any, officeFileSetting?: any): FormArray {
        const formArray = new FormArray([]);
        sendSettingsTypes.forEach(sendSettingType => {
            let checkedValue = false;
            if (officeFileSetting) {
                if (officeFileSetting.sendSettingsTypesCopy.length > 0) {
                    let found = officeFileSetting.sendSettingsTypesCopy.map(x => x.id).includes(sendSettingType.id);
                    if (found) {
                        checkedValue = true;
                    }
                }
            }
            formArray.push(new FormGroup({
                sendSettingTypeId: new FormControl(sendSettingType.id),
                checked: new FormControl(checkedValue),
            }))
        });
        return formArray
    }

    public initOfficesFilesIndicatorsSwitches(form: FormGroup, officesFilesIndicators: any, officeFile?: OfficeFile) {
        officesFilesIndicators.forEach((indicator) => {
            let checked = false;
            if (officeFile != null) {
                let found = officeFile.officesFilesIndicators.find(x => x.id == indicator.id)
                if (found) {
                    checked = found.pivot.value;
                }
            }
            const officesFilesIndicatorsFormArray = form.get('officesFilesIndicators') as FormArray;
            officesFilesIndicatorsFormArray.push(new FormGroup({
                checked: new FormControl(checked),
                officeFileIndicatorId: new FormControl(indicator.id),
            }))
        });
    }

    public refreshAffairs(officeFileId:number, agencyId: number): Observable<any> {
        return this.apiService.get(`${this.route}/${officeFileId}/agency/${agencyId}/refresh-affairs`);
    }

    private formatForm(form: any) {
        const values = this.formFormatterService.createFormCopy(form);
        values.officesFilesSettings.forEach(value => {
            value.sendSettingsTypes = value.sendSettingsTypes.filter(v => v.checked).length > 0 ?
                value.sendSettingsTypes.filter(v => v.checked).map(s => s.sendSettingTypeId) : [];
            value.sendSettingsTypesCopy = value.sendSettingsTypesCopy.filter(v => v.checked).length > 0 ?
                value.sendSettingsTypesCopy.filter(v => v.checked).map(s => s.sendSettingTypeId) : [];
            value.officesFilesRecipients = this.formFormatterService.formatSelectMultipleToIntArray(value.officesFilesRecipients);
            value.officesFilesRecipientsCopy = this.formFormatterService.formatSelectMultipleToIntArray(value.officesFilesRecipientsCopy);
        });
        values.studiesOffices = this.formFormatterService.formatSelectMultipleToIntArray(values.studiesOffices);
        return values;
    }
}
