import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from './utilities/common.service';
import { catchError, finalize } from 'rxjs/operators';
import { DateUtilsService } from './utilities/date-utils.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { ModelWithDatatableAndModalCrud } from './classes/model-datatable-modal-crud';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DataTableColumn } from './classes/model-datatable';
import { Affair, Event } from '@libs/models/src';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';

interface EventDatatableParams {
    selectedAssistants: any;
    selectedUsers: any;
    selectedEventsTypes: any;
    dateMin: any;
    dateMax: any;
    selectedAffair: number;
    isClosed: boolean;
    page: number;
    size: number;
    search: string;
}


@Injectable()
export class EventService extends ModelWithDatatableAndCrudObservable {

    private route = '/events';
    public event$ = new BehaviorSubject<any>(undefined);
    public events$ = new BehaviorSubject<any>(undefined);

    constructor(
        public notifService: NotifService,
        public submitButtonService: SubmitButtonService,
        public swalService: SwalService,
        public apiService: ApiService,
        public http: HttpClient,
        public router: Router,
        private formBuilder: FormBuilder,
        private commonService: CommonService,
        private dateUtilsService: DateUtilsService,
        private formFormatterService: FormFormatterService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[], form?: FormGroup): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        }, this.formToDatatableParameters(form));
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route)
    }

    public store(form: any): Observable<any> {
        return super.store(this.formatForm(form), this.route);
    }

    public storeForAffair(affairId: number, form: any): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.post(this.route + '/affair/' + affairId, this.formatForm(form))
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )

    }

    public update(id: number, form: any): Observable<any> {
        return super.update(id, this.formatForm(form), this.route);
    }

    public updateForAffair(eventId: number, form: any): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.put(this.route + '/' + eventId + '/affair', this.formatForm(form))
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
    }

    public delete(id: number): Observable<any> {
        return this.apiService.delete(this.route + '/' + id)
    }

    public redrawDataTable(form: any): void {
        return super.redrawDataTable(this.formToDatatableParameters(form))
    }

    public updateDate(id: number, startDate: Date, endDate: Date) {
        const duration = this.dateUtilsService.calcTimeDifference(startDate, endDate);
        this.apiService.put(this.route + '/' + id + '/update-date', {
            startDate: startDate.toString(),
            duration: duration.toTimeString()
        })
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            )
            .subscribe(
                (success) => {
                    this.notifService.showSuccessNotif(success);
                }
            );
    }

    public getForCalendar(form: any, users: any): void {
        const usersIds = JSON.stringify(form.value.users.map((v, i) => v ? users[i].id : null).filter(v => v !== null));
        this.apiService.post(`${this.route}/get-for-calendar`, {
            dateFrom: form.value.dateFrom,
            dateTo: form.value.dateTo,
            users: usersIds,
        })
            .subscribe(
                (events: any) => {
                    this.events$.next(events);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public getForAffair(form: any): Observable<any> {
        return this.apiService.post(this.route + '/get-for-affair', this.formatAffairListForm(form))
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            );
    }

    public initList(form): Observable<any> {
        return this.apiService.post(this.route + '/list', this.formToDatatableParameters(form))
    }

    public getGeneratedDescription(form: any): Observable<any> {
        return this.apiService.post(this.route + '/get-generated-description', this.formatGeneratedDescriptionForm(form))
    }


    public getForm(event?: any, eventAdd?: any, users?: any, usersSelected?: any) {
        const name = event ?
            this.commonService.valueToFormString(event.name) : this.commonService.getDefaultFormStringValue();
        const description = event ?
            this.commonService.valueToFormString(event.description) : this.commonService.getDefaultFormStringValue();
        const startDate = event ?
            this.commonService.valueToFormDate(event.startDate) : eventAdd.startDate;
        const duration = event ?
            this.commonService.valueToFormTime(event.duration) : eventAdd.duration;
        const address = event ?
            this.commonService.valueToFormString(event.address) : this.commonService.getDefaultFormStringValue();
        const postalCode = event ?
            this.commonService.valueToFormString(event.postalCode) : this.commonService.getDefaultFormStringValue();
        const locality = event ?
            this.commonService.valueToFormString(event.locality) : this.commonService.getDefaultFormStringValue();
        const isAllDay = event ?
            this.commonService.valueToCheckboxFormNumber(event.isAllDay) : this.commonService.getDefaultFormCheckboxValue();
        let usersIds = [];
        if (event) {
            usersIds = users.filter(x => event.users.map(x => x.id).includes(x.value));
        } else if (usersSelected) {
            usersIds = users.filter(x => usersSelected.map(x => x.id).includes(x.value))
        }
        const eventTypeId = event ?
            this.commonService.valueToFormSelect(event.eventTypeId) : this.commonService.getDefaultFormNullValue();

        return this.formBuilder.group({
            usersIds: [usersIds, Validators.required],
            eventTypeId: [eventTypeId],
            name: [name, Validators.required],
            description: [description],
            startDate: [startDate, Validators.required],
            duration: [duration, Validators.required],
            address: address,
            postalCode: postalCode,
            locality: locality,
            isAllDay: isAllDay,
        });
    }

    public getFormForAffair(affair: any, event?: any, users?: any) {
        const name = event ?
            this.commonService.valueToFormString(event.name) : this.commonService.getDefaultFormStringValue();
        const description = event ?
            this.commonService.valueToFormString(event.description) : this.commonService.getDefaultFormStringValue();
        const startDate = event ?
            this.commonService.valueToFormDate(event.startDate) : this.commonService.getDefaultFormNullValue();
        const duration = event ?
            this.commonService.valueToFormTime(event.duration) : this.commonService.getDefaultFormNullValue();
        const isAllDay = event ?
            this.commonService.valueToCheckboxFormNumber(event.isAllDay) : this.commonService.getDefaultFormCheckboxValue();
        const isClosed = event ?
            this.commonService.valueToCheckboxFormNumber(event.isClosed) : this.commonService.getDefaultFormCheckboxValue();
        const usersIds = event ?
            users.filter(x => event.users.map(x => x.id).includes(x.value)) : [];
        const eventTypeId = event ?
            this.commonService.valueToFormSelect(event.eventTypeId) : this.commonService.getDefaultFormNullValue();
        const buildings = event ?
            this.commonService.valueToFormSelectMultiple(event.buildings) : this.commonService.getDefaultFormNullValue();

        return this.formBuilder.group({
            affairId: affair.id,
            usersIds: [usersIds, Validators.required],
            buildings: [buildings],
            eventTypeId: [eventTypeId, Validators.required],
            name: [name, Validators.required],
            description: [description],
            startDate: [startDate, Validators.required],
            duration: [duration, Validators.required],
            isAllDay: isAllDay,
            isClosed: isClosed,
        });
    }

    getFiltersForm(formValue?: any) {
        const selectedAssistants = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAssistants) : this.commonService.getDefaultFormNullValue();
        const selectedUsers = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedUsers) : this.commonService.getDefaultFormNullValue();
        const selectedEventsTypes = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedEventsTypes) : this.commonService.getDefaultFormNullValue();
        const dateMin = formValue ?
            this.commonService.valueToFormDate(formValue.dateMin) : this.commonService.getDefaultFormNullValue();
        const dateMax = formValue ?
            this.commonService.valueToFormDate(formValue.dateMin) : this.commonService.getDefaultFormNullValue();
        const isClosed = formValue ?
            formValue.isClosed : false;
        const size = formValue ?
            formValue.size : this.commonService.getDefaultPageSizeValue();
        const page = formValue ?
            formValue.page : this.commonService.getDefaultPageIndexValue();
        const search = formValue ?
            formValue.search : this.commonService.getDefaultFormStringValue();

        return this.formBuilder.group({
            selectedAssistants: [selectedAssistants],
            selectedUsers: [selectedUsers],
            selectedEventsTypes: [selectedEventsTypes],
            dateMin: [dateMin],
            dateMax: [dateMax],
            isClosed: [isClosed],
            size: [size],
            page: [page],
            search: [search]
        });
    }

    private formToDatatableParameters(form: FormGroup): EventDatatableParams {
        if (form) {
            return {
                selectedAffair: form.value.selectedAffair ? form.value.selectedAffair : null,
                selectedAssistants: this.formFormatterService.formatSelectMultiple(form.value.selectedAssistants),
                selectedUsers: this.formFormatterService.formatSelectMultiple(form.value.selectedUsers),
                selectedEventsTypes: this.formFormatterService.formatSelectMultiple(form.value.selectedEventsTypes),
                dateMin: form.value.dateMin ? new Date(form.value.dateMin).toJSON() : null,
                dateMax: form.value.dateMax ? new Date(form.value.dateMax).toJSON() : null,
                isClosed: form.value.isClosed,
                page: form.value.page,
                size: form.value.size,
                search: form.value.search
            };
        }
        return null;
    }

    private formatForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.usersIds = this.formFormatterService.formatSelectMultipleToIntArray(values.usersIds);
        values.buildings = values.buildings ? this.formFormatterService.formatSelectMultipleToIntArray(values.buildings) : [];
        values.startDate = values.startDate ? new Date(values.startDate).toString() : null;
        values.duration = values.duration ? new Date(values.duration).toTimeString() : null;
        return values;
    }

    private formatAffairListForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        return values;
    }

    private formatGeneratedDescriptionForm(form: any) {
        const values = this.formFormatterService.createFormCopy(form);
        values.buildings = this.formFormatterService.formatSelectMultipleToIntArray(values.buildings);
        return values;
    }
}
