import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Agency, Payer } from '@libs/models/src';
import { AgencyService, CommonService, FormFormatterService, PayerService, ThingService } from '@libs/services/src';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Subscription } from 'rxjs';

@Component({
  selector: 'payer-informations-form',
  templateUrl: './payer-informations-form.component.html',
  styleUrls: ['./payer-informations-form.component.scss']
})
export class PayerInformationsFormComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() isQuotation: boolean;
  private agency$: Subscription;
  private agencies$: Subscription;
  private payer$: Subscription;
  private payers$: Subscription;
  private payersPreferences$: Subscription;
  public agencies;
  public payers;
  public payersPreferences;

  constructor(
    private commonService: CommonService,
    private agencyService: AgencyService,
    private payerService: PayerService,
    private formFormatterService: FormFormatterService,
    private thingService: ThingService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.getValues();
  }

  ngOnDestroy(): void {
    this.agency$.unsubscribe();
    this.payers$.unsubscribe();
    this.payersPreferences$.unsubscribe();
  }

  private getValues() {
    this.agencyService.getForSelect();
    this.payerService.getForSelect();
    this.thingService.getPayersPreferences();
  }

  /**
   * InvoicingInformations
   */
  onSelectedAgencyChange(value: any) {
    if (value && value.value) {
      this.agencyService.get(value.value);
      this.form.patchValue({
        payerId: this.commonService.getDefaultFormNullValue()
      });
    } else {
      this.agencyService.agency$.next(null);
      this.completePayerInformation(null);
    }
  }

  onSelectedPayerChange(value: any) {
    if (value && value.id) {
      this.payerService.get(value.id);
      this.form.patchValue({
        agencyId: this.commonService.getDefaultFormNullValue()
      });
    } else {
      this.payerService.payer$.next(null);
      this.completePayerInformation(null);
    }
  }

  onPayerAddressChange(address: Address) {
    this.form = this.formFormatterService.completeGoogleAddress(
      this.form, address,
      {
        route: 'payerAddress',
        postal_code: 'payerPostalCode',
        locality: 'payerLocality',
        concatStreetWithRoute: true
      }
    );
  }

  private completePayerInformation(entity?: Agency | Payer): void {
    const defaultString = this.commonService.getDefaultFormStringValue();
    const defaultPayerPreference = this.commonService.getDefaultFormStringValue();
    if (this.form) {
      this.form.patchValue({
        payerName: entity ? entity.name : defaultString,
        accountingCode: entity ? entity.accountingCode : defaultString,
        payerAddress: entity ? entity.address : defaultString,
        payerPreference: entity ? entity.payerPreference : defaultPayerPreference,
        payerAdditionalAddress: entity ? entity.additionalAddress : defaultString,
        payerPostalCode: entity ? entity.postalCode : defaultString,
        payerLocality: entity ? entity.locality : defaultString
      });
    }
  }

  private initSubscriptions() {
    this.agencies$ = this.agencyService.agenciesSelect$.subscribe(
      (data) => {
        if (data) {
          this.agencies = data;
        }
      }
    )
    this.payers$ = this.payerService.selectPayers$.subscribe(
      (data) => {
        if (data) {
          this.payers = data;
        }
      }
    )
    this.payersPreferences$ = this.thingService.payersPreferences$.subscribe(
      (data) => {
        if (data) {
          this.payersPreferences = data;
        }
      }
    )
    this.agency$ = this.agencyService.agency$.subscribe(
      (data) => {
        if (data) {
          this.completePayerInformation(data);
        }
      }
    );
    this.payer$ = this.payerService.payer$.subscribe(
      (data) => {
        if (data) {
          this.completePayerInformation(data);
        }
      }
    );

  }

}
