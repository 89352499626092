// Angular Core
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

// Layout Service - Required
import { pagesToggleService } from '@pages/services/toggler.service';

// Configs
import { API_URL } from './authentification.shared';

// Pages Modules
import { SharedModule as pgSharedModule } from '@pages/components/shared.module';

// Services
import { AuthentificationService } from './authentification.service';
import { AuthGuardService } from './auth-guard.service';

// Components
import { LoginComponent } from './login/login.component';
import { RedirectComponent } from './redirect/redirect.component';

@NgModule({
  declarations: [LoginComponent, RedirectComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    pgSharedModule
  ],
  exports: [
    LoginComponent,
    RedirectComponent,
  ],
  providers: [
    AuthentificationService,
    AuthGuardService,
    pagesToggleService
  ]
})

export class AuthModule {
  static forRoot(host: string) {
    return {
      ngModule: AuthModule,
      providers: [{
        provide: API_URL,
        useValue: host
      }]
    }
  }
}
