import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubmitButton } from '@libs/models/src';
import { SettingService, ThingService } from '@libs/services/src';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-customer-relationship-settings-modal',
  templateUrl: './customer-relationship-settings-modal.component.html',
  styleUrls: ['./customer-relationship-settings-modal.component.scss']
})
export class CustomerRelationshipSettingsModalComponent implements OnInit {
  @Input() setting: any;
  @Input() availableEntitiesClasses: any;
  @ViewChild('settingsAddEditSettingCustomerRelationship', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public items: any[];
  public entityClassesValues$: Subscription;
  public submitButton: SubmitButton;
  public fieldTypeValue = 'text';
  private settingTypeKey = 'customer-relationship';

  constructor(
    private settingService: SettingService,
    private thingService: ThingService
  ) { }

  ngOnInit() {
    this.initValues();
    this.entityClassesValues$ = this.thingService.entitiesClassesValues$.subscribe(
      (entityClassesValues) => {
        if (entityClassesValues) {
          this.items = entityClassesValues;
        }
      }
    );
  }

  ngAfterViewInit() {
    this.settingService.setModalAddEdit(this.childModal);
  }

  ngOnDestroy() {
    this.entityClassesValues$.unsubscribe();
  }

  onModalShown() {
    this.determinateSubmitButton();
    this.form = this.settingService.getForm(this.setting, this.settingTypeKey);
    if (this.setting) {
      this.fieldTypeValue = this.setting.fieldType;
      this.thingService.getEntitiesClassesValues(this.setting.entityClass);
    }
    this.onChanges();
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(val => {
      this.fieldTypeValue = val.fieldType;
      if (val.entityClass !== '') {
        this.thingService.getEntitiesClassesValues(val.entityClass);
      }
    });
  }

  onModalHidden() {
    this.initValues();
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.setting) {
        this.settingService.update(this.setting.id, this.form);
      } else {
        this.settingService.store(this.form);
      }
    }
  }

  private initValues() {
    this.setting = null;
    this.form = null;
  }

  private determinateSubmitButton() {
    this.submitButton = this.settingService.determinateSubmitButton(this.setting, 'le paramètre');
  }

  private isValidForm(): boolean {
    return this.form.valid;
  }
}
