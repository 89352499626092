import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { SubmitButton } from '@libs/models/src';
import { ShuttleSheetBuildingItemSelect, ShuttleSheetService, SubmitButtonService } from '@libs/services/src';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-shuttle-sheet-modal-item',
  templateUrl: './shuttle-sheet-modal-item.component.html',
  styleUrls: ['./shuttle-sheet-modal-item.component.scss']
})
export class ShuttleSheetModalItemComponent implements OnInit, AfterViewInit {

  @Input() model: any;
  @Input() item: ShuttleSheetBuildingItemSelect;
  @ViewChild('ShuttleSheetModalItem', { static: false }) childModal: ModalDirective;
  public itemCopy: ShuttleSheetBuildingItemSelect = null;
  public submitButton: SubmitButton;

  constructor(
    private shuttleService: ShuttleSheetService,
    private submitButtonService: SubmitButtonService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.shuttleService.setModalItem(this.childModal);
  }

  onModalShown() {
    this.itemCopy = JSON.parse(JSON.stringify(this.item));
    this.determinateSubmitButton();
    this.shuttleService.setItemSubmitButton(this.submitButton);
  }

  onModalHidden() {
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm()) {
      let found = false;
      let modelCopy = JSON.parse(JSON.stringify(this.model));
      for (let i = 0; i < modelCopy.buildings.length; i++) {
        for (let j = 0; j < modelCopy.buildings[i].itemsSelect.length; j++) {
          if (modelCopy.buildings[i].itemsSelect[j].key === this.itemCopy.key) {
            modelCopy.buildings[i].itemsSelect[j] = { ...this.itemCopy };
            found = true;
            break;
          }
        }
        if (found) {
          break;
        }
      }
      this.shuttleService.updateBaseModel(modelCopy);
    }
  }

  isValidForm() {
    return this.itemCopy != null;
  }

  private determinateSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      text: 'Mettre à jour',
      objectName: 'l\'item',
      icon: faSave
    });
  }

}
