import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@libs/api/src';
import { SubmitButton } from '@libs/models/src';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { CommonService } from './utilities/common.service';
import { DateUtilsService } from './utilities/date-utils.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';

@Injectable()
export class StatisticInterventionService {

    private route = '/stats/interventions';
    public statsInterventionsFilters$ = new BehaviorSubject<any>(undefined);

    constructor(
        private apiService: ApiService,
        private notifService: NotifService,
        private formBuilder: FormBuilder,
        private swalService: SwalService,
        private submitButtonService: SubmitButtonService,
        private formFormattedService: FormFormatterService,
        private commonService: CommonService,
        private dateUtilsService: DateUtilsService
    ) { }

    getFilters() {
        this.apiService.get(this.route + '/filters').subscribe(
            (result) => {
                this.statsInterventionsFilters$.next(result);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );

    }

    formToParams(form: FormGroup) {
        return {
            'objectsIds': form.value.isRegion ?
                this.formFormattedService.formatSelectMultiple(form.value.objectsIds) : this.formFormattedService.formatSelectMultiple(form.value.mlAgenciesIds),
            'techniciansIds': this.formFormattedService.formatSelectMultiple(form.value.techniciansIds),
            'productsIds': this.formFormattedService.formatSelectMultiple(form.value.productsIds),
            'dateFrom': this.dateUtilsService.getDateFromFormatted(form.value.dateRange[0], form.value.dateDisplayMode),
            'dateTo': this.dateUtilsService.getDateToFormatted(form.value.dateRange[1], form.value.dateDisplayMode),
            'isRegion': form.value.isRegion,
            'dateDisplayMode': form.value.dateDisplayMode,
            'displayMode': form.value.displayMode,
        }

    }

    initForm(sessionForm?: any) {
        const marketsTypesIds = sessionForm ?
            sessionForm.marketsTypesIds : this.commonService.getDefaultFormNullValue();
        const techniciansIds = sessionForm ?
            sessionForm.techniciansIds : this.commonService.getDefaultFormNullValue();
        const productsIds = sessionForm ?
            sessionForm.productsIds : this.commonService.getDefaultFormNullValue();
        const objectsIds = sessionForm ?
            sessionForm.objectsIds : this.commonService.getDefaultFormNullValue();
        const mlAgenciesIds = sessionForm ?
            sessionForm.mlAgenciesIds : this.commonService.getDefaultFormNullValue();
        const dateRange = sessionForm ?
            sessionForm.dateRange : [new Date(), new Date()];
        const dateDisplayMode = sessionForm ?
            sessionForm.dateDisplayMode : 1;
        const displayMode = sessionForm ?
            sessionForm.displayMode : 0;

        return this.formBuilder.group({
            marketsTypesIds: [marketsTypesIds],
            techniciansIds: [techniciansIds],
            productsIds: [productsIds, Validators.required],
            objectsIds: [objectsIds, Validators.required],
            mlAgenciesIds: [mlAgenciesIds],
            dateRange: [dateRange, Validators.required],
            dateDisplayMode: [dateDisplayMode],
            displayMode: [displayMode],
            isRegion: [true],
        });
    }
}
