import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SendSetting, SubmitButton } from '@libs/models/src';
import { SendSettingService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-send-settings-modal',
  templateUrl: './send-settings-modal.component.html',
  styleUrls: ['./send-settings-modal.component.scss']
})
export class SendSettingsModalComponent implements OnInit {
  @Input() sendSetting: SendSetting;
  @Input() standardsResponses: any;
  @Input() form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private sendSettingService: SendSettingService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.sendSettingService.setSubmitButton(this.submitButton);
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.sendSetting) {
        this.sendSettingService.update(this.sendSetting.id, this.form).subscribe(() => {
          this.modal.close();
        });
      } else {
        this.sendSettingService.store(this.form).subscribe(() => {
          this.modal.close();
        });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.sendSettingService.determinateSubmitButton(this.sendSetting, 'le paramètre d\'envoi');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
