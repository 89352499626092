export class AffairPayerPreference {

  static readonly APPLICANT = new AffairPayerPreference(0, 'Demandeur');
  static readonly CLIENT = new AffairPayerPreference(1, 'Maître d’ouvrage');
  static readonly OTHER = new AffairPayerPreference(2, 'Autre société');

  private constructor(
    private readonly key: number,
    public readonly text: string
  ) {}

  toString() {
    return this.key;
  }

}
