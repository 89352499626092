import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-settings-products-products-modal-informations',
  templateUrl: './settings-products-products-modal-informations.component.html',
  styleUrls: ['./settings-products-products-modal-informations.component.scss']
})
export class SettingsProductsProductsModalInformationsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() vatRates: any;
  @Input() marketsTypes: any;
  @Input() affairsGoals: any;

  constructor() { }

  ngOnInit() {
  }

  get getFormColor() {
    return this.form.value.color;
  }

}
