// Angular Core
import { NgModule } from '@angular/core';

// Libs
import { SharedModule } from '@eros-front/shared';

// Components
import { PayersModalAddComponent } from './payers-modal-add/payers-modal-add.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    PayersModalAddComponent
  ],
  exports: [
    PayersModalAddComponent
  ]
})
export class PayersModule { }
