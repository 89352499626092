import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-customer-form-informations',
  templateUrl: './customer-form-informations.component.html',
  styleUrls: ['./customer-form-informations.component.scss']
})
export class CustomerFormInformationsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() marketsTypes: any[];
  @Input() customersTypes: any[];
  @Input() indicatorsCustomers: any[];
  @Input() mlSocieties: any[];
  @Input() mlAgencies: any[];
  @Input() assistants: any[];
  @Input() technicians: any[];
  @Input() isAgency: boolean;
  @Input() keyPrefix: string = "";

  constructor() { }

  ngOnInit() {
  }

}
