import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { SubmitButton } from '@libs/models/src';
import { CommonService, NotifService, SubmitButtonService } from '@libs/services/src';
import { InvoiceService } from '@libs/services/src/lib/invoice.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-affairs-products-to-invoicing-select-tempo-modal',
  templateUrl: './affairs-products-to-invoicing-select-tempo-modal.component.html',
  styleUrls: ['./affairs-products-to-invoicing-select-tempo-modal.component.scss']
})
export class AffairsProductsToInvoicingSelectTempoModalComponent implements OnInit, OnDestroy {
  @Input() productKey: string;
  @Input() affairProducts: any[];
  private invoicesTempoByProduct$: Subscription;
  public invoicesTemporaries: any[];
  public form: FormGroup;
  public submitButton: SubmitButton

  constructor(
    private modal: NzModalRef,
    private invoiceService: InvoiceService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private submitButtonService: SubmitButtonService,
    private notifService: NotifService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.invoiceService.getTemporaryByProduct(this.productKey);
    this.form = this.initForm();
    this.setSubmitButton();
  }

  ngOnDestroy() {
    this.invoicesTempoByProduct$.unsubscribe();
  }

  initForm(): FormGroup {
    const defaultSelect = this.commonService.getDefaultFormNullValue();
    return this.formBuilder.group({
      invoiceId: [defaultSelect, Validators.required],
      affairProducts: [this.affairProducts, Validators.required],
    });
  }

  submitForm() {
    if (this.isValidForm()) {
      this.invoiceService.attachAffairProducts(this.form).subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        }
      )
    }
  }


  isValidForm() {
    return this.form.valid;
  }

  private initSubscriptions() {
    this.invoicesTempoByProduct$ = this.invoiceService.invoicesTempoByProduct$.subscribe(
      (data) => {
        if (data) {
          this.invoicesTemporaries = data;
        }
      }
    )
  }

  private setSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'à la facture sélectionnée',
      text: 'Affilier',
      icon: faCreditCard
    });
    this.invoiceService.setSubmitButton(this.submitButton);
  }



}
