import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiService } from '@libs/api/src';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { StatisticService } from './statistic.service';
import { NotifService } from './utilities/notif.service';

@Injectable()
export class StatisticVolumeService {

    private route = '/stats/volume';
    public statsRegions$ = new BehaviorSubject<any>(undefined);
    public statsTechnicians$ = new BehaviorSubject<any>(undefined);
    public statsProducts$ = new BehaviorSubject<any>(undefined);
    public statsDepartments$ = new BehaviorSubject<any>(undefined);

    constructor(
        private apiService: ApiService,
        private notifService: NotifService,
        private statisticService: StatisticService,
        private spinner: NgxSpinnerService
    ) { }

    getStatsRegions(form: FormGroup) {
        const params = this.statisticService.formToParams(form);
        this.spinner.show('statistic-volume-load');
        this.apiService.post(this.route + '/regions', params).subscribe(
            (result) => {
                this.spinner.hide('statistic-volume-load');
                this.statsRegions$.next(result);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    getStatsTechnicians(form: FormGroup) {
        const params = this.statisticService.formToParams(form);
        this.spinner.show('statistic-volume-load');
        this.apiService.post(this.route + '/technicians', params).subscribe(
            (result) => {
                this.spinner.hide('statistic-volume-load');
                this.statsTechnicians$.next(result);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    getStatsProducts(form: FormGroup) {
        const params = this.statisticService.formToParams(form);
        this.spinner.show('statistic-volume-load');
        this.apiService.post(this.route + '/products', params).subscribe(
            (result) => {
                this.spinner.hide('statistic-volume-load');
                this.statsProducts$.next(result);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    getStatsDeparments(form: FormGroup) {
        const params = this.statisticService.formToParams(form);
        this.apiService.post(this.route + '/departments', params).subscribe(
            (result) => {
                this.statsDepartments$.next(result);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }


}
