import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { SubmitButton } from '@libs/models/src';
import { AgencyService, BrandService, FormFormatterService, GroupService, SubmitButtonService } from '@libs/services/src';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { CustomersCreateAgencyModalComponent } from '../../customers-modals/customers-create-agency-modal/customers-create-agency-modal.component';
import { CustomersCreateBrandModalComponent } from '../../customers-modals/customers-create-brand-modal/customers-create-brand-modal.component';

@Component({
  selector: 'app-customers-show-informations',
  templateUrl: './customers-show-informations.component.html',
  styleUrls: ['./customers-show-informations.component.scss']
})
export class CustomersShowInformationsComponent implements OnInit {

  @Input() customer: any;
  @Input() isGroup: boolean;
  @Input() isBrand: boolean;
  @Input() isAgency: boolean;
  @Input() marketsTypes: any[];
  @Input() customersTypes: any[];
  @Input() indicatorsCustomers: any[];
  @Input() mlAgencies: any[];
  @Input() assistants: any[];
  @Input() technicians: any[];
  @Input() mlSocieties: any[];
  @Input() informationsForm: FormGroup;
  @Output() saveClicked = new EventEmitter();
  public submitButton: SubmitButton;

  constructor(
    private submitButtonService: SubmitButtonService,
    private groupService: GroupService,
    private brandService: BrandService,
    private agencyService: AgencyService,
    private modalService: NzModalService
  ) { }

  ngOnInit() {
    this.determineSubmitButton();
  }

  isValidForm() {
    return this.informationsForm && this.informationsForm.valid && this.informationsForm.dirty;
  }

  onSaveClicked() {
    if (this.isValidForm()) {
      this.saveClicked.emit(this.informationsForm);
    }
  }

  onAddBrandModal() {
    const modalInstance = this.modalService.create({
      nzContent: CustomersCreateBrandModalComponent,
      nzComponentParams: {
        group: this.customer,
        marketsTypes: this.marketsTypes,
        customersTypes: this.customersTypes,
        indicatorsCustomers: this.indicatorsCustomers,
        mlSocieties: this.mlSocieties
      },
      nzStyle: { top: '2vh' }
    });
  }

  onAddAgencyModal() {
    const modalInstance = this.modalService.create({
      nzContent: CustomersCreateAgencyModalComponent,
      nzComponentParams: {
        brand: this.customer,
        marketsTypes: this.marketsTypes,
        customersTypes: this.customersTypes,
        indicatorsCustomers: this.indicatorsCustomers,
        mlSocieties: this.mlSocieties,
        mlAgencies: this.mlAgencies,
        assistants: this.assistants,
        technicians: this.technicians
      },
      nzStyle: { top: '2vh' },
      nzWidth: '70%'
    });
  }

  determineSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance(
      {
        objectName: '',
        text: 'Enregistrer les changements',
        icon: faSave
      }
    );
    if (this.isGroup) {
      this.groupService.setSubmitButton(this.submitButton);
    } else if (this.isBrand) {
      this.brandService.setSubmitButton(this.submitButton);
    } else if (this.isAgency) {
      this.agencyService.setSubmitInformationsButton(this.submitButton);
    }
  }

}
