import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { InterventionService } from '@libs/services/src';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-intervention-edit-historical',
  templateUrl: './intervention-edit-historical.component.html',
  styleUrls: ['./intervention-edit-historical.component.scss']
})
export class InterventionEditHistoricalComponent implements OnInit, OnDestroy, OnChanges {

  @Input() intervention;
  public audits: any[];
  public isDestroyed$ = new Subject<void>();

  constructor(
    private interventionService: InterventionService
  ) { }

  ngOnInit() {
    this.initList();
  }

  ngOnDestroy(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  private initList() {
    this.interventionService.getAudits(this.intervention.id)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (data) => {
          this.audits = data;
        }
      )
  }

}
