import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AffairGoal, AffairStatus, SelectModel } from '@libs/models/src';
import { ProductService } from '@libs/services/src';
import { AffairGoalService } from '@libs/services/src/lib/affair-goal.service';
import { AffairStatusService } from '@libs/services/src/lib/affair-status.service';

@Component({
  selector: 'app-interventions-datatable-filters',
  templateUrl: './interventions-datatable-filters.component.html',
  styleUrls: ['./interventions-datatable-filters.component.scss']
})
export class InterventionsDatatableFiltersComponent implements OnInit {
  @Input() marketType: number;
  @Input() assistants: any[];
  @Input() technicians: any[];
  @Input() agencies: any[];
  @Input() conformities: any[];
  @Input() interventionsStatuses: any[];
  @Input() indicatorsSearchValues: any[] = [];
  @Input() products: any[];
  @Input() productsStatuses: any[];
  @Input() documentsCustomers: any[];
  @Input() affairsGoals: AffairGoal[];
  @Input() affairsStatuses: AffairStatus[];
  @Input() marketsTypes: SelectModel[];
  @Input() affairsMonitoringTypes: SelectModel[];
  @Input() form: FormGroup;
  public affairsGoalsFormatted = [];
  public affairsStatusesFormatted = [];

  constructor(
    private productService: ProductService,
    private affairGoalService: AffairGoalService,
    private affairStatusService: AffairStatusService
  ) { }

  ngOnInit() {
    if (this.products) {
      if (this.marketType) {
        this.products = this.productService.getSelectByMarketType(this.products, this.marketType);
      } else {
        this.products = this.productService.formatToSelectMultiple(this.products);
      }
    }
    if (this.affairsGoals) {
      if (this.marketType) {
        this.affairsGoalsFormatted = this.affairGoalService.getSelectByMarketType(this.affairsGoals, this.marketType);
      } else {
        this.affairsGoalsFormatted = this.affairGoalService.formatToSelectMultiple(this.affairsGoals);
      }
    }
    if (this.affairsStatuses) {
      if (this.marketType) {
        this.affairsStatusesFormatted = this.affairStatusService.getSelectByMarketType(this.affairsStatuses, this.marketType);
      } else {
        this.affairsStatusesFormatted = this.affairStatusService.formatToSelectMultiple(this.affairsStatuses);
      }
    }
  }

}
