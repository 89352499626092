import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiService } from '@libs/api/src';
import { SubmitButton } from '@libs/models/src';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { StatisticInterventionService } from './statistic-intervention.service';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';

@Injectable()
export class StatisticInterventionVolume {

    private route = '/stats/interventions/volume';
    public statsInterventionsRegions$ = new BehaviorSubject<any>(undefined);
    public statsInterventionsTechnicians$ = new BehaviorSubject<any>(undefined);
    public statsInterventionsProducts$ = new BehaviorSubject<any>(undefined);
    public statsInterventionsDepartments$ = new BehaviorSubject<any>(undefined);

    constructor(
        private apiService: ApiService,
        private notifService: NotifService,
        private statisticIntervention: StatisticInterventionService
    ) { }

    getStatsInterventionsRegions(form: FormGroup) {
        const params = this.statisticIntervention.formToParams(form);
        this.apiService.post(this.route + '/regions', params).subscribe(
            (result) => {
                this.statsInterventionsRegions$.next(result);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    getStatsInterventionsTechnicians(form: FormGroup) {
        const params = this.statisticIntervention.formToParams(form);
        this.apiService.post(this.route + '/technicians', params).subscribe(
            (result) => {
                this.statsInterventionsTechnicians$.next(result);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    getStatsInterventionsProducts(form: FormGroup) {
        const params = this.statisticIntervention.formToParams(form);
        this.apiService.post(this.route + '/products', params).subscribe(
            (result) => {
                this.statsInterventionsProducts$.next(result);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    getStatsInterventionsDepartments(form: FormGroup) {
        const params = this.statisticIntervention.formToParams(form);
        this.apiService.post(this.route + '/departments', params).subscribe(
            (result) => {
                this.statsInterventionsDepartments$.next(result);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }


}
