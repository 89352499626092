import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { IndicatorInterventionTypeService, NotifService } from '@eros-front/services';
import { SelectModel, SubmitButton } from '@eros-front/models';
import { FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-settings-interventions-indicators-types-modal',
  templateUrl: './settings-interventions-indicators-types-modal.component.html',
  styleUrls: ['./settings-interventions-indicators-types-modal.component.scss']
})
export class SettingsInterventionsIndicatorsTypesModalComponent implements OnInit {

  @Input() indicatorInterventionType;
  @Input() form: FormGroup;
  @Input() marketsTypes: any[];
  @Input() documentsCustomers: SelectModel[];
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private indicatorInterventionTypeService: IndicatorInterventionTypeService,
    private notifService: NotifService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.indicatorInterventionTypeService.setSubmitButton(this.submitButton);
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.indicatorInterventionType) {
        this.indicatorInterventionTypeService.update(this.indicatorInterventionType.id, this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      } else {
        this.indicatorInterventionTypeService.store(this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.indicatorInterventionTypeService.determinateSubmitButton(this.indicatorInterventionType, 'le type d\'indicateur intervention');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
