import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { AffairIndividualHouseService } from '@libs/services/src/lib/affair-individual-house.service';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { DashboardService } from '@libs/services/src/lib/dashboard.service';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { tabSuffix } from '_config/tab-suffix';
import { AffairsExportComponent } from '../affairs-export/affairs-export.component';

@Component({
  selector: 'app-affairs-individual-house-index',
  templateUrl: './affairs-individual-house-index.component.html',
  styleUrls: ['./affairs-individual-house-index.component.scss']
})
export class AffairsIndividualHouseIndexComponent implements OnInit, AfterViewInit {
  @Input() marketType: number;
  @Input() mlAgencies: any[];
  @Input() agencies: any[];
  @Input() assistants: any[];
  @Input() technicians: any[];
  @Input() products: any[];
  @Input() productsStatuses: any[];
  @Input() affairsStatuses: any[];
  @Input() affairsGoals: any[];
  @Input() affairsMonitoringTypes: any[];
  public columns: DataTableColumn[] = [
    {
      attr: 'mlRef',
      name: 'Réf.',
      individualFilterInput: true
    },
    {
      attr: 'oldRef',
      name: 'Réf. suivi',
      individualFilterInput: true,
      visible: false
    },
    {
      attr: 'finalCustomers',
      name: 'Nom(s) et prénom(s)',
      individualFilterInput: true
    },
    {
      attr: 'customer',
      name: 'Client',
      orderable: false,
      searchable: false
    },
    {
      attr: 'workSiteAddress',
      name: 'Adresse',
      individualFilterInput: true
    },
    {
      attr: 'workSitePostalCode',
      name: 'CP',
      individualFilterInput: true
    },
    {
      attr: 'workSiteLocality',
      name: 'Ville',
      individualFilterInput: true
    },
    {
      attr: 'folderNumber',
      name: 'N° dossier',
      individualFilterInput: true,
      visible: false
    },
    {
      attr: 'customerRef',
      name: 'Réf. client',
      individualFilterInput: true,
      visible: false
    },
    {
      attr: 'assistant',
      name: 'Assistante',
      searchable: false,
      orderable: false,
    },
    {
      attr: 'technician',
      name: 'Technicien',
      searchable: false,
      orderable: false,
    },
    {
      attr: 'productStatuses',
      name: 'Produit(s)',
      orderable: false,
      searchable: false,
      width: '15%'
    },
  ];
  public datatableSelector = 'affairs-mi-datatable';
  private readonly storageKey = 'affairs-mi-filters-form';
  public form: FormGroup;

  constructor(
    private titleService: Title,
    private affairService: AffairIndividualHouseService,
    private storageService: FormLocalStorageService,
    private modalService: NzModalService,
    private dashboardService: DashboardService
  ) {
    this.titleService.setTitle(`Maison Individuelle : Liste des affaires${tabSuffix}`);
  }

  ngOnInit() {
    let storageFormValue = this.dashboardService.retrieveDashboardFilters({
      assistants: this.assistants,
      productsStatuses: this.productsStatuses
    });
    if (storageFormValue == null) {
      storageFormValue = this.storageService.retrieve(this.storageKey);
    }
    this.dashboardService.resetFilters();
    this.form = this.affairService.getFiltersForm(storageFormValue);

    this.form.valueChanges.subscribe(form => {
      this.storageService.store(this.storageKey, this.form)
      this.affairService.redrawDataTable(this.form);
    });
  }

  ngAfterViewInit() {
    this.affairService.initDataTable(this.datatableSelector, this.columns, this.form)
  }

  resetForm() {
    const resetForm = this.affairService.getFiltersForm();
    this.form.reset(resetForm.value);
  }

  openReportModal() {
    const modalInstance = this.modalService.create({
      nzContent: AffairsExportComponent,
      nzComponentParams: {
        form: this.form
      },
      nzStyle: { top: 0 },
      nzWidth: '99%'
    })
    modalInstance.afterClose.subscribe(() => {
    })
  }

}
