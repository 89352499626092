import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SwalService, RegionService, MlAgencyService, DataTableColumn} from '@eros-front/services';
import Swal from 'sweetalert2';
import { MlAgency } from '@eros-front/models';

@Component({
  selector: 'app-settings-invoicing-ml-agencies-index',
  templateUrl: './settings-invoicing-ml-agencies-index.component.html',
  styleUrls: ['./settings-invoicing-ml-agencies-index.component.scss']
})
export class SettingsInvoicingMlAgenciesIndexComponent implements OnInit, AfterViewInit, OnDestroy {

  public datatableSelector = 'ml-agencies-datatable';
  public mlAgency: MlAgency = null;
  public mlAgency$: Subscription;
  public regions = [];
  public regions$: Subscription;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'color',
      name: 'Couleur',
      orderable: false,
      searchable: false
    },
    {
      attr: 'ref',
      name: 'Réf.'
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'address',
      name: 'Adresse'
    },
    {
      attr: 'locality',
      name: 'Ville'
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];

  constructor(
    private mlAgencyService: MlAgencyService,
    private regionService: RegionService,
    private swalService: SwalService
  ) { }

  ngOnInit() {
    this.getValues();
    this.initSubscriptions();
  }

  ngAfterViewInit() {
    this.mlAgencyService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    this.mlAgency$.unsubscribe();
    this.regions$.unsubscribe();
  }

  onShowModalAddEditMlAgency() {
    this.mlAgencyService.showModalAddEdit();
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function() {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.mlAgencyService.get(id);
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function() {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'L\'agence ML sera supprimée de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.mlAgencyService.delete(id);
          }
        });
      }
    });
  }

  private getValues() {
    this.regionService.getAll();
  }

  private initSubscriptions() {
    this.mlAgency$ = this.mlAgencyService.mlAgency$.subscribe(
      (mlAgency) => {
        if (mlAgency) {
          this.mlAgency = mlAgency;
          this.onShowModalAddEditMlAgency();
        }
      }
    );
    this.regions$ = this.regionService.regions$.subscribe(
      (regions) => {
        if (regions) {
          this.regions = regions;
        }
      }
    );
  }

}
