import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@libs/api/src';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotifService } from './utilities/notif.service';

@Injectable()
export class SearchService {

    private route = '/global/search';
    public search$ = new BehaviorSubject<any>(undefined);

    constructor(
        private apiService: ApiService,
        private notifService: NotifService
    ) { }

    search(search: string): void {
        this.apiService.get(`${this.route}?search=` + search).subscribe(
            (data: any) => {
                this.search$.next(data);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    searchByPhone(phoneNumber: string): Observable<any> {
        let phone = '';
        if(phoneNumber.startsWith(" 33")){
            phone = '0' + phoneNumber.slice(3);
        }
        else {
            phone = phoneNumber;
        }
        return this.apiService.get(`/global/search-by-phone/${phone}`);
    }

}
