import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AutocompleteOptions, FormFormatterService } from '@libs/services/src';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-affair-form-work-site-address',
  templateUrl: './affair-form-work-site-address.component.html',
  styleUrls: ['./affair-form-work-site-address.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AffairFormWorkSiteAddressComponent implements OnInit {
  @Input() form: FormGroup;
  public autocompleteOptions: AutocompleteOptions = new AutocompleteOptions();

  constructor(
    private formFormatterService: FormFormatterService
  ) { }

  ngOnInit() {
  }

  onWorkSiteAddressChange(address: Address) {
    this.form = this.formFormatterService.completeGoogleAddress(
      this.form, address,
      {
        street_number: 'workSiteStreetNumber',
        route: 'workSiteAddress',
        postal_code: 'workSitePostalCode',
        locality: 'workSiteLocality'
      }
    );
  }

}
