import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormFormatterService } from './utilities/form-formatter.service';
import { AffairPayerPreference } from '@eros-front/enum';
import { DataTableColumn, ModelWithDatatable } from './classes/model-datatable';
import { CommonService } from './utilities/common.service';
import { AnimationKeyframesSequenceMetadata } from '@angular/animations';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, finalize } from 'rxjs/operators';

export interface AffairDatatableParameters {
    selectedMlAgencies: string;
    selectedAgencies: string;
    selectedAssistants: string;
    selectedTechnicians: string;
    selectedProducts: string;
    selectedProductsStatuses: string;
    selectedAffairsGoals: string;
    selectedAffairsMonitoringTypes: string;
    affairMonitoringTypeLevel: number;
    dateCreationMin: Date;
    dateCreationMax: Date;
    dateProductReceiptMin: Date;
    dateProductReceiptMax: Date;
}

@Injectable()
export class AffairExportService {

    public export$ = new BehaviorSubject<any>(undefined);
    private route = '/affairs/export';

    constructor(
        http: HttpClient,
        router: Router,
        private apiService: ApiService,
        spinner: NgxSpinnerService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService
    ) {
    }

    public getData(form): void {
        this.apiService.post(this.route, this.formToDatatableParameters(form))
            .pipe(
                catchError(err => {
                    return throwError(err);
                }),
                finalize(() => {
                }),
            )
            .subscribe(
                (response) => {
                    this.export$.next(response);
                }
            );
    }

    private formToDatatableParameters(form: FormGroup): AffairDatatableParameters {
        if (form) {
            return {
                selectedMlAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedMlAgencies),
                selectedAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedAgencies),
                selectedAssistants: this.formFormatterService.formatSelectMultiple(form.value.selectedAssistants),
                selectedTechnicians: this.formFormatterService.formatSelectMultiple(form.value.selectedTechnicians),
                selectedProducts: this.formFormatterService.formatSelectMultiple(form.value.selectedProducts),
                selectedProductsStatuses: this.formFormatterService.formatSelectMultiple(form.value.selectedProductsStatuses),
                selectedAffairsGoals: this.formFormatterService.formatSelectMultiple(form.value.selectedAffairsGoals),
                selectedAffairsMonitoringTypes: this.formFormatterService.formatSelectMultiple(form.value.selectedAffairsMonitoringTypes),
                affairMonitoringTypeLevel: form.value.affairMonitoringTypeLevel,
                dateCreationMin: form.value.dateCreationMin,
                dateCreationMax: form.value.dateCreationMax,
                dateProductReceiptMin: form.value.dateProductReceiptMin,
                dateProductReceiptMax: form.value.dateProductReceiptMax,
            };
        }
        return null;
    }

    public getFiltersForm(): FormGroup {
        return this.formBuilder.group({
            configurationId: [null],
        });
    }

}
