import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface DataTableInstanceParameters {
    url: string;
    selector: string;
    dataTableColumns: DataTableColumn[];
    columnDefs?: DataTables.ColumnDefsSettings[];
    dom?: string;
    data?: any;
}

export interface DataTableColumn {
    attr: string;
    searchColumn?: string;
    name: string;
    individualFilterInput?: boolean;
    individualFilterSelect?: boolean;
    orderable?: boolean;
    searchable?: boolean;
    visible?: boolean;
    width?: string;
}

class DataTablesResponse {
    data: any[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
  }

@Injectable()
export class DatatableService {
    items = [];
    private refresh = new Subject<any>();
    refresh$ = this.refresh.asObservable();
  
    constructor(
        private http: HttpClient
    ) {}

    getItems() {
        return this.items;
    }


    private triggerDtEvents(params: DataTableInstanceParameters, dtInstance: DataTables.Api): void {
        $('a.toggle-vis').on('click', function (e) {
            e.preventDefault();
            // Get the column API object
            const column = dtInstance.column($(this).attr('data-column'));
            // Toggle the visibility
            column.visible(!column.visible());
            // Set not visible class
            if (!column.visible()) {
                $(this).addClass('not-visible');
            } else {
                $(this).removeClass('not-visible');
            }
        });
        $('table.dataTable tfoot input').on('keyup change clear', function () {
            const searchValue = $(this).val().toString();
            const columnIndex = $(this).attr('data-column');
            dtInstance.column(columnIndex).search(searchValue).draw();
        });
        $('#btn-datatable-export-excel').on('click', function () {
        });
        $('#btn-datatable-export-csv').on('click', function () {
        });
        $('#btn-datatable-reset-filters').on('click', function () {
            dtInstance.search('');
            $('table.dataTable tfoot input').each(function() {
                // tslint:disable-next-line: deprecation
                $(this).val('').change();
            });
            dtInstance.draw();
        });
        $('#btn-datatable-refresh-data').on('click', function () {
            dtInstance.draw();
        });
    }

    public dataTableColumnsToColumns(dataTableColumns: DataTableColumn[]): any[] {
        const columns = [];
        dataTableColumns.forEach((elem) => {
            columns.push({
                data: elem.attr,
                name: elem.searchColumn != null ? elem.searchColumn : elem.attr,
                orderable: elem.orderable != null ? elem.orderable : true,
                searchable: elem.searchable != null ? elem.searchable : true,
                visible: elem.visible != null ? elem.visible : true,
                width: elem.width != null ? elem.width : '',
            });
        });
        return columns;
    }

    public getLanguageDefault() {
        return {
            processing:     '<div></div>',
            search:         '<i class="fa fa-search"></i>&nbsp;',
            lengthMenu:     'Afficher _MENU_ &eacute;l&eacute;ments',
            info:           'Affichage de l\'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments',
            infoEmpty:      'Affichage de l\'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment',
            infoFiltered:   '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
            infoPostFix:    '',
            loadingRecords: 'Chargement en cours...',
            zeroRecords:    'Aucun &eacute;l&eacute;ment &agrave; afficher',
            emptyTable:     'Aucune donn&eacute;e disponible dans le tableau',
            paginate: {
                first:      '<i class="fa fa-angle-double-left" title="Première page"></i>',
                previous:   '<i class="fa fa-angle-left" title="Précédent"></i>',
                next:       '<i class="fa fa-angle-right" title="Suivant"></i>',
                last:       '<i class="fa fa-angle-double-right" title="Dernière page"></i>'
            },
            aria: {
                sortAscending:  ': activer pour trier la colonne par ordre croissant',
                sortDescending: ': activer pour trier la colonne par ordre d&eacute;croissant'
            }
        }
    }

    private getDomOrDefault(dom?: string): string {
        if (dom != null && dom.length > 0) {
            return dom;
        } else {
            return '<"row"<"col-4 float-left"i><"col-4 text-center"f><"col-4 float-right"l>> <"row"<"col-12"tr>> <"bottom"p>';
        }
    }

    private getColumDefsArray(columnDefs: DataTables.ColumnDefsSettings[]): DataTables.ColumnDefsSettings[]  {
        if (columnDefs) {
            columnDefs.push({
                targets: '_all',
                defaultContent: '-',
            });
        } else {
            columnDefs = [];
        }
        return columnDefs;
    }
}
