import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { NotifService } from './../utilities/notif.service';
import { SubmitButtonService } from './../utilities/submit-button.service';
import { SubmitButton } from '@eros-front/models';
import { SwalService } from '@eros-front/services';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Injectable()
export class ModelWithModalCrud {

    // Variables
    submitButton: SubmitButton;
    dtInstance: DataTables.Api;
    modalAddEdit: ModalDirective;
    // Modules & Services
    notifService: NotifService;
    submitButtonService: SubmitButtonService;
    swalService: SwalService;
    apiService: ApiService;

    get(id: number, observable$: BehaviorSubject<any>, route: string): void {
        this.apiService.get(route + '/' + id)
            .subscribe(
                (object) => {
                    observable$.next(object);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    getAll(observable$: BehaviorSubject<any>, route: string): void {
        this.apiService.get(route)
            .subscribe(
                (objects) => {
                    observable$.next(objects);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    store(object: any, route: string): void {
        this.submitButtonService.setDisabled(this.submitButton);
        this.apiService.post(route, object)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
            .subscribe(
                (success) => {
                    this.hideModalAddEdit();
                    this.swalService.showSwalSuccess(success);
                    if (this.dtInstance) {
                        this.dtInstance.draw();
                    }
                }
            );
    }

    update(id: number, object: any, route: string): void {
        this.submitButtonService.setDisabled(this.submitButton);
        this.apiService.put(route + '/' + id, object)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
            .subscribe(
                (success) => {
                    if (this.modalAddEdit) {
                        this.hideModalAddEdit();
                    }
                    this.swalService.showSwalSuccess(success);
                    if (this.dtInstance) {
                        this.dtInstance.draw();
                    }
                }
            );
    }

    delete(id: number, route: string): void {
        this.apiService.delete(route + '/' + id)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            )
            .subscribe(
                (success) => {
                    this.swalService.showSwalSuccess(success);
                    if (this.dtInstance) {
                        this.dtInstance.draw();
                    }
                }
            );
    }

    setModalAddEdit(modal: ModalDirective): void {
        this.modalAddEdit = modal;
        this.modalAddEdit.hide();
    }

    removeModalAddEdit(): void {
        this.modalAddEdit = null;
    }

    showModalAddEdit(): void {
        this.modalAddEdit.show();
    }

    hideModalAddEdit(): void {
        this.modalAddEdit.hide();
    }

    setSubmitButton(button: SubmitButton): void {
        this.submitButton = button;
    }

    determinateSubmitButton(object: any, objectName: string): SubmitButton {
        return this.submitButtonService.getSubmitButtonInstance({
            isAdd: !object,
            objectName: objectName,
        });
    }
}
