import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifService } from '@libs/services/src';
import { AffairDocumentCustomerService } from '@libs/services/src/lib/affair-document-customer.service';
import { AffairDocumentDeliveredService } from '@libs/services/src/lib/affair-document-delivered.service';
import { AffairDocumentImportMultipleParam, AffairDocumentImportParam } from '@libs/services/src/lib/classes/affair-document';
import { interval, Subscription } from 'rxjs';
import { delay, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-affair-document-import',
  templateUrl: './affair-document-import.component.html',
  styleUrls: ['./affair-document-import.component.scss']
})
export class AffairDocumentImportComponent implements OnInit, OnDestroy {

  @Input() affair: any;
  @Input() dropzones: any[];
  @Input() affairDocumentService: AffairDocumentCustomerService | AffairDocumentDeliveredService;
  public files: File[][] = [];
  private uploadProcess$: Subscription;
  public backButtonIsDisabled = false;
  public affairDocumentParam: AffairDocumentImportParam;
  public affairDocumentMultipleParam: AffairDocumentImportMultipleParam;

  constructor(
    private router: Router,
    private notifService: NotifService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.uploadProcess$) {
      this.uploadProcess$.unsubscribe();
    }
  }

  goToAffairClicked() {
    this.router.navigateByUrl(`affairs/${this.affair.id}/show`);
  }

  onFileImport(param) {
    let event = param.event;
    let documentId = param.documentId;
    if (this.files[documentId] == null) {
      this.files[documentId] = [];
    }
    if (event.addedFiles.length === 1) {
      this.affairDocumentParam = {
        affairId: this.affair.id,
        affairProductId: param.affairProductId,
        documentId: param.documentId,
        incrementVersion: false,
        buildingId: param.buildingId,
        file: null
      }
    } else {
      this.affairDocumentMultipleParam = {
        affairId: this.affair.id,
        affairProductId: param.affairProductId,
        documentId: param.documentId,
        incrementVersion: false,
        buildingId: param.buildingId,
        files: null
      }
    }

    if (param.isImported) {
      Swal.fire({
        title: 'Est-ce une nouvelle version du document ?',
        text: "Le numéro de version sera incrémenté",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          if (event.addedFiles.length === 1) {
            this.affairDocumentParam.incrementVersion = true;
            this.importFile(this.affairDocumentParam, event);
          } else {
            this.affairDocumentMultipleParam.incrementVersion = true;
            this.importFile(this.affairDocumentMultipleParam, event);
          }
        } else {
          if (event.addedFiles.length === 1) {
            this.importFile(this.affairDocumentParam, event);
          } else {
            this.importFile(this.affairDocumentMultipleParam, event);
          }
        }
      })
    } else {
      if (event.addedFiles.length === 1) {
        this.importFile(this.affairDocumentParam, event);
      } else {
        this.importFile(this.affairDocumentMultipleParam, event);
      }
    }
  }

  private importFile(affairDocumentParam: AffairDocumentImportParam | AffairDocumentImportMultipleParam, event: any) {
    this.backButtonIsDisabled = true;
    this.notifService.showNotif({
      type: 'warning',
      text: 'Import en cours...'
    });
    if (event.addedFiles.length === 1) {
      this.affairDocumentParam.file = event.addedFiles[0];
      this.uploadProcess$ = this.affairDocumentService
        .import(this.affairDocumentParam)
        .subscribe(
          (response) => {
            this.files[affairDocumentParam.documentId].push(event.addedFiles[0]);
            this.notifService.removeAllNotifs();
            this.notifService.showNotif({
              type: 'success',
              text: response.message
            });
            this.affairDocumentService.getDropzones(this.affair.id);
            this.backButtonIsDisabled = false;
          },
          (error) => {
            this.notifService.showErrorNotif(error);
            this.backButtonIsDisabled = false;
          }
        )
    } else {
      this.affairDocumentMultipleParam.files = event.addedFiles;
      this.uploadProcess$ = this.affairDocumentService
        .importMultiple(this.affairDocumentMultipleParam)
        .subscribe(
          (response) => {
            this.notifService.removeAllNotifs();
            this.notifService.showNotif({
              type: 'success',
              text: response.message
            });
            this.affairDocumentService.getDropzones(this.affair.id);
            this.backButtonIsDisabled = false;
          },
          (error) => {
            this.notifService.showErrorNotif(error);
            this.backButtonIsDisabled = false;
          }
        )

    }
  }

}
