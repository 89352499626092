import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpRequest} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Model} from "../types/model.type";
import {NotifService} from "@libs/services/src";
import {LinkModelIndex} from "../types/linkModelIndex.type";
import {Index} from "../types/index.type";

@Component({
  selector: 'eros-front-modeles',
  templateUrl: './modeles.component.html',
  styleUrls: ['./modeles.component.css']
})
export class ModelesComponent implements OnInit {

  public models: Model[];
  public isLoading: boolean = true;
  public isVisibleUpdateModal: boolean = false;
  public isVisibleCreationModal: boolean = false;
  public isLoadingModelModal: boolean = false;
  public linkModelData: LinkModelIndex[];
  public modelData: Model;
  public glossary: Index[];
  public files: File[] = [];
  public modelName: string;
  public idModel: string;

  constructor(
    private _http: HttpClient,
    private _notifService: NotifService
    ) { }

  ngOnInit(): void {
    this.loadModels().subscribe({
      next: data => {
        this.models = data;

        this._http.get<Index[]>(environment.serviceEUrl + '/glossary').subscribe({
          next: data => {
            this.glossary = data;
            this.isLoading = false;
          }
        });
      }
    });
  }

  public loadModels() {
    return this._http.get<Model[]>(environment.serviceEUrl + '/models');
  }

  public updateIndex(idLinkModelData, event) {
    let payload = {
      "id_index" : event
    };
    this._http.patch<any>(environment.serviceEUrl + '/models/update/' + idLinkModelData, payload).subscribe({
      next: data => {
        this._notifService.showSuccessNotif(data.message);
      },
      error: err => {
        this._notifService.showErrorNotif(err.error.message);
      }
    });
  }

  /**
   * Add the files to upload to the list of files
   * @param event
   */
  public onSelectFile(event): void {
    this.files.push(...event.addedFiles);
    if (this.files.length > 1) {
      this.files.splice(0, 1);
    }
  }

  /**
   * Remove a file from the list of uploaded file
   * @param event
   */
  public onRemoveFile(event): void {
    this.files.splice(this.files.indexOf(event), 1);
  }

  /**
   * Handle when user clicks on cancel button
   */
  public handleCancel(): void {
    this.isVisibleUpdateModal = false;
    this.isVisibleCreationModal = false;
  }

  public handleOkCreate(): void {
    this.isVisibleUpdateModal = false;
    this.isVisibleCreationModal = false;

    this._http.post<any>(environment.serviceEUrl + '/models/validate/' + this.idModel, {}).subscribe({
      next: data => {
        this._notifService.showSuccessNotif(data.message);
        this.loadModels().subscribe({
          next: data => {
            this.models = data;
          }
        });
      },
      error: err => {
        this._notifService.showErrorNotif(err.error.message);
      }
    }
    );
  }

  public handleOkUpdate() {
    // update model name
    let payload = {
      "name" : this.modelData.name
    };
    this._http.patch<any>(environment.serviceEUrl + '/models/' + this.modelData._id, payload).subscribe({
      next: data => {
        this._notifService.showSuccessNotif(data.message);
        this.loadModels().subscribe({
          next: data => {
            this.models = data;
          }
        });
      },
      error: err => {
        this._notifService.showErrorNotif(err.error.message);
      }
    })

    // close modal
    this.isVisibleUpdateModal = false;
    this.isVisibleCreationModal = false;
  }

  public showCreationModelModal(): void {
    const payload = new FormData();
    payload.append('model_name', this.modelName);
    for(let i = 0 ; i < this.files.length ; i++) {
      payload.append('file[]', this.files[i]);
    }
    this.isVisibleCreationModal = true;
    this.isLoadingModelModal = true;

    this._http.post<any>(environment.serviceEUrl + '/models', payload).subscribe({
      next: data => {
        this.idModel = data;
        this._http.get<LinkModelIndex[]>(environment.serviceEUrl + '/models/' + data).subscribe({
          next: data => {
            this.linkModelData = data;
            this.isLoadingModelModal = false;
            this.modelName = undefined;
            this.files = [];
          },
          error: err => {
            this._notifService.showErrorNotif(err.error.message);
            this.modelName = undefined;
            this.files = [];
          }
        });
      },
      error: err => {
        this._notifService.showErrorNotif(err.error.message);
        this.modelName = undefined;
        this.files = [];
      }
    });
  }

  public showUpdateModelModal(model): void {
    this.isVisibleUpdateModal = true;
    this.isLoadingModelModal = true;
    this.modelData = model;
    this._http.get<LinkModelIndex[]>(environment.serviceEUrl + '/models/' + model._id).subscribe({
      next: data => {
        this.linkModelData = data;
        this.isLoadingModelModal = false;
      },
      error: err => {
        this._notifService?.showErrorNotif(err.error.message);
        this.isVisibleUpdateModal = false;
        this.isLoadingModelModal = false;
      }
    });
  }

  /**
   * Delete the model in parameter
   * @param model model to delete
   */
  public delete(model): void {
    this._http.delete<any>(environment.serviceEUrl + '/models/' + model._id).subscribe({
      next: data => {
        this.loadModels().subscribe({
          next: data => {
            this.models = data;
          }
        });
        this._notifService.showSuccessNotif(data.message);
      },
      error: err => {
        this._notifService.showErrorNotif(err.error.message);
      }
    });
  }

  public canCreateModel(): boolean {
    let isEmptyFiles = this.files.length === 0;
    let isEmptyModelName = this.modelName === undefined;

    return isEmptyFiles || isEmptyModelName;
  }

}
