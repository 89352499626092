import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { Router } from '@angular/router';
import { AffairOfficeFile, OfficeFile } from '@libs/models/src';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { SelectService } from './utilities/select.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { catchError, finalize } from 'rxjs/operators';

@Injectable()
export class AffairMessageService extends ModelWithDatatableAndCrudObservable {

    public route = '/affair-messages';
    public affairMessagesByAffair$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private commonService: CommonService,
        private selectService: SelectService,
        private formFormattedService: FormFormatterService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route);
    }

    public getAll(): Observable<any> {
        return super.getAll(this.route);
    }

    public store(form: any): Observable<any> {
        return this.apiService.post(this.route, form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                    if (this.dtInstance) {
                        this.dtInstance.draw();
                    }
                })
            );
    }

    public update(id: number, form: any): Observable<any> {
        return super.update(id, form.value, this.route);
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route);
    }

    public getByAffair(affairId: number) {
        return this.apiService.get(this.route + '/affair/' + affairId).subscribe(
            (messages) => {
                this.affairMessagesByAffair$.next(messages);
            },
            (error) => {
                this.notifService.showNotif({
                    type: 'danger',
                    text: 'Une erreur est survenue lors de la récupération'
                });
            }
        );
    }

    public getForm(affairId: number): FormGroup {
        const content = this.commonService.getDefaultFormStringValue();
        return this.formBuilder.group({
            affairId: [affairId, Validators.required],
            content: [content, Validators.required],
            sendMailAsCopy: false,
            recipients: [],
            recipientsCopy: []
        });
    }

}
