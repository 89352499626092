import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { SubmitButton } from '@libs/models/src';
import { SubmitButtonService, SwalService } from '@libs/services/src';
import { PaymentService } from '@libs/services/src/lib/payment.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-payments-import-modal',
  templateUrl: './payments-import-modal.component.html',
  styleUrls: ['./payments-import-modal.component.scss']
})
export class PaymentsImportModalComponent implements OnInit {
  public form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private paymentService: PaymentService,
    private formBuilder: FormBuilder,
    private submitButtonService: SubmitButtonService,
    private swalService: SwalService
  ) { }

  ngOnInit() {
    this.form = this.initForm();
    this.setSubmitButton();
  }

  onFileChange(event): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.patchValue({
        fileSource: file
      });
    }
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

  public submitForm(): void {
    if (this.isValidForm()) {
      this.paymentService.import(this.form).subscribe(
        (success) => {
          this.modal.close();
          this.swalService.showSwalSuccess(success);
        }
      )
    }
  }

  private initForm(): FormGroup {
    return this.formBuilder.group({
      'file': new FormControl('', [Validators.required]),
      'fileSource': new FormControl('', [Validators.required])
    });
  }

  private setSubmitButton(): void {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'les paiements',
      text: 'Importer',
      icon: faFileImport
    });
    this.paymentService.setSubmitButton(this.submitButton);
  }

}
