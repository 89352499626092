import { Component, OnInit, AfterViewInit, Input, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';
import { SubmitButton } from '@eros-front/models';
import { UnpaidStatusService } from '@eros-front/services';
import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-settings-invoicing-unpaid-statuses-modal',
  templateUrl: './settings-invoicing-unpaid-statuses-modal.component.html',
  styleUrls: ['./settings-invoicing-unpaid-statuses-modal.component.scss']
})
export class SettingsInvoicingUnpaidStatusesModalComponent implements OnInit, AfterViewInit {

  @Input() status: any;
  @ViewChild('settingsAddEditUnpaidStatus', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private unpaidStatusService: UnpaidStatusService
  ) { }

  ngOnInit() {
    this.initValues();
  }

  ngAfterViewInit() {
    this.unpaidStatusService.setModalAddEdit(this.childModal);
  }

  onModalShown() {
    this.determinateSubmitButton();
    this.form = this.unpaidStatusService.getForm(this.status);
  }

  onModalHidden() {
    this.initValues();
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.status) {
        this.unpaidStatusService.update(this.status.id, this.form);
      } else {
        this.unpaidStatusService.store(this.form);
      }
    }
  }

  private initValues() {
    this.status = null;
    this.form = null;
  }

  private determinateSubmitButton() {
    let text = '';
    let icon = null;
    if (!this.status) {
      text = 'Ajouter le statut impayé';
      icon = faPlus;
    } else {
      text = 'Modifier le statut impayé';
      icon = faSave;
    }
    this.submitButton = {
      text: text,
      originalText: text,
      icon: icon,
      originalIcon: icon,
      enabled: true
    };
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
