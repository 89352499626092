import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-customers-show-offices-files-template',
  templateUrl: './customers-show-offices-files-template.component.html',
  styleUrls: ['./customers-show-offices-files-template.component.scss']
})
export class CustomersShowOfficesFilesTemplateComponent implements OnInit {
  @Input() officeFile;
  public faCircle = faCircle;

  constructor() { }

  ngOnInit() {

  }

}
