import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { Router } from '@angular/router';
import { AffairOfficeFile, OfficeFile } from '@libs/models/src';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { SelectService } from './utilities/select.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { catchError, finalize } from 'rxjs/operators';

@Injectable()
export class AffairOfficeFileService extends ModelWithDatatableAndCrudObservable {

    public route = '/affairs-offices-files';

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private commonService: CommonService,
        private selectService: SelectService,
        private formFormattedService: FormFormatterService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route);
    }

    public getAll(): Observable<any> {
        return super.getAll(this.route);
    }

    public store(affairId: any): Observable<any> {
        return this.apiService.post(this.route, {
            'affairId': affairId
        })
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                    if (this.dtInstance) {
                        this.dtInstance.draw();
                    }
                })
            );
    }

    public update(id: number, form: any): Observable<any> {
        return super.update(id, this.formatForm(form), this.route);
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route);
    }

    public getForm(affairOfficeFile?: AffairOfficeFile) {
        return this.formBuilder.group({
            affairsSendSettings: new FormArray([])
        });
    }

    public initAffairsSendSettings(form: FormGroup, sendSettings: any, sendSettingsTypes: any, contacts: any, officeFile?: any) {
        const sendSettingsFormArray = form.get('affairsSendSettings') as FormArray;
        sendSettings.forEach((sendSetting) => {
            let found = null;
            let affairSendSetting = null;
            let recipients = [];
            let recipientsCopy = [];
            let isAutomaticSendChecked = false;

            if (this.isInstanceOfAffairOfficeFile(officeFile)) {
                if (officeFile) {
                    found = officeFile.affairsSendSettings.find(x => x.sendSettingId == sendSetting.id);
                }
                if (found) {
                    affairSendSetting = found;
                    recipients = contacts.filter(x => affairSendSetting.recipients.map(x => x.id).includes(x.value));
                    recipientsCopy = contacts.filter(x => affairSendSetting.recipientsCopy.map(x => x.id).includes(x.value));
                    isAutomaticSendChecked = affairSendSetting.isAutomaticSend;
                }
            } else {
                found = officeFile.officesFilesSettings.find(x => x.sendSettingId == sendSetting.id);
                if (found) {
                    affairSendSetting = found;
                    recipients = contacts.filter(x => affairSendSetting.officesFilesRecipients.map(x => x.id).includes(x.value));
                    recipientsCopy = contacts.filter(x => affairSendSetting.officesFilesRecipientsCopy.map(x => x.id).includes(x.value));
                    isAutomaticSendChecked = affairSendSetting.isAutomaticSend;
                }
            }
            sendSettingsFormArray.push(new FormGroup({
                sendSettingId: new FormControl(sendSetting.id),
                sendSettingsTypes: this.initSendSettingsTypes(sendSettingsTypes, affairSendSetting),
                sendSettingsTypesCopy: this.initSendSettingsTypesCopy(sendSettingsTypes, affairSendSetting),
                isAutomaticSend: new FormControl(isAutomaticSendChecked),
                recipients: new FormControl(recipients),
                recipientsCopy: new FormControl(recipientsCopy),
            }))
        });
    }

    public initSendSettingsTypes(sendSettingsTypes: any, affairSendSetting?: any): FormArray {
        const formArray = new FormArray([]);
        sendSettingsTypes.forEach(sendSettingType => {
            let checkedValue = false;
            if (affairSendSetting) {
                if (affairSendSetting.sendSettingsTypes.length > 0) {
                    let found = affairSendSetting.sendSettingsTypes.map(x => x.id).includes(sendSettingType.id);
                    if (found) {
                        checkedValue = true;
                    }
                }
            }
            formArray.push(new FormGroup({
                sendSettingTypeId: new FormControl(sendSettingType.id),
                checked: new FormControl(checkedValue),
            }))
        });
        return formArray
    }

    public initSendSettingsTypesCopy(sendSettingsTypes: any, affairSendSetting?: any): FormArray {
        const formArray = new FormArray([]);
        sendSettingsTypes.forEach(sendSettingType => {
            let checkedValue = false;
            if (affairSendSetting) {
                if (affairSendSetting.sendSettingsTypesCopy.length > 0) {
                    let found = affairSendSetting.sendSettingsTypesCopy.map(x => x.id).includes(sendSettingType.id);
                    if (found) {
                        checkedValue = true;
                    }
                }
            }
            formArray.push(new FormGroup({
                sendSettingTypeId: new FormControl(sendSettingType.id),
                checked: new FormControl(checkedValue),
            }))
        });
        return formArray
    }


    public isInstanceOfAffairOfficeFile(object: any): boolean {
        return 'affairsSendSettings' in object;
    }

    private formatForm(form: FormGroup) {
        const values = JSON.parse(JSON.stringify(form.value));
        values.affairsSendSettings.forEach((value) => {
            value.sendSettingsTypes = value.sendSettingsTypes.filter(v => v.checked).length > 0 ?
                value.sendSettingsTypes.filter(v => v.checked).map(s => s.sendSettingTypeId) : [];
            value.sendSettingsTypesCopy = value.sendSettingsTypesCopy.filter(v => v.checked).length > 0 ?
                value.sendSettingsTypesCopy.filter(v => v.checked).map(s => s.sendSettingTypeId) : [];
            value.recipients = this.formFormattedService.formatSelectMultipleToIntArray(value.recipients);
            value.recipientsCopy = this.formFormattedService.formatSelectMultipleToIntArray(value.recipientsCopy);
        });
        return values;
    }
}
