import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Intervention } from '@libs/models/src';
import { InterventionDocumentCustomerService, InterventionService, NotifService } from '@libs/services/src';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-intervention-document-customer-import',
  templateUrl: './intervention-document-customer-import.component.html',
  styleUrls: ['./intervention-document-customer-import.component.scss']
})
export class InterventionDocumentCustomerImportComponent implements OnInit, OnDestroy {

  files: File[][] = [];
  public intervention: Intervention;
  public intervention$: Subscription;
  public uploadProcess$: Subscription;
  public interventionId;
  public interventionDocumentsCustomersImported = [];
  public interventionDocumentsCustomersNeeded = [];
  public faCheckCircle = faCheckCircle;
  public faExclamationCircle = faExclamationCircle;

  constructor(
    private route: ActivatedRoute,
    private interventionService: InterventionService,
    private interventionDocumentCustomerService: InterventionDocumentCustomerService,
    private notifService: NotifService,
    private router: Router
  ) { }

  ngOnInit() {
    this.initSubscriptions();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.interventionId = params.get('id');
      this.interventionService.get(this.interventionId);
    });
  }

  ngOnDestroy() {
    if (this.uploadProcess$) {
      this.uploadProcess$.unsubscribe();
    }
    this.intervention$.unsubscribe();
  }

  onSelect(event, documentCustomerId) {
    if (this.files[documentCustomerId] == null) {
      this.files[documentCustomerId] = [];
    }
    this.notifService.showNotif({
      type: 'warning',
      text: 'Import en cours...'
    });
    for (let i = 0; i < event.addedFiles.length; i++) {
      this.uploadProcess$ = this.interventionDocumentCustomerService.import(this.intervention.id, documentCustomerId, event.addedFiles[i]).subscribe(
        (response) => {
          this.files[documentCustomerId].push(event.addedFiles[i]);
          this.notifService.removeAllNotifs();
          this.notifService.showNotif({
            type: 'success',
            text: response.message
          });
          this.interventionService.get(this.interventionId);
        },
        (error) => {
          this.notifService.showNotif({
            type: 'danger',
            text: "Une erreur est survenue l'import du document"
          });
        }
      )
    }
  }

  onRemove(event, i) {
    this.files[i].splice(this.files.indexOf(event), 1);
  }

  initSubscriptions() {
    this.intervention$ = this.interventionService.intervention$.subscribe(
      (data) => {
        if (data) {
          this.intervention = data;
          this.buildInterventionDocumentsCustomersNeeded(this.intervention);
        }
      }
    )
  }

  private buildInterventionDocumentsCustomersNeeded(intervention) {
    for (let i = 0; i < intervention.products.length; i++) {
      for (let j = 0; j < intervention.products[i].documentsCustomers.length; j++) {
        if (!this.interventionDocumentsCustomersNeeded.some(e => e.id === intervention.products[i].documentsCustomers[j].id)) {
          this.interventionDocumentsCustomersNeeded.push({
            id: intervention.products[i].documentsCustomers[j].id,
            name: intervention.products[i].documentsCustomers[j].name,
            isNeeded: intervention.products[i].documentsCustomers[j].pivot.isNeeded,
          });
        }
      }
    }
  }

  public isImported(documentId) {
    return this.intervention.documentsCustomers.find(x => x.id == documentId);
  }

  public goToAffair() {
    this.router.navigateByUrl('affairs/show/' + this.intervention.affair.id);
  }
}
