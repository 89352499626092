import { Component, Input, OnInit } from '@angular/core';
import { Contact, SubmitButton } from '@libs/models/src';
import { ContactService, CustomerService, NotifService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-customers-show-contacts-modal',
  templateUrl: './customers-show-contacts-modal.component.html',
  styleUrls: ['./customers-show-contacts-modal.component.scss']
})
export class CustomersShowContactsModalComponent implements OnInit {
  @Input() contact: Contact;
  @Input() customer: any;
  @Input() form;
  @Input() professions;
  @Input() group;
  @Input() indicatorsContacts = [];
  @Input() groups;
  @Input() mlSocieties: any[];
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private contactService: ContactService,
    private customerService: CustomerService,
    private notifService: NotifService
  ) {
  }

  ngOnInit() {
    this.determinateSubmitButton();
    this.contactService.setSubmitButton(this.submitButton);
    if (this.contact) {
      this.customerService.initMlSocietiesCheckboxes(this.form, this.mlSocieties, this.contact);
    } else {
      this.customerService.initMlSocietiesCheckboxes(this.form, this.mlSocieties, this.customer);
    }
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.contact) {
        this.contactService.update(this.contact.id, this.form).subscribe((success) => {
          this.modal.close();
          this.notifService.showSuccessNotif(success);
        });
      } else {
        this.contactService.store(this.form).subscribe((success) => {
          this.modal.close();
          this.notifService.showSuccessNotif(success);
        });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.contactService.determinateSubmitButton(this.contact, 'le contact');
  }

  isValidForm(): boolean {
    return this.form.valid;
  }

}
