import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Period} from "../../types/period.type";
import {Index} from "../../types/index.type";
import {NotifService} from "@libs/services/src";
import {Rule} from "../../types/rule.type";

@Component({
  selector: 'eros-front-inspections-periode-rules',
  templateUrl: './inspections-period-rules.component.html',
  styleUrls: ['./inspections-period-rules.component.css']
})
export class InspectionsPeriodRulesComponent implements OnInit {

  private _periodId: string;
  private _period: Period;
  public isVisible = false;
  private _glossary: Index[] = [] as Index[];
  private _rules: Rule[] = [] as Rule[];
  public currentOperator: string;
  private _operators: string[] = [
    '<',
    '<=',
    '=',
    '>',
    '>=',
  ] as string[];

  /**
   * Constructor of InspectionsPeriodRulesComponent
   * @param _activateRoute
   * @param _http
   * @param _notifService
   */
  constructor(private _activateRoute: ActivatedRoute, private _http: HttpClient, private _notifService: NotifService) { }

  public get operators(): string[] {
    return this._operators;
  }


  /**
   * Update the value of the operator for the updated rule
   *
   * @param operator
   * @param id
   */
  public updateOperator(operator: string, id: string) {
    let payload = {
      'operator': operator
    }
    this._http.patch<Rule>(environment.serviceEUrl + '/rules/' + id, payload).subscribe({
      next: _ => {
        this._notifService.showSuccessNotif("L'opérateur de comparaison est maintenant : " + operator);
      }
    })
  }

  /**
   * Update the value of the value for the updated rule
   *
   * @param event
   * @param id
   */
  public updateValue(event, id: string) {
    if(event.target.value !== undefined) {
      let payload = {
        'value': event.target.value
      }
      this._http.patch<Rule>(environment.serviceEUrl + '/rules/' + id, payload).subscribe({
        next: _ => {

        }
      })
    }
  }

  /**
   * Delete the rule
   * @param id
   */
  public deleteRule(id: string) {
    this._http.delete<any>(environment.serviceEUrl + '/rules/' + id).subscribe({
      next: data => {
        for(let i = 0 ; i < this._rules.length ; i++) {
          if(this._rules[i]._id === id) {
            this._rules.splice(i, 1);
          }
        }
        this._rules = [... this._rules]
        this._notifService.showSuccessNotif(data.message)
      }
    })
  }

  /**
   * Load the glossary and show the modal
   */
  public showModal(): void {
    this._http.get<Index[]>(environment.serviceEUrl + '/glossary').subscribe({
      next: data => {
        this._glossary = data;
        this.isVisible = true;
      },
      error: _ => {
        this._notifService.showErrorNotif('Une erreur est survenue lors du chargement du glossaire');
      }
    })
  }

  /**
   * Return the value of _glossary
   */
  public get glossary(): Index[] {
    return this._glossary;
  }

  /**
   * Return the value of _rules
   */
  public get rules(): Rule[] {
    return this._rules;
  }

  /**
   * Create a new rule and add it to the list of rules
   * @param data
   */
  public addRule(data: Index) {
    let payload = {
      'id_period': this._periodId,
      'name_id': data.name_id
    }
    this._http.post<Rule>(environment.serviceEUrl + '/rules', payload).subscribe({
      next: data => {
        this._rules.push(data)
        this._rules = [... this._rules]
        this.isVisible = false;
        this._notifService.showSuccessNotif('La règle a bien été créée !');
      }
    })
  }

  /**
   * Close the modal
   */
  public handleOk(): void {
    this.isVisible = false;
  }

  /**
   * Close the modal
   */
  public handleCancel(): void {
    this.isVisible = false;
  }

  /**
   * Executed on init of component
   *
   * Load the period in parameter and all its rules
   */
  ngOnInit(): void {
    this._activateRoute.paramMap.subscribe((params: ParamMap) => {
      this._periodId = params.get('id');
      this._http.get<Period>(environment.serviceEUrl + '/period/' + this._periodId).subscribe({
        next: data => {
          this._period = data;
          for(let i = 0 ; i < data.rules_ids.length ; i++) {
            this._http.get<Rule>(environment.serviceEUrl + '/rules/' + data.rules_ids[i]).subscribe({
              next: data => {
                this._rules.push(data);
                this._rules = [...this._rules]
              }
            })
          }
        },
        error: _ => {
          this._period = null;
        }
      });
    });
  }

}
