import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { PhoneLine, SubmitButton, User } from '@libs/models/src';
import { NotifService } from '@libs/services/src';
import { PhoneLineService } from '@libs/services/src/lib/phone-line.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-phones-lines-modal',
  templateUrl: './phones-lines-modal.component.html',
  styleUrls: ['./phones-lines-modal.component.scss']
})
export class PhonesLinesModalComponent implements OnInit {
  @Input() phoneLine: PhoneLine;
  @Input() users: User[];
  @Input() form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private phoneLineService: PhoneLineService,
    private modal: NzModalRef,
    private notifService: NotifService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.phoneLineService.setSubmitButton(this.submitButton);
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.phoneLine) {
        this.phoneLineService.update(this.phoneLine.id, this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success)
          this.modal.close();
        });
      } else {
        this.phoneLineService.store(this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success)
          this.modal.close();
        });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.phoneLineService.determinateSubmitButton(this.phoneLine, 'la ligne téléphonique');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

  getFormControls() {
    return (this.form.get('users') as FormArray).controls;
  }

  onAddUserClicked() {
    (this.form.get('users') as FormArray).push(this.phoneLineService.getUserForm());
  }

  onDeleteUserClicked(index: number) {
    (this.form.get('users') as FormArray).removeAt(index);
  }
}
