import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgrammedStandardsResponsesListComponent } from './programmed-standards-responses/programmed-standards-responses-list/programmed-standards-responses-list.component';
import { SharedModule } from '@libs/shared/src';
import { ProgrammedStandardsResponsesFiltersComponent } from './programmed-standards-responses/programmed-standards-responses-filters/programmed-standards-responses-filters.component';
import { MailsSendListComponent } from './mails-send/mails-send-list/mails-send-list.component';
import { MailsSendFiltersComponent } from './mails-send/mails-send-filters/mails-send-filters.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ProgrammedStandardsResponsesListComponent,
    ProgrammedStandardsResponsesFiltersComponent,
    MailsSendFiltersComponent,
    MailsSendListComponent,
  ],
  exports: [
    ProgrammedStandardsResponsesListComponent,
    MailsSendListComponent
  ]
})
export class CustomerRelationshipModule { }
