import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@libs/api/src';
import { AffairYnergie, SubmitButton } from '@libs/models/src';
import { Observable } from 'rxjs';

@Injectable()
export class AffairYnergieService {

  private submitButton: SubmitButton;
  private route = '/affairs/ynergie';

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder
  ) { }

  public list(form): Observable<any> {
    return this.apiService.post(`${this.route}/list`, form.value);
  }

  public create(form): Observable<any> {
    return this.apiService.post(`${this.route}/create`, form.value);
  }

  public get(id: string): Observable<AffairYnergie> {
    return this.apiService.get(`${this.route}/${id}`);
  }

  public createScenario(form): Observable<any> {
    return this.apiService.post(`${this.route}/scenario/create`, form.value);
  }

  public initFiltersForm(): FormGroup {
    return this.formBuilder.group({
      page: 1,
      size: 200,
      search: ''
    });
  }

  public initCreateForm(affairYnergie: AffairYnergie): FormGroup {
    return this.formBuilder.group({
      affairYnergieId: [affairYnergie.id, Validators.required],
      finalCustomerLastname: [affairYnergie.proprietorName ? affairYnergie.proprietorName : '', Validators.required],
      finalCustomerFirstname: [''],
      workSiteStreetNumber: [''],
      workSiteAddress: [affairYnergie.address, Validators.required],
      workSiteAdditionalAddress: [''],
      workSitePostalCode: ['', Validators.required],
      workSiteLocality: [affairYnergie.locality, Validators.required]
    });
  }

  public initScenarioForm(affairYnergie: AffairYnergie): FormGroup {
    return this.formBuilder.group({
      affairYnergieId: [affairYnergie.id, Validators.required],
      name: ['', Validators.required],
      scenarioNumber: ['', Validators.required],
      estimateEnergyGain: [0, Validators.required],
      initialEnergyTag: ['', Validators.required],
      projectEnergyTag: ['', Validators.required],
      description: ['', Validators.required],
      initialCep: [0, Validators.required],
      projectCep: [0, Validators.required],
      initialCef: [0, Validators.required],
      projectCef: [0, Validators.required],
      initialGes: [0, Validators.required],
      projectGes: [0, Validators.required],
      globalUse: ['']
    });
  }

  setSubmitButton(button: SubmitButton): void {
    this.submitButton = button;
  }


}
