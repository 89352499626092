import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { InvoiceStatusService, ThingService, SwalService, DataTableColumn } from '@eros-front/services';
import { InvoiceStatus } from '@eros-front/models';

@Component({
  selector: 'app-settings-invoicing-invoices-statuses-index',
  templateUrl: './settings-invoicing-invoices-statuses-index.component.html',
  styleUrls: ['./settings-invoicing-invoices-statuses-index.component.scss']
})
export class SettingsInvoicingInvoicesStatusesIndexComponent implements OnInit, AfterViewInit, OnDestroy {

  public datatableSelector = 'invoices-statuses-datatable';
  public status: InvoiceStatus = null;
  public status$: Subscription;
  public labelsClasses = [];
  public labelsClasses$: Subscription;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'label',
      name: 'Rendu',
      searchable: false,
      orderable: false
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];

  constructor(
    private invoiceStatusService: InvoiceStatusService,
    private thingService: ThingService,
    private swalService: SwalService
  ) { }

  ngOnInit() {
    this.getValues();
    this.initSubscriptions();
  }

  ngAfterViewInit() {
    this.invoiceStatusService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    this.status$.unsubscribe();
    this.labelsClasses$.unsubscribe();
  }

  onShowModalAddEditStatus() {
    this.invoiceStatusService.showModalAddEdit();
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function() {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.invoiceStatusService.get(id);
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function() {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le statut facture sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.invoiceStatusService.delete(id);
          }
        });
      }
    });
  }

  private getValues() {
    this.thingService.getLabelsClassesSelect();
  }

  private initSubscriptions() {
    this.status$ = this.invoiceStatusService.invoiceStatus$.subscribe(
      (status) => {
        if (status) {
          this.status = status;
          this.onShowModalAddEditStatus();
        }
      }
    );
    this.labelsClasses$ = this.thingService.labelsClasses$.subscribe(
      (labelsClasses) => {
        if (labelsClasses) {
          this.labelsClasses = labelsClasses;
        }
      }
    );
  }

}
