import { DataTableColumn } from '@eros-front/services';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ContactService } from '@eros-front/services';

@Component({
  selector: 'app-customers-consult-contacts-index',
  templateUrl: './customers-consult-contacts-index.component.html',
  styleUrls: ['./customers-consult-contacts-index.component.scss']
})
export class CustomersConsultContactsIndexComponent implements OnInit, AfterViewInit {
  public columns: DataTableColumn[] = [
    {
      attr: 'lastname',
      name: 'Nom'
    },
    {
      attr: 'firstname',
      name: 'Prénom'
    },
    {
      attr: 'professionName',
      searchColumn: 'profession.name',
      name: 'Fonction'
    },
    {
      attr: 'phone',
      name: 'Tél 1'
    },
    {
      attr: 'phoneSecondary',
      name: 'Tél 2'
    },
    {
      attr: 'email',
      name: 'Email 1'
    },
    {
      attr: 'emailSecondary',
      name: 'Email 2'
    },
  ];
  public datatableSelector = 'customers-contacts-datatable';

  constructor(
    private contactService: ContactService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.contactService.initDataTable(this.datatableSelector, this.columns);
  }

}
