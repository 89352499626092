import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ToggleService, ContactService } from '@eros-front/services';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-customers-consult-graph-settings',
  templateUrl: './customers-consult-graph-settings.component.html',
  styleUrls: ['./customers-consult-graph-settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomersConsultGraphSettingsComponent implements OnInit {

  @Input() group: any;
  @Input() sources: any;
  @Input() products: any;
  @Input() marketsTypes: any;
  @Input() companies: any;
  @Input() entities: any;
  @Input() defaultEntities: any;
  @Output() centerGraphOnCustomerRef = new EventEmitter();
  public isOpen = false;
  public isContactMode = false;
  private modalSyncPrices = null;
  private modalSyncManagers = null;
  private modalSyncIndicatorsTypes = null;
  private modalSyncInternalNotes = null;
  private modalSyncRivals = null;

  constructor(
    private toggleService: ToggleService,
    private contactService: ContactService
  ) { }

  ngOnInit() {
    this.toggleService.quickViewToggle.subscribe(data => {
      this.toggle();
    });
  }

  toggle() {
    if (this.isOpen) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }
  }

  onIsContactModeChange() {
    this.isContactMode = !this.isContactMode;
    this.toggleService.setContactMode(this.isContactMode);
  }

  onModalSyncPricesInitComplete(modal: ModalDirective) {
    this.modalSyncPrices = modal;
  }

  onModalSyncManagersInitComplete(modal: ModalDirective) {
    this.modalSyncManagers = modal;
  }

  onModalSyncIndicatorsTypesInitComplete(modal: ModalDirective) {
    this.modalSyncIndicatorsTypes = modal;
  }

  onModalSyncInternalNotesInitComplete(modal: ModalDirective) {
    this.modalSyncInternalNotes = modal;
  }

  onModalSyncRivalsInitComplete(modal: ModalDirective) {
    this.modalSyncRivals = modal;
  }

  onCenterGraphClicked() {
    this.centerGraphOnCustomerRef.emit();
  }

  onAddContactClicked() {
    //this.contactService.showModalAddEdit();
  }

  onSyncPricesClicked() {
    if (this.modalSyncPrices != null) {
      this.modalSyncPrices.show();
    }
  }

  onSyncManagersClicked() {
    if (this.modalSyncManagers != null) {
      this.modalSyncManagers.show();
    }
  }

  onSyncIndicatorsTypesClicked() {
    if (this.modalSyncIndicatorsTypes != null) {
      this.modalSyncIndicatorsTypes.show();
    }
  }

  onSyncInternalNotesClicked() {
    if (this.modalSyncInternalNotes != null) {
      this.modalSyncInternalNotes.show();
    }
  }

  onSyncRivalsClicked() {
    if (this.modalSyncRivals != null) {
      this.modalSyncRivals.show();
    }
  }

  resetEntitiesToDefault() {
    this.entities = this.defaultEntities;
  }
}
