import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-affair-show-shuttle-sheets',
  templateUrl: './affair-show-shuttle-sheets.component.html',
  styleUrls: ['./affair-show-shuttle-sheets.component.scss']
})
export class AffairShowShuttleSheetsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
