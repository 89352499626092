import { HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FileUtilsService, SwalService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { InvoiceTemporaryService } from '@libs/services/src/lib/invoice-temporary.service';
import { InvoiceService } from '@libs/services/src/lib/invoice.service';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-invoices-temporary-index',
  templateUrl: './invoices-temporary-index.component.html',
  styleUrls: ['./invoices-temporary-index.component.scss']
})
export class InvoicesTemporaryIndexComponent implements OnInit, OnDestroy {

  @Input() agencies: any[];
  @Input() status: any[];
  @Input() products: any[];
  @Input() mlAgencies: any[];
  public refresh = false;
  public reset = false;
  public form: FormGroup;
  public storageKey = 'invoices-temporary-filters-form';
  public checkedIds: number[] = [];
  public isDestroyed$ = new Subject<void>();

  constructor(
    private titleService: Title,
    private invoiceTemporaryService: InvoiceTemporaryService,
    private storageService: FormLocalStorageService,
    private fileUtils: FileUtilsService,
    private swalService: SwalService
  ) {
    this.titleService.setTitle(`Liste des factures non validées${tabSuffix}`);
  }

  ngOnInit() {
    const sessionFormValue = this.storageService.retrieve(this.storageKey);
    this.form = this.invoiceTemporaryService.getFiltersForm(sessionFormValue);
  }

  ngOnDestroy(): void {
    this.isDestroyed$?.next();
    this.isDestroyed$?.complete();
  }

  onValidateSelectedClicked(): void {
    this.invoiceTemporaryService.validateSelected(this.checkedIds)
      .pipe(
        catchError(error => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
        takeUntil(this.isDestroyed$)
      )
      .subscribe(
        (success) => {
          this.swalService.showSwalSuccess(success);
          this.onRefreshClicked();
        }
      );
  }

  getCountSelectedRows(): number {
    return this.checkedIds.length;
  }


  resetForm(): void {
    this.reset = !this.reset;
    const resetForm = this.invoiceTemporaryService.getFiltersForm();
    this.form.reset(resetForm.value);
  }

  onRefreshClicked(): void {
    this.refresh = !this.refresh;
  }

  onCheckedIdsChange(values): void {
    this.checkedIds = values;
  }

}
