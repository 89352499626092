import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  @Input() entries;
  @Output() onClickedEntry = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onClickEntry(entry) {
    this.onClickedEntry.emit(entry);
  }

}
