import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AffairMarketType } from '@libs/enum/src';
import { Affair } from '@libs/models/src';
import { FormFormatterService } from '@libs/services/src';
import { AutocompleteOptions } from '@libs/services/src/lib/classes/autocomplete-options';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-affair-form-final-customer-informations',
  templateUrl: './affair-form-final-customer-informations.component.html',
  styleUrls: ['./affair-form-final-customer-informations.component.scss']
})
export class AffairFormFinalCustomerInformationsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() affair: Affair;
  public affairMarketTypeEnum = AffairMarketType;
  public autocompleteOptions: AutocompleteOptions = new AutocompleteOptions();

  constructor(
    private formFormatterService: FormFormatterService
  ) { }

  ngOnInit() {
  }

  getMarketTypeValue() {
    if (this.form) {
      return this.form.value.marketTypeId;
    }
  }

  isCollectiveMarket() {
    return this.getMarketTypeValue() == this.affairMarketTypeEnum.COLLECTIVE || this.getMarketTypeValue() == this.affairMarketTypeEnum.TERTIARY;
  }

  isOtherMarket() {
    return this.getMarketTypeValue() == this.affairMarketTypeEnum.STANDALONE_HOUSE
      || this.getMarketTypeValue() == this.affairMarketTypeEnum.RENOVATION
      || this.getMarketTypeValue() == this.affairMarketTypeEnum.INSPECTION;
  }
}
