import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Quotation } from '@libs/models/src';
import { QuotationService } from '@libs/services/src/lib/quotation.service';
import { Subscription } from 'rxjs';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-quotation-show',
  templateUrl: './quotation-show.component.html',
  styleUrls: ['./quotation-show.component.scss']
})
export class QuotationShowComponent implements OnInit, OnDestroy {
  private quotation$: Subscription;
  public quotation: Quotation;

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private quotationService: QuotationService
  ) {
    this.initSubscriptions();
  }

  ngOnInit(
  ) {
  }

  ngOnDestroy(): void {
    this.quotation$.unsubscribe();
    this.quotationService.reset();
  }

  getQuotationStatusLabelClass() {
    return this.quotation.quotationStatus.labelClass;
  }

  getQuotationStatusName() {
    return this.quotation.quotationStatus.name;
  }

  getDate() {
    return this.quotation.dateFormatted;
  }

  getDateValidity() {
    return this.quotation.dateValidityFormatted;
  }

  getEntityName() {
    return 'Devis';
  }

  getNumber() {
    return this.quotation.number;
  }

  private initSubscriptions(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = Number(params.get('id'));
      if (!isNaN(id)) {
        this.quotationService.get(id);
      }
    });

    this.quotation$ = this.quotationService.quotation$.subscribe(
      (data) => {
        if (data) {
          this.quotation = data;
          this.initWhenQuotation();
        }
      }
    );
  }

  private initWhenQuotation() {
    this.titleService.setTitle(`Gestion devis : ${this.quotation.number}${tabSuffix}`);

  }



}
