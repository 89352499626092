// Angular Core
import { NgModule } from '@angular/core';

// Libs
import { SharedModule } from '@eros-front/shared';

// Components
import { AffairShowBuildingsComponent } from './affair-show/affair-show-buildings/affair-show-buildings.component';
import { AffairShowBuildingsModalPartComponent } from './affair-show/affair-show-buildings/affair-show-buildings-modal-part/affair-show-buildings-modal-part.component';
import { AffairShowBuildingsPartTemplateComponent } from './affair-show/affair-show-buildings/affair-show-buildings-part-template/affair-show-buildings-part-template.component';
import { AffairShowBuildingsBuildingTemplateComponent } from './affair-show/affair-show-buildings/affair-show-buildings-building-template/affair-show-buildings-building-template.component';
import { AffairShowBuildingsModalBuildingComponent } from './affair-show/affair-show-buildings/affair-show-buildings-modal-building/affair-show-buildings-modal-building.component';
import { AffairShowBuildingsModalAssociateComponent } from './affair-show/affair-show-buildings/affair-show-buildings-modal-associate/affair-show-buildings-modal-associate.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    AffairShowBuildingsComponent,
    AffairShowBuildingsModalPartComponent,
    AffairShowBuildingsPartTemplateComponent,
    AffairShowBuildingsBuildingTemplateComponent,
    AffairShowBuildingsModalBuildingComponent,
    AffairShowBuildingsModalAssociateComponent
  ],
  exports: [
    AffairShowBuildingsComponent
  ]
})
export class BuildingModule { }
