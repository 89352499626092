import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { Intervention, SubmitButton } from '@libs/models/src';
import { CommonService, InterventionService, NotifService, SubmitButtonService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-intervention-edit-program-modal',
  templateUrl: './intervention-edit-program-modal.component.html',
  styleUrls: ['./intervention-edit-program-modal.component.scss']
})
export class InterventionEditProgramModalComponent implements OnInit {
  @Input() intervention: Intervention;
  public showTime = {
    nzMinuteStep: 15,
    nzFormat: 'HH:mm'
  }
  @Input() form: FormGroup;
  public submitButton: SubmitButton;
  public faHistory = faHistory;
  public isConfirmed = false;
  public isOpenOnce = false;
  public defaultDate = null;

  constructor(
    private modal: NzModalRef,
    private interventionService: InterventionService,
    private submitButtonService: SubmitButtonService,
    private notifService: NotifService
  ) { }

  ngOnInit() {
    this.setSubmitButton();

    this.form.get('isTimeSlot').valueChanges.subscribe(value => {
      if (value) {
        this.defaultDate = null;
        this.isOpenOnce = false;
        this.form.controls['startDate'].setValidators(null);
        this.form.controls['duration'].setValidators(null);
        this.form.controls['startDate'].updateValueAndValidity();
        this.form.controls['duration'].updateValueAndValidity();
        this.form.controls['timeSlot'].setValidators(Validators.required);
      }
    });
  }

  submitProgramIntervention() {
    if (this.form.valid) {
      this.interventionService.program(this.intervention.id, this.form).subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
    }
  }

  onDatepickerOpening() {
    if (!this.isOpenOnce) {
      this.defaultDate = new Date().setMinutes(0);
      this.isOpenOnce = true;
    }
  }

  onDateRangePickerOpening() {
    if (!this.isOpenOnce) {
      this.defaultDate = [new Date().setMinutes(0), null];
      this.isOpenOnce = true;
    }
  }

  private setSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'l\'intervention',
      text: 'Programmer',
      icon: faHistory
    });
    this.interventionService.setSubmitButtonProgram(this.submitButton);
  }

  isTimeSlotChecked() {
    if (this.form) {
      return this.form.value.isTimeSlot === '1';
    }
    return false;
  }

  public isValidForm(): boolean {
    if (this.form) {
      return this.form.valid;
    }
    return false;
  }
}
