import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RegexService } from '@libs/services/src';

@Component({
  selector: 'input-email',
  templateUrl: './input-email.component.html',
  styleUrls: ['./input-email.component.scss']
})
export class InputEmailComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() inputClass: string;
  @Input() inputFormControlName: string;
  public emailMask = null;
  public emailPatterns = null;

  constructor(private regexService: RegexService) { }

  ngOnInit() {
    this.emailMask = this.regexService.getEmailMask();
    this.emailPatterns = this.regexService.getEmailPattern();
  }

}
