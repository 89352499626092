import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from './utilities/common.service';
import { EventType } from '@libs/models/src';
import { ModelWithModalCrud } from './classes/model-modal-crud';
import { SelectService } from './utilities/select.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DataTableColumn } from './classes/model-datatable';


@Injectable()
export class EventTypeService extends ModelWithDatatableAndCrudObservable {

    private route = '/events-types';
    public eventsTypes$ = new BehaviorSubject<EventType>(undefined);
    public eventsTypesSelect$ = new BehaviorSubject<EventType>(undefined);


    constructor(
        public http: HttpClient,
        public notifService: NotifService,
        public submitButtonService: SubmitButtonService,
        public swalService: SwalService,
        public apiService: ApiService,
        public router: Router,
        private selectService: SelectService,
        private commonService: CommonService,
        private formBuilder: FormBuilder
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }


    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public store(form: any): Observable<any> {
        return super.store(form.value, this.route);
    }

    public update(id: number, form: any): Observable<any> {
        return super.update(id, form.value, this.route);
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route);
    }

    public getAll(): Observable<any> {
        return this.apiService.get(`${this.route}`);
        /*.subscribe(
            (events: any) => {
                this.eventsTypes$.next(events);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );*/
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route);
    }

    public getForm(eventType: EventType): FormGroup {
        const name = eventType ?
            this.commonService.valueToFormString(eventType.name) : this.commonService.getDefaultFormNullValue();
        const color = eventType ?
            this.commonService.valueToFormColor(eventType.color) : this.commonService.getDefaultFormColorValue();
        const labelClass = eventType ?
            this.commonService.valueToFormColor(eventType.labelClass) : this.commonService.getDefaultFormColorValue();
        return this.formBuilder.group({
            name: [name, Validators.required],
            color: [color, Validators.required],
            labelClass: [labelClass, Validators.required],
        });
    }

    public getForSelect(): void {
        this.selectService.getForSelect(this.eventsTypesSelect$, this.route);
    }
}
