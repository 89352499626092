import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { DocumentCustomerService, DataTableColumn, SwalService, SelectService, ThingService } from '@eros-front/services';
import { TextTagService } from '@libs/services/src/lib/text-tag.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-settings-products-documents-customers-index',
  templateUrl: './settings-products-documents-customers-index.component.html',
  styleUrls: ['./settings-products-documents-customers-index.component.scss']
})
export class SettingsProductsDocumentsCustomersIndexComponent implements OnInit, AfterViewInit, OnDestroy {

  public datatableSelector = 'documents-customers-datatable';
  public document: any = null;
  public document$: Subscription;
  public extensions$: Subscription;
  public extensions: any[];
  public textTags: any;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'filename',
      name: 'Nom du fichier'
    },
    {
      attr: 'extensions',
      name: 'Extensions'
    },
    {
      attr: 'pcVisible',
      name: 'Visble portail',
      searchable: false,
      orderable: false
    },
    {
      attr: 'active',
      name: 'Actif',
      searchable: false,
      orderable: false
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];

  constructor(
    private documentService: DocumentCustomerService,
    private swalService: SwalService,
    private thingService: ThingService,
    private textTagService: TextTagService
  ) { }

  ngOnInit() {
    this.initSubscriptions();
    this.thingService.getExtensionsSelect();

    this.textTagService.getAll().subscribe(
      (data) => {
        if (data) {
          this.textTags = data;
        }
      }
    )
  }

  ngAfterViewInit() {
    this.documentService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    this.document$.unsubscribe();
  }

  onShowModalAddEditDocumentCustomer() {
    this.documentService.showModalAddEdit();
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#documents-customers-datatable').on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.documentService.get(id);
      }
    });
    $('#documents-customers-datatable').on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le document client sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.documentService.delete(id);
          }
        });
      }
    });
  }

  private initSubscriptions() {
    this.document$ = this.documentService.documentCustomer$.subscribe(
      (document) => {
        if (document) {
          this.document = document;
          this.onShowModalAddEditDocumentCustomer();
        }
      }
    );
    this.extensions$ = this.thingService.extensions$.subscribe(
      (data) => {
        if (data) {
          this.extensions = data;

        }
      }
    )

  }

}
