import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotifService, SwalService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { TextTagService } from '@libs/services/src/lib/text-tag.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { TextTagsModalComponent } from '../text-tags-modal/text-tags-modal.component';

@Component({
  selector: 'app-text-tags-index',
  templateUrl: './text-tags-index.component.html',
  styleUrls: ['./text-tags-index.component.scss']
})
export class TextTagsIndexComponent implements OnInit {
  public datatableSelector = 'text-tags-datatable';
  public form: FormGroup;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'key',
      name: 'Clé',
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'tag',
      name: 'Balise'
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];
  public textTag = null;
  private textTag$: Subscription;

  constructor(
    private textTagService: TextTagService,
    private swalService: SwalService,
    private modalService: NzModalService,
    private notifService: NotifService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.textTagService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    if (this.textTag$) {
      this.textTag$.unsubscribe();
    }
  }

  onShowModalAddEdit() {
    this.form = this.textTagService.getForm(this.textTag);
    const modalInstance = this.modalService.create({
      nzContent: TextTagsModalComponent,
      nzComponentParams: {
        textTag: this.textTag,
        form: this.form
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.textTag = null;
    })
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.textTag$ = that.textTagService.get(id).subscribe(
          (textTag) => {
            if (textTag) {
              that.textTag = textTag;
              that.onShowModalAddEdit();
            }
          }
        );
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'La balise texte sera supprimée de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.textTagService.delete(id).subscribe(
              (success) => {
                this.notifService.showSwalSuccess(success);
              }
            );
          }
        });
      }
    });
  }

}
