import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CustomerService, CustomersModalSync } from '@eros-front/services';
import { NgSelectMultipleComponent } from '@eros-front/components';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-customers-consult-graph-modal-sync-managers',
  templateUrl: './customers-consult-graph-modal-sync-managers.component.html',
  styleUrls: ['./customers-consult-graph-modal-sync-managers.component.scss']
})
export class CustomersConsultGraphModalSyncManagersComponent extends CustomersModalSync implements OnInit, AfterViewInit, OnDestroy {

  @Input() group: any;
  @Input() sources: any;
  @Input() entities: any;
  @Input() companies: any;
  @ViewChild('customersConsultGraphSyncManagers', { static: false }) childModal: ModalDirective;
  @ViewChild('consultGraphModalSyncManagersSelectEntities', { static: false }) childSelectEntities: NgSelectMultipleComponent;
  @ViewChild('consultGraphModalSyncManagersSelectCompanies', { static: false }) childSelectCompanies: NgSelectMultipleComponent;
  @Output() onModalInitComplete = new EventEmitter<any>();
  @Output() onModalClosed = new EventEmitter<any>();
  private custService: CustomerService;
  public selectedSource: string;
  public selectedEntities: any[];
  public selectedCompanies: any[];
  public selectedPilotMode: number;

  constructor(
    customerService: CustomerService
  ) {
    super(customerService, 4);
    this.custService = customerService;
  }

  ngOnInit() {
    this.initValues();
    this.setSelectEntitiesFromSource(this.selectedSource);
    this.initSubscriptions();
  }

  ngAfterViewInit() {
    this.onModalInitComplete.emit(this.childModal);
  }

  ngOnDestroy() {
    this.previewResult$.unsubscribe();
  }

  onModalHidden() {
    this.initValues();
    this.onModalClosed.emit();
  }

  onSelectedSourceChanged() {
    this.setSelectEntitiesFromSource(this.selectedSource);
    this.getPreviewResult();
  }

  isValidForm() {
    return (this.selectedSource != null
      && this.selectedEntities.length > 0
      && this.selectedCompanies.length > 0
      && !isNaN(Number(this.selectedPilotMode)));
  }

  getPreviewResult() {
    if (this.isValidForm()) {
      this.custService.getPreviewOfSyncManagers({
        sourceRef: this.selectedSource,
        entitiesRefs: this.selectedEntities.map(x => x.value),
        mlCompanies: this.selectedCompanies.map(x => x.value),
        mode: this.selectedPilotMode
      });
    } else {
      this.previewResult = [];
    }
  }

  onApplyClicked() {

  }

  private initValues() {
    this.selectedSource = this.group.ref;
    this.selectedEntities = [];
    this.selectedCompanies = [];
    this.selectedPilotMode = 0;
    this.selectedStep = 0;
    this.previewResult = [];
    if (this.childSelectEntities) {
      this.childSelectEntities.clearSelectedItems();
    }
    if (this.childSelectCompanies) {
      this.childSelectCompanies.clearSelectedItems();
    }
  }

  private initSubscriptions() {
    this.previewResult$ = this.custService.previewOfSyncManagers$.subscribe(
      (data) => {
        if (data) {
          this.previewResult = data;
        }
      }
    );
  }
}
