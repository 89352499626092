import { DataTableInstanceParameters, DataTableColumn,RegionService, SwalService  } from '@eros-front/services';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-settings-invoicing-regions-index',
  templateUrl: './settings-invoicing-regions-index.component.html',
  styleUrls: ['./settings-invoicing-regions-index.component.scss']
})
export class SettingsInvoicingRegionsIndexComponent implements OnInit, AfterViewInit, OnDestroy {

  public datatableSelector = 'regions-datatable';
  public region: any = null;
  public region$: Subscription;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'color',
      name: 'Couleur',
      orderable: false,
      searchable: false
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];

  private params: DataTableInstanceParameters;
  private additionalData: any;

  constructor(
    private regionService: RegionService,
    private swalService: SwalService
  ) { }

  ngOnInit() {
    this.initSubscriptions();
  }

  ngAfterViewInit() {
    this.regionService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    this.region$.unsubscribe();
  }

  onShowModalAddEditRegion() {
    this.regionService.showModalAddEdit();
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function() {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.regionService.get(id);
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function() {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'La région sera supprimée de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.regionService.delete(id);
          }
        });
      }
    });
  }

  private initSubscriptions() {
    this.region$ = this.regionService.region$.subscribe(
      (region) => {
        if (region) {
          this.region = region;
          this.onShowModalAddEditRegion();
        }
      }
    );
  }

}
