import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SubmitButton } from '@eros-front/models';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss']
})
export class SubmitButtonComponent implements OnInit {

  @Input() isValidForm: boolean;
  @Input() submitButton: SubmitButton;
  @Input() isXxlButton = false;
  @Output() onButtonClicked = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  submitForm() {
    this.onButtonClicked.emit();
  }

}
