import { Router } from '@angular/router';
import { debounce, debounceTime, distinctUntilChanged, mergeMap, takeUntil } from 'rxjs/operators';
import { fromEvent, Subject, Subscription, timer } from 'rxjs';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CustomerService } from '@libs/services/src';

@Component({
  selector: 'app-customers-search',
  templateUrl: './customers-search.component.html',
  styleUrls: ['./customers-search.component.scss']
})
export class CustomersSearchComponent implements OnInit, OnDestroy {
  @ViewChild('searchField') searchField: ElementRef;
  public value: string = '';
  public items: any;
  public delayTime = 1000;
  public groupsLength = 0;
  public brandsLength = 0;
  public agenciesLength = 0;
  public contactsLength = 0;
  private key = 'customers-search';
  public searchTextChanged = new Subject<string>();
  private customersSearch$: Subscription;

  constructor(
    private router: Router,
    private customerService: CustomerService,
  ) {
    this.customersSearch$ = this.searchTextChanged.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      mergeMap(search => this.customerService.search(search))
    )
      .subscribe((data) => {
        this.items = data;
        this.groupsLength = data.groups ? data.groups.length : 0;
        this.brandsLength = data.brands ? data.brands.length : 0;
        this.agenciesLength = data.agencies ? data.agencies.length : 0;
        this.contactsLength = data.contacts ? data.contacts.length : 0;

      });
  }

  ngOnInit() {
    if (localStorage.getItem(this.key)) {
      this.value = localStorage.getItem(this.key);
      this.searchTextChanged.next(this.value);
    }
  }

  ngOnDestroy() {
    this.customersSearch$.unsubscribe();
  }

  searchKeyPress($event) {
    localStorage.setItem(this.key, $event.target.value);
    this.searchTextChanged.next($event.target.value);
  }

  redirectToShow(event) {
    const ref = event.searchable.ref;
    if (ref) {
      this.router.navigateByUrl(`customers/${ref}/show`);
    }
  }

  redirectToShowContact(event) {
    const contact = event.searchable;
    const firstAgency = contact.agencies[0];
    if (firstAgency && firstAgency.ref) {
      const lastname = contact.lastname.toLocaleLowerCase();
      this.router.navigateByUrl(`customers/${firstAgency.ref}/show/search/${lastname}`);
    }
  }

  clearSearch() {
    this.value = '';
    localStorage.setItem(this.key, this.value);
    this.searchTextChanged.next(this.value);
  }

}
