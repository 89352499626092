import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AffairMonitoringTypeService, SendSettingService, SwalService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AffairsMonitoringTypesModalComponent } from '../affairs-monitoring-types-modal/affairs-monitoring-types-modal.component';

@Component({
  selector: 'app-affairs-monitoring-types-index',
  templateUrl: './affairs-monitoring-types-index.component.html',
  styleUrls: ['./affairs-monitoring-types-index.component.scss']
})
export class AffairsMonitoringTypesIndexComponent implements OnInit, OnDestroy {
  public datatableSelector = 'affairs-monitoring-types-datatable';
  public form: FormGroup;
  public affairMonitoringType: any = null;
  public affairMonitoringType$: Subscription;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'abbreviation',
      name: 'Abréviation',
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'icon',
      name: 'Icône'
    },
    {
      attr: 'color',
      name: 'Couleur'
    },
    {
      attr: 'defaultText',
      name: 'Texte par défaut',
      orderable: false,
      searchable: false
    },
    {
      attr: 'isAbm',
      name: 'Utilisé par ABM',
      orderable: false,
      searchable: false
    },
    {
      attr: 'isNrj',
      name: 'Utilisé par NRJ',
      orderable: false,
      searchable: false
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];

  constructor(
    private affairMonitoringTypeService: AffairMonitoringTypeService,
    private swalService: SwalService,
    private modalService: NzModalService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.affairMonitoringTypeService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    if (this.affairMonitoringType$) {
      this.affairMonitoringType$.unsubscribe();
    }
  }

  onShowModalAddEditModal() {
    this.form = this.affairMonitoringTypeService.getForm(this.affairMonitoringType);
    const modalInstance = this.modalService.create({
      nzContent: AffairsMonitoringTypesModalComponent,
      nzComponentParams: {
        affairMonitoringType: this.affairMonitoringType,
        form: this.form
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.affairMonitoringType = null;
    })
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.affairMonitoringType$ = that.affairMonitoringTypeService.get(id).subscribe(
          (standardResponse) => {
            if (standardResponse) {
              that.affairMonitoringType = standardResponse;
              that.onShowModalAddEditModal();
            }
          }
        );
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le type de suivi d\'affaire sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.affairMonitoringTypeService.delete(id).subscribe(
              (success) => {
                this.swalService.showSwalSuccess(success);
              }
            );
          }
        });
      }
    });
  }

}
