import { ApiService } from './../../../api/src/lib/api.service';
import { SelectService } from './utilities/select.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotifService } from './utilities/notif.service';

@Injectable()
export class ThingService {

    public vatRates$ = new BehaviorSubject<any>(undefined);
    public fieldsTypes$ = new BehaviorSubject<any>(undefined);
    public fieldsRules$ = new BehaviorSubject<any>(undefined);
    public labelsClasses$ = new BehaviorSubject<any>(undefined);
    public extensions$ = new BehaviorSubject<any>(undefined);
    public googleCalendarColors$ = new BehaviorSubject<any>(undefined);
    public entitiesClassesValues$ = new BehaviorSubject<any>(undefined);
    public availableEntitiesClasses$ = new BehaviorSubject<any>(undefined);
    public availableMonitoringTypesEntitiesClasses$ = new BehaviorSubject<any>(undefined);
    public selectMyLinkCompanies$ = new BehaviorSubject<any>(undefined);
    public shiftOrigins$ = new BehaviorSubject<any>(undefined);
    public payersPreferences$ = new BehaviorSubject<any>(undefined);
    public paymentsModes$ = new BehaviorSubject<any>(undefined);
    public days$ = new BehaviorSubject<any>(undefined);
    public outlookCategories$ = new BehaviorSubject<any>(undefined);
    public affairsMeetingsPhases$ = new BehaviorSubject<any>(undefined);
    public brandsPurchasesOrders$ = new BehaviorSubject<any>(undefined);

    constructor(
        private apiService: ApiService,
        private notifService: NotifService,
        private selectService: SelectService
    ) { }

    getVatRatesSelect(): void {
        this.apiService.get('/things/vat-rates/select')
            .subscribe(
                (vatRates) => {
                    this.vatRates$.next(vatRates);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération des taux de T.V.A.'
                    });
                }
            );
    }

    getFieldsTypesSelect(): void {
        this.apiService.get('/things/fields-types/select')
            .subscribe(
                (fieldsTypes) => {
                    this.fieldsTypes$.next(fieldsTypes);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération des taux des types de champs'
                    });
                }
            );
    }

    getFieldsRulesSelect(): void {
        this.apiService.get('/things/fields-rules/select')
            .subscribe(
                (fieldsRules) => {
                    this.fieldsRules$.next(fieldsRules);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération des taux des règles de champs'
                    });
                }
            );
    }

    getLabelsClassesSelect(): void {
        this.apiService.get('/things/labels/select')
            .subscribe(
                (labelsClasses) => {
                    this.labelsClasses$.next(labelsClasses);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération des classes de labels'
                    });
                }
            );
    }

    getExtensionsSelect(): void {
        this.apiService.get('/things/extensions/select')
            .subscribe(
                (extensions) => {
                    this.extensions$.next(extensions);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération des extensions'
                    });
                }
            );
    }

    getGoogleCalendarColorsSelect(): void {
        this.apiService.get('/things/google-calendar-colors/select')
            .subscribe(
                (googleCalendarColors) => {
                    this.googleCalendarColors$.next(googleCalendarColors);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération des extensions'
                    });
                }
            );
    }

    getEntitiesClassesValues(entityClass): void {
        this.apiService.post('/things/entity-class/select', {
            'entityClass': entityClass
        })
            .subscribe(
                (entityClassesValues) => {
                    this.entitiesClassesValues$.next(entityClassesValues);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération des valeurs des classes d\'entités'
                    });
                }
            );
    }

    getAvailableEntitiesClasses(settingType): void {
        this.apiService.get('/things/available-entity-class/' + settingType + '/select')
            .subscribe(
                (availableEntitiesClasses) => {
                    this.availableEntitiesClasses$.next(availableEntitiesClasses);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération des valeurs des classes d\'entités'
                    });
                }
            );
    }

    getAvailableMonitoringTypesEntityClass(): void {
        this.apiService.get('/things/available-monitoring-types-entity-class/select')
            .subscribe(
                (availableEntitiesClasses) => {
                    this.availableMonitoringTypesEntitiesClasses$.next(availableEntitiesClasses);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération des valeurs des classes d\'entités'
                    });
                }
            );
    }

    getMyLinkCompanies(): void {
        this.selectService.getForSelect(this.selectMyLinkCompanies$, '/things/mylink-companies');
    }

    getShiftOriginSelect(): void {
        this.apiService.get('/things/shift-origins/select')
            .subscribe(
                (shiftOrigins) => {
                    this.shiftOrigins$.next(shiftOrigins);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération des extensions'
                    });
                }
            );
    }

    getPayersPreferences(): void {
        this.apiService.get('/things/payers-preferences/select')
            .subscribe(
                (payersPreferences) => {
                    this.payersPreferences$.next(payersPreferences);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération des préférences payeurs'
                    });
                }
            );
    }

    getPaymentsModesSelect(): void {
        this.apiService.get('/things/payments-modes/select')
            .subscribe(
                (paymentsModes) => {
                    this.paymentsModes$.next(paymentsModes);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération des préférences payeurs'
                    });
                }
            );
    }

    getDaysSelect(): void {
        this.apiService.get('/things/days/select')
            .subscribe(
                (days) => {
                    this.days$.next(days);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération des jours'
                    });
                }
            );
    }

    getOutlookCategories(): void {
        this.apiService.get('/things/outlook-categories/select')
            .subscribe(
                (days) => {
                    this.outlookCategories$.next(days);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération des catégories Outlook'
                    });
                }
            );
    }

    getAffairsMeetingsPhases(): void {
        this.apiService.get('/things/affairs-meetings-phases/select')
            .subscribe(
                (phases) => {
                    this.affairsMeetingsPhases$.next(phases);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération des catégories Outlook'
                    });
                }
            );
    }

    getBrandsPurchasesOrders(): void {
        this.apiService.get('/things/brands-purchases-orders/select')
            .subscribe(
                (brands) => {
                    this.brandsPurchasesOrders$.next(brands);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération des marques bons de commandes'
                    });
                }
            );
    }



}
