import { Component, OnInit } from '@angular/core';
import { NotifService } from '@libs/services/src';
import { ApiConnectionService } from '@libs/services/src/lib/common/api-connection.service';

@Component({
  selector: 'eros-front-settings-connections',
  templateUrl: './settings-connections.component.html',
  styleUrls: ['./settings-connections.component.scss']
})
export class SettingsConnectionsComponent implements OnInit {
  public ynergieToken = null;

  constructor(
    private apiConnectionService: ApiConnectionService,
    private notifService: NotifService,
  ) { }

  ngOnInit() {
    this.getYnergieToken();
  }

  onInitializeYnergieConnectionClicked(): void {
    this.apiConnectionService.initializeYnergieConnection().subscribe(
      (response) => {
        this.notifService.showSuccessNotif(response);
        this.getYnergieToken();
      }
    )
  }

  private getYnergieToken() {
    this.apiConnectionService.getYnergieToken().subscribe(
      (response) => {
        this.ynergieToken = response.token;
      }
    )
  }

}
