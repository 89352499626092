import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SendSettingService, StandardResponseService, SwalService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { SendSettingsModalComponent } from '../send-settings-modal/send-settings-modal.component';

@Component({
  selector: 'app-send-settings-index',
  templateUrl: './send-settings-index.component.html',
  styleUrls: ['./send-settings-index.component.scss']
})
export class SendSettingsIndexComponent implements OnInit {
  public datatableSelector = 'send-settings-datatable';
  public form: FormGroup;
  public sendSetting: any = null;
  public sendSetting$: Subscription;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'key',
      name: 'Clé',
    },
    {
      attr: 'name',
      name: 'Nom',
    },
    {
      attr: 'description',
      name: 'Description'
    },
    {
      attr: 'standardsResponsesNames',
      name: 'Réponses standardisées',
      orderable: false,
      searchable: false
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];
  private standardsResponsesSelect$: Subscription;
  public standardsResponses: any;

  constructor(
    private sendSettingService: SendSettingService,
    private standardResponseService: StandardResponseService,
    private swalService: SwalService,
    private modalService: NzModalService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.standardResponseService.getForSelect();
  }

  ngAfterViewInit() {
    this.sendSettingService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    if (this.sendSetting$) {
      this.sendSetting$.unsubscribe();
    }
  }

  onShowModalAddEditModal() {
    this.form = this.sendSettingService.getForm(this.sendSetting);
    const modalInstance = this.modalService.create({
      nzContent: SendSettingsModalComponent,
      nzComponentParams: {
        sendSetting: this.sendSetting,
        standardsResponses: this.standardsResponses,
        form: this.form
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.sendSetting = null;
    })
  }

  private initSubscriptions() {
    this.standardsResponsesSelect$ = this.standardResponseService.standardsResponsesSelect$.subscribe(
      (data) => {
        if (data) {
          this.standardsResponses = data;
        }
      }
    )

  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.sendSetting$ = that.sendSettingService.get(id).subscribe(
          (standardResponse) => {
            if (standardResponse) {
              that.sendSetting = standardResponse;
              that.onShowModalAddEditModal();
            }
          }
        );
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le paramètre d\'envoi sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.sendSettingService.delete(id).subscribe(
              (success) => {
                this.swalService.showSwalSuccess(success);
              }
            );
          }
        });
      }
    });
  }

}
