import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@libs/api/src';
import { Observable } from 'rxjs';

@Injectable()
export class FileService {

    private route = '/files';

    constructor(
        private apiService: ApiService
    ) { }

    public getBlob(url: string): Observable<Blob> {
        return this.apiService.getFile('/file/download/' + url);
    }

    public stream(url: string): Observable<HttpResponse<Blob>> {
        return this.apiService.postBlob('/file/stream/' + url, []);
    }

    public thumbnail(path: string): Observable<Blob> {
        return this.apiService.getFile('/file/thumbnail/' + path);
    }

    public downloadMultiple(paths: string[]): Observable<HttpResponse<Blob>> {
        return this.apiService.postBlob(`${this.route}/download`, {
            'selectedPaths': paths
        });
    }

    public getPreviewUrl(path: string): string {
        return this.apiService.getApiUrl() + '/file/stream/' + path
    }



}
