import { Injectable } from '@angular/core';
import { ShuttleSheet } from '@libs/models/src';

@Injectable()
export class ShuttleSheetGlobalUtilities {

  constructor(
  ) {
  }

  getLabel(shuttleSheet: ShuttleSheet, category: string, object: string): string[] {
    return shuttleSheet[category][object].label;
  }

  getItems(shuttleSheet: ShuttleSheet, category: string, object: string): string[] {
    return shuttleSheet[category][object].items;
  }

  getSelected(shuttleSheet: ShuttleSheet, category: string, object: string): string[] {
    return shuttleSheet[category][object].selected;
  }

  getReversibility(shuttleSheet: ShuttleSheet, category: string, object: string): boolean {
    return shuttleSheet[category][object].reversibility;
  }

  isInHabitableSurface(shuttleSheet: ShuttleSheet, category: string, object: string): boolean {
    return shuttleSheet[category][object].isInHabitableSurface;
  }

}
