import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AffairMarketType } from '@libs/enum/src';
import { Affair } from '@libs/models/src';
import { MarketType } from '@libs/models/src/lib/market-type.model';
import { FormFormatterService } from '@libs/services/src';
import { AutocompleteOptions } from '@libs/services/src/lib/classes/autocomplete-options';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-affair-form-work-site-informations',
  templateUrl: './affair-form-work-site-informations.component.html',
  styleUrls: ['./affair-form-work-site-informations.component.scss']
})
export class AffairFormWorkSiteInformationsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() affair: Affair;
  @Input() agencies: any;
  @Input() worksCompanies: any;
  @Input() marketsTypes: MarketType[];
  public autocompleteOptions: AutocompleteOptions = new AutocompleteOptions();
  public marketTypeEnum = AffairMarketType;

  constructor(
    private formFormatterService: FormFormatterService
  ) { }

  ngOnInit() {
  }


  onWorkSiteAddressChange(address: Address) {
    this.form = this.formFormatterService.completeGoogleAddress(
      this.form, address,
      {
        street_number: 'workSiteStreetNumber',
        route: 'workSiteAddress',
        postal_code: 'workSitePostalCode',
        locality: 'workSiteLocality'
      }
    );
  }

  isRenovationMarket() {
    return this.affair.marketTypeId == this.marketTypeEnum.RENOVATION;
  }

}
