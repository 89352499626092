import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FileService, FileUtilsService } from '@libs/services/src';
import { InvoiceService } from '@libs/services/src/lib/invoice.service';

@Component({
  selector: 'invoice-thumbnail',
  templateUrl: './invoice-thumbnail.component.html',
  styleUrls: ['./invoice-thumbnail.component.scss']
})
export class InvoiceThumbnailComponent implements OnInit {

  @Input() index: number;
  @Input() currentHoverIndex: number;
  @Input() currentTabIndex: number = 0;
  @Input() invoice: any;
  @Input() isMinified: boolean = false;
  @Output() mouseOverThumbnails = new EventEmitter();
  @Output() onThumbnailSelected = new EventEmitter();
  @Input() isSelected = false;

  constructor(
    private fileService: FileService,
    private router: Router,
    private invoiceService: InvoiceService,
    private fileUtils: FileUtilsService
  ) { }

  ngOnInit() {
  }

  onMouseOverThumbnails(index: number) {
    this.mouseOverThumbnails.emit(index);
  }

  onEdit(event: any) {
    event.stopPropagation();
    this.router.navigateByUrl(`invoices/${this.invoice.id}/show`);
  }

  onDivSelected() {
    this.isSelected = !this.isSelected;
    this.onThumbnailSelected.emit(this.invoice);
  }

  onShow(event: any) {
    event.stopPropagation();
    this.invoiceService.generateReport(this.invoice.id).subscribe(
      (invoice) => {
        this.fileUtils.openPreviewDocument(invoice.path, 'application/pdf');
      }
    )
  }

  onDownload(event: any) {
    event.stopPropagation();
    this.invoiceService.download(this.invoice.id).subscribe(
      (file: HttpResponse<Blob>) => {
        this.fileUtils.responseToDownload(file, 'pdf');
      }
    );
  }

  onDownloadXmlClicked() {
    this.invoiceService.getXmlIgc(this.invoice.id).subscribe((response) => {
      this.fileUtils.responseToDownload(response, 'xml');
    });
  }

  onDownloadIgcClicked() {
    this.invoiceService.downloadForIgc(this.invoice.id).subscribe((response) => {
      this.fileUtils.responseToDownload(response, 'pdf');
    });
  }
}
