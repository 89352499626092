import { AuthentificationService } from './authentification.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';


@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private authService: AuthentificationService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.isTokenExpired()) {
      return true;
    }
    // not logged in so redirect to login page
    this.router.navigate(['/login']), { queryParams: { returnUrl: state.url } };
    return false;
  }
}