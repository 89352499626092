import { Component, OnInit } from '@angular/core';
import { UserService } from '@libs/services/src';
import { PhoneLineService } from '@libs/services/src/lib/phone-line.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ovh-phones-status',
  templateUrl: './ovh-phones-status.component.html',
  styleUrls: ['./ovh-phones-status.component.scss']
})
export class OvhPhonesStatusComponent implements OnInit {
  public phonesLinesStatuses$: Subscription;
  public phonesLinesStatuses: any;
  public colors = ['green', 'red', 'orange', 'grey']

  constructor(
    private phoneLineService: PhoneLineService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.phoneLineService.getPhonesLinesStatuses();
  }

  initSubscriptions() {
    this.phonesLinesStatuses$ = this.phoneLineService.phonesLinesStatuses$.subscribe(
      (data) => {
        if (data) {
          this.phonesLinesStatuses = data;
        }
      }
    )
  }
}
