import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AffairProductsToInvoicingTabs } from '@libs/enum/src';
import { SelectModel } from '@libs/models/src';
import { AffairProductService, FormFormatterService, NotifService, SwalService } from '@libs/services/src';
import { InvoiceService } from '@libs/services/src/lib/invoice.service';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { tabSuffix } from '_config/tab-suffix';
import { AffairsProductsToInvoicingSelectTempoModalComponent } from '../affairs-products-to-invoicing-select-tempo-modal/affairs-products-to-invoicing-select-tempo-modal.component';


interface AffairProductListItem {
  productName: string;
  agencyName: string;
  affairMlRef: string;
  affairFinalsCustomersFullnames: string;
  affairWorkSiteFullAddress: string;
}

@Component({
  selector: 'app-affair-products-to-invoicing-index',
  templateUrl: './affair-products-to-invoicing-index.component.html',
  styleUrls: ['./affair-products-to-invoicing-index.component.scss']
})


export class AffairProductsToInvoicingIndexComponent implements OnInit {
  @Input() productKey: string;
  @Input() productTab: AffairProductsToInvoicingTabs;
  @Input() agencies: SelectModel[];
  @Input() mlAgencies: SelectModel[];
  @Input() productsStatuses: SelectModel[];
  @Input() payers: SelectModel[];
  @Input() payersPreferences;
  public affairProducts: any;
  private storageKey;
  public form: FormGroup;
  checked = false;
  loading = false;
  indeterminate = false;
  listOfData: readonly any[] = [];
  listOfCurrentPageData: readonly any[] = [];
  setOfCheckedId = new Set<number>();
  setOfCheckedAffairProducts = new Set<any>();
  public invoicingAffairsProductsForm: FormGroup;
  public listOfColumn = [];
  public pageSize = 500;

  constructor(
    private titleService: Title,
    private affairProductService: AffairProductService,
    private storageService: FormLocalStorageService,
    private modalService: NzModalService,
    private formBuilder: FormBuilder,
    private invoiceService: InvoiceService,
    private swalService: SwalService,
    private formFormatterService: FormFormatterService,
    private notifService: NotifService
  ) {
  }

  ngOnInit() {
    this.initValues();
    this.initListFromStorage();
    this.listOfColumn = this.initListOfColmun();
    if (this.productKey === 'ebb') {
      this.pageSize = 50;
    }
  }


  initList() {
    this.loading = true;
    this.affairProductService.initList(this.productKey, this.form).subscribe(
      (data) => {
        if (data) {
          this.affairProducts = data;
          this.loading = false;
          if (this.affairProducts.length == 0) {
            this.refreshCheckedStatus();
          }
        }
      }
    );
  }

  initValues() {
    this.storageKey = `affair-products-${this.productKey}-filters-form`;
    this.titleService.setTitle(`Facturation : ${this.productTab.text}${tabSuffix}`);
  }

  resetForm() {
    this.setOfCheckedId = new Set<number>();
    this.setOfCheckedAffairProducts = new Set<any>();
    const resetForm = this.affairProductService.getFiltersForm();
    this.form.reset(resetForm.value);
  }

  onCurrentPageDataChange(listOfCurrentPageData: readonly any[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    let affairProduct = this.affairProducts.find(x => x.id == id);
    if (checked) {
      this.setOfCheckedId.add(id);
      this.setOfCheckedAffairProducts.add(affairProduct);
    } else {
      this.setOfCheckedId.delete(id);
      this.setOfCheckedAffairProducts.delete(affairProduct);
    }
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData.filter(({ disabled }) => !disabled);
    this.checked = listOfEnabledData.every(({ id }) => this.setOfCheckedId.has(id));
    this.indeterminate = listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
  }


  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.listOfCurrentPageData
      .filter(({ disabled }) => !disabled)
      .forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  public isValidForm() {
    return this.form.valid;
  }

  generateInvoicesAffairProducts(draftMode?: boolean) {
    this.swalService.showSwalLoading('Génération des factures en cours...');
    this.invoicingAffairsProductsForm = this.initInvoicingAffairsProductsForm();
    if (draftMode) {
      this.invoicingAffairsProductsForm.patchValue({ 'isTemporary': true })
    }
    this.invoiceService.storeForAffairProducts(this.invoicingAffairsProductsForm)
      .pipe(
        catchError(error => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      )
      .subscribe(
        (success) => {
          this.refreshList();
          this.swalService.showSwalSuccess(success);
        }
      )
  }

  onOpenAffairProductsToInvoicingSelectTempoModal() {
    const modalInstance = this.modalService.create({
      nzWidth: '60%',
      nzContent: AffairsProductsToInvoicingSelectTempoModalComponent,
      nzComponentParams: {
        productKey: this.productKey,
        affairProducts: Array.from(this.setOfCheckedAffairProducts),
      },
      nzStyle: { top: '2vh' }
    });
    modalInstance.afterClose.subscribe(() => {
      this.initListFromStorage();
      this.onAllChecked(false);
    });


  }

  public refreshList() {
    this.setOfCheckedId = new Set<number>();
    this.setOfCheckedAffairProducts = new Set<any>();
    if (this.isValidForm()) {
      this.initList();
    }
  }

  private initListFromStorage() {
    const sessionFormValue = this.storageService.retrieve(this.storageKey);
    this.form = this.affairProductService.getFiltersForm(sessionFormValue);
    if (this.isValidForm()) {
      this.initList();
    }

    this.form.valueChanges.subscribe(form => {
      this.storageService.store(this.storageKey, this.form)
      if (this.isValidForm()) {
        this.initList();
      } else {
        this.affairProducts = [];
      }
    });
  }

  public calcSumSelected() {
    let sum = 0.0;
    this.setOfCheckedAffairProducts.forEach((elem) => {
      sum += elem.price * elem.quantity;
    })
    return sum;
  }


  initInvoicingAffairsProductsForm(): FormGroup {
    return this.formBuilder.group({
      productKey: this.productKey,
      affairProducts: [Array.from(this.setOfCheckedAffairProducts), Validators.required],
      mlAgencyId: this.formFormatterService.formatSelectSingle(this.form.value.mlAgencyId),
      isTemporary: false
    })
  }

  public openAffair(affairId: number) {
    window.open('/affairs/' + affairId + '/show');
  }


  public openAgency(ref: string) {
    window.open('/customers/' + ref + '/show');
  }

  public onCanceledClicked(affairProductId) {

    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'Le code produit sera annulé et l\'affaire sera archivée'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.affairProductService.cancel(affairProductId).subscribe(
          (success) => {
            this.notifService.showSuccessNotif(success);
            that.refreshList();
          }
        )
      }
    });
  }

  private initListOfColmun() {
    return [
      {
        title: 'Produit',
        priority: false
      },
      {
        title: 'Client',
        sortFn: (a: AffairProductListItem, b: AffairProductListItem) => a.agencyName.localeCompare(b.agencyName),
      },
      {
        title: 'Réf',
        sortFn: (a: AffairProductListItem, b: AffairProductListItem) => a.affairMlRef.localeCompare(b.affairMlRef),
      },
      {
        title: 'Noms/Prénoms',
        sortFn: (a: AffairProductListItem, b: AffairProductListItem) => a.affairFinalsCustomersFullnames.localeCompare(b.affairFinalsCustomersFullnames),
      },
      {
        title: 'Adresse',
        priority: false
      },
      {
        title: 'Statut',
        priority: false
      },
      {
        title: 'Tarif',
        priority: false
      },
      {
        title: 'Quantité',
        priority: false
      }
    ]
  }

}
