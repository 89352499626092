import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ContactService, ProfessionService } from '@eros-front/services';
import { FormGroup } from '@angular/forms';
import { SubmitButton } from '@eros-front/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-customers-consult-graph-modal-add-contact',
  templateUrl: './customers-consult-graph-modal-add-contact.component.html',
  styleUrls: ['./customers-consult-graph-modal-add-contact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomersConsultGraphModalAddContactComponent implements OnInit, AfterViewInit {

  @Input() group: any;
  @ViewChild('customersConsultGraphAddContact', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public submitButton: SubmitButton;
  public professions = [];
  public professions$: Subscription;
  public groups = [];
  public indicatorsContacts = [];
  public notificationCheckboxes = [];

  constructor(
    private contactService: ContactService,
    private professionService: ProfessionService
  ) { }

  ngOnInit() {
    this.initValues();
    this.getValues();
    this.initSubscriptions();
  }

  ngAfterViewInit() {
    //this.contactService.setModalAddEdit(this.childModal);
  }

  onModalShown() {
    this.determinateSubmitButton();
    this.contactService.setSubmitButton(this.submitButton);
    this.form = this.contactService.getForm();
  }

  onModalHidden() {
    this.initValues();
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm()) {
      this.contactService.store(this.form);
    }
  }

  onNotificationAllClicked(evt: any) {
    for (const item of this.notificationCheckboxes) {
      item.checked = evt.target.checked;
    }
  }

  isValidForm(): boolean {
    return this.form.valid;
  }

  private initValues() {
    this.form = null;
    this.groups.push(this.group);
    this.notificationCheckboxes = [
      {
        id: 'consult-graph-modal-add-contact-affair-create',
        label: 'à la création d\'affaire',
        checked: false
      },
      {
        id: 'consult-graph-modal-add-contact-document-update',
        label: 'lorsqu\'un document est disponible',
        checked: false
      },
      {
        id: 'consult-graph-modal-add-contact-delivered-update',
        label: 'lorsqu\'un document rendu est disponible',
        checked: false
      },
      {
        id: 'consult-graph-modal-add-contact-shuttle-sheet-update',
        label: 'lorsqu\'une modification est apportée à la fiche navette',
        checked: false
      },
      {
        id: 'consult-graph-modal-add-contact-status-update',
        label: 'lorsque le statut de l\'affaire est modifié',
        checked: false
      },
      {
        id: 'consult-graph-modal-add-contact-affair-archived',
        label: 'lorsque l\'affaire est archivée',
        checked: false
      },
      {
        id: 'consult-graph-modal-add-contact-user-create',
        label: 'lorsqu\'un contact est créé sur son agence',
        checked: false
      },
      {
        id: 'consult-graph-modal-add-contact-invoice-create',
        label: 'lorsqu\'une facture ou un devis est disponible',
        checked: false
      },
      {
        id: 'consult-graph-modal-add-contact-payment-receive',
        label: 'lorsqu\'un paiement est réceptionné',
        checked: false
      },
      {
        id: 'consult-graph-modal-add-contact-reminder',
        label: 'pour une relance de facture impayée',
        checked: false
      }
    ];
  }

  private getValues() {
    this.professionService.getForSelect();
  }

  private initSubscriptions() {
    this.professions$ = this.professionService.selectProfessions$.subscribe(
      (data) => {
        if (data) {
          this.professions = data;
        }
      }
    );
  }

  private determinateSubmitButton() {
    this.submitButton = this.contactService.determinateSubmitButton(null, 'le contact');
  }

}
