import { Subscription } from 'rxjs';
import { ParamMap, ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { hideAll } from 'tippy.js';
import cytoscape from 'cytoscape';
import * as shape from 'd3-shape';
import { CustomerService, GroupService, BrandService, AgencyService, PayerService } from '@eros-front/services';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'app-customers-consult-graph',
  templateUrl: './customers-consult-graph.component.html',
  styleUrls: ['./customers-consult-graph.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomersConsultGraphComponent implements OnInit, OnDestroy {
  nodes: any[];
  edges: any[];
  style: {};
  layout: {};
  curve = shape.curveLinear;
  createPermission = true;
  private graph$: Subscription;
  private group$: Subscription;
  private entities$: Subscription;
  private selects$: Subscription;
  private brand$: Subscription;
  private agency$: Subscription;
  private payers$: Subscription;
  public customerRef: string;
  public graph: cytoscape.Core;
  public searchList = [];
  public selectedSearch = null;
  public group = null;
  public brand = null;
  public agency = null;
  public products = [];
  public companies = [];
  public mlAgencies = [];
  public assistants = [];
  public technicians = [];
  public marketsTypes = [];
  public customersTypes = [];
  public indicatorsCustomers = [];
  public contactFrequencies = [];
  public paymentDeadlines = [];
  public paymentDeadlineTypes = [];
  public billingPreferences = [];
  public payerPreferences = [];
  public payers = [];
  public librariesBuilder = [];
  public managers = [];
  public rivals = [];
  public defaultEntities = [];
  public entities = [];
  public sources = [];

  constructor(
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private groupService: GroupService,
    private brandService: BrandService,
    private agencyService: AgencyService,
    private payerService: PayerService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.getValues();
    this.showGraph();
  }

  ngOnDestroy() {
    this.graph$.unsubscribe();
    this.group$.unsubscribe();
    this.entities$.unsubscribe();
    this.selects$.unsubscribe();
    this.brand$.unsubscribe();
    this.agency$.unsubscribe();
    this.payers$.unsubscribe();
  }

  onGraphInitComplete(graph: cytoscape.Core): void {
    const that = this;
    this.graph = graph;
    hideAll({ duration: 0 });
    // Group menu
    const groupCommands = [{
      // content: '<img src onerror="tippy(\'#group-menu-span-edit\');">' +
      //   '<span id="group-menu-span-edit" data-tippy-content="Éditer">' +
      //   '<i class="fas fa-pen tll-24"></i></span>',
      content: '<i class="fas fa-pen tll-24"></i>',
      contentStyle: { 'pointer-events': 'all' },
      select: function (ele) {
        that.onEditGroupClicked();
      }
    },
    {
      content: '<i class="fas fa-shopping-basket tll-24"></i>',
      contentStyle: { 'pointer-events': 'all' }
    },
    {
      content: '<i class="fas fa-chart-pie tll-24"></i>',
      contentStyle: { 'pointer-events': 'all' }
    }];
    // Brand menu
    const brandCommands = [{
      content: '<i class="fas fa-pen tll-24"></i>',
      contentStyle: { 'pointer-events': 'all' },
      select: function (ele) {
        that.brandService.getByRef(ele.data('id'));
      }
    },
    {
      content: '<i class="fas fa-shopping-basket tll-24"></i>',
      contentStyle: { 'pointer-events': 'all' }
    },
    {
      content: '<i class="fas fa-chart-pie tll-24"></i>',
      contentStyle: { 'pointer-events': 'all' }
    }];
    // Agency menu
    const agencyCommands = [{
      content: '<i class="fas fa-pen tll-24"></i>',
      contentStyle: { 'pointer-events': 'all' },
      select: function (ele) {
        that.agencyService.getByRef(ele.data('id'));
      }
    },
    {
      content: '<i class="fas fa-shopping-basket tll-24"></i>',
      contentStyle: { 'pointer-events': 'all' }
    },
    {
      content: '<i class="fas fa-chart-pie tll-24"></i>',
      contentStyle: { 'pointer-events': 'all' }
    },
    {
      content: '<i class="fas fa-chart-line tll-24"></i>',
      contentStyle: { 'pointer-events': 'all' }
    }];
    // Contact menu
    const contactCommands = [{
      content: '<i class="fas fa-pen tll-24"></i>',
      contentStyle: { 'pointer-events': 'all' }
    },
    {
      content: '<i class="fas fa-trash tll-24"></i>',
      contentStyle: { 'pointer-events': 'all' }
    },
    {
      content: '<i class="far fa-user tll-24"></i>',
      contentStyle: { 'pointer-events': 'all' }
    }];
    // Check creation permission
    if (this.createPermission) {
      groupCommands.push({
        content: '<i class="far fa-copyright tll-24"></i>',
        contentStyle: { 'pointer-events': 'all' },
        select: function () {
          that.brand = null;
          that.onShowBrandModalClicked();
        }
      });
      brandCommands.push({
        content: '<i class="fas fa-home tll-24"></i>',
        contentStyle: { 'pointer-events': 'all' },
        select: function () {
          that.agency = null;
          that.onShowAgencyModalClicked();
        }
      });
    }
    // Menu creation
    this.graph.cxtmenu({
      menuRadius: this.getDefaultCtxRadiusMenu(),
      selector: 'node[type=\'group\']',
      commands: groupCommands,
      minSpotlightRadius: 6,
      maxSpotlightRadius: 6,
      indicatorSize: 6,
      zIndex: 9999
    });
    this.graph.cxtmenu({
      menuRadius: this.getDefaultCtxRadiusMenu(),
      selector: 'node[type=\'brand\']',
      commands: brandCommands,
      minSpotlightRadius: 6,
      maxSpotlightRadius: 6,
      indicatorSize: 6,
      zIndex: 9999
    });
    this.graph.cxtmenu({
      menuRadius: this.getDefaultCtxRadiusMenu(),
      selector: 'node[type=\'agency\']',
      commands: agencyCommands,
      minSpotlightRadius: 6,
      maxSpotlightRadius: 6,
      indicatorSize: 6,
      zIndex: 9999
    });
    this.graph.cxtmenu({
      menuRadius: this.getDefaultCtxRadiusMenu(),
      selector: 'node[type=\'contact\']',
      commands: contactCommands,
      minSpotlightRadius: 6,
      maxSpotlightRadius: 6,
      indicatorSize: 6,
      zIndex: 9999
    });
    // Set the center on ref argument
    this.setZoomAndCenterGraph(this.customerRef);
  }

  onEditGroupClicked() {
  }

  onShowBrandModalClicked() {
  }

  onShowAgencyModalClicked() {
  }

  onSelectSearch(match: TypeaheadMatch) {
    this.selectedSearch = null;
    this.setZoomAndCenterGraph(match.item.id);
  }

  centerGraphOnCustomerRef() {
    this.setZoomAndCenterGraph(this.customerRef);
  }

  private showGraph() {
    this.graph$ = this.customerService.graph$.subscribe((graph) => {
      if (graph) {
        this.nodes = graph.nodes;
        this.edges = graph.edges;

        this.layout = {
          name: 'dagre',
          rankDir: 'TB',
          fit: false,
          condense: true
        };

        for (let node of this.nodes) {
          this.searchList.push({
            id: node.data.id,
            name: node.data.name,
            color: node.data.color,
            search: node.data.id + node.data.name
          })
        }
      }
    });
  }

  private getValues() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.customerRef = params.get('id');
    });
    this.customerService.getGraph(this.customerRef);
    this.customerService.getGroupByCustomerRef(this.customerRef);
    this.customerService.getSelectsForConsultCustomers();
    this.payerService.getAll();
  }

  private initSubscriptions() {
    this.group$ = this.customerService.groupByCustomerRef$.subscribe(
      (data) => {
        if (data) {
          this.group = data;
          this.sources = this.initSourcesSelect(this.group);
        }
      }
    );
    this.entities$ = this.customerService.entitiesBySourceRef$.subscribe(
      (data) => {
        if (data) {
          this.entities = this.entitiesDataToArray(data);
          if (this.defaultEntities.length <= 0) {
            this.defaultEntities = this.entities;
          }
        }
      }
    );
    this.selects$ = this.customerService.selects$.subscribe(
      (data) => {
        if (data) {
          this.products = data.products;
          this.companies = data.mlCompanies;
          this.mlAgencies = data.mlAgencies;
          this.assistants = data.assistants;
          this.technicians = data.technicians;
          this.marketsTypes = this.marketsAndCustomersTypesDataToArray(data.marketsTypes);
          this.customersTypes = this.marketsAndCustomersTypesDataToArray(data.customersTypes);
          this.indicatorsCustomers = this.indicatorsCustomersDataToArray(data.indicatorsCustomers);
          this.contactFrequencies = data.contactFrequencies;
          this.paymentDeadlines = data.paymentDeadlines;
          this.paymentDeadlineTypes = data.paymentDeadlineTypes;
          this.billingPreferences = data.billingPreferences;
          this.payerPreferences = data.payerPreferences;
          this.librariesBuilder = data.librariesBuilder;
          this.rivals = data.rivals;
          this.managers = data.managers;
        }
      }
    );
    this.brand$ = this.brandService.brand$.subscribe(
      (data) => {
        if (data) {
          this.brand = data;
          this.onShowBrandModalClicked();
        }
      }
    );
    this.agency$ = this.agencyService.agency$.subscribe(
      (data) => {
        if (data) {
          this.agency = data;
          this.onShowAgencyModalClicked();
        }
      }
    );
    this.payers$ = this.payerService.payers$.subscribe(
      (data) => {
        if (data) {
          this.payers = data;
        }
      }
    );
  }

  private marketsAndCustomersTypesDataToArray(data: any[]): any[] {
    const result = [];
    for (const item of data) {
      result.push({
        value: item.id,
        label: item.name,
        icon: 'tll fa-' + item.tllIcon
      });
    }
    return result;
  }

  private indicatorsCustomersDataToArray(data: any[]): any[] {
    const result = [];
    for (const item of data) {
      result.push({
        value: item.id,
        label: item.name,
        icon: item.svgIcon
      });
    }
    return result;
  }

  private initSourcesSelect(group: any): any[] {
    const sources = [];
    sources.push(
      this.getSelectItemWithIcon(group, 'fas fa-users')
    );
    for (const item of group.brands) {
      sources.push(
        this.getSelectItemWithIcon(item, 'far fa-copyright')
      );
    }
    return sources;
  }

  private entitiesDataToArray(data: any): any[] {
    const result = [];
    if (data.hasOwnProperty('brands')) {
      for (const item of data.brands) {
        result.push(
          this.getSelectItemWithIcon(item, 'far fa-copyright')
        );
      }
    }
    if (data.hasOwnProperty('agencies')) {
      for (const item of data.agencies) {
        result.push(
          this.getSelectItemWithIcon(item, 'fas fa-home', ', ' + item.postalCode + ' ' + item.locality)
        );
      }
    }
    return result;
  }

  private getSelectItemWithIcon(item: any, icon: string, additionalName?: string): any {
    let label = item.name;
    if (additionalName != null) {
      label += additionalName;
    }
    return {
      value: item.ref,
      label: label,
      icon: icon
    };
  }

  private getDefaultCtxRadiusMenu() {
    return 10;
  }

  private setZoomAndCenterGraph(ref: string): void {
    const elem = this.graph.getElementById(ref);
    this.graph.zoom(0.5);
    this.graph.center(elem);
  }
}
