import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormFormatterService } from './utilities/form-formatter.service';
import { AffairPayerPreference } from '@eros-front/enum';
import { DataTableColumn, ModelWithDatatable } from './classes/model-datatable';
import { CommonService } from './utilities/common.service';
import { NgxSpinnerService } from 'ngx-spinner';

export interface AffairDatatableParameters {
    selectedMlAgencies: string;
    selectedAgencies: string;
    selectedWorksCompanies: string;
    selectedAssistants: string;
    selectedTechnicians: string;
    selectedProducts: string;
    selectedProductsStatuses: string;
    selectedAffairsGoals: string;
    selectedAffairsStatuses: string;
    selectedAffairsMonitoringTypes: string;
    affairMonitoringTypeLevel: number;
    isAfterIntervention: boolean;
    dateCreationMin: Date;
    dateCreationMax: Date;
    dateProductReceiptMin: Date;
    dateProductReceiptMax: Date;
}

@Injectable()
export class AffairRenovationService extends ModelWithDatatable {

    private route = '/affairs/renovation';
    public affair$ = new BehaviorSubject<any>(undefined);
    public affairProductEdit$ = new BehaviorSubject<any>(undefined);
    public affairSelects$ = new BehaviorSubject<any>(undefined);
    public affairPayerPreferenceEnum = AffairPayerPreference;
    public affairDatatableParams: AffairDatatableParameters;

    constructor(
        http: HttpClient,
        router: Router,
        apiService: ApiService,
        spinner: NgxSpinnerService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService
    ) {
        super(
            http,
            router,
            apiService,
            spinner
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[], form?: any) {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns,
            openInNewTabUrl: 'affairs/$id/show'
        }, this.formToDatatableParameters(form));
    }


    private formToDatatableParameters(form: FormGroup): AffairDatatableParameters {
        if (form) {
            return {
                selectedMlAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedMlAgencies),
                selectedAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedAgencies),
                selectedWorksCompanies: this.formFormatterService.formatSelectMultiple(form.value.selectedWorksCompanies),
                selectedAssistants: this.formFormatterService.formatSelectMultiple(form.value.selectedAssistants),
                selectedTechnicians: this.formFormatterService.formatSelectMultiple(form.value.selectedTechnicians),
                selectedProducts: this.formFormatterService.formatSelectMultiple(form.value.selectedProducts),
                selectedProductsStatuses: this.formFormatterService.formatSelectMultiple(form.value.selectedProductsStatuses),
                selectedAffairsGoals: this.formFormatterService.formatSelectMultiple(form.value.selectedAffairsGoals),
                selectedAffairsStatuses: this.formFormatterService.formatSelectMultiple(form.value.selectedAffairsStatuses),
                selectedAffairsMonitoringTypes: this.formFormatterService.formatSelectMultiple(form.value.selectedAffairsMonitoringTypes),
                affairMonitoringTypeLevel: form.value.affairMonitoringTypeLevel,
                isAfterIntervention: form.value.isAfterIntervention,
                dateCreationMin: form.value.dateCreationMin,
                dateCreationMax: form.value.dateCreationMax,
                dateProductReceiptMin: form.value.dateProductReceiptMin,
                dateProductReceiptMax: form.value.dateProductReceiptMax,
            };
        }
        return null;
    }


    public redrawDataTable(form: any): void {
        const affairDatatableParams = this.formToDatatableParameters(form);
        super.redrawDataTable(affairDatatableParams);
    }

    public getFiltersForm(formValue?: any): FormGroup {
        const selectedAssistants = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAssistants) : this.commonService.getDefaultFormNullValue();
        const selectedTechnicians = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedTechnicians) : this.commonService.getDefaultFormNullValue();
        const selectedAgencies = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAgencies) : this.commonService.getDefaultFormNullValue();
        const selectedWorksCompanies = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedWorksCompanies) : this.commonService.getDefaultFormNullValue();
        const selectedMlAgencies = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedMlAgencies) : this.commonService.getDefaultFormNullValue();
        const selectedAffairsGoals = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAffairsGoals) : this.commonService.getDefaultFormNullValue();
        const selectedAffairsStatuses = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAffairsStatuses) : this.commonService.getDefaultFormNullValue();
        const selectedProductsStatuses = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedProductsStatuses) : this.commonService.getDefaultFormNullValue();
        const selectedAffairsMonitoringTypes = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAffairsMonitoringTypes) : this.commonService.getDefaultFormNullValue();
        const affairMonitoringTypeLevel = formValue ?
            this.commonService.valueToFormNumber(formValue.affairMonitoringTypeLevel) : 1;
        const isAfterIntervention = formValue ?
            formValue.isAfterIntervention : null;
        const selectedProducts = formValue ?
            this.commonService.valueToFormSelect(formValue.products) : this.commonService.getDefaultFormNullValue();
        const dateCreationMin = formValue ?
            this.commonService.valueToFormDate(formValue.dateCreationMin) : this.commonService.getDefaultFormNullValue();
        const dateCreationMax = formValue ?
            this.commonService.valueToFormDate(formValue.dateCreationMax) : this.commonService.getDefaultFormNullValue();
        const dateProductReceiptMin = formValue ?
            this.commonService.valueToFormDate(formValue.dateProductReceiptMin) : this.commonService.getDefaultFormNullValue();
        const dateProductReceiptMax = formValue ?
            this.commonService.valueToFormDate(formValue.dateProductReceiptMax) : this.commonService.getDefaultFormNullValue();;
        return this.formBuilder.group({
            selectedAssistants: [selectedAssistants],
            selectedTechnicians: [selectedTechnicians],
            selectedAgencies: [selectedAgencies],
            selectedWorksCompanies: [selectedWorksCompanies],
            selectedMlAgencies: [selectedMlAgencies],
            selectedAffairsGoals: [selectedAffairsGoals],
            selectedAffairsStatuses: [selectedAffairsStatuses],
            selectedProductsStatuses: [selectedProductsStatuses],
            selectedProducts: [selectedProducts],
            selectedAffairsMonitoringTypes: [selectedAffairsMonitoringTypes],
            affairMonitoringTypeLevel: [affairMonitoringTypeLevel],
            isAfterIntervention: [isAfterIntervention],
            dateCreationMin: dateCreationMin,
            dateCreationMax: dateCreationMax,
            dateProductReceiptMin: dateProductReceiptMin,
            dateProductReceiptMax: dateProductReceiptMax
        });
    }
}
