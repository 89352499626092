import { ModelWithDatatableAndModalCrud } from './classes/model-datatable-modal-crud';
import { Router } from '@angular/router';
import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { BehaviorSubject } from 'rxjs';
import { DataTableColumn } from './classes/model-datatable';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { SelectService } from './utilities/select.service';

@Injectable()
export class DocumentDeliveredService extends ModelWithDatatableAndModalCrud {

    public route = '/documents-delivered';
    public documentDelivered$ = new BehaviorSubject<any>(undefined);
    public documentsDelivered$ = new BehaviorSubject<any>(undefined);
    public documentsDeliveredSelect$ = new BehaviorSubject<any>(undefined);


    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        apiService: ApiService,
        router: Router,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private selectService: SelectService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): void {
        super.get(id, this.documentDelivered$, this.route);
    }

    public getAll(): void {
        super.getAll(this.documentsDelivered$, this.route);
    }

    public store(form: any): void {
        super.store(this.formatForm(form), this.route);
    }

    public update(id: number, form: any): void {
        super.update(id, this.formatForm(form), this.route);
    }

    public delete(id: number): void {
        super.delete(id, this.route);
    }

    public getForm(document: any, allowedExtensions: any[]) {
        let name = '';
        let filename = null;
        let insituFilename = null;
        let extensions = null;
        let isActive = '0';
        let isPcVisible = '0';
        let isConsteoVisible = '0';
        if (document) {
            name = document.name ? document.name : name;
            filename = document.filename ? document.filename : filename;
            insituFilename = document.insituFilename ? document.insituFilename : insituFilename;
            extensions = allowedExtensions.filter(x => document.extensions.includes(x.value));
            isActive = document.isActive ? document.isActive.toString() : isActive;
            isPcVisible = document.isPcVisible ? document.isPcVisible.toString() : isPcVisible;
            isConsteoVisible = document.isConsteoVisible ? document.isConsteoVisible.toString() : isConsteoVisible;
        }
        return this.formBuilder.group({
            name: [name, Validators.required],
            filename: [filename],
            insituFilename: [insituFilename],
            extensions: [extensions],
            isActive: isActive,
            isPcVisible: isPcVisible,
            isConsteoVisible: isConsteoVisible,
        });
    }

    public getForSelect() {
        return this.selectService.getForSelect(this.documentsDeliveredSelect$, this.route);
    }

    private formatForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.type = this.formFormatterService.formatSelectSingle(values.type);
        values.extensions = this.formFormatterService.formatSelectMultipleToIntArray(values.extensions);
        return values;
    }
}
