import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { OfficeFile, SubmitButton } from '@libs/models/src';
import { NotifService } from '@libs/services/src';
import { OfficeFileService } from '@libs/services/src/lib/office-file.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-customers-show-offices-files-modal',
  templateUrl: './customers-show-offices-files-modal.component.html',
  styleUrls: ['./customers-show-offices-files-modal.component.scss']
})
export class CustomersShowOfficesFilesModalComponent implements OnInit {
  @Input() officeFile: OfficeFile;
  @Input() form: FormGroup;
  @Input() sendSettings: any;
  @Input() sendSettingsTypes: any;
  @Input() contacts: any;
  @Input() officesFilesIndicators: any;
  @Input() studiesOffices: any;
  public submitButton: SubmitButton;
  public selectRecipients = [];
  public selectRecipientsCopy = [];
  public displayRecipientsCopy = [];
  public faPlus = faPlus;
  public faMinus = faMinus;
  public modules = {
    'toolbar': [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
    ]
  }

  constructor(
    private modal: NzModalRef,
    private officeFileService: OfficeFileService,
    private notifService: NotifService
  ) {
  }

  ngOnInit() {
    this.determinateSubmitButton();
    this.officeFileService.setSubmitButton(this.submitButton);
    this.officeFileService.initOfficesFilesSettings(this.form, this.sendSettings, this.sendSettingsTypes, this.contacts, this.officeFile);
    this.officeFileService.initOfficesFilesIndicatorsSwitches(this.form, this.officesFilesIndicators, this.officeFile);
    this.initDisplayRecipients();
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.officeFile) {
        this.officeFileService.update(this.officeFile.id, this.form).subscribe((success) => {
          this.modal.close();
          this.notifService.showSuccessNotif(success);
        });
      } else {
        this.officeFileService.store(this.form).subscribe((success) => {
          this.modal.close();
          this.notifService.showSuccessNotif(success);
        });
      }
    }
  }

  onChangeSettingType(sendSettingId: number, sendSettingTypeKey: string, index: number): void {
    if (sendSettingTypeKey == 'contacts') {
      this.selectRecipients[sendSettingId] = !this.selectRecipients[sendSettingId];
      if (!this.selectRecipients[sendSettingId]) {
        const sendSettingsFormArray = this.form.get('officesFilesSettings') as FormArray;
        sendSettingsFormArray.at(index).patchValue({
          officesFilesRecipients: []
        })
      }
    }
  }

  onChangeSettingTypeCopy(sendSettingId: number, sendSettingTypeKey: string, index: number): void {
    if (sendSettingTypeKey == 'contacts') {
      this.selectRecipientsCopy[sendSettingId] = !this.selectRecipientsCopy[sendSettingId];
      if (!this.selectRecipientsCopy[sendSettingId]) {
        const sendSettingsCopyFormArray = this.form.get('officesFilesSettings') as FormArray;
        sendSettingsCopyFormArray.at(index).patchValue({
          officesFilesRecipientsCopy: []
        })
      }
    }
  }

  onChangeDisplayRecipientsCopy(sendSettingId: number, index: number): void {
    this.displayRecipientsCopy[sendSettingId] = !this.displayRecipientsCopy[sendSettingId];
    if (!this.displayRecipientsCopy[sendSettingId]) {
      const sendSettingsCopyFormArray = this.form.get('officesFilesSettings') as FormArray;
      sendSettingsCopyFormArray.at(index).patchValue({
        officesFilesRecipientsCopy: []
      })
      let sendSettingsCopyArrayFormArray = sendSettingsCopyFormArray.at(index).get('sendSettingsTypesCopy') as FormArray;
      let values = [];
      this.sendSettingsTypes.forEach(sendSettingType => {
        values.push({
          sendSettingTypeId: sendSettingType.id,
          checked: false
        });

      });
      sendSettingsCopyArrayFormArray.patchValue(values);
    }
  }

  private initDisplayRecipients() {
    this.sendSettings.forEach(sendSetting => {
      let display = false;
      let displayCopy = false;
      let displayRecipientsCopy = false;
      if (this.officeFile) {
        let officeFileSetting = this.officeFile.officesFilesSettings.find(x => x.sendSettingId == sendSetting.id);
        if (officeFileSetting.sendSettingsTypes) {
          let found = officeFileSetting.sendSettingsTypes.find(x => x.key === "contacts");
          if (found) {
            display = true;
          }
        }
        if (officeFileSetting.sendSettingsTypesCopy.length > 0) {
          let found = officeFileSetting.sendSettingsTypesCopy.find(x => x.key === "contacts");
          if (found) {
            displayCopy = true;
          }
          displayRecipientsCopy = true
        }
      }
      this.selectRecipients[sendSetting.id] = display;
      this.selectRecipientsCopy[sendSetting.id] = displayCopy;
      this.displayRecipientsCopy[sendSetting.id] = displayRecipientsCopy;
    });
  }

  private determinateSubmitButton() {
    this.submitButton = this.officeFileService.determinateSubmitButton(this.officeFile, 'la fiche bureau');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }



}
