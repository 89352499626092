import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { API_URL } from './authentification.shared';
import { FormGroup } from '@angular/forms';
import * as jwt_decode from 'jwt-decode';
import { CustomLocalStorageService } from '@libs/services/src/lib/utilities/custom-local-storage.service';

@Injectable()
export class AuthentificationService {
  public apiUrl;
  public token: string;
  private headers: HttpHeaders;
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(API_URL) apiUrl: string,
    private http: HttpClient,
    private router: Router,
    private localStorageService: CustomLocalStorageService
  ) {
    this.apiUrl = apiUrl;
    //append headers
    this.headers = new HttpHeaders();
    this.headers.append("Content-Type", 'application/json');
    this.headers.append("Access-Control-Allow-Origin", "*");
    this.headers.append("Access-Control-Allow-Headers", "Origin, Authorization, Content-Type, Accept");

    // set token if saved in local storage
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.token = currentUser && currentUser.token;

  }

  public getToken(): string {
    return this.token;
  }

  login(form: FormGroup): Observable<any> {
    return this.http.post(this.apiUrl + '/authenticate', form.value, { headers: this.headers })
      .pipe(
        map((response: Response) => {
          // login successful if there's a jwt token in the response
          this.token = response['accessToken'];
          let email = response['email'];
          let selectedMarketsTypes = response['marketsTypes'];
          if (this.token) {
            // store email and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user',
              JSON.stringify({ email: email, token: this.token }));
            this.loggedIn.next(true);
            if (selectedMarketsTypes) {
              this.localStorageService.setSelectedMarketsTypes(selectedMarketsTypes);
            }
            this.router.navigate(['/']);
          }
          return response;
        })
      );
  }

  logAsUser(data) {
    this.token = data['accessToken'];
    let email = data['email'];
    let selectedMarketsTypes = data['marketsTypes'];
    if (this.token) {
      // store email and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('user',
        JSON.stringify({ email: email, token: this.token }));
      this.loggedIn.next(true);
      if (selectedMarketsTypes) {
        this.localStorageService.setSelectedMarketsTypes(selectedMarketsTypes);
      }
      this.router.navigate(['/dashboard']).then(() => {
        window.location.reload();
      });
    }
    return data;
  }

  setToken(token) {
    this.token = token;
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) token = this.getToken();
    if (!token) return true;

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  getUserInfos(token?: string): any {
    const decoded = this.getDecodedToken(token);

    if (decoded.lastname === undefined) return null;
    return {
      id: decoded.id,
      email: decoded.email,
      lastname: decoded.lastname,
      firstname: decoded.firstname,
      initials: decoded.initials,
    }
  }

  getRoles(token?: string): any {
    const decoded = this.getDecodedToken(token);

    if (decoded.roles === undefined) return null;
    return decoded.roles;
  }

  getMarketsTypes(token?: string): any {
    const decoded = this.getDecodedToken(token);

    if (decoded.marketsTypes === undefined) return null;
    return decoded.marketsTypes;
  }

  isAdministrator() {
    const roles = this.getRoles();
    if (roles) {
      return roles.includes('administrator');
    }
    return false;
  }


  isManager() {
    const roles = this.getRoles();
    if (roles) {
      return roles.includes('ra');
    }
    return false;
  }

  private getDecodedToken(token?: string) {
    if (!token) token = this.getToken();
    if (!token) return false;
    return jwt_decode(token);
  }


  logout(): void {
    // clear token remove user from local storage to log user out
    this.token = null;
    localStorage.removeItem('user');
    //this.router.navigate(['login']);
  }
}
