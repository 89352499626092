import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from './utilities/common.service';
import { AffairContact, Contact } from '@libs/models/src';
import { catchError, finalize } from 'rxjs/operators';
import { ModelWithModal } from './classes/model-modal';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class AffairContactService extends ModelWithDatatableAndCrudObservable {

    private route = '/affairs-contacts';
    public contact$ = new BehaviorSubject<AffairContact>(undefined);
    public contacts$ = new BehaviorSubject<AffairContact[]>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private commonService: CommonService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public get(id: number): Observable<any> {
        return this.apiService.get(`${this.route}/${id}`);
    }

    public getAllByAffair(affairId: number): void {
        this.apiService.get(`${this.route}/affairs/${affairId}`)
            .subscribe(
                (contacts: AffairContact[]) => {
                    this.contacts$.next(contacts);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public quickStore(affairId: number, contactId: number): void {
        this.apiService.post(`${this.route}/quick-add`, {
            affairId: affairId,
            contactId: contactId
        })
            .subscribe(
                (success) => {
                    this.getAllByAffair(affairId);
                    this.notifService.showSuccessNotif(success);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public store(form: FormGroup): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.post(this.route, form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
    }

    public update(id: number, form: FormGroup): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.put(`${this.route}/${id}`, form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            );
    }

    public delete(id: number): Observable<any> {
        return this.apiService.delete(`${this.route}/${id}`)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            );
    }

    public getForm(affairId: number, contact?: any) {
        const lastname = contact ?
            this.commonService.valueToFormString(contact.lastname) : this.commonService.getDefaultFormStringValue();
        const firstname = contact ?
            this.commonService.valueToFormString(contact.firstname) : this.commonService.getDefaultFormStringValue();
        const email = contact ?
            this.commonService.valueToFormString(contact.email) : this.commonService.getDefaultFormStringValue();
        const phone = contact ?
            this.commonService.valueToFormString(contact.phone) : this.commonService.getDefaultFormStringValue();
        const comment = contact && contact.hasOwnProperty('comment') ?
            this.commonService.valueToFormString(contact.comment) : this.commonService.getDefaultFormStringValue();
        return this.formBuilder.group({
            affairId: [affairId, Validators.required],
            lastname: [lastname, Validators.required],
            firstname: firstname,
            email: email,
            phone: phone,
            comment: comment
        });
    }

    public valueToFormSelectMultiple(values: any[]): any {
        if (values != null) {
            return values.map(x => ({ label: [x.lastname, x.firstname].filter(Boolean).join(" "), value: x.id }));
        }
        return null;
    }
}
