import { Injectable } from '@angular/core';
import { ApiService } from '@libs/api/src';
import { Observable } from 'rxjs';

@Injectable()
export class ApiConnectionService {

  private route = '/connections'

  constructor(
    private apiService: ApiService
  ) { }

  getYnergieToken(): Observable<any> {
    return this.apiService.get(`${this.route}/ynergie/get-token`);
  }

  initializeYnergieConnection(): Observable<any> {
    return this.apiService.get(`${this.route}/ynergie/initialize`);
  }

}
