import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-shuttle-sheet-edit-select-values-button',
  templateUrl: './shuttle-sheet-edit-select-values-button.component.html',
  styleUrls: ['./shuttle-sheet-edit-select-values-button.component.scss']
})
export class ShuttleSheetEditSelectValuesButtonComponent implements OnInit {

  @Output() editSelectValues = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onEditSelectValues() {
    this.editSelectValues.emit();
  }

}
