import { DatatableService, DataTableColumn } from './../../../../../services/src/lib/utilities/datatable.service';
import { AgencyService } from '@eros-front/services';
import { Subscription } from 'rxjs';
import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-customers-consult-agencies-index',
  templateUrl: './customers-consult-agencies-index.component.html',
  styleUrls: ['./customers-consult-agencies-index.component.scss']
})
export class CustomersConsultAgenciesIndexComponent implements OnInit, AfterViewInit {
  public columns: DataTableColumn[] = [
    {
      attr: 'ref',
      name: 'Ref'
    },
    {
      attr: 'groupName',
      name: 'Groupe'
    },
    {
      attr: 'brandName',
      searchColumn: 'brand.name',
      name: 'Marque'
    },
    {
      attr: 'name',
      name: 'Agence'
    },
    {
      attr: 'rivalsNames',
      searchColumn: 'rivals.name',
      name: 'Concurrents',
    },
    {
      attr: 'managersNames',
      searchColumn: 'managers.lastname',
      name: 'Responsables',
    },
  ];
  public datatableSelector = 'customers-agencies-datatable';

  constructor(
    private agencyService: AgencyService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.agencyService.initDataTable(this.datatableSelector, this.columns);
  }

}
