import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { faCreditCard, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { Affair, SubmitButton } from '@libs/models/src';
import { AffairProductService, AffairService, CommonService, NotifService, SubmitButtonService } from '@libs/services/src';
import { InvoiceService } from '@libs/services/src/lib/invoice.service';
import { QuotationService } from '@libs/services/src/lib/quotation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-affair-show-quotations-generate',
  templateUrl: './affair-show-quotations-generate.component.html',
  styleUrls: ['./affair-show-quotations-generate.component.scss']
})
export class AffairShowQuotationsGenerateComponent implements OnInit, OnChanges {
  @Input() affair: Affair;
  public previewTotalWithoutTaxes = 0;
  public previewAmountVat = 0;
  public previewTotalAllTaxes = 0;
  public selectAllProducts = false;
  public form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private formBuilder: FormBuilder,
    private quotationService: QuotationService,
    private submitButtonService: SubmitButtonService,
    private affairProductService: AffairProductService,
    private affairService: AffairService,
    private notifService: NotifService
  ) {
  }

  ngOnInit() {
    if (this.affair) {
      this.form = this.quotationService.getCreateFormAffair(this.affair);
      this.addCheckboxes();
      this.updatePricePreview();
      this.form.valueChanges.subscribe(form => {
        this.updatePricePreview();
      });
    }
    this.setSubmitButton();
  }

  ngOnChanges() {
  }

  updatePricePreview() {
    this.initPricePreview();
    for (const ap of this.form.value.affairProducts) {
      if (ap.checked) {
        const apPrice = (ap.quantity * ap.price);
        const apAmountVat = (apPrice / 100) * ap.vatRate;
        this.previewTotalWithoutTaxes += apPrice;
        this.previewAmountVat += apAmountVat;
        this.previewTotalAllTaxes += apPrice + apAmountVat;
      }
    }
  }


  get affairProductsFormArray() {
    return this.form.controls.affairProducts as FormArray;
  }

  private addCheckboxes() {
    this.affair.affairProducts.forEach((affairProduct) => {
      let apSelected = affairProduct;
      this.affairProductsFormArray.push(new FormGroup({
        checked: new FormControl(false),
        affairProductId: new FormControl(apSelected.id),
        affairId: new FormControl(apSelected.affairId),
        productId: new FormControl(apSelected.productId),
        vatRate: new FormControl(apSelected.product.vatRate),
        quantity: new FormControl(apSelected.quantity, [Validators.max(affairProduct.quantity), Validators.min(1)]),
        price: new FormControl(apSelected.price, [Validators.min(0)])
      }))
    });

  }

  private initPricePreview(): void {
    this.previewTotalWithoutTaxes = 0;
    this.previewAmountVat = 0;
    this.previewTotalAllTaxes = 0;
  }

  private setSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'un devis',
      text: 'Générer',
      icon: faFileInvoice
    });
    this.quotationService.setSubmitButton(this.submitButton);
  }

  generate() {
    if (this.isValidForm()) {
      this.form.value.affairProducts = this.form.value.affairProducts.filter(v => v.checked);
      this.quotationService.storeForAffair(this.form, this.affair.id).subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.affairService.get(this.affair.id);
          this.form = this.quotationService.getCreateFormAffair(this.affair);
          this.addCheckboxes();
          this.quotationService.thumbnails(this.affair.id);
        }
      );
    }
  }

  isValidForm() {
    return this.form.valid;
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value.checked)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);
      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }

  private initForm(affair: any): FormGroup {
    return this.formBuilder.group({
      affairProducts: new FormArray([], this.minSelectedCheckboxes(1)),
      comment: null
    });
  }


}
