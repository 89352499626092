export class TelephonyTool {

  public static formatToInternational(phoneNumber: string): string {
    let phoneNumberFormatted = "";
    if (phoneNumber.startsWith("0")) {
       phoneNumberFormatted = phoneNumber.substring(1);
    } else {
      phoneNumberFormatted = phoneNumber;
    }
    return `+33${phoneNumberFormatted}`;
  }

  public static formatForFccCall(phoneNumber: string): string {
    let phoneNumberFormatted = "";
    if (phoneNumber.startsWith("0")) {
       phoneNumberFormatted = phoneNumber.substring(1);
    } else {
      phoneNumberFormatted = phoneNumber;
    }
    return `%2B33${phoneNumberFormatted}`;
  }

 public static convertFromInternational(phoneNumber: string): string {
    return phoneNumber;
  }
}
