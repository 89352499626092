import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AffairContactService, AffairService, BuildingPartService, InterventionService, ProductService } from '@eros-front/services';
import { Subscription } from 'rxjs';
import { SelectService } from '@eros-front/services';
import { Affair, AffairContact, Part, SelectModel } from '@libs/models/src';
import { AffairMarketType } from '@libs/enum/src';
import { AffairGoalService } from '@libs/services/src/lib/affair-goal.service';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'apps/eros-nrj/src/environments/environment';
import { tabSuffix } from '_config/tab-suffix';

type TAB_TYPE = 'INFORMATIONS' | 'DOCUMENTS_CUSTOMERS' | 'DOCUMENTS_DELIVERED' | 'BUILDINGS' | 'CONTACTS' | 'PROGRAMMATION'
  | 'INVOICING' | 'QUOTATIONS' | 'HISTORY' | 'YNERGIE' | 'MAILS';

@Component({
  selector: 'app-affair-show',
  templateUrl: './affair-show.component.html',
  styleUrls: ['./affair-show.component.scss']
})
export class AffairShowComponent implements OnInit, OnDestroy {

  public affair: Affair;
  private affair$: Subscription;
  public agenciesSelect = [];
  public mlAgenciesSelect = [];
  public payersByAgencySelect = [];
  private payersByAgencySelect$: Subscription;
  public affairContacts: AffairContact[] = [];
  private affairContacts$: Subscription;
  public buildingObject: Part[] = [];
  private buildingObject$: Subscription;
  public marketTypeEnum = AffairMarketType;
  public affairSelectsForShow$: Subscription;
  public products: any;
  public technicians: any;
  public productsStatuses: any;
  public marketsTypes: any;
  public affairsStatuses: any;
  public assistants: any;
  public affairsGoals: any;
  public worksCompanies: any;
  public affairProductsToProgram$: Subscription;
  public affairProductsToProgram: any = [];
  public appKey = environment.appKey;
  public affairMarketTypeEnum = AffairMarketType;
  public affairsSelectsInit = false;
  public activeTab: TAB_TYPE = 'INFORMATIONS';

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private affairService: AffairService,
    private affairContactService: AffairContactService,
    private partService: BuildingPartService,
    private selectService: SelectService,
    private spinner: NgxSpinnerService,
    private interventionService: InterventionService,
    private router: Router
  ) {
    // override the route reuse strategy
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.initSubscriptions();
  }

  ngOnInit() {
    this.initValues();
    this.getValues();
  }

  ngOnDestroy() {
    this.affair$.unsubscribe();
    this.payersByAgencySelect$.unsubscribe();
    this.affairContacts$.unsubscribe();
    this.buildingObject$.unsubscribe();
    this.affairSelectsForShow$.unsubscribe();
    this.affairService.reset();
  }

  private initValues() {
    this.affair = null;
    this.agenciesSelect = [];
    this.mlAgenciesSelect = [];
    this.payersByAgencySelect = [];
  }

  private getValues() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = Number(params.get('id'));
      if (!isNaN(id)) {
        this.spinner.show('load-affair');
        this.affairService.get(id);
        this.affairService.getSelectsForShow(id);
      }
    });
  }

  private initSubscriptions() {
    this.affair$ = this.affairService.affair$.subscribe(
      (affair) => {
        if (affair) {
          this.affair = affair;
          this.spinner.hide('load-affair');
          this.initWhenAffair();
        }
      }
    );
    this.affairSelectsForShow$ = this.affairService.affairSelectsForShow$.subscribe(
      (data) => {
        if (data) {
          this.agenciesSelect = data.agencies;
          this.mlAgenciesSelect = data.mlAgencies;
          this.products = data.products;
          this.technicians = data.technicians;
          this.productsStatuses = data.productsStatuses;
          this.marketsTypes = data.marketsTypes;
          this.affairsStatuses = data.affairsStatuses;
          this.assistants = data.assistants;
          this.affairsGoals = data.affairsGoals;
          this.worksCompanies = data.worksCompanies;
          this.affairsSelectsInit = true;
        }
      }
    )
    this.payersByAgencySelect$ = this.selectService.selectPayersByAgency$.subscribe(
      (data) => {
        if (data) {
          this.payersByAgencySelect = data;
        }
      }
    );
    this.affairContacts$ = this.affairContactService.contacts$.subscribe(
      (contacts: AffairContact[]) => {
        if (contacts) {
          this.affairContacts = contacts;
        }
      }
    );
    this.buildingObject$ = this.partService.buildingObject$.subscribe(
      (buildingObject: any) => {
        if (buildingObject) {
          this.buildingObject = buildingObject;
        }
      }
    );
    this.affairProductsToProgram$ = this.interventionService.affairsProductsToProgram$.subscribe(
      (data) => {
        if (data) {
          this.affairProductsToProgram = data;
        }
      }
    )
  }

  isRenovationMarket() {
    return this.affair.marketTypeId == this.affairMarketTypeEnum.RENOVATION;
  }

  private initWhenAffair() {
    this.titleService.setTitle('Gestion affaire : ' + this.affair.mlRef + tabSuffix);
    this.selectService.getPayersByAgencySelect(this.affair.agencyId);
    this.affairContactService.getAllByAffair(this.affair.id);
    this.partService.getBuildingsByAffair(this.affair.id);
    this.interventionService.getAffairsProductsToProgram(null, this.affair.id);
  }

}
