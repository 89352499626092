import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AffairMarketType } from '@libs/enum/src';
import { AffairGoal, AffairStatus, SelectModel } from '@libs/models/src';
import { DataTableColumn, InterventionService } from '@libs/services/src';
import { DashboardService } from '@libs/services/src/lib/dashboard.service';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-interventions-index',
  templateUrl: './interventions-index.component.html',
  styleUrls: ['./interventions-index.component.scss']
})
export class InterventionsIndexComponent implements OnInit {
  @Input() marketType: number;
  @Input() marketsTypes: SelectModel[];
  @Input() assistants: any[];
  @Input() technicians: any[];
  @Input() agencies: any[];
  @Input() conformities: any[];
  @Input() interventionsStatuses: any[];
  @Input() indicatorsSearchValues: any[] = [];
  @Input() products: any[];
  @Input() productsStatuses: any[];
  @Input() documentsCustomers: any[];
  @Input() affairsGoals: AffairGoal[];
  @Input() affairsStatuses: AffairStatus[];
  @Input() affairsMonitoringTypes: SelectModel[];
  public form: FormGroup;
  public storageKey = null;
  public interventionsData: any[];
  public refresh = false;
  public reset = false;
  public affairMarketTypeEnum = AffairMarketType;

  constructor(
    private titleService: Title,
    private interventionService: InterventionService,
    private storageService: FormLocalStorageService,
    private dashboardService: DashboardService
  ) {
  }

  ngOnInit(): void {
    this.setStorageKey();
    let storageFormValue = this.dashboardService.retrieveDashboardFilters({
      assistants: this.assistants,
      interventionsStatuses: this.interventionsStatuses,
      productsStatuses: this.productsStatuses
    });
    if (storageFormValue == null) {
      storageFormValue = this.storageService.retrieve(this.storageKey);
    }
    this.dashboardService.resetFilters();
    this.form = this.interventionService.getFiltersForm(storageFormValue, this.marketType);
    this.setTitle();
  }

  resetForm(): void {
    this.reset = !this.reset;
    const resetForm = this.interventionService.getFiltersForm(null, this.marketType);
    this.dashboardService.resetFilters();
    this.form.reset(resetForm.value);
  }

  onRefreshClicked(): void {
    this.refresh = !this.refresh;
  }

  private setStorageKey(): void {
    if (this.marketType == this.affairMarketTypeEnum.STANDALONE_HOUSE) {
      this.storageKey = 'interventions-individual-house-filters-form';
    } else if (this.marketType == this.affairMarketTypeEnum.COLLECTIVE) {
      this.storageKey = 'interventions-collective-filters-form';
    } else if (this.marketType == this.affairMarketTypeEnum.RENOVATION) {
      this.storageKey = 'interventions-renovation-filters-form';
    } else if (this.marketType == this.affairMarketTypeEnum.INSPECTION) {
      this.storageKey = 'interventions-inspection-filters-form';
    } else {
      this.storageKey = 'interventions-all-filters-form';
    }
  }

  private setTitle(): void {
    if (this.marketType == this.affairMarketTypeEnum.STANDALONE_HOUSE) {
      this.titleService.setTitle(`Maison Individuelle : Liste des interventions${tabSuffix}`);
    } else if (this.marketType == this.affairMarketTypeEnum.COLLECTIVE) {
      this.titleService.setTitle(`Col - Ter : Liste des interventions${tabSuffix}`);
    } else if (this.marketType == this.affairMarketTypeEnum.RENOVATION) {
      this.titleService.setTitle(`Rénovation : Liste des interventions${tabSuffix}`);
    } else if (this.marketType == this.affairMarketTypeEnum.INSPECTION) {
      this.titleService.setTitle(`Inspection : Liste des interventions${tabSuffix}`);
    } else {
      this.titleService.setTitle(`Tous marchés : Liste des interventions${tabSuffix}`);
    }
  }

}
