import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-shuttle-sheet-building-template',
  templateUrl: './shuttle-sheet-building-template.component.html',
  styleUrls: ['./shuttle-sheet-building-template.component.scss']
})
export class ShuttleSheetBuildingTemplateComponent implements OnInit {

  @Input() building: any;
  @Input() titlesEditable = false;
  @Input() selectsEditable = false;
  @Output() editSelectValues = new EventEmitter();
  @Output() editTitleText = new EventEmitter();
  @Output() editItemText = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onEditSelectValues(key: string) {
    this.editSelectValues.emit({
      key: key,
      showNoneOther: true
    });
  }

  onEditTitleTextClicked(key: string) {
    this.editTitleText.emit(key);
  }

  onEditItemTextClicked(key: string) {
    this.editItemText.emit(key);
  }

}
