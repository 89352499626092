import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
declare var require: any;

@Injectable()
export class FileUtilsService {

    constructor(
        private router: Router
    ) { }


    public openPreviewDocument(path: string, type: string) {
        let newRelativeUrl = this.router.createUrlTree(['document/preview/'], {
            queryParams: {
                path: path,
                type: type
            }
        });
        let baseUrl = window.location.href.replace(this.router.url, '');
        window.open(baseUrl + newRelativeUrl);
    }

    public responseToDownload(response: HttpResponse<Blob>, type: string): void {
        const blob = new Blob([response.body], { type: `application/${type}` });
        const downloadUrl = window.URL.createObjectURL(blob);
        this.downloadFile(downloadUrl, response, type);
    }


    public downloadFile(downloadUrl: string, response: HttpResponse<Blob>, type: string) {
        let filename = this.getFilename(response, type);
        let a = document.createElement("a");
        if (typeof a.download === 'undefined') {
            window.location.href = downloadUrl;
        } else {
            a.href = downloadUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
        }
    }

    public getFilename(response: HttpResponse<Blob>, type: string) {
        let filename = "";
        const disposition = response.headers.get('content-disposition');
        if (disposition) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches !== null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        } else {
            let uuidv4 = require('uuid/v4');
            filename = uuidv4() + '.' + type;
        }
        return filename;
    }

}
