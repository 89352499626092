import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { SelectModel, SubmitButton } from '@libs/models/src';
import { AutocompleteOptions, CustomerService, FormFormatterService, MarketTypeService } from '@libs/services/src';
import { CustomerTypeService } from '@libs/services/src/lib/customer-type.service';
import { IndicatorCustomerService } from '@libs/services/src/lib/indicator-customer.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Subscription } from 'rxjs';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-customers-create',
  templateUrl: './customers-create.component.html',
  styleUrls: ['./customers-create.component.scss']
})
export class CustomersCreateComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  private marketsTypes$: Subscription;
  private customersTypes$: Subscription;
  private indicatorsCustomers$: Subscription;
  public marketsTypes: any[];
  public customersTypes: any[];
  public indicatorsCustomers: any[];
  public submitButton: SubmitButton;
  public autocompleteOptions: AutocompleteOptions = new AutocompleteOptions();

  constructor(
    private titleService: Title,
    private customerService: CustomerService,
    private marketTypeService: MarketTypeService,
    private customerTypeService: CustomerTypeService,
    private indicatorCustomerService: IndicatorCustomerService,
    private formFormatterService: FormFormatterService
  ) {
    this.titleService.setTitle(`Création d'un client${tabSuffix}`);
    this.initSubscriptions();
  }

  ngOnInit() {
    this.form = this.customerService.getCreateForm();
    this.getValues();
    this.determineSubmitButton();
  }

  ngOnDestroy() {
    this.marketsTypes$.unsubscribe();
    this.customersTypes$.unsubscribe();
    this.indicatorsCustomers$.unsubscribe();
  }

  private getValues() {
    this.marketTypeService.getAll();
    this.customerTypeService.getAll();
    this.indicatorCustomerService.getAll();
  }

  private initSubscriptions() {
    this.marketsTypes$ = this.marketTypeService.getAll().subscribe(
      (data) => {
        if (data) {
          this.marketsTypes = this.marketsAndCustomersTypesDataToArray(data);
        }
      }
    )
    this.customersTypes$ = this.customerTypeService.getAll().subscribe(
      (data) => {
        if (data) {
          this.customersTypes = this.marketsAndCustomersTypesDataToArray(data);
        }
      }
    )
    this.indicatorsCustomers$ = this.indicatorCustomerService.getAll().subscribe(
      (data) => {
        if (data) {
          this.indicatorsCustomers = this.indicatorsCustomersDataToArray(data);
        }
      }
    )
  }

  onAddressChanged(address: Address) {
    this.form = this.formFormatterService.completeGoogleAddress(
      this.form, address,
      {
        street_number: 'agencyStreetNumber',
        route: 'agencyAddress',
        postal_code: 'agencyPostalCode',
        locality: 'agencyLocality'
      }
    );
  }

  onChangeName() {
    if (this.form) {
      this.form.patchValue({ 'name': this.form.value.name.toUpperCase() })
      this.form.patchValue({ 'brandName': this.form.value.name.toUpperCase() })
      this.form.patchValue({ 'agencyName': this.form.value.name.toUpperCase() })
    }
  }

  isValidForm() {
    return this.form.valid;
  }

  onSubmit() {
    if (this.isValidForm()) {
      this.customerService.create(this.form);
    }
  }

  private marketsAndCustomersTypesDataToArray(data: any[]): any[] {
    const result = [];
    for (const item of data) {
      result.push({
        value: item.id,
        label: item.name,
        icon: 'tll fa-' + item.tllIcon
      });
    }
    return result;
  }

  private indicatorsCustomersDataToArray(data: any[]): any[] {
    const result = [];
    for (const item of data) {
      result.push({
        value: item.id,
        label: item.name,
        icon: item.svgIcon
      });
    }
    return result;
  }

  private determineSubmitButton() {
    this.submitButton = this.customerService.determinateSubmitButton(null, 'le client')
    this.customerService.setSubmitButton(this.submitButton);
  }

}
