import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-root-login-layout',
  templateUrl: './root-login-layout.component.html',
  styleUrls: ['./root-login-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RootLoginLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
