import { Calendar } from '@fullcalendar/core';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventApi, EventClickArg } from '@fullcalendar/common';
import { AffairProduct, Event, Intervention, SelectModel, User } from '@libs/models/src';
import { ConformityService, ContactSelectParameters, ContactService, EventService, EventTypeService, InterventionService, NotifService, SettingService, SwalService, UserService } from '@libs/services/src';
import { Subject, Subscription, throwError, zip } from 'rxjs';
import frLocale from '@fullcalendar/core/locales/fr';
import interactionPlugin, { Draggable, DropArg, EventResizeDoneArg } from '@fullcalendar/interaction';
import { DateUtilsService } from '@libs/services/src/lib/utilities/date-utils.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import Swal from 'sweetalert2';
import { catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { InterventionStatusEnum } from '@libs/enum/src';
import { NzModalService } from 'ng-zorro-antd/modal';
import { InterventionModalCreateComponent } from '../interventions/intervention-modal-create/intervention-modal-create.component';
import { InterventionModalEditComponent } from '../interventions/intervention-modal-edit/intervention-modal-edit.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { InterventionEditShiftModalComponent } from '../interventions/intervention-edit/intervention-edit-informations/intervention-edit-shift-modal/intervention-edit-shift-modal.component';
import { InterventionEditDeprogramModalComponent } from '../interventions/intervention-edit/intervention-edit-informations/intervention-edit-deprogram-modal/intervention-edit-deprogram-modal.component';
import Echo from 'laravel-echo';
import * as io from 'socket.io-client'
import { AuthentificationService } from '@libs/auth/src';
import { environment } from '../../environments/environment';
import { EventModalAddEditComponent } from '../events/event-modal-add-edit/event-modal-add-edit.component';
import { EventAffairModalAddEditComponent } from '../events/event-affair-modal-add-edit/event-affair-modal-add-edit.component';
import { Title } from '@angular/platform-browser';
import { tabSuffix } from '_config/tab-suffix';

interface EventAdd {
  startDate: Date;
  duration: Date;
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('fullcalendar') fullcalendar: FullCalendarComponent;
  @ViewChild('external') external: ElementRef;
  @ViewChild('calendarContextMenu') public contextMenu: ContextMenuComponent;
  public form: FormGroup;
  public interventionForm: FormGroup;
  private calendarApi: Calendar;
  private usersSelect$: Subscription;
  private eventsTypesSelect$: Subscription;
  private interventionsCalendarSelects$: Subscription;
  private affairsProductsToProgram$: Subscription;
  private intervention$: Subscription;
  private settings$: Subscription;
  public events: any[] = [];
  public eventsData: any;
  public interventionsData: any;
  private users$: Subscription;
  public users: User[];
  public usersSelect: User[];
  public daysInWeek = 6;
  public research = '';
  public event: Event = null;
  public intervention: Intervention = null;
  public interventionDa: Intervention = null;
  public affairProduct: AffairProduct = null;
  public eventsTypes: any;
  public refresh: Subject<any> = new Subject();
  public eventAdd: EventAdd = null;
  public viewDate: Date;
  public conformities: any[] = [];
  public worksSupervisors: any[] = [];
  public indicatorsInterventionsTypes: any[] = [];
  public affairProductsToProgram: AffairProduct[] = [];
  public affairProductsToProgramAll: AffairProduct[] = [];
  public usersSelected: any[];
  public calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek'
    },
    initialView: 'timeGridWeek',
    editable: true,
    selectable: true,
    locale: frLocale,
    weekNumbers: true,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventResize: this.handleEventResize.bind(this),
    eventDrop: this.handleEventDrop.bind(this),
    eventDidMount: this.handleEventContent.bind(this),
    eventReceive: this.handleEventReceive.bind(this),
    slotDuration: '00:15:00',
    slotMinTime: '07:00:00',
    slotMaxTime: '20:00:00',
    plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
  };
  public isQuickAddOpen = false;
  public activeDayIsOpen: boolean = false;
  public readonly RESOURCE_EVENT = 'event';
  public readonly RESOURCE_INTERVENTION = 'intervention';
  private readonly storageKey = 'calendar-form';
  public shiftOrigins = [];
  public interventionAdd: any;
  public settings;
  public interventionStatusEnum = InterventionStatusEnum;
  public selectContacts$: Subscription;
  public contacts: SelectModel[];
  public selectWorksSupervisors$: Subscription;
  public worksSupervisorsSelect: SelectModel[];
  public selectContactsParams: ContactSelectParameters;
  public zip$: Subscription;
  public technicians: SelectModel[];
  public buildings;
  public housings;
  public isSelectsInit = false;
  public affairProductsToProgram$: Subscription;
  public affairProducts;
  public documentsCustomersAttached$: Subscription;
  public documentsCustomersAttached: any;
  public shiftForm: FormGroup;
  public deprogramForm: FormGroup;
  public eventForm: FormGroup;
  public echo: any;

  constructor(
    private titleService: Title,
    private eventService: EventService,
    private eventTypeService: EventTypeService,
    private userService: UserService,
    private dateUtilsService: DateUtilsService,
    private formBuilder: FormBuilder,
    private interventionService: InterventionService,
    private storageService: FormLocalStorageService,
    private contextMenuService: ContextMenuService,
    private swalService: SwalService,
    private settingService: SettingService,
    private notifService: NotifService,
    private modalService: NzModalService,
    private spinner: NgxSpinnerService,
    private authService: AuthentificationService
  ) {
    const name = Calendar.name;
    this.titleService.setTitle(`Calendrier${tabSuffix}`);
    this.initSubscriptions();
  }

  ngOnInit() {
    this.userService.getAllTechnicians();
    this.userService.getUsersSelect();
    this.eventTypeService.getForSelect();
    this.interventionService.getSelectsForCalendar();
    this.interventionService.getAffairsProductsToProgram();
    this.settingService.getAll();

    // Listen events for refresh
    const SocketIoClient = io;
    try {
      this.echo = new Echo({
        broadcaster: 'socket.io',
        host: environment.webUrl,
        client: SocketIoClient,
        auth: {
          headers: {
            Authorization: 'Bearer ' + `${this.authService.getToken()}`
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
    this.echo.channel('dashboard-events')
      .listen('.intervention-event', () => {
        this.initCalendarEvents(true);
      });
  }


  ngOnDestroy() {
    this.eventsTypesSelect$.unsubscribe();
    this.users$.unsubscribe();
    this.usersSelect$.unsubscribe();
    this.interventionsCalendarSelects$.unsubscribe();
    this.affairsProductsToProgram$.unsubscribe();
  }

  ngAfterViewInit() {
    this.calendarApi = this.fullcalendar.getApi();
    new Draggable(this.external.nativeElement, {
      itemSelector: '.fc-event',
      eventData: function (eventEl) {
        const data: any = JSON.parse(eventEl.getAttribute('data-event'));
        const duration = data.product.duration != null ? data.product.duration : "01:00:00";
        return {
          title: eventEl.innerText,
          duration: duration,
          data: data
        };
      }
    });
    $('.fc-prev-button').on('click', x => {
      this.form.value.dateFrom = this.getCurrentDateFrom();
      this.form.value.dateTo = this.getCurrentDateTo();
      this.initCalendarEvents();
    });
    $('.fc-next-button').on('click', x => {
      this.form.value.dateFrom = this.getCurrentDateFrom();
      this.form.value.dateTo = this.getCurrentDateTo();
      this.initCalendarEvents();
    });
  }

  onChanges() {
    this.form.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(form => {
        this.form.value.dateFrom = this.getCurrentDateFrom();
        this.form.value.dateTo = this.getCurrentDateTo();
        this.storageService.store(this.storageKey, this.form)
        this.eventService.getForCalendar(this.form, this.users);
        this.interventionService.getForCalendar(this.form, this.users);
      });
  }


  initForm(formValue: any, users: any) {
    const usersIds = formValue ?
      formValue.users.map((v, i) => v ? users[i].id : null).filter(v => v !== null) : [];
    this.form = this.formBuilder.group({
      dateFrom: this.getCurrentDateFrom(),
      dateTo: this.getCurrentDateTo(),
      users: new FormArray([])
    });
    this.addCheckboxes(usersIds);
  }

  get usersFormArray() {
    return this.form.controls.users as FormArray;
  }

  getCurrentDateFrom() {
    return this.calendarApi.view.currentStart;
  }

  getCurrentDateTo() {
    return this.calendarApi.view.currentEnd;
  }

  getExternalEvent(event) {
    return JSON.stringify(event);
  }

  getEventResource(event) {
    return event.extendedProps.resource;
  }

  toggleQuickAdd() {
    if (this.isQuickAddOpen) {
      this.isQuickAddOpen = false;
    } else {
      this.isQuickAddOpen = true;
    }
  }

  onSearchChange(value: string) {
    this.research = value;
    this.interventionService.getAffairsProductsToProgram(this.research);
  }

  onClearSearchClicked() {
    this.research = '';
  }

  onDateChange(result: Date): void {
    this.calendarApi.gotoDate(result);
    this.form.value.dateFrom = this.getCurrentDateFrom();
    this.form.value.dateTo = this.getCurrentDateTo();
    this.initCalendarEvents();
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    const calendarApi = selectInfo.view.calendar;
    calendarApi.unselect();
    const duration = this.dateUtilsService.calcTimeDifference(selectInfo.start, selectInfo.end)

    this.eventAdd = {
      startDate: selectInfo.start,
      duration: duration
    }
    this.usersSelected = this.form.value.users.map((v, i) => v ? this.users[i] : null).filter(v => v !== null);
    this.eventForm = this.eventService.getForm(null, this.eventAdd, this.usersSelect, this.usersSelected);
    const modalInstance = this.modalService.create({
      nzContent: EventModalAddEditComponent,
      nzComponentParams: {
        form: this.eventForm,
        users: this.usersSelect,
        usersSelected: this.usersSelected,
        eventsTypes: this.eventsTypes
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.initCalendarEvents();
    })
  }

  handleEventClick(clickInfo: EventClickArg) {

    const resource = this.getEventResource(clickInfo.event);
    if (resource == this.RESOURCE_EVENT) {
      this.event = clickInfo.event.extendedProps.data;

      if (this.event.affairId) {
        this.eventForm = this.eventService.getFormForAffair(this.event.affair, this.event, this.usersSelect)
        const modalInstance = this.modalService.create({
          nzContent: EventAffairModalAddEditComponent,
          nzComponentParams: {
            form: this.eventForm,
            affair: this.event.affair,
            buildings: this.event.affair.buildings,
            event: this.event,
            users: this.usersSelect,
            eventsTypes: this.eventsTypes,
            editFromCalendar: true
          }
        });
        modalInstance.afterClose.subscribe(() => {
          this.initCalendarEvents();
        })

      } else {
        this.eventForm = this.eventService.getForm(this.event, null, this.usersSelect, this.usersSelected);
        const modalInstance = this.modalService.create({
          nzContent: EventModalAddEditComponent,
          nzComponentParams: {
            form: this.eventForm,
            users: this.usersSelect,
            event: this.event,
            usersSelected: this.usersSelected,
            eventsTypes: this.eventsTypes
          }
        });
        modalInstance.afterClose.subscribe(() => {
          this.initCalendarEvents();
        })
      }
    } else {
      this.intervention = clickInfo.event.extendedProps.data;
      this.getEditForCalendar(this.intervention.id);
    }
  }

  onOpenModalIntervention(): void {
    const modalInstance = this.modalService.create({
      nzContent: InterventionModalEditComponent,
      nzComponentParams: {
        intervention: this.intervention,
        users: this.usersSelect,
        conformities: this.conformities,
        affairsProducts: this.affairProducts,
        indicatorsInterventionsTypes: this.indicatorsInterventionsTypes,
        documentsCustomersAttached: this.documentsCustomersAttached,
        form: this.interventionForm,
        contacts: this.contacts,
        worksSupervisorsSelect: this.worksSupervisorsSelect
      },
      nzStyle: { top: 0 },
      nzWidth: '99%'
    })
    modalInstance.afterClose.subscribe(() => {
      this.onModalCloseIntervention();
    })
  }

  handleEventResize(resizeInfo) {
    this.updateDateResource(resizeInfo);
  }

  handleEventDrop(eventDropInfo) {
    this.updateDateResource(eventDropInfo);
  }

  handleEventContent(event) {
    const that = this;
    event.el.addEventListener('contextmenu', function (mouseEvent: MouseEvent) {
      that.onContextMenu(mouseEvent, event);
    });
  }

  onContextMenu($event: MouseEvent, item: any): void {
    this.contextMenuService.show.next({
      event: $event,
      item: item.event,
    });
    $event.preventDefault();
    $event.stopPropagation();
  }

  isResourceEvent = (item: any): boolean => {
    return this.getEventResource(item) === this.RESOURCE_EVENT;
  }

  isResourceIntervention = (item: any): boolean => {
    return this.getEventResource(item) === this.RESOURCE_INTERVENTION;
  }

  isWaitingConfirmation = (item: any): boolean => {
    const intervention = this.getExtendedPropsData(item);
    return intervention && intervention.interventionStatus ?
      intervention.interventionStatus.id === this.getSettingValue(this.interventionStatusEnum.INTERVENTION_STATUS_WAITING_CONFIRMATION) : false;
  }

  isConfirmed = (item: any): boolean => {
    const intervention = this.getExtendedPropsData(item);
    return intervention && intervention.interventionStatus ?
      intervention.interventionStatus.id === this.getSettingValue(this.interventionStatusEnum.INTERVENTION_STATUS_CONFIRMED) : false;
  }

  handleEventReceive(data) {
    const duration = this.dateUtilsService.calcTimeDifference(data.event.start, data.event.end)
    this.interventionAdd = {
      startDate: data.event.start,
      duration: duration,
      usersSelected: this.form.value.users.map((v, i) => v ? this.users[i] : null).filter(v => v !== null)
    }
    this.affairProduct = this.getExtendedPropsData(data.event);
    const modalInstance = this.modalService.create({
      nzContent: InterventionModalCreateComponent,
      nzComponentParams: {
        interventionAdd: this.interventionAdd,
        affairProduct: this.affairProduct,
        users: this.usersSelect,
        conformities: this.conformities,
        indicatorsInterventionsTypes: this.indicatorsInterventionsTypes
      },
      nzStyle: { top: 0 },
      nzWidth: '99%'
    })
    modalInstance.afterClose.subscribe(() => {
      this.onModalCloseIntervention()
      this.interventionService.getAffairsProductsToProgram(this.research);
    })
    data.event.remove();
  }

  onModalCloseEvent() {
    this.event = null;
    this.initCalendarEvents();
  }

  onModalCloseIntervention() {
    this.intervention = null;
    this.interventionForm = null;
    this.initCalendarEvents();
  }

  onModalDeprogramClose() {
    this.intervention = null;
    this.initCalendarEvents();
  }

  onConfirmIntervention(event) {
    this.intervention = this.getExtendedPropsData(event);
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'L\'intervention passera en statut confirmé.'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.interventionService.confirm(that.intervention.id).subscribe(
          (success) => {
            this.notifService.showSuccessNotif(success);
            this.initCalendarEvents();
          }
        );
      }
    });
  }

  onShiftClick(event) {
    const usersSelect = this.userService.formatToSelectMultiple(this.users);
    this.intervention = this.getExtendedPropsData(event);
    this.shiftForm = this.interventionService.initShiftForm(this.intervention, usersSelect);
    const modalInstance = this.modalService.create({
      nzContent: InterventionEditShiftModalComponent,
      nzComponentParams: {
        intervention: this.intervention,
        shiftOrigins: this.shiftOrigins,
        form: this.shiftForm,
        users: usersSelect
      }
    });
    modalInstance.afterClose.subscribe(() => {
      this.initCalendarEvents();
    });
  }


  onDeprogramClick(event) {
    this.intervention = this.getExtendedPropsData(event);
    this.deprogramForm = this.interventionService.initDeprogramForm();
    const modalInstance = this.modalService.create({
      nzContent: InterventionEditDeprogramModalComponent,
      nzComponentParams: {
        intervention: this.intervention,
        shiftOrigins: this.shiftOrigins,
        form: this.deprogramForm
      }
    });
    modalInstance.afterClose.subscribe(() => {
      this.initCalendarEvents();
    });
  }

  onDeleteEvent(event) {
    const eventData = this.getExtendedPropsData(event);
    const that = this;
    const swalOptions = this.swalService.getSwalDeleteOptions({
      text: 'L\'événement sera supprimé'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.eventService.delete(eventData.id).pipe(
          catchError(error => {
            this.swalService.showSwalError(error);
            return throwError(error);
          })
        )
          .subscribe(
            (success) => {
              this.notifService.showSuccessNotif(success);
              this.initCalendarEvents();
            }
          );
      }
    });
  }

  getAffairProducts(affairProductsToProgram, interventionAffairProducts) {
    let affairProducts = [];
    affairProducts = interventionAffairProducts;
    affairProductsToProgram.forEach(affairProduct => {
      const found = interventionAffairProducts.find(x => x.id == affairProduct.id);
      if (!found) {
        affairProducts.push(affairProduct);
      }
    });
    return affairProducts;
  }

  private addCheckboxes(usersIds) {
    this.users.forEach((user) => {
      let checked = false;
      if (usersIds.length > 0) {
        checked = usersIds.map(x => x).includes(user.id);
      }
      this.usersFormArray.push(new FormControl(checked))
    });
  }

  private updateDateResource(eventInfo) {
    const resource = eventInfo.event.extendedProps.resource;
    if (resource == this.RESOURCE_EVENT) {
      this.event = eventInfo.event.extendedProps.data;
      this.eventService.updateDate(this.event.id, eventInfo.event.start, eventInfo.event.end);
    } else {
      this.intervention = eventInfo.event.extendedProps.data;
      if (this.intervention.isReasonNeeded) {
        const usersSelect = this.userService.formatToSelectMultiple(this.users);
        this.shiftForm = this.interventionService.initShiftForm(this.intervention, usersSelect, eventInfo.event.start, eventInfo.event.end);
        const modalInstance = this.modalService.create({
          nzContent: InterventionEditShiftModalComponent,
          nzComponentParams: {
            intervention: this.intervention,
            shiftOrigins: this.shiftOrigins,
            form: this.shiftForm,
            users: usersSelect
          }
        });
        modalInstance.afterClose.subscribe(() => {
          this.initCalendarEvents();
        });
      } else {
        this.interventionService.updateDate(this.intervention.id, eventInfo.event.start, eventInfo.event.end);
      }
    }
  }

  private initCalendarEvents(dontShowSpinner?: boolean) {
    if (!dontShowSpinner) {
      this.spinner.show('load-events');
    }
    this.interventionService.getForCalendar(this.form, this.users);
    this.eventService.getForCalendar(this.form, this.users);
  }

  private getExtendedPropsData(event: any) {
    return event.extendedProps.data;
  }

  getSettingValue(key) {
    return this.settings.find(x => x.key == key).value;
  }

  public getEditForCalendar(id: number) {
    this.interventionService.getEditForCalendar(id).subscribe(
      (data) => {
        this.intervention = data.intervention;
        this.contacts = data.contacts;
        this.worksSupervisors = data.worksSupervisors;
        this.housings = data.housings;
        this.buildings = data.buildings;
        this.documentsCustomersAttached = data.documentsCustomersAttached;
        //Init form
        this.interventionForm = this.interventionService.getEditForm(this.intervention, this.usersSelect, this.contacts);
        this.interventionService.initHousingsCheckboxes(this.interventionForm, this.housings, this.intervention.housings);
        this.interventionService.initAffairProductsCheckboxes(this.interventionForm, this.affairProductsToProgram, this.intervention.affairProducts, this.intervention.affair.affairProducts);
        //Filter & traitements
        if (this.buildings.length > 0 && this.intervention.buildings.length > 0) {
          this.interventionService.filterHousings(this.intervention.buildings, this.housings);
        }
        this.isSelectsInit = true;
        this.worksSupervisorsSelect = data.worksSupervisors;
        this.affairProducts = this.getAffairProducts(this.affairProductsToProgram, this.intervention.affairProducts);
        this.onOpenModalIntervention();
      }
    )
  }
  private initSubscriptions() {
    this.users$ = this.userService.usersTechnicians$.subscribe(
      (data) => {
        if (data) {
          this.users = data;
          const sessionFormValue = this.storageService.retrieve(this.storageKey);
          this.initForm(sessionFormValue, this.users);
          this.onChanges();
          this.initCalendarEvents();
        }
      }
    );
    this.usersSelect$ = this.userService.usersSelect$.subscribe(
      (data) => {
        if (data) {
          this.usersSelect = data;
        }
      }
    );
    this.eventsTypesSelect$ = this.eventTypeService.eventsTypesSelect$.subscribe(
      (data) => {
        if (data) {
          this.eventsTypes = data;
        }
      }
    )
    zip(
      this.eventService.events$,
      this.interventionService.interventionsCalendar$)
      .subscribe(([events, interventions]) => {
        this.events = [];
        if (events) {
          this.eventsData = events;
          for (let i = 0; i < this.eventsData.length; i++) {
            this.events.push({
              title: this.eventsData[i].name,
              start: new Date(this.eventsData[i].start),
              end: new Date(this.eventsData[i].end),
              data: this.eventsData[i].data,
              description: this.eventsData[i].description,
              backgroundColor: this.eventsData[i].backgroundColor,
              textColor: this.getTextColor(this.eventsData[i].backgroundColor),
              resource: 'event'
            })
          }
        }
        if (interventions) {
          this.interventionsData = interventions;
          for (let i = 0; i < this.interventionsData.length; i++) {
            this.events.push({
              title: this.interventionsData[i].name + ' - ' + this.interventionsData[i].locality,
              start: new Date(this.interventionsData[i].start),
              end: new Date(this.interventionsData[i].end),
              data: this.interventionsData[i].data,
              description: this.interventionsData[i].description,
              backgroundColor: this.interventionsData[i].backgroundColor,
              textColor: this.interventionsData[i].backgroundColor ? this.getTextColor(this.interventionsData[i].backgroundColor) : "#FFFFFF",
              resource: 'intervention'
            })
          }
        }
        this.calendarOptions.events = this.events;
        this.spinner.hide('load-events')
      });
    this.interventionsCalendarSelects$ = this.interventionService.interventionsCalendarSelects$.subscribe(
      (data) => {
        if (data) {
          this.conformities = data.conformities;
          this.indicatorsInterventionsTypes = data.indicatorsInterventionsTypes;
          this.shiftOrigins = data.shiftOrigins;
        }
      }
    )
    this.settings$ = this.settingService.settings$.subscribe(
      (data) => {
        if (data) {
          this.settings = data;
        }
      }
    )
  }

  public onUnselectClicked() {
    this.usersFormArray.controls.map(control => {
      control.patchValue(false)
    });
  }

  public getTextColor(hex) {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    let r = parseInt(hex.slice(0, 2), 16);
    let g = parseInt(hex.slice(2, 4), 16);
    let b = parseInt(hex.slice(4, 6), 16);
    // https://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? '#000000'
      : '#FFFFFF';
  }
}
