import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-mails-send-filters',
  templateUrl: './mails-send-filters.component.html',
  styleUrls: ['./mails-send-filters.component.scss']
})
export class MailsSendFiltersComponent implements OnInit {
  @Input() form: FormGroup;

  constructor() { }

  ngOnInit() {
  }

}
