import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { AffairMarketType } from '@libs/enum/src';
import { Affair, MlAgency } from '@libs/models/src';

@Component({
  selector: 'app-affair-form-affair-informations',
  templateUrl: './affair-form-affair-informations.component.html',
  styleUrls: ['./affair-form-affair-informations.component.scss']
})
export class AffairFormAffairInformationsComponent implements OnInit {
  @Input() form: FormGroup
  @Input() affair: Affair
  @Input() mlAgencies: MlAgency[];
  @Input() assistants: any;
  @Input() technicians: any;
  @Input() affairsGoals: any;
  @Input() affairsStatuses: any;

  constructor() { }

  ngOnInit() {
  }

  getFormControls(form: FormGroup, controlName: string) {
    return (form.get(controlName) as FormArray).controls;
  }

}
