import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OfficeFile } from '@libs/models/src';
import { ContactService, CustomerService, NotifService, SendSettingService, SwalService } from '@libs/services/src';
import { OfficeFileIndicatorService } from '@libs/services/src/lib/office-file-indicator.service';
import { OfficeFileService } from '@libs/services/src/lib/office-file.service';
import { SendSettingTypeService } from '@libs/services/src/lib/send-setting-type.service';
import { StudyOfficeService } from '@libs/services/src/lib/study-office.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { Subscription, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { CustomersShowOfficesFilesModalComponent } from './customers-show-offices-files-modal/customers-show-offices-files-modal.component';

@Component({
  selector: 'app-customers-show-offices-files',
  templateUrl: './customers-show-offices-files.component.html',
  styleUrls: ['./customers-show-offices-files.component.scss']
})
export class CustomersShowOfficesFilesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() customer: any;
  @Input() marketsTypes: any[];
  @Input() contacts: any[];
  public form: FormGroup;
  public officeFile = null;
  public sendSettings: any;
  public sendSettingsTypes: any;
  public officesFilesArray = [];
  public officesFilesIndicators: any;
  private selectStudiesOffices$: Subscription;
  public studiesOffices: any;
  public selectContacts: any;

  constructor(
    private officeFileService: OfficeFileService,
    private officeFileIndicatorService: OfficeFileIndicatorService,
    private sendSettingService: SendSettingService,
    private sendSettingTypeService: SendSettingTypeService,
    private modalService: NzModalService,
    private customerService: CustomerService,
    private studyOfficeService: StudyOfficeService,
    private contactService: ContactService,
    private swalService: SwalService,
    private spinner: NgxSpinnerService,
    private notifService: NotifService,
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.initValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
     if (changes['customer'] && changes['customer'].previousValue != changes['customer'].currentValue) {
        this.initValues();
     }
  }

  ngOnDestroy() {
    this.selectStudiesOffices$.unsubscribe();
  }

  getOfficeFile(marketTypeId) {
    return this.customer.officesFiles.find((officeFile) => officeFile.marketTypeId == marketTypeId);
  }


  onShowModalAddEditModal(marketTypeId: number, currentOfficeFile?: OfficeFile) {
    const officesFilesIndicatorsArray = this.officeFileIndicatorService.getByMarketType(marketTypeId, this.officesFilesIndicators);
    this.form = this.officeFileService.getForm(this.customer.id, marketTypeId, currentOfficeFile);

    const modalInstance = this.modalService.create({
      nzContent: CustomersShowOfficesFilesModalComponent,
      nzComponentParams: {
        officeFile: currentOfficeFile,
        sendSettings: this.sendSettings,
        sendSettingsTypes: this.sendSettingsTypes,
        contacts: this.selectContacts,
        officesFilesIndicators: officesFilesIndicatorsArray,
        studiesOffices: this.studiesOffices,
        form: this.form
      },
      nzStyle: { top: '2vh' },
      nzWidth: '98%'
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.officeFile = null;
      this.customerService.refreshEntityByCustomerRef(this.customer.ref);
    })
  }

  private initSubscriptions() {
    this.sendSettingService.getAll().subscribe((data) => {
      if (data) {
        this.sendSettings = data;
      }
    })
    this.sendSettingTypeService.getAll().subscribe((data) => {
      if (data) {
        this.sendSettingsTypes = data;
      }
    })
    this.officeFileIndicatorService.getAll().subscribe((data) => {
      if (data) {
        this.officesFilesIndicators = data;
      }
    })
    this.selectStudiesOffices$ = this.studyOfficeService.selectStudiesOffices$.subscribe(
      (data) => {
        if (data) {
          this.studiesOffices = data;
        }
      })

  }

  onRefreshClicked(currentOfficeFile: OfficeFile): void {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'Toutes les fiches bureaux des affaires affiliées à ce client pour ce marché seront actualisées'
    });
    Swal.fire(swalOptions).then((result) => {
        if (result.value) {
            that.spinner.show('common-spinner');
            that.officeFileService.refreshAffairs(currentOfficeFile.id, that.customer.id)
            .pipe(
                catchError(error => {
                    that.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                  that.spinner.hide('common-spinner');
                })
            )
            .subscribe(
              (success) => {
                that.notifService.showSuccessNotif(success);
                that.spinner.hide('common-spinner');
              }
            )
        }
    });
  }

  private initValues(): void {
    this.sendSettingService.getAll();
    this.sendSettingTypeService.getAll();
    this.officeFileIndicatorService.getAll();
    this.studyOfficeService.getForSelect();
    this.selectContacts = this.contactService.formatToSelectArray(this.contacts, 'email');
  }
    
}
