import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AffairExportService } from '@libs/services/src/lib/affair-export.service';
import { WebdatarocksComponent } from 'ng-webdatarocks';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-affairs-export',
  templateUrl: './affairs-export.component.html',
  styleUrls: ['./affairs-export.component.scss']
})
export class AffairsExportComponent implements OnInit {
  private export$: Subscription;
  @Input() form: FormGroup;
  filtersForm: FormGroup;
  @ViewChild('pivot1') child: WebdatarocksComponent;
  public configurations = [
    {
      id: 3, name: 'Départements', value: {
        "rows": [
          {
            "uniqueName": "dept"
          }
        ],
        "columns": [
          {
            "uniqueName": "Measures"
          }
        ],
        "measures": [
          {
            "uniqueName": "ca",
            "aggregation": "sum"
          }
        ]
      }, group: 'CA'
    }
  ]
  public global = { localization: 'https://cdn.webdatarocks.com/loc/fr.json' };

  constructor(
    private affairExportService: AffairExportService
  ) {
  }


  ngOnInit() {
    this.filtersForm = this.affairExportService.getFiltersForm();
    this.affairExportService.getData(this.form);
  }

  onPivotReady(pivot: WebDataRocks.Pivot): void {
    this.export$ = this.affairExportService.export$.subscribe(
      (data) => {
        if (data) {
          this.child.webDataRocks.setReport({
            dataSource: {
              data: data
            }
          });
        }
      }
    );
  }

  onChangeConfiguration($event) { }

  customizeToolbar(toolbar) {
    const tabs = toolbar.getTabs(); // get all tabs from the toolbar
    toolbar.getTabs = () => {
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      tabs.unshift({
        id: 'wdr-tab-lightblue',
        title: 'Sauvegarder la configuration',
        handler: () => {
          console.log(this.child.webDataRocks.getReport());
        },
        icon: 'fas fa-save'
      })
      return tabs;
    }
  }

}
