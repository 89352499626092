import { Component, Input, OnInit } from '@angular/core';
import { Affair, AffairContact, Intervention } from '@libs/models/src';
import { NotifService } from '@libs/services/src';
import { TelephonyService } from '@libs/services/src/lib/telephony.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { TelephonyTool } from '_tools/telephony';

@Component({
  selector: 'eros-front-contacts-call-modal',
  templateUrl: './contacts-call-modal.component.html',
  styleUrls: ['./contacts-call-modal.component.scss']
})
export class ContactsCallModalComponent implements OnInit {
  @Input() affair: Affair;
  @Input() intervention: Intervention;
  @Input() affairContacts: AffairContact[];

  constructor(
    private modal: NzModalRef,
    private telephonyService: TelephonyService,
    private notifService: NotifService,
  ) { }

  ngOnInit() {
  }

   onCallClicked(phoneNumber: string) {
     this.telephonyService.call(phoneNumber).subscribe(
       (success) => {
         this.notifService.showSuccessNotif('Appel effectué avec succès');
         this.modal.close();
       }
     )
  }

}
