import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AffairExportTabsEnum, MlSocietyEnum } from '@libs/enum/src';
import { SelectModel } from '@libs/models/src';

@Component({
  selector: 'app-affairs-exports-filters',
  templateUrl: './affairs-exports-filters.component.html',
  styleUrls: ['./affairs-exports-filters.component.scss']
})
export class AffairsExportsFiltersComponent implements OnInit {
  @Input() form: FormGroup
  @Input() agencies: SelectModel[];
  @Input() technicians: SelectModel[];
  @Input() documentsDelivered: SelectModel[];
  @Input() activeTab: string;
  @Input() appKey: string;
  public affairExportTabsEnum = AffairExportTabsEnum;
  public mlSocietyEnum = MlSocietyEnum;

  constructor() { }

  ngOnInit(): void {
  }  

}
