import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Agency, Contact } from '@libs/models/src';
import { ContactService, CustomerService, NotifService } from '@libs/services/src';
import { TelephonyService } from '@libs/services/src/lib/telephony.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { fromEvent, Subject, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { CustomersShowContactsModalComponent } from './customers-show-contacts-modal/customers-show-contacts-modal.component';

@Component({
  selector: 'app-customers-show-contacts',
  templateUrl: './customers-show-contacts.component.html',
  styleUrls: ['./customers-show-contacts.component.scss']
})
export class CustomersShowContactsComponent implements OnInit, OnChanges {
  @Input() appKey: string;
  @Input() customer: any;
  @Input() professions: any[];
  @Input() group: any;
  @Input() mlSocieties: any[];
  @ViewChild('searchField') searchField: ElementRef;
  @Input() value = "";
  private destroy$ = new Subject<void>();
  public contactsFiltered: any;
  public delayTime = 500;
  public form: FormGroup;
  public groups = [];

  constructor(
    private contactService: ContactService,
    private customerService: CustomerService,
    private modalService: NzModalService,
    private notifService: NotifService,
    private spinner: NgxSpinnerService,
    private telephonyService: TelephonyService,
  ) {
  }

  ngOnInit() {
    if (this.customer) {
      this.contactsFiltered = this.customer.contacts;
      this.groups.push(this.group);

      if (this.value.length > 0) {
        this.filterContacts(this.value.toLocaleLowerCase());
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['customer'] && changes['customer'].previousValue != changes['customer'].currentValue) {
      this.contactsFiltered = this.customer.contacts;
      if (this.value.length > 0) {
        this.filterContacts(this.value.toLocaleLowerCase());
      }
    }
  }

  onShowModalAddEditModal(contact?) {
    this.form = this.contactService.getForm(contact);
    const modalInstance = this.modalService.create({
      nzContent: CustomersShowContactsModalComponent,
      nzComponentParams: {
        contact: contact,
        customer: this.customer,
        form: this.form,
        group: this.group,
        professions: this.professions,
        groups: this.groups,
        mlSocieties: this.mlSocieties
      },
      nzStyle: { top: '2vh' },
      nzWidth: '98%'
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.customerService.refreshEntityByCustomerRef(this.customer.ref);
    })
  }

  onUserPortalCreateClicked(contact: Contact) {
    Swal.fire({
      title: 'Souhaitez-vous générer des accès à l\'espace ABM ?',
      text: "Un email contenant les intructions de connexion sera envoyé automatiquement",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.contactService.createPortalAccess(contact.id).subscribe(
          (success) => {
            this.notifService.showSuccessNotif(success);
            this.customerService.refreshEntityByCustomerRef(this.customer.ref);
          }
        )
      } else {
        return;
      }
    })
  }

  onDeleteContactClicked(contact: Contact) {
    Swal.fire({
      title: 'Êtes vous sûr ?',
      text: "Le contact sera supprimé",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show('delete-contact');
        this.contactService.delete(contact.id).subscribe(
          (success) => {
            this.notifService.showSuccessNotif(success);
            this.customerService.refreshEntityByCustomerRef(this.customer.ref);
            this.spinner.hide('delete-contact');
          },
          (error) => {
            this.spinner.hide('delete-contact');
          }
        )
      } else {
        return;
      }
    })
  }

  searchKeyPress(event) {

    fromEvent(this.searchField.nativeElement, 'input')
      .pipe(
        debounce(() => timer(this.delayTime)),
        distinctUntilChanged(
          null,
          (event: Event) => (event.target as HTMLInputElement).value
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(e => {
        this.filterContacts(this.value);
      });
  }

  onRedirectCallClicked(phoneNumber: string): void {
    window.open(`incoming-call?phoneNumber=${phoneNumber}`)

  }

  onCallClicked(phoneNumber: string): void {
    this.telephonyService.call(phoneNumber).subscribe(
      (success) => {
        this.notifService.showSuccessNotif('Appel effectué avec succès');
      }
    )
  }

  clearSearch() {
    this.value = '';
    this.contactsFiltered = this.customer.contacts;
  }

  private filterContacts(value) {
    this.contactsFiltered = this.customer.contacts.filter(c => c.fullName.toLowerCase().indexOf(value) !== -1);
  }

}
