import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Intervention } from '@libs/models/src';
import { InterventionService } from '@libs/services/src';

@Component({
  selector: 'app-intervention-products',
  templateUrl: './intervention-products.component.html',
  styleUrls: ['./intervention-products.component.scss']
})
export class InterventionProductsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() intervention: Intervention;
  @Input() affairProducts: any[];
  public isCheckedAll = false;

  constructor(
    private interventionService: InterventionService,
  ) {
  }

  public get affairsProductsArray() {
    return this.form.get('affairProducts') as FormArray
  }

  ngOnInit() {
    if (this.affairProducts.length == 1) {
      this.onCheckAll();
    }
  }

  onCheck(event): void {
    const affairProductsChecked = this.form.value.affairProducts.filter(v => v.checked == true);
    if (affairProductsChecked.length == this.affairProducts.length) {
      this.isCheckedAll = true;
    } else {
      this.isCheckedAll = false;
    }
    this.interventionService.getGeneratedName(this.form);
  }

  onCheckAll(event?): void {
    const affairProductsFormArray = this.form.get('affairProducts') as FormArray;
    for (const ap of this.affairProducts) {
      const checked = event ? event.target.checked : 1;
      affairProductsFormArray.controls.map(value => value.patchValue({ checked: checked }))
    }
    this.interventionService.getGeneratedName(this.form);
  }

  onQuantityChange(): void {
    this.interventionService.getGeneratedName(this.form);
  }

}
