import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SwalService, ThingService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { ProductUrgencyLevelService } from '@libs/services/src/lib/product-urgency-level.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ProductsUrgenciesLevelsModalComponent } from '../products-urgencies-levels-modal/products-urgencies-levels-modal.component';

@Component({
  selector: 'app-products-urgencies-levels-index',
  templateUrl: './products-urgencies-levels-index.component.html',
  styleUrls: ['./products-urgencies-levels-index.component.scss']
})
export class ProductsUrgenciesLevelsIndexComponent implements OnInit {
  public datatableSelector = 'products-urgencies-level-datatable';
  public form: FormGroup;
  public productUrgencyLevel: any = null;
  public productUrgencyLevel$: Subscription;
  public labelsClasses$: Subscription;
  public labelsClasses: any;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'label',
      name: 'Rendu',
      searchable: false,
      orderable: false
    },
    {
      attr: 'color',
      name: 'Couleur'
    },
    {
      attr: 'order',
      name: 'Ordre'
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];

  constructor(
    private productUrgencyLevelService: ProductUrgencyLevelService,
    private swalService: SwalService,
    private modalService: NzModalService,
    private thingService: ThingService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.thingService.getLabelsClassesSelect();
  }

  ngAfterViewInit() {
    this.productUrgencyLevelService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    if (this.productUrgencyLevel$) {
      this.productUrgencyLevel$.unsubscribe();
    }
  }

  onShowModalAddEditModal() {
    this.form = this.productUrgencyLevelService.getForm(this.productUrgencyLevel);
    const modalInstance = this.modalService.create({
      nzContent: ProductsUrgenciesLevelsModalComponent,
      nzComponentParams: {
        productUrgencyLevel: this.productUrgencyLevel,
        labelsClasses: this.labelsClasses,
        form: this.form
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.productUrgencyLevel = null;
    })
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.productUrgencyLevel$ = that.productUrgencyLevelService.get(id).subscribe(
          (data) => {
            if (data) {
              that.productUrgencyLevel = data;
              that.onShowModalAddEditModal();
            }
          }
        );
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le niveau d\'urgence sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.productUrgencyLevelService.delete(id).subscribe(
              (success) => {
                this.swalService.showSwalSuccess(success);
              }
            );
          }
        });
      }
    });
  }

  private initSubscriptions() {
    this.labelsClasses$ = this.thingService.labelsClasses$.subscribe(
      (labelsClasses) => {
        if (labelsClasses) {
          this.labelsClasses = labelsClasses;
        }
      }
    );
  }
}
