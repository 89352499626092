import { Component, Input, OnInit } from '@angular/core';
import { Affair } from '@libs/models/src';
import { QuotationService } from '@libs/services/src/lib/quotation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-affair-show-quotations-thumbnails',
  templateUrl: './affair-show-quotations-thumbnails.component.html',
  styleUrls: ['./affair-show-quotations-thumbnails.component.scss']
})
export class AffairShowQuotationsThumbnailsComponent implements OnInit {
  @Input() affair: Affair;
  private quotationsThumbnails$: Subscription;
  public thumbnails = [];

  constructor(
    private quotationService: QuotationService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.quotationService.thumbnails(this.affair.id);
  }

  ngOnDestroy() {
    this.quotationsThumbnails$.unsubscribe();
  }

  initSubscriptions() {
    this.quotationsThumbnails$ = this.quotationService.quotationsThumbnails$.subscribe(
      (data) => {
        if (data) {
          this.thumbnails = data;
        }
      }
    )
  }

}
