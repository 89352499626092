import { Component, Input, OnInit } from '@angular/core';
import { Affair } from '@libs/models/src';

@Component({
  selector: 'app-affair-show-quotations',
  templateUrl: './affair-show-quotations.component.html',
  styleUrls: ['./affair-show-quotations.component.scss']
})
export class AffairShowQuotationsComponent implements OnInit {
  @Input() affair: Affair;

  constructor() { }

  ngOnInit() {
  }

}
