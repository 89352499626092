import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DocumentCustomerService, MarketTypeService, SwalService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { OfficeFileIndicatorService } from '@libs/services/src/lib/office-file-indicator.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { OfficesFilesIndicatorsModalComponent } from '../offices-files-indicators-modal/offices-files-indicators-modal.component';

@Component({
  selector: 'app-offices-files-indicators-index',
  templateUrl: './offices-files-indicators-index.component.html',
  styleUrls: ['./offices-files-indicators-index.component.scss']
})
export class OfficesFilesIndicatorsIndexComponent implements OnInit, OnDestroy, AfterViewInit {
  public datatableSelector = 'offices-files-indicators-datatable';
  public form: FormGroup;
  public officeFileIndicator: any = null;
  public officeFileIndicator$: Subscription;
  private documentsCustomersSelect$: Subscription;
  public documentsCustomers: any;
  private marketsTypes$: Subscription;
  public marketsTypes: any;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'key',
      name: 'Clé',
    },
    {
      attr: 'name',
      name: 'Nom',
    },
    {
      attr: 'documentsCustomers',
      name: 'Document(s) client(s) nécessaire(s)',
      orderable: false,
      searchable: false
    },
    {
      attr: 'marketsTypesNames',
      name: 'Types de marchés',
      orderable: false,
      searchable: false
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];

  constructor(
    private officeFileIndicatorService: OfficeFileIndicatorService,
    private documentCustomerService: DocumentCustomerService,
    private marketTypeService: MarketTypeService,
    private swalService: SwalService,
    private modalService: NzModalService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.documentCustomerService.getForSelect();
    this.marketTypeService.getForSelect();
  }

  ngAfterViewInit() {
    this.officeFileIndicatorService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    if (this.officeFileIndicator$) {
      this.officeFileIndicator$.unsubscribe();
    }
    this.documentsCustomersSelect$.unsubscribe();
    this.marketsTypes$.unsubscribe();
  }

  onShowModalAddEditModal() {
    this.form = this.officeFileIndicatorService.getForm(this.officeFileIndicator, this.documentsCustomers);
    const modalInstance = this.modalService.create({
      nzContent: OfficesFilesIndicatorsModalComponent,
      nzComponentParams: {
        officeFileIndicator: this.officeFileIndicator,
        documentsCustomers: this.documentsCustomers,
        marketsTypes: this.marketsTypes,
        form: this.form
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.officeFileIndicator = null;
    })
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.officeFileIndicator$ = that.officeFileIndicatorService.get(id).subscribe(
          (data) => {
            if (data) {
              that.officeFileIndicator = data;
              that.onShowModalAddEditModal();
            }
          }
        );
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le paramètre d\'envoi sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.officeFileIndicatorService.delete(id).subscribe(
              (success) => {
                this.swalService.showSwalSuccess(success);
              }
            );
          }
        });
      }
    });
  }

  private initSubscriptions() {
    this.documentsCustomersSelect$ = this.documentCustomerService.documentsCustomersSelect$.subscribe(
      (data) => {
        if (data) {
          this.documentsCustomers = data;
        }
      }
    )
    this.marketsTypes$ = this.marketTypeService.selectMarketType$.subscribe(
      (data) => {
        if (data) {
          this.marketsTypes = data;
        }
      }
    )
  }
}
