import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AffairDocumentCustomerService } from '@libs/services/src/lib/affair-document-customer.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-affair-show-documents-customers',
  templateUrl: './affair-show-documents-customers.component.html',
  styleUrls: ['./affair-show-documents-customers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AffairShowDocumentsCustomersComponent implements OnInit, OnDestroy {

  @Input() affair: any;
  private documents$: Subscription;
  private filters$: Subscription;
  public documents = [];
  public companies = [];
  public filters = [];

  constructor(
    public affairDocumentService: AffairDocumentCustomerService
  ) { }

  ngOnInit() {
    this.documents$ = this.affairDocumentService.getAll(this.affair.id, null).subscribe(
      (documents) => {
        if (documents) {
          this.documents = documents;
          this.companies = Object.keys(documents);
        }
      }
    );
    this.filters$ = this.affairDocumentService.getFilters(this.affair.id, false).subscribe(
      (filters) => {
        if (filters) {
          this.filters = filters;
        }
      }
    );
  }

  ngOnDestroy() {
    this.documents$?.unsubscribe();
    this.filters$?.unsubscribe();
    this.affairDocumentService.resetDocuments();
  }

}
