import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiService } from '@libs/api/src';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { StatisticService } from './statistic.service';
import { NotifService } from './utilities/notif.service';

@Injectable()
export class StatisticRevenueService {

    private route = '/stats/revenue';
    public statsRevenueRegions$ = new BehaviorSubject<any>(undefined);
    public statsRevenueTechnicians$ = new BehaviorSubject<any>(undefined);
    public statsRevenueProducts$ = new BehaviorSubject<any>(undefined);
    public statsRevenueDepartments$ = new BehaviorSubject<any>(undefined);


    constructor(
        private apiService: ApiService,
        private notifService: NotifService,
        private statisticService: StatisticService,
        private spinner: NgxSpinnerService
    ) { }

    getStatsRegions(form: FormGroup) {
        const params = this.statisticService.formToParams(form);
        this.spinner.show('statistic-revenue-load');
        this.apiService.post(this.route + '/regions', params).subscribe(
            (result) => {
                this.spinner.hide('statistic-revenue-load');
                this.statsRevenueRegions$.next(result);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    getStatsTechnicians(form: FormGroup) {
        const params = this.statisticService.formToParams(form);
        this.spinner.show('statistic-revenue-load');
        this.apiService.post(this.route + '/technicians', params).subscribe(
            (result) => {
                this.spinner.hide('statistic-revenue-load');
                this.statsRevenueTechnicians$.next(result);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    getStatsProducts(form: FormGroup) {
        const params = this.statisticService.formToParams(form);
        this.spinner.show('statistic-revenue-load');
        this.apiService.post(this.route + '/products', params).subscribe(
            (result) => {
                this.spinner.hide('statistic-revenue-load');
                this.statsRevenueProducts$.next(result);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    getStatsDeparments(form: FormGroup) {
        const params = this.statisticService.formToParams(form);
        this.spinner.show('statistic-revenue-load');
        this.apiService.post(this.route + '/departments', params).subscribe(
            (result) => {
                this.spinner.hide('statistic-revenue-load');
                this.statsRevenueDepartments$.next(result);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }


}
