// Libs
import { SharedModule } from '@eros-front/shared';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpensesAccountsListComponent } from './expenses-accounts-list/expenses-accounts-list.component';
import { ExpensesAccountsListFiltersComponent } from './expenses-accounts-list/expenses-accounts-list-filters/expenses-accounts-list-filters.component';
import { ExpenseAddEditModalComponent } from './expense-add-edit-modal/expense-add-edit-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    ExpensesAccountsListComponent,
    ExpensesAccountsListFiltersComponent,
    ExpenseAddEditModalComponent
  ],
  exports: [
    ExpensesAccountsListComponent
  ]
})
export class ExpensesAccountsModule { }
