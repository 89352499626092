import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventType } from '@libs/models/src';
import { EventTypeService, SwalService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { EventsTypesModalComponent } from '../events-types-modal/events-types-modal.component';

@Component({
  selector: 'app-events-types-index',
  templateUrl: './events-types-index.component.html',
  styleUrls: ['./events-types-index.component.scss']
})
export class EventsTypesIndexComponent implements OnInit {
  public datatableSelector = 'events-types-datatable';
  public form: FormGroup;
  public eventType: EventType = null;
  public eventType$: Subscription;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'color',
      name: 'Couleur'
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];

  constructor(
    private eventTypeService: EventTypeService,
    private swalService: SwalService,
    private modalService: NzModalService,
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.eventTypeService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    if (this.eventType$) {
      this.eventType$.unsubscribe();
    }
  }

  onShowModalAddEditModal() {
    this.form = this.eventTypeService.getForm(this.eventType);
    const modalInstance = this.modalService.create({
      nzContent: EventsTypesModalComponent,
      nzComponentParams: {
        eventType: this.eventType,
        form: this.form
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.eventType = null;
    })
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.eventType$ = that.eventTypeService.get(id).subscribe(
          (data) => {
            if (data) {
              that.eventType = data;
              that.onShowModalAddEditModal();
            }
          }
        );
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le niveau d\'urgence sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.eventTypeService.delete(id).subscribe(
              (success) => {
                this.swalService.showSwalSuccess(success);
              }
            );
          }
        });
      }
    });
  }
}
