import { Component, Input, OnInit } from '@angular/core';
import { ShuttleSheetTemplateBuilding, ShuttleSheetTemplateService } from '@libs/services/src';

@Component({
  selector: 'app-shuttle-sheet-template-show',
  templateUrl: './shuttle-sheet-template-show.component.html',
  styleUrls: ['./shuttle-sheet-template-show.component.scss']
})
export class ShuttleSheetTemplateShowComponent implements OnInit {

  @Input() baseModel: any;
  @Input() templateModel: any;
  public selectedObject: ShuttleSheetTemplateBuilding = null;

  constructor(
    private shuttleService: ShuttleSheetTemplateService
  ) { }

  ngOnInit() {
  }

  getElements(key: string) {
    return this.baseModel.buildings.filter((e: any) => e.col === key);
  }

  getTemplateCount(key: string) {
    for (let b of this.templateModel.buildings) {
      if (b.key === key) {
        return b.templates.length;
      }
    }
    return 0;
  }

  onEditTemplateClicked(key: string) {
    this.selectedObject = null;
    for (let b of this.templateModel.buildings) {
      if (b.key === key) {
        this.selectedObject = b;
        break;
      }
    }
    if (this.selectedObject == null) {
      this.selectedObject = {
        key: key,
        templates: []
      }
    }
    this.shuttleService.showModalTemplate();
  }

}
