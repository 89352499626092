import { AuthentificationService } from './../authentification.service';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { API_URL } from './../authentification.shared';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { SwalService } from '@libs/services/src/lib/utilities/swal.service';

@Component({
  selector: 'eros-front-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  public form: FormGroup;
  public isLoading = false;
  public isSubmitted = false;
  public apiUrl: string;
  public faCircleNotch = faCircleNotch;

  constructor(
    private router: Router,
    private authService: AuthentificationService,
    private formBuilder: FormBuilder,
    private swalService: SwalService,
    @Inject(API_URL) apiUrl: string,
  ) {
    this.form = this.initForm();
    this.apiUrl = apiUrl;
  }

  ngOnInit(): void {
  }

  isUsernameErrors(): ValidationErrors {
    return this.form.get('email').errors;
  }

  isUsernameRequired(): boolean {
    return this.isUsernameErrors().required;
  }

  isPasswordErrors(): ValidationErrors {
    return this.form.get('password').errors;
  }

  isPasswordRequired(): boolean {
    return this.isPasswordErrors().required;
  }


  onLogIn() {
    this.isSubmitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      // eslint-disable-next-line no-useless-return
      return;
    }
    this.authService.login(this.form)
      .subscribe(res => {
        if (!Array.isArray(res)) {
          this.router.navigate(['dashboard']);
        }
      }, error => {
        this.swalService.showSwalError('Connexion impossible');
      });
  }

  loginWithMicrosoft(e) {
    let originUrl = this.getOriginUrl(this.apiUrl);
    window.location.href = originUrl + 'login-with-microsoft';
    e.preventDefault();
  }

  private initForm() {
    return this.formBuilder.group({
      email: [null, Validators.required],
      password: [null, Validators.required]
    });
  }

  private getOriginUrl(apiUrl) {
    //regex api à la fin de l'adresse
    const originUrl = apiUrl.replace(new RegExp('api$'), '');
    return originUrl;
  }

}
