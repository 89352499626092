import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MarketType } from '@libs/models/src/lib/market-type.model';
import { UserService } from '@libs/services/src';
import { CustomLocalStorageService } from '@libs/services/src/lib/utilities/custom-local-storage.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import Swal from 'sweetalert2';

@Component({
  selector: 'modal-market-type',
  templateUrl: './modal-market-type.component.html',
  styleUrls: ['./modal-market-type.component.scss']
})
export class ModalMarketTypeComponent implements OnInit {
  @Input() marketsTypes: any;
  public form: FormGroup;
  private selectedMarketsTypes: MarketType[] = [];
  public selectedMarketsTypesIds: number[] = [];

  constructor(
    private modal: NzModalRef,
    private localStorageService: CustomLocalStorageService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {

  }

  ngOnInit() {
    this.selectedMarketsTypesIds = this.localStorageService.getSelectedMarketsTypesIds();
    this.form = this.initForm();
  }


  closeModal() {
    this.modal.close();
  }

  initForm() {
    return this.formBuilder.group({
      marketsTypesIds: []
    });
  }

  onSelectedMarketsTypesChange(marketsTypes: MarketType[]) {
    this.selectedMarketsTypes = marketsTypes;
  }

  onUpdateMarketsTypesClicked() {
    this.closeModal();
    this.localStorageService.setSelectedMarketsTypes(this.selectedMarketsTypes);
    Swal.fire({
      title: 'Succès !',
      text: 'La sélection des types de marchés a été mise à jour',
      icon: 'success'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/dashboard']).then(() => {
          window.location.reload();
        });
      }
    });
  }

  isValidSubmit() {
    return this.selectedMarketsTypes.length > 0;
  }

}
