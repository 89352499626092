export class MlSocietiesWorkWith {

  static readonly ABM = new MlSocietiesWorkWith('abm', 'workWithAbm', 'tll fa-logo-abm');
  static readonly NRJ = new MlSocietiesWorkWith('nrj', 'workWithNrj', 'tll fa-logo-nrj');
  static readonly PMB = new MlSocietiesWorkWith('pmb', 'workWithPmb', 'tll fa-logo-pmb');
  static readonly ACC = new MlSocietiesWorkWith('acc', 'workWithAcc', 'tll fa-logo-acc');

  private constructor(
    private readonly key: string,
    public readonly formControlName: string,
    public readonly icon: string
  ) { }

  toString() {
    return this.key;
  }

}