import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SwalService, ThingService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { QuotationStatusService } from '@libs/services/src/lib/quotation-status.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { QuotationsStatusesModalComponent } from '../quotations-statuses-modal/quotations-statuses-modal.component';

@Component({
  selector: 'app-quotations-statuses-index',
  templateUrl: './quotations-statuses-index.component.html',
  styleUrls: ['./quotations-statuses-index.component.scss']
})
export class QuotationsStatusesIndexComponent implements OnInit, OnDestroy {
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'label',
      name: 'Rendu',
      searchable: false,
      orderable: false
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];
  public datatableSelector = 'quotations-statuses-datatable';
  private quotationStatus$: Subscription;
  public quotationStatus = null;
  public form: FormGroup;
  private labelsClasses$: Subscription;
  public labelsClasses: any;

  constructor(
    private quotationStatusService: QuotationStatusService,
    private thingService: ThingService,
    private modalService: NzModalService,
    private swalService: SwalService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.getValues();
  }

  ngOnDestroy() {
    this.quotationStatus$.unsubscribe();
    this.labelsClasses$.unsubscribe();

  }

  ngAfterViewInit() {
    this.quotationStatusService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  onShowModalAddEditModal() {
    this.form = this.quotationStatusService.getForm(this.quotationStatus);
    const modalInstance = this.modalService.create({
      nzContent: QuotationsStatusesModalComponent,
      nzComponentParams: {
        quotationStatus: this.quotationStatus,
        labelsClasses: this.labelsClasses,
        form: this.form
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.quotationStatus = null;
    })
  }

  private getValues() {
    this.thingService.getLabelsClassesSelect();
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.quotationStatus$ = that.quotationStatusService.get(id).subscribe(
          (data) => {
            if (data) {
              that.quotationStatus = data;
              that.onShowModalAddEditModal();
            }
          }
        );
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le statut sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.quotationStatusService.delete(id).subscribe(
              (success) => {
                this.swalService.showSwalSuccess(success);
              }
            );
          }
        });
      }
    });
  }

  private initSubscriptions() {
    this.labelsClasses$ = this.thingService.labelsClasses$.subscribe(
      (labelsClasses) => {
        if (labelsClasses) {
          this.labelsClasses = labelsClasses;
        }
      }
    );
  }

}
