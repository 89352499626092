import { AuthentificationService } from './../../../../../../libs/auth/src/lib/authentification.service';
import { Router } from '@angular/router';
import { pagesToggleService } from '@pages/services/toggler.service';
import { RootLayout } from '@pages/layouts/root/root.component';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PhoneLine, User } from '@libs/models/src';
import { CustomLocalStorageService } from '@libs/services/src/lib/utilities/custom-local-storage.service';
import { Subscription } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModalMarketTypeComponent } from '@libs/components/src/lib/modal-market-type/modal-market-type.component';
import { MarketTypeService, NotifService } from '@libs/services/src';
import { PhoneLineService } from '@libs/services/src/lib/phone-line.service';

@Component({
  selector: 'eros-front-app-with-sidebar',
  templateUrl: './app-with-sidebar.component.html',
  styleUrls: ['./../../app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWithSidebarComponent extends RootLayout implements OnInit {
  public menuLinks = [];
  public userInfos: User;
  public selectedMarketsTypes = [];
  public marketsTypes$: Subscription;
  public marketsTypes: any;
  public roles: any[];
  public phonesLinesStatuses$: Subscription;
  public phonesLinesStatuses: any;
  public userHasLines = false;
  public canDesactivate = false;

  constructor(
    _toggler: pagesToggleService,
    _router: Router,
    public localStorageService: CustomLocalStorageService,
    private authService: AuthentificationService,
    private marketTypeService: MarketTypeService,
    private modalService: NzModalService,
    private phoneLineService: PhoneLineService,
    private notifService: NotifService
  ) {
    super(_toggler, _router, localStorageService);
    this.initSubscriptions();
  }

  ngOnInit() {
    this.userInfos = this.authService.getUserInfos();
    this.marketTypeService.getAll();
    this._menuPin = this.localStorageService.isMenuPin();
    this.selectedMarketsTypes = this.localStorageService.getSelectedMarketsTypes();
    this.toggleMenuPin(null);
    this.roles = this.authService.getRoles();
    this.initMenuLinks();
  }

  logout() {
    this.authService.logout();
  }

  onSelectMarketTypeClicked() {
    const modalInstance = this.modalService.create({
      nzContent: ModalMarketTypeComponent,
      nzComponentParams: {
        marketsTypes: this.marketsTypes
      }
    })
    modalInstance.afterClose.subscribe(() => {
    })

  }

  isAdministrator() {
    if (this.roles) {
      return this.roles.includes('administrator');
    }
  }

  isManager() {
    if (this.roles) {
      return this.roles.includes('ra');
    }
  }

  private initSubscriptions() {
    this.marketsTypes$ = this.marketTypeService.getAll().subscribe(
      (data) => {
        if (data) {
          this.marketsTypes = data;
        }
      }
    )
    this.phonesLinesStatuses$ = this.phoneLineService.phonesLinesStatuses$.subscribe(
      (data) => {
        if (data) {
          this.phonesLinesStatuses = data;
          this.findUserLines();
        }
      }
    )
  }

  private findUserLines() {
    const userLines = this.phonesLinesStatuses.usersPhonesLines.find(x => x.id === this.userInfos.id);
    if (userLines != null) {
      this.userHasLines = true;
      this.canDesactivate = userLines.canDesactivate;
    }
  }

  public updatePhonesLinesStatuses(activate: boolean) {
    this.phoneLineService.updatePhonesLinesStatuses(this.userInfos.id, activate).subscribe(
      (success) => {
        this.notifService.showSuccessNotif(success);
        this.phoneLineService.getPhonesLinesStatuses();
      }
    )
  }

  private initMenuLinks() {
    this.menuLinks = [
      {
        label: 'Accueil',
        routerLink: '/dashboard',
        iconType: 'fa',
        iconName: 'th-large',
        isShow: true
      },
      {
        label: 'Admin',
        iconType: 'fa',
        iconName: 'lock',
        toggle: 'close',
        isShow: this.isAdministrator(),
        submenu: [
          {
            label: 'Gestion des utilisateurs',
            routerLink: '/admin/users/index',
            iconType: 'letter',
            iconName: 'gu'
          },
          {
            label: 'Gestion des rôles',
            routerLink: '/admin/roles/index',
            iconType: 'letter',
            iconName: 'gr'
          },
          {
            label: 'Gestion des permissions',
            routerLink: '/admin/permissions/index',
            iconType: 'letter',
            iconName: 'gp'
          }
        ]
      },
      {
        label: 'Calendrier',
        externalLink: '/calendar',
        target: '_blank',
        iconType: 'fas',
        iconName: 'fas fa-calendar',
        isShow: true
      },
      {
        label: 'Relation client',
        iconType: 'fas',
        iconName: 'fa fa-envelope',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Mails envoyés',
            routerLink: '/customer-relationship/mails-send',
            iconType: 'letter',
            iconName: 'ms'
          },
          {
            label: 'Réponses programmés',
            routerLink: '/customer-relationship/programmed-standards-responses',
            iconType: 'letter',
            iconName: 'ps'
          },
        ]
      },
      {
        label: 'Programmation',
        iconType: 'fas',
        iconName: 'fa fa-calendar-day',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Liste des évènements',
            routerLink: '/events/index',
            iconType: 'letter',
            iconName: 'ev'
          },
          {
            label: 'Préparation',
            routerLink: '/interventions/preparation',
            iconType: 'letter',
            iconName: 'pr'
          },
        ]
      },
      {
        label: 'Affaires',
        iconType: 'fa',
        iconName: 'suitcase',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Créer une affaire',
            routerLink: '/affairs/create',
            iconType: 'letter',
            iconName: 'af'
          },
          {
            label: 'Liste',
            routerLink: '/affairs/index',
            iconType: 'letter',
            iconName: 'lt'
          },
          {
            label: 'Liste des interventions',
            routerLink: '/interventions/index',
            iconType: 'letter',
            iconName: 'in'
          },
          {
            label: 'Affaire groupe',
            routerLink: '/affairs/mylink/index',
            iconType: 'letter',
            iconName: 'ag'
          },
          {
            label: 'Exports',
            routerLink: '/affairs/exports',
            iconType: 'letter',
            iconName: 'ex'
          }
        ]
      },
      {
        label: 'Base client',
        iconType: 'fa',
        iconName: 'user-alt',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Créer',
            routerLink: '/customers/create',
            iconType: 'letter',
            iconName: 'cr'
          },
          {
            label: 'Rechercher',
            routerLink: '/customers/search',
            iconType: 'letter',
            iconName: 'rc'
          }
        ]
      },
      {
        label: 'Facturation',
        iconType: 'fa',
        iconName: 'credit-card',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Factures',
            routerLink: '/invoices/index',
            iconType: 'letter',
            iconName: 'fc'
          },
          {
            label: 'Devis',
            routerLink: '/quotations/index',
            iconType: 'letter',
            iconName: 'dv'
          }
        ]
      },
      {
        label: 'Comptabilité',
        iconType: 'fa',
        iconName: 'university',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Paiements',
            routerLink: '/payments/index',
            iconType: 'letter',
            iconName: 'pm'
          },
          {
            label: 'Exports',
            routerLink: '/accounting/exports/index',
            iconType: 'letter',
            iconName: 'ex'
          },
          {
            label: 'Notes de frais',
            routerLink: '/expenses-accounts/index',
            iconType: 'letter',
            iconName: 'nf'
          }
        ]
      },
      {
        label: 'Inspections',
        iconType: 'fa',
        iconName: 'house',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Importer un lot',
            routerLink: '/inspections/create',
            iconType: 'letter',
            iconName: 'il',
            isHidden: !this.isAdministrator()
          },
          {
            label: 'Lots',
            routerLink: '/inspections/set',
            iconType: 'letter',
            iconName: 'lo',
            isHidden: !this.isAdministrator()
          },
          {
            label: 'Base Lot',
            routerLink: '/inspections/base-lot',
            iconType: 'letter',
            iconName: 'bl'
          },
          {
            label: 'Modèles',
            routerLink: '/inspections/modeles',
            iconType: 'letter',
            iconName: 'md',
            isHidden: !this.isAdministrator()
          },
          {
            label: 'Glossaire',
            routerLink: '/inspections/glossary',
            iconType: 'letter',
            iconName: 'gl',
            isHidden: !this.isAdministrator()
          },
          {
            label: 'Périodes',
            routerLink: '/inspections/period',
            iconType: 'letter',
            iconName: 'pe',
            isHidden: !this.isAdministrator()
          },
          {
            label: 'Historique de liaisons',
            routerLink: '/inspections/history',
            iconType: 'letter',
            iconName: 'hi',
            isHidden: !this.isAdministrator()
          },
          {
            label: 'Outils Beta',
            routerLink: '/inspections/beta',
            iconType: 'letter',
            iconName: 'β',
            isHidden: !this.isAdministrator()
          }
        ]
      },
      {
        label: 'Statistiques',
        iconType: 'fa',
        iconName: 'chart-line',
        toggle: 'close',
        isShow: this.isManager() || this.isAdministrator(),
        submenu: [
          {
            label: 'Volumes',
            routerLink: '/stats/volumes',
            iconType: 'letter',
            iconName: 'af'
          },
          {
            label: 'Chiffre d\'affaires',
            routerLink: '/stats/revenues',
            iconType: 'letter',
            iconName: 'ca'
          },
          {
            label: 'Client',
            routerLink: '/stats/customer',
            iconType: 'letter',
            iconName: 'cl'
          },
          {
            label: 'Contrôle CA techs',
            routerLink: '/stats/check-revenue-technicians',
            iconType: 'letter',
            iconName: 'ct'
          }
        ]
      },
      {
        label: 'Paramètres',
        iconType: 'fa',
        iconName: 'cog',
        toggle: 'close',
        isShow: this.isAdministrator(),
        submenu: [
          {
            label: 'Général',
            routerLink: '/settings/general',
            iconType: 'letter',
            iconName: 'gn'
          },
          {
            label: 'Affaire',
            routerLink: '/settings/affairs',
            iconType: 'letter',
            iconName: 'af'
          },
          {
            label: 'Production',
            routerLink: '/settings/products',
            iconType: 'letter',
            iconName: 'pt'
          },
          {
            label: 'Facturation',
            routerLink: '/settings/invoicing',
            iconType: 'letter',
            iconName: 'ft'
          },
          {
            label: 'Comptabilité',
            routerLink: '/settings/accounting',
            iconType: 'letter',
            iconName: 'cp'
          },
          {
            label: 'Interventions',
            routerLink: '/settings/interventions',
            iconType: 'letter',
            iconName: 'cp'
          },
          {
            label: 'Relation client',
            routerLink: '/settings/customer-relationship',
            iconType: 'letter',
            iconName: 'rc'
          },
          {
            label: 'Client',
            routerLink: '/settings/customer',
            iconType: 'letter',
            iconName: 'cl'
          },
          {
            label: 'Connexions',
            routerLink: '/settings/connections',
            iconType: 'letter',
            iconName: 'co'
          }
        ]
      },
      {
        label: 'Assistance',
        externalLink: 'https://support.octetservices.fr/',
        target: '_blank',
        iconType: 'fa',
        iconName: 'question-circle',
        isShow: true
      },
    ];

  }

}
