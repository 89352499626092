import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit, ViewEncapsulation, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormGroup } from '@angular/forms';
import { AffairService, SubmitButtonService, AgencyService, MlAgencyService, FormFormatterService, MarketTypeService, SelectService } from '@eros-front/services';
import { Subscription } from 'rxjs';
import { AffairGoal, SubmitButton } from '@eros-front/models';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AffairMarketType } from '@eros-front/enum';
import { AutocompleteOptions } from '@libs/services/src/lib/classes/autocomplete-options';
import { AffairMyLinkService } from '@libs/services/src/lib/affair-mylink.service';
import { AffairGoalService } from '@libs/services/src/lib/affair-goal.service';
import { StudyOfficeService } from '@libs/services/src/lib/study-office.service';
import { CustomLocalStorageService } from '@libs/services/src/lib/utilities/custom-local-storage.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-affair-create',
  templateUrl: './affair-create.component.html',
  styleUrls: ['./affair-create.component.scss']
})
export class AffairCreateComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() appKey;
  public submitButton: SubmitButton;
  public agencies = [];
  private agenciesSelect$: Subscription;
  public mlAgenciesSelect = [];
  private mlAgenciesSelect$: Subscription;
  public marketsTypes = [];
  private marketsTypes$: Subscription;
  public form: FormGroup;
  public affairMarketTypeEnum = AffairMarketType;
  private affairsSearch$: Subscription;
  public autocompleteOptions: AutocompleteOptions = new AutocompleteOptions();
  private affairsGoals$: Subscription;
  public affairsGoals: any;
  public affairsGoalsSelect: any;
  public agency$: Subscription;
  public selectStudiesOffices$: Subscription;
  public studiesOffices: any;
  public officesFiles = [];
  public affairsMyLinkResults = [];
  public finalCustomerLastnameChanged = "";
  public workSiteLocalityChanged = "";
  public displayResults = false;
  public selectedMarketsTypesIds = [];
  public technicians = [];
  private technicians$: Subscription;

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private affairService: AffairService,
    private agencyService: AgencyService,
    private mlAgencyService: MlAgencyService,
    private marketTypeService: MarketTypeService,
    private submitButtonService: SubmitButtonService,
    private formFormatterServiceService: FormFormatterService,
    private affairMyLinkService: AffairMyLinkService,
    private affairGoalService: AffairGoalService,
    private studyOfficeService: StudyOfficeService,
    private changeDetector: ChangeDetectorRef,
    private localStorage: CustomLocalStorageService,
    private selectService: SelectService
  ) {
    this.titleService.setTitle('Création d\'une affaire');
    this.getValues();
    this.initSubscriptions();
  }

  ngOnInit() {
    this.setSubmitButton();
    this.form = this.affairService.getInformationsForm(null);
    if (this.selectedMarketsTypesIds.length == 1) {
      const defaultMarketTypeId = this.selectedMarketsTypesIds[0];
      this.form.patchValue({ 'marketTypeId': defaultMarketTypeId });
    }
    this.formChanges();
    this.route.data.subscribe(data => {
      this.appKey = data.appKey;
    })
  }

  searchOnMyLink() {
    this.affairMyLinkService.search(this.form);
  }

  ngOnDestroy() {
    this.agenciesSelect$.unsubscribe();
    this.mlAgenciesSelect$.unsubscribe();
    this.marketsTypes$.unsubscribe();
    this.affairsSearch$.unsubscribe();
    this.agency$.unsubscribe();
    this.affairsGoals$.unsubscribe();
    this.affairsSearch$.unsubscribe();
    this.technicians$.unsubscribe();
    this.affairsMyLinkResults = [];
    this.form = null;
    this.displayResults = false;
  }

  ngAfterViewInit() {
    this.displayResults = false;
    this.changeDetector.detectChanges();
  }

  onSubmit() {
    if (this.isValidForm()) {
      this.affairService.create(this.form);
    }
  }

  onWorkSiteAddressChange(address: Address) {
    this.form = this.formFormatterServiceService.completeGoogleAddress(
      this.form, address,
      {
        street_number: 'workSiteStreetNumber',
        route: 'workSiteAddress',
        postal_code: 'workSitePostalCode',
        locality: 'workSiteLocality'
      }
    );
  }

  onFinalCustomerAddressChange(address: Address) {
    this.form = this.formFormatterServiceService.completeGoogleAddress(
      this.form, address,
      {
        street_number: 'finalCustomerStreetNumber',
        route: 'finalCustomerAddress',
        postal_code: 'finalCustomerPostalCode',
        locality: 'finalCustomerLocality'
      }
    );
  }

  getMarketTypeValue() {
    if (this.form) {
      return this.form.value.marketTypeId;
    }
  }

  isCollectiveMarket() {
    return this.getMarketTypeValue() == this.affairMarketTypeEnum.COLLECTIVE || this.getMarketTypeValue() == this.affairMarketTypeEnum.TERTIARY;
  }

  isOtherMarket() {
    return this.getMarketTypeValue() == this.affairMarketTypeEnum.STANDALONE_HOUSE
      || this.getMarketTypeValue() == this.affairMarketTypeEnum.RENOVATION
      || this.getMarketTypeValue() == this.affairMarketTypeEnum.INSPECTION;
  }

  isValidForm() {
    return this.form.valid;
  }

  onChangeAgency(selected) {
    if (selected != null) {
      this.agencyService.get(selected.value);
    }
  }

  private searchValuesMyLinkChanged(form: FormGroup): boolean {
    return this.finalCustomerLastnameChanged !== "" && this.workSiteLocalityChanged !== "";
  }

  private getValues() {
    this.agencyService.getForSelect();
    this.mlAgencyService.getForSelect();
    this.marketTypeService.getAll();
    this.affairGoalService.getAll();
    this.studyOfficeService.getForSelect();
    this.selectService.getTechniciansSelect();
    this.selectedMarketsTypesIds = this.localStorage.getSelectedMarketsTypesIds();
  }

  private initSubscriptions() {
    this.agenciesSelect$ = this.agencyService.agenciesSelect$.subscribe(
      (data) => {
        if (data) {
          this.agencies = data;
        }
      }
    );
    this.agency$ = this.agencyService.agency$.subscribe(
      (agency) => {
        if (this.form && agency) {
          if (agency.mlAgencyId) {
            this.form.patchValue({ 'mlAgencyId': agency.mlAgencyId });
          }
          if (agency.userTechnicianId) {
            this.form.patchValue({ 'userTechnicianId': agency.userTechnicianId });
          }
        }
      }
    );
    this.mlAgenciesSelect$ = this.mlAgencyService.mlAgenciesSelect$.subscribe(
      (data) => {
        if (data) {
          this.mlAgenciesSelect = data;
        }
      }
    );
    this.marketsTypes$ = this.marketTypeService.getAll().subscribe(
      (data) => {
        if (data) {
          this.marketsTypes = data;
        }
      }
    );
    this.affairsSearch$ = this.affairMyLinkService.affairsSearch$.subscribe(
      (data) => {
        if (data) {
          this.affairsMyLinkResults = data;
          this.displayResults = this.affairsMyLinkResults.length > 0;
        }
      }
    );
    this.affairsGoals$ = this.affairGoalService.getAll().subscribe(
      (data) => {
        if (data) {
          this.affairsGoals = data;
          this.updateAffairsGoalsByMarketType(1);
        }
      }
    )
    this.selectStudiesOffices$ = this.studyOfficeService.selectStudiesOffices$.subscribe(
      (data) => {
        if (data) {
          this.studiesOffices = data;
        }
      }
    )
    this.technicians$ = this.selectService.selectTechnicians$.subscribe(
      (data) => {
        if (data) {
          this.technicians = data;
        }
      }
    )
  }

  private setSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'l\'affaire',
      text: 'Enregistrer',
      icon: faSave
    });
    this.affairService.setSubmitButton(this.submitButton);
  }

  private updateAffairsGoalsByMarketType(marketTypeId) {
    this.affairsGoalsSelect = [];
    this.affairsGoals.forEach((affairGoal: AffairGoal) => {
      if (affairGoal.marketsTypes.find(x => x.id == marketTypeId)) {
        this.affairsGoalsSelect.push({
          label: affairGoal.name,
          value: affairGoal.id
        })
      }
    })
  }

  private formChanges() {
    this.form.controls["finalCustomerLastname"].valueChanges.subscribe(selectedValue => {
      this.finalCustomerLastnameChanged = selectedValue;
      if (this.searchValuesMyLinkChanged(this.form)) {
        setTimeout(() => this.affairMyLinkService.search(this.form));
      }
    });

    this.form.controls["workSiteLocality"].valueChanges.subscribe(selectedValue => {
      this.workSiteLocalityChanged = selectedValue;
      if (this.searchValuesMyLinkChanged(this.form)) {
        setTimeout(() => this.affairMyLinkService.search(this.form));
      }
    });

    this.form.controls['marketTypeId'].valueChanges.subscribe(value => {
      this.updateAffairsGoalsByMarketType(value);
    });
  }

  private setStudyOfficeDefault(officesFiles, marketTypeId) {
    const officeFile = officesFiles.find(x => x.marketTypeId == marketTypeId);
    if (officeFile && officeFile.studiesOffices.length == 1) {
      const defaultStudyOfficeId = officeFile.studiesOffices[0].id;
      this.form.patchValue({ 'studyOfficeId': defaultStudyOfficeId });
    }
  }
}
