import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Affair, AffairContact } from '@libs/models/src';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-affair-show-office-file',
  templateUrl: './affair-show-office-file.component.html',
  styleUrls: ['./affair-show-office-file.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AffairShowOfficeFileComponent implements OnInit {

  @Input() affair: Affair;
  @Input() affairOfficeFile: any;
  @Input() affairContacts: AffairContact[];

  constructor(private modalService: NzModalService) { }

  ngOnInit() {
  }

}
