import { ApiService } from '@eros-front/api';
import { FormFormatterService } from './utilities/form-formatter.service';
import { Router } from '@angular/router';
import { SelectService } from './utilities/select.service';
import { ModelWithDatatableAndModalCrud } from './classes/model-datatable-modal-crud';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { CommonService } from './utilities/common.service';
import { Brand, Group, SubmitButton } from '@eros-front/models';
import { ModelWithDatatableAndCrud } from './classes/model-datatable-crud';
import { catchError, finalize } from 'rxjs/operators';


export interface BrandSelectParam {
    selectedGroups: any[];
    selectedMarketsTypes?: any[];
    selectedCustomersTypes?: any[];
    selectedIndicatorsCustomers?: any[];
    selectedRivals?: any[];
    selectedManagers?: any[];
}

export interface BrandDatatableParameters {
    selectedBrands: any[];
}

export interface BrandFormParameters {
    companies: any[];
    marketsTypes: any[];
    products: any[];
}

@Injectable()
export class BrandService extends ModelWithDatatableAndCrud {

    private route = '/brands';
    public brand$ = new BehaviorSubject<any>(undefined);
    public dtInstance: DataTables.Api;
    private columns: DataTableColumn[];
    public selectedBrands = new Subject<any>();
    public brandsSelect$ = new BehaviorSubject<any>(undefined);
    public selectedBrands$ = this.selectedBrands.asObservable();
    public ajaxParameters: any;
    public brandSelectParams: BrandSelectParam;
    public createButton: SubmitButton;
    public submitPricesButton: SubmitButton;

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        apiService: ApiService,
        router: Router,
        private formBuilder: FormBuilder,
        private selectService: SelectService,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public setDataTableColumns(columns: DataTableColumn[]) {
        this.columns = columns;
    }

    public setSelectedGroupsItems(selectedGroupsItems) {
        this.selectedBrands.next(selectedGroupsItems);
    }

    public getSelectedGroupsItems() {
        this.selectedBrands.next();
    }

    public initDataTable(selector: string, columns: DataTableColumn[]) {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public redrawDataTable(params: BrandDatatableParameters) {
        super.redrawDataTable(params);
    }

    public getForSelectWithParams(form: FormGroup): Observable<any> {
        return this.apiService.post(this.route + '/select-with-parameters', this.formatFormSelectWithParams(form));
    }

    public applyFilters(form: any, selectedGroups: any): void {
        this.brandSelectParams = {
            selectedGroups: selectedGroups.map(x => x.value),
            selectedMarketsTypes: this.formFormatterService.formatSelectSingle(form.value.selectedMarketsTypes),
            selectedCustomersTypes: this.formFormatterService.formatSelectSingle(form.value.selectedCustomersTypes),
            selectedIndicatorsCustomers: this.formFormatterService.formatSelectSingle(form.value.selectedIndicatorsCustomers),
            selectedRivals: this.formFormatterService.formatSelectSingle(form.value.selectedRivals),
            selectedManagers: this.formFormatterService.formatSelectSingle(form.value.selectedManagers),
        };
        this.selectService.getForSelectWithParams(this.brandsSelect$, this.route, this.brandSelectParams);
    }

    public getByRef(ref: string) {

        this.apiService.get('/customers/get/' + ref)
            .subscribe(
                (object) => {
                    this.brand$.next(object);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public store(form: any): Observable<any> {
        this.submitButtonService.setDisabled(this.createButton);
        return this.apiService.post(this.route, this.formatForm(form)).pipe(
            catchError(error => {
                this.swalService.showSwalError(error);
                return throwError(error);
            }),
            finalize(() => {
                this.submitButtonService.setEnabled(this.createButton);
            })
        );
    }

    public update(id: number, form: any): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.put(this.route + '/' + id, this.formatForm(form))
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            );
    }

    public updatePrices(id: number, form: any): Observable<any> {
        this.submitButtonService.setDisabled(this.submitPricesButton);
        return this.apiService.put(`${this.route}/${id}/prices`, form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitPricesButton);
                })
            );
    }

    public getForm(params: BrandFormParameters, brand?: Brand): FormGroup {
        const name = brand ?
            this.commonService.valueToFormString(brand.name) : this.commonService.getDefaultFormStringValue();
        const administrativeInternalNote = brand ?
            this.commonService.valueToFormString(brand.administrativeInternalNote) : this.commonService.getDefaultFormStringValue();
        const technicalInternalNote = brand ?
            this.commonService.valueToFormString(brand.technicalInternalNote) : this.commonService.getDefaultFormStringValue();
        const marketsTypes = brand ?
            brand.marketsTypes.map(x => ({ value: x.id, label: x.name })) : [];
        const customersTypes = brand ?
            brand.customersTypes.map(x => ({ value: x.id, label: x.name })) : [];
        const indicatorsCustomers = brand ?
            brand.indicatorsCustomers.map(x => ({ value: x.id, label: x.name })) : [];
        const products = [];
        // Products
        for (const marketType of params.marketsTypes) {
            const subArray = [];
            for (const product of params.products) {
                let productValue = 0;
                if (brand && brand.products && brand.products[marketType.value]) {
                    const productObj = brand.products[marketType.value][product.id];
                    if (productObj != null && typeof productObj === 'object') {
                        productValue = productObj.pivot.price;
                    }
                }
                subArray.push(
                    this.formBuilder.group({ ['product' + product.id]: productValue })
                );
            }
            products.push(this.formBuilder.array(subArray));
        }
        return this.formBuilder.group({
            name: [name, Validators.required],
            administrativeInternalNote: administrativeInternalNote,
            technicalInternalNote: technicalInternalNote,
            marketsTypes: [marketsTypes],
            customersTypes: [customersTypes],
            indicatorsCustomers: [indicatorsCustomers],
            products: this.formBuilder.array(products)
        });
    }


    public getCreateForm(group: Group): FormGroup {
        return this.formBuilder.group({
            name: [this.commonService.getDefaultFormStringValue(), Validators.required],
            groupId: [group.id, Validators.required],
            administrativeInternalNote: this.commonService.getDefaultFormStringValue(),
            technicalInternalNote: this.commonService.getDefaultFormStringValue(),
            marketsTypes: [group.marketsTypes.map(x => ({ value: x.id, label: x.name }))],
            customersTypes: [group.customersTypes.map(x => ({ value: x.id, label: x.name }))],
            indicatorsCustomers: [group.indicatorsCustomers.map(x => ({ value: x.id, label: x.name }))],
            mlSocieties: new FormArray([])
        });
    }


    public getInformationsForm(brand?: Brand): FormGroup {
        const name = brand ?
            this.commonService.valueToFormString(brand.name) : this.commonService.getDefaultFormStringValue();
        const administrativeInternalNote = brand ?
            this.commonService.valueToFormString(brand.administrativeInternalNote) : this.commonService.getDefaultFormStringValue();
        const technicalInternalNote = brand ?
            this.commonService.valueToFormString(brand.technicalInternalNote) : this.commonService.getDefaultFormStringValue();
        const marketsTypes = brand ?
            brand.marketsTypes.map(x => ({ value: x.id, label: x.name })) : [];
        const customersTypes = brand ?
            brand.customersTypes.map(x => ({ value: x.id, label: x.name })) : [];
        const indicatorsCustomers = brand ?
            brand.indicatorsCustomers.map(x => ({ value: x.id, label: x.name })) : [];
        return this.formBuilder.group({
            name: [name, Validators.required],
            administrativeInternalNote: administrativeInternalNote,
            technicalInternalNote: technicalInternalNote,
            marketsTypes: [marketsTypes],
            customersTypes: [customersTypes],
            indicatorsCustomers: [indicatorsCustomers],
            mlSocieties: new FormArray([])
        });
    }

    public getPricesForm(marketsTypes: any[], products: any[], brand?: Brand): FormGroup {
        const results = [];
        for (const marketType of marketsTypes) {
            const subArray = [];
            for (const product of products) {
                let productValue = 0;
                if (brand && brand.products && brand.products[marketType.value]) {
                    const productObj = brand.products[marketType.value][product.id];
                    if (productObj != null && typeof productObj === 'object') {
                        productValue = productObj.pivot.price;
                    }
                }
                subArray.push(
                    this.formBuilder.group({ ['product' + product.id]: productValue })
                );
            }
            results.push(this.formBuilder.array(subArray));
        }
        return this.formBuilder.group({
            products: this.formBuilder.array(results)
        });
    }

    private formatForm(form: FormGroup) {
        const values = this.formFormatterService.createFormCopy(form);
        values.marketsTypes = this.formFormatterService.formatSelectMultipleToIntArray(values.marketsTypes);
        values.customersTypes = this.formFormatterService.formatSelectMultipleToIntArray(values.customersTypes);
        values.indicatorsCustomers = this.formFormatterService.formatSelectMultipleToIntArray(values.indicatorsCustomers);
        values.mlSocieties = this.formFormatterService.formatSelectMultipleToIntArray(this.formFormatterService.filterCheckedValues(values.mlSocieties), 'mlSocietyId');
        return values;
    }

    private formatFormSelectWithParams(form: FormGroup) {
        const values = this.formFormatterService.createFormCopy(form);
        values.selectedGroups = this.formFormatterService.formatSelectMultipleToIntArray(values.selectedGroups);
        return values;
    }

    public setCreateSubmitButton(button: SubmitButton) {
        this.createButton = button;
    }

    public setSubmitPricesButton(button: SubmitButton) {
        this.submitPricesButton = button;
    }

}
