import Swal from 'sweetalert2';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Agency, Invoice, Payer, SubmitButton } from '@libs/models/src';
import { AgencyService, CommonService, FileService, FileUtilsService, FormFormatterService, PayerService, SubmitButtonService, SwalService } from '@libs/services/src';
import { InvoiceService } from '@libs/services/src/lib/invoice.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Subscription } from 'rxjs';
import { InvoiceGenerateCreditModalComponent } from '../invoice-generate-credit-modal/invoice-generate-credit-modal.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { DocumentModalStandardResponseComponent } from '@libs/components/src/lib/document-modal-standard-response/document-modal-standard-response.component';

@Component({
  selector: 'app-invoice-show-informations',
  templateUrl: './invoice-show-informations.component.html',
  styleUrls: ['./invoice-show-informations.component.scss']
})
export class InvoiceShowInformationsComponent implements OnInit, OnDestroy {

  @Input() invoice: Invoice;
  @Input() mlAgencies = [];
  @Input() agencies = [];
  @Input() payers = [];
  @Input() payersPreferences = [];
  public form: FormGroup;
  public submitButton: SubmitButton;
  private agency$: Subscription;
  private payer$: Subscription;

  constructor(
    private invoiceService: InvoiceService,
    private agencyService: AgencyService,
    private payerService: PayerService,
    private commonService: CommonService,
    private submitButtonService: SubmitButtonService,
    private formFormatterService: FormFormatterService,
    private swalService: SwalService,
    private fileService: FileService,
    private modalService: NzModalService,
    private router: Router,
    private fileUtilsService: FileUtilsService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.setSubmitButton();
    this.form = this.invoiceService.getUpdateForm(this.invoice);
  }

  ngOnDestroy() {
    this.agency$.unsubscribe();
    this.payer$.unsubscribe();
  }

  onPayerAddressChange(address: Address) {
    this.form = this.formFormatterService.completeGoogleAddress(
      this.form, address,
      {
        route: 'payerAddress',
        postal_code: 'payerPostalCode',
        locality: 'payerLocality',
        concatStreetWithRoute: true
      }
    );
    this.form.markAsDirty();
  }

  onSelectedAgencyChange(value: any) {
    if (value && value.value) {
      this.agencyService.get(value.value);
      this.form.patchValue({
        payerId: this.commonService.getDefaultFormNullValue()
      });
    } else {
      this.agencyService.agency$.next(null);
      this.completePayerInformation(null);
    }
  }

  onSelectedPayerChange(value: any) {
    if (value && value.id) {
      this.payerService.get(value.id);
      this.form.patchValue({
        agencyId: this.commonService.getDefaultFormNullValue()
      });
    } else {
      this.payerService.payer$.next(null);
      this.completePayerInformation(null);
    }
  }


  onModalDocumentStandardResponseShow() {
    const modalInstance = this.modalService.create({
      nzContent: DocumentModalStandardResponseComponent,
      nzComponentParams: {
        document: this.invoice,
      },
      nzStyle: { top: '2vh' }
    });
  }

  isValidForm() {
    return this.form.valid && this.form.dirty;
  }

  formatNumberToLocale(property: string): string {
    if (this.invoice[property] && !isNaN(this.invoice[property])) {
      return this.commonService.formatNumberToLocale(this.invoice[property]);
    }
    return '0';
  }

  onSaveClicked() {
    if (this.isValidForm()) {
      this.invoiceService.update(this.invoice.id, this.form);
      this.invoiceService.get(this.invoice.id);
    }
  }

  onShowInTabClicked() {
    this.invoiceService.generateReport(this.invoice.id).subscribe(
      (invoice) => {
        this.fileUtilsService.openPreviewDocument(invoice.path, 'application/pdf');
      }
    )
  }

  onDownloadClicked() {
    this.invoiceService.download(this.invoice.id).subscribe(
      (file) => {
        this.fileUtilsService.responseToDownload(file, 'pdf');
      }
    );
  }

  onDownloadXmlClicked() {
    this.invoiceService.getXmlIgc(this.invoice.id).subscribe((response) => {
      this.fileUtilsService.responseToDownload(response, 'xml');
    });
  }

  onDownloadIgcClicked() {
    this.invoiceService.downloadForIgc(this.invoice.id).subscribe((response) => {
      this.fileUtilsService.responseToDownload(response, 'pdf');
    });
  }

  onValidateClicked() {
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'Seule la référence et la désignation pourront être modifiés par la suite'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.invoiceService.validate(this.invoice.id);
      }
    });
  }

  onDeleteClicked() {
    const swalOptions = this.swalService.getSwalDeleteOptions({
      text: 'La facture sera supprimée de manière irréversible'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.invoiceService.delete(this.invoice.id);
      }
    });
  }

  onOpenModalGenerateCredit() {
    const modalInstance = this.modalService.create({
      nzWidth: '60%',
      nzContent: InvoiceGenerateCreditModalComponent,
      nzComponentParams: {
        invoice: this.invoice
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.invoiceService.get(this.invoice.id);
    });

  }

  onRedirectAffairClicked(invoice: Invoice): void {
    const firstAffair = invoice.affairs ? invoice.affairs[0] : null
    if (firstAffair != null) {
      this.router.navigateByUrl('/affairs/' + firstAffair.id + '/show');
    }
  }

  private initSubscriptions(): void {
    this.agency$ = this.agencyService.agency$.subscribe(
      (data) => {
        if (data) {
          this.completePayerInformation(data);
        }
      }
    );
    this.payer$ = this.payerService.payer$.subscribe(
      (data) => {
        if (data) {
          this.completePayerInformation(data);
        }
      }
    );
  }

  private completePayerInformation(entity?: Agency | Payer): void {
    const defaultString = this.commonService.getDefaultFormStringValue();
    this.form.patchValue({
      payerName: entity ? entity.name : defaultString,
      accountingCode: entity ? entity.accountingCode : defaultString,
      payerAddress: entity ? entity.address : defaultString,
      payerAdditionalAddress: entity ? entity.additionalAddress : defaultString,
      payerPostalCode: entity ? entity.postalCode : defaultString,
      payerLocality: entity ? entity.locality : defaultString
    });
  }

  private setSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'les changements',
      text: 'Enregistrer'
    });
    this.invoiceService.setSubmitButton(this.submitButton);
  }
}
