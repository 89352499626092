import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { ModelWithDatatableAndCrud } from './classes/model-datatable-crud';
import { DataTableColumn } from './classes/model-datatable';
import { FormFormatterService } from './utilities/form-formatter.service';
import { CommonService } from './utilities/common.service';
import { catchError, finalize } from 'rxjs/operators';

export interface InvoiceTemporaryDatatableParameters {
    isTemporary: boolean;
    affair: string;
    selectedAgencies: string;
    selectedMlAgencies: string;
    selectedProducts: string;
    selectedInvoicesStatuses: string;
    page: number;
    size: number;
    search: number;
    sortField: string;
    sortOrder: string;
    individualSearch: any;
}

@Injectable()
export class InvoiceTemporaryService extends ModelWithDatatableAndCrud {

    private route = '/invoices/temporary';
    public invoice$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[], form?: FormGroup): void {
        super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns,
            useCheckbox: true,
            pageLength: 25
        }, this.formToDatatableParameters(form));
    }

    public redrawDataTable(form: FormGroup): void {
        super.redrawDataTable(this.formToDatatableParameters(form));
    }

    public getFiltersForm(formValue?: any): FormGroup {
        const affair = formValue ?
            this.commonService.valueToFormString(formValue.affair) : this.commonService.getDefaultFormStringValue();
        const selectedAgencies = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAgencies) : this.commonService.getDefaultFormNullValue();
        const selectedMlAgencies = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedMlAgencies) : this.commonService.getDefaultFormNullValue();
        const selectedProducts = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedProducts) : this.commonService.getDefaultFormNullValue();
        const selectedInvoicesStatuses = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedInvoicesStatuses) : this.commonService.getDefaultFormNullValue();
        const page = formValue ?
            formValue.page : 1;
        const size = formValue ?
            formValue.page : 10;
        const search = formValue ?
            formValue.search : this.commonService.getDefaultFormStringValue();
        const sortField = formValue ?
            formValue.sortField : this.commonService.getDefaultFormNullValue();
        const sortOrder = formValue ?
            formValue.sortOrder : this.commonService.getDefaultFormNullValue();
        return this.formBuilder.group({
            isTemporary: true,
            affair: affair,
            selectedAgencies: [selectedAgencies],
            selectedMlAgencies: [selectedMlAgencies],
            selectedProducts: [selectedProducts],
            selectedInvoicesStatuses: [selectedInvoicesStatuses],
            page: [page],
            size: [size],
            search: [search],
            sortField: [sortField],
            sortOrder: [sortOrder],
            individualSearch: new FormArray([])
        });
    }

    public get(id: number): void {
        super.get(id, this.invoice$, this.route);
    }

    public create(form: FormGroup): void {
        super.store(form.value, this.route);
    }

    public update(id: number, form: FormGroup): void {
        super.update(id, form.value, this.route);
    }

    public validateSelected(selectedInvoices: number[]): Observable<any> {
        return this.apiService.post(`${this.route}/selected/validate`, {
            'selectedInvoices': selectedInvoices
        })
    }

    private formToDatatableParameters(form: FormGroup): InvoiceTemporaryDatatableParameters {
        if (form) {
            return {
                isTemporary: form.value.isTemporary,
                affair: form.value.affair,
                selectedAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedAgencies),
                selectedMlAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedMlAgencies),
                selectedProducts: this.formFormatterService.formatSelectMultiple(form.value.selectedProducts),
                selectedInvoicesStatuses: this.formFormatterService.formatSelectMultiple(form.value.selectedInvoicesStatuses),
                page: form.value.page,
                size: form.value.size,
                search: form.value.search,
                sortField: form.value.sortField,
                sortOrder: form.value.sortOrder,
                individualSearch: form.value.individualSearch
            };
        }
        return null;
    }

}
