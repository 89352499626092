import { Component, Input, OnInit } from '@angular/core';
import { IncomingCallSheetMailListParameters, MailService } from '@libs/services/src';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-incoming-call-sheet-emails',
  templateUrl: './incoming-call-sheet-emails.component.html',
  styleUrls: ['./incoming-call-sheet-emails.component.scss']
})
export class IncomingCallSheetEmailsComponent implements OnInit {
  @Input() contact: any;
  public pageSize: number = 10;
  public pageIndex: number = 1;
  public total: number = 1;
  public loading: boolean;
   public incomingCallSheetParams: IncomingCallSheetMailListParameters = {
    email: null,
    size: 10,
    search: '',
    page: 1,
  }
  public emailsData = [];
  public inputSearchValue = '';

  constructor(
    private mailService: MailService,
  ) { }

  ngOnInit() {
    this.initMailsList();
  }

  onInputSearchChanged(newSearch: string): void {
    this.inputSearchValue = newSearch;
    this.initMailsList();
  }

    onQueryParamsChange(params: NzTableQueryParams): void {
    this.pageIndex = params.pageIndex;
    this.pageSize = params.pageSize;
    this.initMailsList();
  }

  private initMailsList(): void {
    this.incomingCallSheetParams = {
        email: this.contact.email,
        size: this.pageSize,
        page: this.pageIndex,
        search: this.inputSearchValue
    }
    this.loading = true;
    this.mailService.getForIncomingCallList(this.incomingCallSheetParams)
    .pipe(
      finalize(() => {
        this.loading = false;
      })
    ).subscribe(
      (response) => {
        this.emailsData = response.data;
        this.total = response.meta.total;

      }
    )
  }

}
