import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Form, FormGroup } from '@angular/forms';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { AffairPayerCustomer, AffairPayerInvoicing, AffairPayerPreference, MlSocietyEnum } from '@libs/enum/src';
import { Affair, MlAgency, Payer, SubmitButton } from '@libs/models/src';
import { PayersModalAddComponent } from '@libs/payers/src/lib/payers-modal-add/payers-modal-add.component';
import { CustomerService, SubmitButtonService } from '@libs/services/src';
import { AffairInvoicingPreferenceService } from '@libs/services/src/lib/affair-invoicing-preference.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-affair-show-invoices-invoicing-preferences',
  templateUrl: './affair-show-invoices-invoicing-preferences.component.html',
  styleUrls: ['./affair-show-invoices-invoicing-preferences.component.scss']
})
export class AffairShowInvoicesInvoicingPreferencesComponent implements OnInit, OnChanges {
  @Input() affair: Affair;
  @Input() payers: Payer[];
  @Input() mlAgencies: MlAgency[];
  @Input() appKey: string = '';
  public submitButton: SubmitButton;
  public form: FormGroup;
  public affairPayerPreferenceEnum = AffairPayerPreference;
  public affairPayerInvoicingEnum = AffairPayerInvoicing;
  public affairPayerCustomerEnum = AffairPayerCustomer;
  public mlSocietyEnum = MlSocietyEnum;

  constructor(
    private affairInvoicingPreferenceService: AffairInvoicingPreferenceService,
    private submitButtonService: SubmitButtonService,
    private modalService: NzModalService,
    private customerService: CustomerService
  ) { }

  ngOnInit() {
    this.form = this.affairInvoicingPreferenceService.getForm(this.affair);
    this.setSubmitButton();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['affair'] && changes['affair'].previousValue != changes['affair'].currentValue) {
      this.form = this.affairInvoicingPreferenceService.getForm(this.affair);
    }
  }

  private setSubmitButton() {
    const pref = this.affair.affairInvoicingPreferences;
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'les informations de facturation',
      text: pref ? 'Mettre à jour' : 'Enregistrer',
      icon: faSave
    });
    this.affairInvoicingPreferenceService.setSubmitButton(this.submitButton);
  }

  onPayerChange(item: any) {
    this.invoicePreferencesPayerRadioChange(
      this.affairPayerPreferenceEnum.OTHER,
      item
    );
  }

  payerPreferenceRadioChange(value: AffairPayerPreference) {
    this.invoicePreferencesPayerRadioChange(
      value,
      this.affair
    );
  }

  onModalAddPayerShow() {
    const modalInstance = this.modalService.create({
      nzContent: PayersModalAddComponent,
      nzComponentParams: {
        agencyId: this.affair.agencyId
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.customerService.refreshEntityByCustomerRef(this.affair.agency.ref);
    })
  }


  submit() {
    const pref = this.affair.affairInvoicingPreferences;
    if (this.isValidForm()) {
      if (pref != null) {
        this.affairInvoicingPreferenceService.update(this.affair.id, this.form);
      } else {
        this.affairInvoicingPreferenceService.store(this.form);
      }
    }
  }

  private invoicePreferencesPayerRadioChange(value: AffairPayerPreference, object: any): void {
    let payerPreference = 0;
    let payerName = '';
    let payerAccountingCode = '';
    let payerAddress = '';
    let payerPostalCode = '';
    let payerLocality = '';
    if (object) {
      if (value === this.affairPayerPreferenceEnum.APPLICANT) {
        const pref = object.affairInvoicingPreferences;
        payerPreference = this.affairPayerPreferenceEnum.APPLICANT.toString();
        payerName = object.agency.name;
        payerAccountingCode = object.agency.accountingCode;
        payerAddress = object.agency.address;
        payerPostalCode = object.agency.postalCode;
        payerLocality = object.agency.locality;
      } else if (value === this.affairPayerPreferenceEnum.CLIENT) {
        payerPreference = this.affairPayerPreferenceEnum.CLIENT.toString();
        payerName = object.finalsCustomersFullNames;
        payerAccountingCode = object.mlRef.match(/(\d+)/)[0];
        if (object.finalCustomerAddress) {
          if (object.finalCustomerStreetNumber) {
            payerAddress = object.finalCustomerStreetNumber + " " + object.finalCustomerAddress;
          } else {
            payerAddress = object.finalCustomerAddress;
          }
          payerPostalCode = object.finalCustomerPostalCode;
          payerLocality = object.finalCustomerLocality;
        } else {
          if (object.workSiteStreetNumber) {
            payerAddress = object.workSiteStreetNumber + " " + object.workSiteAddress;
          } else {
            payerAddress = object.workSiteAddress;
          }
          payerPostalCode = object.workSitePostalCode;
          payerLocality = object.workSiteLocality;
        }
      } else {
        payerPreference = this.affairPayerPreferenceEnum.OTHER.toString();
        payerName = object.name;
        payerAccountingCode = object.accountingCode;
        payerAddress = object.address;
        payerPostalCode = object.postalCode;
        payerLocality = object.locality;
      }
    }
    this.form.controls['payerPreference'].setValue(payerPreference.toString());
    this.form.controls['payerName'].setValue(payerName);
    this.form.controls['payerAccountingCode'].setValue(payerAccountingCode);
    this.form.controls['payerAddress'].setValue(payerAddress);
    this.form.controls['payerPostalCode'].setValue(payerPostalCode);
    this.form.controls['payerLocality'].setValue(payerLocality);
  }

  isValidForm() {
    return this.form.valid;
  }

}
