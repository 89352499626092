import { DataTableInstanceParameters } from './../../../../../services/src/lib/utilities/datatable.service';
import { group } from '@angular/animations';
import { BrandService } from '@eros-front/services';
import { GroupService } from '@eros-front/services';
import { Subscription } from 'rxjs';
import { DataTableColumn } from '@eros-front/services';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-customers-consult-brands-index',
  templateUrl: './customers-consult-brands-index.component.html',
  styleUrls: ['./customers-consult-brands-index.component.scss']
})
export class CustomersConsultBrandsIndexComponent implements OnInit, AfterViewInit, OnDestroy {
  public columns: DataTableColumn[] = [
    {
      attr: 'ref',
      name: 'Ref'
    },
    {
      attr: 'groupName',
      searchColumn: 'group.name',
      name: 'Groupe'
    },
    {
      attr: 'name',
      name: 'Marque'
    },
    {
      attr: 'agenciesNames',
      searchColumn: 'agencies.name',
      name: 'Agences',
    },
    {
      attr: 'rivalsNames',
      searchColumn: 'rivals.name',
      name: 'Concurrents',
    },
    {
      attr: 'managersNames',
      searchColumn: 'managers.lastname',
      name: 'Responsables',
    },
  ];
  private selectedBrands$: Subscription;
  private selectedGroups$: Subscription;
  public datatableSelector = 'customers-brands-datatable';
  private params: DataTableInstanceParameters;
  public ajaxParameters: any;


  constructor(
    private brandService: BrandService,
    private groupService: GroupService,
  ) { }

  ngOnInit() {
    this.initSubscriptions();
  }

  ngAfterViewInit() {
    this.brandService.initDataTable(this.datatableSelector, this.columns);
  }

  ngOnDestroy(): void {
  }

  initSubscriptions() {
  }

}
