import { Component, Input, OnInit, Output, ViewChild, EventEmitter, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { Intervention, SubmitButton } from '@libs/models/src';
import { InterventionService, NotifService, SubmitButtonService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-intervention-edit-deprogram-modal',
  templateUrl: './intervention-edit-deprogram-modal.component.html',
  styleUrls: ['./intervention-edit-deprogram-modal.component.scss']
})
export class InterventionEditDeprogramModalComponent implements OnInit {
  @Input() intervention: Intervention;
  @Input() shiftOrigins;
  @Input() form: FormGroup;
  public submitButton: SubmitButton;
  public faHistory = faHistory;

  constructor(
    private modal: NzModalRef,
    private interventionService: InterventionService,
    private submitButtonService: SubmitButtonService,
    private notifService: NotifService
  ) { }

  ngOnInit() {
    this.setSubmitButton();
  }


  private setSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'l\'intervention',
      text: 'Déprogrammer',
      icon: faHistory
    });
    this.interventionService.setSubmitButtonDeprogram(this.submitButton);
  }

  submitDeprogramIntervention() {
    if (this.isValidForm()) {
      this.interventionService.deprogram(this.intervention.id, this.form).subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        }
      )
    }
  }

  public isValidForm(): boolean {
    if (this.form) {
      return this.form.valid;
    }
    return false;
  }
}
