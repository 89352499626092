import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Intervention } from '@libs/models/src';

@Component({
  selector: 'app-intervention-edit-products',
  templateUrl: './intervention-edit-products.component.html',
  styleUrls: ['./intervention-edit-products.component.scss']
})
export class InterventionEditProductsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() intervention: Intervention;
  @Input() affairProducts: any[]

  constructor() { }

  ngOnInit() {
  }

}
