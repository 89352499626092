import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-timeline-horizontal',
  templateUrl: './timeline-horizontal.component.html',
  styleUrls: ['./timeline-horizontal.component.less']
})
export class TimelineHorizontalComponent implements OnInit {
  @Input() entries;

  constructor() { }

  ngOnInit() {
  }

}
