import { SubmitButton } from '@eros-front/models';
import { Injectable } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';

export interface SubmitButtonDisabledOptions {
    text?: string;
    icon?: IconDefinition;
    enabled?: boolean;
}

export interface SubmitButtonOptions {
    objectName: string;
    isAdd?: boolean;
    icon?: IconDefinition;
    text?: string;
}

@Injectable()
export class SubmitButtonService {

    constructor() { }

    setDisabled(button: SubmitButton, options?: SubmitButtonDisabledOptions): void {
        if (button) {
            let enabled = false;
            let text = 'Traitement en cours...';
            let icon = faSpinner;
            if (options) {
                if (options.enabled) {
                    enabled = options.enabled;
                }
                if (options.text) {
                    text = options.text;
                }
                if (options.icon) {
                    icon = options.icon;
                }
            }
            button.enabled = enabled;
            button.text = text;
            button.icon = icon;
        }
    }

    setEnabled(button: SubmitButton): void {
        if (button) {
            button.enabled = true;
            button.text = button.originalText;
            button.icon = button.originalIcon;
        }
    }

    getSubmitButtonInstance(options: SubmitButtonOptions): SubmitButton {
        const defaultText = options.isAdd ? 'Ajouter' : 'Modifier';
        const defaultIcon = options.isAdd ? faPlus : faSave;
        const icon = options.icon ? options.icon : defaultIcon;
        let text = options.text ? options.text : defaultText;
        text += ' ' + options.objectName;
        return {
            text: text,
            originalText: text,
            icon: icon,
            originalIcon: icon,
            enabled: true
        };
    }

}
