import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { NotifService } from './utilities/notif.service';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { FormFormatterService } from './utilities/form-formatter.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { ExpenseAccountStatus, InvoiceStatus, PhoneLine, PhoneLineUser, User } from '@eros-front/models';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { SelectService } from './utilities/select.service';
import { catchError, finalize } from 'rxjs/operators';

@Injectable()
export class PhoneLineService extends ModelWithDatatableAndCrudObservable {

    private route = '/phones-lines';
    public phonesLinesSelect$ = new BehaviorSubject<any>(undefined);
    public phonesLinesStatuses$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService,
        private selectService: SelectService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route);
    }

    public getAll(): Observable<any> {
        return super.getAll(this.route);
    }

    public store(form: any): Observable<any> {
        return super.store(this.formatForm(form), this.route);
    }

    public update(id: number, form: any): Observable<any> {
        return super.update(id, this.formatForm(form), this.route);
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route);
    }

    public getForm(phoneLine: PhoneLine): FormGroup {
        const name = phoneLine ?
            this.commonService.valueToFormString(phoneLine.name) : this.commonService.getDefaultFormNullValue();
        const billingAccount = phoneLine ?
            this.commonService.valueToFormString(phoneLine.billingAccount) : this.commonService.getDefaultFormNullValue();
        const line = phoneLine ?
            this.commonService.valueToFormString(phoneLine.line) : this.commonService.getDefaultFormNullValue();
        const eventToken = phoneLine ?
            this.commonService.valueToFormString(phoneLine.eventToken) : this.commonService.getDefaultFormNullValue();
        let users = [];
        if (phoneLine && phoneLine.users.length > 0) {
            for (let user of phoneLine.users) {
                users.push(this.getUserForm(user));
            }
        }

        return this.formBuilder.group({
            name: [name, Validators.required],
            billingAccount: [billingAccount, Validators.required],
            line: [line, Validators.required],
            eventToken: [eventToken],
            users: new FormArray(users)
        });
    }

    public getUserForm(user?: PhoneLineUser) {
        const id = user ? user.userId : '_' + Math.random().toString(36).substr(2, 9);
        const userId = user ? user.userId : null;
        const agentId = user ?
            user.agentId : this.commonService.getDefaultFormStringValue();
        return this.formBuilder.group({
            id: [id, Validators.required],
            userId: [userId, Validators.required],
            agentId: [agentId, Validators.required]
        })

    }

    public getForSelect(): void {
        this.selectService.getForSelect(this.phonesLinesSelect$, this.route);
    }

    public getPhonesLinesStatuses() {
        this.apiService.get(this.route + '/statuses')
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                })
            ).subscribe(
                (data) => {
                    this.phonesLinesStatuses$.next(data);
                }
            );
    }

    public updatePhonesLinesStatuses(userId: number, activate: boolean): Observable<any> {
        return this.apiService.put(this.route + '/update-statuses', {
            userId: userId,
            status: activate
        })
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            )
    }

    private formatForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.users.forEach(value => {
            value.userId = this.formFormatterService.formatSelectSingle(value.userId)
        });
        return values;
    }

}
