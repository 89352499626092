import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TelephonyTool } from '_tools/telephony';
import { param } from 'jquery';

@Injectable()
export class TelephonyService {

    private httpClient: HttpClient;

    constructor(
        httpBackend: HttpBackend,
    ) {
         this.httpClient = new HttpClient(httpBackend);
    }

    call(phoneNumber: string): Observable<any> {
        const phoneFormatted = TelephonyTool.formatForFccCall(phoneNumber);
        return this.httpClient.get(`https://api-mediation.flexiblecontactcenter.orange-business.com/clickToCall?phoneNumber=${phoneFormatted}`, {
            withCredentials: true
        });
    }

}
