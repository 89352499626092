import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { Invoice, SubmitButton } from '@libs/models/src';
import { FormValidatorsService, NotifService, SubmitButtonService } from '@libs/services/src';
import { InvoiceService } from '@libs/services/src/lib/invoice.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-invoice-generate-credit-modal',
  templateUrl: './invoice-generate-credit-modal.component.html',
  styleUrls: ['./invoice-generate-credit-modal.component.scss']
})
export class InvoiceGenerateCreditModalComponent implements OnInit {
  @Input() invoice: Invoice;
  public form: FormGroup;
  public submitButton: SubmitButton;
  public isTotality = true;

  constructor(
    private modal: NzModalRef,
    private invoiceService: InvoiceService,
    private submitButtonService: SubmitButtonService,
    private notifService: NotifService,
    private formValidatorsService: FormValidatorsService
  ) { }

  ngOnInit() {
    this.form = this.invoiceService.getCreateCreditForm(this.invoice);
    this.invoiceService.initCreditInvoiceDetails(this.form, this.invoice);
    this.setSubmitButton();


    this.form.get('isTotality').valueChanges.subscribe(value => {
      if (value) {
        this.form.controls['invoiceDetails'].setValidators(null);
        this.form.controls['invoiceDetails'].updateValueAndValidity();
      } else {
        this.form.controls['invoiceDetails'].setValidators(this.formValidatorsService.minSelectedCheckboxes(1));
        this.form.controls['invoiceDetails'].updateValueAndValidity();
      }
    });
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

  public submitForm(): void {
    if (this.isValidForm()) {
      this.invoiceService.storeCredit(this.form).subscribe(
        (success) => {
          this.modal.close();
          this.notifService.showSuccessNotif(success);
        }
      )
    }

  }

  private setSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'un avoir',
      text: 'Générer',
      icon: faCreditCard
    });
    this.invoiceService.setSubmitButton(this.submitButton);
  }

}
