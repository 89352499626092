// Angular Core
import { NgModule } from '@angular/core';

// Libs
import { SharedModule } from '@eros-front/shared';

// Components
import { InvoicesIndexComponent } from './invoices-index/invoices-index.component';
import { InvoicesDatatableFiltersComponent } from './invoices-datatable-filters/invoices-datatable-filters.component';
import { InvoicesTabsComponent } from './invoices-tabs/invoices-tabs.component';
import { InvoicesTemporaryIndexComponent } from './invoices-temporary-index/invoices-temporary-index.component';
import { InvoiceManualCreateComponent } from './invoice-manual-create/invoice-manual-create.component';
import { InvoiceShowComponent } from './invoice-show/invoice-show.component';
import { InvoiceShowInformationsComponent } from './invoice-show/invoice-show-informations/invoice-show-informations.component';
import { InvoiceShowProductsComponent } from './invoice-show/invoice-show-products/invoice-show-products.component';
import { InvoiceShowProductsModalComponent } from './invoice-show/invoice-show-products/invoice-show-products-modal/invoice-show-products-modal.component';
import { InvoiceThumbnailComponent } from '../../../components/src/lib/invoice-thumbnail/invoice-thumbnail.component';
import { InvoicesExportGenerateComponent } from './invoices-export-generate/invoices-export-generate.component';
import { AccountingExportsIndexComponent } from './accounting-exports-index/accounting-exports-index.component';
import { InvoiceGenerateCreditModalComponent } from './invoice-show/invoice-generate-credit-modal/invoice-generate-credit-modal.component';
import { InvoicesListComponent } from './invoices-list/invoices-list.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    InvoicesTabsComponent,
    InvoicesDatatableFiltersComponent,
    InvoicesIndexComponent,
    InvoicesTemporaryIndexComponent,
    InvoiceManualCreateComponent,
    InvoiceShowComponent,
    InvoiceShowInformationsComponent,
    InvoiceShowProductsComponent,
    InvoiceShowProductsModalComponent,
    InvoicesExportGenerateComponent,
    AccountingExportsIndexComponent,
    InvoiceGenerateCreditModalComponent,
    InvoicesListComponent,
  ]
})
export class InvoicesModule { }
