import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AffairMarketType } from '@libs/enum/src';
import { ProductService } from '@libs/services/src';
import { AffairGoalService } from '@libs/services/src/lib/affair-goal.service';
import { AffairStatusService } from '@libs/services/src/lib/affair-status.service';

@Component({
  selector: 'app-affairs-datatable-filters',
  templateUrl: './affairs-datatable-filters.component.html',
  styleUrls: ['./affairs-datatable-filters.component.scss']
})
export class AffairsDatatableFiltersComponent implements OnInit {
  @Input() marketType: number;
  @Input() mlAgencies: any[];
  @Input() agencies: any[];
  @Input() worksCompanies: any[];
  @Input() assistants: any[];
  @Input() technicians: any[];
  @Input() products: any[];
  @Input() productsStatuses: any[];
  @Input() affairsStatuses: any[];
  @Input() affairsGoals: any[];
  @Input() affairsMonitoringTypes: any[];
  @Input() performances: any[];
  @Input() form: FormGroup;
  public affairsGoalsFormatted: any[] = [];
  public affairsStatusesFormatted: any[] = [];
  public productsFormatted: any[] = [];
  public marketTypeEnum = AffairMarketType;

  constructor(
    private affairGoalService: AffairGoalService,
    private affairStatusService: AffairStatusService,
    private productService: ProductService
  ) { }

  ngOnInit() {
    if (this.affairsGoals) {
      this.affairsGoalsFormatted = this.affairGoalService.getSelectByMarketType(this.affairsGoals, this.marketType);
    }
    if (this.affairsStatuses) {
      this.affairsStatusesFormatted = this.affairStatusService.getSelectByMarketType(this.affairsStatuses, this.marketType);
    }
    if (this.products) {
      this.productsFormatted = this.productService.getSelectByMarketType(this.products, this.marketType);
    }
  }

}
