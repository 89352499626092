// Angular Core
import { NgModule } from '@angular/core';

// Libs
import { SharedModule } from '@eros-front/shared';
import { PayersModule } from '@eros-front/payers';

// Services
import {
  CustomerService,
  NotifService,
  GroupService,
  SwalService,
  BrandService,
  AgencyService,
  ContactService,
  ThingService,
  AffairService,
  ProductStatusService,
  DatatableService,
  ToggleService,
  ProfessionService,
  SubmitButtonService,
  SelectService,
  FormFormatterService,
  ShuttleSheetService,
  ShuttleSheetTemplateService
} from '@eros-front/services';
import { MessageService } from '@pages/components/message/message.service';
import { ApiService } from '@libs/api/src';

// Modules
import { MessageModule } from '@pages/components/message/message.module';

// Components
import { CustomersConsultAgenciesIndexComponent } from './customers-consult/customers-consult-agencies-index/customers-consult-agencies-index.component';
import { CustomersConsultContactsIndexComponent } from './customers-consult/customers-consult-contacts-index/customers-consult-contacts-index.component';
import { CustomersConsultGroupsIndexComponent } from './customers-consult/customers-consult-groups-index/customers-consult-groups-index.component';
import { CustomersConsultFiltersComponent } from './customers-consult/customers-consult-filters/customers-consult-filters.component';
import { CustomersConsultComponent } from './customers-consult/customers-consult.component';
import { CustomersSearchComponent } from './customers-search/customers-search.component';
import { CustomersConsultBrandsIndexComponent } from './customers-consult/customers-consult-brands-index/customers-consult-brands-index.component';
import { CustomersConsultGraphComponent } from './customers-consult/customers-consult-graph/customers-consult-graph.component';
import { CustomersConsultGraphInternalNotesModuleComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modules/customers-consult-graph-internal-notes-module/customers-consult-graph-internal-notes-module.component';
import { CustomersConsultGraphManagersModuleComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modules/customers-consult-graph-managers-module/customers-consult-graph-managers-module.component';
import { CustomersConsultGraphModalGroupComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modal-group/customers-consult-graph-modal-group.component';
import { CustomersConsultGraphModalAddContactComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modal-add-contact/customers-consult-graph-modal-add-contact.component';
import { CustomersConsultGraphModalBrandComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modal-brand/customers-consult-graph-modal-brand.component';
import { CustomersConsultGraphModalSyncIndicatorsTypesComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modal-sync-indicators-types/customers-consult-graph-modal-sync-indicators-types.component';
import { CustomersConsultGraphModalSyncManagersComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modal-sync-managers/customers-consult-graph-modal-sync-managers.component';
import { CustomersConsultGraphModalSyncPricesComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modal-sync-prices/customers-consult-graph-modal-sync-prices.component';
import { CustomersConsultGraphModalSyncRivalsComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modal-sync-rivals/customers-consult-graph-modal-sync-rivals.component';
import { CustomersConsultGraphModalSyncInternalNotesComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modal-sync-internal-notes/customers-consult-graph-modal-sync-internal-notes.component';
import { CustomersConsultGraphPricesModuleComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modules/customers-consult-graph-prices-module/customers-consult-graph-prices-module.component';
import { CustomersConsultGraphRivalsModuleComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modules/customers-consult-graph-rivals-module/customers-consult-graph-rivals-module.component';
import { CustomersConsultGraphSettingsComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-settings/customers-consult-graph-settings.component';
import { CustomersConsultGraphTypesIndicatorsModuleComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modules/customers-consult-graph-types-indicators-module/customers-consult-graph-types-indicators-module.component';
import { CustomersConsultGraphModulePriceInputComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modules/customers-consult-graph-prices-module/customers-consult-graph-prices-module/customers-consult-graph-module-price-input.component';
import { CustomersConsultGraphModalAgencyComponent } from './customers-consult/customers-consult-graph/customers-consult-graph-modal-agency/customers-consult-graph-modal-agency.component';
import { CustomersShowComponent } from './customers-show/customers-show.component';
import { CustomersShowInformationsComponent } from './customers-show/customers-show-informations/customers-show-informations.component';
import { CustomersShowPricesComponent } from './customers-show/customers-show-prices/customers-show-prices.component';
import { CustomersShowFinancialHealthComponent } from './customers-show/customers-show-financial-health/customers-show-financial-health.component';
import { CustomersShowSynchronizationComponent } from './customers-show/customers-show-synchronization/customers-show-synchronization.component';
import { CustomersShowModalSyncPricesComponent } from './customers-show/customers-show-synchronization/customers-show-modal-sync-prices/customers-show-modal-sync-prices.component';
import { CustomersShowModalSyncInternalNoteComponent } from './customers-show/customers-show-synchronization/customers-show-modal-sync-internal-note/customers-show-modal-sync-internal-note.component';
import { CustomersShowModalSyncIndicatorsTypesComponent } from './customers-show/customers-show-synchronization/customers-show-modal-sync-indicators-types/customers-show-modal-sync-indicators-types.component';
import { CustomersShowInvoicingComponent } from './customers-show/customers-show-invoicing/customers-show-invoicing.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LocalStorageService } from 'ngx-webstorage';
import { CustomersShowOfficesFilesComponent } from './customers-show/customers-show-offices-files/customers-show-offices-files.component';
import { CustomersShowOfficesFilesModalComponent } from './customers-show/customers-show-offices-files/customers-show-offices-files-modal/customers-show-offices-files-modal.component';
import { CustomersShowOfficesFilesTemplateComponent } from './customers-show/customers-show-offices-files/customers-show-offices-files-template/customers-show-offices-files-template.component';
import { CustomersShowContactsComponent } from './customers-show/customers-show-contacts/customers-show-contacts.component';
import { CustomersShowContactsModalComponent } from './customers-show/customers-show-contacts/customers-show-contacts-modal/customers-show-contacts-modal.component';
import { CustomersShowGraphComponent } from './customers-show/customers-show-graph/customers-show-graph.component';
import { CustomersCreateComponent } from './customers-create/customers-create.component';
import { CustomersCreateBrandModalComponent } from './customers-modals/customers-create-brand-modal/customers-create-brand-modal.component';
import { CustomersCreateAgencyModalComponent } from './customers-modals/customers-create-agency-modal/customers-create-agency-modal.component';
import { CustomerFormInformationsComponent } from './customer-form/customer-form-informations/customer-form-informations.component';
import { CustomersShowHeadbandComponent } from './customers-show/customers-show-headband/customers-show-headband.component';
import { CustomersShowShuttlesSheetsComponent } from './customers-show/customers-show-shuttles-sheets/customers-show-shuttles-sheets.component';
import { ShuttleSheetsModule } from '@eros-front/shuttle-sheets';
import { CustomersShowImportLogoComponent } from './customers-show/customers-show-import-logo/customers-show-import-logo.component';


@NgModule({
  imports: [
    SharedModule,
    MessageModule,
    PayersModule,
    ScrollingModule,
    ShuttleSheetsModule
  ],
  declarations: [
    CustomersSearchComponent,
    CustomersConsultComponent,
    CustomersConsultGraphComponent,
    CustomersConsultFiltersComponent,
    CustomersConsultGroupsIndexComponent,
    CustomersConsultBrandsIndexComponent,
    CustomersConsultAgenciesIndexComponent,
    CustomersConsultContactsIndexComponent,
    CustomersConsultGraphInternalNotesModuleComponent,
    CustomersConsultGraphManagersModuleComponent,
    CustomersConsultGraphModalAddContactComponent,
    CustomersConsultGraphModalGroupComponent,
    CustomersConsultGraphModalBrandComponent,
    CustomersConsultGraphModalAgencyComponent,
    CustomersConsultGraphModalSyncIndicatorsTypesComponent,
    CustomersConsultGraphModalSyncManagersComponent,
    CustomersConsultGraphModalSyncPricesComponent,
    CustomersConsultGraphModalSyncRivalsComponent,
    CustomersConsultGraphModalSyncInternalNotesComponent,
    CustomersConsultGraphPricesModuleComponent,
    CustomersConsultGraphRivalsModuleComponent,
    CustomersConsultGraphSettingsComponent,
    CustomersConsultGraphTypesIndicatorsModuleComponent,
    CustomersConsultGraphModulePriceInputComponent,
    CustomersShowComponent,
    CustomersShowInformationsComponent,
    CustomersShowPricesComponent,
    CustomersShowFinancialHealthComponent,
    CustomersShowSynchronizationComponent,
    CustomersShowModalSyncPricesComponent,
    CustomersShowModalSyncInternalNoteComponent,
    CustomersShowModalSyncIndicatorsTypesComponent,
    CustomersShowInvoicingComponent,
    CustomersShowOfficesFilesComponent,
    CustomersShowOfficesFilesModalComponent,
    CustomersShowOfficesFilesTemplateComponent,
    CustomersShowContactsComponent,
    CustomersShowContactsModalComponent,
    CustomersShowHeadbandComponent,
    CustomersShowGraphComponent,
    CustomersCreateComponent,
    CustomersCreateBrandModalComponent,
    CustomersCreateAgencyModalComponent,
    CustomerFormInformationsComponent,
    CustomersShowShuttlesSheetsComponent,
    CustomersShowImportLogoComponent
  ],
  providers: [
    SubmitButtonService,
    CustomerService,
    ApiService,
    NotifService,
    MessageService,
    GroupService,
    BrandService,
    SwalService,
    SelectService,
    FormFormatterService,
    AgencyService,
    ContactService,
    ThingService,
    AffairService,
    ProductStatusService,
    DatatableService,
    ToggleService,
    ProfessionService,
    LocalStorageService,
    ShuttleSheetService,
    ShuttleSheetTemplateService
  ]
})
export class CustomersModule { }
