import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '@libs/api/src';
import { Observable, Subscriber } from 'rxjs';

@Injectable()
export class ProgrammedStandardResponseService {

  private route = '/programmed-standards-responses';

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder
  ) {

  }

  public list(form): Observable<any> {
    return this.apiService.post(`${this.route}/list`, form.value)
  }

  public destroy(id: number): Observable<any> {
    return this.apiService.delete(`${this.route}/${id}`);
  }

  public initFiltersForm(): FormGroup {
    return this.formBuilder.group({
      triggerDateMin: [null],
      triggerDateMax: [null],
      isSend: [null],
      page: 1,
      size: 10,
    })

  }

}
