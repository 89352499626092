import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { MailService } from '@libs/services/src';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject, Subscriber, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'eros-front-mails-send-list',
  templateUrl: './mails-send-list.component.html',
  styleUrls: ['./mails-send-list.component.scss']
})
export class MailsSendListComponent implements OnInit {
  public form: FormGroup;
  public mailsData = [];
  public total = 1;
  public pageSize = 10;
  public pageIndex = 1;
  public isLoading = false;
  private isDestroyed$ = new Subject<void>();
  public inputSearchValue = '';

  constructor(
    private mailService: MailService,
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle(`Liste des mails envoyés${tabSuffix}`);
    this.form = this.mailService.initFiltersForm();
    this.initList();

    this.form.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$)
      )
      .subscribe(form => {
        this.initList();
      })
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.pageIndex = params.pageIndex;
    this.pageSize = params.pageSize;
    this.form.patchValue({
      page: this.pageIndex,
      size: this.pageSize
    })
  }

  onInputSearchChanged(newSearch: string): void {
    this.inputSearchValue = newSearch;
    this.form.patchValue({
      search: newSearch,
    })
  }

  initList(): void {
    this.isLoading = true;
    this.mailService.getForList(this.form)
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        (response) => {
          this.mailsData = response.data;
          this.total = response.meta.total;
        }
      )
  }

  onOpenLink(link: string): void {
    window.open(link);
  }

  resetForm(): void {
    const resetForm = this.mailService.initFiltersForm();
    this.form.reset(resetForm);
  }

  onRefreshClicked(): void {
    this.initList();
  }
}
