import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RegexService } from '@libs/services/src';

@Component({
  selector: 'input-time-slot',
  templateUrl: './input-time-slot.component.html',
  styleUrls: ['./input-time-slot.component.scss']
})
export class InputTimeSlotComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() inputClass: string;
  @Input() inputFormControlName: string;
  public timeSlotMask = null;
  public timeSlotPatterns = null;

  @Output() timeSlotChange = new EventEmitter<any>();

  constructor(private regexService: RegexService) { }

  ngOnInit() {
    this.timeSlotMask = this.regexService.getTimeSlotMask();
    this.timeSlotPatterns = {
      R: { pattern: new RegExp("[0-2]") },
      Z: { pattern: new RegExp("[0-9]") }
    };
  }

  onTimeSlotChange() {
    this.timeSlotChange.emit();
  }

}
