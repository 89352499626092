import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectModel } from '@libs/models/src';

@Component({
  selector: 'app-expenses-accounts-list-filters',
  templateUrl: './expenses-accounts-list-filters.component.html',
  styleUrls: ['./expenses-accounts-list-filters.component.scss']
})
export class ExpensesAccountsListFiltersComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() users: SelectModel[];

  constructor() { }

  ngOnInit() {
  }

}
