import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SwalService } from './utilities/swal.service';
import { Affair } from '@libs/models/src';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AffairStandardResponseService {

    public files$ = new BehaviorSubject<any>(undefined);
    public recipients$ = new BehaviorSubject<any>(undefined);
    public textReplaced$ = new BehaviorSubject<any>(undefined);

    constructor(
        private notifService: NotifService,
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        private swalService: SwalService
    ) {
    }

    getFiles(affairId: number, param: any) {
        this.apiService.post(`/affairs/${affairId}/standards-responses/files`, param.value)
            .subscribe(
                (object) => {
                    this.files$.next(object);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    getRecipients(affairId: number, form: any) {
        this.apiService.post(`/affairs/${affairId}/standards-responses/recipients`, form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            )
            .subscribe(
                (object) => {
                    this.recipients$.next(object);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );

    }

    replaceTextTags(affairId: number, form: any): void {
        this.apiService.post(`/affairs/${affairId}/standards-responses/replace/text-tags`, form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            )
            .subscribe(
                (object) => {
                    this.textReplaced$.next(object);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }


    initForm(affair: Affair): FormGroup {
        const lastAffairProductId = affair.affairProducts[affair.affairProducts.length - 1].id;
        return this.formBuilder.group({
            sendSettingId: [null, Validators.required],
            standardResponseId: [null, Validators.required],
            recipients: [null],
            recipientsCopy: null,
            affairProductId: [lastAffairProductId],
            affairId: [affair.id],
            subject: [null],
            content: [null],
            attachments: null,
        });
    }



}
