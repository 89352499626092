import { ApiService } from '@eros-front/api';
import { ModelWithDatatableAndModalCrud } from './classes/model-datatable-modal-crud';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { CommonService } from './utilities/common.service';
import { SubmitButtonService } from './utilities/submit-button.service';

export interface SettingsDatatableParameters {
    settingType: string;
}

@Injectable()
export class SettingService extends ModelWithDatatableAndModalCrud {

    private route = '/settings';
    public dtInstance: DataTables.Api;
    private columns: DataTableColumn[];
    public modalAddEdit: ModalDirective;
    public setting$ = new BehaviorSubject<any>(undefined);
    public settings$ = new BehaviorSubject<any>(undefined);
    public settingValue$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private commonService: CommonService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[], settingType: string): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        }, {
            settingType: settingType
        });
    }

    public get(id: number): void {
        super.get(id, this.setting$, this.route);
    }

    public getAll(): void {
        super.getAll(this.settings$, this.route);
    }

    public store(form: any): void {
        super.store(form.value, this.route);
    }

    public update(id: number, form: any): void {
        super.update(id, form.value, this.route);
    }

    public delete(id: number): void {
        super.delete(id, this.route);
    }

    public getForm(setting: any, settingTypeKey: string) {
        const key = setting ?
            this.commonService.valueToFormString(setting.key) : this.commonService.getDefaultFormStringValue();
        const description = setting ?
            this.commonService.valueToFormString(setting.description) : this.commonService.getDefaultFormStringValue();
        const fieldType = setting ?
            this.commonService.valueToFormString(setting.fieldType) : 'text';
        const entityClass = setting ?
            this.commonService.valueToFormString(setting.entityClass) : this.commonService.getDefaultFormStringValue();
        const value = setting ?
            this.commonService.valueToFormString(setting.value) : this.commonService.getDefaultFormStringValue();
        return this.formBuilder.group({
            key: [key, Validators.required],
            description: [description],
            settingType: [settingTypeKey, Validators.required],
            fieldType: [fieldType, Validators.required],
            entityClass: [entityClass],
            value: [value, Validators.required],
        });
    }

    public getValue(key) {
        return this.apiService.get('/settings/value/' + key);
    }
}
