import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customers-show-financial-health',
  templateUrl: './customers-show-financial-health.component.html',
  styleUrls: ['./customers-show-financial-health.component.scss']
})
export class CustomersShowFinancialHealthComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  formatNumberToPercent(n: number) {
    return n.toLocaleString('fr-FR', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2
    }) + '%';
  }

}
