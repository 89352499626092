import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@libs/api/src';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from './utilities/common.service';
import { DateUtilsService } from './utilities/date-utils.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { NotifService } from './utilities/notif.service';
import { startOfMonth, endOfMonth } from 'date-fns'

@Injectable()
export class StatisticService {

    private route = '/stats';
    public statsFilters$ = new BehaviorSubject<any>(undefined);

    constructor(
        private apiService: ApiService,
        private notifService: NotifService,
        private formBuilder: FormBuilder,
        private formFormattedService: FormFormatterService,
        private commonService: CommonService,
        private dateUtilsService: DateUtilsService
    ) { }

    getFilters() {
        this.apiService.get(this.route + '/filters').subscribe(
            (result) => {
                this.statsFilters$.next(result);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );

    }

    formToParams(form: FormGroup) {
        return {
            'objectsIds': form.value.isRegion ?
                this.formFormattedService.formatSelectMultiple(form.value.objectsIds) : this.formFormattedService.formatSelectMultiple(form.value.mlAgenciesIds),
            'techniciansIds': this.formFormattedService.formatSelectMultiple(form.value.techniciansIds),
            'productsIds': this.formFormattedService.formatSelectMultiple(form.value.productsIds),
            'selectedGroups': this.formFormattedService.formatSelectMultiple(form.value.selectedGroups),
            'selectedBrands': this.formFormattedService.formatSelectMultiple(form.value.selectedBrands),
            'selectedAgencies': this.formFormattedService.formatSelectMultiple(form.value.selectedAgencies),
            'dateFrom': this.dateUtilsService.getDateFromFormatted(form.value.dateRange[0], form.value.dateDisplayMode),
            'dateTo': this.dateUtilsService.getDateToFormatted(form.value.dateRange[1], form.value.dateDisplayMode),
            'isRegion': form.value.isRegion,
            'dateDisplayMode': form.value.dateDisplayMode,
            'displayMode': form.value.displayMode,
            'expressedIn': form.value.expressedIn,
            'optionMode': form.value.optionMode
        }
    }

    initForm(sessionForm?: any) {
        const techniciansIds = sessionForm != undefined && sessionForm.techniciansIds ?
            sessionForm.techniciansIds : this.commonService.getDefaultFormNullValue();
        const productsIds = sessionForm != undefined && sessionForm.productsIds ?
            sessionForm.productsIds : this.commonService.getDefaultFormNullValue();
        const objectsIds = sessionForm != undefined && sessionForm.objectsIds ?
            sessionForm.objectsIds : this.commonService.getDefaultFormNullValue();
        const mlAgenciesIds = sessionForm != undefined && sessionForm.objectsIds ?
            sessionForm.mlAgenciesIds : this.commonService.getDefaultFormNullValue();
        const selectedGroups = sessionForm != undefined && sessionForm.selectedGroups ?
            sessionForm.selectedGroups : this.commonService.getDefaultFormNullValue();
        const selectedBrands = sessionForm != undefined && sessionForm.selectedBrands ?
            sessionForm.selectedBrands : this.commonService.getDefaultFormNullValue();
        const selectedAgencies = sessionForm != undefined && sessionForm.selectedAgencies ?
            sessionForm.selectedAgencies : this.commonService.getDefaultFormNullValue();
        const dateRange = sessionForm != undefined && sessionForm.dateRange ?
            sessionForm.dateRange : [startOfMonth(new Date()), endOfMonth(new Date())];
        const dateDisplayMode = sessionForm != undefined && sessionForm.dateDisplayMode ?
            sessionForm.dateDisplayMode : 1;
        const displayMode = sessionForm != undefined && sessionForm.displayMode ?
            sessionForm.displayMode : 0;
        const optionMode = sessionForm != undefined && sessionForm.optionMode ?
            sessionForm.optionMode : 0;
        const expressedIn = sessionForm != undefined && sessionForm.expressedIn ?
            sessionForm.expressedIn : 0;
        return this.formBuilder.group({
            techniciansIds: [techniciansIds],
            productsIds: [productsIds, Validators.required],
            objectsIds: [objectsIds, Validators.required],
            mlAgenciesIds: [mlAgenciesIds],
            selectedGroups: [selectedGroups],
            selectedBrands: [selectedBrands],
            selectedAgencies: [selectedAgencies],
            dateRange: [dateRange, Validators.required],
            dateDisplayMode: [dateDisplayMode, Validators.required],
            displayMode: [displayMode, Validators.required],
            optionMode: [optionMode, Validators.required],
            isRegion: [true],
            expressedIn: [expressedIn],
        });
    }
}
