import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faEdit, faPlus, faProcedures } from '@fortawesome/free-solid-svg-icons';
import { Contact, Expense, ExpenseAmount, ExpenseParcipant, ExpenseType, ExpenseWording, SelectModel, SubmitButton, User } from '@libs/models/src';
import { FileService, FileUtilsService, NotifService, SubmitButtonService } from '@libs/services/src';
import { ExpenseService } from '@libs/services/src/lib/expense.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

type ParticipantType = 'NONE' | 'CONTACT' | 'PROSPECT';

@Component({
  selector: 'app-expense-add-edit-modal',
  templateUrl: './expense-add-edit-modal.component.html',
  styleUrls: ['./expense-add-edit-modal.component.scss']
})
export class ExpenseAddEditModalComponent implements OnInit {
  @Input() expenseAccountId: number;
  @Input() expense: Expense;
  @Input() users: SelectModel[];
  @Input() expensesTypes: ExpenseType[];
  @Input() expensesWording: any;
  @Input() contacts: SelectModel[] = [];
  public form: FormGroup;
  public initialExpensesTypes: ExpenseType[] = [];
  public submitButton: SubmitButton;
  public initialUsers: SelectModel[] = [];
  public initialContacts: SelectModel[] = [];
  public isDestroyed$ = new Subject<void>();
  public selectedParticipant: ParticipantType = 'NONE';

  public readonly maxParticipant: number = 10;
  public readonly maxAmount: number = 5;
  public totalAmount: number = 0;
  public fileToUpload: File | null = null;
  public participantsTypes = [
    {
      value: "NONE",
      label: "Aucun"
    },
    {
      value: "CONTACT",
      label: "Collègue et/ou client"
    },
    {
      value: "PROSPECT",
      label: "Propect et/ou autre"
    }
  ]

  constructor(
    private modal: NzModalRef,
    private expenseService: ExpenseService,
    private formBuilder: FormBuilder,
    private submitButtonService: SubmitButtonService,
    private notifService: NotifService,
    private fileService: FileService
  ) { }

  ngOnInit() {
    this.initForm();
    this.determinateSubmitButton();
    this.setValueChangesSubscriptions();
    this.initialExpensesTypes = this.expensesTypes;
    if (this.expense != null) {
      this.onTotalChanged();
      if (this.expense.expenseWording) {
        this.setExpensesTypes(this.expense.expenseWording.id);
      }
    }
  }

  getFormControls(field: string): any[] {
    return (this.form.get(field) as FormArray).controls;
  }

  onAddAmountClicked(): void {
    const formArray = (this.form.get('expensesAmounts') as FormArray);
    if (formArray.length < this.maxAmount) {
      formArray.push(this.getExpensesAmountsForm(null));
    }
  }


  private initForm(): void {
    let expenseWordingId = null;
    if (this.expense) {
      if (this.expense.expenseWording) {
        expenseWordingId = this.expense.expenseWording.id;
      } else if (this.expense.otherWording) {
        expenseWordingId = -1;
      }
    }
    const contacts = this.expense ?
      this.contacts.filter(x => this.expense.contacts.map(x => x.id).includes(x.value)) : []
    const users = this.expense ?
      this.users.filter(x => this.expense.users.map(x => x.id).includes(x.value)) : []
    this.form = this.formBuilder.group({
      expenseAccountId: [this.expenseAccountId, Validators.required],
      date: [this.expense?.date || null, Validators.required],
      expenseWordingId: [expenseWordingId || null, Validators.required],
      otherWording: [this.expense?.otherWording],
      participantType: [this.setSelectedParticipant(), Validators.required],
      users: [users],
      userSearch: '',
      contacts: [contacts],
      contactSearch: '',
      expensesParticipants: new FormArray(this.getExpensesParticipants()),
      expensesAmounts: new FormArray(this.getExpensesAmounts()),
    });
    this.setValueChangesSubscriptions();
  }


  private setValueChangesSubscriptions(): void {
    this.form.get('expenseWordingId')?.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((id) => {
        this.setExpensesTypes(Number(id));
      });
    this.form.get('participantType')?.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((type: ParticipantType) => {
        this.selectedParticipant = type;
        this.clearParticipantSelection(type);
      });
  }

  private setExpensesTypes(expWordingId: number): void {
    const expWording = this.expensesWording.find((e: ExpenseWording) => e.id === expWordingId);
    if (expWording != null && expWording.id != -1) {
      this.expensesTypes = expWording.expensesTypes;
    } else {
      this.expensesTypes = this.initialExpensesTypes;
    }
  }

  private getExpensesAmounts(): any[] {
    const expensesAmounts: any[] = [];
    if (this.expense) {
      this.expense.expensesAmounts.forEach((expAm: ExpenseAmount) => {
        expensesAmounts.push(this.getExpensesAmountsForm(expAm));
      });
    } else {
      expensesAmounts.push(this.getExpensesAmountsForm(null));
    }
    return expensesAmounts;
  }

  private getExpensesAmountsForm(expAm: ExpenseAmount | null): FormGroup {
    return this.formBuilder.group({
      expenseTypeId: [expAm?.expenseType.id || null, Validators.required],
      amountVatFive: expAm?.amountVatFive || 0,
      amountVatTen: expAm?.amountVatTen || 0,
      amountVatTwenty: expAm?.amountVatTwenty || 0,
      amountTotalAllTaxes: [expAm?.amountTotalAllTaxes || 0, Validators.required],
    });
  }

  private getExpensesParticipants(): any[] {
    const expensesParticipants: any[] = [];
    if (this.expense) {
      this.expense.expensesParticipants.forEach((expPart: ExpenseParcipant) => {
        expensesParticipants.push(this.getExpensesParticipantsForm(expPart));
      });
    }
    return expensesParticipants;
  }

  private setSelectedParticipant(): ParticipantType {
    if (this.expense?.users.length > 0 && this.expense?.contacts.length > 0) {
      this.selectedParticipant = 'CONTACT';
      return 'CONTACT';
    }
    if (this.expense?.expensesParticipants.length > 0) {
      this.selectedParticipant = 'PROSPECT';
      return 'PROSPECT';
    }
    this.selectedParticipant = 'NONE';
    return 'NONE';
  }

  private clearParticipantSelection(type: ParticipantType): void {
    this.form.patchValue({
      users: [],
      userSearch: '',
      contacts: [],
      contactSearch: '',
    });
    const formArray = (this.form.get('expensesParticipants') as FormArray);
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
    if (type === 'PROSPECT') {
      this.onAddParticipantClicked();
    }
  }


  onTotalChanged(): void {
    const formatted = this.form.value as Expense;
    this.totalAmount = 0;
    formatted.expensesAmounts.forEach((e: ExpenseAmount) => {
      this.totalAmount += e.amountTotalAllTaxes;
    });
  }

  onAddParticipantClicked(): void {
    const formArray = (this.form.get('expensesParticipants') as FormArray);
    if (formArray.length < this.maxParticipant) {
      formArray.push(this.getExpensesParticipantsForm(null));
    }
  }

  isValidForm(): boolean {
    return this.form.valid
  }


  onShowFile() {
    this.fileService.getBlob(this.expense.path).subscribe(
      (file) => {
        const blob = new Blob([file], { type: 'image/png' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      }
    );
  }

  getExpenseWordingIdValue(): number {
    return this.form.get('expenseWordingId')?.value;
  }

  submitForm() {
    if (this.isValidForm()) {
      let observable: Observable<any>;
      if (this.expense) {
        observable = this.expenseService.update(this.expense.id, this.form);
      } else {
        observable = this.expenseService.store(this.form);
      }
      observable
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe({
          next: (returnedExpense: any) => {
            if (this.fileToUpload) {
              this.expenseService.import(returnedExpense.data.id, this.fileToUpload)
                .pipe(takeUntil(this.isDestroyed$))
                .subscribe({
                  next: () => {
                    this.notifService.showSuccessNotif(returnedExpense)
                    this.modal.close();
                  },
                  error: (error) => {
                    this.notifService.showErrorNotif(error)
                    this.modal.close();
                  },
                });
            } else {
              this.notifService.showSuccessNotif(returnedExpense)
              this.modal.close();
            }
          },
          error: (error) => {
            this.notifService.showErrorNotif(error)
            this.modal.close();
          },
        });

    }
  }

  onFileSelected(evt: Event): void {
    const targ = (evt.target as HTMLInputElement);
    if (targ != null) {
      this.fileToUpload = targ.files[0];
    }
  }

  onRemoveClicked(field: string, i: number): void {
    (this.form.get(field) as FormArray).removeAt(i);
    this.onTotalChanged();
  }

  private getExpensesParticipantsForm(expPart: ExpenseParcipant | null): FormGroup {
    return this.formBuilder.group({
      fullName: [expPart?.fullName || '', Validators.required],
      society: expPart?.society || '',
    });
  }


  private determinateSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'la dépense',
      text: this.expense ? 'Modifier' : 'Ajouter',
      icon: this.expense ? faEdit : faPlus
    });
    this.expenseService.setSubmitButton(this.submitButton);
  }

}
