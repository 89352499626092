import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ShiftOrigin, SubmitButton } from '@libs/models/src';
import { NotifService } from '@libs/services/src';
import { ShiftOriginService } from '@libs/services/src/lib/shift-origin.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-shift-origins-modal',
  templateUrl: './shift-origins-modal.component.html',
  styleUrls: ['./shift-origins-modal.component.scss']
})
export class ShiftOriginsModalComponent implements OnInit {
  @Input() shiftOrigin: ShiftOrigin;
  @Input() form: FormGroup;
  public submitButton: SubmitButton;
  public colorValue: string;

  constructor(
    private modal: NzModalRef,
    private shiftOriginService: ShiftOriginService,
    private notifService: NotifService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.shiftOriginService.setSubmitButton(this.submitButton);
    if (this.form) {
      this.colorValue = this.form.get('color').value
    }
  }


  submitForm() {
    if (this.isValidForm()) {
      if (this.shiftOrigin) {
        this.shiftOriginService.update(this.shiftOrigin.id, this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      } else {
        this.shiftOriginService.store(this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.shiftOriginService.determinateSubmitButton(this.shiftOrigin, 'l\'origine de décalage');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
