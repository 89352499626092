import { TokenInterceptor } from './token-interceptor.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { API_URL } from './api.shared';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ]
})
export class ApiModule {
  static forRoot(host: string) {
    return {
      ngModule: ApiModule,
      providers: [{
        provide: API_URL,
        useValue: host
      }]
    }
  }
}
