import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Affair, Part, SubmitButton } from '@libs/models/src';
import { BuildingPartService, NotifService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-affair-show-buildings-modal-part',
  templateUrl: './affair-show-buildings-modal-part.component.html',
  styleUrls: ['./affair-show-buildings-modal-part.component.scss']
})
export class AffairShowBuildingsModalPartComponent implements OnInit {
  @Input() affair: Affair;
  @Input() part: Part;
  @Input() form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private partService: BuildingPartService,
    private notifService: NotifService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.part) {
        this.partService.update(this.part.id, this.form).subscribe(
          (success) => {
            this.notifService.showSuccessNotif(success);
            this.modal.close();
          }
        )
      } else {
        this.partService.store(this.form).subscribe(
          (success) => {
            this.notifService.showSuccessNotif(success);
            this.modal.close();
          }
        )
      }
    }
  }

  isValidForm() {
    return this.form.valid;
  }

  private determinateSubmitButton() {
    this.submitButton = this.partService.determinateSubmitButton(this.part, 'la tranche');
    this.partService.setSubmitButton(this.submitButton);
  }

}
