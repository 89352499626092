import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AffairService } from '@libs/services/src';
import { SearchService } from '@libs/services/src/lib/search.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscriber, Subscription, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-incoming-call-sheet',
  templateUrl: './incoming-call-sheet.component.html',
  styleUrls: ['./incoming-call-sheet.component.scss']
})
export class IncomingCallSheetComponent implements OnInit, OnDestroy {
  private route$: Subscription;
  public phoneNumber: string;
  public contact: any;
  public isFound = null;
 
  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
    private titleService: Title,
    private spinnerService: NgxSpinnerService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.titleService.setTitle(`Fiche contact${tabSuffix}`)
    this.route$ = this.route.queryParams
      .subscribe(params => {
        this.phoneNumber = params.phoneNumber;
        if (this.phoneNumber) {
          this.spinnerService.show('load-sheet');
          this.searchService.searchByPhone(this.phoneNumber)
           .pipe(
            finalize(() => {
               this.spinnerService.hide('load-sheet');
            }),
            catchError(error => {
              this.isFound = false;
              return throwError(error);
            }),
          )
          .subscribe(
            (response) => {
              this.isFound = true;
              this.contact = response;
            },
          )
        }
      }
    );
  }

  ngOnDestroy(): void {
      this.route$?.unsubscribe();
  }

  onRedirectAffairListClicked(): void {
     this.router.navigateByUrl(`affairs/index`);
  }

  onRedirectAffairCreateClicked(): void {
    this.router.navigateByUrl(`affairs/create`);
  }

}
