import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-customers-consult-graph-module-price-input',
  templateUrl: './customers-consult-graph-module-price-input.component.html',
  styleUrls: ['./customers-consult-graph-module-price-input.component.scss']
})
export class CustomersConsultGraphModulePriceInputComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() priceParent: number;
  @Input() priceChild: number;
  @Input() marketTypeIndex: number;
  @Input() productId: number;
  @Input() productIndex: number;
  public priceCurrent: number;
  public inputColor: string;
  public buttonBackgroundColor: string;
  private isEnabled = true;
  
  constructor() { }

  ngOnInit() {
    this.priceCurrent = this.priceChild;
  }

  isPriceOfParentDifferent() {
    return this.priceParent != this.priceChild;
  }

  onApplyPriceOfParentClicked() {
    this.setPriceOfParent();
    this.setInputColor(this.isEnabled);
    this.setButtonBackgroundColor();
    this.isEnabled = !this.isEnabled;
  }

  onPreviewPriceOfParentMouseOver() {
    if (this.isEnabled) {
      this.setInputColor(true);
      this.setPriceOfParent();
    }
  }

  onPreviewPriceOfParentMouseLeave() {
    if (this.isEnabled) {
      this.setInputColor(false);
      this.setPriceOfChild();
    }
  }

  private setPriceOfParent(): void {
    if (this.isPriceOfParentDifferent()) {
      const products = this.form.get('products') as FormArray;
      const product = products.controls[this.marketTypeIndex] as FormArray;
      product.controls[this.productIndex].setValue({
        ['product' + this.productId]: this.priceParent
      });
      this.priceCurrent = this.priceParent;
    }
  }

  private setPriceOfChild(): void {
    if (this.isPriceOfParentDifferent()) {
      const products = this.form.get('products') as FormArray;
      const product = products.controls[this.marketTypeIndex] as FormArray;
      product.controls[this.productIndex].setValue({
        ['product' + this.productId]: this.priceChild
      });
      this.priceCurrent = this.priceChild;
    }
  }

  private setInputColor(condition: boolean) {
    this.inputColor = condition ? 'red' : 'black';
  }

  private setButtonBackgroundColor() {
    this.buttonBackgroundColor = this.isEnabled ? 'darkgray' : 'transparent';
  }

}
