import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppWithoutSidebarComponent } from './app-without-sidebar/app-without-sidebar.component';
import { RootLoginLayoutComponent } from './root-login-layout/root-login-layout.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [AppWithoutSidebarComponent, RootLoginLayoutComponent],
  exports: [AppWithoutSidebarComponent, RootLoginLayoutComponent]
})

export class LayoutsModule { }
