import Swal from 'sweetalert2';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AgencyService, MarketTypeService, SwalService } from '@libs/services/src';
import { AffairMyLinkService } from '@libs/services/src/lib/affair-mylink.service';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { Subscription } from 'rxjs';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-affairs-mylink-index',
  templateUrl: './affairs-mylink-index.component.html',
  styleUrls: ['./affairs-mylink-index.component.scss']
})
export class AffairsMylinkIndexComponent implements OnInit, AfterViewInit, OnDestroy {

  public columns: DataTableColumn[] = [
    {
      attr: 'customer',
      name: 'Client',
      searchable: false,
      orderable: false
    },
    {
      attr: 'mlRef',
      name: 'Référence',
      individualFilterInput: true,
    },
    {
      attr: 'finalCustomers',
      name: 'Nom(s) et prénom(s)'
    },
    {
      attr: 'mlStatuses',
      name: 'Sociétés MyLink',
      orderable: false,
      searchable: false,
      width: '15%'
    },
    {
      attr: 'marketsTypes',
      name: 'Marché(s)',
      orderable: false,
      searchable: false
    },
    {
      attr: 'workSiteAddress',
      name: 'Adresse',
      individualFilterInput: true,
    },
    {
      attr: 'workSitePostalCode',
      name: 'CP',
      individualFilterInput: true,
    },
    {
      attr: 'workSiteLocality',
      name: 'Ville',
      individualFilterInput: true,
    },
    {
      attr: 'createdAtFormatted',
      name: 'Date création',
      orderable: false,
      searchable: false
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];
  public agencies = [];
  private agencies$: Subscription;
  public marketsTypes = [];
  private marketsTypes$: Subscription;
  public datatableSelector = 'affairs-mylink-datatable';
  private readonly storageKey = 'affairs-mylink-filters-form';
  public form: FormGroup;

  constructor(
    private titleService: Title,
    private affairService: AffairMyLinkService,
    private agencyService: AgencyService,
    private marketTypeService: MarketTypeService,
    private storageService: FormLocalStorageService,
    private swalService: SwalService
  ) {
    this.titleService.setTitle(`Liste des affaires groupe${tabSuffix}`);
    this.initSubscriptions();
  }

  ngOnInit() {
    const sessionFormValue = this.storageService.retrieve(this.storageKey);
    this.form = this.affairService.getFiltersForm(sessionFormValue);
    this.form.valueChanges.subscribe(() => {
      this.storageService.store(this.storageKey, this.form)
      this.affairService.redrawDataTable(this.form);
    });
    this.agencyService.getForSelect();
    this.marketTypeService.getForSelect();
  }

  ngAfterViewInit() {
    this.affairService.initDataTable(this.datatableSelector, this.columns);
    this.triggerDataTableEvents();
  }

  ngOnDestroy() {
    this.agencies$.unsubscribe();
    this.marketsTypes$.unsubscribe();
  }

  public resetForm() {
    const resetForm = this.affairService.getFiltersForm();
    this.form.reset(resetForm.value);

  }

  private initSubscriptions() {
    this.agencies$ = this.agencyService.agenciesSelect$.subscribe(
      (agencies) => {
        if (agencies) {
          this.agencies = agencies;
        }
      }
    );
    this.marketsTypes$ = this.marketTypeService.selectMarketType$.subscribe(
      (marketsTypes) => {
        if (marketsTypes) {
          this.marketsTypes = marketsTypes;
        }
      }
    );
  }

  private triggerDataTableEvents() {
    const that = this;
    $(`#${this.datatableSelector}`).on('click', '.btn-import', function () {
      const mlRef = $(this).data('ref');
      that.affairService.import(mlRef);
    });
    $(`#${this.datatableSelector}`).on('click', '.btn-edit', function () {
      const id = $(this).data('id');
      that.affairService.edit(id);
    });
    $(`#${this.datatableSelector}`).on('click', '.btn-archive', function () {
      const swalOptions = that.swalService.getSwalDeleteOptions({
        text: 'L\'affaire sera archivée et ne sera plus visible par les autres sociétés du groupe.'
      });
      Swal.fire(swalOptions).then((result) => {
        if (result.value) {
          const mlRef = $(this).data('ref');
          that.affairService.archive(mlRef, that.form);
        }
      });
    });
  }

}
