import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AffairMonitoring, AffairMonitoringType, SubmitButton } from '@libs/models/src';
import { AffairMonitoringService, InterventionService } from '@libs/services/src';

@Component({
  selector: 'app-affair-show-monitoring-form',
  templateUrl: './affair-show-monitoring-form.component.html',
  styleUrls: ['./affair-show-monitoring-form.component.scss'],
})
export class AffairShowMonitoringFormComponent implements OnInit {
  @Input() affairMonitoring: AffairMonitoring;
  @Input() form: FormGroup;
  @Input() affairsMonitoringTypes: any;
  @Output() closeForm = new EventEmitter<any>();
  public submitButton: SubmitButton;
  public markAsClosed = false;
  public isDeletedClicked = false;

  constructor(
    private affairMonitoringService: AffairMonitoringService,
    private interventionService: InterventionService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.affairMonitoringService.setSubmitButton(this.submitButton);
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.affairMonitoring) {
        this.affairMonitoringService.update(this.affairMonitoring.id, this.form).subscribe(() => {
          this.closeForm.emit();
          this.interventionService.refreshIntervention();
        });
      } else {
        this.affairMonitoringService.store(this.form).subscribe(() => {
          this.closeForm.emit();
          this.interventionService.refreshIntervention();
        });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.affairMonitoringService.determinateSubmitButton(this.affairMonitoring, 'le suivi d\'affaire');
  }

  public markAsClosedClicked() {
    if (this.affairMonitoring) {
      this.markAsClosed = true;
      this.affairMonitoringService.markAsClosed(this.affairMonitoring.id).subscribe(() => {
        this.closeForm.emit();
        this.interventionService.refreshIntervention();
        this.markAsClosed = false;
      })
    }
  }

  public onDeleteClicked() {
    if (this.affairMonitoring) {
      this.isDeletedClicked = true;
      this.affairMonitoringService.delete(this.affairMonitoring.id).subscribe(() => {
        this.closeForm.emit();
        this.interventionService.refreshIntervention();
        this.isDeletedClicked = false;
      })
    }
  }

  public cancel(e) {
    this.closeForm.emit();
    e.preventDefault();
  }

  onAffairMonitoringTypeChange(affairMonitoringType: AffairMonitoringType) {
    if (affairMonitoringType) {
      this.form.patchValue({ 'text': affairMonitoringType.defaultText });
    }
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
