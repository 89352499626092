import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-affair-form-fields',
  templateUrl: './affair-form-fields.component.html',
  styleUrls: ['./affair-form-fields.component.scss']
})
export class AffairFormFieldsComponent implements OnInit {
  @Input() form: FormGroup;

  constructor() { }

  ngOnInit() {
  }

  getFormControls(form: FormGroup, controlName: string) {
    return (form.get(controlName) as FormArray).controls;
  }


}
