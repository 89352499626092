import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Product, Quotation, QuotationDetail, SubmitButton } from '@libs/models/src';
import { CommonService, NotifService, SwalService } from '@libs/services/src';
import { InvoiceDetailService } from '@libs/services/src/lib/invoice-detail.service';
import { QuotationDetailService } from '@libs/services/src/lib/quotation-detail.service';
import { QuotationService } from '@libs/services/src/lib/quotation.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-quotation-show-products-modal',
  templateUrl: './quotation-show-products-modal.component.html',
  styleUrls: ['./quotation-show-products-modal.component.scss']
})
export class QuotationShowProductsModalComponent implements OnInit {
  @Input() quotation: Quotation;
  @Input() detail: QuotationDetail;
  @Input() products: any[];
  @Input() vatRates: any[];
  public form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private quotationDetailService: QuotationDetailService,
    private commonService: CommonService,
    private notifService: NotifService,
    private quotationService: QuotationService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.quotationDetailService.setSubmitButton(this.submitButton);
    this.form = this.quotationDetailService.getForm(this.quotation.id, this.detail);
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.detail) {
        this.quotationDetailService.update(this.detail.id, this.form).subscribe(
          (success) => {
            this.notifService.showSuccessNotif(success);
            this.modal.close();
          }
        );
      } else {
        this.quotationDetailService.store(this.form).subscribe(
          (success) => {
            this.notifService.showSuccessNotif(success);
            this.modal.close();
          }
        );
      }
    }
  }

  isValidForm() {
    return this.form.valid;
  }

  getTotalWithoutTaxes() {
    let total = 0;
    if (this.form) {
      const unitPrice = this.form.controls['unitPrice'];
      const quantity = this.form.controls['quantity'];
      if (unitPrice && !isNaN(unitPrice.value) && quantity && !isNaN(quantity.value)) {
        total = unitPrice.value * quantity.value;
      }
    }
    return this.commonService.formatNumberToLocale(total);
  }

  onSelectProductChange(product: Product) {
    if (product) {
      this.form.patchValue({
        ref: product.name,
        accountingCode: product.accountingCode,
        designation: product.designation,
        vatRate: product.vatRate
      });
    }
  }


  private initValues() {
    this.detail = null;
    this.form = null;
  }

  private determinateSubmitButton() {
    this.submitButton = this.quotationDetailService.determinateSubmitButton(this.detail, 'le produit');
  }

}
