import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileService, ImageService } from '@libs/services/src';

@Component({
  selector: 'image-thumbnail',
  templateUrl: './image-thumbnail.component.html',
  styleUrls: ['./image-thumbnail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageThumbnailComponent implements OnInit {
  @Input() path;
  @Input() width = 240;
  imageToShow: any;
  isImageLoading = true;

  constructor(
    private fileService: FileService,
    private imageService: ImageService,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.imageService.getImage(this.path).subscribe(
      (thumbnail: Blob) => {
        this.createImageFromBlob(thumbnail);
        this.isImageLoading = false;
      },
      error => {
        this.isImageLoading = false;
      })
  }

  createImageFromBlob(image: Blob) {
    let that = this;
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = function () {
      that.imageToShow = that._sanitizer.bypassSecurityTrustUrl(reader.result.toString());
    }
  }
}
