import { Router } from '@angular/router';
import { SwalService, RoleService, DataTableColumn } from '@eros-front/services';
import Swal from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'eros-front-roles-index',
  templateUrl: './roles-index.component.html',
  styleUrls: ['./roles-index.component.scss']
})
export class RolesIndexComponent implements OnInit {
  public datatableSelector = 'admin-roles-datatable';
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'displayName',
      name: 'Nom usuel'
    },
    {
      attr: 'description',
      name: 'Description'
    },
    {
      attr: 'permissions',
      searchColumn: 'permissions.name',
      name: 'Permissions'
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];


  constructor(
    private titleService: Title,
    private roleService: RoleService,
    private swalService: SwalService,
    private routerService: Router
  ) {
    this.titleService.setTitle(`Liste des rôles${tabSuffix}`);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.roleService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  
  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function() {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.routerService.navigateByUrl(`/admin/${id}/role`);
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function() {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le rôle sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.roleService.delete(id);
          }
        });
      }
    });
  }

  createRole() {
    this.routerService.navigateByUrl('/admin/role/create');
    
  }


}
