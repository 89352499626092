import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy, HostBinding, ElementRef, Input, HostListener, ViewChild } from '@angular/core';
import { fadeAnimation } from '@pages/animations/fade-animations';
import { pagesToggleService } from '@pages/services/toggler.service';
import { Observable, Observer, of, Subscription } from 'rxjs';
import { noop } from 'jquery';
import { Router } from '@angular/router';
import { CustomLocalStorageService } from '@libs/services/src/lib/utilities/custom-local-storage.service';
import { SearchService } from '@libs/services/src/lib/search.service';
import { AffairService } from '@libs/services/src';

@Component({
  selector: 'global-search-overlay',
  templateUrl: './global-search-overlay.component.html',
  animations: [fadeAnimation],
  styleUrls: ['./global-search-overlay.component.scss']
})
export class GlobalSearchOverlayComponent implements OnDestroy {
  @ViewChild('searchField') searchField: ElementRef;
  toggleSubscription: Subscription;
  _isEnabled: boolean = false;
  isVisible: boolean = false;
  value: string = '';
  modal: any;
  search: string;
  errorMessage: string;
  search$: Subscription;
  items: any;

  constructor(
    private toggler: pagesToggleService,
    private router: Router,
    private searchService: SearchService,
    private affairService: AffairService
  ) {
    this.initSubscriptions();

  }
  ngOnDestroy() {
    this.toggleSubscription.unsubscribe();
  }

  @Input() set isEnabled(isEnabled: boolean) {
    this.isEnabled = isEnabled;
  }
  get isEnabled() {
    return this._isEnabled;
  }


  close($event?) {
    if ($event) {
      $event.preventDefault();
    }
    this.isVisible = false;
    this.value = '';
  }

  open() {
    this.isVisible = true;
    this.value = '';
    setTimeout(() => {
      this.searchField.nativeElement.focus();
    }, 200);
  }

  searchKeyPress(event) {
    if (this.isVisible && event.keyCode === 27) {
      this.isVisible = false;
    }
    this.searchService.search(this.value);
  }

  private initSubscriptions() {
    this.toggleSubscription = this.toggler.searchToggle.subscribe(toggleValue => {
      this.open();
    });
    this.search$ = this.searchService.search$.subscribe(data => {
      if (data) {
        this.items = data;
      }
    });

  }

  onSelectRoute(item: any) {
    let url = '';
    if (item.type == 'affairs') {
      url = `affairs/${item.searchable.id}/show`;
    }
    if (item.type == 'agencies') {
      url = `customers/${item.searchable.ref}/show`;
    }
    this.close();
    this.router.navigateByUrl(url);
  }
}
