import { Component, Input, OnInit } from '@angular/core';
import { SendSettingType, SubmitButton } from '@libs/models/src';
import { SendSettingTypeService } from '@libs/services/src/lib/send-setting-type.service';
import { InnerPopupComponent } from 'ng-zorro-antd/date-picker/inner-popup.component';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-send-settings-types-modal',
  templateUrl: './send-settings-types-modal.component.html',
  styleUrls: ['./send-settings-types-modal.component.scss']
})
export class SendSettingsTypesModalComponent implements OnInit {
  @Input() form;
  @Input() sendSettingType: SendSettingType;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private sendSettingTypeService: SendSettingTypeService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.sendSettingTypeService.setSubmitButton(this.submitButton);
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.sendSettingType) {
        this.sendSettingTypeService.update(this.sendSettingType.id, this.form).subscribe(() => {
          this.modal.close();
        });
      } else {
        this.sendSettingTypeService.store(this.form).subscribe(() => {
          this.modal.close();
        });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.sendSettingTypeService.determinateSubmitButton(this.sendSettingType, 'le type de paramètre d\'envoi');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
