import { Injectable } from '@angular/core';
import { ApiService } from '@libs/api/src';
import { SubmitButton } from '@libs/models/src';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';

export interface ShuttleSheetTemplateBuilding {
    key: string;
    templates: string[];
}

export interface ShuttleSheetTemplateEquipment {
    key: string;
    templates: string[];
    options: any[];
}

@Injectable()
export class ShuttleSheetTemplateService {

    private route = '/shuttles-sheets-configurations';
    private templateSubmitButton: SubmitButton;
    private modalTemplate: ModalDirective;
    public model$ = new BehaviorSubject<any>(undefined);

    constructor(
        private apiService: ApiService,
        private notifService: NotifService,
        private swalService: SwalService,
        private submitButtonService: SubmitButtonService
    ) { }

    getModel() {
        this.apiService.get(`${this.route}/template`)
            .subscribe(
                (result) => {
                    this.model$.next(result);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    updateModel(model: any) {
        this.submitButtonService.setDisabled(this.templateSubmitButton);
        this.apiService.put(`${this.route}/template`, {
            properties: JSON.stringify(model)
        })
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.templateSubmitButton);
                })
            )
            .subscribe(
                (success) => {
                    this.hideModalTemplate();
                    this.getModel();
                    this.swalService.showSwalSuccess(success);
                }
            );
    }

    setModalTemplate(modal: ModalDirective): void {
        this.modalTemplate = modal;
    }

    showModalTemplate(): void {
        this.modalTemplate.show();
    }

    hideModalTemplate(): void {
        this.modalTemplate.hide();
    }

    setTemplateSubmitButton(button: SubmitButton): void {
        this.templateSubmitButton = button;
    }
}
