import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Building, Part } from '@libs/models/src';

@Component({
  selector: 'app-affair-show-buildings-part-template',
  templateUrl: './affair-show-buildings-part-template.component.html',
  styleUrls: ['./affair-show-buildings-part-template.component.scss']
})
export class AffairShowBuildingsPartTemplateComponent implements OnInit {

  @Input() part: Part;
  @Input() currentPartIdHover: number;
  @Input() currentBuildingIdHover: number;
  @Output() editPartClicked = new EventEmitter();
  @Output() deletePartClicked = new EventEmitter();
  @Output() addBuildingClicked = new EventEmitter();
  @Output() editBuildingClicked = new EventEmitter();
  @Output() associateBuildingClicked = new EventEmitter();
  @Output() deleteBuildingClicked = new EventEmitter();
  @Output() mouseOverPart = new EventEmitter();
  @Output() mouseOverBuilding = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onMouseOverPart(id: number) {
    this.mouseOverPart.emit(id);
  }

  onMouseOverBuilding(id: number) {
    this.mouseOverBuilding.emit(id);
  }

  onEditPartClicked(part: Part) {
    this.editPartClicked.emit(part);
  }

  onDeletePartClicked(part: Part) {
    this.deletePartClicked.emit(part);
  }

  onAddBuildingClicked() {
    this.addBuildingClicked.emit();
  }

  onEditBuildingClicked(building: Building) {
    this.editBuildingClicked.emit(building);
  }

  onAssociateBuildingClicked(building: Building) {
    this.associateBuildingClicked.emit(building);
  }

  onDeleteBuildingClicked(building: Building) {
    this.deleteBuildingClicked.emit(building);
  }

}
