import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-customers-consult-graph-prices-module',
  templateUrl: './customers-consult-graph-prices-module.component.html',
  styleUrls: ['./customers-consult-graph-prices-module.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomersConsultGraphPricesModuleComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() model: any;
  @Input() marketsTypes: any[];
  @Input() products: any[];
  @Input() displayedProducts: any[];
  @Input() divHeight = '60vh';
  @Output() priceMarketTypeTextChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  onTabPriceMarketTypeSelect(event) {
    if (event.index <= this.marketsTypes.length) {
      this.priceMarketTypeTextChange.emit(this.marketsTypes[event.index].label);
    }
  }

  onSelectedFilteredProductChange(item) {
    this.displayedProducts.every((p) => {
      p.visible = !(item != null);
      return p;
    });
    if (item != null) {
      this.displayedProducts.find(p => p.id === item.id).visible = true;
    }
  }

  getPriceOfParent(marketTypeId: number, productIndex: number): number {
    console.log(this.model);
    // It's a group
    if (this.model
        && this.model.hasOwnProperty('group')
        && this.model.group.hasOwnProperty('products')
        && this.model.group.products[marketTypeId]
        && this.model.group.products[marketTypeId][productIndex]) {
          return this.model.group.products[marketTypeId][productIndex].pivot.price;
    }
    // It's a brand
    if (this.model
      && this.model.hasOwnProperty('brand')
      && this.model.brand.hasOwnProperty('products')
      && this.model.brand.products[marketTypeId]
      && this.model.brand.products[marketTypeId][productIndex]) {
        return this.model.brand.products[marketTypeId][productIndex].pivot.price;
    }
    return 0;
  }

  getPriceOfChild(marketTypeId: number, productIndex: number): number {
    if (this.model
        && this.model.products[marketTypeId]
        && this.model.products[marketTypeId][productIndex]) {
          return this.model.products[marketTypeId][productIndex].pivot.price;
    }
    return 0;
  }

}
