import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SwalService } from './../../../../services/src/lib/utilities/swal.service';
import { PermissionService } from '@eros-front/services';
import { DataTableColumn } from '@eros-front/services';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { tabSuffix } from '_config/tab-suffix';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'eros-front-permissions-index',
  templateUrl: './permissions-index.component.html',
  styleUrls: ['./permissions-index.component.scss']
})
export class PermissionsIndexComponent implements OnInit, AfterViewInit {
  public datatableSelector = 'admin-permissions-datatable';
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'displayName',
      name: 'Nom usuel'
    },
    {
      attr: 'description',
      name: 'Description'
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];

  constructor(
    private titleService: Title,
    private permissionService: PermissionService,
    private swalService: SwalService,
    private router: Router
  ) {
    this.titleService.setTitle(`Liste des permissions${tabSuffix}`);
  }

  ngOnInit() {
   
  }

  ngAfterViewInit() {
    this.permissionService.initDataTable(this.datatableSelector,this.columns);
    this.initDataTableButtonsEvents();
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function() {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.router.navigateByUrl(`admin/permission/${id}/edit`);
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function() {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'La permission sera supprimée de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.permissionService.delete(id);
          }
        });
      }
    });
  }

  createPermission() {
    this.router.navigateByUrl('admin/permission/create');
  }

}
