import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MarketType } from '@libs/models/src/lib/market-type.model';
import { MarketTypeService, UserService } from '@libs/services/src';
import { Subscription } from 'rxjs';

@Component({
  selector: 'markets-types-checkboxes',
  templateUrl: './markets-types-checkboxes.component.html',
  styleUrls: ['./markets-types-checkboxes.component.scss']
})
export class MarketsTypesCheckboxesComponent implements OnInit {

  @Input() keyPrefix: string;
  @Input() marketsTypes: MarketType[] = [];
  @Input() selectedMarketsTypesIds: number[] = [];
  @Output() selectedMarketsTypesChange = new EventEmitter();

  constructor(
    private userService: UserService
  ) {
  }

  ngOnInit() {

  }

  onSelectedMarketsTypesChange(event: any) {
    if (event.target.checked) {
      this.selectedMarketsTypesIds.push(Number(event.target.value));
    } else {
      const index = this.selectedMarketsTypesIds.indexOf(Number(event.target.value));
      if (index !== -1) {
        this.selectedMarketsTypesIds.splice(index, 1);
      }
    }
    this.selectedMarketsTypesChange.emit(this.arrayIdsToObjects());
    this.userService.updateMarketsTypes(this.selectedMarketsTypesIds);
  }


  private arrayIdsToObjects() {
    let arrayToReturn: MarketType[] = [];
    for (let id of this.selectedMarketsTypesIds) {
      for (let marketType of this.marketsTypes) {
        if (marketType.id === id) {
          arrayToReturn.push(marketType);
        }
      }
    }
    return arrayToReturn;
  }
}
