import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ExpenseAccountStatus, SubmitButton } from '@libs/models/src';
import { NotifService } from '@libs/services/src';
import { ExpenseAccountStatusService } from '@libs/services/src/lib/expense-account-status.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-expenses-accounts-statuses-modal',
  templateUrl: './expenses-accounts-statuses-modal.component.html',
  styleUrls: ['./expenses-accounts-statuses-modal.component.scss']
})
export class ExpensesAccountsStatusesModalComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() expenseAccountStatus: ExpenseAccountStatus;
  public submitButton: SubmitButton;
  public colorValue: string;

  constructor(
    private modal: NzModalRef,
    private expenseAccountStatusService: ExpenseAccountStatusService,
    private notifService: NotifService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.expenseAccountStatusService.setSubmitButton(this.submitButton);
    if (this.form) {
      this.colorValue = this.form.get('color').value
    }
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.expenseAccountStatus) {
        this.expenseAccountStatusService.update(this.expenseAccountStatus.id, this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      } else {
        this.expenseAccountStatusService.store(this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.expenseAccountStatusService.determinateSubmitButton(this.expenseAccountStatus, 'le statut de note de frais');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
