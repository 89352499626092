import { I } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, Input, OnInit, Output, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { faAd, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { AffairProduct, Contact, SubmitButton } from '@libs/models/src';
import { ContactSelectParameters, ContactService, InterventionService, NotifService, SubmitButtonService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-intervention-modal-create',
  templateUrl: './intervention-modal-create.component.html',
  styleUrls: ['./intervention-modal-create.component.scss']
})
export class InterventionModalCreateComponent implements OnInit, OnDestroy {
  @Input() affairProduct: AffairProduct;
  @Input() users: any;
  @Input() conformities: any;
  @Input() indicatorsInterventionsTypes: any[];
  @Input() worksSupervisors: any[];
  @Input() contacts: any[];
  @Input() interventionAdd: any;
  public submitButton: SubmitButton;
  public form: FormGroup;
  public showTime = {
    nzMinuteStep: 15,
    nzFormat: 'HH:mm'
  }
  public selectContactsParams: ContactSelectParameters;
  public affairsProducts: any[];
  private generatedDescription$: Subscription;
  private generatedName$: Subscription;

  constructor(
    private modal: NzModalRef,
    private interventionService: InterventionService,
    private contactService: ContactService,
    private submitButtonService: SubmitButtonService,
    private notifService: NotifService
  ) {
    this.initValues();
    this.initSubscriptions();
    this.determinateSubmitButton();
  }

  ngOnInit() {
    const agenciesIds = [this.affairProduct.affair.agency.ref];
    this.selectContactsParams = {
      selectedAgencies: JSON.stringify(agenciesIds)
    }
    this.affairsProducts = this.affairProduct.affair.affairProducts;
    this.contactService.getForSelectWithParams(this.selectContactsParams);
    this.contactService.getForSelectWorksSupervisor(this.selectContactsParams);
    this.form = this.interventionService.getAddForm(this.affairProduct.affair, this.users, this.interventionAdd);
    this.initAffairProductsCheckboxes();
    //Description & form changes
    this.interventionService.getGeneratedDescription(this.affairProduct.affair);
    this.interventionService.setSubmitButton(this.submitButton);
  }

  ngOnDestroy() {
    this.generatedDescription$?.unsubscribe();
    this.generatedName$?.unsubscribe();
  }

  private initAffairProductsCheckboxes() {
    const affairsProductsTemp: any[] = [];
    affairsProductsTemp.push(this.affairProduct);
    this.affairsProducts.forEach((affairProduct) => {
      const checked = affairsProductsTemp.map(x => x.id).includes(affairProduct.id);
      let apSelected = affairProduct;
      if (checked) {
        apSelected = affairsProductsTemp.find(x => x.id == affairProduct.id);
      }
      this.affairProductsFormArray.push(new FormGroup({
        checked: new FormControl(checked),
        affairProductId: new FormControl(apSelected.id),
        productId: new FormControl(apSelected.productId),
        quantity: new FormControl(apSelected.quantity, [Validators.max(affairProduct.quantity), Validators.min(1)]),
        price: new FormControl(apSelected.price),
      }))
    });
  }

  get affairProductsFormArray() {
    return this.form.controls.affairProducts as FormArray;
  }

  get indicatorsInterventionsTypesFormArray() {
    return this.form.controls.indicatorsInterventionsTypes as FormArray;
  }

  isValidForm(): boolean {
    return this.form.valid;
  }


  private initValues() {
    this.form = null;
  }

  private initSubscriptions() {
    this.generatedName$ = this.interventionService.generatedName$.subscribe(
      (data) => {
        if (data) {
          if (this.form) {
            this.form.patchValue({ 'name': data });
          }
        }
      }
    )
    this.generatedDescription$ = this.interventionService.generatedDescription$.subscribe(
      (data) => {
        if (data) {
          if (this.form) {
            this.form.patchValue({ 'generatedDescription': data });
          }
        }
      }
    )
  }

  submitForm() {
    if (this.isValidForm()) {
      this.interventionService.storeForModal(this.form).subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        }
      )

    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'l\'intervention',
      text: 'Créer',
      icon: faPlus
    });
  }
}
