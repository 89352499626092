import { HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SwalService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { InvoiceService } from '@libs/services/src/lib/invoice.service';
import { FileUtilsService } from '@libs/services/src/lib/utilities/file-utils.service';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { tabSuffix } from '_config/tab-suffix';
declare var require: any;

@Component({
  selector: 'app-invoices-index',
  templateUrl: './invoices-index.component.html',
  styleUrls: ['./invoices-index.component.scss']
})
export class InvoicesIndexComponent implements OnInit {

  @Input() agencies: any[];
  @Input() status: any[];
  @Input() products: any[];
  @Input() mlAgencies: any[];
  public form: FormGroup;
  public storageKey = 'invoices-filters-form';
  public checkedIds: number[] = [];
  public reset = false;
  public refresh = false;

  constructor(
    private titleService: Title,
    private invoiceService: InvoiceService,
    private storageService: FormLocalStorageService,
    private swalService: SwalService,
    private router: Router,
    private fileUtils: FileUtilsService,
  ) {
    this.titleService.setTitle(`Liste des factures${tabSuffix}`);
  }

  ngOnInit() {
    const sessionFormValue = this.storageService.retrieve(this.storageKey);
    this.form = this.invoiceService.getFiltersForm(sessionFormValue);
  }

  onInvoiceCreateClicked() {
    this.router.navigateByUrl(`invoices/manual/create`);
  }

  onDownloadDetailedInvoicesClicked() {
    this.swalService.showSwalLoading('Téléchargement des factures détaillées en cours...');
    this.invoiceService.downloadDetailed(this.checkedIds)
      .pipe(
        catchError(error => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
        finalize(() => {
          this.swalService.closeSwal();
        })
      )
      .subscribe(
        (response: HttpResponse<Blob>) => {
          this.responseToDownload(response, 'zip');
          this.onRefreshClicked();
        }
      );
  }

  onDownloadSimplifiedInvoicesClicked() {
    this.swalService.showSwalLoading('Téléchargement des factures simplifiées en cours...');
    this.invoiceService.downloadSimplified(this.checkedIds)
      .pipe(
        catchError(error => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
        finalize(() => {
          this.swalService.closeSwal();
        })
      )
      .subscribe(
        (response: HttpResponse<Blob>) => {
          this.responseToDownload(response, 'zip');
          this.onRefreshClicked();
        }
      );
  }

  onMergePDFsClicked() {
    this.swalService.showSwalLoading('Assemblage des PDFs en cours...');
    this.invoiceService.mergePDFs(this.checkedIds)
      .pipe(
        catchError(error => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
        finalize(() => {
          this.swalService.closeSwal();
        })
      )
      .subscribe(
        (response: HttpResponse<Blob>) => {
          this.responseToDownload(response, 'pdf');
          this.onRefreshClicked();
        }
      );
  }

  areButtonsEnabled() {
    return this.checkedIds.length > 0;
  }

  private responseToDownload(response: HttpResponse<Blob>, type: string): void {
    const blob = new Blob([response.body], { type: `application/${type}` });
    const downloadUrl = window.URL.createObjectURL(blob);
    if (type === 'zip') {
      this.downloadZip(downloadUrl);
    } else {
      this.fileUtils.downloadFile(downloadUrl, response, type);
    }
  }

  generateExport(): void {
    this.swalService.showSwalLoading('Génération de l\'export en cours...');
    this.invoiceService.generateListExport(this.form)
      .pipe(
        catchError(error => {
          this.swalService.showSwalError(error);
          return throwError(error);
        })
      )
      .subscribe(
        (response: HttpResponse<Blob>) => {
          this.responseToDownload(response, 'xlsx');
          this.swalService.closeSwal();
        }
      )
  }

  resetForm(): void {
    this.reset = !this.reset;
    const resetForm = this.invoiceService.getFiltersForm();
    this.form.reset(resetForm.value);
  }

  onRefreshClicked(): void {
    this.refresh = !this.refresh;
  }

  onClearSelected(): void {
    this.refresh = !this.refresh;
  }

  onCheckedIdsChange(values): void {
    this.checkedIds = values;
  }

  private downloadZip(downloadUrl: string) {
    window.open(downloadUrl);
  }
}
