//Core
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Libs
import { SharedModule } from '@eros-front/shared';

//Components
import { PaymentsIndexComponent } from './payments-index/payments-index.component';
import { PaymentsDatatableFiltersComponent } from './payments-datatable-filters/payments-datatable-filters.component';
import { PaymentCreateComponent } from './payment-create/payment-create.component';
import { PaymentsImportModalComponent } from './payments-import-modal/payments-import-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    PaymentCreateComponent,
    PaymentsIndexComponent,
    PaymentsDatatableFiltersComponent,
    PaymentsImportModalComponent
  ]
})
export class PaymentsModule { }
