import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StatisticModeEnum } from '@libs/enum/src';
import { ChartService, StatisticInterventionVolume } from '@libs/services/src';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-statistics-volumes-regions',
  templateUrl: './statistics-volumes-regions.component.html',
  styleUrls: ['./statistics-volumes-regions.component.scss']
})
export class StatisticsVolumesRegionsComponent implements OnInit, OnDestroy {
  public options: any;
  public statisticsData: any;
  private statsInterventionsRegions$: Subscription;
  @Input() displayMode: number;

  constructor(
    private statisticInterventionVolume: StatisticInterventionVolume,
    private chartService: ChartService
  ) { }

  ngOnInit() {
    this.statsInterventionsRegions$ = this.statisticInterventionVolume.statsInterventionsRegions$.subscribe(
      (data) => {
        if (data) {
          this.initBarChart(data);
          this.statisticsData = data;
        }
      }
    )
  }

  ngOnDestroy() {
    this.statsInterventionsRegions$.unsubscribe();
  }


  initBarChart(data: any) {
    if (data) {
      if (this.displayMode == StatisticModeEnum.YMINUSONE) {
        this.options = this.chartService.initYMinusOneVolumeOptions(data);
      } else {
        this.options = this.chartService.initVolumeOptions(data);
      }
    }
  }

}
