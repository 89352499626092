import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@libs/api/src';
import { Observable, Subscription } from 'rxjs';
import { CommonService } from './utilities/common.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { FormLocalStorageService } from './utilities/form-local-storage.service';

@Injectable()
export class DashboardService {

    private storageKey = 'dashboard-index-filters'

    constructor(
        private formBuilder: FormBuilder,
        private storageService: FormLocalStorageService,
        private commonService: CommonService,
        private formFormatterService: FormFormatterService,
        private apiService: ApiService
    ) { }

    getForm(form: any, selectedMarketsTypesIds?: number[]): FormGroup {
        const userId = form.userId ?
            this.commonService.valueToFormNumber(form.userId) : this.commonService.getDefaultFormNullValue();
        const dayNumber = form.dayNumber ?
            form.dayNumber : this.commonService.getDefaultFormNullValue();
        const marketsTypesIds = selectedMarketsTypesIds ?
            selectedMarketsTypesIds : this.commonService.getDefaultFormNullValue();
        const selectedProducts = form.selectedProducts ?
            form.selectedProducts : this.commonService.getDefaultFormNullValue();

        return this.formBuilder.group({
            userId: [userId],
            dayNumber: [dayNumber, Validators.required],
            marketsTypesIds: [marketsTypesIds, Validators.required],
            selectedProducts: [selectedProducts, Validators.required],
        });
    }

    setDashboardFilters(filters: any) {
        this.storageService.store(this.storageKey, this.transformForm(filters));
    }

    retrieveDashboardFilters(selects: any) {
        const filters = this.storageService.retrieve(this.storageKey);
        if (filters) {
            const selectedAssistants = filters.selectedAssistants && selects.assistants ?
                selects.assistants.filter(x => filters.selectedAssistants.map(x => x).includes(x.value)) : this.commonService.getDefaultFormNullValue();
            const selectedProductsStatuses = filters.selectedProductsStatuses && selects.productsStatuses ?
                selects.productsStatuses.filter(x => filters.selectedProductsStatuses.map(x => x).includes(x.value)) : this.commonService.getDefaultFormNullValue();
            const selectedInterventionsStatuses = filters.selectedInterventionsStatuses && selects.interventionsStatuses ?
                selects.interventionsStatuses.filter(x => filters.selectedInterventionsStatuses.map(x => x).includes(x.value)) : this.commonService.getDefaultFormNullValue();
            const dateInterventionMin = filters.dateInterventionMin ? filters.dateInterventionMin : this.commonService.getDefaultFormStringValue();
            const dateInterventionMax = filters.dateInterventionMax ? filters.dateInterventionMax : this.commonService.getDefaultFormStringValue();
            const dateCreationMin = filters.dateCreationMin ? filters.dateCreationMin : this.commonService.getDefaultFormStringValue();
            const dateCreationMax = filters.dateCreationMax ? filters.dateCreationMax : this.commonService.getDefaultFormStringValue();
            return {
                selectedAssistants: selectedAssistants,
                selectedProductsStatuses: selectedProductsStatuses,
                selectedInterventionsStatuses: selectedInterventionsStatuses,
                dateInterventionMin: dateInterventionMin,
                dateInterventionMax: dateInterventionMax,
                dateCreationMin: dateCreationMin,
                dateCreationMax: dateCreationMax,
            }
        }
        return null;
    }

    transformForm(filters: any): FormGroup {
        const selectedAssistants = filters.selectedAssistants ?
            filters.selectedAssistants : this.commonService.getDefaultFormNullValue();
        const selectedProductsStatuses = filters.selectedProductsStatuses ?
            filters.selectedProductsStatuses : this.commonService.getDefaultFormNullValue();
        const selectedInterventionsStatuses = filters.selectedInterventionsStatuses ?
            filters.selectedInterventionsStatuses : this.commonService.getDefaultFormNullValue();
        const dateInterventionMin = filters.dateInterventionMin ?
            filters.dateInterventionMin : this.commonService.getDefaultFormNullValue();
        const dateInterventionMax = filters.dateInterventionMax ?
            filters.dateInterventionMax : this.commonService.getDefaultFormNullValue();
        const dateCreationMin = filters.createdAtMin ?
            filters.createdAtMin : this.commonService.getDefaultFormNullValue();
        const dateCreationMax = filters.createdAtMax ?
            filters.createdAtMax : this.commonService.getDefaultFormNullValue();

        return this.formBuilder.group({
            selectedAssistants: [selectedAssistants],
            selectedProductsStatuses: [selectedProductsStatuses],
            selectedInterventionsStatuses: [selectedInterventionsStatuses],
            dateInterventionMin: [dateInterventionMin],
            dateInterventionMax: [dateInterventionMax],
            dateCreationMin: [dateCreationMin],
            dateCreationMax: [dateCreationMax]
        });
    }


    getDefaultDashboardProducts(): Observable<any> {
        return this.apiService.get('/affair-products/dashboard/products/default');
    }

    resetFilters() {
        this.storageService.remove(this.storageKey);
    }

}
