import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ExpenseWording, SelectModel } from '@libs/models/src';
import { SwalService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { ExpenseTypeService } from '@libs/services/src/lib/expense-type.service';
import { ExpenseWordingService } from '@libs/services/src/lib/expense-wording.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ExpensesWordingModalComponent } from '../expenses-wording-modal/expenses-wording-modal.component';

@Component({
  selector: 'app-expenses-wording-index',
  templateUrl: './expenses-wording-index.component.html',
  styleUrls: ['./expenses-wording-index.component.scss']
})
export class ExpensesWordingIndexComponent implements OnInit {
  public datatableSelector = 'expenses-wording-datatable';
  public form: FormGroup;
  public expenseWording: ExpenseWording = null;
  public expenseWording$: Subscription;
  public expensesTypes$: Subscription;
  public expensesTypes: SelectModel[];
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'thirdPartyAccount',
      name: 'Compte tiers'
    },
    {
      attr: 'expensesTypes',
      name: 'Types de dépenses associés',
      orderable: false,
      searchable: false
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];

  constructor(
    private expenseWordingService: ExpenseWordingService,
    private expenseTypeService: ExpenseTypeService,
    private swalService: SwalService,
    private modalService: NzModalService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.expenseTypeService.getForSelect();
  }

  ngAfterViewInit() {
    this.expenseWordingService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    if (this.expenseWording$) {
      this.expenseWording$.unsubscribe();
    }
    this.expensesTypes$.unsubscribe();
  }

  onShowModalAddEditModal() {
    this.form = this.expenseWordingService.getForm(this.expenseWording);
    const modalInstance = this.modalService.create({
      nzContent: ExpensesWordingModalComponent,
      nzComponentParams: {
        expenseWording: this.expenseWording,
        form: this.form,
        expensesTypes: this.expensesTypes
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.expenseWording = null;
    })
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.expenseWording$ = that.expenseWordingService.get(id).subscribe(
          (expenseType) => {
            if (expenseType) {
              that.expenseWording = expenseType;
              that.onShowModalAddEditModal();
            }
          }
        );
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'L\'objectif sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.expenseWordingService.delete(id).subscribe(
              (success) => {
                this.swalService.showSwalSuccess(success);
              }
            );
          }
        });
      }
    });
  }

  private initSubscriptions() {
    this.expensesTypes$ = this.expenseTypeService.expensesTypes$.subscribe(
      (data) => {
        if (data) {
          this.expensesTypes = data;
        }
      }
    )
  }

}
