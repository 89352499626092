import { ApiService } from '@eros-front/api';
import { API_URL } from './../services.shared';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { param } from 'jquery';
import { NgxSpinnerService } from "ngx-spinner";

export interface DataTableInstanceParameters {
    url: string;
    selector: string;
    dataTableColumns: DataTableColumn[];
    data?: any;
    columnDefs?: DataTables.ColumnDefsSettings[];
    dom?: string;
    paging?: boolean;
    redirectUrl?: string;
    openInNewTabUrl?: string;
    useCheckbox?: boolean;
    order?: any[];
    pageLength?: 10 | 25 | 50 | 100;
}

export interface DataTableColumn {
    attr: string;
    name: string;
    searchColumn?: string;
    individualFilterInput?: boolean;
    individualFilterSelect?: boolean;
    orderable?: boolean;
    searchable?: boolean;
    visible?: boolean;
    width?: string;
    displayInToggleVis?: boolean;
}

class DataTablesResponse {
    data: any[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
}

@Injectable()
export class ModelWithDatatable {
    dtInstance: DataTables.Api;
    items = [];
    ajaxParameters: any = [];
    apiUrl: string;

    constructor(
        public http: HttpClient,
        public router: Router,
        public apiService: ApiService,
        public spinner?: NgxSpinnerService
    ) {
    }

    initializeDataTable(params: DataTableInstanceParameters, ajaxParameters?: any): void {
        if (ajaxParameters) {
            this.ajaxParameters = ajaxParameters;
        }
        this.dtInstance = this.getDtInstance(params);
    }

    redrawDataTable(params?: any): void {
        if (params) {
            this.ajaxParameters = params;
        }
        if (this.dtInstance != null) {
            this.dtInstance.ajax.reload();
        }
    }

    getApiUrl() {
        return this.apiService.getApiUrl();
    }

    getBearerToken() {
        return this.apiService.getBearerToken();
    }

    getDtInstanceDatatable(): DataTables.Api {
        if (this.dtInstance != null) {
            return this.dtInstance;
        }
    }

    navigate(params: DataTableInstanceParameters, id): void {
        if (params.redirectUrl) {
            this.router.navigateByUrl(params.redirectUrl.replace('$id', id));
        } else if (params.openInNewTabUrl) {
            window.open(params.openInNewTabUrl.replace('$id', id), '_blank');
        }
    }

    private getDtInstance(params: DataTableInstanceParameters): DataTables.Api {
        const that = this;
        const opt = {
            pagingType: 'full_numbers',
            pageLength: params.pageLength !== null ? params.pageLength : 10,
            processing: true,
            serverSide: true,
            stateSave: true,
            paging: params.paging != null ? params.paging : true,
            ajax: {
                url: that.getApiUrl() + params.url,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + that.getBearerToken());
                },
                type: 'post',
                data: function (d) {
                    Object.assign(d, that.ajaxParameters);
                    return d;
                }
            },
            dom: this.getDomOrDefault(params.dom),
            columns: this.dataTableColumnsToColumns(params.dataTableColumns),
            columnDefs: this.getColumDefsArray(params.columnDefs, params.useCheckbox),
            select: this.getSelectObject(params.useCheckbox),
            order: this.getOrderArray(params.useCheckbox),
            language: {
                processing: '',
                search: '<i class="fa fa-search"></i>&nbsp;',
                lengthMenu: 'Afficher _MENU_ &eacute;l&eacute;ments',
                info: 'Affichage de l\'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments',
                infoEmpty: 'Affichage de l\'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment',
                infoFiltered: '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
                infoPostFix: '',
                loadingRecords: 'Chargement des résultats...',
                zeroRecords: 'Aucun &eacute;l&eacute;ment &agrave; afficher',
                emptyTable: 'Aucune donn&eacute;e disponible dans le tableau',
                paginate: {
                    first: '<i class="fa fa-angle-double-left" title="Première page"></i>',
                    previous: '<i class="fa fa-angle-left" title="Précédent"></i>',
                    next: '<i class="fa fa-angle-right" title="Suivant"></i>',
                    last: '<i class="fa fa-angle-double-right" title="Dernière page"></i>'
                },
                aria: {
                    sortAscending: ': activer pour trier la colonne par ordre croissant',
                    sortDescending: ': activer pour trier la colonne par ordre d&eacute;croissant'
                },
                select: {
                    rows: " | %d ligne(s) sélectionnée(s)"
                }
            },
            rowCallback: (row: Node, data: any, index: number) => {
                const self = this;
                if (params.redirectUrl != null || params.openInNewTabUrl != null) {
                    $('td', row).unbind('click');
                    $('td', row).bind('click', () => {
                        self.navigate(params, data.id);
                    });
                }
                return row;
            },
            initComplete: () => {
                $('a.toggle-vis').each(function () {
                    // Get the column API object
                    var column = dtInstance.column($(this).attr('data-column'));
                    if (!column.visible()) {
                        $(this).addClass('not-visible');
                    } else {
                        $(this).removeClass('not-visible');
                    }
                });
                const state = dtInstance.state.loaded();
                if (state) {
                    dtInstance.columns().eq(0).each(function (colIdx) {
                        var colSearch = state.columns[colIdx].search;
                        if (colSearch.search) {
                            $('input', dtInstance.column(colIdx).footer()).val(colSearch.search);
                        }
                    });
                    dtInstance.draw();
                }
            }
        };

        /*if (that.spinner) {
            that.spinner.show('load-datatable');
        }*/
        const dtInstance = $(params.selector).DataTable(opt);
        $(params.selector).on('processing.dt', function (e, settings, processing) {
            if (that.spinner) {
                if (processing) {
                    that.spinner.show('load-datatable');
                } else {
                    that.spinner.hide('load-datatable');
                }
            }
        });
        this.triggerDtEvents(params, dtInstance);
        return dtInstance;
    }

    private triggerDtEvents(params: DataTableInstanceParameters, dtInstance: DataTables.Api): void {
        $('a.toggle-vis').on('click', function (e) {
            e.preventDefault();
            // Get the column API object
            const column = dtInstance.column($(this).attr('data-column'));
            // Toggle the visibility
            column.visible(!column.visible());
            // Set not visible class
            if (!column.visible()) {
                $(this).addClass('not-visible');
            } else {
                $(this).removeClass('not-visible');
            }
        });
        $('table.dataTable tfoot input').on('keyup change clear', function () {
            const searchValue = $(this).val().toString();
            const columnIndex = $(this).attr('data-column');
            dtInstance.column(columnIndex).search(searchValue).draw();
        });
        $('#btn-datatable-export-excel').on('click', function () {
        });
        $('#btn-datatable-export-csv').on('click', function () {
        });
        $('.btn-datatable-reset-filters').on('click', function () {
            dtInstance.search('');
            $('table.dataTable tfoot input').each(function () {
                // tslint:disable-next-line: deprecation
                $(this).val('').change();
            });
            dtInstance.draw();
        });
        $('.btn-datatable-refresh-data').on('click', function () {
            dtInstance.draw();
        });
        if (params.useCheckbox) {
            const anyDtInstance: any = dtInstance;
            anyDtInstance.on("click", "th.select-checkbox", function () {
                if ($("th.select-checkbox").hasClass("selected")) {
                    anyDtInstance.rows().deselect();
                    $("th.select-checkbox").removeClass("selected");
                } else {
                    anyDtInstance.rows().select();
                    $("th.select-checkbox").addClass("selected");
                }
            }).on("select deselect", function () {
                if (anyDtInstance.rows({
                    selected: true
                }).count() !== anyDtInstance.rows().count()) {
                    $("th.select-checkbox").removeClass("selected");
                } else {
                    $("th.select-checkbox").addClass("selected");
                }
            });
        }
    }

    private dataTableColumnsToColumns(dataTableColumns: DataTableColumn[]): any[] {
        const columns = [];
        dataTableColumns.forEach((elem) => {
            columns.push({
                data: elem.attr,
                name: elem.searchColumn != null ? elem.searchColumn : elem.attr,
                orderable: elem.orderable != null ? elem.orderable : true,
                searchable: elem.searchable != null ? elem.searchable : true,
                visible: elem.visible != null ? elem.visible : true,
                width: elem.width != null ? elem.width : '',
            });
        });
        return columns;
    }

    private getDomOrDefault(dom?: string): string {
        if (dom != null && dom.length > 0) {
            return dom;
        } else {
            return '<"row"<"col-4 float-left"i><"col-4 text-center"f><"col-4 float-right"l>> <"row"<"col-12"tr>> <"bottom"p>';
        }
    }

    private getColumDefsArray(columnDefs: any[], isSelect: boolean): any[] {
        if (columnDefs) {
            columnDefs.push({
                targets: '_all',
                defaultContent: '-',
            });
        } else {
            columnDefs = [];
        }
        if (isSelect) {
            columnDefs.push({
                width: "5%",
                targets: 0,
                data: null,
                defaultContent: '',
                orderable: false,
                checkboxes: {
                    'selectRow': true
                },
                className: 'select-checkbox'
            });
        }
        return columnDefs;
    }

    private getSelectObject(isSelect: boolean): any {
        if (isSelect) {
            return {
                style: 'multi+shift',
                selector: 'td:first-child'
            };
        }
        return null;
    }

    private getOrderArray(isSelect: boolean): any[] {
        if (isSelect) {
            return [[1, 'desc']];
        }
        return [[0, 'desc']];
    }
}
