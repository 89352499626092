import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Affair } from '@libs/models/src';
import { InvoiceService } from '@libs/services/src/lib/invoice.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-affair-show-invoices-thumbnails',
  templateUrl: './affair-show-invoices-thumbnails.component.html',
  styleUrls: ['./affair-show-invoices-thumbnails.component.scss']
})
export class AffairShowInvoicesThumbnailsComponent implements OnInit, OnDestroy {
  @Input() affair: Affair;
  private invoicesThumbnails$: Subscription;
  public thumbnails = [];

  constructor(
    private invoiceService: InvoiceService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.invoiceService.thumbnails(this.affair.id);
  }

  ngOnDestroy() {
    this.invoicesThumbnails$.unsubscribe();
  }

  initSubscriptions() {
    this.invoicesThumbnails$ = this.invoiceService.invoicesThumbnails$.subscribe(
      (data) => {
        if (data) {
          this.thumbnails = data;
        }
      }
    )
  }


}
