export class AffairProductsToInvoicingTabs {

  static readonly BIH = new AffairProductsToInvoicingTabs('bih', 'Be in home', 'tll fa-mi-market');
  static readonly DOMBOX = new AffairProductsToInvoicingTabs('dombox', 'Dombox', 'tll fa-mi-market');
  static readonly EBB = new AffairProductsToInvoicingTabs('ebb', 'Clic RE2020', 'tll fa-mi-market');

  private constructor(
    private readonly key: string,
    public readonly text: string,
    public readonly icon: string
  ) { }

  toString() {
    return this.key;
  }

}