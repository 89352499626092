import { Component, OnInit } from '@angular/core';
import { FormGroup, SelectMultipleControlValueAccessor } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Expense, ExpenseAccount, SelectModel } from '@libs/models/src';
import { ContactService, FileService, FileUtilsService, NotifService, SwalService, UserService } from '@libs/services/src';
import { ExpenseAccountService } from '@libs/services/src/lib/expense-account.service';
import { ExpenseTypeService } from '@libs/services/src/lib/expense-type.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subscription, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { ExpenseAddEditModalComponent } from '../expense-add-edit-modal/expense-add-edit-modal.component';
import { tabSuffix } from '_config/tab-suffix';
import { ExpenseWordingService } from '@libs/services/src/lib/expense-wording.service';
import { ExpenseService } from '@libs/services/src/lib/expense.service';
import { HttpResponse } from '@angular/common/http';
import { catchError, distinctUntilChanged, finalize } from 'rxjs/operators';


export interface ExpenseAccountData {
  data: ExpenseAccount[];
  meta: any;
}

@Component({
  selector: 'app-expenses-accounts-list',
  templateUrl: './expenses-accounts-list.component.html',
  styleUrls: ['./expenses-accounts-list.component.scss']
})
export class ExpensesAccountsListComponent implements OnInit {
  public form: FormGroup;
  public expensesAccountsData: ExpenseAccount[];
  public pageSize: number = 100;
  public pageIndex: number = 1;
  public total: number = 1;
  public loading: boolean;
  public expandSet = new Set<number>();
  public setOfCheckedId = new Set<number>();
  public users$: Subscription;
  public users: SelectModel[];
  listOfCurrentPageData: readonly ExpenseAccount[] = [];
  public checked = false;
  public expensesTypes$: Subscription;
  public expensesTypes;
  public expensesWording$: Subscription;
  public expensesWording: any;
  public contacts$: Subscription;
  public contacts: any;

  constructor(
    private titleService: Title,
    private expenseAccountService: ExpenseAccountService,
    private userService: UserService,
    private swalService: SwalService,
    private notifService: NotifService,
    private modalService: NzModalService,
    private expenseTypeService: ExpenseTypeService,
    private expenseWordingService: ExpenseWordingService,
    private fileService: FileService,
    private expenseService: ExpenseService,
    private fileUtilsService: FileUtilsService,
    private contactService: ContactService
  ) {
    this.titleService.setTitle(`Liste des notes de frais${tabSuffix}`);
    this.initSubscriptions();
  }

  ngOnInit() {
    this.form = this.expenseAccountService.initFiltersForm();
    this.userService.getUsersSelect();
    this.expenseTypeService.getForSelect();
    this.expenseWordingService.getForSelect();
    this.contactService.getForSelect();
    this.initList();
    this.form.valueChanges.pipe(distinctUntilChanged())
      .subscribe(val => {
        this.initList();
      })
  }

  initList() {
    this.expenseAccountService.getForComptabilityList(this.form).subscribe(
      (data) => {
        if (data) {
          this.expensesAccountsData = data.data;
          this.total = data.meta.total;
        }
      }
    )
  }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onCurrentPageDataChange(listOfCurrentPageData: readonly any[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData;
    this.checked = listOfEnabledData.every(({ id }) => this.setOfCheckedId.has(id));
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.listOfCurrentPageData
      .forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.form.patchValue({
      'page': pageIndex,
      'size': pageSize
    });

    this.initList();
  }

  onGenerateExportClicked(): void {
    this.swalService.showSwalLoading('Génération de l\'export en cours...');
    this.expenseAccountService.generateExport(this.form)
      .pipe(
        catchError(error => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
        finalize(() => {
          this.swalService.closeSwal();
        })
      )
      .subscribe(
        (response: HttpResponse<Blob>) => {
          this.fileUtilsService.responseToDownload(response, 'vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        }
      );
  }

  markAsPosted(): void {
    const checkedExpensesAccounts = Array.from(this.setOfCheckedId);
    this.expenseAccountService.markAsPosted(checkedExpensesAccounts).subscribe(
      (response) => {
        this.notifService.showSuccessNotif(response);
        this.initList();
      }
    )
  }

  onShowFile(expense: Expense) {
    this.fileService.getBlob(expense.path).subscribe(
      (file) => {
        const blob = new Blob([file], { type: expense.mimeType });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      }
    );
  }

  onDeleteExpense(id: number) {
    const swalOptions = this.swalService.getSwalDeleteOptions({
      text: 'La dépense sera supprimée'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.expenseService.delete(id).subscribe(
          (success) => {
            if (success) {
              this.notifService.showSuccessNotif(success);
              this.initList();
            }
          }
        );
      }
    });

  }


  validate(id: number) {
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'La note de frais sera validée'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.expenseAccountService.validate(id).subscribe(
          (success) => {
            if (success) {
              this.notifService.showSuccessNotif(success);
              this.initList();
            }
          }
        );
      }
    });
  }


  proofMissing(id: number) {
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'La note de frais sera notifiée comme en manque de justificatif'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.expenseAccountService.proofMissing(id).subscribe(
          (success) => {
            if (success) {
              this.notifService.showSuccessNotif(success);
              this.initList();
            }
          }
        );
      }
    });
  }

  onShowAddEditExpenseModal(expenseAccountId: number, expense?: Expense) {
    const modalInstance = this.modalService.create({
      nzWidth: '60%',
      nzContent: ExpenseAddEditModalComponent,
      nzComponentParams: {
        expenseAccountId: expenseAccountId,
        expense: expense,
        users: this.users,
        contacts: this.contacts,
        expensesTypes: this.expensesTypes,
        expensesWording: this.expensesWording
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.initList();
    })
  }

  private initSubscriptions(): void {
    this.users$ = this.userService.usersSelect$.subscribe(
      (data) => {
        if (data) {
          this.users = data;
        }
      }
    )
    this.expensesTypes$ = this.expenseTypeService.expensesTypes$.subscribe(
      (data) => {
        if (data) {
          this.expensesTypes = data;
        }
      }
    )
    this.expensesWording$ = this.expenseWordingService.expensesWording$.subscribe(
      (data) => {
        if (data) {
          this.expensesWording = data;
          this.expensesWording.push({ name: "Autre", id: -1 });
        }
      }
    )
    this.contacts$ = this.contactService.selectContacts$.subscribe(
      (contacts) => {
        if (contacts) {
          this.contacts = contacts;
        }
      }
    )
  }

}
