import { Component, Input, OnInit } from '@angular/core';
import { ColorsTool } from '_tools/colors';

@Component({
  selector: 'app-intervention-tag',
  templateUrl: './intervention-tag.component.html',
  styleUrls: ['./intervention-tag.component.scss']
})
export class InterventionTagComponent implements OnInit {
  
  @Input() name: string;
  @Input() color: string = '#c8c8c8';
  @Input() isLast: boolean = false;
  @Input() maxWidth: string = '200px';
  @Input() startDate: string;
  @Input() hourInterval: string;

  constructor() { }

  ngOnInit() {
  }

  getDarkenColor(color: string): string {
    return ColorsTool.getShadeColor(color, -20);
  }

}
