import { Injectable } from '@angular/core';
import { ApiService } from '@libs/api/src';
import { SubmitButton } from '@libs/models/src';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';

export interface ShuttleSheetBuildingTitle {
    name: string;
    key: string;
    icon: string;
    col: 'right' | 'left';
    itemsSelect: ShuttleSheetBuildingItemSelect[];
}

export interface ShuttleSheetBuildingItemSelect {
    name: string;
    key: string;
    visible: boolean;
    visibleSingleStory: boolean;
    visibleFloor: boolean;
    visibleConvertedAttic: boolean;
    visibleGarage: boolean;
    showHelp: boolean;
    helpTooltip: string;
    showAddButton: boolean;
    favorite: string;
    select: any[];
    selection: any[];
}

export interface ShuttleSheetEquipmentTitle {
    name: string;
    icon: string;
    key: string;
    itemsRadio?: ShuttleSheetBuildingItemRadio[];
    showAddButton?: boolean;
    select?: any[];
    selection?: any[];
}

export interface ShuttleSheetBuildingItemRadio {
    name: string;
    key: string;
    icons: string[];
    select: any[];
    selection: any[];
    options: any[];
}

@Injectable()
export class ShuttleSheetService {

    private route = '/shuttles-sheets-configurations';
    private templateSubmitButton: SubmitButton;
    private modalTemplate: ModalDirective;
    private titleSubmitButton: SubmitButton;
    private modalTitle: ModalDirective;
    private itemSubmitButton: SubmitButton;
    private modalItem: ModalDirective;
    private optionSubmitButton: SubmitButton;
    private modalOption: ModalDirective;
    public baseModel$ = new BehaviorSubject<any>(undefined);

    constructor(
        private apiService: ApiService,
        private notifService: NotifService,
        private swalService: SwalService,
        private submitButtonService: SubmitButtonService
    ) { }

    getBaseModel() {
        this.apiService.get(`${this.route}/base`)
            .subscribe(
                (result) => {
                    this.baseModel$.next(result);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    updateBaseModel(model: any) {
        this.submitButtonService.setDisabled(this.titleSubmitButton);
        this.submitButtonService.setDisabled(this.itemSubmitButton);
        this.submitButtonService.setDisabled(this.optionSubmitButton);
        this.apiService.put(`${this.route}/base`, {
            properties: JSON.stringify(model)
        })
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.titleSubmitButton);
                    this.submitButtonService.setEnabled(this.itemSubmitButton);
                    this.submitButtonService.setEnabled(this.optionSubmitButton);
                })
            )
            .subscribe(
                (success) => {
                    this.hideModalTitle();
                    this.hideModalItem();
                    this.hideModalOption();
                    this.getBaseModel();
                    this.swalService.showSwalSuccess(success);
                }
            );
    }

    setModalTemplate(modal: ModalDirective): void {
        this.modalTemplate = modal;
    }

    showModalTemplate(): void {
        this.modalTemplate.show();
    }

    hideModalTemplate(): void {
        this.modalTemplate.hide();
    }

    setTemplateSubmitButton(button: SubmitButton): void {
        this.templateSubmitButton = button;
    }

    setModalTitle(modal: ModalDirective): void {
        this.modalTitle = modal;
    }

    showModalTitle(): void {
        this.modalTitle.show();
    }

    hideModalTitle(): void {
        this.modalTitle.hide();
    }

    setTitleSubmitButton(button: SubmitButton): void {
        this.titleSubmitButton = button;
    }

    setModalItem(modal: ModalDirective): void {
        this.modalItem = modal;
    }

    showModalItem(): void {
        this.modalItem.show();
    }

    hideModalItem(): void {
        this.modalItem.hide();
    }

    setItemSubmitButton(button: SubmitButton): void {
        this.itemSubmitButton = button;
    }

    setModalOption(modal: ModalDirective): void {
        this.modalOption = modal;
    }

    showModalOption(): void {
        this.modalOption.show();
    }

    hideModalOption(): void {
        this.modalOption.hide();
    }

    setOptionSubmitButton(button: SubmitButton): void {
        this.optionSubmitButton = button;
    }
}
