import { Injectable } from '@angular/core';
import { FormArray, FormGroup, ValidatorFn } from '@angular/forms';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Injectable()
export class FormValidatorsService {

    constructor() { }

    minSelectedCheckboxes(min = 1) {
        const validator: ValidatorFn = (formArray: FormArray) => {
            const totalSelected = formArray.controls
                // get a list of checkbox values (boolean)
                .map(control => control.value)
                .filter(value => value.checked == 1)
                .length
            // if the total is not greater than the minimum, return the error message
            return totalSelected >= min ? null : { required: true };

        };

        return validator;
    }


}
