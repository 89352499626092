import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormFormatterService } from './utilities/form-formatter.service';
import { CommonService } from './utilities/common.service';
import { ApiService } from '@libs/api/src';
import { ModelWithModalCrud } from './classes/model-modal-crud';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { NotifService } from './utilities/notif.service';
import { catchError, finalize } from 'rxjs/operators';
import { QuotationService } from './quotation.service';
import { InvoiceDetail, QuotationDetail } from '@libs/models/src';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';


@Injectable()
export class QuotationDetailService extends ModelWithDatatableAndCrudObservable {

    private route = '/quotation-details';
    public detail$ = new BehaviorSubject<any>(undefined);
    public details$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        router: Router,
        apiService: ApiService,
        swalService: SwalService,
        notifService: NotifService,
        public submitButtonService: SubmitButtonService,
        private quotationService: QuotationService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public getForm(quotationId: number, detail?: QuotationDetail): FormGroup {
        const productId = detail ? this.commonService.valueToFormSelect(detail.productId) : this.commonService.getDefaultFormNullValue();
        const ref = detail ? this.commonService.valueToFormString(detail.ref) : this.commonService.getDefaultFormStringValue();
        const accountingCode = detail ? this.commonService.valueToFormString(detail.accountingCode) : this.commonService.getDefaultFormStringValue();
        const designation = detail ? this.commonService.valueToFormString(detail.designation) : this.commonService.getDefaultFormStringValue();
        const vatRate = detail ? this.commonService.valueToFormSelect(detail.vatRate) : this.commonService.getDefaultFormNullValue();
        const unitPrice = detail ? this.commonService.valueToFormNumber(detail.unitPrice) : 1;
        const quantity = detail ? this.commonService.valueToFormNumber(detail.quantity) : 1;
        return this.formBuilder.group({
            quotationId: [quotationId, Validators.required],
            productId: productId,
            ref: [ref, Validators.required],
            accountingCode: [accountingCode, Validators.required],
            designation: designation,
            vatRate: [vatRate, Validators.required],
            unitPrice: [unitPrice, Validators.required],
            quantity: [quantity, Validators.required]
        });
    }

    public getManualCreateForm(): FormGroup {
        let form = this.getForm(0);
        form.removeControl('quotationId');
        form = this.formBuilder.group({
            ...form.controls,
            rowId: '_' + Math.random().toString(36).substr(2, 9),
            vatRate: [this.commonService.getDefaultFormVatRateValue()],
            totalWithoutTaxes: this.commonService.formatNumberToLocale(1)
        });
        return form;
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route);
    }

    public getAllByInvoice(invoiceId: number): void {
        this.apiService.get(`${this.route}/invoice/${invoiceId}`)
            .subscribe(
                (objects) => {
                    this.details$.next(objects);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public store(form: FormGroup): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.post(this.route, this.formatForm(form))
            .pipe(
                catchError(error => {
                    this.notifService.showErrorNotif(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            );
    }

    public update(id: number, form: FormGroup): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.put(this.route + '/' + id, this.formatForm(form))
            .pipe(
                catchError(error => {
                    this.notifService.showErrorNotif(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            );
    }

    public deleteAndRefresh(invoiceId: number, id: number): void {
        this.apiService.delete(this.route + '/' + id)
            .pipe(
                catchError(error => {
                    this.notifService.showErrorNotif(error);
                    return throwError(error);
                })
            )
            .subscribe(
                (success) => {
                    this.quotationService.get(invoiceId);
                    this.notifService.showSuccessNotif(success);
                }
            );
    }

    private formatForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.productId = this.formFormatterService.formatSelectSingle(values.productId);
        return values;
    }

}
