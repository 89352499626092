import { animate, state, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faClipboard, faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Affair, AffairMonitoring } from '@libs/models/src';
import { AffairMonitoringService, AffairMonitoringTypeService, AffairService, NotifService, SwalService, ToggleService } from '@libs/services/src';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-affair-show-monitoring',
  templateUrl: './affair-show-monitoring.component.html',
  styleUrls: ['./affair-show-monitoring.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger(
      'openFormAnimation',
      [
        state('open', style({
          right: "-70%",
          width: "70%"
        })),
        state('closed', style({
          right: "-35%",
          width: "35%"
        })),
        transition('open => closed', [
          animate('0.4s')
        ]),
        transition('closed => open', [
          animate('0.4s')
        ]),
        transition('* => closed', [
          animate('0.4s')
        ]),
        transition('* => open', [
          animate('0.4s')
        ]),
        transition('open <=> closed', [
          animate('0.4s')
        ]),
        transition('* => open', [
          animate('0.4s',
            style({ opacity: '*' }),
          ),
        ]),
        transition('* => *', [
          animate('0.4s')
        ]),
      ]
    ),
  ]
})
export class AffairShowMonitoringComponent implements OnInit, OnDestroy {
  @Input() affair: Affair;
  public form: FormGroup;
  public isOpen = false;
  public timelineBeforeIntervention: any[] = [];
  public timelineAfterIntervention: any[] = [];
  private affairsMonitoring$: Subscription;
  private affairsMonitoringTypes$: Subscription;
  private affairProgrammedStandardResponses$: Subscription;
  public affairMonitoring = null;
  public affairsMonitoringTypes = [];
  public formOpen = false;
  public faClipboard = faClipboard;
  public faEnvelope = faPaperPlane;
  public affairProgrammedStandardResponses: any = [];

  constructor(
    private affairMonitoringService: AffairMonitoringService,
    private affairMonitoringTypeService: AffairMonitoringTypeService,
    private affairService: AffairService,
    private toggleService: ToggleService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private swalService: SwalService,
    private notifService: NotifService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.affairMonitoringService.getByAffair(this.affair.id);
    this.affairMonitoringTypeService.getAll();
    this.affairService.getProgrammedStandardResponses(this.affair.id);
  }

  ngOnDestroy() {
    this.affairsMonitoring$.unsubscribe();
    this.affairsMonitoringTypes$.unsubscribe();
    this.affairProgrammedStandardResponses$.unsubscribe();
  }

  toggle() {
    if (this.isOpen) {
      this.isOpen = false;
      this.renderer.setStyle(this.document.body, 'overflow', 'auto');
    } else {
      this.isOpen = true;
      this.renderer.setStyle(this.document.body, 'overflow', 'hidden');
    }
  }

  onShowModalAddEditModal() {
    this.form = this.affairMonitoringService.getForm(this.affair, this.affairMonitoring);
    this.formOpen = true;
  }

  onClickedEntry(entry) {
    this.affairMonitoring = entry.object;
    this.form = this.affairMonitoringService.getForm(this.affair, this.affairMonitoring);
    this.formOpen = true;
  }

  onDeleteProgrammedStandardResponse(programmedStandardResponseId: number) {
    const swalOptions = this.swalService.getSwalDeleteOptions({
      text: 'La réponse programmée sera supprimée'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.affairService.deleteProgrammedStandardResponses(this.affair, programmedStandardResponseId).subscribe(
          (success) => {
            if (success) {
              this.notifService.showSuccessNotif(success);
              this.affairService.getProgrammedStandardResponses(this.affair.id);
            }
          }
        );
      }
    });
  }

  closeForm() {
    this.formOpen = false;
    this.form = null;
    this.affairMonitoring = null;
    this.affairMonitoringService.getByAffair(this.affair.id);
  }

  calcSumTimelines(): number {
    return this.timelineBeforeIntervention.length + this.timelineAfterIntervention.length;
  }

  calcSumProgrammedStandardResponseNotSent(): number {
    let count = 0;
    this.affairProgrammedStandardResponses.forEach(affairProgrammedStandardResponse => {
      if (!affairProgrammedStandardResponse.isSend) {
        count++;
      }
    });
    return count;
  }

  private initSubscriptions() {
    this.toggleService.quickViewToggle.subscribe(data => {
      this.toggle();
    });

    this.affairsMonitoring$ = this.affairMonitoringService.affairsMonitoring$.subscribe(
      (data) => {
        if (data) {
          this.initTimeline(data)
        }
      });

    this.affairsMonitoringTypes$ = this.affairMonitoringTypeService.getAll().subscribe(
      (data) => {
        this.affairsMonitoringTypes = data;

      }
    )
    this.affairProgrammedStandardResponses$ = this.affairService.affairProgrammedStandardResponses$.subscribe(
      (data) => {
        if (data) {
          this.affairProgrammedStandardResponses = data;
        }
      }
    )
  }

  private initTimeline(affairsMonitoring: AffairMonitoring[]) {
    this.timelineBeforeIntervention = [];
    this.timelineAfterIntervention = [];
    affairsMonitoring.forEach((affairMonitoring) => {
      const object = {
        dateFormatted: affairMonitoring.createdAt,
        icon: affairMonitoring.affairMonitoringType.icon,
        color: affairMonitoring.affairMonitoringType.color,
        title: affairMonitoring.affairMonitoringType.abbreviation,
        content: affairMonitoring.text,
        username: affairMonitoring.username,
        badge: affairMonitoring.level,
        object: affairMonitoring,
        isClosed: affairMonitoring.isClosed,
        isAfterIntervention: affairMonitoring.isAfterIntervention
      }
      if (affairMonitoring.isAfterIntervention) {
        this.timelineAfterIntervention.push(object);
      } else {
        this.timelineBeforeIntervention.push(object);
      }
    });
  }


}
