import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'quick-stats-widget',
  templateUrl: './quick-stats-widget.component.html',
  styleUrls: ['./quick-stats-widget.component.scss']
})
export class QuickStatsWidgetComponent implements OnInit {
  @Input() title = "";
  @Input() number = 0;
  @Input() total = 0;
  @Input() progressBarColor = 'danger';
  @Input() rate = '0%';


  constructor() { }

  ngOnInit() { }
}
