import { Component, OnDestroy, OnInit } from '@angular/core';
import { InvoiceService } from '@libs/services/src/lib/invoice.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-invoices-tabs',
  templateUrl: './invoices-tabs.component.html',
  styleUrls: ['./invoices-tabs.component.scss']
})
export class InvoicesTabsComponent implements OnInit, OnDestroy {

  public agencies = [];
  public status = [];
  public products = [];
  public mlAgencies = [];
  private filters$: Subscription;

  constructor(
    private invoiceService: InvoiceService
  ) {
    this.filters$ = this.invoiceService.filters$.subscribe(
      (data) => {
        if (data) {
          this.agencies = data.agencies;
          this.status = data.invoicesStatuses;
          this.products = data.products;
          this.mlAgencies = data.mlAgencies;
        }
      }
    );
  }

  ngOnInit() {
    this.invoiceService.getDataTableFilters();
  }

  ngOnDestroy() {
    this.filters$.unsubscribe();
  }

}
