import { ApiService } from '@eros-front/api';
import { SwalService } from './swal.service';
import { SubmitButton } from '@eros-front/models';
import { SubmitButtonService } from './submit-button.service';
import { NotifService } from './notif.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class SelectService {

    public selectMlAgencies$ = new BehaviorSubject<any>(undefined);
    public selectProducts$ = new BehaviorSubject<any>(undefined);
    public selectAssistants$ = new BehaviorSubject<any>(undefined);
    public selectTechnicians$ = new BehaviorSubject<any>(undefined);
    public selectPayersByAgency$ = new BehaviorSubject<any>(undefined);

    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private notifService: NotifService,
        private submitButtonService: SubmitButtonService,
        private swalService: SwalService
    ) {}

    getMlAgenciesSelect() {
        this.apiService.get('/ml-agencies/select')
        .pipe(
            catchError(err => {
                return throwError(err);
            })
        )
        .subscribe(
            (response) => {
                this.selectMlAgencies$.next(response);
            }
        );
    }

    getProductsSelect() {
        this.apiService.get('/products/select')
        .pipe(
            catchError(err => {
                return throwError(err);
            })
        )
        .subscribe(
            (response) => {
                this.selectProducts$.next(response);
            }
        );
    }

    getAssistantsSelect() {
        this.apiService.get('/users/assistant/select')
        .pipe(
            catchError(err => {
                return throwError(err);
            })
        )
        .subscribe(
            (response) => {
                this.selectAssistants$.next(response);
            }
        );
    }

    getTechniciansSelect() {
        this.apiService.get('/users/technician/select')
        .pipe(
            catchError(err => {
                return throwError(err);
            })
        )
        .subscribe(
            (response) => {
                this.selectTechnicians$.next(response);
            }
        );
    }

    getPayersByAgencySelect(id: number) {
        this.apiService.get('/payers/agency/' + id)
        .pipe(
            catchError(err => {
                return throwError(err);
            })
        )
        .subscribe(
            (response) => {
                this.selectPayersByAgency$.next(response);
            }
        );
    }

    getForSelect(observable$: BehaviorSubject<any>, route: string) {
        this.apiService.get(route + '/select')
        .subscribe(
            (objects) => {
                observable$.next(objects);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    getForSelectWithParams(observable$: BehaviorSubject<any>, route: string, params: any) {
        this.apiService.post(route + '/select-with-parameters', params)
        .pipe(
            catchError(error => {
                this.swalService.showSwalError(error);
                return throwError(error);
            }),
        )
        .subscribe(
            (objects) => {
                observable$.next(objects);
            }
        );
    }
}
