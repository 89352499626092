import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-affair-show-program',
  templateUrl: './affair-show-program.component.html',
  styleUrls: ['./affair-show-program.component.scss']
})
export class AffairShowProgramComponent implements OnInit {
  @Input() affair: any;
  @Input() affairProductsToProgram: any;
  @Input() buildingObject: any;

  constructor() { }

  ngOnInit() {
    if (this.buildingObject) {
      console.log(this.buildingObject);
    }
  }

}
