import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ng-select-multiple',
  templateUrl: './ng-select-multiple.component.html',
  styleUrls: ['./ng-select-multiple.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => NgSelectMultipleComponent)
    }
  ]
})
export class NgSelectMultipleComponent implements OnInit, ControlValueAccessor {

  @Input() class: string;
  @Input() items: any[];
  @Input() selectedNgModel: any;
  @Input() maxSelectedItems = 3;
  @Input() selectAllText: string;
  @Input() selectAllFilteredText: string;
  @Input() deselectAllText: string;
  @Input() countSelectedText: string;
  @Input() placeholder: string;
  @Input() virtualScroll = false;
  @Input() useOptionIconPrefix = false;
  @Input() useOptionIconSuffix = false;
  @Input() useLabelIconPrefix = false;
  @Input() useSvgSource = false;
  @Input() appendTo: string;
  @Input() disabled = false;
  @Input() loading = false;
  public filteredItems: any[] = [];

  selectedItemsValue: any[];
  @Output() selectedItemsChange = new EventEmitter<any>();
  @Input()
  get selectedItems() {
    return this.selectedItemsValue;
  }
  set selectedItems(val) {
  }

  constructor() { }

  ngOnInit() {
    if (!this.maxSelectedItems || this.maxSelectedItems <= 0) {
      this.maxSelectedItems = 3;
    }
    if (!this.items) {
      this.items = []
    }
  }

  onSelectAll() {
    this.selectedItemsValue = this.items;
    this.propagateChange(this.selectedItemsValue);
    this.selectedItemsChange.emit(this.selectedItemsValue);
  }

  onSelectAllFiltered() {
    this.selectedItemsValue = this.filteredItems;
    this.propagateChange(this.selectedItemsValue);
    this.selectedItemsChange.emit(this.selectedItemsValue);
  }

  onDeselectAll() {
    this.selectedItemsValue = [];
    this.filteredItems = [];
    this.propagateChange(this.selectedItemsValue);
    this.selectedItemsChange.emit(this.selectedItemsValue);
  }

  clearSelectItem(callback: Function, arg: any) {
    callback(arg);
  }

  onChange($event) {
    this.selectedItemsValue = $event;
    this.propagateChange(this.selectedItemsValue);
    this.selectedItemsChange.emit(this.selectedItemsValue);
  }

  onSearch($event) {
    if ($event.term == "") {
      this.filteredItems = [];
    } else {
      this.filteredItems = $event.items;
    }
  }

  clearSelectedItems() {
    this.selectedItemsValue = [];
    this.filteredItems = [];
  }

  writeValue(value: any) {
    this.selectedItemsValue = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  propagateChange = (_: any) => { };
}