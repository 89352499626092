import { SwalService, ThingService, InterventionStatusService, DataTableColumn } from '@eros-front/services';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SettingsInterventionsInterventionsStatusesModalComponent } from '../settings-interventions-interventions-statuses-modal/settings-interventions-interventions-statuses-modal.component';

@Component({
  selector: 'app-settings-interventions-interventions-statuses-index',
  templateUrl: './settings-interventions-interventions-statuses-index.component.html',
  styleUrls: ['./settings-interventions-interventions-statuses-index.component.scss']
})
export class SettingsInterventionsInterventionsStatusesIndexComponent implements OnInit, AfterViewInit, OnDestroy {

  public datatableSelector = 'interventions-statuses-datatable';
  public status: any = null;
  public status$: Subscription;
  public labelsClasses = [];
  public labelsClasses$: Subscription;
  public googleCalendarColors = [];
  public googleCalendarColors$: Subscription;
  public outlookCategories = [];
  public outlookCategories$: Subscription;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'label',
      name: 'Rendu',
      searchable: false,
      orderable: false
    },
    {
      attr: 'color',
      name: 'Couleur calendrier Éros',
      searchable: false,
      orderable: false
    },
    {
      attr: 'outlookCategory',
      name: 'Catégorie Outlook',
      searchable: false,
      orderable: false
    },
     {
      attr: 'tagColor',
      name: 'Couleur tag',
      searchable: false,
      orderable: false
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];
  public form: FormGroup;

  constructor(
    private interventionStatusService: InterventionStatusService,
    private thingService: ThingService,
    private swalService: SwalService,
    private modalService: NzModalService
  ) { }

  ngOnInit() {
    this.getValues();
    this.initSubscriptions();
  }

  ngAfterViewInit() {
    this.interventionStatusService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    if (this.status$) {
      this.status$.unsubscribe();
    }
    this.labelsClasses$.unsubscribe();
  }

  onShowModalAddEdit() {
    this.form = this.interventionStatusService.getForm(this.status);
    const modalInstance = this.modalService.create({
      nzContent: SettingsInterventionsInterventionsStatusesModalComponent,
      nzComponentParams: {
        status: this.status,
        form: this.form,
        googleCalendarColors: this.googleCalendarColors,
        labelsClasses: this.labelsClasses,
        outlookCategories: this.outlookCategories
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.status = null;
    })

  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.status$ = that.interventionStatusService.get(id).subscribe(
          (status) => {
            if (status) {
              that.status = status;
              that.onShowModalAddEdit();
            }
          }
        );
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le statut d\'intervention sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.interventionStatusService.delete(id).subscribe(
              (success) => {
                that.swalService.showSwalSuccess(success);
              }
            );
          }
        });
      }
    });
  }

  private getValues() {
    this.thingService.getLabelsClassesSelect();
    this.thingService.getGoogleCalendarColorsSelect();
    this.thingService.getOutlookCategories();
  }

  private initSubscriptions() {
    this.labelsClasses$ = this.thingService.labelsClasses$.subscribe(
      (labelsClasses) => {
        if (labelsClasses) {
          this.labelsClasses = labelsClasses;
        }
      }
    );
    this.googleCalendarColors$ = this.thingService.googleCalendarColors$.subscribe(
      (googleCalendarColors) => {
        if (googleCalendarColors) {
          this.googleCalendarColors = googleCalendarColors;
        }
      }
    );
    this.outlookCategories$ = this.thingService.outlookCategories$.subscribe(
      (outlookCategories) => {
        if (outlookCategories) {
          this.outlookCategories = outlookCategories;
        }
      }
    )
  }


}
