import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { SelectService } from './utilities/select.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { Group, SubmitButton } from '@eros-front/models';
import { CommonService } from './utilities/common.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { ModelWithDatatableAndCrud } from './classes/model-datatable-crud';
import { catchError, finalize } from 'rxjs/operators';

export interface GroupSelectParam {
    selectedMarketsTypes: any[];
    selectedCustomersTypes: any[];
    selectedIndicatorsCustomers: any[];
    selectedRivals: any[];
    selectedManagers: any[];
}

export interface GroupDatatableParameters {
    selectedGroups: any[];
}

export interface GroupFormParameters {
    companies: any[];
    marketsTypes: any[];
    products: any[];
}

@Injectable()
export class GroupService extends ModelWithDatatableAndCrud {

    private route = '/groups';
    public dtInstance: DataTables.Api;
    private columns: DataTableColumn[];
    public groupsSelect$ = new BehaviorSubject<any>(undefined);
    public selectedGroups = new Subject<any>();
    public selectedGroups$ = this.selectedGroups.asObservable();
    public ajaxParameters: any;
    public groupSelectParams: GroupSelectParam;
    public submitPricesButton: SubmitButton;

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        apiService: ApiService,
        router: Router,
        private formBuilder: FormBuilder,
        private selectService: SelectService,
        private commonService: CommonService,
        private formFormatterService: FormFormatterService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }

    public setDataTableColumns(columns: DataTableColumn[]) {
        this.columns = columns;
    }

    public setSelectedGroupsItems(selectedGroupsItems) {
        this.selectedGroups.next(selectedGroupsItems);
    }

    public getSelectedGroupsItems() {
        this.selectedGroups.next();
    }

    public initDataTable(selector: string, columns: DataTableColumn[]) {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns,
            redirectUrl: 'customers/$id/consult'
        });
    }

    public redrawDataTable(params: GroupDatatableParameters) {
        super.redrawDataTable(params);
    }

    public getForSelectWithParams(params = []): void {
        this.selectService.getForSelectWithParams(this.groupsSelect$, this.route, params);
    }

    public applyFilters(form: any): void {
        if (form.value) {
            this.groupSelectParams = {
                selectedMarketsTypes: form.value.selectedMarketsTypes.map(x => x.value),
                selectedCustomersTypes: form.value.selectedCustomersTypes.map(x => x.value),
                selectedIndicatorsCustomers: form.value.selectedIndicatorsCustomers.map(x => x.value),
                selectedRivals: form.value.selectedRivals.map(x => x.value),
                selectedManagers: form.value.selectedManagers.map(x => x.value),
            };
        }
        this.selectService.getForSelectWithParams(this.groupsSelect$, this.route, this.groupSelectParams);
    }

    public store(form: any): void {
        super.store(this.formatForm(form), this.route);
    }

    public update(id: number, form: any): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.put(this.route + '/' + id, this.formatForm(form)).pipe(
            catchError(error => {
                this.swalService.showSwalError(error);
                return throwError(error);
            }),
            finalize(() => {
                this.submitButtonService.setEnabled(this.submitButton);
            })
        )
    }

    public updatePrices(id: number, form: any): Observable<any> {
        this.submitButtonService.setDisabled(this.submitPricesButton);
        return this.apiService.put(`${this.route}/${id}/prices`, form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitPricesButton);
                })
            )
    }

    public delete(id: number): void {
        super.delete(id, this.route);
    }

    public getForm(params: GroupFormParameters, group?: Group): FormGroup {
        const name = group ?
            this.commonService.valueToFormString(group.name) : this.commonService.getDefaultFormStringValue();
        const administrativeInternalNote = group ?
            this.commonService.valueToFormString(group.administrativeInternalNote) : this.commonService.getDefaultFormStringValue();
        const technicalInternalNote = group ?
            this.commonService.valueToFormString(group.technicalInternalNote) : this.commonService.getDefaultFormStringValue();
        const marketsTypes = group ?
            group.marketsTypes.map(x => ({ value: x.id, label: x.name })) : [];
        const customersTypes = group ?
            group.customersTypes.map(x => ({ value: x.id, label: x.name })) : [];
        const indicatorsCustomers = group ?
            group.indicatorsCustomers.map(x => ({ value: x.id, label: x.name })) : [];
        const products = [];
        // Products
        for (const marketType of params.marketsTypes) {
            const subArray = [];
            for (const product of params.products) {
                let productValue = 0;
                if (group && group.products && group.products[marketType.value]) {
                    const productObj = group.products[marketType.value][product.id];
                    if (productObj != null && typeof productObj === 'object') {
                        productValue = productObj.pivot.price;
                    }
                }
                subArray.push(
                    this.formBuilder.group({ ['product' + product.id]: productValue })
                );
            }
            products.push(this.formBuilder.array(subArray));
        }
        return this.formBuilder.group({
            name: [name, Validators.required],
            administrativeInternalNote: administrativeInternalNote,
            technicalInternalNote: technicalInternalNote,
            marketsTypes: [marketsTypes],
            customersTypes: [customersTypes],
            indicatorsCustomers: [indicatorsCustomers],
            products: this.formBuilder.array(products)
        });
    }

    public getInformationsForm(group?: Group): FormGroup {
        const name = group ?
            this.commonService.valueToFormString(group.name) : this.commonService.getDefaultFormStringValue();
        const administrativeInternalNote = group ?
            this.commonService.valueToFormString(group.administrativeInternalNote) : this.commonService.getDefaultFormStringValue();
        const technicalInternalNote = group ?
            this.commonService.valueToFormString(group.technicalInternalNote) : this.commonService.getDefaultFormStringValue();
        const marketsTypes = group ?
            group.marketsTypes.map(x => ({ value: x.id, label: x.name })) : [];
        const customersTypes = group ?
            group.customersTypes.map(x => ({ value: x.id, label: x.name })) : [];
        const indicatorsCustomers = group ?
            group.indicatorsCustomers.map(x => ({ value: x.id, label: x.name })) : [];
        return this.formBuilder.group({
            name: [name, Validators.required],
            marketsTypes: [marketsTypes],
            customersTypes: [customersTypes],
            indicatorsCustomers: [indicatorsCustomers],
            administrativeInternalNote: [administrativeInternalNote],
            technicalInternalNote: [technicalInternalNote],
            mlSocieties: new FormArray([])
        });
    }

    public getPricesForm(marketsTypes: any[], products: any[], group?: Group): FormGroup {
        const results = [];
        for (const marketType of marketsTypes) {
            const subArray = [];
            for (const product of products) {
                let productValue = 0;
                if (group && group.products && group.products[marketType.value]) {
                    const productObj = group.products[marketType.value][product.id];
                    if (productObj != null && typeof productObj === 'object') {
                        productValue = productObj.pivot.price;
                    }
                }
                subArray.push(
                    this.formBuilder.group({ ['product' + product.id]: productValue })
                );
            }
            results.push(this.formBuilder.array(subArray));
        }
        return this.formBuilder.group({
            products: this.formBuilder.array(results)
        });
    }


    public initMlSocietiesCheckboxes(form: FormGroup, mlSocieties: any, group?: Group): void {
        const mlSocietiesFormArray = form.get('mlSocieties') as FormArray;
        mlSocieties.forEach((mlSociety) => {
            let checked = false;
            if (group) {
                checked = group.mlSocieties.map(x => x.id).includes(mlSociety.id);
            }
            mlSocietiesFormArray.push(new FormGroup({
                mlSocietyId: new FormControl(mlSociety.id),
                checked: new FormControl(checked),
            }))
        });
    }

    private formatForm(form: FormGroup) {
        const values = JSON.parse(JSON.stringify(form.value));
        values.marketsTypes = this.formFormatterService.formatSelectMultipleToIntArray(values.marketsTypes);
        values.customersTypes = this.formFormatterService.formatSelectMultipleToIntArray(values.customersTypes);
        values.indicatorsCustomers = this.formFormatterService.formatSelectMultipleToIntArray(values.indicatorsCustomers);
        values.mlSocieties = this.formFormatterService.formatSelectMultipleToIntArray(this.formFormatterService.filterCheckedValues(values.mlSocieties), 'mlSocietyId')
        return values;
    }

    public setSubmitPricesButton(button: SubmitButton) {
        this.submitPricesButton = button;
    }

}
