import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AffairMonitoringTypeService, SendSettingService, SwalService, ThingService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { MonitoringTypeService } from '@libs/services/src/lib/monitoring-type.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { MonitoringTypesModalComponent } from '../monitoring-types-modal/monitoring-types-modal.component';

@Component({
  selector: 'app-monitoring-types-index',
  templateUrl: './monitoring-types-index.component.html',
  styleUrls: ['./monitoring-types-index.component.scss']
})
export class MonitoringTypesIndexComponent implements OnInit, OnDestroy {
  public datatableSelector = 'monitoring-types-datatable';
  private availableMonitoringTypesEntitiesClasses$: Subscription;
  public form: FormGroup;
  public monitoringType: any = null;
  public monitoringType$: Subscription;
  public availableMonitoringTypesEntitiesClasses = [];
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'abbreviation',
      name: 'Abréviation',
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'icon',
      name: 'Icône'
    },
    {
      attr: 'color',
      name: 'Couleur'
    },
    {
      attr: 'defaultText',
      name: 'Texte par défaut'
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];

  constructor(
    private monitoringTypeService: MonitoringTypeService,
    private swalService: SwalService,
    private modalService: NzModalService,
    private thingService: ThingService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.thingService.getAvailableMonitoringTypesEntityClass();
  }

  ngAfterViewInit() {
    this.monitoringTypeService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    if (this.monitoringType$) {
      this.monitoringType$.unsubscribe();
    }
  }

  onShowModalAddEditModal() {
    this.form = this.monitoringTypeService.getForm(this.monitoringType);
    const modalInstance = this.modalService.create({
      nzContent: MonitoringTypesModalComponent,
      nzComponentParams: {
        monitoringType: this.monitoringType,
        availableEntitiesClasses: this.availableMonitoringTypesEntitiesClasses,
        form: this.form
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.monitoringType = null;
    })
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.monitoringType$ = that.monitoringTypeService.get(id).subscribe(
          (data) => {
            if (data) {
              that.monitoringType = data;
              that.onShowModalAddEditModal();
            }
          }
        );
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le type de suivi d\'affaire sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.monitoringTypeService.delete(id).subscribe(
              (success) => {
                this.swalService.showSwalSuccess(success);
              }
            );
          }
        });
      }
    });
  }

  private initSubscriptions() {
    this.availableMonitoringTypesEntitiesClasses$ = this.thingService.availableMonitoringTypesEntitiesClasses$.subscribe(
      (data) => {
        if (data) {
          this.availableMonitoringTypesEntitiesClasses = data;

        }
      }
    )

  }

}
