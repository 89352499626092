import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { SubmitButton } from '@libs/models/src';
import { AgencyService, FormFormatterService, PayerService } from '@libs/services/src';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-customers-consult-graph-modal-agency',
  templateUrl: './customers-consult-graph-modal-agency.component.html',
  styleUrls: ['./customers-consult-graph-modal-agency.component.scss']
})
export class CustomersConsultGraphModalAgencyComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() group: any;
  @Input() agency: any;
  @Input() companies: any[];
  @Input() mlAgencies: any[];
  @Input() assistants: any[];
  @Input() technicians: any[];
  @Input() marketsTypes: any[];
  @Input() customersTypes: any[];
  @Input() indicatorsCustomers: any[];
  @Input() products: any[];
  @Input() contactFrequencies: any[];
  @Input() paymentDeadlines: any[];
  @Input() paymentDeadlineTypes: any[];
  @Input() billingPreferences: any[];
  @Input() payerPreferences: any[];
  @Input() payers: any[];
  @Input() librariesBuilder: any[];
  @Input() managers: any[];
  @Input() rivals: any[];
  @ViewChild('customersConsultGraphAddEditAgency', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public payerForm: FormGroup;
  public submitButton: SubmitButton;
  public submitPayerButton: SubmitButton;
  public displayedProducts = [];
  public selectedPriceMarketTypeText = '';
  public isDivAddPayerVisible = false;

  constructor(
    private agencyService: AgencyService,
    private payerService: PayerService,
    private formFormatterService: FormFormatterService
  ) { }

  ngOnInit() {
    this.initValues();
  }

  ngAfterViewInit() {
    //this.agencyService.setModalAddEdit(this.childModal);
  }

  ngOnChanges() {
    if (this.marketsTypes.length > 0 && this.selectedPriceMarketTypeText.length === 0) {
      this.selectedPriceMarketTypeText = this.marketsTypes[0].label;
    }
    if (this.products.length > 0 && this.displayedProducts.length === 0) {
      for (const p of this.products) {
        this.displayedProducts.push({
          id: p.id,
          name: p.name,
          visible: true
        });
      }
    }
  }

  onModalShown() {
    this.determinateSubmitButton();
    //this.agencyService.setSubmitButton(this.submitButton);
    this.form = this.agencyService.getForm({
      companies: this.companies,
      marketsTypes: this.marketsTypes,
      products: this.products
    }, this.agency);
    this.payerForm = this.payerService.getForm();
  }

  onModalHidden() {
    this.initValues();
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm(this.form)) {
      if (this.agency) {
        this.agencyService.update(this.agency.id, this.form);
      } else {
        this.agencyService.store(this.form);
      }
    }
  }

  submitPayerForm() {
    if (this.isValidForm(this.payerForm)) {
      this.payerService.store(this.payerForm, this.submitPayerButton);
    }
  }

  isValidForm(form: FormGroup) {
    return form.valid;
  }

  onAddressChange(address: Address) {
    this.form = this.formFormatterService.completeGoogleAddress(
      this.form, address,
      {
        street_number: 'streetNumber',
        route: 'address',
        postal_code: 'postalCode',
        locality: 'locality'
      }
    );
  }

  onPayerAddressChange(address: Address) {
    this.payerForm = this.formFormatterService.completeGoogleAddress(
      this.payerForm, address,
      {
        route: 'address',
        postal_code: 'postalCode',
        locality: 'locality',
        concatStreetWithRoute: true
      }
    );
  }

  onButtonDivAddPayerClicked() {
    this.isDivAddPayerVisible = !this.isDivAddPayerVisible;
  }

  private determinateSubmitButton() {
    this.submitButton = this.agencyService.determinateSubmitButton(this.agency, 'l\'agence');
    this.submitPayerButton = {
      text: 'Ajouter le payeur',
      originalText: 'Ajouter le payeur',
      icon: faPlus,
      originalIcon: faPlus,
      enabled: true
    };
  }

  private initValues() {
    this.form = null;
    this.payerForm = null;
  }

}
