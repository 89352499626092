import { Component, OnInit, Input, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { AffairProductService, AffairService, NotifService, SubmitButtonService } from '@eros-front/services';
import { Affair, SubmitButton } from '@eros-front/models';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'affair-product-modal-add',
  templateUrl: './affair-product-modal-add.component.html',
  styleUrls: ['./affair-product-modal-add.component.scss']
})
export class AffairProductModalAddComponent implements OnInit {

  @Input() affair: Affair;
  @Input() products: any[];
  @Input() techniciansItems: any[];
  public form: FormGroup;
  public affairProducts: FormArray;
  public affairFields: FormArray;
  public submitButton: SubmitButton;
  public selectedProducts: any;

  constructor(
    private modal: NzModalRef,
    private formBuilder: FormBuilder,
    private affairService: AffairService,
    private affairProductService: AffairProductService,
    private notifService: NotifService,
    private submitButtonService: SubmitButtonService
  ) { }

  ngOnInit() {
    this.initFormValues();
    this.determinateSubmitButton();
  }

  onAffairProductAdd(item: any) {
    this.affairProducts = this.form.get('affairProducts') as FormArray;
    this.affairProducts.push(this.affairProductService.getAddForm(this.affair, item, this.techniciansItems));

    // We also add affair fields
    this.affairFields = this.form.get('affairFields') as FormArray;
    item.fields.forEach((field) => {
      if (!this.affairFields.value.some((e) => e.id === field.id) &&
        !this.affair.fields.some((e) => e.id === field.id)) {
        this.affairFields.push(this.affairService.createAffairFieldForm(field));
      }
    });
  }

  onAffairProductRemove(item: any) {
    for (let i = 0; i < this.affairProducts.controls.length; i++) {
      const control = this.affairProducts.controls[i];
      if (control.value.productId === item.value.id) {
        this.affairProducts.removeAt(i);
        break;
      }
    }
    // We keep a field if at least one product need it
    const fieldsIndexesToDelete = [];
    let offsetDelete = 0;
    for (let i = 0; i < this.affairFields.controls.length; i++) {
      let atLeastOneNeeded = false;
      const control = this.affairFields.controls[i];
      this.selectedProducts.forEach((product) => {
        product.fields.forEach((f) => {
          if (f.id === control.value.id) {
            atLeastOneNeeded = true;
            return;
          }
        });
        if (atLeastOneNeeded) {
          return;
        }
      });
      if (!atLeastOneNeeded) {
        fieldsIndexesToDelete.push(i);
      }
    }
    fieldsIndexesToDelete.forEach((i) => {
      this.affairFields.removeAt(i - offsetDelete);
      offsetDelete++;
    });
  }

  submitForm() {
    if (this.isValidForm()) {
      this.affairProductService.storeMultiple(this.form).subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        },
        (error) => {
          this.notifService.showErrorNotif(error);
        }
      );
    }
  }


  private initFormValues() {
    if (this.affair) {
      this.affairProducts = new FormArray([]);
      this.affairFields = new FormArray([]);
      this.form = this.formBuilder.group({
        affairId: [this.affair.id, Validators.required],
        affairProducts: this.formBuilder.array([]),
        affairFields: this.formBuilder.array([])
      });
    }
  }

  public isValidForm(): boolean {
    if (this.form) {
      return this.form.valid;
    }
    return false;
  }

  private determinateSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'un ou plusieurs code(s) produit(s)',
      text: 'Ajouter',
      icon: faPlus
    });
    this.affairProductService.setSubmitButton(this.submitButton);
  }

}
