import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RegexService } from '@libs/services/src';

@Component({
  selector: 'input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss']
})
export class InputPhoneComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() inputClass: string;
  @Input() inputFormControlName: string;
  public phoneMask = null;

  constructor(private regexService: RegexService) { }

  ngOnInit() {
    this.phoneMask = this.regexService.getPhoneMask();
  }

}
