import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@libs/services/src';

@Component({
  selector: 'app-affair-show-preview-amount',
  templateUrl: './affair-show-preview-amount.component.html',
  styleUrls: ['./affair-show-preview-amount.component.scss']
})
export class AffairShowPreviewAmountComponent implements OnInit {
  @Input() previewTotalWithoutTaxes: number;
  @Input() previewAmountVat: number;
  @Input() previewTotalAllTaxes: number;

  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit() {
  }

  formatNumberToLocale(number: number) {
    return this.commonService.formatNumberToLocale(number);
  }

}
