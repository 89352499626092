import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-settings-affairs',
  templateUrl: './settings-affairs.component.html',
  styleUrls: ['./settings-affairs.component.scss']
})
export class SettingsAffairsComponent implements OnInit, OnDestroy {
  private settingsAffairs$: Subscription;
  public appKey;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute
  ) {
    this.titleService.setTitle(`Paramètres affaire${tabSuffix}`);
  }

  ngOnInit() {
    this.settingsAffairs$ = this.route.data.subscribe(
      (data) =>
        this.appKey = data.appKey
    );
  }

  ngOnDestroy() {
    this.settingsAffairs$.unsubscribe();
  }

}
