import { Component, Input, OnInit } from '@angular/core';
import { DocumentCustomer, DocumentCustomerAttached } from '@libs/models/src/lib/document-customer.model';

@Component({
  selector: 'app-affair-document-customer-attached',
  templateUrl: './affair-document-customer-attached.component.html',
  styleUrls: ['./affair-document-customer-attached.component.scss']
})
export class AffairDocumentCustomerAttachedComponent implements OnInit {
  @Input() documentsCustomersAttached: any;

  constructor() { }

  ngOnInit() {
  }

}
