import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, Validators, FormArray, FormControl, FormGroup } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { DataTableColumn } from './classes/model-datatable';
import { FormFormatterService } from './utilities/form-formatter.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { ModelWithDatatableAndModalCrud } from './classes/model-datatable-modal-crud';
import { Field } from '@eros-front/models';
import { CommonService } from './utilities/common.service';

@Injectable()
export class FieldService extends ModelWithDatatableAndModalCrud {

    private route = '/fields';
    public field$ = new BehaviorSubject<any>(undefined);
    public fields$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        apiService: ApiService,
        router: Router,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): void {
        super.get(id, this.field$, this.route);
    }

    public getAll(): void {
        super.getAll(this.fields$, this.route);
    }

    public store(form: any): void {
        super.store(this.formatForm(form), this.route);
    }

    public update(id: number, form: any): void {
        super.update(id, this.formatForm(form), this.route);
    }

    public delete(id: number): void {
        super.delete(id, this.route);
    }

    public getForm(field: Field): FormGroup {
        const name = field ?
            this.commonService.valueToFormString(field.name) : this.commonService.getDefaultFormNullValue();
        const type = field ?
            this.commonService.valueToFormString(field.type) : this.commonService.getDefaultFormNullValue();
        const placeholder = field ?
            this.commonService.valueToFormString(field.placeholder) : this.commonService.getDefaultFormNullValue();
        const isActive = field ?
            this.commonService.valueToRadioFormString(field.isActive) : this.commonService.getDefaultFormRadioValue();
        const rules = [];
        if (field) {
            if (field.rules && Array.isArray(field.rules)) {
                field.rules.forEach((rule) => {
                    rules.push(new FormControl(rule));
                });
            }
        }
        return this.formBuilder.group({
            name: [name, Validators.required],
            type: [type, Validators.required],
            rules: new FormArray(rules),
            placeholder: placeholder,
            isActive: isActive
        });
    }

    private formatForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.type = this.formFormatterService.formatSelectSingle(values.type);
        return values;
    }



}
