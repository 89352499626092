import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-front-programmed-standards-responses-filters',
  templateUrl: './programmed-standards-responses-filters.component.html',
  styleUrls: ['./programmed-standards-responses-filters.component.scss']
})
export class ProgrammedStandardsResponsesFiltersComponent implements OnInit {
  @Input() form: FormGroup;

  constructor() { }

  ngOnInit() {
  }

}
