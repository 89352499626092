import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AuthentificationService, AuthGuardService } from '@libs/auth/src';
import { SelectModel } from '@libs/models/src';
import { InterventionService, UserService } from '@libs/services/src';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-interventions-preparation',
  templateUrl: './interventions-preparation.component.html',
  styleUrls: ['./interventions-preparation.component.scss']
})
export class InterventionsPreparationComponent implements OnInit {
  public technicians$: Subscription;
  public technicians: SelectModel[];
  public interventions: any;

  public form: FormGroup;
  public checked = false;
  public loading = false;
  public indeterminate = false;
  public listOfData: readonly any[] = [];
  public listOfCurrentPageData: readonly any[] = [];
  public setOfCheckedId = new Set<number>();
  public setOfCheckedInterventions = new Set<any>();
  public userInfos: any;

  constructor(
    private titleService: Title,
    private interventionService: InterventionService,
    private userService: UserService,
    private authService: AuthentificationService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.titleService.setTitle(`Préparation intervention${tabSuffix}`);
    this.userService.getTechniciansSelect();
    this.userInfos = this.authService.getUserInfos();


    this.form = this.interventionService.getPreparationForm(this.userInfos.id);
    this.initList();

    this.form.valueChanges.subscribe(form => {
      if (this.isValidForm()) {
        this.initList();
      } else {
        this.interventions = [];
      }
    });
  }

  initList() {
    this.loading = true;
    this.interventionService.initPreparationList(this.form).subscribe(
      (data) => {
        if (data) {
          this.interventions = data;
          this.loading = false;
          if (this.interventions.length == 0) {
            this.refreshCheckedStatus();
          }
        }
      }
    );
  }

  resetForm() {
    const resetForm = this.interventionService.getPreparationForm(this.userInfos.id);
    this.form.reset(resetForm.value);
  }

  onCurrentPageDataChange(listOfCurrentPageData: readonly any[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    let intervention = this.interventions.find(x => x.id == id);
    if (checked) {
      this.setOfCheckedId.add(id);
      this.setOfCheckedInterventions.add(intervention);
    } else {
      this.setOfCheckedId.delete(id);
      this.setOfCheckedInterventions.delete(intervention);
    }
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData.filter(({ disabled }) => !disabled);
    this.checked = listOfEnabledData.every(({ id }) => this.setOfCheckedId.has(id));
    this.indeterminate = listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
  }


  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.listOfCurrentPageData
      .filter(({ disabled }) => !disabled)
      .forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  private isValidForm() {
    return this.form.valid;
  }

  private initSubscriptions() {
    this.technicians$ = this.userService.selectTechnicians$.subscribe(
      (data) => {
        if (data) {
          this.technicians = data;
        }
      }
    )
  }

  redirectToAffair(affairId: number) {
    window.open('affairs/' + affairId + '/show', '_blank');
  }

  prepareInterventions() {
    this.interventionService.prepare(Array.from(this.setOfCheckedId)).subscribe(
      (response: HttpResponse<Blob>) => {
        const blob = new Blob([response.body], { type: 'application/zip' });
        const downloadUrl = window.URL.createObjectURL(blob);
        window.open(downloadUrl);
      }
    );
  }

}
