export class AffairPayerInvoicing {

  static readonly YES = new AffairPayerInvoicing(1, 'Oui');
  static readonly NO = new AffairPayerInvoicing(0, 'Non');

  private constructor(
    private readonly key: number,
    public readonly text: string
  ) { }

  toString() {
    return this.key;
  }

}
