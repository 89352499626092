import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MlSocietiesWorkWith } from '@libs/enum/src';
import { MlSocietyService } from '@libs/services/src/lib/ml-society.service';
import { InputPhoneComponent } from '../input-phone/input-phone.component';

@Component({
  selector: 'ml-societies-work-with',
  templateUrl: './ml-societies-work-with.component.html',
  styleUrls: ['./ml-societies-work-with.component.scss']
})
export class MlSocietiesWorkWithComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() mlSocieties: any[];
  @Input() keyPrefix: '';
  @Input() size: 'xs' | 'xl' = 'xs';

  constructor(
    private mlSocietyService: MlSocietyService
  ) { }

  ngOnInit() {
    if (!this.mlSocieties) {
      this.mlSocietyService.getAll().subscribe(
        (data) => {
          if (data) {
            this.mlSocieties = data;
          }
        }
      )
    }
  }

}
