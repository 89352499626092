import { AfterViewInit, Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { Intervention, SubmitButton } from '@libs/models/src';
import { CommonService, InterventionService, NotifService, SubmitButtonService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-intervention-edit-shift-modal',
  templateUrl: './intervention-edit-shift-modal.component.html',
  styleUrls: ['./intervention-edit-shift-modal.component.scss']
})
export class InterventionEditShiftModalComponent implements OnInit {
  @Input() intervention: Intervention;
  @Input() shiftOrigins;
  @Input() users: any;
  public form: FormGroup;
  public showTime = {
    nzMinuteStep: 15,
    nzFormat: 'HH:mm'
  }
  public submitButton: SubmitButton;
  public faHistory = faHistory;
  public isOpenOnce = false;
  public defaultDate = null;

  constructor(
    private modal: NzModalRef,
    private interventionService: InterventionService,
    private submitButtonService: SubmitButtonService,
    private notifService: NotifService
  ) {
  }

  ngOnInit() {
    this.setSubmitButton();
    this.form.get('isTimeSlot').valueChanges.subscribe(value => {
      this.defaultDate = null;
      this.isOpenOnce = false;
      if (value === '1') {
        this.form.controls['startDate'].setValidators(null);
        this.form.controls['duration'].setValidators(null);
        this.form.controls['startDate'].updateValueAndValidity();
        this.form.controls['duration'].updateValueAndValidity();
        this.form.controls['timeSlot'].setValidators(Validators.required);
        this.form.controls['timeSlot'].updateValueAndValidity();
      } else {
        this.form.controls['startDate'].setValidators(Validators.required);
        this.form.controls['duration'].setValidators(Validators.required);
        this.form.controls['startDate'].updateValueAndValidity();
        this.form.controls['duration'].updateValueAndValidity();
        this.form.controls['timeSlot'].setValidators(null);
        this.form.controls['timeSlot'].setValue(null);
        this.form.controls['timeSlot'].updateValueAndValidity();
      }
    });
  }

  submitShiftIntervention() {
    if (this.form.valid) {
      this.interventionService.shift(this.intervention.id, this.form).subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        }
      );
    }
  }

  onDatepickerOpening() {
    if (!this.isOpenOnce) {
      this.defaultDate = new Date().setMinutes(0);
      this.isOpenOnce = true;
    }
  }

  onDateRangePickerOpening() {
    if (!this.isOpenOnce && this.form.value.timeSlot == null) {
      this.defaultDate = [new Date().setMinutes(0), null];
      this.isOpenOnce = true;
    }
  }


  public isValidForm(): boolean {
    if (this.form) {
      return this.form.valid;
    }
    return false;
  }

  public isTimeSlotChecked() {
    if (this.form) {
      return this.form.value.isTimeSlot === '1';
    }
    return false;
  }

  private setSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'l\'intervention',
      text: 'Décaler',
      icon: faHistory
    });
    this.interventionService.setSubmitButtonShift(this.submitButton);
  }
}
