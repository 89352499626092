import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from './utilities/common.service';
import { Affair, Part } from '@libs/models/src';
import { catchError, finalize } from 'rxjs/operators';
import { ModelWithModal } from './classes/model-modal';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BuildingPartService extends ModelWithDatatableAndCrudObservable {

    private route = '/buildings-parts';
    public part$ = new BehaviorSubject<Part>(undefined);
    public selectParts$ = new BehaviorSubject<Part[]>(undefined);
    public buildingObject$ = new BehaviorSubject<Part[]>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private commonService: CommonService,
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route);
    }

    public getAllByAffair(affairId: number): void {
        this.apiService.get(`${this.route}/affairs/${affairId}/select`)
            .subscribe(
                (parts: Part[]) => {
                    this.selectParts$.next(parts);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public getBuildingsByAffair(affairId: number): void {
        this.apiService.post(`${this.route}/get-by-affair`, {
            affairId: affairId
        })
            .subscribe(
                (parts: Part[]) => {
                    this.buildingObject$.next(parts);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public store(form: FormGroup): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.post(this.route, form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            );
    }

    public update(id: number, form: FormGroup): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return super.update(id, form.value, this.route)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            );
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            );
    }

    public getForm(affair: Affair, part?: Part) {
        const name = part ?
            this.commonService.valueToFormString(part.name) : this.commonService.getDefaultFormStringValue();
        let deliveryDate = this.commonService.getDefaultFormNullValue();
        if (part) {
            deliveryDate = this.commonService.valueToFormDate(part.deliveryDate)
        } else if (affair) {
            deliveryDate = this.commonService.valueToFormDate(affair.deliveryDate)
        }
        return this.formBuilder.group({
            affairId: [affair.id, Validators.required],
            name: [name, Validators.required],
            deliveryDate: deliveryDate
        });
    }
}
