import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { RegionService } from '@libs/services/src';
import { CHARTS_NAMES } from 'apps/eros-nrj/src/config/charts-names';
import { endOfMonth, addMonths } from 'date-fns';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-statistic-filters',
  templateUrl: './statistic-filters.component.html',
  styleUrls: ['./statistic-filters.component.scss']
})
export class StatisticFiltersComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() technicians;
  @Input() regions;
  @Input() formattedRegions;
  @Input() mlAgencies;
  @Input() products;
  @Input() marketsTypes;
  @Input() selectedChart;
  public defaultRange: any;
  public chartsNames = CHARTS_NAMES;
  public agencyMode = false;
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;
  public regionsSelectTechnicians$: Subscription;
  public dateRangeMode = "month"

  constructor(
    private regionService: RegionService
  ) {
    this.regionsSelectTechnicians$ = this.regionService.regionsSelectTechnicians$.subscribe(
      (data) => {
        if (data) {
          this.form.patchValue({ 'techniciansIds': data })
        }
      }
    );
  }

  ngOnInit() {
    this.defaultRange = { 'Ce mois-ci': [new Date(), endOfMonth(new Date())], 'Le mois suivant': [addMonths(new Date(), 1), addMonths(new Date(), 1)] };

    if (this.form) {
      this.form.get('objectsIds').valueChanges.subscribe(val => {
        const selectedRegionsIds = val.map((x: any) => x.value);
        const regionsItems = this.regions.filter(x => selectedRegionsIds.includes(x.id))
        let mlAgenciesIds = [];
        regionsItems.forEach(region =>
          region.mlAgencies.forEach(mlAgency => {
            mlAgenciesIds.push({ label: mlAgency.name, value: mlAgency.id });
          }
          ));
        this.form.patchValue({ 'mlAgenciesIds': mlAgenciesIds })
        this.regionService.getForSelectTechnicians(selectedRegionsIds);
      });

      this.form.get('dateDisplayMode').valueChanges.subscribe(val => {
        this.dateRangeMode = this.getDisplayDateMode(val);
      });

      this.dateRangeMode = this.getDisplayDateMode(this.form.value.dateDisplayMode);
    }

  }

  ngOnDestroy() {
  }

  activateAgencyMode($event) {
    this.agencyMode = !this.agencyMode;
    this.form.patchValue({ 'isRegion': !this.agencyMode })
  }

  getDisplayDateMode(dateDisplayMode) {
    switch (dateDisplayMode) {
      case 0: return 'day';
      case 3: return 'year';
      default: return 'month';
    }
  }
}
