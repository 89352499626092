import { catchError } from 'rxjs/operators';
import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { SelectService } from './utilities/select.service';
import { ModelWithDatatableAndModalCrud } from './classes/model-datatable-modal-crud';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, Subscription, throwError } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { CommonService } from './utilities/common.service';
import { Contact } from '@eros-front/models';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { FormFormatterService } from './utilities/form-formatter.service';

export interface ContactSelectParameters {
    selectedGroups?: string[];
    selectedBrands?: string[];
    selectedAgencies: string;
}

export interface ContactDatatableParameters {
    selectedContacts: any[];
}


@Injectable()
export class ContactService extends ModelWithDatatableAndCrudObservable {

    private route = '/contacts';
    public dtInstance: DataTables.Api;
    private columns: DataTableColumn[];
    public selectedContacts = new Subject<any>();
    public contacts$ = new BehaviorSubject<Contact[]>(undefined);
    public contactsAffair$ = new BehaviorSubject<Contact[]>(undefined);
    public contactsSelect$ = new BehaviorSubject<any>(undefined);
    public selectContacts$ = new BehaviorSubject<any>(undefined);
    public selectedAgencies$ = this.selectedContacts.asObservable();
    public ajaxParameters: any;
    public contactsSelectParams: ContactSelectParameters;
    public selectWorksSupervisors$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        apiService: ApiService,
        router: Router,
        private formBuilder: FormBuilder,
        private selectService: SelectService,
        private commonService: CommonService,
        private formFormatterService: FormFormatterService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public setDataTableColumns(columns: DataTableColumn[]) {
        this.columns = columns;
    }

    public setSelectedGroupsItems(selectedAgenciesItems) {
        this.selectedContacts.next(selectedAgenciesItems);
    }

    public getSelectedGroupsItems() {
        this.selectedContacts.next();
    }

    public initDataTable(selector: string, columns: DataTableColumn[]) {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public redrawDataTable(params: ContactDatatableParameters) {
        super.redrawDataTable(params);
    }

    public getAll(): Observable<any> {
        return super.getAll(this.route);
    }

    public store(form: any): Observable<any> {
        return super.store(this.formatForm(form), this.route);
    }

    public update(id: number, form: any): Observable<any> {
        return super.update(id, this.formatForm(form), this.route);
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route);
    }

    public getAllByAffair(affairId: number, research?: string): void {
        this.apiService.post(`${this.route}/affairs/search`, {
            affairId: affairId,
            search: research
        })
            .subscribe(
                (contacts: Contact[]) => {
                    this.contactsAffair$.next(contacts);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public getForSelect(): void {
        this.selectService.getForSelect(this.selectContacts$, this.route);
    }

    public getForSelectWithParams(params: ContactSelectParameters): void {
        this.selectService.getForSelectWithParams(this.contactsSelect$, this.route, params);
    }

    public applyFilters(selectedGroups: any, selectedBrands: any, selectedAgencies: any): void {
        this.contactsSelectParams = {
            selectedGroups: selectedGroups.map(x => x.value),
            selectedBrands: selectedBrands.map(x => x.value),
            selectedAgencies: selectedAgencies.map(x => x.value),
        };

        this.selectService.getForSelectWithParams(this.contactsSelect$, this.route, this.contactsSelectParams);
    }

    public getForSelectWorksSupervisor(params: ContactSelectParameters) {
        this.apiService.post(this.route + '/select-works-supervisors', params)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
            )
            .subscribe(
                (objects) => {
                    this.selectWorksSupervisors$.next(objects);
                }
            );
    }

    public getForm(contact?: Contact): FormGroup {
        const lastname = contact ?
            this.commonService.valueToFormString(contact.lastname) : this.commonService.getDefaultFormStringValue();
        const firstname = contact ?
            this.commonService.valueToFormString(contact.firstname) : this.commonService.getDefaultFormStringValue();
        const professionId = contact ?
            this.commonService.valueToFormSelect(contact.professionId) : this.commonService.getDefaultFormNullValue();
        const phone = contact ?
            this.commonService.valueToFormString(contact.phone) : this.commonService.getDefaultFormStringValue();
        const phoneSecondary = contact ?
            this.commonService.valueToFormString(contact.phoneSecondary) : this.commonService.getDefaultFormStringValue();
        const email = contact ?
            this.commonService.valueToFormString(contact.email) : this.commonService.getDefaultFormStringValue();
        const emailSecondary = contact ?
            this.commonService.valueToFormString(contact.emailSecondary) : this.commonService.getDefaultFormStringValue();
        const isArchived = contact ?
            this.commonService.valueToFormNumber(contact.isArchived) : this.commonService.getDefaultFormCheckboxValue();
        const archivingReason = contact ?
            this.commonService.valueToFormString(contact.archivingReason) : this.commonService.getDefaultFormStringValue();
        const groups = contact ?
            contact.groups.map(x => ({ value: x.id, label: x.name })) : [];
        const brands = contact ?
            contact.brands.map(x => ({ value: x.id, label: x.name })) : [];
        const agencies = contact ?
            contact.agencies.map(x => ({ value: x.id, label: x.name })) : [];
        return this.formBuilder.group({
            lastname: [lastname, Validators.required],
            firstname: [firstname],
            professionId: [professionId],
            phone: phone,
            phoneSecondary: phoneSecondary,
            email: email,
            emailSecondary: emailSecondary,
            isArchived: isArchived,
            archivingReason: archivingReason,
            groups: [groups],
            brands: [brands],
            agencies: [agencies, Validators.required],
            mlSocieties: new FormArray([])
        });
    }

    private formatForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.groups = this.formFormatterService.formatSelectMultipleToIntArray(values.groups);
        values.brands = this.formFormatterService.formatSelectMultipleToIntArray(values.brands);
        values.agencies = this.formFormatterService.formatSelectMultipleToIntArray(values.agencies);
        values.mlSocieties = this.formFormatterService.formatSelectMultipleToIntArray(this.formFormatterService.filterCheckedValues(values.mlSocieties), 'mlSocietyId')
        return values;
    }

    public formatToSelectArray(contacts: Contact[], specificKey?: string) {
        let contactsArray = [];
        contacts.forEach(contact => {
            let label = contact.fullName;
            if (specificKey && contact[specificKey]) {
                label = contact.fullName + ' - ' + contact[specificKey];
            }
            contactsArray.push({ label: label, value: contact.id })
        })
        return contactsArray;
    }

    public createPortalAccess(id: number): Observable<any> {
        return this.apiService.post(`${this.route}/access/portal`, {
            'contactId': id
        })
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
            )
    }

    public valueToFormSelectMultiple(values: any[]): any {
        if (values != null) {
            return values.map(x => ({ label: [x.lastname, x.firstname].filter(Boolean).join(" "), value: x.id }));
        }
        return null;
    }
}
