import {Component, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ApiService} from "@libs/api/src";
import {NotifService} from "@libs/services/src";
import {Delegator} from "../../types/delegator.type";
import {Set} from "../../types/set.type";

@Component({
  selector: 'eros-front-manager',
  templateUrl: './inspections-set-manager.component.html',
  styleUrls: ['./inspections-set-manager.component.css']
})
export class InspectionsSetManagerComponent implements OnInit {

  private _rawSets: Set[];
  private _sortedSets: Set[];
  public archiveStatus: string = 'all';
  public delegatorFilter: string = 'all';
  private _delegators: Delegator[];

  /**
   * Constructor of ManagerComponent
   * @param _http HttpClient
   * @param _apiService ApiService
   * @param _notifService NotifService
   */
  constructor(private _http: HttpClient, private _apiService: ApiService, private _notifService: NotifService) {
    this._sortedSets = [] as Set[];
    this._delegators = [] as Delegator[];
  }

  /**
   * Update the filters of the list of sets
   * @param status
   * @param delegator
   */
  public updateFilters(status: string, delegator: string): void {
    this.archiveStatus = status;
    this.delegatorFilter = delegator;

    if(this.archiveStatus === 'archived') {
      this._sortedSets = this._rawSets.filter(set => set.is_archived === true)
    }else if(this.archiveStatus === 'not-archived') {
      this._sortedSets = this._rawSets.filter(set => set.is_archived === false)
    }else{
      this._sortedSets = this._rawSets;
    }

    if(this.delegatorFilter !== 'all' && this.delegatorFilter !== null) {
      this._sortedSets = this._sortedSets.filter(set => set.id_delegataire === this.delegatorFilter)
    }

  }

  /**
   * Return the list of delegators
   */
  public get delegators(): Delegator[] {
    return this._delegators;
  }

  /**
   * Delete a set
   * @param idDelegataire id of the delegataire
   * @param setUid uid of the set
   */
  public delete(idDelegataire: string, setUid: string): void {
    this._http.delete<void>(environment.serviceEUrl + '/set/' + idDelegataire + '/' + setUid).subscribe({
      next: data => {
        this._notifService.showSuccessNotif(data);
        // removing the set of the list of sets
        for(let i = 0 ; i < this._sortedSets.length ; i++) {
          if(this._sortedSets[i].id_delegataire === idDelegataire && this._sortedSets[i].uid === setUid) {
            this._sortedSets.splice(i, 1);
          }
        }
        this._sortedSets = [... this._sortedSets];
      },
      error: err => {
        this._notifService.showErrorNotif(err.error.message);
      }
    });
  }

  /**
   * Archive a set
   * @param set set to archive
   */
  public archive(set: Set): void {
    this._http.patch<any>(environment.serviceEUrl + '/set/' + set.id_delegataire + '/' + set.uid + '/archive', {}).subscribe({
      next: data => {
        set.is_archived = !set.is_archived;
        this._notifService.showSuccessNotif(data.message);
      },
      error: err => {
        this._notifService.showErrorNotif(err.error.message);
      }
    });
  }

  /**
   * Return true if the existing sets are loading, otherwise false
   */
  public get isLoadingSets(): boolean {
    return this._rawSets === undefined;
  }

  /**
   * On init of component, load the existing sets
   */
  ngOnInit(): void {
    this._http.get<Set[]>(environment.serviceEUrl + '/set').subscribe({
      next: data1 => {
        this._apiService.get<Delegator[]>('/agencies/customers-types/authorized-representative').subscribe({
          next: data2 => {
            this._rawSets = [];
            for(let i = 0 ; i < data1.length ; i++) {
              for(let j = 0 ; j < data2.length ; j++) {
                if(data1[i].id_delegataire === data2[j].ref) {
                  this._rawSets.push(data1[i]);
                  this._rawSets[i].social_reason = data2[j].name;
                }
              }
            }
            // sort array by delegataire
            this._sortedSets = this._rawSets;
          }
        })
      }
    });
    this._apiService.get<Delegator[]>('/agencies/customers-types/authorized-representative').subscribe({
      next: data => {
        this._delegators = data;
      }
    });
  }

  /**
   * Return the value of _sortedSets
   */
  public get sortedSets(): any[] {
    return this._sortedSets;
  }

}
