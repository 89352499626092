import { PermissionService } from './../../../services/src/lib/permission.service';
import { RoleService } from './../../../services/src/lib/role.service';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from './../../../components/src/lib/components.module';
import { pgCardModule } from './../../../../@pages/components/card/card.module';
import { UserService } from './../../../services/src/lib/user.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersIndexComponent } from './users-index/users-index.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { RolesIndexComponent } from './roles-index/roles-index.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { PermissionsIndexComponent } from './permissions-index/permissions-index.component';
import { PermissionCreateEditComponent } from './permission-create-edit/permission-create-edit.component';
import { RoleCreateEditComponent } from './role-create-edit/role-create-edit.component';

@NgModule({
  imports: [
    CommonModule,
    pgCardModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    ColorPickerModule
  ],
  declarations: [
    UsersIndexComponent, 
    UserCreateComponent, 
    RolesIndexComponent, 
    UserEditComponent, 
    PermissionsIndexComponent, 
    PermissionCreateEditComponent, 
    RoleCreateEditComponent
  ],
  providers: [
    UserService,
    RoleService,
    PermissionService
  ]
})
export class UsersModule {}
