import { Injectable } from '@angular/core';
import { AffairMarketType } from '@libs/enum/src';

@Injectable()
export class CommonService {
    public marketTypeEnum = AffairMarketType;

    constructor() { }

    public valueToRadioFormString(value: any): string {
        if (value != null) {
            if (typeof value === 'boolean') {
                return value ? '1' : '0';
            } else if (!isNaN(Number(value))) {
                return value.toString();
            }
        }
        return this.getDefaultFormRadioValue();
    }

    public valueToCheckboxFormNumber(value: any): number {
        if (value != null) {
            if (typeof value === 'boolean') {
                return value ? 1 : 0;
            } else if (!isNaN(Number(value))) {
                return value;
            }
        }
        return this.getDefaultFormCheckboxValue();
    }

    public valueToFormString(value: any): string {
        if (value != null) {
            return value;
        }
        return this.getDefaultFormStringValue();
    }

    public valueToFormNumber(value: any): number {
        if (value != null) {
            return value;
        }
        return this.getDefaultFormNumberValue();
    }

    public valueToFormSelect(value: any): any {
        if (value != null) {
            return value;
        }
        return this.getDefaultFormNullValue();
    }

    public valueToFormSelectMultiple(values: any[]): any {
        if (values != null) {
            return values.map(x => ({ label: x.name, value: x.id }));
        }
        return this.getDefaultFormNullValue();
    }

    public valueToFormDate(value: any, doesReturnDefaultDate?: boolean): Date {
        if (value != null && value != "") {
            return new Date(value);
        } else {
            if (doesReturnDefaultDate) {
                return this.getDefaultFormDateValue();
            } else {
                return this.getDefaultFormNullValue();
            }
        }
    }

    public valueToFormTime(value: any, doesReturnDefaultDate?: boolean): any {
        if (value != null) {
            let dateTimeString = new Date().toDateString() + " " + value;
            return new Date(dateTimeString);
        } else {
            if (doesReturnDefaultDate) {
                return this.getDefaultFormDateValue();
            } else {
                return this.getDefaultFormNullValue();
            }
        }
    }

    public valueToFormColor(value: any): string {
        if (value != null) {
            return value;
        }
        return this.getDefaultFormColorValue();
    }

    public valueObjectToFormValue(object: any, attr: string): any {
        if (object != null && object[attr] != null) {
            return object[attr];
        }
        return this.getDefaultFormNullValue();
    }

    public getDefaultFormStringValue(): string {
        return '';
    }

    public getDefaultFormNumberValue(): number {
        return 0;
    }

    public getDefaultFormCheckboxValue(): number {
        return 0;
    }

    public getDefaultFormDateValue(): Date {
        return new Date();
    }

    public getDefaultFormRadioValue(): string {
        return '0';
    }

    public getDefaultFormNullValue(): any {
        return null;
    }

    public getDefaultFormColorValue(): string {
        return '#000000';
    }

    public getDefaultMarketTypeValue() {
        return this.marketTypeEnum.STANDALONE_HOUSE
    }

    public getDefaultFormVatRateValue(): any {
        return 20;
    }

    public getDefaultFormPayerPreferenceValue(): number {
        return 0;
    }

    public getDefaultPageIndexValue(): number {
        return 1;
    }

    public getDefaultPageSizeValue(): number {
        return 10;
    }

    public formatNumberToLocale(number: number): string {
        if (number) {
            return number.toLocaleString('fr-FR', {
                minimumIntegerDigits: 2,
                minimumFractionDigits: 2
            });
        }
        return '0';
    }
}
