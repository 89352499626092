import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { ModelWithDatatableAndCrud } from './classes/model-datatable-crud';
import { DataTableColumn } from './classes/model-datatable';
import { FormFormatterService } from './utilities/form-formatter.service';
import { CommonService } from './utilities/common.service';
import { catchError, finalize } from 'rxjs/operators';

export interface PaymentDatatableParameters {
    selectedAgencies: string;
    selectedPayers: string;
    selectedInvoices: string;
}

@Injectable()
export class PaymentService extends ModelWithDatatableAndCrud {

    private route = '/payments';
    public invoice$ = new BehaviorSubject<any>(undefined);
    public filters$ = new BehaviorSubject<any>(undefined);
    public invoicesThumbnails$ = new BehaviorSubject<any>(undefined);
    public payerForProduct$ = new BehaviorSubject<any>(undefined);
    public invoicesTempoByProduct$ = new BehaviorSubject<any>(undefined);
    public redirectUrl = '/payments/index';

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[], form?: FormGroup): void {
        super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns,
            pageLength: 25
        }, this.formToDatatableParameters(form));
    }

    public redrawDataTable(form: FormGroup): void {
        super.redrawDataTable(this.formToDatatableParameters(form));
    }

    public getDataTableFilters(): void {
        this.apiService.get(`${this.route}/list/selects`)
            .subscribe(
                (object) => {
                    this.filters$.next(object);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public getFiltersForm(formValue: any): FormGroup {
        const selectedAgencies = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAgencies) : this.commonService.getDefaultFormNullValue();
        const selectedInvoices = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedInvoices) : this.commonService.getDefaultFormNullValue();
        const selectedPayers = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedPayers) : this.commonService.getDefaultFormNullValue();
        return this.formBuilder.group({
            selectedAgencies: [selectedAgencies],
            selectedInvoices: [selectedInvoices],
            selectedPayers: [selectedPayers]
        });
    }


    public getForm(): FormGroup {
        return this.formBuilder.group({
            agencyId: [],
            payerId: [],
            paymentMode: [this.commonService.getDefaultFormNullValue(), Validators.required],
            date: [this.commonService.getDefaultFormDateValue(), Validators.required],
            ref: [this.commonService.getDefaultFormStringValue(), Validators.required],
            invoices: [[], Validators.required],
            amount: [this.commonService.getDefaultFormNumberValue(), Validators.required]
        });
    }

    public get(id: number): void {
        super.get(id, this.invoice$, this.route);
    }

    public store(form: FormGroup): void {
        super.store(this.formatForm(form), this.route, this.redirectUrl);
    }

    public update(id: number, form: FormGroup): void {
        super.update(id, this.formatForm(form), this.route);
    }

    public delete(id: number): void {
        super.delete(id, this.route);
    }

    public import(form: any): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        const formData = new FormData();
        formData.append('file', form.get('fileSource').value);
        return this.apiService.importFile(this.route + '/import', formData)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
    }

    private formToDatatableParameters(form: FormGroup): PaymentDatatableParameters {
        if (form) {
            return {
                selectedAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedAgencies),
                selectedPayers: this.formFormatterService.formatSelectMultiple(form.value.selectedPayers),
                selectedInvoices: this.formFormatterService.formatSelectMultiple(form.value.selectedInvoices),
            };
        }
        return null;
    }


    private formatForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.paymentMode = values.paymentMode ? this.formFormatterService.formatSelectSingle(values.paymentMode) : null;
        return values;
    }
}
