import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NotifService, ProgrammedStandardResponseService, SwalService } from '@libs/services/src';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-front-programmed-standards-responses-list',
  templateUrl: './programmed-standards-responses-list.component.html',
  styleUrls: ['./programmed-standards-responses-list.component.scss']
})
export class ProgrammedStandardsResponsesListComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public standardResponsesData: any[];
  private isDestroyed$ = new Subject<void>();
  public isLoading = false;
  public total = 1;
  public pageIndex = 1;
  public pageSize = 10;

  constructor(
    private programmedStandardResponseService: ProgrammedStandardResponseService,
    private notifService: NotifService,
    private swalService: SwalService,
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle(`Liste des envois programmés${tabSuffix}`);
    this.form = this.programmedStandardResponseService.initFiltersForm();
    this.initList();

    this.form.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$)
      )
      .subscribe(form => {
        this.initList();
      });
  }

  ngOnDestroy(): void {
    this.isDestroyed$?.next();
    this.isDestroyed$?.complete();
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    this.pageIndex = params.pageIndex;
    this.pageSize = params.pageSize;
    this.form.patchValue({
      page: this.pageIndex,
      size: this.pageSize
    })
  }

  onRedirectClicked(object: any) {
    if (object.type === 'intervention') {
      window.open(`interventions/${object.id}/edit`);
    } else if (object.type === 'affair') {
      window.open(`affairs/${object.id}/show`);
    }
  }

  onDeleteClicked(id: number) {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'La réponse programmée sera supprimée'
    });
    Swal.fire(swalOptions).then((result) => {
      that.programmedStandardResponseService.destroy(id).subscribe(
        (response) => {
          that.notifService.showSuccessNotif(response);
          that.initList();
        }
      )
    });
  }

  initList(): void {
    this.isLoading = true;
    this.programmedStandardResponseService.list(this.form)
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        (response) => {
          this.standardResponsesData = response.data;
          this.total = response.meta.total;
        }
      );
  }

  resetForm(): void {
    const resetForm = this.programmedStandardResponseService.initFiltersForm();
    this.form.reset(resetForm);
  }

  onRefreshClicked(): void {
    this.initList();
  }



}
