import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { FormBuilder, Validators, FormGroup, FormArray, FormControl } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { DataTableColumn } from './classes/model-datatable';
import { ModelWithDatatableAndModalCrud } from './classes/model-datatable-modal-crud';
import { Product } from '@eros-front/models';
import { CommonService } from './utilities/common.service';
import { SelectService } from './utilities/select.service';
import { AffairMarketType } from '@libs/enum/src';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ProductService extends ModelWithDatatableAndModalCrud {

    private route = '/products';
    public product$ = new BehaviorSubject<any>(undefined);
    public products$ = new BehaviorSubject<any>(undefined);
    public productsSelect$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService,
        private selectService: SelectService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): void {
        super.get(id, this.product$, this.route);
    }

    public getAll(): void {
        super.getAll(this.products$, this.route);
    }

    public store(form: any): void {
        super.store(this.formatForm(form), this.route);
    }

    public update(id: number, form: any): void {
        super.update(id, this.formatForm(form), this.route);
    }

    public delete(id: number): void {
        super.delete(id, this.route);
    }

    public getForm(product?: Product): FormGroup {
        const name = product ?
            this.commonService.valueToFormString(product.name) : this.commonService.getDefaultFormNullValue();
        const accountingCode = product ?
            this.commonService.valueToFormString(product.accountingCode) : this.commonService.getDefaultFormNullValue();
        const abbreviation = product ?
            this.commonService.valueToFormString(product.abbreviation) : this.commonService.getDefaultFormNullValue();
        const icon = product ?
            this.commonService.valueToFormString(product.icon) : this.commonService.getDefaultFormNullValue();
        const pcDescription = product ?
            this.commonService.valueToFormString(product.pcDescription) : this.commonService.getDefaultFormNullValue();
        const defaultStatusId = product ?
            this.commonService.valueToFormSelect(product.defaultStatusId) : this.commonService.getDefaultFormNullValue();
        const duration = product ?
            this.commonService.valueToFormNumber(product.duration) : this.commonService.getDefaultFormNumberValue();
        const designation = product ?
            this.commonService.valueToFormString(product.designation) : this.commonService.getDefaultFormNullValue();
        const vatRate = product ?
            this.commonService.valueToFormSelect(product.vatRate) : this.commonService.getDefaultFormNullValue();
        const kizeoFormId = product ?
            this.commonService.valueToFormString(product.kizeoFormId) : this.commonService.getDefaultFormNullValue();
        const isActive = product ?
            this.commonService.valueToRadioFormString(product.isActive) : this.commonService.getDefaultFormRadioValue();
        const isSecondary = product ?
            this.commonService.valueToRadioFormString(product.isSecondary) : this.commonService.getDefaultFormRadioValue();
        const isSmsToSend = product ?
            this.commonService.valueToRadioFormString(product.isSmsToSend) : this.commonService.getDefaultFormRadioValue();
        const isAffairInvoicing = product ?
            this.commonService.valueToRadioFormString(product.isAffairInvoicing) : this.commonService.getDefaultFormRadioValue();
        const color = product ?
            this.commonService.valueToFormColor(product.color) : this.commonService.getDefaultFormColorValue();
        const marketsTypes = product ?
            product.marketsTypes.map(x => ({ value: x.id, label: x.name })) : [];
        const affairsGoals = product ?
            product.affairsGoals.map(x => ({ value: x.id, label: x.name })) : [];
        return this.formBuilder.group({
            name: [name, Validators.required],
            accountingCode: [accountingCode, Validators.required],
            abbreviation: [abbreviation, Validators.required],
            icon: icon,
            pcDescription: pcDescription,
            defaultStatusId: defaultStatusId,
            duration: [duration],
            designation: [designation, Validators.required],
            vatRate: [vatRate, Validators.required],
            kizeoFormId: [kizeoFormId],
            isActive: isActive,
            isSecondary: isSecondary,
            isSmsToSend: isSmsToSend,
            isAffairInvoicing: isAffairInvoicing,
            color: color,
            marketsTypes: [marketsTypes],
            affairsGoals: [affairsGoals],
            documentsCustomers: new FormArray([]),
            documentsDelivered: new FormArray([]),
            fields: new FormArray([]),
            conformities: new FormArray([])
        });
    }

    initDocumentsCustomersList(form: FormGroup, product: Product) {
        const documentsCustomersFormArray = form.get('documentsCustomers') as FormArray;
        product.documentsCustomers.forEach((documentCustomer) => {
            const isNeeded = documentCustomer.pivot.isNeeded;
            documentsCustomersFormArray.push(new FormGroup({
                id: new FormControl(documentCustomer.id),
                name: new FormControl(documentCustomer.name),
                isNeeded: new FormControl(isNeeded)
            }));
        });
    }

    initDocumentsDeliveredList(form: FormGroup, product: Product) {
        const documentsCustomersFormArray = form.get('documentsDelivered') as FormArray;
        product.documentsDelivered.forEach((documentDelivered) => {
            const isNeeded = documentDelivered.pivot.isNeeded;
            documentsCustomersFormArray.push(new FormGroup({
                id: new FormControl(documentDelivered.id),
                name: new FormControl(documentDelivered.name),
                isNeeded: new FormControl(isNeeded)
            }));
        });
    }

    initFieldsList(form: FormGroup, product: Product) {
        const fieldsFormArray = form.get('fields') as FormArray;
        product.fields.forEach((field) => {
            const isRequired = field.pivot.isRequired;
            fieldsFormArray.push(new FormGroup({
                id: new FormControl(field.id),
                name: new FormControl(field.name),
                isRequired: new FormControl(isRequired)
            }));
        });
    }

    initConformitiesList(form: FormGroup, conformities: any, documentsDelivered: any, product: Product) {
        const conformitiesFormArray = form.get('conformities') as FormArray;
        conformities.forEach((conformity) => {
            let documentsDeliveredIds = [];
            let optionalDocumentsDeliveredIds = [];
            if (product) {
                let conformitySelected = product.conformities.find(x => x.id == conformity.id);
                if (conformitySelected != null) {
                    documentsDeliveredIds = documentsDelivered.filter(x => conformitySelected.pivot.documentsDeliveredIds.includes(x.value));
                    optionalDocumentsDeliveredIds = documentsDelivered.filter(x => conformitySelected.pivot.optionalDocumentsDeliveredIds.includes(x.value));
                }
            }
            conformitiesFormArray.push(new FormGroup({
                id: new FormControl(conformity.id),
                documentsDeliveredIds: new FormControl(documentsDeliveredIds),
                optionalDocumentsDeliveredIds: new FormControl(optionalDocumentsDeliveredIds),
            }));
        });
    }

    getForSelect() {
        this.selectService.getForSelect(this.productsSelect$, this.route);

    }

    getByMarketType(marketType: AffairMarketType): Observable<Product[]> {
        return this.apiService.get(`${this.route}/market-type/${marketType}`);
    }

    private formatForm(form: FormGroup) {
        const values = this.formFormatterService.createFormCopy(form);
        values.defaultStatusId = this.formFormatterService.formatSelectSingle(values.defaultStatusId);
        values.vatRate = this.formFormatterService.formatSelectSingle(values.vatRate);
        values.conformities.forEach((value) => {
            value.documentsDeliveredIds = this.formFormatterService.formatSelectMultipleToIntArray(value.documentsDeliveredIds);
            value.optionalDocumentsDeliveredIds = this.formFormatterService.formatSelectMultipleToIntArray(value.optionalDocumentsDeliveredIds);
        });
        values.marketsTypes = this.formFormatterService.formatSelectMultipleToIntArray(values.marketsTypes);
        values.affairsGoals = this.formFormatterService.formatSelectMultipleToIntArray(values.affairsGoals);
        return values;
    }

    public getSelectByMarketType(products: Product[], marketTypeId: number) {
        let productsSelect = [];
        products.forEach((product: Product) => {
            if (product.marketsTypes.find(x => x.id == marketTypeId)) {
                productsSelect.push({
                    label: product.abbreviation + '-' + product.name,
                    value: product.id
                })
            }
        })
        return productsSelect;
    }

    public formatToSelectMultiple(products: Product[]) {
        let productsSelect = [];
        products.forEach((product: Product) => {
            productsSelect.push({
                label: product.abbreviation + '-' + product.name,
                value: product.id
            })
        })
        return productsSelect;
    }

}
