import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable()
export class FormLocalStorageService {

  constructor() { }

  public store(key: string, form: FormGroup): void {
    localStorage.setItem(key, JSON.stringify(form.value));
  }

  public retrieve(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  public remove(key: string) {
    if (localStorage.getItem(key)) {
      localStorage.removeItem(key);
    }
  }

}
