import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { group } from '@angular/animations';
import { AgencyService } from '@eros-front/services';
import { SubmitButton } from '@eros-front/models';
import { GroupService } from '@eros-front/services';
import { CustomerService } from '@eros-front/services';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ThingService } from '@eros-front/services';
import { Subscription } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { BrandService } from '@eros-front/services';
import { flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-customers-consult-filters',
  templateUrl: './customers-consult-filters.component.html',
  styleUrls: ['./customers-consult-filters.component.scss']
})
export class CustomersConsultFiltersComponent implements OnInit, OnDestroy {
  // Region select
  public regions = [];
  public selectRegion$: Subscription;
  public selectedRegions: any[];
  // Department select
  public departments = [];
  public selectDepartment$: Subscription;
  public selectedDepartments: any[];
  // MarketType select
  public marketsTypes = [];
  public selectMarketType$: Subscription;
  public selectedMarketsTypes: any[];
  // CustomerType select
  public customersTypes = [];
  public selectCustomerType$: Subscription;
  public selectedCustomersTypes: any[];
  // CustomerType select
  public indicatorsCustomers = [];
  public selectIndicatorsCustomers$: Subscription;
  public selectedIndicatorsCustomers: any[];
  // Rival select
  public rivals = [];
  public selectRivals$: Subscription;
  public selectedRivals: any[];
  // Manager select
  public managers = [];
  public selectManagers$: Subscription;
  public selectedManagers: any[];
  // MyLink company select
  public myLinkCompanies = [];
  public selectMyLinkCompanies$: Subscription;
  public selectedMyLinkCompaniesWorkingWith: any[] = [];
  public selectedMyLinkCompaniesNotWorkingWith: any[] = [];
  //Others
  public form: FormGroup;
  public submitButton: SubmitButton;
  public clearIcon = faTimesCircle;
  public selectGroup$: Subscription;
  public selectBrand$: Subscription;

  public customerSelectFilters$: Subscription;


  constructor(
    private customerService: CustomerService,
    private thingService: ThingService,
    private groupService: GroupService,
    private brandService: BrandService,
    private agencyService: AgencyService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.initValues();
    this.initSubscriptions();
  }

  ngOnDestroy() {
    if (this.selectMyLinkCompanies$) {
      this.selectMyLinkCompanies$.unsubscribe();
    }
    if (this.selectGroup$) {
      this.selectGroup$.unsubscribe();
    }
    if (this.selectBrand$) {
      this.selectBrand$.unsubscribe();
    }
  }

  initValues() {
    this.customerService.getForFilters();
    this.thingService.getMyLinkCompanies();
    this.form = this.getFiltersForm();
    this.determinateSubmitButton();
    this.groupService.applyFilters(this.form);
  }

  initSubscriptions() {
    this.customerSelectFilters$ = this.customerService.customerSelectFilters$.subscribe(
      (filters) => {
        if (filters) {
          this.customersTypes = filters.customersTypes;
          this.departments = filters.departments;
          this.indicatorsCustomers = filters.indicatorsCustomers;
          this.managers = filters.managers;
          this.marketsTypes = filters.marketsTypes;
          this.regions = filters.regions;
          this.rivals = filters.rivals;
        }
      }
    );
    this.selectMyLinkCompanies$ = this.thingService.selectMyLinkCompanies$.subscribe(
      (data) => {
        if (data) {
          this.myLinkCompanies = data;
        }
      }
    );
    this.selectGroup$ = this.groupService.groupsSelect$.subscribe(
      (data) => {
        if (data) {
          if (this.isValidForm()) {
            this.brandService.applyFilters(this.form, data.selected);
          }
        }
      }
    );
    this.selectBrand$ = this.brandService.brandsSelect$.subscribe((data) => {
      if (data) {
        if (this.isValidForm()) {
          this.selectedRegions = this.form.value.selectedRegions;
          this.selectedDepartments = this.form.value.selectedDepartments;
          this.selectedMarketsTypes = this.form.value.selectedMarketsTypes;
          this.selectedCustomersTypes = this.form.value.selectedCustomersTypes;
          this.selectedIndicatorsCustomers = this.form.value.selectedIndicatorsCustomers;
          this.selectedRivals = this.form.value.selectedRivals;
          this.selectedManagers = this.form.value.selectedManagers;
          this.agencyService.applyFilters(this.form, data.selected);
        }
      }
    }
    );
  }

  private determinateSubmitButton() {
    let text = 'Appliquer';
    let icon = faCheck;
    this.submitButton = {
      text: text,
      originalText: text,
      icon: icon,
      originalIcon: icon,
      enabled: true
    };
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

  public getFiltersForm() {
    const isPilot = false;
    const selectedMyLinkCompaniesWorkingWith = [];
    const isWorkingWithStrictMode = false;
    const selectedMyLinkCompaniesNotWorkingWith = [];
    const isNotWorkingWithStrictMode = false;
    const lastAppointmentDateMin = false;
    const lastAppointmentDateMax = false;
    return this.formBuilder.group({
      selectedRegions: [this.selectedRegions],
      selectedDepartments: [this.selectedDepartments],
      selectedMarketsTypes: [this.selectedMarketsTypes],
      selectedCustomersTypes: [this.selectedCustomersTypes],
      selectedIndicatorsCustomers: [this.selectedIndicatorsCustomers],
      selectedRivals: [this.selectedRivals],
      selectedManagers: [this.selectedManagers],
      isPilot: [isPilot],
      selectedMyLinkCompaniesWorkingWith: [selectedMyLinkCompaniesWorkingWith],
      isWorkingWithStrictMode: [isWorkingWithStrictMode],
      selectedMyLinkCompaniesNotWorkingWith: [selectedMyLinkCompaniesNotWorkingWith],
      isNotWorkingWithStrictMode: [isNotWorkingWithStrictMode],
      lastAppointmentDateMin: [lastAppointmentDateMin],
      lastAppointmentDateMax: [lastAppointmentDateMax]
    });
  }

  submitForm() {
    if (this.isValidForm()) {
      this.groupService.applyFilters(this.form);
    }
  }

  clear() {
    this.selectedRegions = [];
    this.selectedDepartments = [];
    this.selectedMarketsTypes = [];
    this.selectedCustomersTypes = [];
    this.selectedIndicatorsCustomers = [];
    this.selectedRivals = [];
    this.selectedManagers = [];
    this.form.reset();
    this.form = this.getFiltersForm();
    this.groupService.applyFilters(this.form);
  }

}
