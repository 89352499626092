import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { SubmitButton } from '@libs/models/src';
import { ShuttleSheetBuildingTitle, ShuttleSheetEquipmentTitle, ShuttleSheetService, SubmitButtonService } from '@libs/services/src';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-shuttle-sheet-modal-title',
  templateUrl: './shuttle-sheet-modal-title.component.html',
  styleUrls: ['./shuttle-sheet-modal-title.component.scss']
})
export class ShuttleSheetModalTitleComponent implements OnInit, AfterViewInit {

  @Input() model: any;
  @Input() object: ShuttleSheetBuildingTitle | ShuttleSheetEquipmentTitle;
  @ViewChild('ShuttleSheetModalTitle', { static: false }) childModal: ModalDirective;
  public objectCopy: any = null;
  public submitButton: SubmitButton;
  public isLinkDivVisible = false;
  public selectItems = [
    {
      label: 'Droite',
      value: 'right'
    },
    {
      label: 'Gauche',
      value: 'left'
    }
  ];

  constructor(
    private shuttleService: ShuttleSheetService,
    private submitButtonService: SubmitButtonService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.shuttleService.setModalTitle(this.childModal);
  }

  onModalShown() {
    this.objectCopy = JSON.parse(JSON.stringify(this.object));
    this.determinateSubmitButton();
    this.shuttleService.setTitleSubmitButton(this.submitButton);
  }

  onModalHidden() {
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm()) {
      let found = false;
      let modelCopy = JSON.parse(JSON.stringify(this.model));
      for (let i = 0; i < modelCopy.buildings.length; i++) {
        if (modelCopy.buildings[i].key === this.objectCopy.key) {
          modelCopy.buildings[i] = { ...this.objectCopy };
          found = true;
          break;
        }
      }
      if (!found) {
        for (let i = 0; i < modelCopy.equipments.length; i++) {
          if (modelCopy.equipments[i].key === this.objectCopy.key) {
            modelCopy.equipments[i] = { ...this.objectCopy };
            break;
          }
        }
      }
      this.shuttleService.updateBaseModel(modelCopy);
    }
  }

  isValidForm() {
    return this.objectCopy != null && this.objectCopy.name.length > 0;
  }

  updateLinkDivVisibility() {
    this.isLinkDivVisible = !this.isLinkDivVisible;
  }

  private determinateSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      text: 'Mettre à jour',
      objectName: 'le titre',
      icon: faSave
    });
  }

}
