import { Component, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { AffairService, AffairProductService, NotifService, SubmitButtonService, SendSettingService, SwalService, ContactService } from '@eros-front/services';
import { AffairMarketType } from '@eros-front/enum';
import { Affair, AffairContact, AffairProduct, SubmitButton } from '@eros-front/models';
import { catchError, finalize } from 'rxjs/operators';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { AffairGoalService } from '@libs/services/src/lib/affair-goal.service';
import { AffairOfficeFileService } from '@libs/services/src/lib/affair-office-file.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AffairShowOfficeFileModalComponent } from '@libs/affairs/src/lib/affair-show/affair-show-office-file/affair-show-office-file-modal/affair-show-office-file-modal.component';
import { SendSettingTypeService } from '@libs/services/src/lib/send-setting-type.service';
import { AffairStatusService } from '@libs/services/src/lib/affair-status.service';
import { AffairProductModalAddComponent } from '@libs/affairs/src/lib/affair-product-modal/affair-product-modal-add/affair-product-modal-add.component';
import { AffairProductModalEditComponent } from '@libs/affairs/src/lib/affair-product-modal/affair-product-modal-edit/affair-product-modal-edit.component';
import Swal from 'sweetalert2';
import { AffairModalStandardResponseComponent } from '@libs/components/src/lib/affair-modal-standard-response/affair-modal-standard-response.component';
import { ContactsCallModalComponent } from '@libs/components/src/lib/contacts-call-modal/contacts-call-modal.component';

@Component({
  selector: 'app-affair-show-informations',
  templateUrl: './affair-show-informations.component.html',
  styleUrls: ['./affair-show-informations.component.scss']
})
export class AffairShowInformationsComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() affair: Affair;
  @Input() agenciesSelect: any[];
  @Input() worksCompanies: any[];
  @Input() mlAgenciesSelect: any[];
  @Input() affairContacts: AffairContact[];
  @Input() products = [];
  @Input() assistants: any[];
  @Input() techniciansItems: any[];
  @Input() productsStatuses = [];
  @Input() marketsTypes: any;
  @Input() affairsStatuses = [];
  @Input() affairsGoals: any;
  private affairProductEdit$: Subscription;
  private sendSettings$: Subscription;
  private sendSettingsTypes$: Subscription;
  public informationsForm: FormGroup;
  public selectedProducts = [];
  public editedAffairProduct: AffairProduct = null;
  public affairMarketTypeEnum = AffairMarketType;
  public submitButton: SubmitButton;
  public affairsGoalsSelect: any;
  public affairsStatusesSelect: any;
  public affairOfficeFileForm: FormGroup;
  public sendSettings: any[];
  public sendSettingsTypes: any[];
  public selectContacts: any[];

  constructor(
    private affairService: AffairService,
    private affairProductService: AffairProductService,
    private notifService: NotifService,
    private submitButtonService: SubmitButtonService,
    private affairGoalService: AffairGoalService,
    private affairStatusService: AffairStatusService,
    private affairOfficeFileService: AffairOfficeFileService,
    private modalService: NzModalService,
    private sendSettingService: SendSettingService,
    private sendSettingTypeService: SendSettingTypeService,
    private contactService: ContactService,
    private swalService: SwalService
  ) {
    this.initSubscriptions();
    this.determinateSubmitButton();
  }

  ngOnInit() {
    if (this.affair) {
      this.sendSettingService.getAll();
      this.sendSettingTypeService.getAll();
      this.selectContacts = this.contactService.formatToSelectArray(this.affair.agency.contacts, 'email');
      if (this.affairsGoals) {
        this.affairsGoalsSelect = this.affairGoalService.getSelectByMarketType(this.affairsGoals, this.affair.marketTypeId);
      }

      if (this.affairsStatuses) {
        this.affairsStatusesSelect = this.affairStatusService.getSelectByMarketType(this.affairsStatuses, this.affair.marketTypeId);
      }
      this.informationsForm = this.affairService.getInformationsForm(this.affair);
    }
  }

  ngOnDestroy() {
    this.affairProductEdit$.unsubscribe();
    this.sendSettings$.unsubscribe();
    this.sendSettingsTypes$.unsubscribe();
  }

  ngAfterViewInit() {
  }

  onSubmit() {
    if (this.informationsForm.valid) {
      this.affairService.update(this.affair.id, this.informationsForm)
        .pipe(
          catchError(error => {
            this.notifService.showErrorNotif(error);
            return throwError(error);
          }),
          finalize(() => {
            this.submitButtonService.setEnabled(this.submitButton);
          })
        )
        .subscribe(
          (success) => {
            this.notifService.showSuccessNotif(success);
            this.affairService.get(this.affair.id);
          }
        );
    }
  }

  onModalAffairProductShow() {
    const modalInstance = this.modalService.create({
      nzWidth: '60%',
      nzContent: AffairProductModalAddComponent,
      nzComponentParams: {
        affair: this.affair,
        products: this.products,
        techniciansItems: this.techniciansItems
      },
      nzStyle: { top: '2vh' }
    });
    modalInstance.afterClose.subscribe(() => {
      this.affairService.get(this.affair.id);
      this.affairProductService.getByAffairToInvoicing(this.affair.id);
    });
  }

  onShowModalEditModalOfficeFile() {
    this.affairOfficeFileForm = this.affairOfficeFileService.getForm(this.affair.affairOfficeFile);
    const modalInstance = this.modalService.create({
      nzContent: AffairShowOfficeFileModalComponent,
      nzComponentParams: {
        affairOfficeFile: this.affair.affairOfficeFile,
        sendSettings: this.sendSettings,
        sendSettingsTypes: this.sendSettingsTypes,
        contacts: this.selectContacts,
        form: this.affairOfficeFileForm
      },
      nzStyle: { top: 0 },
      nzWidth: '50%'
    })
    modalInstance.afterClose.subscribe(() => {
      this.affairOfficeFileForm = null;
      this.affairService.get(this.affair.id);
    })
  }

  onModalStandardResponseShow() {
    const modalInstance = this.modalService.create({
      nzTitle: 'Envoi d\'une réponse standardisée',
      nzWidth: '80%',
      nzContent: AffairModalStandardResponseComponent,
      nzComponentParams: {
        affair: this.affair,
        affairSendSettings: this.affair.affairOfficeFile.affairsSendSettings,
        affairContacts: this.affairContacts
      },
      nzStyle: { top: '2vh' }
    });
    modalInstance.afterClose.subscribe(() => {
      this.affairService.get(this.affair.id);
    });
  }

  onModalAffairProductEditShow(): void {
    const modalInstance = this.modalService.create({
      nzWidth: '60%',
      nzContent: AffairProductModalEditComponent,
      nzComponentParams: {
        affair: this.affair,
        products: this.products,
        techniciansItems: this.techniciansItems,
        productsStatuses: this.productsStatuses,
        selectedAffairProducts: this.affair.affairProducts
      },
      nzStyle: { top: '2vh' }
    });
    modalInstance.afterClose.subscribe(() => {
      this.affairService.get(this.affair.id);
      this.affairProductService.getByAffairToInvoicing(this.affair.id);
    });
  }

  onOpenCallModalClicked(): void {
    const modalInstance = this.modalService.create({
      nzWidth: '80%',
      nzContent: ContactsCallModalComponent,
      nzComponentParams: {
        affair: this.affair,
        affairContacts: this.affairContacts
      },
      nzStyle: { top: '2vh' }
    });
  }

  archive() {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'L\'affaire sera archivée'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.affairService.archive(that.affair.id).subscribe((success) => {
          that.notifService.showSuccessNotif(success);
          that.affairService.get(that.affair.id);
        });
      }
    });
  }

  unarchive() {
    const that = this;
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: 'L\'affaire sera restaurée'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        that.affairService.unarchive(that.affair.id).subscribe((success) => {
          that.notifService.showSuccessNotif(success);
          that.affairService.get(that.affair.id);
        });
      }
    });
  }

  onAddOfficeFile(): void {
    this.affairOfficeFileService.store(this.affair.id).subscribe(
      (data) => {
        this.affairService.get(this.affair.id);
        this.notifService.showSuccessNotif(data);
      }
    )
  }


  isValidForm(): boolean {
    return this.informationsForm.valid;
  }

  private initSubscriptions(): void {
    this.affairProductEdit$ = this.affairProductService.affairProduct$.subscribe(
      (affairProduct) => {
        if (affairProduct) {
          this.editedAffairProduct = affairProduct;
          this.affairProductService.showModalEdit();
        }
      }
    );
    this.sendSettings$ = this.sendSettingService.getAll().subscribe(
      (data) => {
        if (data) {
          this.sendSettings = data;
        }
      }
    )
    this.sendSettingsTypes$ = this.sendSettingTypeService.getAll().subscribe(
      (data) => {
        if (data) {
          this.sendSettingsTypes = data;
        }
      }
    )
  }


  private determinateSubmitButton(): void {
    let text = '';
    let icon = null;
    icon = faSave;
    text = "Valider les changements"
    this.submitButton = {
      text: text,
      originalText: text,
      icon: icon,
      originalIcon: icon,
      enabled: true
    };
    this.affairService.setSubmitButton(this.submitButton)
  }



}
