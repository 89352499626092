import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AgencyService, PayerService, SwalService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { InvoiceService } from '@libs/services/src/lib/invoice.service';
import { PaymentService } from '@libs/services/src/lib/payment.service';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { PaymentsImportModalComponent } from '../payments-import-modal/payments-import-modal.component';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-payments-index',
  templateUrl: './payments-index.component.html',
  styleUrls: ['./payments-index.component.scss']
})
export class PaymentsIndexComponent implements OnInit, OnDestroy, AfterViewInit {
  private agencies$: Subscription;
  private invoices$: Subscription;
  private payers$: Subscription;
  @Input() agencies: any[];
  @Input() invoices: any[];
  @Input() payers: any[];
  public form: FormGroup;
  public datatableSelector = 'payments-datatable';
  public columns: DataTableColumn[] = [
    {
      attr: 'ref',
      name: 'Réf',
      individualFilterInput: true
    },
    {
      attr: 'payerName',
      name: 'Payeur',
      orderable: false,
      searchable: false
    },
    {
      attr: 'dateFormatted',
      name: 'Date',
      searchColumn: 'date',
      individualFilterInput: true
    },
    {
      attr: 'amount',
      name: 'Montant',
      individualFilterInput: true
    },
    {
      attr: 'paidInvoices',
      name: 'Factures payées',
      orderable: false,
      searchable: false
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];
  private readonly storageKey = 'payments-filters-form';

  constructor(
    private titleService: Title,
    private paymentService: PaymentService,
    private storageService: FormLocalStorageService,
    private agencyService: AgencyService,
    private invoiceService: InvoiceService,
    private payerService: PayerService,
    private router: Router,
    private swalService: SwalService,
    private modalService: NzModalService
  ) {
    this.titleService.setTitle(`Liste des paiements${tabSuffix}`);
    this.initSubscriptions();
  }

  ngOnInit() {
    this.agencyService.getForSelect();
    this.invoiceService.getForSelect();
    const sessionFormValue = this.storageService.retrieve(this.storageKey);
    this.form = this.paymentService.getFiltersForm(sessionFormValue);
    this.form.valueChanges.subscribe(() => {
      this.storageService.store(this.storageKey, this.form);
      this.paymentService.redrawDataTable(this.form);
    });
  }

  ngOnDestroy() {
    this.agencies$.unsubscribe();
    this.invoices$.unsubscribe();
    this.payers$.unsubscribe();
  }

  ngAfterViewInit() {
    this.paymentService.initDataTable(this.datatableSelector, this.columns, this.form);
    this.triggerDtActions();
  }

  onCreateClicked() {
    this.router.navigateByUrl('/payment/create');
  }

  onImportClicked() {
    const modalInstance = this.modalService.create({
      nzContent: PaymentsImportModalComponent
    });
  }

  private triggerDtActions() {
    const that = this;
    $(`#${this.datatableSelector}`).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le paiement sera supprimé de manière irréversible et les factures passeront en statut "En attente Paiement"'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.paymentService.delete(id);
          }
        });
      }
    });
  }

  private initSubscriptions() {
    this.agencies$ = this.agencyService.agenciesSelect$.subscribe(
      (data) => {
        if (data) {
          this.agencies = data;
        }
      }
    )
    this.invoices$ = this.invoiceService.selectInvoices$.subscribe(
      (data) => {
        if (data) {
          this.invoices = data;
        }
      }
    )
    this.payers$ = this.payerService.selectPayers$.subscribe(
      (data) => {
        if (data) {
          this.payers = data;
        }
      }
    )

  }

}
