import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { SubmitButton, Field } from '@eros-front/models';
import { FieldService } from '@eros-front/services';

@Component({
  selector: 'app-settings-products-fields-modal',
  templateUrl: './settings-products-fields-modal.component.html',
  styleUrls: ['./settings-products-fields-modal.component.scss']
})
export class SettingsProductsFieldsModalComponent implements OnInit, AfterViewInit {

  @Input() field: Field;
  @Input() fieldsTypes: any;
  @Input() fieldsRules: any;
  @ViewChild('settingsAddEditField', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private fieldService: FieldService
  ) { }

  ngOnInit() {
    this.initValues();
  }

  ngAfterViewInit() {
    this.fieldService.setModalAddEdit(this.childModal);
  }

  onModalShown() {
    this.determinateSubmitButton();
    this.fieldService.setSubmitButton(this.submitButton);
    this.form = this.fieldService.getForm(this.field);
  }

  onModalHidden() {
    this.initValues();
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.field) {
        this.fieldService.update(this.field.id, this.form);
      } else {
        this.fieldService.store(this.form);
      }
    }
  }

  onFieldRuleChecked(event: any) {
    const rules = this.form.get('rules') as FormArray;
    if (event.target.checked) {
      rules.push(new FormControl(event.target.value));
    } else {
      for (let i  = 0; i < rules.controls.length; i++) {
        const rule = rules.controls[i];
        if (rule.value === event.target.value) {
          rules.removeAt(i);
          break;
        }
      }
    }
  }

  checkIfFormArrayContainsValue(value: string) {
    const rules = this.form.get('rules') as FormArray;
    for (let i  = 0; i < rules.controls.length; i++) {
      const rule = rules.controls[i];
      if (rule.value === value) {
        return true;
      }
    }
    return false;
  }

  private initValues() {
    this.field = null;
    this.form = null;
  }

  private determinateSubmitButton() {
    this.submitButton = this.fieldService.determinateSubmitButton(this.field, 'le champ');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
