import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { InterventionService } from '@libs/services/src';
import { OnChangeType } from 'ng-zorro-antd/core/types';

@Component({
  selector: 'app-dashboard-affairs-volume',
  templateUrl: './dashboard-affairs-volume.component.html',
  styleUrls: ['./dashboard-affairs-volume.component.scss']
})

export class DashboardAffairsVolumeComponent implements OnInit, OnChanges {

  @Input() statistics;
  @Input() index;
  public options: any;
  constructor(
  ) { }

  ngOnInit() {

  }

  ngOnChanges() {
    if (this.statistics && this.statistics.length > 0) {
      this.initOptions(this.statistics[this.index]);
    }
  }

  initOptions(data) {
    if (data) {
      this.options = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: 'Volume',
            type: 'pie',
            radius: '50%',
            data: data.series,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
    }

  }

}
