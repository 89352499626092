import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

@Injectable()
export class QuickviewService {
  constructor(private http: HttpClient) {}

  // Get from the API
  getNotes() {
    return this.http.get('assets/data/notes.json').map(res => res);
  }

  getUsers() {
    return this.http.get('assets/data/users.json').map(res => res);
  }

  getChatMessages() {
    return this.http.get('assets/data/messages.json').map(res => res);
  }
}
