import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-incoming-call-sheet-contact-informations',
  templateUrl: './incoming-call-sheet-contact-informations.component.html',
  styleUrls: ['./incoming-call-sheet-contact-informations.component.scss']
})
export class IncomingCallSheetContactInformationsComponent implements OnInit {
  @Input() contact: any;

  constructor() { }

  ngOnInit() {
  }

}
