import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProductUrgencyLevel, SubmitButton } from '@libs/models/src';
import { NotifService } from '@libs/services/src';
import { ProductUrgencyLevelService } from '@libs/services/src/lib/product-urgency-level.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-products-urgencies-levels-modal',
  templateUrl: './products-urgencies-levels-modal.component.html',
  styleUrls: ['./products-urgencies-levels-modal.component.scss']
})
export class ProductsUrgenciesLevelsModalComponent implements OnInit {
  @Input() productUrgencyLevel: ProductUrgencyLevel;
  @Input() labelsClasses: any;
  @Input() form: FormGroup;
  public submitButton: SubmitButton;
  public colorValue: string;

  constructor(
    private modal: NzModalRef,
    private productUrgencyLevelService: ProductUrgencyLevelService,
    private notifService: NotifService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.productUrgencyLevelService.setSubmitButton(this.submitButton);
    if (this.form) {
      this.colorValue = this.form.get('color').value
    }
  }


  submitForm() {
    if (this.isValidForm()) {
      if (this.productUrgencyLevel) {
        this.productUrgencyLevelService.update(this.productUrgencyLevel.id, this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      } else {
        this.productUrgencyLevelService.store(this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.productUrgencyLevelService.determinateSubmitButton(this.productUrgencyLevel, 'le niveau d\'urgence');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }


}
