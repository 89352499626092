import { Component, Input, OnInit } from '@angular/core';
import { StatisticModeEnum } from '@libs/enum/src';
import { ChartService, StatisticInterventionRevenueService, StatisticInterventionVolume } from '@libs/services/src';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-statistics-revenues-regions',
  templateUrl: './statistics-revenues-regions.component.html',
  styleUrls: ['./statistics-revenues-regions.component.scss']
})
export class StatisticsRevenuesRegionsComponent implements OnInit {
  public options: any;
  public statisticsData: any;
  private statsInterventionsRevenueRegions$: Subscription;
  @Input() displayMode: number;

  constructor(
    private statisticInterventionRevenueService: StatisticInterventionRevenueService,
    private chartService: ChartService
  ) { }

  ngOnInit() {
    this.statsInterventionsRevenueRegions$ = this.statisticInterventionRevenueService.statsInterventionsRevenueRegions$.subscribe(
      (data) => {
        if (data) {
          this.initBarChart(data);
          this.statisticsData = data;
        }
      }
    );
  }

  initBarChart(data: any) {
    if (data) {
      if (this.displayMode == StatisticModeEnum.YMINUSONE) {
        this.options = this.chartService.initYMinusOneVolumeOptions(data);
      } else {
        this.options = this.chartService.initRevenueOptions(data);
      }
    }
  }

}
