import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataTableColumn, UnpaidStatusService, SwalService } from '@eros-front/services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-settings-invoicing-unpaid-statuses-index',
  templateUrl: './settings-invoicing-unpaid-statuses-index.component.html',
  styleUrls: ['./settings-invoicing-unpaid-statuses-index.component.scss']
})
export class SettingsInvoicingUnpaidStatusesIndexComponent implements OnInit, AfterViewInit, OnDestroy {

  public datatableSelector = 'unpaid-statuses-datatable';
  public unpaidStatus: any = null;
  public unpaidStatus$: Subscription;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'color',
      name: 'Couleur',
      orderable: false,
      searchable: false
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];

  constructor(
    private unpaidStatusService: UnpaidStatusService,
    private swalService: SwalService
  ) { }

  ngOnInit() {
    this.initSubscriptions();
  }

  ngAfterViewInit() {
    this.unpaidStatusService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    this.unpaidStatus$.unsubscribe();
  }

  onShowModalAddEditUnpaidStatus() {
    this.unpaidStatusService.showModalAddEdit();
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#unpaid-statuses-datatable').on('click', 'button.btn-edit', function() {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.unpaidStatusService.get(id);
      }
    });
    $('#unpaid-statuses-datatable').on('click', 'button.btn-delete', function() {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le statut impayé sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.unpaidStatusService.delete(id);
          }
        });
      }
    });
  }

  private initSubscriptions() {
    this.unpaidStatus$ = this.unpaidStatusService.unpaidStatus$.subscribe(
      (unpaidStatus) => {
        if (unpaidStatus) {
          this.unpaidStatus = unpaidStatus;
          this.onShowModalAddEditUnpaidStatus();
        }
      }
    );
  }

}
