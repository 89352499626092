import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectMultipleComponent } from '@libs/components/src';
import { CustomerService, CustomersModalSync, NotifService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-customers-show-modal-sync-indicators-types',
  templateUrl: './customers-show-modal-sync-indicators-types.component.html',
  styleUrls: ['./customers-show-modal-sync-indicators-types.component.scss']
})
export class CustomersShowModalSyncIndicatorsTypesComponent extends CustomersModalSync implements OnInit, OnDestroy {

  @Input() source: any;
  @Input() entities: any;
  @ViewChild('consultGraphModalSyncIndicatorsTypesSelectEntities', { static: false }) childSelectEntities: NgSelectMultipleComponent;
  private custService: CustomerService;
  public selectedEntities: any[];
  public selectedMode: number;
  public syncMarketsTypes: boolean;
  public syncCustomersTypes: boolean;
  public syncIndicatorsCustomersTypes: boolean;
  public submitClicked = false;

  constructor(
    customerService: CustomerService,
    private notifService: NotifService,
    private modal: NzModalRef
  ) {
    super(customerService, 3);
    this.custService = customerService;
  }

  ngOnInit() {
    this.initValues();
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.previewResult$.unsubscribe();
  }

  onSelectedSourceChanged() {
    this.getPreviewResult();
  }

  isValidForm() {
    return (this.selectedEntities.length > 0
      && !isNaN(Number(this.selectedMode))
      && (this.syncMarketsTypes || this.syncCustomersTypes || this.syncIndicatorsCustomersTypes));
  }

  getPreviewResult() {
    if (this.isValidForm()) {
      this.custService.getPreviewOfSyncIndicatorsTypes({
        sourceRef: this.source.ref,
        entitiesRefs: this.selectedEntities.map(x => x.value),
        mode: this.selectedMode,
        syncMarketsTypes: this.syncMarketsTypes,
        syncCustomersTypes: this.syncCustomersTypes,
        syncIndicatorsCustomersTypes: this.syncIndicatorsCustomersTypes,
      });
    } else {
      this.previewResult = [];
    }
  }

  onApplyClicked() {
    if (this.isValidForm()) {
      this.submitClicked = true;
      this.custService.appySyncIndicatorsTypes({
        sourceRef: this.source.ref,
        entitiesRefs: this.selectedEntities.map(x => x.value),
        mode: this.selectedMode,
        syncMarketsTypes: this.syncMarketsTypes,
        syncCustomersTypes: this.syncCustomersTypes,
        syncIndicatorsCustomersTypes: this.syncIndicatorsCustomersTypes,
      }).subscribe((response) => {
        this.notifService.showSuccessNotif(response);
        this.submitClicked = false;
        this.modal.close();
      })
    }
  }

  private initValues() {
    this.selectedEntities = [];
    this.selectedMode = 0;
    this.syncMarketsTypes = false;
    this.syncCustomersTypes = false;
    this.syncIndicatorsCustomersTypes = false;
    this.selectedStep = 0;
    this.previewResult = [];
    if (this.childSelectEntities) {
      this.childSelectEntities.clearSelectedItems();
    }
  }

  private initSubscriptions() {
    this.previewResult$ = this.custService.previewOfSyncIndicatorsTypes$.subscribe(
      (data) => {
        if (data) {
          this.previewResult = data;
        }
      }
    );
  }

}
