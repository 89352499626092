import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { NotifService } from './utilities/notif.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { AffairDocument, AffairDocumentImportMultipleParam, AffairDocumentImportParam, AffairDocumentListParam } from './classes/affair-document';
import { Observable } from 'rxjs';
import { FormFormatterService } from './utilities/form-formatter.service';


@Injectable()
export class AffairDocumentCustomerService extends AffairDocument {

    private route = '/documents-customers';

    constructor(
        notifService: NotifService,
        apiService: ApiService,
        formBuilder: FormBuilder,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        formFormatterService: FormFormatterService
    ) {
        super(
            notifService,
            apiService,
            formBuilder,
            submitButtonService,
            swalService,
            formFormatterService
        );
    }

    public getAll(affairId: number, param: FormGroup): Observable<any> {
        return super.getAll(affairId, param, this.route);
    }

    public getFilters(affairId: number, showOthersCompanies: boolean): Observable<any>  {
        return super.getFilters(affairId, showOthersCompanies, this.route);
    }

    public getDropzones(affairId: number): void {
        super.getDropzones(affairId, this.route);
    }

    public import(affairDocumentImportParam: AffairDocumentImportParam): Observable<any> {
        return super.import(affairDocumentImportParam, this.route);
    }

    public importMultiple(affairDocumentImportParam: AffairDocumentImportMultipleParam): Observable<any> {
        return super.importMultiple(affairDocumentImportParam, this.route);
    }

    public rename(affairId: number, form: FormGroup): Observable<any> {
        return super.rename(affairId, form, this.route);
    }

    public delete(affairId: number, paths: string[]): Observable<any> {
        return super.delete(affairId, paths, this.route);
    }

    public identify(affairId: number, form: FormGroup): Observable<any> {
        return super.identify(affairId, form, this.route);
    }

    public resetDropzones() {
        this.dropzones$.next(null);
    }

    public resetDocuments() {
        this.documents$.next(null);
    }

}
