import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FieldService, ThingService, SwalService,DataTableColumn  } from '@eros-front/services';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { Field } from '@eros-front/models';

@Component({
  selector: 'app-settings-products-fields-index',
  templateUrl: './settings-products-fields-index.component.html',
  styleUrls: ['./settings-products-fields-index.component.scss']
})
export class SettingsProductsFieldsIndexComponent implements OnInit, AfterViewInit, OnDestroy {

  public datatableSelector = 'fields-datatable';
  public field: Field = null;
  public field$: Subscription;
  public fieldsTypes = [];
  public fieldsTypes$: Subscription;
  public fieldsRules = [];
  public fieldsRules$: Subscription;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'type',
      name: 'Type'
    },
    {
      attr: 'rules',
      name: 'Règles'
    },
    {
      attr: 'active',
      name: 'Actif',
      orderable: false,
      searchable: false
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];

  constructor(
    private fieldService: FieldService,
    private thingService: ThingService,
    private swalService: SwalService
  ) { }

  ngOnInit() {
    this.getValues();
    this.initSubscriptions();
  }

  ngAfterViewInit() {
    this.fieldService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    this.field$.unsubscribe();
    this.fieldsTypes$.unsubscribe();
    this.fieldsRules$.unsubscribe();
  }

  onShowModalAddEditField() {
    this.fieldService.showModalAddEdit();
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function() {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.fieldService.get(id);
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function() {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le champ sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.fieldService.delete(id);
          }
        });
      }
    });
  }

  private getValues() {
    this.thingService.getFieldsTypesSelect();
    this.thingService.getFieldsRulesSelect();
  }

  private initSubscriptions() {
    this.field$ = this.fieldService.field$.subscribe(
      (field) => {
        if (field) {
          this.field = field;
          this.onShowModalAddEditField();
        }
      }
    );
    this.fieldsTypes$ = this.thingService.fieldsTypes$.subscribe(
      (fieldsTypes) => {
        if (fieldsTypes) {
          this.fieldsTypes = fieldsTypes;
        }
      }
    );
    this.fieldsRules$ = this.thingService.fieldsRules$.subscribe(
      (fieldsRules) => {
        if (fieldsRules) {
          this.fieldsRules = fieldsRules;
        }
      }
    );
  }

}
