import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Affair, Building, BuildingType, Part } from '@libs/models/src';
import { BuildingPartService, BuildingService, BuildingTypeService, IndicatorInterventionTypeService, NotifService, SwalService } from '@libs/services/src';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AffairShowBuildingsModalAssociateComponent } from './affair-show-buildings-modal-associate/affair-show-buildings-modal-associate.component';
import { AffairShowBuildingsModalBuildingComponent } from './affair-show-buildings-modal-building/affair-show-buildings-modal-building.component';
import { AffairShowBuildingsModalPartComponent } from './affair-show-buildings-modal-part/affair-show-buildings-modal-part.component';

@Component({
  selector: 'app-affair-show-buildings',
  templateUrl: './affair-show-buildings.component.html',
  styleUrls: ['./affair-show-buildings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AffairShowBuildingsComponent implements OnInit, OnDestroy {

  @Input() affair: Affair;
  @Input() buildingObject: any;
  public currentPartIdHover = -1;
  public currentBuildingIdHover = -1;
  public buildingsTypes: BuildingType[] = [];
  public buildingsTypes$: Subscription;
  public selectedPart: Part = null;
  private part$: Subscription;
  public selectedBuilding: Building = null;
  public partForm: FormGroup;
  public buildingForm: FormGroup;
  public associateForm: FormGroup;
  public indicatorsInterventionsTypes$: Subscription;
  public indicatorsInterventionsTypes: any[];

  constructor(
    private partService: BuildingPartService,
    private buildingService: BuildingService,
    private buildingTypeService: BuildingTypeService,
    private swalService: SwalService,
    private notifService: NotifService,
    private modalService: NzModalService,
    private indicatorInterventionTypeService: IndicatorInterventionTypeService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.buildingTypeService.getAll();
    this.indicatorInterventionTypeService.getAllByMarketType(this.affair.marketTypeId).subscribe(
      (indicators) => {
        this.indicatorsInterventionsTypes = indicators;
      }
    );
  }

  ngOnDestroy() {
    this.buildingsTypes$.unsubscribe();
    if (this.part$) {
      this.part$.unsubscribe();
    }
  }

  onAddBuildingPartClicked() {
    this.selectedPart = null;
    this.onOpenModalAddEditPart();
  }

  onEditBuildingPartClicked(part: Part) {
    this.partService.get(part.id).subscribe(
      (part: Part) => {
        if (part) {
          this.selectedPart = part;
          this.onOpenModalAddEditPart();
        }
      }
    );
  }

  onOpenModalAddEditPart() {
    this.partForm = this.partService.getForm(this.affair, this.selectedPart);
    const modalInstance = this.modalService.create({
      nzContent: AffairShowBuildingsModalPartComponent,
      nzComponentParams: {
        form: this.partForm,
        affair: this.affair,
        part: this.selectedPart
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.refreshBuildings();
    })
  }


  onDeleteBuildingPartClicked(part: Part) {
    const swalOptions = this.swalService.getSwalDeleteOptions({
      text: 'La tranche sera supprimée.'
    });
    Swal.fire(swalOptions).then((result) => {
      this.partService.delete(part.id).subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.refreshBuildings();
        }
      );
    });
  }

  openModalAddEditBuilding() {
    this.buildingForm = this.buildingService.getForm({
      affair: this.affair,
      building: this.selectedBuilding,
      partId: this.selectedPart ? this.selectedPart.id : null
    });
    this.buildingService.initInterventionsTypesSwitches(this.buildingForm, this.indicatorsInterventionsTypes, this.selectedBuilding, this.affair);
    const modalInstance = this.modalService.create({
      nzContent: AffairShowBuildingsModalBuildingComponent,
      nzWidth: '70%',
      nzComponentParams: {
        form: this.buildingForm,
        affair: this.affair,
        buildingsTypes: this.buildingsTypes,
        building: this.selectedBuilding,
        part: this.selectedPart,
        indicatorsInterventionsTypes: this.indicatorsInterventionsTypes
      }
    });
    modalInstance.afterClose.subscribe(() => {
      this.refreshBuildings();
    });
  }


  onAddBuildingClicked(part?: Part) {
    this.selectedBuilding = null;
    this.selectedPart = part ? part : null;
    this.openModalAddEditBuilding();
  }

  onDeleteBuildingClicked(building: Building) {
    const swalOptions = this.swalService.getSwalDeleteOptions({
      text: 'Le bâtiment sera supprimé.'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.buildingService.delete(building.id).subscribe(
          (response) => {
            this.notifService.showSuccessNotif(response);
          }
        );
      }
    });
  }

  onEditBuildingClicked(building: Building) {
    this.buildingService.get(building.id).subscribe(
      (building) => {
        this.selectedBuilding = building;
        this.openModalAddEditBuilding();
      }
    );
  }

  onAssociateBuildingClicked(building: Building) {
    this.selectedBuilding = building;
    this.associateForm = this.buildingService.getAssociateForm(building.id);
    const modalInstance = this.modalService.create({
      nzContent: AffairShowBuildingsModalAssociateComponent,
      nzComponentParams: {
        affair: this.affair,
        building: building,
        form: this.associateForm
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.refreshBuildings();
    });
  }

  private refreshBuildings() {
    this.buildingService.getByAffair(this.affair.id);
    this.partService.getAllByAffair(this.affair.id);
    this.partService.getBuildingsByAffair(this.affair.id);
  }

  private initSubscriptions() {
    this.buildingsTypes$ = this.buildingTypeService.buildingsTypes$.subscribe(
      (types: BuildingType[]) => {
        if (types) {
          this.buildingsTypes = types;
        }
      }
    );
  }

}
