import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Affair, SubmitButton } from '@libs/models/src';
import { AffairProductService, NotifService, SubmitButtonService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-affair-product-modal-edit',
  templateUrl: './affair-product-modal-edit.component.html',
  styleUrls: ['./affair-product-modal-edit.component.scss']
})
export class AffairProductModalEditComponent implements OnInit {
  @Input() affair: Affair;
  @Input() products: any[];
  @Input() productsStatuses: any[];
  @Input() productsUrgenciesLevels: any[];
  @Input() techniciansItems: any[];
  @Input() selectedAffairProducts: any[];
  public form: FormGroup;
  public affairProducts: FormArray;
  public affairFields: FormArray;
  public submitButton: SubmitButton;
  public selectedProducts: any;


  constructor(
    private modal: NzModalRef,
    private affairProductService: AffairProductService,
    private formBuilder: FormBuilder,
    private submitButtonService: SubmitButtonService,
    private notifService: NotifService
  ) {

  }

  ngOnInit() {
    this.initFormValues();
    this.determinateSubmitButton();
    if (this.selectedAffairProducts && this.selectedAffairProducts.length > 0) {
      this.affairProducts = this.form.get('affairProducts') as FormArray;
      this.selectedAffairProducts.forEach(ap => {
        this.affairProducts.push(this.affairProductService.getForm(ap, this.techniciansItems));
      });
    }

  }

  private initFormValues() {
    if (this.affair) {
      this.affairProducts = new FormArray([]);
      this.affairFields = new FormArray([]);
      this.form = this.formBuilder.group({
        affairId: [this.affair.id, Validators.required],
        affairProducts: this.formBuilder.array([]),
        affairFields: this.formBuilder.array([])
      });
    }
  }


  public isValidForm(): boolean {
    if (this.form) {
      return this.form.valid;
    }
    return false;
  }

  submitForm() {
    if (this.isValidForm()) {
      this.affairProductService.updateMultiple(this.form).subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        },
        (error) => {
          this.notifService.showErrorNotif(error);
        }
      );;
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'un ou plusieurs code(s) produit(s)',
      text: 'Modifier',
      icon: faSave
    });
    this.affairProductService.setSubmitButton(this.submitButton);
  }


}
