import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ShuttleSheetService, ShuttleSheetTemplateService } from '@libs/services/src';
import { Subscription } from 'rxjs';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-settings-shuttle-sheets',
  templateUrl: './settings-shuttle-sheets.component.html',
  styleUrls: ['./settings-shuttle-sheets.component.scss']
})
export class SettingsShuttleSheetsComponent implements OnInit, OnDestroy {

  public baseModel: any = null;
  private baseModel$: Subscription;
  public templateModel: any = null;
  private templateModel$: Subscription;

  constructor(
    private titleService: Title,
    private shuttleService: ShuttleSheetService,
    private shuttleTemplateService: ShuttleSheetTemplateService,
  ) {
    this.titleService.setTitle(`Paramètres fiche navette${tabSuffix}`);
    this.initSubscriptions();
  }

  ngOnInit() {
    this.shuttleService.getBaseModel();
    this.shuttleTemplateService.getModel();
  }

  ngOnDestroy() {
    this.baseModel$.unsubscribe();
    this.templateModel$.unsubscribe();
  }

  private initSubscriptions() {
    this.baseModel$ = this.shuttleService.baseModel$.subscribe(
      (model) => {
        if (model) {
          this.baseModel = model;
        }
      }
    );
    this.templateModel$ = this.shuttleTemplateService.model$.subscribe(
      (model) => {
        if (model) {
          this.templateModel = model;
        }
      }
    );
  }

}
