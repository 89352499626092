import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InterventionService } from '@libs/services/src';

@Component({
  selector: 'app-intervention-edit-document-customer',
  templateUrl: './intervention-edit-document-customer.component.html',
  styleUrls: ['./intervention-edit-document-customer.component.scss']
})
export class InterventionEditDocumentCustomerComponent implements OnInit {

  @Input() intervention;

  constructor(
    private interventionService: InterventionService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  public importDocumentCustomer() {
    this.router.navigateByUrl('interventions/' + this.intervention.id + '/document-customer/import');

  }

}
