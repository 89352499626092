import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn, DatatableService } from './utilities/datatable.service';
import { ModelWithDatatableAndCrud } from './classes/model-datatable-crud';
import { SelectService } from './utilities/select.service';
import { ApiService } from '@libs/api/src';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';

@Injectable()
export class CustomerTypeService extends ModelWithDatatableAndCrudObservable {

    private route = '/customers-types';
    public customersTypes$ = new BehaviorSubject<any>(undefined);
    public selectCustomerType$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        apiService: ApiService,
        router: Router,
        private formBuilder: FormBuilder,
        private selectService: SelectService,
        private commonService: CommonService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }


    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route);
    }

    public getAll(): Observable<any> {
        return super.getAll(this.route);
    }

    public store(form: any): Observable<any> {
        return super.store(form.value, this.route);
    }

    public update(id: number, form: any): Observable<any> {
        return super.update(id, form.value, this.route);
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route);
    }

    public getForSelect(): void {
        this.selectService.getForSelect(this.selectCustomerType$, this.route);
    }

    public getForm(marketType: any) {
        const name = marketType ?
            marketType.name : this.commonService.getDefaultFormStringValue();
        const abbreviation = marketType ?
            marketType.abbreviation : this.commonService.getDefaultFormStringValue();
        const svgIcon = marketType ?
            marketType.svgIcon : this.commonService.getDefaultFormStringValue();
        const tllIcon = marketType ?
            marketType.tllIcon : this.commonService.getDefaultFormStringValue();
        return this.formBuilder.group({
            name: [name, Validators.required],
            abbreviation: [abbreviation, Validators.required],
            svgIcon: [svgIcon],
            tllIcon: [tllIcon]
        });
    }
}
