import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AffairProductService, SwalService } from '@libs/services/src';
import { CustomLocalStorageService } from '@libs/services/src/lib/utilities/custom-local-storage.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-affairs-products-finished-list',
  templateUrl: './affairs-products-finished-list.component.html',
  styleUrls: ['./affairs-products-finished-list.component.scss']
})
export class AffairsProductsFinishedListComponent implements OnInit, OnChanges {
  public affairProductsData: any = [];
  @Input() form: FormGroup;
  @Input() refreshList = false;
  @Output() isInit = new EventEmitter<any>();
  public listByProducts = [];
  public technicians: any[] = [];
  public techniciansSelect: any[] = [];
  private readonly storageKey = 'dashboard-exit-technicians-select';

  constructor(
    private affairProductService: AffairProductService,
    private swalService: SwalService,
    private localStorageService: CustomLocalStorageService,
  ) {
  }

  ngOnInit() {

    const selectTechniciansLocalStorage = this.localStorageService.retrieve(this.storageKey);
    this.techniciansSelect = selectTechniciansLocalStorage != null ? selectTechniciansLocalStorage : []
    this.initList();
    this.form.valueChanges.subscribe(form => {
      this.initList();
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['refreshList'] && changes['refreshList'].previousValue != changes['refreshList'].currentValue) {
      if (this.refreshList) {
        this.initList();
      }
    }
  }

  private initList() {
    this.affairProductService.initFinishedList(this.form)
      .pipe(
        catchError(error => {
          this.swalService.showSwalError(error);
          this.isInit.emit();
          return throwError(error);
        }),
      )
      .subscribe(
        (data) => {
          if (data) {
            this.affairProductsData = data;
            this.initTechniciansList();
            if (this.techniciansSelect && this.techniciansSelect.length > 0) {
              this.initListByProducts();
            }
            this.isInit.emit();
          }
        }
      )
  }

  onChangeTechnicians(technicianId: number, isChecked: boolean) {
    this.listByProducts = [];
    if (isChecked) {
      this.techniciansSelect.push(technicianId);
    } else {
      let index = this.techniciansSelect.indexOf(technicianId);
      this.techniciansSelect.splice(index, 1);
    }
    this.localStorageService.store(this.storageKey, this.techniciansSelect);
    this.initListByProducts();
  }

  initListByProducts() {
    this.listByProducts = JSON.parse(JSON.stringify(this.affairProductsData.affairsProducts));
    this.listByProducts.forEach(product => {
      product.data = product.data.filter((t) => this.techniciansSelect.includes(t.id));
    });
  }

  initTechniciansList() {
    this.technicians = [];
    this.affairProductsData.technicians.forEach(technician => {
      this.technicians.push({
        id: technician.id,
        initials: technician.initials,
        firstname: technician.firstname,
        color: technician.color,
        checked: this.techniciansSelect && this.techniciansSelect.length > 0 ? this.techniciansSelect.includes(technician.id) : false,
      })
    });
  }

  onAffairClicked(affairId: number): void {
    window.open('/affairs/' + affairId + '/show');
  }

}
