import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AffairMarketType } from '@libs/enum/src';
import { SelectModel } from '@libs/models/src';
import { AffairService, UserService } from '@libs/services/src';
import { CustomLocalStorageService } from '@libs/services/src/lib/utilities/custom-local-storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-affairs-tabs',
  templateUrl: './affairs-tabs.component.html',
  styleUrls: ['./affairs-tabs.component.scss']
})
export class AffairsTabsComponent implements OnInit, OnDestroy {

  public affairsSelect: any[];
  public mlAgencies: any[];
  public agencies: any[];
  public worksCompanies: any[];
  public assistants: any[];
  public technicians: any[];
  public products: any[];
  public productsStatuses: any[];
  private affairSelects$: Subscription;
  public selectedMarketsTypesIds = [];
  public marketTypeEnum = AffairMarketType;
  public affairsStatuses: any[];
  public affairsGoals: any[];
  public affairsMonitoringTypes: any[];
  public performances: SelectModel[];
  public activeTab: number;

  constructor(
    private affairService: AffairService,
    private localStorage: CustomLocalStorageService,
    private route: ActivatedRoute
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.affairService.getSelects();
    this.selectedMarketsTypesIds = this.localStorage.getSelectedMarketsTypesIds();
  }

  ngOnDestroy() {
    this.affairSelects$.unsubscribe();
  }

  private initSubscriptions() {
    this.affairSelects$ = this.affairService.affairSelects$.subscribe(
      (data) => {
        if (data) {
          this.affairsSelect = data;
          this.assistants = data.assistants;
          this.technicians = data.technicians;
          this.agencies = data.agencies;
          this.worksCompanies = data.worksCompanies;
          this.mlAgencies = data.mlAgencies;
          this.products = data.products;
          this.productsStatuses = data.productsStatuses;
          this.affairsStatuses = data.affairsStatuses;
          this.affairsGoals = data.affairsGoals;
          this.affairsMonitoringTypes = data.affairsMonitoringTypes;
          this.performances = data.performances;
        }
      }
    );
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = null
      if (params.has('activeTab')) {
        id = Number(params.get('activeTab'))
      }
      if (!isNaN(id) && id != null) {
        this.activeTab = id;
      }
    });

  }

  public isDisplayTab(marketTypeId) {
    return this.selectedMarketsTypesIds.includes(marketTypeId);
  }

}
