export class AffairPayerCustomer {

  static readonly AFFAIR = new AffairPayerCustomer(0, 'Affaire');
  static readonly MONTH = new AffairPayerCustomer(1, 'Mois');
  static readonly UNIT = new AffairPayerCustomer(1, 'Lot');
  static readonly ALL = [
    AffairPayerCustomer.AFFAIR,
    AffairPayerCustomer.MONTH,
    AffairPayerCustomer.UNIT
  ];

  private constructor(
    private readonly key: number,
    public readonly text: string
  ) { }

  toString() {
    return this.key;
  }

}
