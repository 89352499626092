export class ArrayTool {

    public static findVal(object, key) {
        const that = this;
        var value;
        Object.keys(object).some(function (k) {
            if (k === key) {
                value = object[k];
                return true;
            }
            if (object[k] && typeof object[k] === 'object') {
                value = that.findVal(object[k], key);
                return value !== undefined;
            }
        });
        return value;
    }
}