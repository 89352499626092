import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthentificationService } from '@libs/auth/src';
import { InterventionService, SwalService, UserService } from '@libs/services/src';
import { DashboardService } from '@libs/services/src/lib/dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, throwError, zip } from 'rxjs';
import { EchoService } from 'angular-laravel-echo';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import Echo from 'laravel-echo';
import * as io from 'socket.io-client'
// Configs
import { environment } from '../../environments/environment';
import { LocalStorageService } from 'ngx-webstorage';
import { CustomLocalStorageService } from '@libs/services/src/lib/utilities/custom-local-storage.service';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { PhoneLineService } from '@libs/services/src/lib/phone-line.service';
import { Title } from '@angular/platform-browser';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public userInfos: any;
  public form: FormGroup;
  private usersSelect$: Subscription;
  public users: any[];
  public days = [
    {
      'label': '7 jours',
      'value': 7
    },
    {
      'label': '15 jours',
      'value': 15
    },
    {
      'label': '30 jours',
      'value': 30
    }
  ];
  public defaultDayNumber = {
    'label': '7 jours',
    'value': 7
  }
  echo: Echo;
  public categories = [];
  public selectedMarketsTypes = [];
  public readonly storageKey = 'dashboard-form';
  public statsCompleted: any;
  public statsAffairProducts: any;
  public statsUnconfirmed: any;
  public statsUncompleted: any;
  public dashboardVolume: any;

  constructor(
    private titleService: Title,
    private authService: AuthentificationService,
    private userService: UserService,
    private dashboardService: DashboardService,
    private localStorageService: CustomLocalStorageService,
    private interventionService: InterventionService,
    private swalService: SwalService,
    private storageService: FormLocalStorageService,
    private phoneLineService: PhoneLineService
  ) {
    this.titleService.setTitle(`Accueil${tabSuffix}`);
    this.initSubsciptions();
  }

  ngOnInit() {
    this.userInfos = this.authService.getUserInfos();
    this.selectedMarketsTypes = this.localStorageService.getSelectedMarketsTypes();
    const selectedMarketsTypesIds = this.selectedMarketsTypes.map(x => x.id);
    const storageFormValue = this.storageService.retrieve(this.storageKey);

    if (storageFormValue != null) {
      storageFormValue.userId = this.userInfos.id;
      this.form = this.dashboardService.getForm(storageFormValue, selectedMarketsTypesIds);
      this.formValueChanges();
    } else {
      const filtersValues = {
        userId: this.userInfos.id,
        dayNumber: this.defaultDayNumber,
      }
      this.form = this.dashboardService.getForm(filtersValues, selectedMarketsTypesIds);
      this.formValueChanges();

    }
    this.userService.getUsersSelect();
    this.initStatistics();

    /*const SocketIoClient = io;
    try {
      this.echo = new Echo({
        broadcaster: 'socket.io',
        host: environment.webUrl,
        client: SocketIoClient,
        auth: {
          headers: {
            Authorization: 'Bearer ' + `${this.authService.getToken()}`
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
    this.echo.channel('dashboard-events')
      .listen('.intervention-event', () => {
        this.initStatistics();
      })
      .listen('.affair-product-event', () => {
        this.initStatistics();
      })*/
  }

  private formValueChanges() {
    this.form.valueChanges.subscribe(form => {
      this.storageService.store(this.storageKey, this.form);
      this.initStatistics();
    });
  }

  private initStatistics() {
    zip(
      this.interventionService.initCompletedList(this.form),
      this.interventionService.initAffairProductsList(this.form),
      this.interventionService.initUnconfirmedList(this.form),
      this.interventionService.initUncompletedList(this.form),
      this.interventionService.dashboardVolume(this.form)
    )
      .pipe(
        catchError(error => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
        finalize(() => {
        })
      )
      .subscribe(([statsCompleted, statsAffairProducts, statsUnconfirmed, statsUncompleted, dashboardVolume]) => {
        this.statsCompleted = statsCompleted;
        this.statsAffairProducts = statsAffairProducts;
        this.statsUnconfirmed = statsUnconfirmed;
        this.statsUncompleted = statsUncompleted;
        this.dashboardVolume = dashboardVolume;
      })
  }


  ngOnDestroy() {
    this.usersSelect$.unsubscribe();
  }

  initSubsciptions() {
    this.usersSelect$ = this.userService.usersSelect$.subscribe(
      (data) => {
        if (data) {
          this.users = data;
        }
      }
    )
  }

  private stopLoading() {

  }

}
