import { Component, Input, OnInit } from '@angular/core';
import { Affair, AffairProduct } from '@libs/models/src';
import { AffairProductService, AffairService, SwalService } from '@libs/services/src';
import { NzModalService } from 'ng-zorro-antd/modal';
import Swal from 'sweetalert2';
import { AffairProductModalEditComponent } from '../../affair-product-modal/affair-product-modal-edit/affair-product-modal-edit.component';
import { AffairProductModifyModalComponent } from '../../affair-product-modal/affair-product-modify-modal/affair-product-modify-modal.component';

@Component({
  selector: 'app-affair-form-affair-products',
  templateUrl: './affair-form-affair-products.component.html',
  styleUrls: ['./affair-form-affair-products.component.scss']
})
export class AffairFormAffairProductsComponent implements OnInit {
  @Input() affair: Affair;
  @Input() products: any;
  @Input() productsStatuses: any;
  @Input() techniciansItems: any;

  constructor(
    private modalService: NzModalService,
    private affairService: AffairService,
    private affairProductService: AffairProductService,
    private swalService: SwalService
  ) { }

  ngOnInit() {
  }

  onModalAffairProductEditShow() {
    const modalInstance = this.modalService.create({
      nzWidth: '60%',
      nzContent: AffairProductModalEditComponent,
      nzComponentParams: {
        affair: this.affair,
        products: this.products,
        techniciansItems: this.techniciansItems,
        productsStatuses: this.productsStatuses,
        selectedAffairProducts: this.affair.affairProducts
      },
      nzStyle: { top: '2vh' }
    });
    modalInstance.afterClose.subscribe(() => {
      this.affairService.get(this.affair.id);
      this.affairProductService.getByAffairToInvoicing(this.affair.id);
    });
  }

  deleteAffairProduct(id: number) {
    const swalOptions = this.swalService.getSwalDeleteOptions({
      text: 'Le produit sera supprimé de manière irréversible.'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.affairProductService.delete(id).subscribe(
          (success) => {
            this.swalService.showSwalSuccess(success);
            this.affairService.get(this.affair.id);
            this.affairProductService.getByAffairToInvoicing(this.affair.id);
          }
        );
      }
    });
  }

  modifyAffairProduct(affairProduct: AffairProduct) {
    const modalInstance = this.modalService.create({
      nzContent: AffairProductModifyModalComponent,
      nzComponentParams: {
        affair: this.affair,
        products: this.products,
        affairProduct: affairProduct
      }
    });
    modalInstance.afterClose.subscribe(() => {
      this.affairService.get(this.affair.id);
      this.affairProductService.getByAffairToInvoicing(this.affair.id);
    });

  }

}
