import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthentificationService } from '@libs/auth/src';
import { ToggleService, UserService } from '@libs/services/src';
import { DashboardService } from '@libs/services/src/lib/dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard-settings',
  templateUrl: './dashboard-settings.component.html',
  styleUrls: ['./dashboard-settings.component.scss']
})
export class DashboardSettingsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() users: any[];
  @Input() days: any[];
  public isOpen = false;

  constructor(
    private toggleService: ToggleService
  ) { }

  ngOnInit() {
    this.toggleService.quickViewToggle.subscribe(data => {
      this.toggle();
    });
  }

  toggle() {
    if (this.isOpen) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }
  }


}
