import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'custom-spinner',
  templateUrl: './custom-spinner.component.html',
  styleUrls: ['./custom-spinner.component.scss']
})
export class CustomSpinnerComponent implements OnInit {
  @Input() name = "";
  @Input() text;
  @Input() fullScreen = true;
  @Input() type = 'ball-clip-rotate-multiple';
  @Input() bdColor = "rgba(0, 0, 0, 0.8)";
  @Input() color = '#fff'
  public types = [
    'ball-clip-rotate-multiple',
    'timer',
    'fire',
    'ball-spin-clockwise'
  ]

  constructor() { }

  ngOnInit() {
  }

  ramdomType() {
    const randomElement = this.types[Math.floor(Math.random() * this.types.length)];
    return randomElement

  }

}
