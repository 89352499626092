import { map, filter } from 'rxjs/operators';
import { RoleService, MlAgencyService, FormFormatterService, UserService } from '@eros-front/services';
import { SubmitButton, User } from '@eros-front/models';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Subscription, BehaviorSubject, zip } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { tabSuffix } from '_config/tab-suffix';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'eros-front-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit, OnDestroy {
  public userId = null;
  public user$: Subscription;
  public user: User = null;
  public form: FormGroup;
  public submitButton: SubmitButton;
  public roles: any;
  public mlAgencies: any;
  public selectedMlAgencies: any[];

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private userService: UserService,
    private roleService: RoleService,
    private mlAgencyService: MlAgencyService,
    private spinner: NgxSpinnerService
  ) {
    this.titleService.setTitle(`Édition d'un utilisateur${tabSuffix}`);
  }

  ngOnInit() {
    this.roleService.getAll();
    this.mlAgencyService.getForSelect();
    this.initSubscriptions();
    this.spinner.show('load-user');
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userId = params.get('id');
      this.userService.get(this.userId);
    });
  }

  ngOnDestroy() {
    this.userService.reset();
    this.user$.unsubscribe();
  }

  private initSubscriptions() {
    this.user$ =
      zip(
        this.userService.user$,
        this.roleService.roles$,
        this.mlAgencyService.mlAgenciesSelect$,
      ).subscribe(([userData, roleData, mlAgencyData]) => {
        if (userData) {
          this.user = userData;
          this.form = this.userService.getEditForm(this.user);
          this.determinateSubmitButton();
          this.userService.setSubmitButton(this.submitButton);
        }
        if (roleData) {
          this.roles = roleData;
        }
        if (mlAgencyData) {
          this.mlAgencies = mlAgencyData;
        }
        if (userData && roleData && mlAgencyData) {
          this.spinner.hide('load-user');
          this.addCheckboxes();
          this.selectedMlAgencies = this.mlAgencies.filter(x => this.user.mlAgencies.map(x => x.id).includes(x.value));
          this.form.controls.selectedMlAgencies.patchValue(this.selectedMlAgencies);
        }

      })
  }


  isInvalidField(fieldName: string) {
    const field = this.form.get(fieldName);
    return (field.dirty || field.touched) && field.invalid && field.errors.required;
  }

  isValidForm(): boolean {
    return this.form.valid;
  }

  submitForm() {
    if (this.isValidForm()) {
      this.form.value.roles = this.form.value.roles.map((v, i) => v ? this.roles[i].id : null).filter(v => v !== null);
      this.form.value.selectedMlAgencies = this.form.value.selectedMlAgencies.map(x => x.value),
        this.userService.update(this.userId, this.form);
    }
  }

  get rolesFormArray() {
    return this.form.controls.roles as FormArray;
  }

  private addCheckboxes() {
    this.roles.forEach((role) => {
      const checked = this.user.roles.map(x => x.id).includes(role.id);
      this.rolesFormArray.push(new FormControl(checked))
    });
  }

  private determinateSubmitButton() {
    this.submitButton = this.userService.determinateSubmitButton(this.user, 'l\'utilisateur');
  }

}
