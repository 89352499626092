import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PhoneLine, User } from '@libs/models/src';
import { NotifService, SelectService, SwalService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { PhoneLineService } from '@libs/services/src/lib/phone-line.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { PhonesLinesModalComponent } from '../phones-lines-modal/phones-lines-modal.component';

@Component({
  selector: 'app-phones-lines-index',
  templateUrl: './phones-lines-index.component.html',
  styleUrls: ['./phones-lines-index.component.scss']
})
export class PhonesLinesIndexComponent implements OnInit {
  public datatableSelector = 'phones-lines-datatable';
  public form: FormGroup;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID'
    },
    {
      attr: 'name',
      name: 'Nom'
    },
    {
      attr: 'billingAccount',
      name: 'Compte de ligne'
    },
    {
      attr: 'line',
      name: 'N° Ligne'
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false
    }
  ];
  public phoneLine: PhoneLine = null;
  private phoneLine$: Subscription;
  public users: User[];
  private users$: Subscription;

  constructor(
    private phoneLineService: PhoneLineService,
    private swalService: SwalService,
    private modalService: NzModalService,
    private selectService: SelectService
  ) { }

  ngOnInit() {
    this.initSubscriptions();
    this.selectService.getAssistantsSelect();
  }

  ngAfterViewInit() {
    this.phoneLineService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy() {
    if (this.phoneLine$) {
      this.phoneLine$.unsubscribe();
    }
  }

  onShowModalAddEdit() {
    this.form = this.phoneLineService.getForm(this.phoneLine);
    const modalInstance = this.modalService.create({
      nzWidth: '70%',
      nzContent: PhonesLinesModalComponent,
      nzComponentParams: {
        phoneLine: this.phoneLine,
        form: this.form,
        users: this.users
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.phoneLine = null;
    })
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.phoneLine$ = that.phoneLineService.get(id).subscribe(
          (phoneLine) => {
            if (phoneLine) {
              that.phoneLine = phoneLine;
              that.onShowModalAddEdit();
            }
          }
        );
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'La ligne téléphonique sera supprimée de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.phoneLineService.delete(id).subscribe(
              (success) => {
                this.notifService.showSwalSuccess(success);
              }
            );
          }
        });
      }
    });
  }

  private initSubscriptions() {
    this.users$ = this.selectService.selectAssistants$.subscribe(
      (data) => {
        if (data) {
          this.users = data;
        }
      }
    )
  }

}
