import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-affair-product-array',
  templateUrl: './affair-product-array.component.html',
  styleUrls: ['./affair-product-array.component.scss']
})
export class AffairProductArrayComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() techniciansItems;
  @Input() affairProducts;
  @Input() formArrayName;
  @Input() productsStatuses;
  @Input() productsUrgenciesLevels = [];
  @Input() isEditMode = false;

  constructor() { }

  ngOnInit() {
  }

}
