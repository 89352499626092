import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { SubmitButton } from '@libs/models/src';
import { MarketType } from '@libs/models/src/lib/market-type.model';
import { AgencyService, AutocompleteOptions, CommonService, FormFormatterService, MarketTypeService, MlAgencyService, PayerService, ProductService, SubmitButtonService, ThingService } from '@libs/services/src';
import { QuotationDetailService } from '@libs/services/src/lib/quotation-detail.service';
import { QuotationService } from '@libs/services/src/lib/quotation.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Subscription } from 'rxjs';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-quotation-manual-create',
  templateUrl: './quotation-manual-create.component.html',
  styleUrls: ['./quotation-manual-create.component.scss']
})
export class QuotationManualCreateComponent implements OnInit, OnDestroy {
  public stepIndex = 0;
  public informationsForm: FormGroup;
  public addProductsForm: FormGroup;
  public marketsTypes$: Subscription;
  public marketsTypes: any[];
  private mlAgencies$: Subscription;
  public mlAgencies;
  public comment = '';
  public submitButton: SubmitButton;

  constructor(
    private titleService: Title,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private marketTypeService: MarketTypeService,
    private mlAgencyService: MlAgencyService,
    private thingService: ThingService,
    private productService: ProductService,
    private quotationService: QuotationService,
    private quotationDetailService: QuotationDetailService,
    private submitButtonService: SubmitButtonService
  ) {
    this.titleService.setTitle(`Création d'un devis${tabSuffix}`);
    this.initSubscriptions();
  }

  ngOnInit() {
    this.initValues();
    this.informationsForm = this.getInformationsForm();
    this.addProductsForm = this.getAddProductsForm();
    this.setSubmitButton();
  }

  ngOnDestroy() {
    this.marketsTypes$.unsubscribe();
    this.mlAgencies$.unsubscribe();
  }


  isValidInformationsForm() {
    return this.informationsForm.valid;
  }

  isValidAddProductsForm() {
    return this.addProductsForm.valid && this.getFormControls(this.addProductsForm, 'products').length > 0;
  }

  onNextInformationsClicked() {
    if (this.isValidInformationsForm()) {
      this.stepIndex++;
    }
  }

  areValidForms() {
    return this.isValidInformationsForm() && this.isValidAddProductsForm();
  }

  //Products

  addProductRow() {
    let products = this.addProductsForm.get('products') as FormArray;
    products.push(this.quotationDetailService.getManualCreateForm());
  }

  onPreviousAddProductsClicked() {
    this.stepIndex--;
  }

  onNextAddProductsClicked() {
    if (this.isValidAddProductsForm()) {
      this.stepIndex++;
    }
  }

  // Summary

  onPreviousSummaryClicked() {
    this.stepIndex--;
  }

  getTotalWithoutTaxes() {
    let total = 0;
    const formArray = this.addProductsForm.get('products') as FormArray;
    for (let i = 0; i < formArray.controls.length; i++) {
      const control = formArray.controls[i];
      total += (control.value.unitPrice * control.value.quantity);
    }
    return this.commonService.formatNumberToLocale(total);
  }

  getVatAmount() {
    let total = 0;
    const formArray = this.addProductsForm.get('products') as FormArray;
    for (let i = 0; i < formArray.controls.length; i++) {
      const control = formArray.controls[i];
      const price = (control.value.unitPrice * control.value.quantity);
      total += (price / 100) * control.value.vatRate;
    }
    return this.commonService.formatNumberToLocale(total);
  }

  getTotalWithTaxes() {
    let total = 0;
    const formArray = this.addProductsForm.get('products') as FormArray;
    for (let i = 0; i < formArray.controls.length; i++) {
      const control = formArray.controls[i];
      const price = (control.value.unitPrice * control.value.quantity);
      const vatAmount = (price / 100) * control.value.vatRate;
      total += price + vatAmount;
    }
    return this.commonService.formatNumberToLocale(total);
  }

  finalizeClicked() {
    if (this.areValidForms()) {
      const form = this.formBuilder.group({
        mlAgencyId: [this.informationsForm.value.mlAgencyId, Validators.required],
        agencyId: this.informationsForm.value.agencyId,
        payerId: this.informationsForm.value.payerId,
        marketTypeId: this.informationsForm.value.marketTypeId,
        accountingCode: [this.informationsForm.value.accountingCode, Validators.required],
        payerPreference: [this.informationsForm.value.payerPreference, Validators.required],
        payerName: [this.informationsForm.value.payerName, Validators.required],
        payerAddress: [this.informationsForm.value.payerAddress, Validators.required],
        payerAdditionalAddress: this.informationsForm.value.payerAdditionalAddress,
        payerPostalCode: [this.informationsForm.value.payerPostalCode, Validators.required],
        payerLocality: [this.informationsForm.value.payerLocality, Validators.required],
        affairLastname: [this.informationsForm.value.affairLastname],
        affairFirstname: [this.informationsForm.value.affairFirstname],
        affairAddress: [this.informationsForm.value.affairAddress],
        affairPostalCode: [this.informationsForm.value.affairPostalCode],
        affairLocality: [this.informationsForm.value.affairLocality],
        affairInternalNote: [this.informationsForm.value.affairInternalNote],
        comment: this.comment,
        quotationDetails: this.addProductsForm.get('products') as FormArray
      });
      this.quotationService.store(form);
    }
  }

  private initValues() {
    this.mlAgencyService.getForSelect();
    this.thingService.getVatRatesSelect();
    this.productService.getAll();
    this.marketTypeService.getAll();
  }

  private initSubscriptions() {
    this.marketsTypes$ = this.marketTypeService.getAll().subscribe(
      (data) => {
        if (data) {
          this.marketsTypes = data;
        }
      }
    )
    this.mlAgencies$ = this.mlAgencyService.mlAgenciesSelect$.subscribe(
      (data) => {
        if (data) {
          this.mlAgencies = data;
        }
      }
    )
  }

  private getFormControls(form: FormGroup, controlName: string) {
    return (form.get(controlName) as FormArray).controls;
  }

  private getInformationsForm(): FormGroup {
    const defaultString = this.commonService.getDefaultFormStringValue();
    const defaultSelect = this.commonService.getDefaultFormNullValue();
    const defaultMarketTypeId = this.commonService.getDefaultMarketTypeValue();
    return this.formBuilder.group({
      mlAgencyId: [defaultSelect, Validators.required],
      agencyId: defaultSelect,
      payerId: defaultSelect,
      payerName: [defaultString, Validators.required],
      payerAddress: [defaultString, Validators.required],
      payerAdditionalAddress: defaultString,
      payerPostalCode: [defaultString, Validators.required],
      payerLocality: [defaultString, Validators.required],
      marketTypeId: [defaultMarketTypeId],
      affairLastname: [defaultString],
      affairFirstname: [defaultString],
      affairAddress: [defaultString],
      affairPostalCode: [defaultString],
      affairLocality: [defaultString],
      affairInternalNote: [defaultString],
    });
  }

  private getAddProductsForm(): FormGroup {
    return this.formBuilder.group({
      products: new FormArray([])
    });
  }

  private setSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'le devis',
      text: 'Finaliser',
      icon: faCheck
    });
    this.quotationService.setSubmitButton(this.submitButton);
  }
}
