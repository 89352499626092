import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';
import { SubmitButton, ProductStatus } from '@eros-front/models';
import { ProductStatusService } from '@eros-front/services';

@Component({
  selector: 'app-settings-products-products-statuses-modal',
  templateUrl: './settings-products-products-statuses-modal.component.html',
  styleUrls: ['./settings-products-products-statuses-modal.component.scss']
})
export class SettingsProductsProductsStatusesModalComponent implements OnInit, AfterViewInit {

  @Input() status: ProductStatus;
  @Input() labelsClasses: any[];
  @ViewChild('settingsAddEditProductStatus', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private productStatusService: ProductStatusService
  ) { }

  ngOnInit() {
    this.initValues();
  }

  ngAfterViewInit() {
    this.productStatusService.setModalAddEdit(this.childModal);
  }

  onModalShown() {
    this.determinateSubmitButton();
    this.productStatusService.setSubmitButton(this.submitButton);
    this.form = this.productStatusService.getForm(this.status);
  }

  onModalHidden() {
    this.initValues();
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.status) {
        this.productStatusService.update(this.status.id, this.form);
      } else {
        this.productStatusService.store(this.form);
      }
    }
  }

  private initValues() {
    this.status = null;
    this.form = null;
  }

  private determinateSubmitButton() {
    this.submitButton = this.productStatusService.determinateSubmitButton(this.status, 'le statut produit');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
