import Swal from 'sweetalert2';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AffairContactService, ContactService, NotifService, SwalService } from '@libs/services/src';
import { Subscription } from 'rxjs';
import { Affair, AffairContact, Contact } from '@libs/models/src';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AffairShowContactsModalComponent } from './affair-show-contacts-modal/affair-show-contacts-modal.component';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-affair-show-contacts',
  templateUrl: './affair-show-contacts.component.html',
  styleUrls: ['./affair-show-contacts.component.scss']
})
export class AffairShowContactsComponent implements OnInit, OnDestroy {

  @Input() affair: Affair;
  @Input() affairContacts: AffairContact[] = [];
  public research = '';
  public contacts = [];
  private contacts$: Subscription;
  public agencyContacts = [];
  private agencyContacts$: Subscription;
  public form: FormGroup;

  constructor(
    private affairContactService: AffairContactService,
    private contactService: ContactService,
    private notifService: NotifService,
    private swalService: SwalService,
    private modalService: NzModalService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.contactService.getAll();
    this.contactService.getAllByAffair(this.affair.id, this.research);
  }

  ngOnDestroy() {
    this.contacts$.unsubscribe();
    this.agencyContacts$.unsubscribe();
  }

  onSearchChange(value: string) {
    this.research = value;
    this.contactService.getAllByAffair(this.affair.id, this.research);
  }

  onClearSearchClicked() {
    this.research = '';
    this.contactService.getAllByAffair(this.affair.id, this.research);
  }

  onQuickAddClicked(contactId: number) {
    this.affairContactService.quickStore(this.affair.id, contactId);
  }

  onTextCopied(text: string) {
    navigator.clipboard.writeText(text);
    this.notifService.showSuccessNotif(text + ' copié dans le presse-papier avec succès !');
  }

  onAddEditContactClicked(contact?: any) {
    this.form = this.affairContactService.getForm(this.affair.id, contact)
    const modalInstance = this.modalService.create({
      nzContent: AffairShowContactsModalComponent,
      nzComponentParams: {
        affair: this.affair,
        contact: contact,
        form: this.form,
        contacts: this.agencyContacts
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.affairContactService.getAllByAffair(this.affair.id);
    })
  }

  onEditContactClicked(id: number) {
    this.affairContactService.get(id);
  }

  onDeleteContactClicked(id: number) {
    const swalOptions = this.swalService.getSwalDeleteOptions({
      text: 'Le contact sera retiré de l\'affaire de manière irréversible.'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.affairContactService.delete(id).subscribe(
          (success) => {
            this.notifService.showSuccessNotif(success);
          }
        );
      }
    });
  }

  private initSubscriptions() {
    this.contacts$ = this.contactService.contacts$.subscribe(
      (contacts: Contact[]) => {
        this.contacts = contacts;
      }
    );
    this.agencyContacts$ = this.contactService.contactsAffair$.subscribe(
      (contacts: any) => {
        this.agencyContacts = contacts;
      }
    );
  }

}
