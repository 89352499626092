import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { SubmitButton } from '@libs/models/src';
import { NotifService, SubmitButtonService } from '@libs/services/src';
import { AccountingExportService } from '@libs/services/src/lib/accounting-export.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
declare var require: any;

@Component({
  selector: 'app-invoices-export-generate',
  templateUrl: './invoices-export-generate.component.html',
  styleUrls: ['./invoices-export-generate.component.scss']
})
export class InvoicesExportGenerateComponent implements OnInit {
  public form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private submitButtonService: SubmitButtonService,
    private accountingExportService: AccountingExportService,
    private notifService: NotifService
  ) { }

  public ngOnInit(

  ) {
    this.form = this.accountingExportService.getCreateForm();
    this.setSubmitButton();
  }

  public isValidForm() {
    return this.form.valid;
  }

  public submitForm(): void {
    if (this.isValidForm()) {
      this.accountingExportService.storeForInvoices(this.form).subscribe(
        (response: HttpResponse<Blob>) => {
          this.modal.close();
          this.responseToDownload(response, 'vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        }
      );
    }
  }

  private setSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'un export',
      text: 'Générer',
      icon: faFileExcel
    });
    this.accountingExportService.setSubmitButton(this.submitButton);
  }

  private responseToDownload(response: HttpResponse<Blob>, type: string): void {
    const blob = new Blob([response.body], { type: `application/${type}` });
    const downloadUrl = window.URL.createObjectURL(blob);
    this.downloadXlsx(downloadUrl, response);
  }

  private downloadXlsx(downloadUrl: string, response: HttpResponse<Blob>) {
    let filename = "";
    const disposition = response.headers.get('content-disposition');
    if (disposition) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches !== null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    } else {
      let uuidv4 = require('uuid/v4');
      filename = uuidv4() + '.xlsx';
    }
    let a = document.createElement("a");
    if (typeof a.download === 'undefined') {
      window.location.href = downloadUrl;
    } else {
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }
  }

}
