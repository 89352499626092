import { Subscription } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { PermissionService } from '@eros-front/services';
import { SubmitButton } from '../../../../models/src/lib/submit-button.model';
import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { tabSuffix } from '_config/tab-suffix';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'eros-front-permission-create-edit',
  templateUrl: './permission-create-edit.component.html',
  styleUrls: ['./permission-create-edit.component.scss']
})
export class PermissionCreateEditComponent implements OnInit {
  public form: FormGroup;
  public permission = null;
  public submitButton: SubmitButton;
  public permissionId = null;
  public permission$: Subscription;

  constructor(
    private titleService: Title,
    private permissionService: PermissionService,
    private route: ActivatedRoute
  ) {
    this.titleService.setTitle(`Création d'une permission${tabSuffix}`);
  }

  ngOnInit() {
    this.initSubscriptions();
    
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.permissionId = params.get('id');
      if (this.permissionId) {
        this.permissionService.get(this.permissionId);
      } else {
        this.form = this.permissionService.getForm(this.permission);
        this.determinateSubmitButton();
      }
    });
  }

  private initSubscriptions() {
    this.permission$ = this.permissionService.permission$.subscribe(
      (data) => {
        if (data) {
          this.permission = data;
          this.form = this.permissionService.getForm(this.permission);
          this.determinateSubmitButton();
          this.titleService.setTitle(`Édition d'une permission${tabSuffix}`);
        }
    });
  }

  isValidForm(): boolean {
    return this.form.valid;
  }

  private determinateSubmitButton() {
    this.submitButton = this.permissionService.determinateSubmitButton(this.permission, 'la permission');
  }

  isInvalidField(fieldName: string) {
    const field = this.form.get(fieldName);
    return (field.dirty || field.touched) && field.invalid && field.errors.required;
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.permission){
        this.permissionService.update(this.permissionId, this.form);
      } else {
        this.permissionService.store(this.form);
      }
    }
  }

}
