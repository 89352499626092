import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { SubmitButton } from '@libs/models/src';
import { PayersModalAddComponent } from '@libs/payers/src/lib/payers-modal-add/payers-modal-add.component';
import { AgencyService, CustomerService, PayerService, SubmitButtonService } from '@libs/services/src';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-customers-show-invoicing',
  templateUrl: './customers-show-invoicing.component.html',
  styleUrls: ['./customers-show-invoicing.component.scss']
})
export class CustomersShowInvoicingComponent implements OnInit {

  @Input() customer: any;
  @Input() invoicingForm: FormGroup;
  @Input() paymentDeadlines: any[];
  @Input() paymentDeadlineTypes: any[];
  @Input() billingPreferences: any[];
  @Input() payerPreferences: any[];
  @Input() payers: any[];
  @Input() librariesBuilder: any[];
  @Output() saveClicked = new EventEmitter();
  public submitButton: SubmitButton;

  constructor(
    private submitButtonService: SubmitButtonService,
    private agencyService: AgencyService,
    private modalService: NzModalService,
    private customerService: CustomerService
  ) { }

  ngOnInit() {
    this.determineSubmitButton();
  }

  isValidForm() {
    return this.invoicingForm && this.invoicingForm.valid && this.invoicingForm.dirty;
  }

  onSaveClicked() {
    if (this.isValidForm()) {
      this.saveClicked.emit(this.invoicingForm);
    }
  }

  generateAccountingCode() {

  }

  onAddPayerClicked() {
    const modalInstance = this.modalService.create({
      nzContent: PayersModalAddComponent,
      nzComponentParams: {
        agencyId: this.customer.id
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.customerService.refreshEntityByCustomerRef(this.customer.ref);
    })
  }

  determineSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance(
      {
        objectName: '',
        text: 'Enregistrer les changements',
        icon: faSave
      }
    );
    this.agencyService.setSubmitInvoicingButton(this.submitButton);

  }

}
