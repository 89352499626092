import { Component, Input, OnInit } from '@angular/core';
import { AutocompleteOptions, FormFormatterService } from '@libs/services/src';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-quotation-form-affair',
  templateUrl: './quotation-form-affair.component.html',
  styleUrls: ['./quotation-form-affair.component.scss']
})
export class QuotationFormAffairComponent implements OnInit {
  @Input() form;
  @Input() marketsTypes;
  public autocompleteOptions: AutocompleteOptions = new AutocompleteOptions();

  constructor(
    private formFormatterService: FormFormatterService
  ) { }

  ngOnInit() {
  }

  onAffairAddressChange(address: Address) {
    this.form = this.formFormatterService.completeGoogleAddress(
      this.form, address,
      {
        route: 'affairAddress',
        postal_code: 'affairPostalCode',
        locality: 'affairLocality',
        concatStreetWithRoute: true
      }
    );
  }

}
