import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ErosNzTableCheckboxesComponent } from '@libs/components/src/lib/eros-nz-table-checkboxes/eros-nz-table-checkboxes.component';
import { FileUtilsService, SwalService } from '@libs/services/src';
import { AffairDocumentDeliveredService } from '@libs/services/src/lib/affair-document-delivered.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { throwError } from 'rxjs';
import { catchError, distinctUntilChanged, finalize } from 'rxjs/operators';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-affairs-exports-documents-delivered',
  templateUrl: './affairs-exports-documents-delivered.component.html',
  styleUrls: ['./affairs-exports-documents-delivered.component.scss']
})
export class AffairsExportsDocumentsDeliveredComponent extends ErosNzTableCheckboxesComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() refresh: boolean;
  public affairsDocumentsDeliveredData: any[];
  public inputSearchValue = '';


  constructor(
    private affairDocumentDeliveredService: AffairDocumentDeliveredService,
    private titleService: Title,
    private swalService: SwalService,
    private fileUtilsService: FileUtilsService,
  ) {
    super();
    this.titleService.setTitle(`Export des données de sorties${tabSuffix}`);
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(distinctUntilChanged())
      .subscribe(val => {
        this.initList();
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['refresh'] && changes['refresh'].previousValue != changes['refresh'].currentValue) {
      this.initList();
      this.refresh = false;
    }
  }

  private initList() {
    this.isLoading = true;
    this.affairDocumentDeliveredService.getForExportList(this.form).subscribe(
      (data) => {
        this.affairsDocumentsDeliveredData = data.data;
        this.total = data.meta.total;
        this.isLoading = false;
      });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params;
    const currentSort = sort.find(item => item.value !== null);
    this.form.patchValue({
      'page': pageIndex,
      'size': pageSize
    });

    this.initList();
  }

  onInputSearchChanged(newSearch: string): void {
    this.inputSearchValue = newSearch;
    this.form.patchValue({
      search: newSearch,
    })
  }

  onDownloadElementsClicked(selectAll?: boolean): void {
    this.swalService.showSwalLoading('Création de l\'export en cours...');
    let elementsIds = [];
    if (selectAll) {
      elementsIds = this.affairsDocumentsDeliveredData.map(x => x.id);
    } else {
      elementsIds = Array.from(this.setOfCheckedId);
    }
    this.affairDocumentDeliveredService.downloadMultiple(elementsIds)
      .pipe(
        catchError(error => {
          this.swalService.showSwalError(error);
          return throwError(error);
        }),
      )
      .subscribe(
        (file) => {
          this.fileUtilsService.responseToDownload(file, 'zip');
          this.checked = false;
          this.onAllChecked(this.checked);
          this.swalService.closeSwal();
        }
      )
  }

  getCheckedSize(): number {
    return this.setOfCheckedId.size;
  }

  isDownloadButtonDisabled(): boolean {
    return this.setOfCheckedId.size === 0;
  }
}
