import { catchError, finalize } from 'rxjs/operators';
import { ModelWithDatatableAndCrud } from './classes/model-datatable-crud';
import { Affair, Building, Contact, Intervention, SubmitButton } from '@eros-front/models';
import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { DataTableColumn } from './classes/model-datatable';
import { CommonService } from './utilities/common.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DateUtilsService } from './utilities/date-utils.service';
import { FormValidatorsService } from './utilities/form-validators.service';
import moment from 'moment';
import { AffairContactService } from './affair-contact.service';

export interface InterventionDatatableParameters {
    selectedAffair?: number;
    selectedMarketsTypes: string;
    selectedAgencies: string;
    selectedAssistants: string;
    selectedTechnicians: string;
    selectedConformities: string;
    selectedInterventionsStatuses: string;
    selectedProducts: string;
    selectedProductsStatuses: string;
    selectedDocumentsCustomersMissing: string;
    selectedAffairsGoals: string;
    selectedAffairsStatuses: string;
    selectedAffairsMonitoringTypes: string;
    affairMonitoringTypeLevel: number;
    isAfterIntervention: boolean;
    dateInterventionMin: string;
    dateInterventionMax: string;
    isNotBilled: number;
    isWaitingCheck: number;
    isDeliveredHouse: number;
    isOffice: number;
    page: number;
    size: number;
    search: string;
    addressSearch: string;
    sortField: string,
    sortOrder: string,
    individualSearch: any;
}

export interface InterventionNameParameters {
    affair: Affair;
    affairProductsSelected?: any;
    buildings?: Building[];
    isDeliveredHouse?: boolean;
    isTimeSlot?: boolean;
}

@Injectable()
export class InterventionService extends ModelWithDatatableAndCrud {

    private route = '/interventions';
    private redirectUrl = '/admin/interventions/index';
    private editUrl = '/interventions/$id/edit';
    private affairShowUrl = '/affairs/$id/show';
    public intervention$ = new BehaviorSubject<any>(undefined);
    public currentIntervention = null;
    public interventions$ = new BehaviorSubject<any>(undefined);
    public interventionsSelects$ = new BehaviorSubject<any>(undefined);
    public interventionsEditSelects$ = new BehaviorSubject<any>(undefined);
    public interventionsCalendarSelects$ = new BehaviorSubject<any>(undefined);
    public interventionsCreateSelects$ = new BehaviorSubject<any>(undefined);
    public interventionsCalendar$ = new BehaviorSubject<any>(undefined);
    public affairsProductsToProgram$ = new BehaviorSubject<any>(undefined);
    public generatedName$ = new BehaviorSubject<any>(undefined);
    public generatedDescription$ = new BehaviorSubject<any>(undefined);
    public modalShift: ModalDirective;
    public submitButtonShift: SubmitButton;
    public modalProgram: ModalDirective;
    public submitButtonProgram: SubmitButton;
    public modalDeprogram: ModalDirective;
    public submitButtonDeprogram: SubmitButton;
    public submitButtonCancel: SubmitButton;
    public modalEdit: ModalDirective;
    public modalAdd: ModalDirective;
    public refreshIntervention$ = new BehaviorSubject<any>(undefined);
    public documentsCustomersAttached$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formFormatterService: FormFormatterService,
        private dateUtilsService: DateUtilsService,
        private formBuilder: FormBuilder,
        private commonService: CommonService,
        private formValidatorsService: FormValidatorsService,
        private affairContactService: AffairContactService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[], form?: FormGroup): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns,
            openInNewTabUrl: this.editUrl
        }, this.formToDatatableParameters(form));
    }

    public initList(form): Observable<any> {
        return this.apiService.post(this.route + '/list', this.formToDatatableParameters(form))
    }

    public get(id: number): void {
        this.apiService.get(this.route + '/' + id)
            .subscribe(
                (object) => {
                    this.intervention$.next(object);
                    this.currentIntervention = object;
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public getAll(): void {
        super.getAll(this.interventions$, this.route);
    }

    public storeForAffair(form: FormGroup): void {
        this.affairShowUrl = this.affairShowUrl.replace('$id', form.value.affairId);
        super.store(this.formatCreateForm(form), this.route, this.affairShowUrl);
    }

    public storeForModal(form: FormGroup): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.post(this.route, this.formatCreateForm(form))
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )

    }

    public update(id: number, form: any): Observable<any> {
        //super.update(id, this.formatUpdateForm(form), this.route);
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.put(this.route + '/' + id, this.formatUpdateForm(form))
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            );
    }

    public updateForModal(id: number, form: any): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.put(this.route + '/' + id, this.formatUpdateForm(form))
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            );
    }

    public updateDate(id: number, startDate: Date, endDate: Date) {
        const duration = this.dateUtilsService.calcTimeDifference(startDate, endDate);
        this.apiService.put(this.route + '/' + id + '/update-date', {
            startDate: startDate.toString(),
            duration: duration.toTimeString()
        })
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            )
            .subscribe(
                (success) => {
                    this.notifService.showSuccessNotif(success);
                }
            );
    }


    public delete(id: number): void {
        super.delete(id, this.route);
    }

    public redrawDataTable(form: FormGroup): void {
        super.redrawDataTable(this.formToDatatableParameters(form));
    }

    getSelects(): void {
        this.apiService.get('/interventions/list/selects')
            .subscribe(
                (selects) => {
                    this.interventionsSelects$.next(selects);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération'
                    });
                }
            );

    }

    getSelectsForCreate(affairId: number): void {
        this.apiService.get(`/interventions/create-for-affair/${affairId}/selects`)
            .subscribe(
                (selects) => {
                    this.interventionsCreateSelects$.next(selects);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération'
                    });
                }
            );
    }

    getSelectsForEdit(interventionId: number): void {
        this.apiService.get(`/interventions/${interventionId}/edit/selects`)
            .subscribe(
                (selects) => {
                    this.interventionsEditSelects$.next(selects);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération'
                    });
                }
            );

    }

    getSelectsForCalendar(): void {
        this.apiService.get(`/interventions/calendar/selects`)
            .subscribe(
                (selects) => {
                    this.interventionsCalendarSelects$.next(selects);
                },
                (error) => {
                    this.notifService.showNotif({
                        type: 'danger',
                        text: 'Une erreur est survenue lors de la récupération'
                    });
                }
            );

    }

    getEditForCalendar(id: number): Observable<any> {
        return this.apiService.get(`/interventions/${id}/get-for-calendar`)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            );
    }

    getForAffair(form: any): Observable<any> {
        return this.apiService.post(this.route + '/get-for-affair', this.formatAffairListForm(form))
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            );
    }

    getAudits(id: number): Observable<any> {
        return this.apiService.get(`${this.route}/${id}/audits`)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            );
    }

    reset(): void {
        this.intervention$.next(null);
    }

    confirm(id: number): Observable<any> {
        return this.apiService.get(this.route + '/' + id + '/confirm')
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            );
    }

    shift(id: number, form: any): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButtonShift);
        return this.apiService.post(this.route + '/' + id + '/shift', this.formatProgramForm(form))
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButtonShift);
                })
            );
    }

    program(id: number, form: any): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButtonProgram);
        return this.apiService.post(this.route + '/' + id + '/program', this.formatProgramForm(form))
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButtonProgram);
                })
            );
    }

    deprogram(id: number, form: any): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButtonDeprogram);
        return this.apiService.post(this.route + '/' + id + '/deprogram', form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButtonDeprogram);
                })
            )
    }

    cancel(id: number, form: any): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButtonCancel);
        return this.apiService.post(this.route + '/' + id + '/cancel', form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButtonCancel);
                })
            )
    }

    markAsFree(id: number): Observable<any> {
        return this.apiService.get(this.route + '/' + id + '/mark-as-free')
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            )
    }

    markAsWaitingConfirmation(id: number): Observable<any> {
        return this.apiService.get(this.route + '/' + id + '/mark-as-waiting-confirmation')
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            )
    }

    markAsBilled(id: number): Observable<any> {
        return this.apiService.get(this.route + '/' + id + '/mark-as-billed')
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            )
    }

    getDocumentCustomersAttached(id: number): void {
        this.apiService.get(this.route + '/' + id + '/documents-customers/attached')
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            )
            .subscribe(
                (object) => {
                    this.documentsCustomersAttached$.next(object);
                }
            );
    }

    setSubmitButtonShift(button: SubmitButton): void {
        this.submitButtonShift = button;
    }

    setSubmitButtonProgram(button: SubmitButton): void {
        this.submitButtonProgram = button;
    }

    setSubmitButtonDeprogram(button: SubmitButton): void {
        this.submitButtonDeprogram = button;
    }

    setSubmitButtonCancel(button: SubmitButton): void {
        this.submitButtonCancel = button;
    }

    setModalAdd(modal: ModalDirective): void {
        this.modalAdd = modal;
    }

    showModalAdd(): void {
        this.modalAdd.show();
    }

    setModalEdit(modal: ModalDirective): void {
        this.modalEdit = modal;
    }

    showModalEdit(): void {
        this.modalEdit.show();
    }

    getAddForm(affair: Affair, technicians: any, interventionAdd?: any): FormGroup {
        const name = this.commonService.getDefaultFormStringValue();
        const generatedDescription = this.commonService.getDefaultFormStringValue();

        let usersIds = [];
        if (interventionAdd) {
            usersIds = technicians.filter(x => interventionAdd.usersSelected.map(x => x.id).includes(x.value))
        } else {
            usersIds = technicians.filter(x => affair.userTechnicianId == x.value);
        }
        const startDate = interventionAdd ?
            this.commonService.valueToFormDate(interventionAdd.startDate) : this.commonService.getDefaultFormNullValue();
        const duration = interventionAdd ?
            this.commonService.valueToFormDate(interventionAdd.duration) : this.commonService.getDefaultFormNullValue();
        return this.formBuilder.group({
            affairId: affair.id,
            name: [name, Validators.required],
            generatedDescription: [generatedDescription, Validators.required],
            description: [this.commonService.getDefaultFormStringValue()],
            conformityId: null,
            worksSupervisorId: null,
            deliveredDate: null,
            startDate: startDate,
            duration: duration,
            techniciansIds: [usersIds, Validators.required],
            affairProducts: new FormArray([], this.formValidatorsService.minSelectedCheckboxes(1)),
            contacts: [],
            affairsContacts: [],
            isConfirmed: false,
            isDeliveredHouse: false,
            isWaitingCheck: false,
            recoverCheck: false,
            isOffice: false,
            purchaseOrder: null,
            unitNumber: null,
            commitmentDate: null,
            isTimeSlot: [this.commonService.getDefaultFormRadioValue(), Validators.required],
            timeSlot: [this.commonService.getDefaultFormNullValue()],
            doNotShift: [false],
            doNotShiftReason: [this.commonService.getDefaultFormStringValue()],
            isPrepared: [false],
            buildingsIds: null,
            housings: new FormArray([])
        });
    }

    getEditForm(intervention: Intervention, technicians: any, contacts?: any): FormGroup {
        const name = intervention ?
            this.commonService.valueToFormString(intervention.name) : this.commonService.getDefaultFormStringValue();
        const generatedDescription = intervention ?
            this.commonService.valueToFormString(intervention.generatedDescription) : this.commonService.getDefaultFormStringValue();
        const description = intervention ?
            this.commonService.valueToFormString(intervention.description) : this.commonService.getDefaultFormStringValue();
        const conformityId = intervention ?
            this.commonService.valueToFormString(intervention.conformityId) : this.commonService.getDefaultFormNullValue();
        const techniciansIds = intervention ?
            technicians.filter(x => intervention.usersTechnicians.map(x => x.id).includes(x.value)) : [];
        const worksSupervisorId = intervention ?
            this.commonService.valueToFormSelect(intervention.worksSupervisorId) : this.commonService.getDefaultFormNullValue();
        const contactsIds = intervention && contacts ?
            contacts.filter(x => intervention.contacts.map(x => x.id).includes(x.value)) : [];
        const affairsContacts = intervention ?
            this.affairContactService.valueToFormSelectMultiple(intervention.affairsContacts) : [];
        const isDeliveredHouse = intervention ?
            intervention.isDeliveredHouse : this.commonService.getDefaultFormCheckboxValue();
        const isWaitingCheck = intervention ?
            intervention.isWaitingCheck : this.commonService.getDefaultFormCheckboxValue();
        const isOffice = intervention ?
            intervention.isOffice : this.commonService.getDefaultFormCheckboxValue();
        const purchaseOrder = intervention ?
            intervention.purchaseOrder : this.commonService.getDefaultFormStringValue();
        const buildingsIds = intervention ?
            this.commonService.valueToFormSelectMultiple(intervention.buildings) : [];
        const unitNumber = intervention ?
            intervention.unitNumber : this.commonService.getDefaultFormStringValue();
        const commitmentDate = intervention ?
            this.commonService.valueToFormDate(intervention.commitmentDate) : this.commonService.getDefaultFormDateValue();
        const timeSlot = intervention ?
            intervention.timeSlot : this.commonService.getDefaultFormStringValue();
        const doNotShift = intervention ?
            intervention.doNotShift : false;
        const doNotShiftReason = intervention ?
            intervention.doNotShiftReason : this.commonService.getDefaultFormStringValue();
        const isPrepared = intervention ?
            intervention.isPrepared : false;
        const recoverCheck = intervention ?
            intervention.recoverCheck : false;
        let affairStatusId = this.commonService.getDefaultFormNullValue();
        if (intervention && intervention.affairStatusId) {
            affairStatusId = this.commonService.valueToFormSelect(intervention.affairStatusId);
        } else if (intervention && intervention.affair.affairStatus) {
            affairStatusId = this.commonService.valueToFormSelect(intervention.affair.affairStatusId);
        }
        return this.formBuilder.group({
            id: [intervention.id],
            name: [name, [Validators.required]],
            generatedDescription: [generatedDescription],
            description: [description],
            conformityId: conformityId,
            affairStatusId: [affairStatusId],
            worksSupervisorId: worksSupervisorId,
            affairProducts: new FormArray([]),
            techniciansIds: [techniciansIds, Validators.required],
            contacts: [contactsIds],
            affairsContacts: [affairsContacts],
            isDeliveredHouse: isDeliveredHouse,
            isWaitingCheck: isWaitingCheck,
            isOffice: isOffice,
            purchaseOrder: purchaseOrder,
            unitNumber: [unitNumber],
            commitmentDate: [commitmentDate],
            timeSlot: [timeSlot],
            buildingsIds: [buildingsIds],
            doNotShift: [doNotShift],
            doNotShiftReason: [doNotShiftReason],
            recoverCheck: [recoverCheck],
            isPrepared: [isPrepared],
            housings: new FormArray([])
        });
    }

    getPreparationForm(userId: number): FormGroup {
        const userSelectId = userId ?
            this.commonService.valueToFormSelect(userId) : this.commonService.getDefaultFormNullValue();
        const dateInterventionMin = this.dateUtilsService.getNextWeekStart();
        const dateInterventionMax = this.dateUtilsService.getNextWeekEnd();

        return this.formBuilder.group({
            userId: [userSelectId, [Validators.required]],
            dateInterventionMin: [dateInterventionMin, [Validators.required]],
            dateInterventionMax: [dateInterventionMax, [Validators.required]],
            isPrepared: false,
        });

    }

    initProgramForm(): FormGroup {
        return this.formBuilder.group({
            startDate: [null, Validators.required],
            duration: [null, Validators.required],
            isConfirmed: false,
            isTimeSlot: [this.commonService.getDefaultFormRadioValue()],
            timeSlot: [null]
        });
    }

    initShiftForm(intervention: Intervention, technicians: any, dropStart?: any, dropEnd?: any): FormGroup {

        let startDate = this.commonService.getDefaultFormDateValue();
        if (dropStart) {
            startDate = this.commonService.valueToFormDate(dropStart)
        } else if (intervention) {
            startDate = this.commonService.valueToFormDate(intervention.startDate)
        }
        let duration = this.commonService.getDefaultFormNullValue();
        if (dropEnd) {
            const durationCalc = this.dateUtilsService.calcTimeDifference(dropStart, dropEnd);
            duration = durationCalc;
        } else if (intervention) {
            duration = this.getDurationDate(intervention)
        }
        const isTimeSlot = intervention ?
            this.commonService.valueToRadioFormString(intervention.isTimeSlot) : this.commonService.getDefaultFormRadioValue();
        const timeSlot = intervention && intervention.isTimeSlot ?
            this.commonService.valueToFormString(intervention.timeSlot) : null;
        const techniciansIds = intervention ?
            technicians.filter(x => intervention.usersTechnicians.map(x => x.id).includes(x.value)) : [];

        return this.formBuilder.group({
            techniciansIds: [techniciansIds, Validators.required],
            startDate: [startDate, Validators.required],
            duration: [duration, Validators.required],
            shiftOriginId: [null, Validators.required],
            reason: [null],
            isTimeSlot: [isTimeSlot],
            timeSlot: [timeSlot]
        });
    }

    initDeprogramForm(): FormGroup {
        return this.formBuilder.group({
            origin: [null, Validators.required],
            reason: [null]
        });
    }

    initCancelForm(): FormGroup {
        return this.formBuilder.group({
            origin: [null, Validators.required],
            reason: [null]
        });
    }

    getForCalendar(form: any, users: any): void {
        const usersIds = JSON.stringify(form.value.users.map((v, i) => v ? users[i].id : null).filter(v => v !== null));
        this.apiService.post(`${this.route}/get-for-calendar`, {
            dateFrom: form.value.dateFrom,
            dateTo: form.value.dateTo,
            users: usersIds,
        })
            .subscribe(
                (events: any) => {
                    this.interventionsCalendar$.next(events);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    getAffairsProductsToProgram(research?: string, affairId?: number): void {
        this.apiService.post(`${this.route}/affairs-products/get-to-program`, {
            search: research,
            affairId: affairId
        })
            .subscribe(
                (affairProducts: any) => {
                    this.affairsProductsToProgram$.next(affairProducts);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    getFormControls(form: FormGroup, controlName: string) {
        return (form.get(controlName) as FormArray).controls;
    }

    initInterventionsTypesSwitches(form: FormGroup, indicatorsInterventionsTypes: any, intervention?: Intervention, affair?: Affair) {
        indicatorsInterventionsTypes.forEach((indicator) => {
            let checked = false;
            if (intervention) {
                let found = intervention.indicatorsInterventionsTypes.find(x => x.id == indicator.id)
                if (found) {
                    checked = found.pivot.value;
                }
            } else if (affair) {
                let found = affair.documentsCustomersIds.some(x => indicator.documentsCustomersIds.includes(x));
                if (found) {
                    checked = true;
                }
            }
            const indicatorsInterventionsTypesFormArray = form.get('indicatorsInterventionsTypes') as FormArray;
            indicatorsInterventionsTypesFormArray.push(new FormGroup({
                checked: new FormControl(checked),
                indicatorInterventionTypeId: new FormControl(indicator.id),
            }))
        });
    }

    initAffairProductsCheckboxes(form: FormGroup, affairProductsToProgram: any, affairProductsSelected?: any, affairProducts?: any) {
        const affairProductsFormArray = form.get('affairProducts') as FormArray;
        if (affairProductsSelected) {
            affairProductsSelected.forEach(affairProduct => {
                let quantity = affairProduct.quantity;
                if (affairProducts) {
                    const productFromAffair = affairProducts.find(x => x.id == affairProduct.id);
                    quantity = productFromAffair.quantity;
                }
                affairProductsFormArray.push(new FormGroup({
                    checked: new FormControl(true),
                    affairProductId: new FormControl(affairProduct.id),
                    productId: new FormControl(affairProduct.productId),
                    quantity: new FormControl(affairProduct.quantity, [Validators.max(quantity), Validators.min(1)]),
                    price: new FormControl(affairProduct.price),
                }))

            });
        }
        affairProductsToProgram.forEach((affairProduct) => {
            const found = affairProductsSelected ? affairProductsSelected.find(x => x.id == affairProduct.id) : false;
            if (!found) {
                affairProductsFormArray.push(new FormGroup({
                    checked: new FormControl(false),
                    affairProductId: new FormControl(affairProduct.id),
                    productId: new FormControl(affairProduct.productId),
                    quantity: new FormControl(affairProduct.quantity, [Validators.max(affairProduct.quantity), Validators.min(1)]),
                    price: new FormControl(affairProduct.price),
                }))
            }
        });
    }

    initHousingsCheckboxes(form: FormGroup, housings: any, housingsSelected?: any) {
        const housingsFormArray = form.get('housings') as FormArray;
        while (housingsFormArray.length !== 0) {
            housingsFormArray.removeAt(0)
        }
        housings.forEach((housing) => {
            let checked = false;
            let housingSelected = housing;
            if (housingsSelected) {
                checked = housingsSelected.map(x => x.id).includes(housing.id);
                if (checked) {
                    housingSelected = housingsSelected.find(x => x.id == housing.id);
                }
            }

            housingsFormArray.push(new FormGroup({
                checked: new FormControl(checked),
                name: new FormControl(housing.name),
                housingId: new FormControl(housingSelected.id),
                result: new FormControl(housingSelected.result),
            }))
        });
    }

    updateDuration(form: FormGroup, affair: Affair, affairProductsSelected?: any): void {
        let currentDateObject = new Date();
        let dateObject = new Date();
        if (affairProductsSelected) {
            for (let i = 0; i < affairProductsSelected.length; i++) {
                if (affairProductsSelected[i].product.duration != null) {
                    dateObject = moment(dateObject).add(affairProductsSelected[i].product.duration, 'm').toDate();
                }
            }
        }
        const duration = this.dateUtilsService.calcTimeDifference(currentDateObject, dateObject);
        form.controls.duration.patchValue(duration);
    }

    updateTechnicians(form: FormGroup, technicians: any, techniciansIds: any): void {
        const selectedTechnicians = technicians.filter(x => techniciansIds.includes(x.value));
        form.controls.techniciansIds.patchValue(selectedTechnicians);
    }

    filterHousings(buildings: any, housings: any[], valueChange?: boolean) {
        const buildingsSelected = buildings.map(v => valueChange ? v.value : v.id);
        return housings.filter(x => buildingsSelected.includes(x.buildingId))
    }

    initUnconfirmedList(form?: FormGroup): Observable<any> {
        return this.apiService.post(this.route + '/list/unconfirmed', this.formatFiltersStatusForm(form));
    }

    initUncompletedList(form?: FormGroup): Observable<any> {
        return this.apiService.post(this.route + '/list/uncompleted', this.formatFiltersStatusForm(form));
    }

    initCompletedList(form?: FormGroup): Observable<any> {
        return this.apiService.post(this.route + '/list/completed', this.formatFiltersStatusForm(form));
    }

    initAffairProductsList(form?: FormGroup): Observable<any> {
        return this.apiService.post(this.route + '/list/affair-products', this.formatFiltersStatusForm(form));
    }

    initPreparationList(form?: FormGroup): Observable<any> {
        return this.apiService.post(this.route + '/list/preparation', this.formatFiltersPreparationForm(form));
    }

    dashboardVolume(form?: FormGroup): Observable<any> {
        return this.apiService.post(this.route + '/dashboard/volume', this.formatFiltersStatusForm(form));
    }

    prepare(interventionsIds: any): Observable<HttpResponse<any>> {
        return this.apiService.postBlob(this.route + '/prepare', {
            'interventionsIds': interventionsIds
        });
    }

    getGeneratedName(form: any): void {
        this.apiService.post(this.route + '/get-generated-name', this.formatGeneratedNameForm(form))
            .subscribe(
                (data: any) => {
                    this.generatedName$.next(data);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    getGeneratedDescription(form: any): void {
        this.apiService.post(this.route + '/get-generated-description', this.formatGeneratedDescriptionForm(form))
            .subscribe(
                (data: any) => {
                    this.generatedDescription$.next(data);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    pushToYsier(id: number): Observable<any> {
        return this.apiService.get(`${this.route}/${id}/push-yzier`);
    }

    refreshIntervention(): void {
        this.apiService.get(this.route + '/' + this.currentIntervention.id)
            .subscribe(
                (object) => {
                    this.refreshIntervention$.next(object);
                    this.currentIntervention = object;
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    createForInspection(form: FormGroup): Observable<void> {
        return this.apiService.post(`${this.route}/inspections/create`, this.formatProgramForm(form));
    }

    private formToDatatableParameters(form: FormGroup): InterventionDatatableParameters {
        if (form) {
            return {
                selectedAffair: form.value.selectedAffair,
                selectedMarketsTypes: this.formFormatterService.formatSelectMultiple(form.value.selectedMarketsTypes),
                selectedAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedAgencies),
                selectedAssistants: this.formFormatterService.formatSelectMultiple(form.value.selectedAssistants),
                selectedTechnicians: this.formFormatterService.formatSelectMultiple(form.value.selectedTechnicians),
                selectedConformities: this.formFormatterService.formatSelectMultiple(form.value.selectedConformities),
                selectedInterventionsStatuses: this.formFormatterService.formatSelectMultiple(form.value.selectedInterventionsStatuses),
                selectedProducts: this.formFormatterService.formatSelectMultiple(form.value.selectedProducts),
                selectedProductsStatuses: this.formFormatterService.formatSelectMultiple(form.value.selectedProductsStatuses),
                selectedDocumentsCustomersMissing: this.formFormatterService.formatSelectMultiple(form.value.selectedDocumentsCustomersMissing),
                selectedAffairsGoals: this.formFormatterService.formatSelectMultiple(form.value.selectedAffairsGoals),
                selectedAffairsStatuses: this.formFormatterService.formatSelectMultiple(form.value.selectedAffairsStatuses),
                selectedAffairsMonitoringTypes: this.formFormatterService.formatSelectMultiple(form.value.selectedAffairsMonitoringTypes),
                affairMonitoringTypeLevel: form.value.affairMonitoringTypeLevel,
                isAfterIntervention: form.value.isAfterIntervention,
                dateInterventionMin: form.value.dateInterventionMin ? new Date(form.value.dateInterventionMin).toJSON() : null,
                dateInterventionMax: form.value.dateInterventionMax ? new Date(form.value.dateInterventionMax).toJSON() : null,
                isNotBilled: this.formFormatterService.formatBoolean(form.value.isNotBilled),
                isWaitingCheck: this.formFormatterService.formatBoolean(form.value.isWaitingCheck),
                isDeliveredHouse: this.formFormatterService.formatBoolean(form.value.isDeliveredHouse),
                isOffice: this.formFormatterService.formatBoolean(form.value.isOffice),
                page: form.value.page,
                size: form.value.size,
                search: form.value.search,
                addressSearch: form.value.addressSearch,
                sortField: form.value.sortField,
                sortOrder: form.value.sortOrder,
                individualSearch: form.value.individualSearch,
            };
        }
        return null;
    }

    private formatCreateForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.techniciansIds = this.formFormatterService.formatSelectMultipleToIntArray(values.techniciansIds);
        values.affairProducts = this.formFormatterService.filterCheckedValues(values.affairProducts);
        values.housings = this.formFormatterService.filterCheckedValues(values.housings);
        values.startDate = values.startDate ? new Date(values.startDate).toString() : null;
        values.duration = values.duration ? new Date(values.duration).toTimeString() : null;
        values.contacts = this.formFormatterService.formatSelectMultipleToIntArray(values.contacts);
        values.affairsContacts = this.formFormatterService.formatSelectMultipleToIntArray(values.affairsContacts);
        values.buildingsIds = this.formFormatterService.formatSelectMultipleToIntArray(values.buildingsIds);
        return values;
    }

    private formatProgramForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.techniciansIds = this.formFormatterService.formatSelectMultipleToIntArray(values.techniciansIds);
        values.startDate = values.startDate ? new Date(values.startDate).toString() : null;
        values.duration = values.duration ? new Date(values.duration).toTimeString() : null;
        return values;
    }

    private formatUpdateForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.techniciansIds = this.formFormatterService.formatSelectMultipleToIntArray(values.techniciansIds);
        values.affairProducts = this.formFormatterService.filterCheckedValues(values.affairProducts);
        values.housings = this.formFormatterService.filterCheckedValues(values.housings);
        values.contacts = this.formFormatterService.formatSelectMultipleToIntArray(values.contacts);
        values.affairsContacts = this.formFormatterService.formatSelectMultipleToIntArray(values.affairsContacts);
        values.buildingsIds = this.formFormatterService.formatSelectMultipleToIntArray(values.buildingsIds);
        return values;
    }

    private formatFiltersStatusForm(form) {
        const values = this.formFormatterService.createFormCopy(form);
        values.userId = this.formFormatterService.formatSelectSingle(values.userId);
        values.dayNumber = this.formFormatterService.formatSelectSingle(values.dayNumber);
        return values;
    }

    private formatFiltersPreparationForm(form) {
        const values = this.formFormatterService.createFormCopy(form);
        values.userId = this.formFormatterService.formatSelectSingle(values.userId);
        values.dateInterventionMin = new Date(form.value.dateInterventionMin).toJSON();
        values.dateInterventionMax = new Date(form.value.dateInterventionMax).toJSON();
        return values;
    }

    private formatGeneratedNameForm(form: any) {
        const values = this.formFormatterService.createFormCopy(form);
        values.affairProducts = this.formFormatterService.filterCheckedValues(values.affairProducts);
        values.housings = this.formFormatterService.filterCheckedValues(values.housings);
        return values;
    }

    private formatGeneratedDescriptionForm(form: any) {
        const values = this.formFormatterService.createFormCopy(form);
        values.worksSupervisorId = this.formFormatterService.formatSelectSingle(values.worksSupervisorId);
        values.contacts = this.formFormatterService.formatSelectMultipleToIntArray(values.contacts);
        values.affairsContacts = this.formFormatterService.formatSelectMultipleToIntArray(values.affairsContacts);
        values.housings = this.formFormatterService.filterCheckedValues(values.housings);
        return values;
    }

    private formatAffairListForm(form: any) {
        const values = this.formFormatterService.createFormCopy(form);
        values.selectedConformities = this.formFormatterService.formatSelectMultiple(form.value.selectedConformities);
        values.selectedInterventionsStatuses = this.formFormatterService.formatSelectMultiple(form.value.selectedInterventionsStatuses);
        return values;
    }


    private getDurationDate(intervention: Intervention): Date {
        let dateTimeString = new Date().toDateString() + " " + intervention.duration;
        return new Date(dateTimeString);
    }


    public getFiltersForm(formValue?: any, marketTypeId?: number): FormGroup {
        let selectedMarketsTypes = this.commonService.getDefaultFormNullValue();
        if (marketTypeId) {
            selectedMarketsTypes = [{ label: marketTypeId, value: marketTypeId }];
        } else if (formValue?.selectedMarketsTypes) {
            selectedMarketsTypes = this.commonService.valueToFormSelect(formValue.selectedMarketsTypes)
        }
        const selectedAssistants = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAssistants) : this.commonService.getDefaultFormNullValue();
        const selectedTechnicians = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedTechnicians) : this.commonService.getDefaultFormNullValue();
        const selectedAgencies = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAgencies) : this.commonService.getDefaultFormNullValue();
        const selectedConformities = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedConformities) : this.commonService.getDefaultFormNullValue();
        const selectedInterventionsStatuses = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedInterventionsStatuses) : this.commonService.getDefaultFormNullValue();
        const selectedProducts = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedProducts) : this.commonService.getDefaultFormNullValue();
        const selectedProductsStatuses = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedProductsStatuses) : this.commonService.getDefaultFormNullValue();
        const selectedDocumentsCustomersMissing = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedDocumentsCustomersMissing) : this.commonService.getDefaultFormNullValue();
        const dateInterventionMin = formValue ?
            this.commonService.valueToFormDate(formValue.dateInterventionMin) : this.commonService.getDefaultFormNullValue();
        const dateInterventionMax = formValue ?
            this.commonService.valueToFormDate(formValue.dateInterventionMax) : this.commonService.getDefaultFormNullValue();
        const deliveredDateMin = formValue ?
            this.commonService.valueToFormDate(formValue.deliveredDateMin) : this.commonService.getDefaultFormNullValue();
        const deliveredDateMax = formValue ?
            this.commonService.valueToFormDate(formValue.deliveredDateMax) : this.commonService.getDefaultFormNullValue();
        const selectedAffairsGoals = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAffairsGoals) : this.commonService.getDefaultFormNullValue();
        const selectedAffairsStatuses = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAffairsStatuses) : this.commonService.getDefaultFormNullValue();
        const selectedAffairsMonitoringTypes = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAffairsMonitoringTypes) : this.commonService.getDefaultFormNullValue();
        const affairMonitoringTypeLevel = formValue ?
            formValue.affairMonitoringTypeLevel : 1;
        const isAfterIntervention = formValue ?
            formValue.isAfterIntervention : null;
        const isNotBilled = formValue ?
            formValue.isNotBilled : this.commonService.getDefaultFormCheckboxValue();
        const isWaitingCheck = formValue ?
            formValue.isWaitingCheck : this.commonService.getDefaultFormCheckboxValue();
        const isDeliveredHouse = formValue ?
            formValue.isDeliveredHouse : this.commonService.getDefaultFormCheckboxValue();
        const isOffice = formValue ?
            formValue.isOffice : this.commonService.getDefaultFormCheckboxValue();
        const page = formValue ?
            formValue.page : 1;
        const size = formValue ?
            formValue.page : 10;
        const search = formValue ?
            formValue.search : this.commonService.getDefaultFormStringValue();
        const addressSearch = formValue ?
            formValue.addressSearch : this.commonService.getDefaultFormStringValue();
        const sortField = formValue ?
            formValue.sortField : this.commonService.getDefaultFormNullValue();
        const sortOrder = formValue ?
            formValue.sortOrder : this.commonService.getDefaultFormNullValue();

        return this.formBuilder.group({
            selectedMarketsTypes: [selectedMarketsTypes],
            selectedAssistants: [selectedAssistants],
            selectedTechnicians: [selectedTechnicians],
            selectedAgencies: [selectedAgencies],
            selectedConformities: [selectedConformities],
            selectedInterventionsStatuses: [selectedInterventionsStatuses],
            selectedProducts: [selectedProducts],
            selectedProductsStatuses: [selectedProductsStatuses],
            dateInterventionMin: [dateInterventionMin],
            dateInterventionMax: [dateInterventionMax],
            deliveredDateMin: [deliveredDateMin],
            deliveredDateMax: [deliveredDateMax],
            selectedDocumentsCustomersMissing: [selectedDocumentsCustomersMissing],
            selectedAffairsGoals: [selectedAffairsGoals],
            selectedAffairsStatuses: [selectedAffairsStatuses],
            selectedAffairsMonitoringTypes: [selectedAffairsMonitoringTypes],
            affairMonitoringTypeLevel: [affairMonitoringTypeLevel],
            isAfterIntervention: [isAfterIntervention],
            indicators: [],
            isNotBilled: [isNotBilled],
            isWaitingCheck: [isWaitingCheck],
            isDeliveredHouse: [isDeliveredHouse],
            isOffice: [isOffice],
            size: size,
            page: page,
            search: search,
            addressSearch: addressSearch,
            sortField: sortField,
            sortOrder: sortOrder,
            individualSearch: new FormArray([])
        });
    }


}
