import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { SubmitButton } from '@libs/models/src';
import { NotifService } from '@libs/services/src';
import { AffairDocumentCustomerService } from '@libs/services/src/lib/affair-document-customer.service';
import { AffairDocumentDeliveredService } from '@libs/services/src/lib/affair-document-delivered.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-affair-show-modal-rename-document',
  templateUrl: './affair-show-modal-rename-document.component.html',
  styleUrls: ['./affair-show-modal-rename-document.component.scss']
})
export class AffairShowModalRenameDocumentComponent implements OnInit {

  @Input() affair: any;
  @Input() document: any;
  @Input() filtersParam: any;
  @Input() affairDocumentService: AffairDocumentCustomerService | AffairDocumentDeliveredService;
  public form: FormGroup;
  public submitButton: SubmitButton = {
    text: 'Renommer le document',
    originalText: 'Renommer le document',
    icon: faPen,
    originalIcon: faPen,
    enabled: true
  };

  constructor(
    private modal: NzModalRef,
    private notifService: NotifService,
  ) { }

  ngOnInit() {
    this.affairDocumentService.setSubmitButton(this.submitButton);
    this.form = this.affairDocumentService.getRenameForm(this.document);
  }

  submitForm() {
    if (this.isValidForm()) {
      this.affairDocumentService.rename(this.affair.id, this.form).subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        }
      );
    }
  }

  isValidForm(): boolean {
    return this.form.valid;
  }

}
