import { ApiService } from '@eros-front/api';
import { ModelWithDatatableAndModalCrud } from './classes/model-datatable-modal-crud';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { Router } from '@angular/router';
import { Affair, AffairMonitoring, AffairMonitoringType } from '@libs/models/src';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { SelectService } from './utilities/select.service';

@Injectable()
export class AffairMonitoringService extends ModelWithDatatableAndCrudObservable {

    public route = '/affairs-monitoring';
    public affairsMonitoring$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private commonService: CommonService,
        private selectService: SelectService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route);
    }

    public getAll(): Observable<any> {
        return super.getAll(this.route);
    }

    public store(form: any): Observable<any> {
        return super.store(form.value, this.route);
    }

    public update(id: number, form: any): Observable<any> {
        return super.update(id, form.value, this.route);
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route);
    }

    public markAsClosed(id:number): Observable<any> {
        return this.apiService.get(this.route +  '/' + id + '/mark-as-closed');
    }

    getByAffair(affairId) {
        this.apiService.get(this.route + '/affair/' + affairId)
            .subscribe(
                (objects) => {
                    this.affairsMonitoring$.next(objects);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    getForm(affair: Affair, affairMonitoring?: AffairMonitoring) {
        const affairMonitoringTypeId = affairMonitoring ?
            this.commonService.valueToFormString(affairMonitoring.affairMonitoringTypeId) : this.commonService.getDefaultFormStringValue();
        const text = affairMonitoring ?
            this.commonService.valueToFormString(affairMonitoring.text) : this.commonService.getDefaultFormStringValue();
        const isAfterIntervention = affairMonitoring ?
            affairMonitoring.isAfterIntervention : false;
        
        return this.formBuilder.group({
            affairId: [affair.id, Validators.required],
            affairMonitoringTypeId: [affairMonitoringTypeId, Validators.required],
            text: [text, Validators.required],
            isAfterIntervention: [isAfterIntervention]
        });

    }
}
