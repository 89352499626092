import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'eros-front-app-without-sidebar',
  templateUrl: './app-without-sidebar.component.html',
  styleUrls: ['./app-without-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWithoutSidebarComponent {
}
