import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { AffairOfficeFile, OfficeFile, SubmitButton } from '@libs/models/src';
import { NotifService, SwalService } from '@libs/services/src';
import { AffairOfficeFileService } from '@libs/services/src/lib/affair-office-file.service';
import { OfficeFileService } from '@libs/services/src/lib/office-file.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-affair-show-office-file-modal',
  templateUrl: './affair-show-office-file-modal.component.html',
  styleUrls: ['./affair-show-office-file-modal.component.scss']
})
export class AffairShowOfficeFileModalComponent implements OnInit {
  @Input() affairOfficeFile: AffairOfficeFile;
  @Input() form: FormGroup;
  @Input() sendSettings: any;
  @Input() sendSettingsTypes: any;
  @Input() contacts: any;
  public submitButton: SubmitButton;
  public selectRecipients = [];
  public selectRecipientsCopy = [];
  public displayRecipientsCopy = [];
  public faPlus = faPlus;
  public faMinus = faMinus;
  public modules = {
    'toolbar': [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
    ]
  }

  constructor(
    private modal: NzModalRef,
    private affairOfficeFileService: AffairOfficeFileService,
    private notifService: NotifService,
    private swalService: SwalService,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit() {
    this.determinateSubmitButton();
    this.affairOfficeFileService.setSubmitButton(this.submitButton);
    this.affairOfficeFileService.initAffairsSendSettings(this.form, this.sendSettings, this.sendSettingsTypes, this.contacts, this.affairOfficeFile);
    this.initDisplayRecipients(this.affairOfficeFile);
  }

  initDisplayRecipients(officeFile: any) {
    this.sendSettings.forEach(sendSetting => {
      let display = false;
      let displayCopy = false;
      let displayRecipientsCopy = false;
      let affairSendSetting = null;
      if (officeFile) {
        if (this.affairOfficeFileService.isInstanceOfAffairOfficeFile(officeFile)) {
          affairSendSetting = officeFile.affairsSendSettings.find(x => x.sendSettingId == sendSetting.id);
        } else {
          affairSendSetting = officeFile.officesFilesSettings.find(x => x.sendSettingId == sendSetting.id);
        }
        if (affairSendSetting.sendSettingsTypes.length > 0) {
          let found = affairSendSetting.sendSettingsTypes.find(x => x.key === "contacts");
          if (found) {
            display = true;
          }
        }
        if (affairSendSetting.sendSettingsTypesCopy.length) {
          let found = affairSendSetting.sendSettingsTypesCopy.find(x => x.key === "contacts");
          if (found) {
            displayCopy = true;
          }
          displayRecipientsCopy = true
        }
      }
      this.selectRecipients[sendSetting.id] = display;
      this.selectRecipientsCopy[sendSetting.id] = displayCopy;
      this.displayRecipientsCopy[sendSetting.id] = displayRecipientsCopy;
    });
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.affairOfficeFile) {
        this.affairOfficeFileService.update(this.affairOfficeFile.id, this.form).subscribe((success) => {
          this.modal.close();
          this.notifService.showSuccessNotif(success);
        });
      } else {
        this.affairOfficeFileService.store(this.form).subscribe((success) => {
          this.modal.close();
          this.notifService.showSuccessNotif(success);
        });
      }
    }
  }

  onChangeSettingType(sendSettingId: number, sendSettingTypeKey: string, index: number): void {
    if (sendSettingTypeKey == 'contacts') {
      this.selectRecipients[sendSettingId] = !this.selectRecipients[sendSettingId];
      if (!this.selectRecipients[sendSettingId]) {
        const sendSettingsFormArray = this.form.get('affairsSendSettings') as FormArray;
        sendSettingsFormArray.at(index).patchValue({
          recipients: []
        })
      }
    }
  }

  onChangeSettingTypeCopy(sendSettingId: number, sendSettingTypeKey: string, index: number): void {
    if (sendSettingTypeKey == 'contacts') {
      this.selectRecipientsCopy[sendSettingId] = !this.selectRecipientsCopy[sendSettingId];
      if (!this.selectRecipientsCopy[sendSettingId]) {
        const sendSettingsCopyFormArray = this.form.get('affairsSendSettings') as FormArray;
        sendSettingsCopyFormArray.at(index).patchValue({
          recipientsCopy: []
        })
      }
    }
  }

  onChangeDisplayRecipientsCopy(sendSettingId: number, index: number): void {
    this.displayRecipientsCopy[sendSettingId] = !this.displayRecipientsCopy[sendSettingId];
    if (!this.displayRecipientsCopy[sendSettingId]) {
      const sendSettingsCopyFormArray = this.form.get('affairsSendSettings') as FormArray;
      sendSettingsCopyFormArray.at(index).patchValue({
        recipientsCopy: []
      })
      let sendSettingsCopyArrayFormArray = sendSettingsCopyFormArray.at(index).get('sendSettingsTypesCopy') as FormArray;
      let values = [];
      this.sendSettingsTypes.forEach(sendSettingType => {
        values.push({
          sendSettingTypeId: sendSettingType.id,
          checked: false
        });

      });
      sendSettingsCopyArrayFormArray.patchValue(values);
    }
  }

  resetFormWithOfficeFile(): void {
    const resetForm = this.affairOfficeFileService.getForm(this.affairOfficeFile);
    this.affairOfficeFileService.initAffairsSendSettings(resetForm, this.sendSettings, this.sendSettingsTypes, this.contacts, this.affairOfficeFile.officeFile);
    console.log(resetForm.value);
    this.form.reset(resetForm.value);
    //this.initDisplayRecipients(this.affairOfficeFile.officeFile);
  }

  onDeleteClicked(): void {
    const that = this;
    const swalOptions = this.swalService.getSwalDeleteOptions({
      text: 'La fiche bureau affaire sera supprimée'
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.spinner.show('common-spinner');
        that.affairOfficeFileService.delete(that.affairOfficeFile.id).subscribe(
          (success) => {
            that.notifService.showSuccessNotif(success);
            this.modal.close();
            this.spinner.hide('common-spinner');
          },
          () => {
             this.spinner.hide('common-spinner');
          }
        )
      }
    });
  }

  private determinateSubmitButton() {
    this.submitButton = this.affairOfficeFileService.determinateSubmitButton(this.affairOfficeFile, 'la fiche bureau affaire');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
