import { Router } from '@angular/router';
import { Subject, BehaviorSubject, Subscription } from 'rxjs';
import { GroupService } from '@eros-front/services';
import { DataTableColumn } from '@eros-front/services';
import { Component, OnInit, AfterViewInit, Input, OnChanges, ViewChild, OnDestroy, Host } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-customers-consult-groups-index',
  templateUrl: './customers-consult-groups-index.component.html',
  styleUrls: ['./customers-consult-groups-index.component.scss']
})
export class CustomersConsultGroupsIndexComponent implements OnInit, AfterViewInit {
  selectedGroups$: Subscription;
  public table: DataTables.Api;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'Ref'
    },
    {
      attr: 'name',
      name: 'Groupe'
    },
    {
      attr: 'brandsNames',
      searchColumn: 'brands.name',
      name: 'Marques'
    },
    {
      attr: 'agenciesNames',
      searchColumn: 'agencies.name',
      name: 'Agences',
    },
    {
      attr: 'rivalsNames',
      searchColumn: 'rivals.name',
      name: 'Concurrents',
    },
    {
      attr: 'managersNames',
      searchColumn: 'managers.lastname',
      name: 'Responsables',
    },
  ];
  public datatableSelector = 'customers-groups-datatable';
  public ajaxParameters: any;

  constructor(
    private groupService: GroupService,
    private router: Router
  ) { }


  ngOnInit() {
  }

  ngAfterViewInit() {
    this.groupService.initDataTable(this.datatableSelector, this.columns);
  }

  ngOnDestroy(): void {
  }
}
