import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Agency, Payer } from '@libs/models/src';
import { AgencyService, CommonService, FormFormatterService, MlAgencyService, PayerService, ProductService, ThingService } from '@libs/services/src';
import { InvoiceDetailService } from '@libs/services/src/lib/invoice-detail.service';
import { InvoiceService } from '@libs/services/src/lib/invoice.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Subscription } from 'rxjs';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-invoice-manual-create',
  templateUrl: './invoice-manual-create.component.html',
  styleUrls: ['./invoice-manual-create.component.scss']
})
export class InvoiceManualCreateComponent implements OnInit, OnDestroy {

  public stepIndex = 0;
  public invoicingInformationsForm: FormGroup;
  public addProductsForm: FormGroup;
  public comment = '';
  public mlAgencies = [];
  public agencies = [];
  public payers = [];
  public payersPreferences = [];
  public products = [];
  public vatRates = [];
  private mlAgencies$: Subscription;
  private agency$: Subscription;
  private agencies$: Subscription;
  private payer$: Subscription;
  private payers$: Subscription;
  private payersPreferences$: Subscription;
  private products$: Subscription;
  private vatRates$: Subscription;

  constructor(
    private titleService: Title,
    private invoiceService: InvoiceService,
    private invoiceDetailService: InvoiceDetailService,
    private mlAgencyService: MlAgencyService,
    private agencyService: AgencyService,
    private payerService: PayerService,
    private thingService: ThingService,
    private productService: ProductService,
    private formFormatterService: FormFormatterService,
    private formBuilder: FormBuilder,
    private commonService: CommonService
  ) {
    this.titleService.setTitle(`Création d'une facture${tabSuffix}`);
    this.initSubscriptions();
  }

  ngOnInit() {
    this.getValues();
    this.invoicingInformationsForm = this.getInvoicingInformationsForm();
    this.addProductsForm = this.getAddProductsForm();
  }

  ngOnDestroy() {
    this.mlAgencies$.unsubscribe();
    this.agency$.unsubscribe();
    this.agencies$.unsubscribe();
    this.payer$.unsubscribe();
    this.payers$.unsubscribe();
    this.payersPreferences$.unsubscribe();
    this.vatRates$.unsubscribe();
    this.products$.unsubscribe();
  }

  getFormControls(form: FormGroup, controlName: string) {
    return (form.get(controlName) as FormArray).controls;
  }

  private initSubscriptions() {
    this.mlAgencies$ = this.mlAgencyService.mlAgenciesSelect$.subscribe(
      (data) => {
        if (data) {
          this.mlAgencies = data;
        }
      }
    );
    this.agency$ = this.agencyService.agency$.subscribe(
      (data) => {
        if (data) {
          this.completePayerInformation(data);
        }
      }
    );
    this.agencies$ = this.agencyService.agenciesSelect$.subscribe(
      (data) => {
        if (data) {
          this.agencies = data;
        }
      }
    );
    this.payer$ = this.payerService.payer$.subscribe(
      (data) => {
        if (data) {
          this.completePayerInformation(data);
        }
      }
    );
    this.payers$ = this.payerService.payers$.subscribe(
      (data) => {
        if (data) {
          this.payers = data;
        }
      }
    );
    this.payersPreferences$ = this.thingService.payersPreferences$.subscribe(
      (data) => {
        if (data) {
          this.payersPreferences = data;
        }
      }
    );
    this.vatRates$ = this.thingService.vatRates$.subscribe(
      (data) => {
        if (data) {
          this.vatRates = data;
        }
      }
    );
    this.products$ = this.productService.products$.subscribe(
      (data) => {
        if (data) {
          this.products = data;
        }
      }
    );
  }

  private getValues() {
    this.mlAgencyService.getForSelect();
    this.agencyService.getForSelect();
    this.payerService.getAll();
    this.thingService.getPayersPreferences();
    this.thingService.getVatRatesSelect();
    this.productService.getAll();
  }

  /**
   * InvoicingInformations
   */
  onSelectedAgencyChange(value: any) {
    if (value && value.value) {
      this.agencyService.get(value.value);
      this.invoicingInformationsForm.patchValue({
        payerId: this.commonService.getDefaultFormNullValue()
      });
    } else {
      this.agencyService.agency$.next(null);
      this.completePayerInformation(null);
    }
  }

  onSelectedPayerChange(value: any) {
    if (value && value.id) {
      this.payerService.get(value.id);
      this.invoicingInformationsForm.patchValue({
        agencyId: this.commonService.getDefaultFormNullValue()
      });
    } else {
      this.payerService.payer$.next(null);
      this.completePayerInformation(null);
    }
  }

  onPayerAddressChange(address: Address) {
    this.invoicingInformationsForm = this.formFormatterService.completeGoogleAddress(
      this.invoicingInformationsForm, address,
      {
        route: 'payerAddress',
        postal_code: 'payerPostalCode',
        locality: 'payerLocality',
        concatStreetWithRoute: true
      }
    );
  }

  isValidInvoicingInformationsForm() {
    return this.invoicingInformationsForm.valid;
  }

  onNextInvoicingInformationsClicked() {
    if (this.isValidInvoicingInformationsForm()) {
      this.stepIndex++;
    }
  }

  private completePayerInformation(entity?: Agency | Payer): void {
    const defaultString = this.commonService.getDefaultFormStringValue();
    this.invoicingInformationsForm.patchValue({
      payerName: entity ? entity.name : defaultString,
      accountingCode: entity ? entity.accountingCode : defaultString,
      payerAddress: entity ? entity.address : defaultString,
      payerAdditionalAddress: entity ? entity.additionalAddress : defaultString,
      payerPostalCode: entity ? entity.postalCode : defaultString,
      payerLocality: entity ? entity.locality : defaultString
    });
  }

  private getInvoicingInformationsForm(): FormGroup {
    const defaultString = this.commonService.getDefaultFormStringValue();
    const defaultSelect = this.commonService.getDefaultFormNullValue();
    return this.formBuilder.group({
      mlAgencyId: [defaultSelect, Validators.required],
      agencyId: defaultSelect,
      payerId: defaultSelect,
      payerName: [defaultString, Validators.required],
      payerPreference: [0],
      accountingCode: [defaultString, Validators.required],
      payerAddress: [defaultString, Validators.required],
      payerAdditionalAddress: defaultString,
      payerPostalCode: [defaultString, Validators.required],
      payerLocality: [defaultString, Validators.required]
    });
  }

  /**
   * AddProducts
   */
  isValidAddProductsForm() {
    return this.addProductsForm.valid && this.getFormControls(this.addProductsForm, 'products').length > 0;
  }

  onPreviousAddProductsClicked() {
    this.stepIndex--;
  }

  onNextAddProductsClicked() {
    if (this.isValidAddProductsForm()) {
      this.stepIndex++;
    }
  }

  addProductRow() {
    let products = this.addProductsForm.get('products') as FormArray;
    products.push(this.invoiceDetailService.getManualCreateForm());
  }

  deleteProductRow(ref: string) {
    const formArray = this.addProductsForm.get('products') as FormArray;
    for (let i = 0; i < formArray.controls.length; i++) {
      const control = formArray.controls[i];
      if (control.value.rowId === ref) {
        formArray.removeAt(i);
        break;
      }
    }
  }

  onSelectProductChange(item: any, ref: string) {
    if (item) {
      const control = this.getControlOfFormArray(ref);
      if (control) {
        control.patchValue({
          ref: item.name,
          accountingCode: item.accountingCode,
          designation: item.designation,
          vatRate: item.vatRate
        });
      }
    }
  }

  refreshTotalPrice(ref: string) {
    const control = this.getControlOfFormArray(ref);
    if (control) {
      control.patchValue({
        totalWithoutTaxes: this.commonService.formatNumberToLocale(control.value.quantity * control.value.unitPrice)
      });
    }
  }

  private getAddProductsForm(): FormGroup {
    return this.formBuilder.group({
      products: new FormArray([])
    });
  }

  private getControlOfFormArray(ref: string): any {
    const formArray = this.addProductsForm.get('products') as FormArray;
    for (let i = 0; i < formArray.controls.length; i++) {
      const control = formArray.controls[i];
      if (control.value.rowId === ref) {
        return control;
      }
    }
    return null;
  }

  /**
   * Summary
   */
  onPreviousSummaryClicked() {
    this.stepIndex--;
  }

  getTotalWithoutTaxes() {
    let total = 0;
    const formArray = this.addProductsForm.get('products') as FormArray;
    for (let i = 0; i < formArray.controls.length; i++) {
      const control = formArray.controls[i];
      total += (control.value.unitPrice * control.value.quantity);
    }
    return this.commonService.formatNumberToLocale(total);
  }

  getVatAmount() {
    let total = 0;
    const formArray = this.addProductsForm.get('products') as FormArray;
    for (let i = 0; i < formArray.controls.length; i++) {
      const control = formArray.controls[i];
      const price = (control.value.unitPrice * control.value.quantity);
      total += (price / 100) * control.value.vatRate;
    }
    return this.commonService.formatNumberToLocale(total);
  }

  getTotalWithTaxes() {
    let total = 0;
    const formArray = this.addProductsForm.get('products') as FormArray;
    for (let i = 0; i < formArray.controls.length; i++) {
      const control = formArray.controls[i];
      const price = (control.value.unitPrice * control.value.quantity);
      const vatAmount = (price / 100) * control.value.vatRate;
      total += price + vatAmount;
    }
    return this.commonService.formatNumberToLocale(total);
  }

  areValidForms() {
    return this.isValidInvoicingInformationsForm() && this.isValidAddProductsForm();
  }

  finalizeOrDraftInvoiceClicked(isTemporary: boolean) {
    if (this.areValidForms()) {
      const form = this.formBuilder.group({
        mlAgencyId: [this.invoicingInformationsForm.value.mlAgencyId, Validators.required],
        agencyId: this.invoicingInformationsForm.value.agencyId,
        payerId: this.invoicingInformationsForm.value.payerId,
        accountingCode: [this.invoicingInformationsForm.value.accountingCode, Validators.required],
        payerPreference: this.invoicingInformationsForm.value.payerPreference,
        payerName: [this.invoicingInformationsForm.value.payerName, Validators.required],
        payerAddress: [this.invoicingInformationsForm.value.payerAddress, Validators.required],
        payerAdditionalAddress: this.invoicingInformationsForm.value.payerAdditionalAddress,
        payerPostalCode: [this.invoicingInformationsForm.value.payerPostalCode, Validators.required],
        payerLocality: [this.invoicingInformationsForm.value.payerLocality, Validators.required],
        comment: this.comment,
        isTemporary: isTemporary,
        invoiceDetails: this.addProductsForm.get('products') as FormArray
      });
      this.invoiceService.store(form);
    }
  }
}
