import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { SubmitButton } from '@libs/models/src';
import { ShuttleSheetTemplateService, ShuttleSheetTemplateBuilding, SubmitButtonService } from '@libs/services/src';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-shuttle-sheet-modal-template',
  templateUrl: './shuttle-sheet-modal-template.component.html',
  styleUrls: ['./shuttle-sheet-modal-template.component.scss']
})
export class ShuttleSheetModalTemplateComponent implements OnInit, AfterViewInit {

  @Input() model: any;
  @Input() object: ShuttleSheetTemplateBuilding;
  @ViewChild('ShuttleSheetModalTemplate', { static: false }) childModal: ModalDirective;
  public objectCopy: ShuttleSheetTemplateBuilding = null;
  public submitButton: SubmitButton;

  constructor(
    private shuttleService: ShuttleSheetTemplateService,
    private submitButtonService: SubmitButtonService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.shuttleService.setModalTemplate(this.childModal);
  }

  onModalShown() {
    this.objectCopy = JSON.parse(JSON.stringify(this.object));
    this.determinateSubmitButton();
    this.shuttleService.setTemplateSubmitButton(this.submitButton);
  }

  onModalHidden() {
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm()) {
      let found = false;
      let modelCopy = JSON.parse(JSON.stringify(this.model));
      for (let i = 0; i < modelCopy.buildings.length; i++) {
        if (modelCopy.buildings[i].key === this.objectCopy.key) {
          modelCopy.buildings[i] = this.objectCopy;
          found = true;
          break;
        }
      }
      if (!found) {
        modelCopy.buildings.push(this.objectCopy);
      }
      // if (!found) {
      //   for (let i = 0; i < modelCopy.equipments.length; i++) {
      //     if (modelCopy.equipments[i].key === this.objectCopy.key) {
      //       modelCopy.equipments[i] = { ...this.objectCopy };
      //       break;
      //     }
      //   }
      // }
      this.shuttleService.updateModel(modelCopy);
    }
  }

  isValidForm() {
    return true;
  }

  onAddTemplateClicked() {
    this.objectCopy.templates.push("");
  }

  onInputValueChanged(evt: any, index: number) {
    if (index < this.objectCopy.templates.length) {
      this.objectCopy.templates[index] = evt.target.value;
    }
  }

  onDeleteTemplateClicked(index: number) {
    this.objectCopy.templates.splice(index, 1);
  }

  private determinateSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      text: 'Mettre à jour',
      objectName: 'les templates',
      icon: faSave
    });
  }

}
