import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { NotifService } from './utilities/notif.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubmitButtonService } from './utilities/submit-button.service';
import { Affair, AffairProduct, Product, SubmitButton } from '@eros-front/models';
import { catchError, finalize } from 'rxjs/operators';
import { SwalService } from './utilities/swal.service';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { ModelWithDatatableAndCrud } from './classes/model-datatable-crud';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DataTableColumn } from './classes/model-datatable';

export interface AffairProductDatatableParameters {
    selectedMlAgencies: string;
    selectedAgencies: string;
}

@Injectable()
export class AffairProductService extends ModelWithDatatableAndCrudObservable {

    private route = '/affair-products';
    private modalAdd: ModalDirective;
    private modalEdit: ModalDirective;
    private submitButtonEdit: SubmitButton;
    public affairProduct$ = new BehaviorSubject<any>(undefined);
    public affairProductsToInvoicing$ = new BehaviorSubject<any>(undefined);
    public affairProductsListToInvoicing$ = new BehaviorSubject<any>(undefined);
    public affairProductsFilters$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formFormatterService: FormFormatterService,
        private formBuilder: FormBuilder,
        private commonService: CommonService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }


    public initDataTable(selector: string, columns: DataTableColumn[], productKey: string) {
        return super.initializeDataTable({
            url: this.route + '/' + productKey + '/list',
            selector: '#' + selector,
            dataTableColumns: columns,
            useCheckbox: true,
            paging: false
        });
    }

    public get(id: number): Observable<any> {
        return this.apiService.get(this.route + '/' + id);
    }

    public storeMultiple(form: any): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.post(this.route, this.formatForm(form)).pipe(
            catchError(error => {
                this.swalService.showSwalError(error);
                return throwError(error);
            }),
            finalize(() => {
                this.submitButtonService.setEnabled(this.submitButton);
            })
        );
    }

    public updateMultiple(form: any): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.put(this.route + '/update-multiple', this.formatForm(form)).pipe(
            catchError(error => {
                this.swalService.showSwalError(error);
                return throwError(error);
            }),
            finalize(() => {
                this.submitButtonService.setEnabled(this.submitButton);
            })
        );
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route);
    }

    public cancel(id: number): Observable<any> {
        return this.apiService.get(this.route + '/cancel/' + id)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            )
    }

    public modify(id: number, form: any): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.put(this.route + '/modify/' + id, form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            );
    }

    public getAddForm(affair: Affair, product: Product, technicians: any): FormGroup {
        let productId = product.id;
        let productName = product.name;
        const quantity = 1;
        const price = this.getDefaultPrice(affair, product);
        const dateReceipt = this.commonService.getDefaultFormDateValue();
        const techniciansIds = affair.userTechnicianId ?
            technicians.filter(x => affair.userTechnicianId == x.value) : this.commonService.getDefaultFormNullValue();
        const productNrjId = product ?
            product.productNrjId : this.commonService.getDefaultFormNullValue();
        const syncNrj = product.productNrjId ?
            true : false;
        return this.formBuilder.group({
            productId: [productId, Validators.required],
            productName: [productName, Validators.required],
            quantity: [quantity, Validators.required],
            price: [price, Validators.required],
            dateReceipt: dateReceipt,
            techniciansIds: [techniciansIds, Validators.required],
            productNrjId: [productNrjId],
            syncNrj: [syncNrj],
        });
    }

    public getForm(affairProduct: any, technicians: any): FormGroup {
        let productId = null;
        let productName = '';
        const quantity = affairProduct ?
            this.commonService.valueToFormString(affairProduct.quantity) : 1;
        const price = affairProduct ?
            this.commonService.valueToFormString(affairProduct.price) : 1;
        const dateReceipt = affairProduct ?
            this.commonService.valueToFormDate(affairProduct.dateReceipt) : this.commonService.getDefaultFormDateValue();
        const dateRealisation = affairProduct ?
            this.commonService.valueToFormDate(affairProduct.dateRealisation) : this.commonService.getDefaultFormNullValue();
        const techniciansIds = affairProduct.technicians ?
            technicians.filter(x => affairProduct.technicians.map(x => x.id).includes(x.value)) : this.commonService.getDefaultFormNullValue();
        const productStatusId = affairProduct ?
            this.commonService.valueToFormSelect(affairProduct.productStatusId) : this.commonService.getDefaultFormNullValue();
        const productUrgencyLevelId = affairProduct ?
            this.commonService.valueToFormSelect(affairProduct.productUrgencyLevelId) : this.commonService.getDefaultFormNullValue();
        const comment = affairProduct && affairProduct.comment ?
            this.commonService.valueToFormString(affairProduct.comment) : this.commonService.getDefaultFormStringValue();
        if (affairProduct) {
            if (affairProduct.product) {
                productId = affairProduct.product.id;
                productName = affairProduct.product.name;
            } else {
                productId = affairProduct.id ? affairProduct.id : productId;
                productName = affairProduct.name ? affairProduct.name : productName;
            }
        }
        return this.formBuilder.group({
            id: [affairProduct.id, Validators.required],
            productId: [productId, Validators.required],
            productName: [productName, Validators.required],
            quantity: [quantity, Validators.required],
            price: [price, Validators.required],
            dateReceipt: dateReceipt,
            dateRealisation: dateRealisation,
            techniciansIds: [techniciansIds, Validators.required],
            productStatusId: [productStatusId, Validators.required],
            productUrgencyLevelId: [productUrgencyLevelId],
            comment: [comment],
        });
    }

    public getModifyForm(affairProduct: AffairProduct): FormGroup {
        return this.formBuilder.group({
            productId: [affairProduct.productId, Validators.required],
            price: [affairProduct.price, Validators.required]
        });
    }

    public setModalAdd(modal: ModalDirective): void {
        this.modalAdd = modal;
    }

    public showModalAdd(): void {
        this.modalAdd.show();
    }

    public hideModalAdd(): void {
        this.modalAdd.hide();
    }

    public setModalEdit(modal: ModalDirective): void {
        this.modalEdit = modal;
    }

    public showModalEdit(): void {
        this.modalEdit.show();
    }

    public hideModalEdit(): void {
        this.modalEdit.hide();
    }

    public setSubmitButtonEdit(button: SubmitButton): void {
        this.submitButtonEdit = button;
    }

    public getDefaultPrice(affair: Affair, product: Product): number {
        let defaultPrice = 0.0;
        affair.agency.products.forEach((price) => {
            if (price.marketTypeId == affair.marketTypeId && product.id == price.productId) {
                defaultPrice = price.price;
            }
        });
        return defaultPrice;
    }

    public getByAffairToInvoicing(affairId: number) {
        this.apiService.get(this.route + '/affair/' + affairId + '/to-invoicing')
            .subscribe(
                (data) => {
                    this.affairProductsToInvoicing$.next(data);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }


    public getFiltersForm(formValue?: any): FormGroup {
        const selectedAgencies = formValue ?
            formValue.selectedAgencies : this.commonService.getDefaultFormNullValue();
        const selectedMlAgency = formValue ?
            formValue.mlAgencyId : this.commonService.getDefaultFormNullValue();
        const selectedProductsStatuses = formValue ?
            formValue.selectedProductsStatuses : this.commonService.getDefaultFormNullValue();
        return this.formBuilder.group({
            selectedAgencies: [selectedAgencies],
            mlAgencyId: [selectedMlAgency, Validators.required],
            selectedProductsStatuses: [selectedProductsStatuses]
        });
    }

    public redrawDataTable(form: any): void {
        const affairDatatableParams: AffairProductDatatableParameters = {
            selectedMlAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedMlAgencies),
            selectedAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedAgencies),
        };
        super.redrawDataTable(affairDatatableParams);
    }

    public getFiltersForProductList() {
        this.apiService.get(`${this.route}/products/filters`)
            .subscribe(
                (object) => {
                    this.affairProductsFilters$.next(object);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );

    }

    public initList(productKey: string, form?: FormGroup): Observable<any> {
        return this.apiService.post(this.route + '/' + productKey + '/list', this.formatFiltersForm(form)).pipe(
            catchError(error => {
                this.swalService.showSwalError(error);
                return throwError(error);
            }),
        );
    }

    public initUncompletedList(form?: FormGroup): Observable<any> {
        return this.apiService.post(this.route + '/list/uncompleted', this.formatFiltersStatusForm(form)).pipe(
            catchError(error => {
                this.swalService.showSwalError(error);
                return throwError(error);
            }),
        );
    }

    public initCompletedList(form?: FormGroup): Observable<any> {
        return this.apiService.post(this.route + '/list/completed', this.formatFiltersStatusForm(form)).pipe(
            catchError(error => {
                this.swalService.showSwalError(error);
                return throwError(error);
            }),
        );
    }

    public initFinishedList(form?: FormGroup): Observable<any> {
        return this.apiService.post(this.route + '/list/finished', this.formatFiltersStatusForm(form)).pipe(
            catchError(error => {
                this.swalService.showSwalError(error);
                return throwError(error);
            }),
        );
    }

    private formatForm(form) {
        const values = this.formFormatterService.createFormCopy(form);
        values.affairProducts.forEach((value) => {
            value.techniciansIds = value.techniciansIds.map(x => x.value);
            value.dateReceipt = value.dateReceipt ? new Date(value.dateReceipt).toDateString() : null;
            value.dateRealisation = value.dateRealisation ? new Date(value.dateRealisation).toDateString() : null;
        });
        values.affairFields.forEach((value) => {
            if (value.type === "date") {
                value.value = value.value ? new Date(value.value).toDateString() : null;
            }
        });
        return values;
    }


    private formatFiltersForm(form) {
        const values = this.formFormatterService.createFormCopy(form);
        values.mlAgencyId = this.formFormatterService.formatSelectSingle(form.value.mlAgencyId);
        values.selectedAgencies = this.formFormatterService.formatSelectMultiple(form.value.selectedAgencies);
        values.selectedProductsStatuses = this.formFormatterService.formatSelectMultiple(form.value.selectedProductsStatuses);
        return values;
    }

    private formatFiltersStatusForm(form) {
        const values = this.formFormatterService.createFormCopy(form);
        values.userId = this.formFormatterService.formatSelectSingle(values.userId);
        values.dayNumber = this.formFormatterService.formatSelectSingle(values.dayNumber);
        values.selectedProducts = this.formFormatterService.formatSelectMultiple(values.selectedProducts);
        return values;
    }

}
