import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SelectModel } from '@libs/models/src';
import { AgencyService, DocumentDeliveredService, SelectService } from '@libs/services/src';
import { Subscription, zip } from 'rxjs';

@Component({
  selector: 'app-affairs-exports',
  templateUrl: './affairs-exports.component.html',
  styleUrls: ['./affairs-exports.component.scss']
})
export class AffairsExportsComponent implements OnInit, OnDestroy {
  public activeTab = "DOCUMENTS_DELIVERED";
  public form: FormGroup;
  private zip$: Subscription;
  public technicians: SelectModel[];
  public agencies: SelectModel[];
  public documentsDelivered: SelectModel[];
  public refresh = false;
  public appKey: string;

  constructor(
    private formBuilder: FormBuilder,
    private selectService: SelectService,
    private agencyService: AgencyService,
    private route: ActivatedRoute,
    private documentDeliveredService: DocumentDeliveredService,
  ) {
  }

  ngOnInit(): void {
    this.form = this.initForm();
    this.initSubscriptions();
    this.initValues();

    this.route.data.subscribe(data => {
      this.appKey = data.appKey;
    })
  }

  ngOnDestroy(): void {
    this.zip$?.unsubscribe();
  }

  initForm(): FormGroup {
    return this.formBuilder.group({
      selectedAgencies: [null],
      selectedTechnicians: [null],
      selectedDocumentsDelivered: [null],
      page: [1],
      size: [10],
      search: '',
      dateMin: [null],
      dateMax: [null],
      unitNumber: [null],
    });
  }

  initValues(): void {
    this.selectService.getTechniciansSelect();
    this.agencyService.getForSelect();
    this.documentDeliveredService.getForSelect();
  }

  initSubscriptions(): void {
    zip(
      this.selectService.selectTechnicians$,
      this.agencyService.agenciesSelect$,
      this.documentDeliveredService.documentsDeliveredSelect$
    ).subscribe(
      ([technicians, agencies, documentDelivered]) => {
        this.technicians = technicians;
        this.agencies = agencies;
        this.documentsDelivered = documentDelivered
      });
  }

  resetForm(): void {
    const resetForm = this.initForm();
    this.form.reset(resetForm.value);
  }

  onRefreshClicked(): void {
    this.refresh = true;
  }


}
