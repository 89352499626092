import { Injectable } from '@angular/core';
import { ApiService } from '@libs/api/src';
import { SubmitButton } from '@libs/models/src';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { SwalService } from './utilities/swal.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export interface IncomingCallSheetMailListParameters {
    email: string;
    size: number;
    page: number;
    search: string;
}

@Injectable()
export class MailService {

    public submitButton: SubmitButton;
    private route = '/mails';

    constructor(
        private apiService: ApiService,
        private submitButtonService: SubmitButtonService,
        private swalService: SwalService,
        private formBuilder: FormBuilder,
    ) { }

    public send(form): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.post(this.route, form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            );
    }


    public getForList(form: any): Observable<any> {

        return this.apiService.post(this.route + '/list', form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
            );
    }

    public getForIncomingCallList(params: IncomingCallSheetMailListParameters): Observable<any> {

        return this.apiService.post(this.route + '/incoming-call/list', params);
    }

    public initForm(): FormGroup {
        return this.formBuilder.group({
            standardResponseId: [null, Validators.required],
            recipients: [null],
            recipientsCopy: [null],
            subject: [null],
            content: [null],
            attachments: [null]
        })
    }

    public initFiltersForm(): FormGroup {
        return this.formBuilder.group({
            dateMin: [null],
            dateMax: [null],
            page: 1,
            size: 10,
            search: "",
        })

    }

    setSubmitButton(button: SubmitButton): void {
        this.submitButton = button;
    }

}
