import { ApiService } from '@eros-front/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { DataTableColumn } from './classes/model-datatable';
import { Router } from '@angular/router';
import { AffairStatus } from '@libs/models/src';
import { CommonService } from './utilities/common.service';
import { ModelWithDatatableAndCrudObservable } from './classes/model-datatable-crud-observable';
import { SelectService } from './utilities/select.service';
import { catchError, finalize } from 'rxjs/operators';
import { FormFormatterService } from './utilities/form-formatter.service';

@Injectable()
export class AffairStatusService extends ModelWithDatatableAndCrudObservable {

    public route = '/affairs-statuses';
    public selectAffairsStatuses$ = new BehaviorSubject<any>(undefined);

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private commonService: CommonService,
        private selectService: SelectService,
        private formFormatterService: FormFormatterService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router,
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[]): void {
        return super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns
        });
    }

    public get(id: number): Observable<any> {
        return super.get(id, this.route);
    }

    public getAll(): Observable<any> {
        return super.getAll(this.route);
    }

    public store(form: any): Observable<any> {
        return super.store(form.value, this.route);
    }

    public update(id: number, form: any): Observable<any> {
        return super.update(id, form.value, this.route);
    }

    public updateSettings(id: number, form: any): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.put(`${this.route}/${id}/update-settings`, this.formatSettingsForm(form))
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                    if (this.dtInstance) {
                        this.dtInstance.draw();
                    }
                })
            );
    }

    public delete(id: number): Observable<any> {
        return super.delete(id, this.route);
    }

    public getForm(affairStatus?: AffairStatus) {
        const marketTypeId = affairStatus ?
            this.commonService.valueToFormSelect(affairStatus.marketTypeId) : this.commonService.getDefaultFormNullValue();
        const name = affairStatus ?
            this.commonService.valueToFormString(affairStatus.name) : this.commonService.getDefaultFormStringValue();
        const color = affairStatus ?
            this.commonService.valueToFormColor(affairStatus.color) : this.commonService.getDefaultFormColorValue();
        const step = affairStatus ?
            this.commonService.valueToFormNumber(affairStatus.step) : this.commonService.getDefaultFormNumberValue();
        return this.formBuilder.group({
            marketTypeId: [marketTypeId, Validators.required],
            name: [name, Validators.required],
            color: [color, Validators.required],
            step: [step, Validators.required],
        });
    }

    public getSettingForm(affairStatus: AffairStatus, documentsDelivered: any, documentsCustomers: any) {
        const isOrCondition = affairStatus ?
            this.commonService.valueToRadioFormString(affairStatus.isOrCondition) : this.commonService.getDefaultFormRadioValue();
        const documentsDeliveredIds = affairStatus ?
            documentsDelivered.filter(x => affairStatus.documentsDeliveredIds.map(x => x).includes(x.value)) : [];
        const documentsCustomersIds = affairStatus ?
            documentsCustomers.filter(x => affairStatus.documentsCustomersIds.map(x => x).includes(x.value)) : [];
        return this.formBuilder.group({
            isOrCondition: [isOrCondition],
            documentsDeliveredIds: [documentsDeliveredIds],
            documentsCustomersIds: [documentsCustomersIds],
        });

    }

    public getForSelect(): void {
        this.selectService.getForSelect(this.selectAffairsStatuses$, this.route);
    }

    public getSelectByMarketType(affairsStatuses: any, marketTypeId: number) {
        let affairsStatusesSelect = [];
        affairsStatuses.forEach((affairStatus: AffairStatus) => {
            if (affairStatus.marketTypeId == marketTypeId) {
                affairsStatusesSelect.push({
                    label: affairStatus.name,
                    value: affairStatus.id
                })
            }
        })
        return affairsStatusesSelect;
    }

    public formatToSelectMultiple(affairsStatuses: any) {
        let affairsStatusesSelect = [];
        affairsStatuses.forEach((affairStatus: AffairStatus) => {
            affairsStatusesSelect.push({
                label: affairStatus.name,
                value: affairStatus.id
            })
        })
        return affairsStatusesSelect;
    }


    private formatSettingsForm(form) {
        const values = this.formFormatterService.createFormCopy(form);
        values.documentsCustomersIds = this.formFormatterService.formatSelectMultipleToIntArray(values.documentsCustomersIds)
        values.documentsDeliveredIds = this.formFormatterService.formatSelectMultipleToIntArray(values.documentsDeliveredIds)
        return values;
    }
}
