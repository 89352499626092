import { Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConformityService, InterventionService, InterventionStatusService } from '@libs/services/src';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-affair-show-interventions',
  templateUrl: './affair-show-interventions.component.html',
  styleUrls: ['./affair-show-interventions.component.scss']
})
export class AffairShowInterventionsComponent implements OnInit, OnDestroy {

  @Input() affair: any;
  @Input() affairProductsToProgram: any;
  @Input() buildingObject: any;
  public form: FormGroup;
  // Select conformities
  public conformities$: Subscription;
  public conformitiesItems: any[];
  public selectedConformities: any;
  public interventionsStatuses$: Subscription;
  public interventionsSelects$: Subscription;
  // Select interventions statuses
  public interventionsStatusesItems: any[];
  public selectedInterventionsStatuses: any;
  public interventionsData: any;
  public isLoading = false;

  constructor(
    private router: Router,
    private interventionService: InterventionService,
    private formBuilder: FormBuilder,
    private conformityService: ConformityService,
    private interventionStatusService: InterventionStatusService
  ) {
  }

  ngOnInit() {
    this.initSubscriptions();
    this.conformityService.getForSelect();
    this.interventionStatusService.getForSelect();
    this.form = this.getFiltersForm();
    this.initList();

    this.form.valueChanges.subscribe(form => {
      this.initList();
    });
  }

  ngOnDestroy(): void {
    this.conformities$.unsubscribe();
    this.interventionsStatuses$.unsubscribe();
  }

  initList() {
    this.isLoading = true;
    this.interventionService.getForAffair(this.form).subscribe(
      (data) => {
        this.interventionsData = data;
        this.isLoading = false;
      }
    )
  }

  createIntervention() {
    this.router.navigateByUrl('interventions/create/affair/' + this.affair.id);
  }

  onRowClicked(id: number) {
    window.open('interventions/' + id + '/edit');
  }

  public getFiltersForm(): FormGroup {
    return this.formBuilder.group({
      selectedAffair: this.affair.id,
      selectedConformities: this.selectedConformities,
      selectedInterventionsStatuses: this.selectedInterventionsStatuses,
      indicators: []
    });
  }

  private initSubscriptions() {
    this.conformities$ = this.conformityService.selectConformities$.subscribe(
      (data) => {
        if (data) {
          this.conformitiesItems = data;
        }
      }
    )
    this.interventionsStatuses$ = this.interventionStatusService.interventionsStatusesSelect$.subscribe(
      (data) => {
        if (data) {
          this.interventionsStatusesItems = data;
        }
      }
    )
  }
}
