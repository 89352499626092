import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubmitButton } from '@libs/models/src';
import { InterventionStatusService, NotifService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-settings-interventions-interventions-statuses-modal',
  templateUrl: './settings-interventions-interventions-statuses-modal.component.html',
  styleUrls: ['./settings-interventions-interventions-statuses-modal.component.scss']
})
export class SettingsInterventionsInterventionsStatusesModalComponent implements OnInit {
  @Input() status: any;
  @Input() labelsClasses: any;
  @Input() googleCalendarColors: any;
  @Input() outlookCategories: any;
  @Input() form: FormGroup;
  public submitButton: SubmitButton;
  public colorValue: string

  constructor(
    private modal: NzModalRef,
    private interventionStatusService: InterventionStatusService,
    private notifService: NotifService
  ) { }

  ngOnInit() {
    this.determinateSubmitButton();
    this.interventionStatusService.setSubmitButton(this.submitButton);
    if (this.form) {
      this.colorValue = this.form.get('color').value
    }
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.status) {
        this.interventionStatusService.update(this.status.id, this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      } else {
        this.interventionStatusService.store(this.form).subscribe((success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.interventionStatusService.determinateSubmitButton(this.status, 'le statut intervention');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
