import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { Agency, SubmitButton } from '@libs/models/src';
import { AgencyService, SubmitButtonService, SwalService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';

export interface ImageTransform {
  scale?: number;
  rotate?: number;
  flipH?: boolean;
  flipV?: boolean;
}

export interface ImageCroppedEvent {
  base64?: string | null;
  file?: Blob | null;
  width: number;
  height: number;
  cropperPosition: CropperPosition;
  imagePosition: CropperPosition;
  offsetImagePosition?: CropperPosition;
}

export interface CropperPosition {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

export interface Dimensions {
  width: number;
  height: number;
}


@Component({
  selector: 'app-customers-show-import-logo',
  templateUrl: './customers-show-import-logo.component.html',
  styleUrls: ['./customers-show-import-logo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomersShowImportLogoComponent implements OnInit {
  @Input() customer: Agency;
  @Input() form: FormGroup;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  public submitButton: SubmitButton;
  public logoThumbnailBase64;

  constructor(
    private modal: NzModalRef,
    private agencyService: AgencyService,
    private submitButtonService: SubmitButtonService,
    private formBuilder: FormBuilder,
    private swalService: SwalService
  ) { }

  ngOnInit() {
    this.setSubmitButton();

    if (this.customer.logoThumbnail) {
      this.croppedImage = "data:image/png;base64," + this.customer.logoThumbnail;
      this.logoThumbnailBase64 = "data:image/png;base64," + this.customer.logoThumbnail;
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.form.patchValue({
      logo: event.base64,
      width: event.width,
      height: event.height
    });
  }

  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
  }

  cropperReady() {
    console.log('Cropper ready');
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  base64ToFile(base64Image: string): Blob {
    const split = base64Image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }

  public isValidForm(): boolean {
    if (this.form) {
      return this.form.valid;
    }
    return false;
  }

  public submitForm(): void {
    if (this.isValidForm()) {
      this.agencyService.importLogo(this.customer.id, this.form).subscribe(
        (success) => {
          this.modal.close();
          this.swalService.showSwalSuccess(success);
        }
      )
    }
  }

  private setSubmitButton(): void {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'le logo',
      text: 'Importer',
      icon: faFileImport
    });
    this.agencyService.setSubmitButton(this.submitButton);
  }

}
