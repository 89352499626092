import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ErosNzTableCheckboxesComponent } from '@libs/components/src/lib/eros-nz-table-checkboxes/eros-nz-table-checkboxes.component';
import { FileUtilsService } from '@libs/services/src';
import { InvoiceService } from '@libs/services/src/lib/invoice.service';
import { FormLocalStorageService } from '@libs/services/src/lib/utilities/form-local-storage.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-invoices-list',
  templateUrl: './invoices-list.component.html',
  styleUrls: ['./invoices-list.component.scss']
})
export class InvoicesListComponent extends ErosNzTableCheckboxesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() form: FormGroup;
  @Input() storageKey: string;
  @Input() refresh: boolean = false;
  @Input() reset: boolean = false;
  @Output() refreshChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() resetChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() checkedIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  public invoicesData: any[];
  private isDestroyed$ = new Subject<void>();
  public inputSearchValue: string = '';
  public listOfColumns = [];

  constructor(
    private invoiceService: InvoiceService,
    private storageService: FormLocalStorageService,
    private router: Router,
    private fileUtilsService: FileUtilsService
  ) {
    super();
  }

  ngOnInit() {
    this.initListOfColumns();
    this.initIndividualSearch(this.form, this.listOfColumns);

  }

  ngOnDestroy(): void {
    this.isDestroyed$?.next();
    this.isDestroyed$?.complete();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['form'] && changes['form'].previousValue != changes['form'].currentValue)) {
      this.form.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          takeUntil(this.isDestroyed$)
        )
        .subscribe(form => {
          this.storageService.store(this.storageKey, this.form)
          this.initList();
          this.inputSearchValue = this.form.value.search;
        });
    }

    if (changes['refresh'] && changes['refresh'].previousValue != changes['refresh'].currentValue) {
      this.initList();
      this.refreshChange.emit(this.refresh);
    }
    if (changes['reset'] && changes['reset'].previousValue != changes['reset'].currentValue) {
      this.initIndividualSearch(this.form, this.listOfColumns);
      this.resetChange.emit(this.reset);
    }
  }

  onQueryParamsChange(params: NzTableQueryParams) {
    this.pageIndex = params.pageIndex;
    this.pageSize = params.pageSize;
    const currentSort = params.sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.form.patchValue({
      page: this.pageIndex,
      size: this.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
    })
  }

  getIndividualSearchControls() {
    return (this.form.get('individualSearch') as FormArray).controls;
  }

  getListOfColumnsVisibility(key: string) {
    let column = this.listOfColumns.find(x => x.key == key);
    if (column) {
      return column.searchable;
    }
    return false;
  }

  initIndividualSearch(form: FormGroup, columns: any[]) {
    const individualSearchArray = form.get('individualSearch') as FormArray;
    if (individualSearchArray.length > 0) {
      this.clearFormArray(individualSearchArray);
    }
    columns.forEach((column) => {
      individualSearchArray.push(new FormGroup({
        key: new FormControl(column.key),
        value: new FormControl(''),
      }))
    });
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  /**
   *  Clicks functions
   */
  onEditClicked(id: number): void {
    this.router.navigateByUrl(`invoices/${id}/show`);
  }

  onDownloadClicked(id: number): void {
    this.invoiceService.download(id)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((response) => {
        this.fileUtilsService.responseToDownload(response, 'pdf');
      });
  }

  onDownloadXmlClicked(id: number): void {
    this.invoiceService.getXmlIgc(id)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((response) => {
        this.fileUtilsService.responseToDownload(response, 'xml');
      });
  }

  onDownloadIgcClicked(id: number): void {
    this.invoiceService.downloadForIgc(id)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((response) => {
        this.fileUtilsService.responseToDownload(response, 'pdf');
      });
  }

  private initList() {
    this.isLoading = true;
    this.invoiceService.initList(this.form)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (response) => {
          this.invoicesData = response.data;
          this.isLoading = false;
          this.setOfCheckedId.clear();
          this.refreshCheckedStatus();
          this.total = response.meta.total;
        }
      )
  }

  /**
   * Private functions
   */
  private initListOfColumns() {
    this.listOfColumns = [
      {
        title: 'Numéro',
        sortFn: true,
        columnKey: 'number',
        key: 'number',
        searchable: true,
      },
      {
        title: 'Date',
        sortFn: true,
        columnKey: 'date',
        key: 'date',
        searchable: true,
      },
      {
        title: 'Date échéance',
        sortFn: true,
        columnKey: 'dateDue',
        key: 'dateDue',
        searchable: true,
      },
      {
        title: 'Payeur',
        sortFn: true,
        columnKey: 'payerName',
        key: 'payerName',
        searchable: true,
      },
      {
        title: 'Client',
        columnKey: 'customer',
        key: 'customer',
        searchable: false,
      },
      {
        title: 'Prestation(s)',
        columnKey: null,
        key: 'productsRefs',
        searchable: false,
      },
      {
        title: 'Affaire(s)',
        columnKey: 'affairsNames',
        key: 'affairsNames',
        searchable: false,
      },
      {
        title: 'Statut',
        columnKey: 'status',
        key: 'status',
        searchable: false,
      },
      {
        title: 'Totaux',
        columnKey: 'total',
        key: 'total',
        searchable: false,
      },
      {
        title: 'Actions',
        columnKey: 'actions',
        key: 'actions',
        width: '150px',
        searchable: false,
      }
    ];
  }

}
