import { ApiService } from '@eros-front/api';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';
import { ModelWithDatatableAndCrud } from './classes/model-datatable-crud';
import { DataTableColumn } from './classes/model-datatable';
import { FormFormatterService } from './utilities/form-formatter.service';
import { CommonService } from './utilities/common.service';
import { catchError, finalize } from 'rxjs/operators';
import { SelectService } from './utilities/select.service';
import { Invoice } from '@libs/models/src';

export interface InvoiceDatatableParameters {
    isTemporary: boolean;
    affair: string;
    selectedAgencies: string;
    selectedMlAgencies: string;
    selectedProducts: string;
    selectedInvoicesStatuses: string;
    dueDate: Date;
    isDue: boolean;
    hasMultipleAffairs: boolean;
    isManual: boolean;
    page: number;
    size: number;
    search: number;
    sortField: string;
    sortOrder: string;
    individualSearch: any;
}

@Injectable()
export class InvoiceService extends ModelWithDatatableAndCrud {

    private route = '/invoices';
    public invoice$ = new BehaviorSubject<any>(undefined);
    public filters$ = new BehaviorSubject<any>(undefined);
    public invoicesThumbnails$ = new BehaviorSubject<any>(undefined);
    public payerForProduct$ = new BehaviorSubject<any>(undefined);
    public invoicesTempoByProduct$ = new BehaviorSubject<any>(undefined);
    public selectInvoices$ = new BehaviorSubject<any>(undefined);
    public redirectUrl = 'invoices/index';

    constructor(
        http: HttpClient,
        notifService: NotifService,
        submitButtonService: SubmitButtonService,
        swalService: SwalService,
        router: Router,
        apiService: ApiService,
        private formBuilder: FormBuilder,
        private formFormatterService: FormFormatterService,
        private commonService: CommonService,
        private selectService: SelectService
    ) {
        super(
            http,
            notifService,
            submitButtonService,
            swalService,
            apiService,
            router
        );
    }

    public initDataTable(selector: string, columns: DataTableColumn[], form?: FormGroup): void {
        super.initializeDataTable({
            url: this.route + '/list',
            selector: '#' + selector,
            dataTableColumns: columns,
            useCheckbox: true,
            pageLength: 25
        }, this.formToDatatableParameters(form));
    }

    public initList(form): Observable<any> {
        return this.apiService.post(this.route + '/list', this.formToDatatableParameters(form))
    }

    public redrawDataTable(form: FormGroup): void {
        super.redrawDataTable(this.formToDatatableParameters(form));
    }

    public getDataTableFilters(): void {
        this.apiService.get(`${this.route}/list/selects`)
            .subscribe(
                (object) => {
                    this.filters$.next(object);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public getFiltersForm(formValue?: any): FormGroup {
        const affair = formValue ?
            this.commonService.valueToFormString(formValue.affair) : this.commonService.getDefaultFormStringValue();
        const selectedAgencies = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedAgencies) : this.commonService.getDefaultFormNullValue();
        const selectedMlAgencies = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedMlAgencies) : this.commonService.getDefaultFormNullValue();
        const selectedProducts = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedProducts) : this.commonService.getDefaultFormNullValue();
        const selectedInvoicesStatuses = formValue ?
            this.commonService.valueToFormSelect(formValue.selectedInvoicesStatuses) : this.commonService.getDefaultFormNullValue();
        const dueDate = formValue ?
            this.commonService.valueToFormDate(formValue.dueDate) : this.commonService.getDefaultFormNullValue();
        const isDue = formValue ?
            this.commonService.valueToCheckboxFormNumber(formValue.isDue) : this.commonService.getDefaultFormCheckboxValue();
        const hasMultipleAffairs = formValue ?
            this.commonService.valueToCheckboxFormNumber(formValue.hasMultipleAffairs) : this.commonService.getDefaultFormCheckboxValue();
        const isManual = formValue ?
            this.commonService.valueToCheckboxFormNumber(formValue.isManual) : this.commonService.getDefaultFormCheckboxValue();
        const page = formValue ?
            formValue.page : 1;
        const size = formValue ?
            formValue.page : 10;
        const search = formValue ?
            formValue.search : this.commonService.getDefaultFormStringValue();
        const sortField = formValue ?
            formValue.sortField : this.commonService.getDefaultFormNullValue();
        const sortOrder = formValue ?
            formValue.sortOrder : this.commonService.getDefaultFormNullValue();
        return this.formBuilder.group({
            isTemporary: false,
            affair: affair,
            selectedAgencies: [selectedAgencies],
            selectedMlAgencies: [selectedMlAgencies],
            selectedProducts: [selectedProducts],
            selectedInvoicesStatuses: [selectedInvoicesStatuses],
            dueDate: dueDate,
            isDue: isDue,
            hasMultipleAffairs: hasMultipleAffairs,
            isManual: isManual,
            page: [page],
            size: [size],
            search: [search],
            sortField: [sortField],
            sortOrder: [sortOrder],
            individualSearch: new FormArray([])
        });
    }

    public getUpdateForm(invoice: any): FormGroup {
        if (invoice) {
            return this.formBuilder.group({
                mlAgencyId: [this.commonService.valueToFormSelect(invoice.mlAgencyId), Validators.required],
                agencyId: this.commonService.valueToFormSelect(invoice.agencyId),
                payerId: this.commonService.valueToFormSelect(invoice.payerId),
                accountingCode: [this.commonService.valueToFormString(invoice.accountingCode), Validators.required],
                payerPreference: this.commonService.valueToFormSelect(invoice.payerPreference),
                payerName: [this.commonService.valueToFormString(invoice.payerName), Validators.required],
                payerAddress: [this.commonService.valueToFormString(invoice.payerAddress), Validators.required],
                payerAdditionalAddress: this.commonService.valueToFormString(invoice.payerAdditionalAddress),
                payerPostalCode: [this.commonService.valueToFormString(invoice.payerPostalCode), Validators.required],
                payerLocality: [this.commonService.valueToFormString(invoice.payerLocality), Validators.required],
                comment: this.commonService.valueToFormString(invoice.comment)
            });
        }
        return null;
    }

    public get(id: number): void {
        super.get(id, this.invoice$, this.route);
    }

    public store(form: FormGroup): void {
        super.store(this.formatForm(form, true), this.route, this.redirectUrl);
    }

    public storeForAffair(form: FormGroup, affairId: number): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.post(`${this.route}/affair/${affairId}`, form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            );
    }

    public update(id: number, form: FormGroup): void {
        super.update(id, this.formatForm(form), this.route);
    }

    public delete(id: number): void {
        this.apiService.delete(this.route + '/' + id)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                })
            )
            .subscribe(
                (success) => {
                    this.router.navigateByUrl(this.route + '/index');
                }
            );
    }


    public stream(id: Number): Observable<Blob> {
        return this.apiService.getFile(`${this.route}/${id}/stream`);
    }

    public download(id: number): Observable<HttpResponse<Blob>> {
        return this.apiService.postBlob(`${this.route}/download/` + id, []);
    }

    public getXmlIgc(id: number): Observable<HttpResponse<Blob>> {
        return this.apiService.postBlob(`${this.route}/${id}/xml-igc`, []);
    }

    public downloadForIgc(id: number): Observable<HttpResponse<Blob>> {
        return this.apiService.postBlob(`${this.route}/${id}/download-igc`, []);
    }

    public downloadDetailed(selectedInvoices: number[]): Observable<HttpResponse<Blob>> {
        return this.apiService.postBlob(`${this.route}/selected/detailed/download`, this.getSelectedInvoicesBody(selectedInvoices));
    }

    public downloadSimplified(selectedInvoices: number[]): Observable<HttpResponse<Blob>> {
        return this.apiService.postBlob(`${this.route}/selected/symplified/download`, this.getSelectedInvoicesBody(selectedInvoices));
    }

    public mergePDFs(selectedInvoices: number[]): Observable<HttpResponse<Blob>> {
        return this.apiService.postBlob(`${this.route}/merge`, this.getSelectedInvoicesBody(selectedInvoices));
    }

    public generateReport(id: number): Observable<any> {
        return this.apiService.get(`${this.route}/${id}/generate-report`);
    }

    public generateListExport(form: FormGroup): Observable<HttpResponse<Blob>> {
        return this.apiService.postBlob(`${this.route}/list/export`, this.formToDatatableParameters(form));
    }

    public validate(invoiceId: number) {
        this.apiService.get(`${this.route}/${invoiceId}/validate`)
            .subscribe(
                (success) => {
                    this.get(invoiceId);
                    this.swalService.showSwalSuccess(success);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }


    public thumbnails(affairId: number) {
        this.apiService.get(`${this.route}/affair/${affairId}/thumbnails`)
            .subscribe(
                (object) => {
                    this.invoicesThumbnails$.next(object);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    private formToDatatableParameters(form: FormGroup): InvoiceDatatableParameters {
        if (form) {
            return {
                isTemporary: form.value.isTemporary,
                affair: form.value.affair,
                selectedAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedAgencies),
                selectedMlAgencies: this.formFormatterService.formatSelectMultiple(form.value.selectedMlAgencies),
                selectedProducts: this.formFormatterService.formatSelectMultiple(form.value.selectedProducts),
                selectedInvoicesStatuses: this.formFormatterService.formatSelectMultiple(form.value.selectedInvoicesStatuses),
                dueDate: form.value.dueDate,
                isDue: form.value.isDue,
                hasMultipleAffairs: form.value.hasMultipleAffairs,
                isManual: form.value.isManual,
                page: form.value.page,
                size: form.value.size,
                search: form.value.search,
                sortField: form.value.sortField,
                sortOrder: form.value.sortOrder,
                individualSearch: form.value.individualSearch
            };
        }
        return null;
    }

    private getSelectedInvoicesBody(selectedInvoices: number[]): any {
        return {
            'selectedInvoices': selectedInvoices
        }
    }

    private formatForm(form: FormGroup, isCreate?: boolean): void {

        const values = this.formFormatterService.createFormCopy(form);
        values.mlAgencyId = this.formFormatterService.formatSelectSingle(values.mlAgencyId);
        values.agencyId = this.formFormatterService.formatSelectSingle(values.agencyId);
        values.payerId = this.formFormatterService.formatSelectSingle(values.payerId);
        values.payerPreference = this.formFormatterService.formatSelectSingle(values.payerPreference);
        if (isCreate) {
            values.invoiceDetails.forEach((value) => {
                value.vatRate = this.formFormatterService.formatSelectSingle(value.vatRate);
            });
        }
        return values;
    }

    public storeForAffairProducts(form: any): Observable<any> {
        return this.apiService.post(`${this.route}/affair-products`, form.value);
    }

    public attachAffairProducts(form: any): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        form.value.invoiceId = this.formFormatterService.formatSelectSingle(form.value.invoiceId);
        return this.apiService.post(`${this.route}/affair-products/attach`, form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
    }


    public getPayerForProduct(productKey: string) {
        this.apiService.get(`${this.route}/products/${productKey}/get-payer`)
            .subscribe(
                (object) => {
                    this.payerForProduct$.next(object);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public getTemporaryByProduct(productKey: string) {
        this.apiService.get(`${this.route}/products/${productKey}/get-temporary`)
            .subscribe(
                (object) => {
                    this.invoicesTempoByProduct$.next(object);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    public getByPayer(payerId: number): Observable<any> {
        return this.apiService.get(`${this.route}/payer/${payerId}`);
    }

    public getByAgency(agencyId: number): Observable<any> {
        return this.apiService.get(`${this.route}/agency/${agencyId}`);
    }

    public getForSelect() {
        this.selectService.getForSelect(this.selectInvoices$, this.route);
    }

    public getCreateCreditForm(invoice: Invoice) {
        return this.formBuilder.group({
            invoiceId: [invoice.id, Validators.required],
            isTotality: [true, Validators.required],
            invoiceDetails: new FormArray([])
        });
    }

    public initCreditInvoiceDetails(form: FormGroup, invoice: Invoice) {
        const invoiceDetailsFormArray = form.get('invoiceDetails') as FormArray;
        invoice.invoiceDetails.forEach((invoiceDetail) => {
            invoiceDetailsFormArray.push(new FormGroup({
                checked: new FormControl(false),
                affairProductId: new FormControl(invoiceDetail.affairProductId),
                affairId: new FormControl(invoiceDetail.affairId),
                productId: new FormControl(invoiceDetail.productId),
                ref: new FormControl(invoiceDetail.ref),
                designation: new FormControl(invoiceDetail.designation),
                vatRate: new FormControl(invoiceDetail.vatRate),
                accountingCode: new FormControl(invoiceDetail.accountingCode),
                quantity: new FormControl(invoiceDetail.quantity, [Validators.max(invoiceDetail.quantity), Validators.min(1)]),
                unitPrice: new FormControl(invoiceDetail.unitPrice, [Validators.max(invoiceDetail.unitPrice), Validators.min(1)]),
            }))
        });
    }

    public storeCredit(form: FormGroup): Observable<any> {
        this.submitButtonService.setDisabled(this.submitButton);
        return this.apiService.post(`${this.route}/credit`, this.formatCreateCreditForm(form))
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            );
    }

    private formatCreateCreditForm(form: FormGroup): any {
        const values = this.formFormatterService.createFormCopy(form);
        values.invoiceDetails = this.formFormatterService.filterCheckedValues(values.invoiceDetails);
        return values;
    }

    public reset(): void {
        this.invoice$.next(null);
    }



}
