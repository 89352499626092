import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectMultipleComponent } from '@libs/components/src';
import { CustomerService, CustomersModalSync, NotifService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-customers-show-modal-sync-prices',
  templateUrl: './customers-show-modal-sync-prices.component.html',
  styleUrls: ['./customers-show-modal-sync-prices.component.scss']
})
export class CustomersShowModalSyncPricesComponent extends CustomersModalSync implements OnInit, OnDestroy {

  @Input() source: any;
  @Input() products: any;
  @Input() marketsTypes: any;
  @Input() entities: any;
  @ViewChild('consultGraphModalSyncPricesSelectEntities', { static: false }) childSelectEntities: NgSelectMultipleComponent;
  @ViewChild('consultGraphModalSyncPricesSelectMarketsTypes', { static: false }) childSelectMarketsTypes: NgSelectMultipleComponent;
  @ViewChild('consultGraphModalSyncPricesSelectProducts', { static: false }) childSelectProducts: NgSelectMultipleComponent;
  private custService: CustomerService;
  public selectedEntities: any[];
  public selectedMarketsTypes: any[];
  public selectedProducts: any[];
  public submitClicked = false;

  constructor(
    customerService: CustomerService,
    private modal: NzModalRef,
    private notifService: NotifService
  ) {
    super(customerService, 3);
    this.custService = customerService;
  }

  ngOnInit() {
    this.initValues();
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.previewResult$.unsubscribe();
  }

  isValidForm() {
    return (this.selectedEntities.length > 0
      && this.selectedMarketsTypes.length > 0
      && this.selectedProducts.length > 0);
  }

  getPreviewResult() {
    if (this.isValidForm()) {
      this.custService.getPreviewOfSyncPrices({
        sourceRef: this.source.ref,
        entitiesRefs: this.selectedEntities.map(x => x.value),
        marketsTypesIds: this.selectedMarketsTypes.map(x => x.value),
        productsIds: this.selectedProducts.map(x => x.value)
      });
    } else {
      this.previewResult = [];
    }
  }

  onApplyClicked() {
    if (this.isValidForm()) {
      this.submitClicked = true;
      this.custService.applySyncPrices({
        sourceRef: this.source.ref,
        entitiesRefs: this.selectedEntities.map(x => x.value),
        marketsTypesIds: this.selectedMarketsTypes.map(x => x.value),
        productsIds: this.selectedProducts.map(x => x.value)
      }).subscribe((response) => {
        this.notifService.showSuccessNotif(response);
        this.submitClicked = false;
        this.modal.close();
      })
    }
  }

  private initValues() {
    this.selectedEntities = [];
    this.selectedMarketsTypes = [];
    this.selectedProducts = [];
    this.selectedStep = 0;
    this.previewResult = [];
    if (this.childSelectEntities) {
      this.childSelectEntities.clearSelectedItems();
    }
    if (this.childSelectMarketsTypes) {
      this.childSelectMarketsTypes.clearSelectedItems();
    }
    if (this.childSelectProducts) {
      this.childSelectProducts.clearSelectedItems();
    }
  }

  private initSubscriptions() {
    this.previewResult$ = this.custService.previewOfSyncPrices$.subscribe(
      (data) => {
        if (data) {
          this.previewResult = data;
        }
      }
    );
  }

}
