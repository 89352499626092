import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { faCreditCard, faSave } from '@fortawesome/free-solid-svg-icons';
import { Affair, SubmitButton } from '@libs/models/src';
import { AffairProductService, AffairService, CommonService, NotifService, SubmitButtonService } from '@libs/services/src';
import { InvoiceService } from '@libs/services/src/lib/invoice.service';
import { Subscription } from 'rxjs';

export interface InvoiceCardAffairProduct {
  id: number;
  checked: boolean;
  productId: number;
  productName: string;
  quantity: number;
  price: number;
  statusLabelClass: string;
  statusName: string;
  vatRate: number;
}

@Component({
  selector: 'app-affair-show-invoices-generate',
  templateUrl: './affair-show-invoices-generate.component.html',
  styleUrls: ['./affair-show-invoices-generate.component.scss']
})

export class AffairShowInvoicesGenerateComponent implements OnInit, OnChanges, OnDestroy {

  @Input() affair: Affair;
  public previewTotalWithoutTaxes = 0;
  public previewAmountVat = 0;
  public previewTotalAllTaxes = 0;
  public selectAllProducts = false;
  public form: FormGroup;
  public submitButton: SubmitButton;
  public affairProductsToInvoicing$: Subscription;
  public affairProductsToInvoicing = [];
  public invoiceComment: string = "";
  public isTemporaryGenerateClicked = false;

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private invoiceService: InvoiceService,
    private submitButtonService: SubmitButtonService,
    private affairProductService: AffairProductService,
    private affairService: AffairService,
    private notifService: NotifService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    if (this.affair) {
      this.affairProductService.getByAffairToInvoicing(this.affair.id);
    }
    this.setSubmitButton();
  }

  ngOnChanges() {
    this.updateInvoiceComment();
  }

  ngOnDestroy(): void {
    if (this.affairProductsToInvoicing$) {
      this.affairProductsToInvoicing$.unsubscribe();
    }
  }

  invoiceCardCheckAllProducts(event: any) {
    this.selectAllProducts = !this.selectAllProducts;
    this.affairProductsFormArray.controls.map(control => {
      control.patchValue({
        checked: this.selectAllProducts
      })
    });
  }

  updatePricePreview() {
    this.initPricePreview();
    for (const ap of this.form.value.affairProducts) {
      if (ap.checked) {
        const apPrice = (ap.quantity * ap.price);
        const apAmountVat = (apPrice / 100) * ap.vatRate;
        this.previewTotalWithoutTaxes += apPrice;
        this.previewAmountVat += apAmountVat;
        this.previewTotalAllTaxes += apPrice + apAmountVat;
      }
    }
  }

  initInvoiceComment() {
    if (this.affair) {
      this.invoiceComment = [this.affair.customerRef, this.affair.folderNumber, this.affair.invoiceComment].filter(Boolean).join("\n");
    }
  }

  updateInvoiceComment() {
    this.initInvoiceComment();
    if (this.form) {
      for (const ap of this.form.value.affairProducts) {
        if (ap.checked) {
          if (ap.purchaseOrder) {
            this.invoiceComment = this.invoiceComment.concat('\nBon de commande: ' + ap.purchaseOrder);
          }
        }
      }
      this.form.patchValue({
        'comment': this.invoiceComment
      })
    }
  }

  formatNumberToLocale(number: number) {
    return this.commonService.formatNumberToLocale(number);
  }

  generate(isTemporary?: boolean) {
    if (this.isValidForm()) {
      this.form.value.affairProducts = this.form.value.affairProducts.filter(v => v.checked);
      if (isTemporary) {
        this.form.value.isTemporary = isTemporary ? 1 : 0;
        this.isTemporaryGenerateClicked = true;
      }
      this.invoiceService.storeForAffair(this.form, this.affair.id).subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.affairService.get(this.affair.id);
          this.affairProductService.getByAffairToInvoicing(this.affair.id);
          this.form = this.initForm();
          this.invoiceService.thumbnails(this.affair.id);
          this.isTemporaryGenerateClicked = false;
        },
        (error) => {
          this.isTemporaryGenerateClicked = false;
        }
      );

    }
  }

  get affairProductsFormArray() {
    return this.form.controls.affairProducts as FormArray;
  }

  private initForm(): FormGroup {
    this.initInvoiceComment();

    return this.formBuilder.group({
      affairProducts: new FormArray([], this.minSelectedCheckboxes(1)),
      isTemporary: false,
      comment: [this.invoiceComment]
    });
  }

  private addCheckboxes() {
    this.affairProductsToInvoicing.forEach((affairProduct) => {
      let apSelected = affairProduct;
      this.affairProductsFormArray.push(new FormGroup({
        checked: new FormControl(apSelected.checked),
        affairProductId: new FormControl(apSelected.id),
        affairId: new FormControl(apSelected.affairId),
        productId: new FormControl(apSelected.productId),
        interventionId: new FormControl(apSelected.interventionId),
        unitNumber: new FormControl(apSelected.unitNumber),
        vatRate: new FormControl(apSelected.product.vatRate),
        purchaseOrder: new FormControl(apSelected.purchaseOrder),
        quantity: new FormControl(apSelected.quantity, [Validators.max(affairProduct.quantity), Validators.min(1)]),
        price: new FormControl(apSelected.price, [Validators.min(0)])
      }))
    });
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value.checked)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);
      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }


  isValidForm() {
    return this.form.valid;
  }

  private initPricePreview(): void {
    this.previewTotalWithoutTaxes = 0;
    this.previewAmountVat = 0;
    this.previewTotalAllTaxes = 0;
  }

  private setSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'une facture',
      text: 'Générer',
      icon: faCreditCard
    });
    this.invoiceService.setSubmitButton(this.submitButton);
  }

  private initSubscriptions() {
    this.affairProductsToInvoicing$ = this.affairProductService.affairProductsToInvoicing$.subscribe(
      (data) => {
        if (data) {
          this.affairProductsToInvoicing = data;
          this.form = this.initForm();
          this.addCheckboxes();
          this.updatePricePreview();
          this.form.valueChanges.subscribe(form => {
            this.updatePricePreview();
          });
        }
      }
    )
  }

}
