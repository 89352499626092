import { Injectable } from "@angular/core";
import { Options as PlacesOptions } from 'ngx-google-places-autocomplete/objects/options/options';
import { ComponentRestrictions } from 'ngx-google-places-autocomplete/objects/options/componentRestrictions';
import { LatLng } from 'ngx-google-places-autocomplete/objects/latLng';
import { LatLngBounds } from 'ngx-google-places-autocomplete/objects/latLngBounds';
import { MapCircle } from '@angular/google-maps';

@Injectable()
export class AutocompleteOptions extends PlacesOptions {

    public componentRestrictions: ComponentRestrictions = new ComponentRestrictions({
        country: 'FR'
    });

    public bounds: LatLngBounds = null;
    public origin: LatLng;
    public strictBounds: boolean;

    constructor(opt?: Partial<PlacesOptions>) {
        super(opt);

        /* Gets users current geolocation */
        if (this.bounds === null && window.navigator.geolocation) {
            window.navigator.geolocation.watchPosition(
                (position) => {
                    let latLng = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                    let circle = new google.maps.Circle({
                        center: latLng,
                        radius: position.coords.accuracy
                    });

                    this.bounds = circle.getBounds();
                }
            );
        }
    }
}