import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { AffairYnergie, SubmitButton } from '@libs/models/src';
import { NotifService, SubmitButtonService, SwalService } from '@libs/services/src';
import { AffairYnergieService } from '@libs/services/src/lib/affairs/affair-ynergie.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subject, throwError } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'eros-front-affair-ynergie-create-modal',
  templateUrl: './affair-ynergie-create-modal.component.html',
  styleUrls: ['./affair-ynergie-create-modal.component.scss']
})
export class AffairYnergieCreateModalComponent implements OnInit, OnDestroy {
  @Input() affairYnergie: AffairYnergie;
  public form: FormGroup;
  public submitButton: SubmitButton;
  public isDestroyed$ = new Subject<void>();

  constructor(
    private modal: NzModalRef,
    private affairYnergieService: AffairYnergieService,
    private submitButtonService: SubmitButtonService,
    private notifService: NotifService,
    private swalService: SwalService,
  ) { }

  ngOnInit(): void {
    this.form = this.affairYnergieService.initCreateForm(this.affairYnergie);
    this.setSubmitButton();
  }

  ngOnDestroy(): void {
    this.isDestroyed$?.next();
    this.isDestroyed$?.complete();
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

  public submitForm(): void {
    if (this.isValidForm()) {
      this.submitButtonService.setDisabled(this.submitButton);
      this.affairYnergieService.create(this.form)
        .pipe(
          takeUntil(this.isDestroyed$),
          catchError(error => {
            this.swalService.showSwalError(error);
            return throwError(error);
          }),
          finalize(() => {
            this.submitButtonService.setEnabled(this.submitButton);
          })
        )
        .subscribe(
          (response) => {
            this.notifService.showSuccessNotif(response);
            this.modal.close();
          }
        )
    }
  }

  private setSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'une affaire',
      text: 'Créer',
      icon: faPlus
    });
    this.affairYnergieService.setSubmitButton(this.submitButton);
  }

}
