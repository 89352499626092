import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Affair, AffairProduct, Product, SubmitButton } from '@libs/models/src';
import { AffairProductService, NotifService, SubmitButtonService } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-affair-product-modify-modal',
  templateUrl: './affair-product-modify-modal.component.html',
  styleUrls: ['./affair-product-modify-modal.component.scss']
})
export class AffairProductModifyModalComponent implements OnInit {
  @Input() affairProduct: AffairProduct;
  @Input() affair: Affair;
  @Input() products: Product[];

  public form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private affairProductService: AffairProductService,
    private submitButtonService: SubmitButtonService,
    private notifService: NotifService

  ) { }

  ngOnInit() {
    this.form = this.affairProductService.getModifyForm(this.affairProduct);
    this.determinateSubmitButton();
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

  public submitForm() {
    if (this.isValidForm()) {
      this.affairProductService.modify(this.affairProduct.id, this.form).subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        },
        (error) => {
          this.notifService.showErrorNotif(error);
        }
      )
    }
  }

  public onChangeProduct(product) {
    this.form.patchValue({
      price: this.affairProductService.getDefaultPrice(this.affair, product)
    })

  }

  private determinateSubmitButton() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'le code produit',
      text: 'Modifier',
      icon: faSave
    });
    this.affairProductService.setSubmitButton(this.submitButton);
  }

}
