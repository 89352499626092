import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MarketType } from '@libs/models/src/lib/market-type.model';

@Component({
  selector: 'markets-types-selection',
  templateUrl: './markets-types-selection.component.html',
  styleUrls: ['./markets-types-selection.component.scss']
})
export class MarketsTypesSelectionComponent implements OnInit {

  @Input() keyPrefix = '';
  @Input() size: 'xs' | 'xl' = 'xs';
  @Input() marketsTypes: MarketType[] = [];
  @Input() form: FormGroup;

  constructor() { }

  ngOnInit() {
  }

}
