// Angular Core
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Configs
import { API_URL } from './services.shared';
import { AutocompleteOptions } from './classes/autocomplete-options';

// Modules
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

// Services
import { AffairService } from './affair.service';
import { ProductService } from './product.service';
import { AffairProductService } from './affair-product.service';
import { CommonService } from './utilities/common.service';
import { MlAgencyService } from './ml-agency.service';
import { PayerService } from './payer.service';
import { FormFormatterService } from './utilities/form-formatter.service';
import { CustomerService } from './customer.service';
import { InterventionService } from './intervention.service';
import { ApiService } from '@eros-front/api';
import { AffairDocumentCustomerService } from './affair-document-customer.service';
import { InterventionDocumentCustomerService } from './intervention-document-customer.service';
import { AffairInvoicingPreferenceService } from './affair-invoicing-preference.service';
import { FileService } from './file.service';
import { AffairDocumentDeliveredService } from './affair-document-delivered.service';
import { InvoiceService } from './invoice.service';
import { FormLocalStorageService } from './utilities/form-local-storage.service';
import { RegexService } from './utilities/regex.service';
import { InvoiceTemporaryService } from './invoice-temporary.service';
import { InvoiceDetailService } from './invoice-detail.service';
import { InvoiceStatusService } from './invoice-status.service';
import { AffairIndividualHouseService } from './affair-individual-house.service';
import { AffairCollectiveService } from './affair-collective.service';
import { MarketTypeService } from './market-type.service';
import { AffairMyLinkService } from './affair-mylink.service';
import { FieldService } from './field.service';
import { DocumentCustomerService } from './document-customer.service';
import { DocumentDeliveredService } from './document-delivered.service';
import { PerformanceService } from './performance.service';
import { SettingService } from './setting.service';
import { RegionService } from './region.service';
import { UnpaidStatusService } from './unpaid-status.service';
import { InterventionStatusService } from './intervention-status.service';
import { ConformityService } from './conformity.service';
import { IndicatorInterventionTypeService } from './indicator-intervention-type.service';
import { AffairContactService } from './affair-contact.service';
import { BuildingPartService } from './building-part.service';
import { BuildingTypeService } from './building-type.service';
import { BuildingService } from './building.service';
import { EventService } from './event.service';
import { ShuttleSheetService } from './shuttle-sheet.service';
import { ShuttleSheetTemplateService } from './shuttle-sheet-template.service';
import { EventTypeService } from './event-type.service';
import { DateUtilsService } from './utilities/date-utils.service';
import { FormValidatorsService } from './utilities/form-validators.service';
import { DragDropService } from './utilities/drag-drop.service';
import { StatisticInterventionService } from './statistic-intervention.service';
import { StatisticInterventionVolume } from './statistic-intervention-volume.service';
import { ChartService } from './utilities/chart.service';
import { StatisticInterventionRevenueService } from './statistic-intervention-revenue.service';
import { StatisticService } from './statistic.service';
import { StatisticVolumeService } from './statistic-volume.service';
import { StatisticRevenueService } from './statistic-revenue.service';
import { MapChartService } from './utilities/map-chart.service';
import { StandardResponseService } from './standard-response.service';
import { SendSettingService } from './send-setting.service';
import { AffairMonitoringTypeService } from './affair-monitoring-type.service';
import { AffairMonitoringService } from './affair-monitoring.service';
import { AffairGoalService } from './affair-goal.service';
import { StudyOfficeService } from './study-office.service';
import { OfficeFileService } from './office-file.service';
import { SendSettingTypeService } from './send-setting-type.service';
import { AffairOfficeFileService } from './affair-office-file.service';
import { OfficeFileIndicatorService } from './office-file-indicator.service';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { AffairStandardResponseService } from './affair-standard-response.service';
import { MailService } from './mail.service';
import { CustomerTypeService } from './customer-type.service';
import { IndicatorCustomerService } from './indicator-customer.service';
import { RivalService } from './rival.service';
import { ManagerService } from './manager.service';
import { AffairStatusService } from './affair-status.service';
import { CustomLocalStorageService } from './utilities/custom-local-storage.service';
import { SearchService } from './search.service';
import { QuotationService } from './quotation.service';
import { QuotationDetailService } from './quotation-detail.service';
import { MonitoringTypeService } from './monitoring-type.service';
import { QuotationStatusService } from './quotation-status.service';
import { MlSocietyService } from './ml-society.service';
import { DashboardService } from './dashboard.service';
import { TextTagService } from './text-tag.service';
import { PaymentService } from './payment.service';
import { AffairInspectionService } from './affair-inspection.service';
import { AffairExportService } from './affair-export.service';
import { ProductUrgencyLevelService } from './product-urgency-level.service';
import { ShiftOriginService } from './shift-origin.service';
import { AffairMessageService } from './affair-message.service';
import { AccountingExportService } from './accounting-export.service';
import { ExpenseAccountStatusService } from './expense-account-status.service';
import { ExpenseTypeService } from './expense-type.service';
import { ExpenseWordingService } from './expense-wording.service';
import { PhoneLineService } from './phone-line.service';
import { ExpenseAccountService } from './expense-account.service';
import { ExpenseService } from './expense.service';
import { AffairMeetingService } from './affair-meeting.service';
import { FileUtilsService } from './utilities/file-utils.service';
import { AffairArchiveService } from './affairs/affair-archive.service';
import { ImageService } from './utilities/image.service';
import { AffairShuttleSheetService } from './affairs/affair-shuttle-sheet.service';
import { ShuttleSheetGlobalUtilities } from './utilities/shuttle-sheet-global-utilities.service';
import { TelephonyService } from './telephony.service';
import { AffairRenovationService } from './affair-renovation.service';
import { ProgrammedStandardResponseService } from './customer-relationship/programmed-standard-response.service';
import { ApiConnectionService } from './common/api-connection.service';
import { AffairYnergieService } from './affairs/affair-ynergie.service';

@NgModule({
  imports: [
    CommonModule,
    GooglePlaceModule
  ],
  declarations: [
    SanitizeHtmlPipe
  ],
  providers: [
    CommonService,
    FormLocalStorageService,
    RegexService,
    CustomerService,
    FormFormatterService,
    ApiService,
    FileService,
    PayerService,
    MlAgencyService,
    AffairProductService,
    ProductService,
    AffairService,
    AffairIndividualHouseService,
    AffairMyLinkService,
    AffairCollectiveService,
    AffairInspectionService,
    AffairInvoicingPreferenceService,
    InterventionService,
    AffairDocumentCustomerService,
    AffairDocumentDeliveredService,
    AffairContactService,
    InterventionDocumentCustomerService,
    InvoiceService,
    InvoiceTemporaryService,
    InvoiceStatusService,
    InvoiceDetailService,
    MarketTypeService,
    FieldService,
    DocumentCustomerService,
    DocumentDeliveredService,
    PerformanceService,
    SettingService,
    RegionService,
    UnpaidStatusService,
    InterventionStatusService,
    ConformityService,
    IndicatorInterventionTypeService,
    BuildingPartService,
    BuildingTypeService,
    BuildingService,
    EventService,
    ShuttleSheetService,
    ShuttleSheetTemplateService,
    EventTypeService,
    DateUtilsService,
    FormValidatorsService,
    DragDropService,
    StatisticInterventionService,
    StatisticInterventionVolume,
    StatisticInterventionRevenueService,
    ChartService,
    StatisticService,
    StatisticVolumeService,
    StatisticRevenueService,
    MapChartService,
    StandardResponseService,
    SendSettingService,
    AffairMonitoringTypeService,
    AffairMonitoringService,
    AffairGoalService,
    StudyOfficeService,
    OfficeFileService,
    SendSettingTypeService,
    AffairOfficeFileService,
    OfficeFileIndicatorService,
    AffairStandardResponseService,
    MailService,
    CustomerTypeService,
    IndicatorCustomerService,
    RivalService,
    ManagerService,
    AffairStatusService,
    CustomLocalStorageService,
    SearchService,
    QuotationService,
    QuotationStatusService,
    QuotationDetailService,
    MonitoringTypeService,
    MlSocietyService,
    DashboardService,
    TextTagService,
    PaymentService,
    AffairExportService,
    ProductUrgencyLevelService,
    ShiftOriginService,
    AffairMessageService,
    AccountingExportService,
    ExpenseAccountStatusService,
    ExpenseTypeService,
    ExpenseWordingService,
    PhoneLineService,
    ExpenseAccountService,
    ExpenseService,
    AffairMeetingService,
    FileUtilsService,
    AffairArchiveService,
    ImageService,
    AffairShuttleSheetService,
    ShuttleSheetGlobalUtilities,
    TelephonyService,
    AffairRenovationService,
    ProgrammedStandardResponseService,
    ApiConnectionService,
    AffairYnergieService,
  ],
  exports: [
    SanitizeHtmlPipe
  ]
})
export class ServicesModule {
  static forRoot(host: string) {
    return {
      ngModule: ServicesModule,
      providers: [{
        provide: API_URL,
        useValue: host
      }]
    }
  }

}
