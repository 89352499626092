import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Quotation } from '@libs/models/src';
import { FileService, FileUtilsService } from '@libs/services/src';
import { QuotationService } from '@libs/services/src/lib/quotation.service';

@Component({
  selector: 'quotation-thumbnail',
  templateUrl: './quotation-thumbnail.component.html',
  styleUrls: ['./quotation-thumbnail.component.scss']
})
export class QuotationThumbnailComponent implements OnInit {

  @Input() index: number;
  @Input() currentHoverIndex: number;
  @Input() currentTabIndex: number = 0;
  @Input() quotation: Quotation;
  @Input() isMinified: boolean = false;
  @Output() mouseOverThumbnails = new EventEmitter();
  @Output() onThumbnailSelected = new EventEmitter();
  @Input() isSelected = false;

  constructor(
    private fileService: FileService,
    private router: Router,
    private quotationService: QuotationService,
    private fileUtilsService: FileUtilsService
  ) { }

  ngOnInit() {
  }

  onMouseOverThumbnails(index: number) {
    this.mouseOverThumbnails.emit(index);
  }

  onEdit(event: any) {
    event.stopPropagation();
    this.router.navigateByUrl(`quotations/${this.quotation.id}/show`);
  }

  onDivSelected() {
    this.isSelected = !this.isSelected;
    this.onThumbnailSelected.emit(this.quotation);
  }

  onShow(event: any) {
    event.stopPropagation();
    this.quotationService.generateReport(this.quotation.id).subscribe(
      (quotation) => {
        this.fileUtilsService.openPreviewDocument(quotation.path, 'application/pdf');
      }
    )
  }

  onDownload(event: any) {
    event.stopPropagation();
    this.quotationService.download(this.quotation.id).subscribe(
      (file: HttpResponse<Blob>) => {
        this.fileUtilsService.responseToDownload(file, 'pdf');
      }
    );
  }
}
