import { Component, OnInit, AfterViewInit, Input, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';
import { InvoiceStatusService } from '@eros-front/services';
import { InvoiceStatus, SubmitButton } from '@eros-front/models';

@Component({
  selector: 'app-settings-invoicing-invoices-statuses-modal',
  templateUrl: './settings-invoicing-invoices-statuses-modal.component.html',
  styleUrls: ['./settings-invoicing-invoices-statuses-modal.component.scss']
})
export class SettingsInvoicingInvoicesStatusesModalComponent implements OnInit, AfterViewInit {

  @Input() status: InvoiceStatus;
  @Input() labelsClasses: any;
  @ViewChild('settingsAddEditInvoiceStatus', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private invoiceStatusService: InvoiceStatusService
  ) { }

  ngOnInit() {
    this.initValues();
  }

  ngAfterViewInit() {
    this.invoiceStatusService.setModalAddEdit(this.childModal);
  }

  onModalShown() {
    this.determinateSubmitButton();
    this.invoiceStatusService.setSubmitButton(this.submitButton);
    this.form = this.invoiceStatusService.getForm(this.status);
  }

  onModalHidden() {
    this.initValues();
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.status) {
        this.invoiceStatusService.update(this.status.id, this.form);
      } else {
        this.invoiceStatusService.store(this.form);
      }
    }
  }

  private initValues() {
    this.status = null;
    this.form = null;
  }

  private determinateSubmitButton() {
    this.submitButton = this.invoiceStatusService.determinateSubmitButton(this.status, 'le statut facture');
  }

  private isValidForm(): boolean {
    return this.form.valid;
  }

}
