import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Invoice } from '@libs/models/src';
import { AgencyService, MlAgencyService, PayerService, ProductService, ThingService } from '@libs/services/src';
import { InvoiceService } from '@libs/services/src/lib/invoice.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { tabSuffix } from '_config/tab-suffix';
import { InvoiceGenerateCreditModalComponent } from './invoice-generate-credit-modal/invoice-generate-credit-modal.component';

@Component({
  selector: 'app-invoice-show',
  templateUrl: './invoice-show.component.html',
  styleUrls: ['./invoice-show.component.scss']
})
export class InvoiceShowComponent implements OnInit, OnDestroy {


  public invoice: Invoice = null;
  public mlAgencies = [];
  public agencies = [];
  public payers = [];
  public payersPreferences = [];
  public products = [];
  public vatRates = [];
  private invoice$: Subscription;
  private mlAgencies$: Subscription;
  private agencies$: Subscription;
  private payers$: Subscription;
  private payersPreferences$: Subscription;
  private products$: Subscription;
  private vatRates$: Subscription;

  constructor(
    private route: ActivatedRoute,
    private invoiceService: InvoiceService,
    private mlAgencyService: MlAgencyService,
    private agencyService: AgencyService,
    private payerService: PayerService,
    private thingService: ThingService,
    private productService: ProductService,
    private titleService: Title,
    private router: Router
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.getValues();
  }

  ngOnDestroy() {
    this.invoice$.unsubscribe();
    this.mlAgencies$.unsubscribe();
    this.agencies$.unsubscribe();
    this.payers$.unsubscribe();
    this.payersPreferences$.unsubscribe();
    this.vatRates$.unsubscribe();
    this.products$.unsubscribe();
    this.invoiceService.reset();
  }

  getStatusLabelClass() {
    return this.invoice.invoiceStatus.labelClass;
  }

  getStatusName() {
    return this.invoice.invoiceStatus.name;
  }

  getDate() {
    return this.invoice.dateFormatted;
  }

  getEntityName() {
    return this.invoice.isCredit ? 'Avoir' : 'Facture';
  }

  getNumber() {
    return this.invoice.number;
  }

  private initSubscriptions(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = Number(params.get('id'));
      if (!isNaN(id)) {
        this.invoiceService.get(id);
      }
    });
    this.invoice$ = this.invoiceService.invoice$.subscribe(
      (data) => {
        if (data) {
          this.invoice = data;
          this.initWhenInvoice();
        }
      }
    );
    this.mlAgencies$ = this.mlAgencyService.mlAgenciesSelect$.subscribe(
      (data) => {
        if (data) {
          this.mlAgencies = data;
        }
      }
    );
    this.agencies$ = this.agencyService.agenciesSelect$.subscribe(
      (data) => {
        if (data) {
          this.agencies = data;
        }
      }
    );
    this.payers$ = this.payerService.payers$.subscribe(
      (data) => {
        if (data) {
          this.payers = data;
        }
      }
    );
    this.payersPreferences$ = this.thingService.payersPreferences$.subscribe(
      (data) => {
        if (data) {
          this.payersPreferences = data;
        }
      }
    );
    this.vatRates$ = this.thingService.vatRates$.subscribe(
      (data) => {
        if (data) {
          this.vatRates = data;
        }
      }
    );
    this.products$ = this.productService.products$.subscribe(
      (data) => {
        if (data) {
          this.products = data;
        }
      }
    );
  }

  private getValues(): void {
    this.mlAgencyService.getForSelect();
    this.agencyService.getForSelect();
    this.payerService.getAll();
    this.thingService.getPayersPreferences();
    this.thingService.getVatRatesSelect();
    this.productService.getAll();
  }

  private initWhenInvoice(): void {
    this.titleService.setTitle(`Gestion facture : ${this.invoice.number}${tabSuffix}`);
  }

  redirectToInvoice(invoice: any) {
    this.router.navigateByUrl('invoices/' + invoice.id + '/show')

  }
}
