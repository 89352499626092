import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { tabSuffix } from '_config/tab-suffix';

@Component({
  selector: 'app-settings-customer-relationship',
  templateUrl: './settings-customer-relationship.component.html',
  styleUrls: ['./settings-customer-relationship.component.scss']
})
export class SettingsCustomerRelationshipComponent implements OnInit {

  constructor(
    private titleService: Title,
  ) {
    this.titleService.setTitle(`Paramètres relation client${tabSuffix}`);
  }

  ngOnInit() {
  }

}
