import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { CommonService, ProductService, ThingService } from '@libs/services/src';
import { Subscription } from 'rxjs';

@Component({
  selector: 'products-array-form',
  templateUrl: './products-array-form.component.html',
  styleUrls: ['./products-array-form.component.scss']
})
export class ProductsArrayFormComponent implements OnInit, OnDestroy {
  @Input() addProductsForm: FormGroup;
  @Output() addProductRowEvent = new EventEmitter();
  private products$: Subscription;
  private vatRates$: Subscription;
  public products;
  public vatRates;

  constructor(
    private productService: ProductService,
    private commonService: CommonService,
    private thingService: ThingService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.productService.getAll();
  }

  ngOnDestroy() {
    this.products$.unsubscribe();
    this.vatRates$.unsubscribe();
  }

  addProductRow() {
    this.addProductRowEvent.emit();
  }

  onSelectProductChange(item: any, ref: string) {
    if (item) {
      const control = this.getControlOfFormArray(ref);
      if (control) {
        control.patchValue({
          ref: item.name,
          accountingCode: item.accountingCode,
          designation: item.designation,
          vatRate: item.vatRate
        });
      }
    }
  }

  refreshTotalPrice(ref: string) {
    const control = this.getControlOfFormArray(ref);
    if (control) {
      control.patchValue({
        totalWithoutTaxes: this.commonService.formatNumberToLocale(control.value.quantity * control.value.unitPrice)
      });
    }
  }

  getFormControls(form: FormGroup, controlName: string) {
    return (form.get(controlName) as FormArray).controls;
  }


  deleteProductRow(ref: string) {
    const formArray = this.addProductsForm.get('products') as FormArray;
    for (let i = 0; i < formArray.controls.length; i++) {
      const control = formArray.controls[i];
      if (control.value.rowId === ref) {
        formArray.removeAt(i);
        break;
      }
    }
  }

  /**
   * UTILS 
   */
  private getControlOfFormArray(ref: string): any {
    const formArray = this.addProductsForm.get('products') as FormArray;
    for (let i = 0; i < formArray.controls.length; i++) {
      const control = formArray.controls[i];
      if (control.value.rowId === ref) {
        return control;
      }
    }
    return null;
  }

  private initSubscriptions() {
    this.products$ = this.productService.products$.subscribe(
      (data) => {
        if (data) {
          this.products = data;
        }
      }
    )
    this.vatRates$ = this.thingService.vatRates$.subscribe(
      (data) => {
        if (data) {
          this.vatRates = data;
        }
      }
    )
  }

}
