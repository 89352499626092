import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

export interface FiltersValues {
  research: string;
  selectedDocumentsTypes: any;
  selectedProducts: any;
  selectedVersions: any;
  selectedBuildings: any;
  showOthersCompanies: boolean;
}

@Component({
  selector: 'app-affair-show-document-filters',
  templateUrl: './affair-show-document-filters.component.html',
  styleUrls: ['./affair-show-document-filters.component.scss']
})

export class AffairShowDocumentFiltersComponent implements OnInit, OnChanges {

  @Input() form: any;
  @Input() filters: any;
  public documentsTypes = [];
  public products = [];
  public versions = [];
  public buildings = [];
  public research = '';
  public selectedDocumentsTypes = [];
  public selectedProducts = [];
  public selectedVersions = [];
  public selectedBuildings = [];

  @Output() onSearchChange = new EventEmitter();
  @Output() onDocumentsTypesChange = new EventEmitter();
  @Output() onProductsChange = new EventEmitter();
  @Output() onVersionsChange = new EventEmitter();
  @Output() onBuildingsChange = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    this.initValues();
  }

   ngOnChanges(changes: any) {
    if (changes['filters'] && changes['filters'].previousValue != changes['filters'].currentValue) {
       this.initValues();
    }
  }

  searchChange(value: string) {
    this.form.patchValue({ research: value });
    this.onSearchChange.emit();
  }

  onClearSearchClicked(value: string) {
    this.form.patchValue({ research: value });
    this.onSearchChange.emit();
  }

  onDocumentClicked(value: number) {
    const index =  this.selectedDocumentsTypes.indexOf(value);
    if (index > -1) {
      this.selectedDocumentsTypes.splice(index, 1);
    } else {
      this.selectedDocumentsTypes.push(value);
    }
    this.form.patchValue({selectedDocumentsTypes: this.selectedDocumentsTypes});
    this.onDocumentsTypesChange.emit();
  }

  isInSelectedDocumentsTypes(value): boolean {
    return this.selectedDocumentsTypes.includes(value);
  }

  getButtonType(value): string {
    return this.isInSelectedDocumentsTypes(value) ? 'primary' : 'default';
  }

  onDocumentDeselectAll(): void {
    this.selectedDocumentsTypes = [];
    this.form.patchValue({selectedDocumentsTypes: this.selectedDocumentsTypes});
  }

  private initValues() {
    if (this.filters.documentsTypes) {
      this.documentsTypes = this.filters.documentsTypes;
    }
    if (this.filters.products) {
      this.products = this.filters.products;
    }
    if (this.filters.versions) {
      this.versions = this.filters.versions;
    }
    if (this.filters.buildings) {
      this.buildings = this.filters.buildings;
    }
  }

}
