import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-affair-show-costs-estimates',
  templateUrl: './affair-show-costs-estimates.component.html',
  styleUrls: ['./affair-show-costs-estimates.component.scss']
})
export class AffairShowCostsEstimatesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
